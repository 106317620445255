/* Libs */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  Img,
  ConfirmAction,
} from 'components';

import {
  useOutsideClickHook,
  useToggleBooleanHook,
} from 'hooks';

/* Styles */
import { ERROR_CROSS } from 'assets/icons/signs';
import * as Styled from './styles';

function DeleteAnswer({
  deleteAnswer,
  isVisible,
}) {
  const [isOpen, toggle] = useToggleBooleanHook(false);

  const close = () => {
    toggle(false);
  };

  const onConfirm = () => {
    toggle(false);
    deleteAnswer();
  };

  const sectionRef = useRef(null);
  useOutsideClickHook(close, sectionRef);

  if (!isVisible) return null;

  return (
    <Styled.Wrapper
      ref={sectionRef}
    >
      <Img size={[20, 20]} src={ERROR_CROSS} onClick={toggle} />
      <ConfirmAction
        isOpen={isOpen}
        onConfirm={onConfirm}
        onReject={close}
      />
    </Styled.Wrapper>
  );
}

/* DeleteAnswer type of props */

DeleteAnswer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  deleteAnswer: PropTypes.func.isRequired,
};

export default DeleteAnswer;
