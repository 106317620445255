/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Table } from 'components/blocks/Table/styles';
import { Cell as ActionCell } from 'components/blocks/TableCells/ActionsCell/styles';
import { Wrapper as ColumnCell } from 'components/blocks/TableCells/ColumnCell/styles';


export const EditUser = styled.div`
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;

export const Users = styled.div`
  margin-top: 35px;

  & ${Table} {
    & ${ColumnCell}{
       max-width: 206px;
    }
    
    & ${ActionCell} {
      & > *:not(:first-child)::before {
        background-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      }
      & div {
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 14px;
        cursor: pointer;
      }
      ${EditUser} {
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
      }
    }
  }
`;
