import { TIME_OPTIONS, DATE_SETTINGS } from 'config';
import moment from 'moment';

export default (
  step = TIME_OPTIONS.STEP_IN_MINUTES,
  valueFormat = DATE_SETTINGS.FORMAT.HOURS_MINUTES,
  labelFormat = DATE_SETTINGS.FORMAT.HOURS_MINUTES_MERIDIEM,
) => {
  const times = [];
  const cursorDate = moment().startOf('day');
  const endOfDay = moment().endOf('day');

  while (cursorDate.isBefore(endOfDay)) {
    times.push({
      value: cursorDate.format(valueFormat),
      label: cursorDate.format(labelFormat),
    });
    cursorDate.add(step, 'minutes');
  }

  return times;
};
