/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import { ANSWER_STATUS } from '_constants';

/* Styles */

import * as Styled from './styles';

const STATUS_LABELS = {
  [ANSWER_STATUS.CORRECT]: 'Correct',
  [ANSWER_STATUS.WRONG]: 'Wrong',
  [ANSWER_STATUS.NOT_ANSWERED]: 'Not answered',
};

function ManageGameAnswerListItem({
  answer,
  status,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Answer isCorrect={status === ANSWER_STATUS.CORRECT}>
        {answer}
      </Styled.Answer>
      <Styled.Status status={status}>
        { STATUS_LABELS[status] }
      </Styled.Status>
    </Styled.Wrapper>
  );
}

/* ManageGameAnswerListItem type of props */

ManageGameAnswerListItem.propTypes = {
  answer: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    ANSWER_STATUS.NOT_ANSWERED,
    ANSWER_STATUS.CORRECT,
    ANSWER_STATUS.WRONG,
  ]).isRequired,
};

/* ManageGameAnswerListItem default props */

ManageGameAnswerListItem.defaultProps = {};

export default ManageGameAnswerListItem;
