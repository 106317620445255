import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button } from 'components';
import * as Styled from './styles';

const SmallConfirmationModal = ({
  open, onConfirm, onClose, description, confirmText, loading,
}) => {
  if (!open) return null;
  return (
    <Modal open={open} withoutCloseCross style={{ minHeight: 'unset' }} onClose={onClose}>
      <Styled.Wrapper>
        <Styled.Description>
          {description}
        </Styled.Description>

        <Styled.ButtonsWrapper>
          <Button onClick={onConfirm} isLoading={loading} disabled={loading}>
            {confirmText}
          </Button>
          <Styled.CancelButton onClick={onClose}>
            Cancel
          </Styled.CancelButton>
        </Styled.ButtonsWrapper>
      </Styled.Wrapper>
    </Modal>
  );
};

SmallConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  confirmText: PropTypes.string,
};

SmallConfirmationModal.defaultProps = {
  open: false,
  description: '',
  confirmText: 'Confirm',
};

export default SmallConfirmationModal;
