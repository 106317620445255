import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import { Button } from 'components/ui/Button/styles';
import { Wrapper as WrapperLogo } from 'components/blocks/ui/Logo/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 48px;
  background: ${COLORS_CONFIG.SKY_BLUE};
  ${WrapperLogo} {
    width: 150px;
  }
  ${Button} {
    margin-top: 40px;
    border: 1px solid ${COLORS_CONFIG.WHITE};
    background: ${COLORS_CONFIG.TRANSPARENT};
    &:hover {
      background-color: ${COLORS_CONFIG.WHITE$30};
      color: ${COLORS_CONFIG.WHITE};
    }
  }
`;

export const Title = styled.div`
  margin-top: 32px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
`;

export const Description = styled.div`
  margin-top: 16px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
`;
