/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import {
  Modal,
  EditContractForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function EditContractModal({
  onClose,
  open,
  contract,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Edit contract"
      open={open}
    >
      <Styled.EditContract>
        <EditContractForm onClose={onClose} contract={contract} />
      </Styled.EditContract>
    </Modal>
  );
}

// Type of props
EditContractModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  contract: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
};

// Default value for props
EditContractModal.defaultProps = {};
