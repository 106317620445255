import moment from 'moment';
import abbrHasSpecialSymbols from 'utils/custom/abbrHasSpecialSymbols';

const PREVIOUS_TIMEZONES_ARRAY = moment.tz.names()
  .reduce(
    (tzs, timezone) => {
      const abbrName = moment.tz(timezone).zoneAbbr();
      return abbrHasSpecialSymbols(abbrName) || tzs.some(({ timezoneAbbr }) => timezoneAbbr === abbrName) || 'UTC'
        ? tzs
        : tzs.concat({
          timezoneName: timezone,
          timezoneAbbr: abbrName,
          timezoneOffset: moment.tz(timezone).format('Z'),
        });
    },
    [],
  );


const getTimezoneName = (timezone) => {
  if (timezone && timezone.length > 4) return timezone;

  // console.warn(`Your Timezone is ${timezone}. Probably, it isn't in IANA Standart. Please, edit the session.`);

  const timezoneObject = PREVIOUS_TIMEZONES_ARRAY.find(({ timezoneAbbr }) => timezone === timezoneAbbr);

  return timezoneObject ? timezoneObject.timezoneName : (timezone || moment.tz.guess());
};


export default getTimezoneName;
