/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { Img } from 'components';

import { LOCKED, LOCKED_DARK } from 'assets/icons/locks';

/* Styles */

import * as Styled from './styles';

function CloseQuestion({
  icon,
  name,
  isDarkMode,
}) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <Styled.Icon>
        <Img disabled src={isDarkMode ? LOCKED_DARK : LOCKED} size={[40, 55]} />
      </Styled.Icon>
      <Styled.Name>
        {name}
      </Styled.Name>
      <Styled.Line />
      <Styled.Tip>
        Find code to unlock
      </Styled.Tip>
    </Styled.Wrapper>
  );
}

/* CloseQuestion type of props */

CloseQuestion.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
};

/* CloseQuestion default props */

CloseQuestion.defaultProps = {
  icon: '',
};

export default CloseQuestion;
