import moment from 'moment';

export default ({
  dateFrom,
  dateTo,
  weekdays,
}) => {
  const included = [];
  const startDate = moment(dateFrom);
  const endDate = moment(dateTo);
  let diff = endDate.diff(startDate, 'days');

  if (diff >= 6) return weekdays;

  let start = startDate.day();

  do {
    included.push(weekdays[start]);

    if (start >= 6) {
      start = 0;
    } else {
      start += 1;
    }
    diff -= 1;
  } while (diff >= 0);

  return included;
};
