import { createGlobalStyle } from 'styled-components';

import theme from 'reapop-theme-wybo/lib/styles.module.css';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/**
 * Get classname for css
 * @param {String} cn
 */
function c(cn) {
  return `.${cn}`;
}
export default createGlobalStyle`
  ${c(theme.notification)} {
    display: flex;
    flex-direction: column;
    padding: 5px 18px;
    background-color: ${({ mode }) => (mode ? COLORS_CONFIG.NAVY_BLUE : COLORS_CONFIG.WHITE)} !important;
    color: ${({ mode }) => (mode ? COLORS_CONFIG.WHITE : COLORS_CONFIG.SMOOTH_BROWN)} !important;
    
    & ${c(theme['notification-buttons'])} {
      flex-direction: row!important;
      padding-top: 10px;
      & ${c(theme['notification-button'])} {
        border:none!important;
        &:last-child {
          border-left: 1px solid ${({ mode }) => (mode ? COLORS_CONFIG.SKY_BLUE : COLORS_CONFIG.SILVER)}!important;
        }
      }
    }
    & ${c(theme['notification-icon'])}{
      margin: 0;
      padding-bottom: 12px;
    }
    & ${c(theme['notification-meta'])}{
      padding: 0px;
      & ${c(theme['notification-title'])}{
        line-height: 15px;
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        margin-bottom: 8px;
      }
      & ${c(theme['notification-message'])}{
        line-height: 13px;
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
      }
      
    }
  }
  
  ${c(theme['notification-button'])} {
    color: ${({ mode }) => (mode ? COLORS_CONFIG.WHITE : COLORS_CONFIG.SMOOTH_BROWN)} !important;
    
    &:hover{
     color: ${({ mode }) => (mode ? COLORS_CONFIG.SKY_BLUE : COLORS_CONFIG.SMOOTH_BROWN)} !important;
    }
  }
  
  ${c(theme['notification--success'])} {
    border-left: 3px solid ${({ mode }) => (mode ? COLORS_CONFIG.SKY_BLUE : COLORS_CONFIG.LIGHT_GREEN)}!important;
    box-shadow: ${({ mode }) => (mode ? COLORS_CONFIG.SHADOWS.NOTIFY_SHADOW : 'none')} !important;
    & ${c(theme['notification-icon'])}::before {
      color: ${({ mode }) => (mode ? COLORS_CONFIG.SKY_BLUE : COLORS_CONFIG.LIGHT_GREEN)} !important;
    }
  }
  ${c(theme['notification--info'])} {
    border-left: 3px solid ${({ mode }) => (mode ? COLORS_CONFIG.SKY_BLUE : COLORS_CONFIG.LIGHT_AQUA)}!important;
    box-shadow: ${({ mode }) => (mode ? COLORS_CONFIG.SHADOWS.NOTIFY_SHADOW : 'none')} !important;
    & ${c(theme['notification-icon'])}::before {
      color: ${({ mode }) => (mode ? COLORS_CONFIG.SKY_BLUE : COLORS_CONFIG.LIGHT_AQUA)} !important;
    }
  }
  ${c(theme['notification--error'])} {
    border-left: 3px solid ${({ mode }) => (mode ? COLORS_CONFIG.RED_DARK : COLORS_CONFIG.ORANGE)}!important;
    box-shadow: ${({ mode }) => (mode ? COLORS_CONFIG.SHADOWS.NOTIFY_SHADOW : 'none')} !important;
    & ${c(theme['notification-icon'])}::before {
      color: ${({ mode }) => (mode ? COLORS_CONFIG.RED_DARK : COLORS_CONFIG.ORANGE)} !important;
    }
  }
`;
