/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import { Modal, EditKitForm } from 'components';

/* Styles */

import * as Styled from './styles';

export default function EditKit({
  onClose,
  data,
  open,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Edit a kit"
      open={open}
    >
      <Styled.EditKitModal>
        <EditKitForm onClose={onClose} data={data} />
      </Styled.EditKitModal>
    </Modal>
  );
}

// Type of props
EditKit.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
EditKit.defaultProps = {};
