/* Libs */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

/* Utils */

import {
  redirect,
  getEditGameDefLink,
  hasPermission,
} from 'utils/custom';

/* Components */
import {
  SimpleTextCell,
  ActionsCell,
  Table,
  GoLabel,
} from 'components';

/* Constants */

import { GAME_DEF_TABLE } from 'config';
import { PERMISSION_NAMES, LABEL_COLOR_TYPES, TRIANGLE_TYPES } from '_constants';

/* Styles */
import * as Styled from './styles';

// GameDefinitionsTable columns cell structure
const getColumnsData = actions => [
  {
    accessor: 'storyline.name',
    minWidth: 95,
    Header: 'Storyline',
    Cell: props => <SimpleTextCell value={props.value} size="small" />,
  },
  {
    accessor: 'name',
    minWidth: 100,
    Header: 'Game definition',
    Cell: props => <SimpleTextCell value={props.value} size="small" />,
  },
  { // TODO: create logic for action sections in that table
    // accessor: 'actions',
    minWidth: hasPermission(PERMISSION_NAMES.VIEW_GAME_DEFINITION) && !actions.isImpersonatedAdmin ? 150 : 85,
    Header: '',
    Cell: ({ original: { id } }) => (
      <ActionsCell>
        {
          hasPermission(PERMISSION_NAMES.VIEW_GAME_DEFINITION) && !actions.isImpersonatedAdmin && (
            <Link to={getEditGameDefLink(id)}>View</Link>
          )
        }
        <GoLabel
          text="Export"
          triangleType={TRIANGLE_TYPES.FORWARD}
          color={LABEL_COLOR_TYPES.BROWN}
          click={() => actions.loadGameDef(id)}
        />
      </ActionsCell>
    ),
  },
];


export default function GameDefinitionsTable({
  data, actions, tableSize, pagination, isImpersonatedAdmin,
}) {
  return (
    <Styled.GameDefinitionsTable>
      <Table
        defaultPageSize={data.length ? data.length : null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={getColumnsData({ ...actions, isImpersonatedAdmin })}
        data={data}
        sortable={GAME_DEF_TABLE.sortable}
        // actions={actions}
        pagination={pagination}
      />
    </Styled.GameDefinitionsTable>
  );
}

// Type of props
GameDefinitionsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    storyline: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
  })).isRequired,
  isImpersonatedAdmin: PropTypes.bool,
};

GameDefinitionsTable.defaultProps = {
  isImpersonatedAdmin: false,
};
