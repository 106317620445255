import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useOutsideClickHook } from 'hooks';

import { Confirm } from 'components';

import * as Styled from './styles';

export default function ConfirmToggle({
  toggleText,
  onSubmit,
}) {
  const [open, setOpen] = useState(false);
  const openConfirm = () => !open && setOpen(true);
  const closeConfirm = () => open && setOpen(false);
  const toggleConfirm = () => open
    ? closeConfirm()
    : openConfirm();

  const toggleRef = useRef(null);
  useOutsideClickHook(closeConfirm, toggleRef);

  return (
    <Styled.Wrapper ref={toggleRef}>
      <Styled.Text onClick={toggleConfirm}>{toggleText}</Styled.Text>
      <Confirm onSubmit={onSubmit} open={open} onCancel={closeConfirm} />
    </Styled.Wrapper>
  );
}

ConfirmToggle.propTypes = {
  toggleText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

ConfirmToggle.defaultProps = {
  toggleText: 'Close campaign',
};
