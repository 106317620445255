/* Libs */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Actions */

import { AuthEntity } from '_entities';

/* Utils */
import { getError, redirect } from 'utils/custom';
import { setUserRole } from 'utils/custom/hasPermission';
import { formIsValid } from 'utils/validation';
import { validateText, validatePasswordDummy, validateEmail } from 'utils/validation/fields';
import { notification } from 'utils/services';

/* Components */

import {
  Input,
  Button,
} from 'components';

/* Constants */

import { KEY_CODE, VALIDATION_VALUES } from '_constants';

/* Styles */

import { URLS_CONFIG } from 'config';
import * as Styled from './styles';

const DEFAULT_DATA = {
  email: '',
  password: '',
};
const DEFAULT_ERRORS = {
  email: null,
  password: null,
};

function AdminSigninForm({
  signin,
}) {
  const [formData, setData] = useState({
    data: DEFAULT_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
    loading: false,
    responseError: null,
  });

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateText({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      case 'password':
        newErrors[name] = validatePasswordDummy({ value, name });
        break;
      default:
        break;
    }
    setData({
      data: { ...data, [name]: value },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email', 'password']),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateText({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      case 'password':
        newErrors[name] = validatePasswordDummy({ value, name });
        break;
      default:
        break;
    }
    setData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email', 'password']),
    }));
  };

  const submit = async () => {
    setData({ ...formData, loading: true });
    try {
      await signin({ ...formData.data }).then(({ user: { role } }) => {
        setUserRole(role);
      }); // Redirect to appropriate page in AuthRoute
    } catch (error) {
      setData({
        ...formData,
        loading: false,
        responseError: getError(error),
      });
      notification.error(getError(error));
    }
  };

  const catchEnter = ({ keyCode }) => keyCode === KEY_CODE.ENTER
      && !loading
      && canSubmit
      && submit();

  const {
    data, errors, loading, canSubmit,
  } = formData;
  return (
    <Styled.Wrapper onKeyDown={catchEnter}>
      <Input
        name="email"
        type="email"
        value={data.email}
        onChange={handleChange}
        onBlur={validateOnBlur}
        placeholder="Email"
        error={errors.email}
        data-test="login-email"
      />
      <Input
        name="password"
        type="password"
        value={data.password}
        onChange={handleChange}
        onBlur={validateOnBlur}
        placeholder="Password"
        error={errors.password}
        data-test="login-password"
      />
      <Button
        isLoading={loading}
        disabled={loading || !canSubmit}
        onClick={submit}
        data-test="login-submit"
      >
        Submit
      </Button>
      <Styled.Reset>
        Forgot your password?
        <span onClick={() => redirect(URLS_CONFIG.auth.reset)}> Reset </span>
      </Styled.Reset>
    </Styled.Wrapper>
  );
}

/* AdminSigninForm type of props */

AdminSigninForm.propTypes = {
  signin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  signin: AuthEntity.actions.signinAdmin,
})(AdminSigninForm);
