/* Libs */
import styled from 'styled-components';

import { Wrapper as ToggleWrapper } from 'components/ui/ToggleBar/styles';

import { COLORS_CONFIG } from 'config';

export const Content = styled.div`
  
`;

export const Wrapper = styled.div`
  & > ${ToggleWrapper} {
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
`;
