const confirmTemplateUpdate = (onSuccess, onCancel) => ({
  message: 'Your data will be lost',
  dissmissAfter: 0,
  dismissible: false,
  buttons: [
    {
      name: 'Continue',
      primary: true,
      onClick: onSuccess,
    },
    {
      name: 'Cancel',
      onClick: onCancel,
    },
  ],
});

const confirmTemplate = ({ onSuccess, onCancel, message }) => ({
  message,
  dissmissAfter: 0,
  dismissible: false,
  buttons: [
    {
      name: 'Continue',
      primary: true,
      onClick: onSuccess,
    },
    {
      name: 'Cancel',
      onClick: onCancel,
    },
  ],
});

const confirmAnonymousMode = (onSuccess, onCancel) => ({
  message: 'The game will start in anonymous mode. Would you like to continue?',
  dissmissAfter: 0,
  dismissible: false,
  buttons: [
    {
      name: 'Continue',
      primary: true,
      onClick: onSuccess,
    },
    {
      name: 'Cancel',
      onClick: onCancel,
    },
  ],
});

const confirmExitGame = (onSuccess, onCancel) => ({
  message: 'Are you sure you want to exit game?',
  dissmissAfter: 0,
  dismissible: false,
  buttons: [
    {
      name: 'Yes',
      primary: true,
      onClick: onSuccess,
    },
    {
      name: 'No',
      onClick: onCancel,
    },
  ],
});

const confirmBookTeam = (onSuccess, onCancel) => ({
  message: 'You have do not saved participant email in the field. Would you like to continue without that participant?',
  dissmissAfter: 0,
  dismissible: false,
  buttons: [
    {
      name: 'Yes',
      primary: true,
      onClick: onSuccess,
    },
    {
      name: 'No',
      onClick: onCancel,
    },
  ],
});

const confirmMultipleSessionsCreation = (onSuccess, onCancel) => ({
  message: 'The chosen kit is already scheduled for some of your chosen dates/times. Would you like to create sessions for the dates/times without conflicts?',
  dissmissAfter: 0,
  dismissible: false,
  buttons: [
    {
      name: 'Yes',
      primary: true,
      onClick: onSuccess,
    },
    {
      name: 'No',
      onClick: onCancel,
    },
  ],
});

export default {
  confirmTemplateUpdate,
  confirmAnonymousMode,
  confirmExitGame,
  confirmTemplate,
  confirmBookTeam,
  confirmMultipleSessionsCreation,
};
