/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 16px;
  cursor: pointer;
  color: ${COLORS_CONFIG.SKY_BLUE};
`;
