/* Libs */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

/* Utils */

import { hasPermission } from 'utils/custom';

/* Components */
import {
  SimpleTextCell,
  ActionsCell,
  Table,
} from 'components';

/* Constants */

import { KITS_TABLE, DATE_SETTINGS } from 'config';
import { PERMISSION_NAMES } from '_constants';

/* Styles */
import * as Styled from './styles';

// KitsTable columns cell structure
const getColumnsData = tableActions => [
  {
    accessor: 'storyline.name',
    minWidth: 80,
    // maxWidth: 150,
    Header: 'Storyline',
    Cell: props => <SimpleTextCell value={props.value} size="small" />,
  },
  {
    accessor: 'kit_id',
    minWidth: 80,
    // maxWidth: 150,
    Header: 'Kit ID',
    Cell: props => <SimpleTextCell value={props.value} size="small" />,
  },
  {
    accessor: 'nickname',
    minWidth: 80,
    // maxWidth: 150,
    Header: 'Nickname',
    Cell: ({ value }) => <SimpleTextCell value={value} size="small" />,
  },
  {
    accessor: 'license_start',
    minWidth: 95,
    // maxWidth: 150,
    Header: 'Start date',
    Cell: props => (
      <SimpleTextCell
        value={moment(props.original.license_start).format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR)}
        size="small"
      />
    ),
  },
].concat(
  hasPermission(PERMISSION_NAMES.VIEW_KIT)
    ? {
      minWidth: 60,
      Cell: ({
        original: { id },
      }) => (
        <ActionsCell>
          <div onClick={() => tableActions.openEditKit(id)}>View</div>
        </ActionsCell>
      ),
    }
    : [],
);


export default function KitsTable({
  data, actions, tableSize, pagination, loading,
}) {
  return (
    <Styled.KitsTable>
      <Table
        loading={loading}
        defaultPageSize={data.length ? data.length : null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={getColumnsData({ ...actions })}
        data={data}
        sortable={KITS_TABLE.sortable}
        pagination={pagination}
      />
    </Styled.KitsTable>
  );
}

// Type of props
KitsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    storyline: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    license_start: PropTypes.string.isRequired,
  })).isRequired,
  actions: PropTypes.shape({
    openEditKit: PropTypes.func.isRequired,
  }).isRequired,
};

KitsTable.defaultProps = {
};
