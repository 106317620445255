/* Libs */
import React, { useState, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Constants */
import { ADD_SESSION_TYPES } from 'config';

/* Components */
import {
  ToggleBar,
  EditSessionFormComponent,
  useEditSessionForm,
} from 'components';
import { AdminCompanyEntity } from '_entities';
import { useValidationOnBlurHook } from 'hooks';
import MultipleSessionsForm from './Multiple';
import useSetupSessionsForm from './Multiple/Steps/SetupSessions/container';

/* Actions */


/* Styles */
import * as Styled from './styles';

function AddSessionForm({ formType, setFormType, ...rest }) {
  const [currentStep, setStep] = useState(0);
  const nextStep = () => setStep(currentStep + 1);
  const prevStep = () => setStep(currentStep - 1);
  const [externalData, setExternalData] = useState({});

  const validationProps = useValidationOnBlurHook();


  const singleSessionData = useEditSessionForm({
    ...rest,
    externalData,
    setExternalData,
  });

  const multipleSessionsForm = useSetupSessionsForm({
    ...rest,
    ...singleSessionData,
    steps: { nextStep, prevStep, currentStep },
    externalData,
    setExternalData,
  });

  const title = useMemo(() => (() => (
    <Styled.Title>
      {
        currentStep
          ? 'Confirm sessions'
          : `Add ${formType.toLowerCase()} session`
      }
    </Styled.Title>
  ))(), [currentStep, formType]);

  return (
    <Styled.Wrapper formType={formType}>
      {title}
      {
        !currentStep && (
          <Styled.Toggle>
            <ToggleBar
              name="monthes"
              onChange={({ target: { value } }) => setFormType(value)}
              active={formType}
              options={[
                ADD_SESSION_TYPES.SINGLE,
                ADD_SESSION_TYPES.MULTIPLE,
              ]}
            />
          </Styled.Toggle>
        )
      }
      {
        formType === ADD_SESSION_TYPES.SINGLE
          ? <EditSessionFormComponent {...singleSessionData} {...validationProps} />
          : <MultipleSessionsForm {...multipleSessionsForm} />
      }
    </Styled.Wrapper>
  );
}

/* AddSessionForm type of props */
AddSessionForm.propTypes = {
  formType: PropTypes.oneOf([
    ADD_SESSION_TYPES.SINGLE,
    ADD_SESSION_TYPES.MULTIPLE,
  ]),
  setFormType: PropTypes.func.isRequired,
};

/* AddSessionForm default props */
AddSessionForm.defaultProps = {};

export default connect(null, {
  addNewLocation: AdminCompanyEntity.actions.addNewLocation,
})(memo(AddSessionForm));
