import * as TYPES from './Company.types';

export const getCompanyById = id => ({
  types: [TYPES.GET_COMPANY_BY_ID, TYPES.GET_COMPANY_BY_ID_SUCCESS, TYPES.GET_COMPANY_BY_ID_FAIL],
  promise: client => client.get(`/companies/${id}/`),
});

export const resetCompany = () => ({
  type: TYPES.RESET_COMPANY_STATE,
});

export const updateCompanyContract = ({
  companyId, id: contractId, start_date, end_date,
}) => ({
  types: [
    TYPES.UPDATE_COMPANY_CONTRACT,
    TYPES.UPDATE_COMPANY_CONTRACT_SUCCESS,
    TYPES.UPDATE_COMPANY_CONTRACT_FAIL,
  ],
  promise: client => client.put(`/companies/contracts/${contractId}/`, {
    company: companyId,
    start_date,
    end_date,
  }),
});

export const updateCompanyLicensesCount = ({ companyId, licenses: number_of_seats }) => ({
  types: [
    TYPES.UPDATE_COMPANY_LICESES_COUNT,
    TYPES.UPDATE_COMPANY_LICESES_COUNT_SUCCESS,
    TYPES.UPDATE_COMPANY_LICESES_COUNT_FAIL,
  ],
  promise: client => client.put(`/companies/user-licenses/${companyId}/`, {
    number_of_seats,
  }),
});

export const updatePOC = data => // TODO: make a real request when BE will ready
  ({
    types: [TYPES.UPDATE_POC, TYPES.UPDATE_POC_SUCCESS, TYPES.UPDATE_POC_FAIL],
    promise: client => client.put(`/users/${data.id}/`, data),
  });


export const resendCompanyAdminInvite = data => ({
  types: [
    TYPES.RESEND_COMPANY_ADMIN_INVITE,
    TYPES.RESEND_COMPANY_ADMIN_INVITE_SUCCESS,
    TYPES.RESEND_COMPANY_ADMIN_INVITE_FAIL,
  ],
  promise: client => client.post('/users/resend-invite/', data),
});

export const deleteCompany = id => ({
  types: [TYPES.DELETE_COMPANY, TYPES.DELETE_COMPANY_SUCCESS, TYPES.DELETE_COMPANY_FAIL],
  promise: client => client.delete(`/companies/${id}/`),
});

export const changeCompanyActivityStatus = (id, status) => ({
  types: [
    TYPES.CHANGE_COMPANY_ACTIVITY,
    TYPES.CHANGE_COMPANY_ACTIVITY_SUCCESS,
    TYPES.CHANGE_COMPANY_ACTIVITY_FAIL,
  ],
  promise: client => client.put(`/companies/${id}/`, { is_active: status }),
});

export const getUniqueCompanyName = name => ({
  types: [
    TYPES.GET_UNIQUE_COMPANY_NAME,
    TYPES.GET_UNIQUE_COMPANY_NAME_SUCCESS,
    TYPES.GET_UNIQUE_COMPANY_NAME_FAIL,
  ],
  promise: client => client.post('/companies/check-name/', {
    name,
  }),
});
