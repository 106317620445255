/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VimeoPlayer from '@livingsecurity/react-vimeo';

/* Utils */

import { isEmpty } from 'utils/validation';

/* Components */

import { BigTitle, Img } from 'components';

/* Constants */

import { GAME_STEP_TITLES } from '_constants';
import { WARNING_SIGN } from 'assets/icons/signs';

/* Styles */

import * as Styled from './styles';

function Preview({
  vimeoId,
  title,
  text,
  step,
}) {
  const [videoNotFound, setVideoNotFound] = useState(false);

  return (
    <Styled.Wrapper>
      {
        (title || step) && (
          <BigTitle>
            {title || GAME_STEP_TITLES[step]}
          </BigTitle>
        )
      }
      <Styled.Content>
        {
          (!isEmpty(vimeoId) && !videoNotFound)
            ? (
              <VimeoPlayer
                videoId={vimeoId}
                autoplay
                onError={() => setVideoNotFound(true)}
              />
            )
            : <div dangerouslySetInnerHTML={{ __html: text }} />
        }
        { videoNotFound && (
          <Styled.Warning>
            <Img src={WARNING_SIGN} size={[20, 20]} />
            Sorry, but probably your Vimeo ID is incorrect
          </Styled.Warning>
        )}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* Preview type of props */

Preview.propTypes = {
  vimeoId: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  step: PropTypes.oneOf(Object.keys(GAME_STEP_TITLES)),
};

Preview.defaultProps = {
  vimeoId: null,
  title: null,
  text: null,
  step: null,
};

export default Preview;
