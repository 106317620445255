import moment from 'moment';

const getTimezoneWithZoneObject = timezone => moment.tz.zone(timezone).offsets[0];

const getTimezoneWithUtcOffset = (date, timezone) => moment.tz(date, timezone).utcOffset();

const getTimezoneOffset = (timezone, date) => {
  if (!timezone) return 0;

  return (timezone.includes('GMT') ? getTimezoneWithZoneObject(timezone) : getTimezoneWithUtcOffset(date, timezone));
};

export default getTimezoneOffset;
