/* Libs */
import styled from 'styled-components';
import { CustomScroll } from 'assets/styles/mixins';
import { COLORS_CONFIG } from 'config/index';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  
  .infinite-scroll-component {
    padding-right: 15px;
    ${() => CustomScroll({ scrollBG: COLORS_CONFIG.WHITE })}
  }
`;
