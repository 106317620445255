/* Libs */
import styled from 'styled-components';

/* Constants */
// import { FONTS_CONFIG, COLORS_CONFIG, MEDIA_QUERIES } from 'config';

// background-color: ${COLORS_CONFIG.SKY_BLUE};
// font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};

export const SettingsElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: ${({ full }) => (full ? '100%' : '47%')};
`;
