/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { AuthEntity } from '_entities';

/* Utils */

import { redirectWithError } from 'utils/custom';

/* Components */
import {
  NewPasswordForm,
  AdminAuthStruct,
  ResetPasswordStruct,
} from 'components';

/* Constants */
import { URLS_CONFIG } from 'config';
import { NOTIFICATIONS } from '_constants';

const TITLE = 'Create your password';

function NewPasswordPage({
  checkResetToken,
  location: { search },
}) {
  const [data, setData] = useState({
    token: '',
  });

  useEffect(() => {
    async function checkToken() {
      const token = new URLSearchParams(search).get('token');
      if (!token) {
        return redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.ERROR_RESET_TOKEN);
      }
      try {
        await checkResetToken({ token });
        setData({
          token,
        });
      } catch (error) {
        redirectWithError(URLS_CONFIG.auth.signin, error);
      }
    }

    checkToken();
  }, []);

  return (
    <AdminAuthStruct>
      <ResetPasswordStruct title={TITLE}>
        <NewPasswordForm token={data.token} />
      </ResetPasswordStruct>
    </AdminAuthStruct>
  );
}

NewPasswordPage.title = TITLE;
NewPasswordPage.path = URLS_CONFIG.auth.newPassword;

NewPasswordPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  checkResetToken: PropTypes.func.isRequired,
};

export default connect(null, {
  checkResetToken: AuthEntity.actions.checkResetToken,
})(NewPasswordPage);
