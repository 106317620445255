/* Libs */
import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  ExportsList,
  Button,
} from 'components';

/* Hooks */

import {
  useOutsideClickHook,
  useToggleBooleanHook,
} from 'hooks';

/* Styles */

import * as Styled from './styles';

function ExportsMenu({ actions }) {
  const [isOpened, toggle] = useToggleBooleanHook(false);

  const menuRef = useRef(null);
  useOutsideClickHook(() => toggle(false), menuRef);

  return (
    <Styled.Wrapper
      menuIsOpened={isOpened}
      onClick={toggle}
      ref={menuRef}
    >
      <Button>
        Export Report
      </Button>
      { isOpened && (
        <ExportsList>
          {
            actions.map(({ text, onClick }) => (
              <Styled.Button key={text} onClick={onClick}>{text}</Styled.Button>
            ))
          }
        </ExportsList>
      )}
    </Styled.Wrapper>
  );
}

/* ExportsMenu type of props */

ExportsMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

/* ExportsMenu default props */

ExportsMenu.defaultProps = {};

export default memo(ExportsMenu);
