import { COMMON_ERRORS } from '_constants/errors';
import { NOTIFICATIONS } from '_constants';
import { isEmpty, isValidPassword } from 'utils/validation';

export default ({
  value, name, customErrors = {}, initial = false,
}) => {
  let error = (isEmpty(value) && COMMON_ERRORS.isRequired(name))
    || (!isValidPassword(value) && NOTIFICATIONS.ERROR_PASSWORD_DOES_NOT_MEET_REQUIREMENTS);
  error = error && initial ? null : error;
  return error;
};
