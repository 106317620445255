/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const Cell = styled.div`
  max-width: 100%;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Time = styled.div``;

export const Finish = styled.div`
  font-size: 12px;
`;
