/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/* Styles */
import * as Styled from './styles';

// ----------------
function LinkTo({
  children, text, size, to, style, disabled,
}) {
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };
  return (
    <Styled.LinkTo size={size} style={style} disabled={disabled}>
      <Link to={to} onClick={handleClick}>
        {text && <Styled.SpanText size={size}>{text}</Styled.SpanText>}
        {children}
      </Link>
    </Styled.LinkTo>
  );
}

// Type of props
LinkTo.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  text: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big']),
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

// Default value for props
LinkTo.defaultProps = {
  size: 'small',
  disabled: false,
};

export default memo(LinkTo);
