/* Libs */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import {
  GameDefEntity,
} from '_entities';

/* Utils */

import { notification } from 'utils/services';
import {
  isNull,
} from 'utils/validation';
import {
  deepCopyObject,
  getError,
  redirect,
} from 'utils/custom';

/* Components */

import {
  GameDefinitionForm,
} from 'components';

/* Config */

import { URLS_CONFIG } from 'config';

/* Constants */

import { NOTIFICATIONS, GAME_DEF_FORM, GAME_DEF_FORM_DATA } from '_constants';
import { isEqual } from 'lodash';


const DEFAULT_FORM_DATA = Object.freeze({
  data: { ...GAME_DEF_FORM_DATA.DEFAULT_DATA },
  errors: { ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS },
});

const PAGE_TITLE = 'Add Game Definition Template';

const AddGameDefTemplate = ({
  createCompanyGameDef,
}) => {
  const [formData, setFormData] = useState({ ...deepCopyObject(DEFAULT_FORM_DATA) });
  const [loading, setLoading] = useState(false);

  const formChanged = useMemo(() => {
    if (DEFAULT_FORM_DATA.data.is_final_code_only && formData.data.is_final_code_only) {
      return !isEqual({
        ...deepCopyObject(DEFAULT_FORM_DATA.data),
        questions: null,
      }, {
        ...formData.data,
        questions: null,
      });
    }
    return !isEqual(DEFAULT_FORM_DATA.data, formData.data);
  }, [formData.data]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const requestedData = {
        ...formData.data,
        company: null,
      };
      if (requestedData.is_final_code_only) {
        delete requestedData.questions;
      }
      if (isNull(requestedData.game_def_template)) {
        delete requestedData.game_def_template;
      }
      await createCompanyGameDef(requestedData);
      redirect(URLS_CONFIG.admin.templates, NOTIFICATIONS.SUCCESS_ADD_GAME_DEF_TEMPLATE);
    } catch (error) {
      const newError = getError(error);
      if (error.response.data.name) {
        setFormData(prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            name: newError,
          },
        }));
      }
      notification.error(newError);
      setLoading(false);
    }
  }, [setLoading, formData.data, createCompanyGameDef]);

  return (
    <GameDefinitionForm
      title={PAGE_TITLE}
      formData={formData}
      setFormData={setFormData}
      loading={loading}
      setLoading={setLoading}
      handleSave={handleSave}
      mode={GAME_DEF_FORM.MODE.TEMPLATE}
      formChanged={formChanged}
      goBack={{
        enabled: true,
        text: 'Back to templates',
        href: URLS_CONFIG.admin.templates,
      }}
    />
  );
};

/* Page Url */

AddGameDefTemplate.path = URLS_CONFIG.admin.addTemplate;

/* Page Title */

AddGameDefTemplate.title = PAGE_TITLE;

AddGameDefTemplate.propTypes = {
  createCompanyGameDef: PropTypes.func.isRequired,
};

export default connect(null, {
  createCompanyGameDef: GameDefEntity.actions.createCompanyGameDef,
})(AddGameDefTemplate);
