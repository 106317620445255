import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { merge, uniq } from 'lodash';
import { validateText } from 'utils/validation/fields';
import { isValidEmail } from 'utils/validation';
import { notification } from 'utils/services';
import { removeAllSpaces } from 'utils/custom';
import { ShareLink, Textarea, Button } from 'components';

import * as Styled from './styles';
// import email from 'utils/validation/fields/email';

const DEFAULT_DATA = {
  emails: '',
  // It is temporary unused field
  // message: '',
};

const DEFAULT_ERRORS = {
  emails: null,
  // It is unused code
  // message: null,
};

export default function InviteParticipantsSection({
  isLinkOpen,
  link,
  campaignName,
  onSubmit,
  onClose,
}) {
  const [data, changeData] = useState(DEFAULT_DATA);
  const [errors, setErrors] = useState(DEFAULT_ERRORS);
  const [loading, setLoading] = useState(false);
  const handleChange = ({ target: { name, value } }) => {
    changeData({
      ...data,
      [name]: value,
    });
    setErrors(prev => ({
      ...prev,
      [name]: validateText({ name, value, required: false }),
    }));
  };

  const handleEmailInput = ({ target: { name, value } }) => handleChange({ target: { name, value: removeAllSpaces(value) } });

  const handleSubmit = async () => {
    // TODO: think about more simple template
    const emails = uniq(
      data.emails
        .replace(/\r?\n|\r/g, ' ')
        .replace(/\s\s+/g, ' ')
        .trim()
        .split(' '),
    );
    const invalidEmail = emails.find(email => !isValidEmail(email));

    if (invalidEmail) {
      notification.error(`Error! ${invalidEmail} is invalid email!`);
      return;
    }

    const requsetData = merge(
      {},
      // It is unused code
      // data.message ? { participants_email_message: data.message } : {},
      emails.length ? { participants_emails: emails } : {},
    );

    setLoading(true);
    await onSubmit(requsetData);
    setLoading(false);
  };

  return (
    <Styled.Invite>
      {isLinkOpen && <ShareLink link={link} />}
      <Styled.Campaign>
        <Styled.CampaignTitle>
          Campaign:
          <Styled.CampaignName>{ campaignName}</Styled.CampaignName>
        </Styled.CampaignTitle>
      </Styled.Campaign>
      <Textarea
        title="Add participant emails:"
        placeholder="Enter participant emails"
        name="emails"
        onChange={handleEmailInput}
        value={data.emails}
        error={errors.emails}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
      />
      {/* It is unused code */}
      {/* <Textarea
        title="Add optional message:"
        placeholder="Enter message here"
        name="message"
        onChange={handleChange}
        value={data.message}
        error={errors.message}
      /> */}
      <Styled.Actions>
        <Button
          onClick={handleSubmit}
          disabled={!data.emails || loading}
          isLoading={loading}
        >
          Send invite
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </Styled.Actions>
    </Styled.Invite>
  );
}

InviteParticipantsSection.propTypes = {
  isLinkOpen: PropTypes.bool,
  link: PropTypes.string,
  campaignName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

InviteParticipantsSection.defaultProps = {
  isLinkOpen: true,
  link: null,
  campaignName: 'No campaign name',
};
