/* Fonst config */
const APP_LANGUAGE = 'en';

const FONST_CHOOSE_CONFIG = {
  en: {
    mainTextFontRegular: 'GothamBook',
    mainTextFontBold: 'GothamBold',
    mainTitleFontLight: 'GothamBook',
    mainTitleFontBold: 'GothamBold',
  },
};

const FONTS_CONFIG = {
  MAIN_TEXT_FONT_REGULAR: FONST_CHOOSE_CONFIG[APP_LANGUAGE].mainTextFontRegular,
  MAIN_TITLE_FONT_LIGHT: FONST_CHOOSE_CONFIG[APP_LANGUAGE].mainTitleFontLight,
  MAIN_TITLE_FONT_BOLD: FONST_CHOOSE_CONFIG[APP_LANGUAGE].mainTitleFontBold,
  MAIN_TEXT_FONT_BOLD: FONST_CHOOSE_CONFIG[APP_LANGUAGE].mainTextFontBold,
};

export default FONTS_CONFIG;
