import styled from 'styled-components';

import { Wrapper as AddCircleWrapper } from 'components/ui/AddCircle/styles';
import { Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import { Pagination } from 'components/ui/Pagination/styles';


export const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  & > div {
    flex-grow: 1;
  }
  ${AddCircleWrapper} {
    margin-right: 66px;
  }
  ${SelectWrapper} {
    width: 230px;
  }
  ${Pagination} {
    margin-top: 40px;
  }
`;
