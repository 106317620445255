/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { QuestionStatisticListItem, EmptyBlockText } from 'components';

/* Styles */
import * as Styled from './styles';

function QuestionStatisticList({ data }) {
  if (!data.length) {
    return <EmptyBlockText />;
  }

  return (
    <Styled.Wrapper>
      {data.map((item, i) => (
        <QuestionStatisticListItem
          key={i}
          label={item.question}
          value={item.percentage}
        />
      ))}
    </Styled.Wrapper>
  );
}

/* QuestionStatisticList type of props */

QuestionStatisticList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

/* QuestionStatisticList default props */

QuestionStatisticList.defaultProps = {
  data: [],
};

export default QuestionStatisticList;
