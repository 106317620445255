/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* utils */

import { hasPermission } from 'utils/custom';

/* Components */

import {
  Card,
  LicensingTable,
  EditContractModal,
} from 'components';

/* Constants */

import { TABLES_CONFIG } from 'config';
import { PERMISSION_NAMES } from '_constants';

/* Styles */

import * as Styled from './styles';

function LicensingCard({
  data,
  isImpersonatedAdmin,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Styled.Wrapper>
      <Card
        title="Licensing"
        actions={hasPermission(PERMISSION_NAMES.EDIT_LICENSING) && !isImpersonatedAdmin
          ? [{
            label: 'Edit',
            action: () => setIsOpen(true),
          }]
          : []
        }
      >
        <LicensingTable
          data={[data]}
          tableSize={TABLES_CONFIG.SIZE.S}
        />
      </Card>
      <EditContractModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        contract={data}
      />
    </Styled.Wrapper>
  );
}

/* LicensingCard type of props */

LicensingCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  isImpersonatedAdmin: PropTypes.bool,
};

LicensingCard.defaultProps = {
  isImpersonatedAdmin: false,
};

export default LicensingCard;
