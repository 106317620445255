/* Libs */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import moment from 'moment';

/* Actions */

import { GameEntity, StaticDataEntity } from '_entities';

/* Utils */

import { redirectWithError, getGameLink } from 'utils/custom';

/* Components */

import {
  ContainerLoader,
  GameSection,
  Watermark,
} from 'components';

/* Constants */

import { URLS_CONFIG, GAME_CONFIG } from 'config';

const PAGE_TITLE = 'Game';

function Game({
  loaded,
  step,
  getGameInfo,
  updateGameTime,
  timeLeft,
  token,
  isDarkMode,
  logo,
  setStaticDataDarkMode,
  match: { params: { token: paramToken } },
  history: { replace },
}) {
  const isMounted = useRef(false);

  const { current: pingServer } = useRef(throttle(updateGameTime, 30000));
  useEffect(() => {
    if (!GAME_CONFIG.PINGABLE_STEPS.includes(step)) return;
    pingServer(timeLeft);
    const timerId = setInterval(() => pingServer(timeLeft), 1000);
    return () => clearInterval(timerId);
  }, [timeLeft, step]);

  useEffect(() => {
    async function loadGameInfo() {
      try {
        await getGameInfo(paramToken);
        isMounted.current = true;
      } catch (error) {
        redirectWithError(URLS_CONFIG.auth.signin, error);
      }
    }
    loadGameInfo();
  }, []);

  useEffect(() => {
    if (!isDarkMode) return;
    setStaticDataDarkMode(isDarkMode);
  }, [isDarkMode]);


  useEffect(() => {
    if (isMounted.current) {
      replace(getGameLink(token));
    }
  }, [token]);

  function manageDisplay() {
    switch (step) {
      case GAME_CONFIG.STEP.INTRODUCTION:
      case GAME_CONFIG.STEP.INSTRUCTIONS:
      case GAME_CONFIG.STEP.STORYLINE:
        return <GameSection.BeforeGame step={step} />;
      case GAME_CONFIG.STEP.GAME_PLAY:
        return <GameSection.GamePlay step={step} />;
      case GAME_CONFIG.STEP.GAME_FAILED:
      case GAME_CONFIG.STEP.GAME_SUCCESS:
        return <GameSection.EndGame step={step} />;
      case GAME_CONFIG.STEP.DEBRIEFING:
      case GAME_CONFIG.STEP.RESET:
        return <GameSection.AfterGame step={step} />;
      default:
        return <GameSection.BeforeGame />;
    }
  }

  return loaded ? (
    <>
      {manageDisplay()}
      <Watermark logo={logo} isDarkMode={isDarkMode} />
    </>
  ) : <ContainerLoader />;
}

/* Game type of props */

Game.propTypes = {
  getGameInfo: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  timeLeft: PropTypes.instanceOf(moment).isRequired,
  updateGameTime: PropTypes.string.isRequired,
};

/* Page Url */

Game.path = URLS_CONFIG.game.game;

/* Page Title */

Game.title = PAGE_TITLE;

export default connect(({ game }) => ({
  timeLeft: game.timeLeft,
  loaded: game.loaded,
  token: game.token,
  step: game.step,
  isDarkMode: game.gameDef.is_dark_mode,
  logo: game.logo,
}), {
  getGameInfo: GameEntity.actions.getGameInfo,
  updateGameTime: GameEntity.actions.updateGameTime,
  setStaticDataDarkMode: StaticDataEntity.actions.setDarkMode,
})(Game);
