import moment from 'moment';
/**
 * @param {String} timeDifference  - time which we
 * wanna ad in one of next formats 'HH:mm:ss' 'mm:ss'
 */
function getTimeIn(timeDifference) {
  return moment().add({
    hours: timeDifference.hours(),
    minutes: timeDifference.minutes(),
    seconds: timeDifference.seconds(),
  }).valueOf();
}

export default getTimeIn;
