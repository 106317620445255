/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import * as Styled from './styles';


export default function SettingsComponentsWrapper({
  components,
}) {
  return (
    <Styled.SettingsComponentsWrapper multiple={components.length > 1}>
      {components}
    </Styled.SettingsComponentsWrapper>
  );
}

// Type of props
SettingsComponentsWrapper.propTypes = {
};

// Default value for props
SettingsComponentsWrapper.defaultProps = {
};
