export function saveFastSessionData(data) {
  localStorage.setItem('FAST_SESSION_DATA', JSON.stringify(data));
}

export function getFastSessionData() {
  return JSON.parse(localStorage.getItem('FAST_SESSION_DATA'));
}

export function removeFastSessionData() {
  localStorage.removeItem('FAST_SESSION_DATA');
}

export default {
  saveFastSessionData,
  getFastSessionData,
  removeFastSessionData,
};
