/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import { BREAKPOINTS } from '_constants';
import { COLORS_CONFIG } from 'config';

/* Styles */

import { Wrapper as ToggleWrapper } from 'components/ui/ToggleBar/styles';
import { Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import { Wrapper as SearchInputWrapper } from 'components/ui/SearchInput/styles';
import { CustomScroll } from 'assets/styles/mixins';

export const Sidebar = styled.div`
  padding: 40px 32px 0;
  background-color: ${COLORS_CONFIG.WHITE};
  transition: left 300ms;
  position: absolute;
  height: 100%;
  overflow: auto;
  min-width: 430px;
  max-width: 430px;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      padding: 20px 25px;
  }
  
   ${CustomScroll}
`;

export const Content = styled.div`
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  z-index: 2;
  overflow: auto;
  
  ${CustomScroll}
  
  .weekCalendar {
    padding-top: 48px;
    min-height: 100%;

    &__headerWrapper,
    &__headerColumn,
    &__scaleCell,
    &__scaleHeader,
    &__scaleColumn {
      background: none;
      border: none;
    }

    &__scaleHeader,
    &__header {
      height: 48px;
      line-height: 48px;
    }

    &__scaleHeader {
      background: ${COLORS_CONFIG.SILVER};
    }

    &__scaleColumn {
      padding-top: 48px;
    }

    &__scaleCell {
      opacity: 0;

      span {
        position: relative;
        top: -50%;
      }

      &:nth-child(6n + 1) {
        opacity: 1;
      }

      &:first-child {
        opacity: 0;
      }
    }

    .calendarBody {
      border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};

      &__column {

        &:last-child {
          border: none;
        }
      }

      &__cell {
        pointer-events: none;
        background: none;
        border-color: ${COLORS_CONFIG.TRANSPARENT};

        &:nth-child(6n) {
          border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
        }

        &:last-child {
          border-color: ${COLORS_CONFIG.TRANSPARENT};
        }
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    .weekCalendar {
      padding-left: 90px;

      &__header {
        padding-left: 90px;
      }

      &__scaleColumn,
      &__scaleHeader {
        width: 90px;
      }
    }
  }
`;

export const Page = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  position: relative;
  background-color: ${COLORS_CONFIG.SILVER};
  overflow: auto;
  padding-left: 432px;
  transition: padding 300ms;
  
  ${CustomScroll}

  & ${Sidebar} {
    left: 0;
    flex-shrink: 0;
  }

  & ${Content} {
    flex: 5;
  }
`;

export const Controls = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & ${ToggleWrapper} {
    height: 32px;
    margin: 0 100px;
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
    margin: 0 0 0 auto;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between; 
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
  height: 100px;

  & ${SearchInputWrapper} {
    max-width: 336px;
    margin-right: 10px;
    border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
    transition: box-shadow 100ms, border 100ms;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
      box-shadow: 0 0 1px ${COLORS_CONFIG.SKY_BLUE};
    }
    @media screen and (max-width: ${BREAKPOINTS.LAPTOP}) {
      & > input {
        font-size: 14px;
      }
    }
  }

  & ${SelectWrapper} {
    max-width: 336px;

    & .ReactSelect {
      &--is-disabled {
        opacity: 0.5;
      }

      &__control {
        min-height: 35px;
        border-radius: 0;
        @media screen and (max-width: ${BREAKPOINTS.LAPTOP}) {
          font-size: 14px;
        }
      }

      &__value-container {
        padding: 0;
        padding-left: 11px;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    & ${Controls} {
      &:last-child {
        flex-direction: column;
        margin: 20px 0 0;

        & ${Row} {
          &:last-child {
            margin: 10px 0 0;
          }
        }
      }
    }
  }
`;

export const Date = styled.div`
  width: 30%;
  min-width: 300px;
`;

export const EventContainer = styled.div`
  padding: 3px 0;
  width: auto;
  margin: 0 3px;
  height: 100%;
  cursor: pointer;
  background: ${COLORS_CONFIG.ORANGE};
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.2;
  overflow: hidden;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$30};
  
  ${({ disabled }) => disabled && css`
     background: ${COLORS_CONFIG.GREY_CUSTOM_6};
  `}
`;

export const CalendarWrapper = styled.div``;
