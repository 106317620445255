import { redirect } from 'utils/custom';
import { tokenService } from 'utils/services';
import { URLS_CONFIG } from 'config';
import * as TYPES from './Auth.types';

export const setToken = data => ({
  type: TYPES.SET_TOKEN,
  payload: data,
});

export const startWithoutToken = () => ({
  type: TYPES.START_WITHOUT_TOKEN,
});

export const updateToken = () => ({
  types: [TYPES.UPDATE_TOKEN, TYPES.UPDATE_TOKEN_SUCCESS, TYPES.UPDATE_TOKEN_FAIL],
  promise: client => client.post('/auth/api-token-refresh/', { token: tokenService.getToken() }),
});

export const getInitialUserData = () => ({
  types: [
    TYPES.GET_INITIAL_USER_DATA,
    TYPES.GET_INITIAL_USER_DATA_SUCCESS,
    TYPES.GET_INITIAL_USER_DATA_FAIL,
  ],
  promise: client => client.get('/users/me/'),
});

export const signinAdmin = credentials => signin({ credentials, url: '/auth/login/' });

export const signin = ({
  credentials,
  url,
}) => ({
  types: [TYPES.SIGNIN, TYPES.SIGNIN_SUCCESS, TYPES.SIGNIN_FAIL],
  promise: client => client.post(url, credentials),
});

export const signup = data => ({
  types: [TYPES.SIGNUP, TYPES.SIGNUP_SUCCESS, TYPES.SIGNUP_FAIL],
  promise: client => client.post('/auth/signup', credentials),
});

export const resetPassword = email => ({
  types: [TYPES.RESET_PASSWORD, TYPES.RESET_PASSWORD_SUCCESS, TYPES.RESET_PASSWORD_FAIL],
  promise: client => client.post('/auth/password/reset/', { email }),
});
export const confirmNewPassword = data => ({
  types: [TYPES.CONFIRM_NEW_PASSWORD, TYPES.CONFIRM_NEW_PASSWORD_SUCCESS, TYPES.CONFIRM_NEW_PASSWORD_FAIL],
  promise: client => client.post('/auth/set-password/', data),
});
export const checkResetToken = data => ({
  types: [TYPES.CHECK_RESET_TOKEN, TYPES.CHECK_RESET_TOKEN_SUCCESS, TYPES.CHECK_RESET_TOKEN_FAIL],
  promise: client => client.post('/auth/check-set-password-token/', data),
});

export const confirmAccount = token => ({
  types: [TYPES.CONFIRM_ACCOUNT, TYPES.CONFIRM_ACCOUNT_SUCCESS, TYPES.CONFIRM_ACCOUNT_FAIL],
});

export const logout = (withRedirect = true) => {
  withRedirect && redirect(URLS_CONFIG.auth.signin);
  return {
    type: TYPES.LOGOUT,
  };
};

export const changeUserInfo = data => ({
  type: TYPES.CHANGE_USER_INFO,
  payload: data,
});
