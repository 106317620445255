import queryString from 'query-string';
import { store } from 'lib';
import { pickBy, isUndefined } from 'lodash';
import {
  USERS_TABLE,
  CAMPAIGNS_LIST,
  GAME_SESSIONS_TABLE,
  STORYLINES_LIST,
  LOCATIONS_LIST,
  PARTICIPANTS_TABLE,
} from 'config';
import { reportsFilterDataToQueryParams } from 'utils/transformers';

import { getStaticData, getSessionStatus } from 'utils/custom';
import { SESSION_STATUSES } from '_constants';
import * as TYPES from './AdminCompany.types';

export const getCampaigns = ({
  campaign,
  isActive,
  company,
  page = 1,
  perPage = CAMPAIGNS_LIST.PAGINATION.PER_PAGE,
  withoutStoreChanging = false,
  withSessionsCompleted = false,
  fromDate,
  toDate,
} = {}) => {
  const params = queryString.stringify(
    pickBy(
      {
        name: campaign,
        is_active: isActive,
        company,
        offset: (page - 1) * perPage,
        limit: perPage,
        from_date: fromDate,
        to_date: toDate,
        with_sessions_completed: withSessionsCompleted,
      },
      item => !isUndefined(item),
    ),
  );

  return {
    types: withoutStoreChanging
      ? [
        TYPES.FIND_CAMPAIGNS,
        TYPES.FIND_CAMPAIGNS_SUCCESS,
        TYPES.FIND_CAMPAIGNS_FAIL,
      ]
      : [
        TYPES.GET_CAMPAIGNS,
        TYPES.GET_CAMPAIGNS_SUCCESS,
        TYPES.GET_CAMPAIGNS_FAIL,
      ],
    promise: client => client.get(`/campaigns/${params ? `?${params}` : ''}`),
  };
};

export const getCampaign = ({ id, withoutStoreChanging = false }) => ({
  types: withoutStoreChanging
    ? [
      TYPES.FIND_CAMPAIGN,
      TYPES.FIND_CAMPAIGN_SUCCESS,
      TYPES.FIND_CAMPAIGN_FAIL,
    ]
    : [TYPES.GET_CAMPAIGN, TYPES.GET_CAMPAIGN_SUCCESS, TYPES.GET_CAMPAIGN_FAIL],
  promise: client => client.get(`/campaigns/${id}/`),
});

export const getCampaignWithPosition = ({ id }) => ({
  types: [
    TYPES.GET_CAMPAIGN_WITH_POSITION,
    TYPES.GET_CAMPAIGN_WITH_POSITION_SUCCESS,
    TYPES.GET_CAMPAIGN_WITH_POSITION_FAIL,
  ],
  promise: client => client.get(`/campaigns/${id}/position/`),
});

// TODO: think about this loading in Calendar and Campaigns tabs.
// TODO: think about this loading all inacvite campaigns.
export const getAllActiveCampaigns = ({ limit, company }) => {
  const params = queryString.stringify({
    limit,
    company,
    is_active: true,
  });

  return {
    types: [
      TYPES.GET_ALL_ACTIVE_CAMPAIGNS,
      TYPES.GET_ALL_ACTIVE_CAMPAIGNS_SUCCESS,
      TYPES.GET_ALL_ACTIVE_CAMPAIGNS_FAIL,
    ],
    promise: client => client.get(`/campaigns/${params ? `?${params}` : ''}`),
  };
};

export const updateCampaign = ({ id, ...data }) => ({
  types: [
    TYPES.UPDATE_CAMPAIGN,
    TYPES.UPDATE_CAMPAIGN_SUCCESS,
    TYPES.UPDATE_CAMPAIGN_FAIL,
  ],
  promise: client => client.put(`/campaigns/${id}/`, data),
  id,
});

export const deleteCampaign = id => ({
  types: [
    TYPES.DELETE_CAMPAIGN,
    TYPES.DELETE_CAMPAIGN_SUCCESS,
    TYPES.DELETE_CAMPAIGN_FAIL,
  ],
  promise: client => client.delete(`/campaigns/${id}/`),
});

export const getGameSessions = ({
  campaign,
  kit,
  location,
  fromDate,
  toDate,
  isCompleted,
  status,
  offset,
  limit,
  deleted,
} = {}) => {
  const params = queryString.stringify(
    pickBy(
      {
        campaign,
        kit,
        location,
        from_date: fromDate,
        to_date: toDate,
        is_completed: isCompleted,
        status,
        offset,
        limit,
        deleted,
      },
      item => !isUndefined(item),
    ),
    { arrayFormat: 'comma' },
  );

  return {
    types: [
      TYPES.GET_GAME_SESSIONS,
      TYPES.GET_GAME_SESSIONS_SUCCESS,
      TYPES.GET_GAME_SESSIONS_FAIL,
    ],
    promise: client => client.get(`/game-sessions/${params ? `?${params}` : ''}`),
    payload: {
      isScheduledSessions: status === getStaticData('gameSessionStatuses').scheduled,
      campaignId: campaign,
    },
  };
};

export const getGameSession = id => ({
  types: [
    TYPES.GET_GAME_SESSION,
    TYPES.GET_GAME_SESSION_SUCCESS,
    TYPES.GET_GAME_SESSION_FAIL,
  ],
  promise: client => client.get(`/game-sessions/${id}/`),
});

export const addGameSession = requestData => ({
  types: [
    TYPES.ADD_GAME_SESSION,
    TYPES.ADD_GAME_SESSION_SUCCESS,
    TYPES.ADD_GAME_SESSION_FAIL,
  ],
  promise: client => client.post('/game-sessions/', requestData),
});

export const editGameSession = ({ id, ...requestData }) => ({
  types: [
    TYPES.EDIT_GAME_SESSION,
    TYPES.EDIT_GAME_SESSION_SUCCESS,
    TYPES.EDIT_GAME_SESSION_FAIL,
  ],
  promise: client => client.put(`/game-sessions/${id}/`, requestData),
  payload: {
    isParticipantsListChanged: requestData.participants_emails
      || requestData.participants_ids_to_delete
      || requestData.auto_signup,
  },
});

export const deleteGameSession = ({ sessionId, campaignId, status }) => {
  const statusName = getSessionStatus(status);
  return {
    types: [
      TYPES.DELETE_GAME_SESSION,
      TYPES.DELETE_GAME_SESSION_SUCCESS,
      TYPES.DELETE_GAME_SESSION_FAIL,
    ],
    promise: client => client.delete(`/game-sessions/${sessionId}/`),
    payload: { status: (statusName && statusName) || SESSION_STATUSES.NAME.SCHEDULED },
    campaignId,
    sessionId,
  };
};

export const addDayWithGameSession = date => ({
  type: TYPES.ADD_DAY_WITH_GAME_SESSION,
  payload: { date },
});

export const getDaysWithGameSession = ({
  campaign,
  fromDate,
  toDate,
  participant,
  status,
} = {}) => {
  const params = queryString.stringify({
    campaign,
    from_date: fromDate,
    to_date: toDate,
    participant,
    status,
  });

  return {
    types: [
      TYPES.GET_DAYS_WITH_GAME_SESSION,
      TYPES.GET_DAYS_WITH_GAME_SESSION_SUCCESS,
      TYPES.GET_DAYS_WITH_GAME_SESSION_FAIL,
    ],
    promise: client => client.get(
      `/game-sessions/existing-by-days/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getKits = ({
  company,
  storyline,
  kit,
  limit = 1,
  withoutStoreChanging = false,
}) => {
  const getParams = limit => queryString.stringify({
    company,
    storyline,
    kit_id: kit,
    limit,
  });

  const types = withoutStoreChanging
    ? [TYPES.FIND_KITS, TYPES.FIND_KITS_SUCCESS, TYPES.FIND_KITS_FAIL]
    : [TYPES.GET_KITS, TYPES.GET_KITS_SUCCESS, TYPES.GET_KITS_FAIL];


  const promise = client => client
    .get(`/kits/${getParams(limit) ? `?${getParams(limit)}` : ''}`)
    .then(res => (res.count > limit ? client.get(`/kits/${`?${getParams(res.count)}`}`) : res));

  return { types, promise };
};

export const getKit = ({ id }) => ({
  types: [TYPES.GET_KIT, TYPES.GET_KIT_SUCCESS, TYPES.GET_KIT_FAIL],
  promise: client => client.get(`/kits/${id}/`),
});

export const removeKit = id => ({
  type: TYPES.REMOVE_KIT,
  payload: { id },
});

export const getGameDefs = ({
  company,
  storyline,
  gameDef,
  limit = 10,
  withoutStoreChanging = false,
}) => {
  const params = queryString.stringify({
    company,
    storyline,
    name: gameDef,
    limit,
  });


  return {
    types: withoutStoreChanging
      ? [
        TYPES.FIND_GAME_DEFS,
        TYPES.FIND_GAME_DEFS_SUCCESS,
        TYPES.FIND_GAME_DEFS_FAIL,
      ]
      : [
        TYPES.GET_GAME_DEFS,
        TYPES.GET_GAME_DEFS_SUCCESS,
        TYPES.GET_GAME_DEFS_FAIL,
      ],
    promise: client => client.get(`/game-defs/${params ? `?${params}` : ''}`),
  };
};

export const getGameDef = ({ id }) => ({
  types: [
    TYPES.GET_GAME_DEF,
    TYPES.GET_GAME_DEF_SUCCESS,
    TYPES.GET_GAME_DEF_FAIL,
  ],
  promise: client => client.get(`/game-defs/${id}/`),
});

export const removeGameDef = id => ({
  type: TYPES.REMOVE_GAME_DEF,
  payload: id,
});

export const getStorylines = ({
  storyline,
  company,
  page = 1,
  perPage = STORYLINES_LIST.PAGINATION.PER_PAGE,
} = {}) => {
  const params = queryString.stringify({
    name: storyline,
    company,
    offset: (page - 1) * perPage,
    limit: perPage,
  });

  return {
    types: [
      TYPES.GET_STORYLINES,
      TYPES.GET_STORYLINES_SUCCESS,
      TYPES.GET_STORYLINES_FAIL,
    ],
    promise: client => client.get(`/storylines/${params ? `?${params}` : ''}`),
  };
};

export const getLocations = ({
  location,
  page = 1,
  perPage = LOCATIONS_LIST.PAGINATION.PER_PAGE,
  withoutStoreChanging = false,
} = {}) => {
  const params = queryString.stringify({
    location,
    company: store.getState().auth.company,
    offset: (page - 1) * perPage,
    limit: perPage,
  });

  return {
    types: withoutStoreChanging
      ? [
        TYPES.FIND_LOCATIONS,
        TYPES.FIND_LOCATIONS_SUCCESS,
        TYPES.FIND_LOCATIONS_FAIL,
      ]
      : [
        TYPES.GET_LOCATIONS,
        TYPES.GET_LOCATIONS_SUCCESS,
        TYPES.GET_LOCATIONS_FAIL,
      ],
    promise: client => client.get(`/locations/${params ? `?${params}` : ''}`),
  };
};

export const getLocation = id => ({
  types: [
    TYPES.GET_LOCATION,
    TYPES.GET_LOCATION_SUCCESS,
    TYPES.GET_LOCATION_FAIL,
  ],
  promise: client => client.get(`/locations/${id}/`),
});

export const addNewLocation = location => ({
  types: [
    TYPES.ADD_NEW_LOCATION,
    TYPES.ADD_NEW_LOCATION_SUCCESS,
    TYPES.ADD_NEW_LOCATION_FAIL,
  ],
  promise: client => client.post('/locations/', { location }),
});

export const addCampaign = data => ({
  types: [
    TYPES.ADD_CAMPAIGN,
    TYPES.ADD_CAMPAIGN_SUCCESS,
    TYPES.ADD_CAMPAIGN_FAIL,
  ],
  promise: client => client.post('/campaigns/', data),
});

export const getCompany = id => ({
  types: [TYPES.GET_COMPANY, TYPES.GET_COMPANY_SUCCESS, TYPES.GET_COMPANY_FAIL],
  promise: client => client.get(`/companies/${id}/`),
});

export const updateCompany = ({ id, ...data }) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return {
    types: [
      TYPES.UPDATE_COMPANY,
      TYPES.UPDATE_COMPANY_SUCCESS,
      TYPES.UPDATE_COMPANY_FAIL,
    ],
    promise: client => client.put(`/companies/${id}/`, formData),
  };
};

export const resetCompany = () => ({
  type: TYPES.RESET_COMPANY,
});

export const downloadLeaderboard = ({ campaign, token } = {}) => {
  const params = queryString.stringify({ campaign, token });

  return {
    types: [
      TYPES.DOWNLOAD_LEADERBOARD,
      TYPES.DOWNLOAD_LEADERBOARD_SUCCESS,
      TYPES.DOWNLOAD_LEADERBOARD_FAIL,
    ],
    promise: client => client.get(
      `/game-sessions/result/download/${params ? `?${params}` : ''}`,
    ),
  };
};

export const downloadUsers = ({ company } = {}) => {
  const params = queryString.stringify({
    company,
  });

  return {
    types: [
      TYPES.DOWNLOAD_USERS,
      TYPES.DOWNLOAD_USERS_SUCCESS,
      TYPES.DOWNLOAD_USERS_FAIL,
    ],
    promise: client => client.get(`/users/download/${params ? `?${params}` : ''}`),
  };
};

export const downloadSession = id => ({
  types: [
    TYPES.DOWNLOAD_SESSION,
    TYPES.DOWNLOAD_SESSION_SUCCESS,
    TYPES.DOWNLOAD_SESSION_FAIL,
  ],
  promise: client => client.get(`/game-sessions/result/${id}/download-game-zip/`),
});

export const getUsers = ({
  company,
  isActive,
  name,
  campaign,
  page = 1,
  perPage = USERS_TABLE.PAGINATION.PER_PAGE,
} = {}) => {
  const params = queryString.stringify({
    is_active: isActive,
    name,
    company,
    campaign,
    offset: (page - 1) * perPage,
    limit: perPage,
  });

  return {
    types: [TYPES.GET_USERS, TYPES.GET_USERS_SUCCESS, TYPES.GET_USERS_FAIL],
    promise: client => client.get(`/users/${params ? `?${params}` : ''}`),
  };
};

export const addUser = data => ({
  types: [TYPES.ADD_USER, TYPES.ADD_USER_SUCCESS, TYPES.ADD_USER_FAIL],
  promise: client => client.post('/users/', data),
});

export const updateUser = ({ id, ...data }) => ({
  types: [TYPES.UPDATE_USER, TYPES.UPDATE_USER_SUCCESS, TYPES.UPDATE_USER_FAIL],
  promise: client => client.put(`/users/${id}/`, data),
  id,
});

export const deleteUser = id => ({
  types: [TYPES.DELETE_USER, TYPES.DELETE_USER_SUCCESS, TYPES.DELETE_USER_FAIL],
  promise: client => client.delete(`/users/${id}/`),
  id,
});

export const resendEmail = data => ({
  types: [
    TYPES.RESEND_EMAIL,
    TYPES.RESEND_EMAIL_SUCCESS,
    TYPES.RESEND_EMAIL_FAIL,
  ],
  promise: client => client.post('/users/resend-invite/', data),
});

export const getLeaderboard = ({
  // campaign,
  token,
  location,
  locations = [],
  page = 1,
  perPage = GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE,
  participant,
  from_date,
  to_date,
  team_name,
  with_first_answered_percents,
} = {}) => {
  const params = queryString.stringify(
    pickBy(
      {
        // campaign,
        token,
        location,
        locations,
        participant,
        from_date,
        to_date,
        team_name,
        with_first_answered_percents,
        offset: (page - 1) * perPage,
        limit: perPage,
      },
      item => !isUndefined(item),
    ),
  );

  return {
    types: [
      TYPES.GET_LEADERBOARD,
      TYPES.GET_LEADERBOARD_SUCCESS,
      TYPES.GET_LEADERBOARD_FAIL,
    ],
    promise: client => client.get(`/game-sessions/result/${params ? `?${params}` : ''}`),
  };
};

export const getMultipleGameSessions = requestData => ({
  types: [
    TYPES.GET_MULTIPLE_GAME_SESSIONS,
    TYPES.GET_MULTIPLE_GAME_SESSIONS_SUCCESS,
    TYPES.GET_MULTIPLE_GAME_SESSIONS_FAIL,
  ],
  promise: client => client.post('/game-sessions/multiple-get/', requestData),
});

export const createMultipleGameSessions = requestData => ({
  types: [
    TYPES.ADD_MULTIPLE_GAME_SESSIONS,
    TYPES.ADD_MULTIPLE_GAME_SESSIONS_SUCCESS,
    TYPES.ADD_MULTIPLE_GAME_SESSIONS_FAIL,
  ],
  promise: client => client.post('/game-sessions/multiple-create/', requestData),
  payload: {
    campaign: requestData.campaign,
  },
});

/* Reports */

export const getCampaignReportsActivity = ({ id, ...requestData }) => {
  const params = reportsFilterDataToQueryParams(requestData);

  return {
    types: [
      TYPES.GET_CAMPAIGN_REPORTS_ACTIVITY,
      TYPES.GET_CAMPAIGN_REPORTS_ACTIVITY_SUCCESS,
      TYPES.GET_CAMPAIGN_REPORTS_ACTIVITY_FAIL,
    ],
    promise: client => client.get(
      `/campaigns/${id}/reports/activity/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getCampaignReportsAnswers = ({ id, ...requestData }) => {
  const params = reportsFilterDataToQueryParams(requestData);

  return {
    types: [
      TYPES.GET_CAMPAIGN_REPORTS_ANSWERS,
      TYPES.GET_CAMPAIGN_REPORTS_ANSWERS_SUCCESS,
      TYPES.GET_CAMPAIGN_REPORTS_ANSWERS_FAIL,
    ],
    promise: client => client.get(
      `/campaigns/${id}/reports/answers/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getCampaignReportsGeneral = ({ id, ...requestData }) => {
  const params = reportsFilterDataToQueryParams(requestData);

  return {
    types: [
      TYPES.GET_CAMPAIGN_REPORTS_GENERAL,
      TYPES.GET_CAMPAIGN_REPORTS_GENERAL_SUCCESS,
      TYPES.GET_CAMPAIGN_REPORTS_GENERAL_FAIL,
    ],
    promise: client => client.get(
      `/campaigns/${id}/reports/general/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getCampaignReportsDownloadParticipants = ({ id }) => ({
  types: [
    TYPES.DOWNLOAD_CAMPAIGN_PARTICIPANTS,
    TYPES.DOWNLOAD_CAMPAIGN_PARTICIPANTS_SUCCESS,
    TYPES.DOWNLOAD_CAMPAIGN_PARTICIPANTS_FAIL,
  ],
  promise: client => client.get(`/campaigns/${id}/reports/participants/download/`),
});

export const downloadCampaignReport = ({ id, dateFrom, dateTo }) => {
  const params = reportsFilterDataToQueryParams({ dateFrom, dateTo });
  return {
    types: [
      TYPES.DOWNLOAD_CAMPAIGN_REPORT,
      TYPES.DOWNLOAD_CAMPAIGN_REPORT_SUCCESS,
      TYPES.DOWNLOAD_CAMPAIGN_REPORT_FAIL,
    ],
    promise: client => client.get(
      `/campaigns/${id}/reports/export/${params ? `?${params}` : ''}`,
    ),
  };
};


export const getCompanyReportsDownloadParticipants = ({ id }) => ({
  types: [
    TYPES.DOWNLOAD_COMPANY_PARTICIPANTS,
    TYPES.DOWNLOAD_COMPANY_PARTICIPANTS_SUCCESS,
    TYPES.DOWNLOAD_COMPANY_PARTICIPANTS_FAIL,
  ],
  promise: client => client.get(`/companies/${id}/reports/participants/download/`),
});

export const downloadCompanyReport = ({
  id,
  dateFrom,
  dateTo,
  withParticipants = false,
} = {}) => {
  const params = queryString.stringify({
    from_date: dateFrom,
    to_date: dateTo,
    with_participants: withParticipants,
  });
  return {
    types: [
      TYPES.DOWNLOAD_COMPANY_REPORT,
      TYPES.DOWNLOAD_COMPANY_REPORT_SUCCESS,
      TYPES.DOWNLOAD_COMPANY_REPORT_FAIL,
    ],
    promise: client => client.get(
      `/companies/${id}/reports/export/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getCompanyReportsGeneral = ({ id, ...requestData }) => {
  const params = reportsFilterDataToQueryParams(requestData);

  return {
    types: [
      TYPES.GET_COMPANY_REPORTS_GENERAL,
      TYPES.GET_COMPANY_REPORTS_GENERAL_SUCCESS,
      TYPES.GET_COMPANY_REPORTS_GENERAL_FAIL,
    ],
    promise: client => client.get(
      `/companies/${id}/reports/general/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getcompanyReportsActivity = ({ id, ...requestData }) => {
  const params = reportsFilterDataToQueryParams(requestData);

  return {
    types: [
      TYPES.GET_COMPANY_REPORTS_ACTIVITY,
      TYPES.GET_COMPANY_REPORTS_ACTIVITY_SUCCESS,
      TYPES.GET_COMPANY_REPORTS_ACTIVITY_FAIL,
    ],
    promise: client => client.get(
      `/companies/${id}/reports/activity/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getCompanyParticipants = ({
  id,
  email,
  page = 1,
  perPage = PARTICIPANTS_TABLE.PAGINATION.PER_PAGE,
} = {}) => {
  const params = reportsFilterDataToQueryParams({
    email,
    offset: (page - 1) * perPage,
    limit: perPage,
  });

  return {
    types: [
      TYPES.GET_COMPANY_PARTICIPANTS,
      TYPES.GET_COMPANY_PARTICIPANTS_SUCCESS,
      TYPES.GET_COMPANY_PARTICIPANTS_FAIL,
    ],
    promise: client => client.get(
      `/companies/${id}/reports/participants/${params ? `?${params}` : ''}`,
    ),
  };
};

export const getCampaignParticipants = ({
  id,
  email,
  page = 1,
  perPage = PARTICIPANTS_TABLE.PAGINATION.PER_PAGE,
} = {}) => {
  const params = reportsFilterDataToQueryParams({
    email,
    offset: (page - 1) * perPage,
    limit: perPage,
  });

  return {
    types: [
      TYPES.GET_CAMPAIGN_PARTICIPANTS,
      TYPES.GET_CAMPAIGN_PARTICIPANTS_SUCCESS,
      TYPES.GET_CAMPAIGN_PARTICIPANTS_FAIL,
    ],
    promise: client => client.get(
      `/campaigns/${id}/reports/participants/${params ? `?${params}` : ''}`,
    ),
  };
};
