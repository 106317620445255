import { get, cloneDeep } from 'lodash';

export default function removeIds(source) {
  // debugger;
  const isArray = Array.isArray(source);
  const isObject = typeof source === 'object' && source !== null;
  const hasId = get(source, 'id', false);

  if (!isArray && !hasId && !isObject) {
    return source;
  }

  const copySource = cloneDeep(source);

  if (isObject) {
    if (hasId) {
      delete copySource.id;
    }
    const keys = Object.keys(copySource);
    keys.map(key => copySource[key] = removeIds(copySource[key]));
  }

  if (isArray) {
    return copySource.map(sourceField => removeIds(sourceField));
  }
  return copySource;
}
