export const GET_COMPANY_GAME_DEF = '@PROJECT_NAME/GET_COMPANY_GAME_DEF';
export const GET_COMPANY_GAME_DEF_SUCCESS = '@PROJECT_NAME/GET_COMPANY_GAME_DEF_SUCCESS';
export const GET_COMPANY_GAME_DEF_FAIL = '@PROJECT_NAME/GET_COMPANY_GAME_DEF_FAIL';

export const CREATE_COMPANY_GAME_DEF = '@PROJECT_NAME/CREATE_COMPANY_GAME_DEF';
export const CREATE_COMPANY_GAME_DEF_SUCCESS = '@PROJECT_NAME/CREATE_COMPANY_GAME_DEF_SUCCESS';
export const CREATE_COMPANY_GAME_DEF_FAIL = '@PROJECT_NAME/CREATE_COMPANY_GAME_DEF_FAIL';

export const UPDATE_COMPANY_GAME_DEF = '@PROJECT_NAME/UPDATE_COMPANY_GAME_DEF';
export const UPDATE_COMPANY_GAME_DEF_SUCCESS = '@PROJECT_NAME/UPDATE_COMPANY_GAME_DEF_SUCCESS';
export const UPDATE_COMPANY_GAME_DEF_FAIL = '@PROJECT_NAME/UPDATE_COMPANY_GAME_DEF_FAIL';

export const DELETE_COMPANY_GAME_DEF = '@PROJECT_NAME/DELETE_COMPANY_GAME_DEF';
export const DELETE_COMPANY_GAME_DEF_SUCCESS = '@PROJECT_NAME/DELETE_COMPANY_GAME_DEF_SUCCESS';
export const DELETE_COMPANY_GAME_DEF_FAIL = '@PROJECT_NAME/DELETE_COMPANY_GAME_DEF_FAIL';

export const GET_GAME_DEF_BY_ID = '@PROJECT_NAME/GET_GAME_DEF_BY_ID';
export const GET_GAME_DEF_BY_ID_SUCCESS = '@PROJECT_NAME/GET_GAME_DEF_BY_ID_SUCCESS';
export const GET_GAME_DEF_BY_ID_FAIL = '@PROJECT_NAME/GET_GAME_DEF_BY_ID_FAIL';

export const DOWNLOAD_GAME_DEF = '@PROJECT_NAME/DOWNLOAD_GAME_DEF';
export const DOWNLOAD_GAME_DEF_SUCCESS = '@PROJECT_NAME/DOWNLOAD_GAME_DEF_SUCCESS';
export const DOWNLOAD_GAME_DEF_FAIL = '@PROJECT_NAME/DOWNLOAD_GAME_DEF_FAIL';

export const GET_ALL_GAME_DEF = '@PROJECT_NAME/GET_ALL_GAME_DEF';
export const GET_ALL_GAME_DEF_SUCCESS = '@PROJECT_NAME/GET_ALL_GAME_DEF_SUCCESS';
export const GET_ALL_GAME_DEF_FAIL = '@PROJECT_NAME/GET_ALL_GAME_DEF_FAIL';

export const IMPORT_GAME_DEF = '@PROJECT_NAME/IMPORT_GAME_DEF';
export const IMPORT_GAME_DEF_SUCCESS = '@PROJECT_NAME/IMPORT_GAME_DEF_SUCCESS';
export const IMPORT_GAME_DEF_FAIL = '@PROJECT_NAME/IMPORT_GAME_DEF_FAIL';
