/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

// import {} from 'utils';

/* Components */

import {
  // Select,
  CalendarBig,
} from 'components';

/* Constants */

// import {} from 'constants';

/* Styles */

import * as Styled from './styles';

function CalendarSidebar({ campaignPicker, campaign, ...restProps }) {
  return (
    <Styled.Wrapper>
      {campaignPicker}
      {campaign && <CalendarBig {...restProps} />}
    </Styled.Wrapper>
  );
}

/* CalendarSidebar type of props */

CalendarSidebar.propTypes = {
  campaignPicker: PropTypes.node.isRequired,
  campaign: PropTypes.number,
};

/* CalendarSidebar default props */

CalendarSidebar.defaultProps = {
  campaign: null,
};

export default CalendarSidebar;
