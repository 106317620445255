/* Libs */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';

/* Actions */

import { GameEntity } from '_entities';

/* Utils */

import { removeFocus, redirect, getTimeIn } from 'utils/custom';
import { notification } from 'utils/services';
import { isEmpty } from 'utils/validation';

/* Components */

import {
  GameSuccessModal,
  GameFailedModal,
  GameSection,
  GameStruct,
  Input,
  Timer,
} from 'components';

/* Constants */

import { NOTIFICATIONS, QUESTION_STATUS } from '_constants';
import { GAME_CONFIG, URLS_CONFIG, DATE_SETTINGS } from 'config';

function GamePlay({
  setQuestionIsOpened,
  handleAnswerClick,
  hasAfterGamePart,
  updateGameTime,
  setExpireDate,
  is_finished,
  gameFailed,
  expireDate,
  changeStep,
  gameSuccess,
  campaignId,
  questions,
  finalCode,
  timerTick,
  timeLimit,
  timeLeft,
  timerUp,
  token,
  step,
  isDarkMode,
}) {
  const [code, setCode] = useState('');
  function handleChange({ target: { value } }) {
    setCode(value);
  }
  const handleCodeChange = useCallback(debounce((currentCode) => {
    if (finalCode === currentCode) {
      return gameSuccess();
    }
    const questionWithCode = questions.find(question => question.unlock_code === currentCode);

    if (!questionWithCode && currentCode.length > 20) {
      setCode('');
      return notification.info(NOTIFICATIONS.INFO_INCORRECT_GAME_CODE);
    }

    if (questionWithCode) {
      if (questionWithCode.status !== QUESTION_STATUS.NOT_OPENED) {
        setCode('');
        return notification.info(NOTIFICATIONS.INFO_GAME_CODE_ALREADY_USED);
      }
      return handleCorrectCode(questionWithCode);
    }
  }, 500), [questions]);

  useEffect(() => {
    handleCodeChange(code);
  }, [code]);
  useEffect(() => {
    setExpireDate(getTimeIn(timeLeft));
  }, []);

  useEffect(() => {
    if (step !== GAME_CONFIG.STEP.GAME_PLAY) return;
    function beforeUnloadHandler(e) {
      e.preventDefault();
      e.returnValue = NOTIFICATIONS.ON_GAME_LEAVE;
      return NOTIFICATIONS.ON_GAME_LEAVE;
    }
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [step, is_finished]);

  function handleCorrectCode(question) {
    notification.success(NOTIFICATIONS.SUCCESS_GAME_CODE_IS_CORRECT);
    setCode('');
    setQuestionIsOpened(question);
    removeFocus();
  }

  function handleTimerTick({ target: { value } }) {
    timerTick(value);
  }


  function handleExpired() {
    gameFailed();
  }

  function getFinishStep() {
    return hasAfterGamePart
      ? () => changeStep(GAME_CONFIG.STEP.DEBRIEFING)
      : () => redirect(URLS_CONFIG.auth.signin);
  }

  const handleAnswer = question => (answer, isCorrect) => async () => {
    try {
      await handleAnswerClick({ question, answer, isCorrect });
      updateGameTime(timeLeft);
    } catch (error) {
      notification.error(error);
    }
  };
  return (
    <GameStruct
      headerInput={(
        <Input
          onChange={handleChange}
          placeholder="Enter code"
          error={null}
          name="code"
          autoComplete="off"
          value={code}
        />
      )}
      headerTimer={expireDate !== 0 && (
        <Timer
          expireDate={expireDate}
          timeLeft={timeLeft}
          timeLimit={timeLimit}
          countUp={timerUp}
          normalFormat={DATE_SETTINGS.FORMAT.TIMER_FORMAT}
          expiredFormat={DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED}
          stopped={
            [GAME_CONFIG.STEP.GAME_SUCCESS, GAME_CONFIG.STEP.GAME_FAILED].includes(step)
          }
          onTick={handleTimerTick}
          onExpired={handleExpired}
          name="gameTimer"
        />
      )}
      isDarkMode={isDarkMode}
    >
      <GameSection.Questions
        isDarkMode={isDarkMode}
        questions={questions}
        handleAnswerClick={handleAnswer}
      />
      {/* Leave in case we should return these modals */}
      {/* <GameSuccessModal */}
      {/*  hasAfterGamePart={hasAfterGamePart} */}
      {/*  finishStep={getFinishStep()} */}
      {/*  token={token} */}
      {/*  campaignId={campaignId} */}
      {/*  open={step === GAME_CONFIG.STEP.GAME_SUCCESS} */}
      {/*  isDarkMode={isDarkMode} */}
      {/* /> */}
      {/* <GameFailedModal */}
      {/*  hasAfterGamePart={hasAfterGamePart} */}
      {/*  finishStep={getFinishStep()} */}
      {/*  token={token} */}
      {/*  campaignId={campaignId} */}
      {/*  open={step === GAME_CONFIG.STEP.GAME_FAILED} */}
      {/*  isDarkMode={isDarkMode} */}
      {/* /> */}
    </GameStruct>
  );
}

/* Game type of props */

GamePlay.propTypes = {
  setQuestionIsOpened: PropTypes.func.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
  gameFailed: PropTypes.func.isRequired,
  gameSuccess: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  timeLeft: PropTypes.instanceOf(moment).isRequired,
  setCompletedTime: PropTypes.func.isRequired,
  finalCode: PropTypes.string.isRequired,
  timeLimit: PropTypes.string.isRequired,
  hasAfterGamePart: PropTypes.bool.isRequired,
  timerUp: PropTypes.bool.isRequired,
  step: PropTypes.oneOf([
    GAME_CONFIG.STEP.GAME_PLAY,
    GAME_CONFIG.STEP.GAME_FAILED,
    GAME_CONFIG.STEP.GAME_SUCCESS,
  ]),
};

export default connect(({ game }) => ({
  questions: game.questions,
  timeLeft: game.timeLeft,
  expireDate: game.expireDate,
  finalCode: game.gameDef.final_code,
  isDarkMode: game.gameDef.is_dark_mode,
  is_finished: game.is_finished,
  timeLimit: game.gameDef.time_limit,
  timerUp: game.gameSession.is_timer_counting_up,
  token: game.campaignToken,
  campaignId: game.gameSession.campaign,
  hasAfterGamePart: !isEmpty(game.gameDef.debriefing_text)
    || !isEmpty(game.gameDef.reset_text)
    || !isEmpty(game.gameDef.debriefing_vimeo_id)
    || !isEmpty(game.gameDef.reset_vimeo_id)
    || !isEmpty(game.gameDef.debriefing_title)
    || !isEmpty(game.gameDef.reset_title),
}), {
  setQuestionIsOpened: GameEntity.actions.setQuestionIsOpened,
  handleAnswerClick: GameEntity.actions.handleAnswerClick,
  setExpireDate: GameEntity.actions.setExpireDate,
  timerTick: GameEntity.actions.timerTick,
  setCompletedTime: GameEntity.actions.setCompletedTime,
  gameFailed: GameEntity.actions.gameFailed,
  gameSuccess: GameEntity.actions.gameSuccess,
  changeStep: GameEntity.actions.changeStep,
  updateGameTime: GameEntity.actions.updateGameTime,
})(GamePlay);
