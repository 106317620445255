/* Libs */
import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

/* Utils */
import { goBack } from 'utils/custom';

/* Components */
import {
  ParticipantsTable,
  Button,
  SearchInput,
  GoBackButton,
} from 'components';

/* Styles */
import * as Styled from './styles';

function Participants({
  title,
  download,
  handleSearch,
  searchValue,
  participants,
  pagination,
  url,
  anonymousParticipantsCount,
  participantsCount,
}) {
  const mappedParticipants = useMemo(() => participants.map(participant => ({
    id: participant.id,
    invited: (participant.invited && participant.email) || null,
    signed: (participant.signed && participant.email) || null,
    actual: (participant.actual && participant.email) || null,
  })), [participants]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Column>
          <GoBackButton onClick={goBack}>Back</GoBackButton>
          <Styled.Title>
            {title}
            {handleSearch && (
              <SearchInput
                name="search"
                placeholder="Search by email"
                value={searchValue}
                onChange={handleSearch}
              />
            )}
          </Styled.Title>
        </Styled.Column>
        <Styled.Column>
          {download && (
            <Button disabled={!mappedParticipants.length} onClick={download}>
              Download
            </Button>
          )}
        </Styled.Column>
      </Styled.Header>
      <Styled.ParticipantsCount>
        <div>
          Anonymous participants count:
          {' '}
          <b>{anonymousParticipantsCount}</b>
        </div>
        <div>
          Overall participants count:
          {' '}
          <b>{participantsCount + anonymousParticipantsCount}</b>
        </div>
      </Styled.ParticipantsCount>
      <ParticipantsTable
        data={mappedParticipants}
        pagination={pagination}
        url={url}
      />
    </Styled.Wrapper>
  );
}

Participants.propTypes = {
  title: PropTypes.string,
  download: PropTypes.func,
  handleSearch: PropTypes.func,
  url: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  participants: PropTypes.array,
  anonymousParticipantsCount: PropTypes.number.isRequired,
  participantsCount: PropTypes.number.isRequired,
};

Participants.defaultProps = {
  title: '',
  download: null,
  handleSearch: null,
  searchValue: null,
  participants: null,
};

export default Participants;
