import {
  validateAnswer,
  validateQuestionField,
  validateQuestionName,
  validateText,
  validateUnlockCode,
} from 'utils/validation/fields';
import { isNull, isUndefined } from 'utils/validation';

const validate = ({
  name, value, section, additionalData,
}) => {
  if (!section || !name) return false;

  const rules = {
    questions: {
      correct_answer_message: () => validateText({
        name,
        value,
        max: 2000,
      }),
      incorrect_answer_message: () => validateText({
        name,
        value,
        max: 2000,
      }),
      name: () => validateQuestionName({
        name,
        value,
        anotherQuestions: additionalData.otherQuestions,
      }),
      intro_vimeo_id: () => validateText({
        name,
        value,
        required: false,
        max: 500,
      }),
      question: () => validateQuestionField({
        name,
        value,
        anotherQuestions: additionalData.otherQuestions,
        max: 500,
      }),
      unlock_code: () => validateUnlockCode({
        value,
        max: 20,
        anotherQuestions: additionalData.otherQuestions,
        final_code: additionalData.final_code,
      }),
      security_context: () => validateText({
        name,
        value,
        max: 500,
        required: false,
      }),
      hint_text: () => validateText({
        name,
        value,
        required: false,
        max: 500,
      }),
      answers: () => value.map((answer, idx) => validateAnswer({
        currentAnswerNumber: idx,
        answers: value,
        name: `Answer ${idx + 1}`,
        value: answer,
        max: 500,
      })),
    },
    basicInformation: {
      name: () => validateText({ name, value, max: 500 }),
      game_def_template: () => (isNull(value) || isUndefined(value) ? null : !value),
      time_limit: () => (isNull(value) || isUndefined(value) ? null : !value),
      final_code: () => validateText({ name, value, max: 20 }),
      storyline: () => (isNull(value) || isUndefined(value) ? null : !value),
    },
    introduction: {
      introduction_title: () => validateText({
        name, value, required: false, max: 500,
      }),
      introduction_text: () => validateText({
        name, value, required: false, max: 2000,
      }),
      introduction_vimeo_id: () => validateText({
        name, value, required: false, max: 500,
      }),
    },
    instruction: {
      instruction_title: () => validateText({
        name, value, required: false, max: 500,
      }),
      instruction_text: () => validateText({
        name, value, required: false, max: 2000,
      }),
      instruction_vimeo_id: () => validateText({
        name, value, required: false, max: 500,
      }),
    },
    storyline: {
      storyline_title: () => validateText({
        name, value, required: false, max: 500,
      }),
      storyline_text: () => validateText({
        name, value, required: false, max: 2000,
      }),
      storyline_vimeo_id: () => validateText({
        name, value, required: false, max: 500,
      }),
    },
    debriefing: {
      debriefing_title: () => validateText({
        name, value, required: false, max: 500,
      }),
      debriefing_text: () => validateText({
        name, value, required: false, max: 2000,
      }),
      debriefing_vimeo_id: () => validateText({
        name, value, required: false, max: 500,
      }),
    },
    reset: {
      reset_title: () => validateText({
        name, value, required: false, max: 500,
      }),
      reset_text: () => validateText({
        name, value, required: false, max: 2000,
      }),
      reset_vimeo_id: () => validateText({
        name, value, required: false, max: 500,
      }),
    },
  };

  const validationFunction = rules[section] && rules[section][name];
  if (!validationFunction) return null;
  return rules[section][name]();
};

export default validate;
