/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function AdminViewCompanyStruct({ leftColumn, rightColumn }) {
  return (
    <Styled.CompanyViewStruct>
      <Styled.LeftColumn>
        {leftColumn}
      </Styled.LeftColumn>
      <Styled.RightColumn>
        {rightColumn}
      </Styled.RightColumn>
    </Styled.CompanyViewStruct>
  );
}

/* AdminViewCompanyStruct type of props */

AdminViewCompanyStruct.propTypes = {
  leftColumn: PropTypes.node.isRequired,
  rightColumn: PropTypes.node.isRequired,
};

export default AdminViewCompanyStruct;
