/* Libs */
import React, { useState, useMemo, memo } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';

/* Constants */

import {
  SESSION_TIME_TYPES,
} from 'config';

/* Utils */

import {
  getSessionTimeCellData,
  getTimezoneAbbr,
} from 'utils/custom';

/* Components */

import {
  SimpleTextCell,
  ActionsCell,
  Table,
  SessionTimeCell,
  ParticipantsCell,
  HeaderSessionTimeCell,
} from 'components';

/* Styles */

import * as Styled from './styles';

const getColumnsData = ({
  actions,
  tableTimeType,
  toggleTableTimeType,
}) => [
  {
    accessor: 'start_time',
    minWidth: 200,
    Header: () => (
      <HeaderSessionTimeCell
        value={tableTimeType}
        onToggle={toggleTableTimeType}
        checked={tableTimeType === SESSION_TIME_TYPES.SESSION_TIME}
      />
    ),
    Cell: (props) => {
      const {
        startTime,
        endTime,
        timezoneTitle,
        label,
      } = getSessionTimeCellData(tableTimeType, null, props);
 
      return (
        <SessionTimeCell
          startTime={startTime}
          endTime={endTime}
          timezoneTitle={timezoneTitle}
          label={label}
        />
      );
    },
  },
  {
    accessor: 'kit',
    minWidth: 95,
    flexGrow: 3,
    Header: 'Kit ID',
    Cell: props => <SimpleTextCell value={props.original.kit_id} size="small" />,
  },
  {
    accessor: 'invited_participants_count',
    minWidth: 130,
    headerStyle: {
      overflow: 'visible',
    },
    Header: () => (
      <ParticipantsCell
        leftParticipants="Signedup participants"
        rightParticipants="Participants per session"
      />
    ),
    Cell: ({
      original: {
        participants_per_session,
        signedup_participants,
      },
    }) => (
      <SimpleTextCell
        value={`${signedup_participants.length} of ${participants_per_session}`}
        size="small"
      />
    ),
  },
  {
    minWidth: 200,
    Header: '',
    Cell: ({
      original: {
        id,
        participants_per_session,
        signedup_participants,
        public_invite_link,
      },
    }) => (
      <ActionsCell>
        <Styled.Book
          disabled={signedup_participants.length === participants_per_session}
          onClick={() => actions.bookSession({ sessionID: id })}
        >
          Book
        </Styled.Book>
        <Styled.BookForTeamBox>
          <Styled.BookForTeam
            disabled={signedup_participants.length >= participants_per_session - 1}
            onClick={() => actions.openBookForTeam({
              signedUpParticipantsAmount: signedup_participants.length,
              participantsPerSessionAmount: participants_per_session,
              sessionID: id,
              publicInviteLink: public_invite_link,
            })}
          >
            Book for Team
          </Styled.BookForTeam>
        </Styled.BookForTeamBox>

      </ActionsCell>
    ),
  },
];

function SignupCampaign({
  data,
  actions,
  loading,
}) {
  const [tableTimeType, setTableTimeType] = useState(SESSION_TIME_TYPES.LOCAL_TIME);
  const toggleTableTimeType = () => setTableTimeType(
    tableTimeType === SESSION_TIME_TYPES.SESSION_TIME
      ? SESSION_TIME_TYPES.LOCAL_TIME
      : SESSION_TIME_TYPES.SESSION_TIME,
  );
  const columnsData = useMemo(() => getColumnsData({
    actions,
    tableTimeType,
    toggleTableTimeType,
  }), [
    actions,
    tableTimeType,
    toggleTableTimeType,
    data,
  ]);


  return (
    <Styled.SignupCampaign>
      <Table
        defaultPageSize={data.length || null}
        columns={columnsData}
        data={data}
        loading={loading}
      />
    </Styled.SignupCampaign>
  );
}

// Type of props
SignupCampaign.propTypes = {
  // data: PropTypes.arrayOf(PropTypes.shape({
  // })).isRequired,
  // actions: PropTypes.shape({
  //   openEditSession: PropTypes.func.isRequired,
  // }).isRequired,
  // loading: PropTypes.bool.isRequired,
};

export default memo(SignupCampaign);
