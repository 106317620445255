/* Libs */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  Input,
  Checkbox,
  DeleteAnswer,
  CircleWithSign,
  DeleteQuestion,
} from 'components';

/* Constants */

import { GAME_DEF_FORM } from '_constants';
import { GAME_DEF_CONFIG } from 'config';

/* Styles */

import {
  validateText,
  validateAnswer,
  validateUnlockCode,
  validateQuestionForm,
  validateQuestionName,
  validateQuestionField,
} from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import * as Styled from './styles';

function QuestionForm({
  data,
  disabled,
  final_code,
  handleChange,
  deleteQuestion,
  questionIsValid,
  otherQuestions,
  game_def_template,
  importedData,
  globalErrors,
  formIsEmpty,
}) {
  const [formData, setFormData] = useState({
    data,
    errors: { ...GAME_DEF_CONFIG.DEFAULT_ERRORS.QUESTION },
  });

  const prevNumberRef = useRef();
  useEffect(() => {
    prevNumberRef.current = formData.data.number;
  });
  const prevNumber = prevNumberRef.current;

  useEffect(() => {
    if (formData.data.number === 0) return;
    handleChange({ ...formData.data });
  }, [formData.data, importedData]);

  useEffect(() => {
    if (data.number === 0) return;
    const newData = { ...data };
    delete newData.isNew;
    const { errors: initialErrors } = validateQuestionForm({
      questionForValidate: data,
      otherQuestions,
      final_code,
    });
    setFormData({
      data: newData,
      errors: initialErrors,
    });
  }, [data.number, game_def_template]);

  useEffect(() => {
    if (!importedData) return;
    const newData = (importedData && importedData.questions && importedData.questions[0])
      || data;
    const { errors: initialErrors } = validateQuestionForm({
      questionForValidate: newData,
      otherQuestions,
      final_code,
    });
    setFormData({
      data: newData,
      errors: initialErrors,
    });
  }, [importedData]);

  useEffect(() => {
    questionIsValid(
      formIsValid(
        { ...formData.errors },
        GAME_DEF_CONFIG.REQUIRED_FIELDS.QUESTION,
      ),
    );
  }, [formData.errors]);

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      if (disabled) return;
      setFormData(prevFormData => ({
        data: { ...prevFormData.data, [name]: value },
        errors: {
          ...prevFormData.errors,
          [name]: (() => {
            switch (name) {
              case 'correct_answer_message':
                return validateText({
                  name: 'correct_answer_message',
                  value,
                  max: 500,
                });
              case 'incorrect_answer_message':
                return validateText({
                  name: 'incorrect_answer_message',
                  value,
                  max: 500,
                });
              case 'name':
                return validateQuestionName({
                  name: 'name',
                  value,
                  anotherQuestions: otherQuestions,
                });
              case 'intro_vimeo_id':
                return validateText({
                  name: 'intro_vimeo_id',
                  value,
                  required: false,
                  max: 500,
                });
              case 'question':
                return validateQuestionField({
                  name: 'question',
                  value,
                  anotherQuestions: otherQuestions,
                  max: 500,
                });
              case 'unlock_code':
                return validateUnlockCode({
                  value,
                  max: 20,
                  anotherQuestions: otherQuestions,
                  final_code,
                });
              case 'security_context':
                return validateText({
                  name,
                  value,
                  max: 500,
                  required: false,
                });
              case 'hint_text':
                return validateText({
                  name: 'hint_text',
                  value,
                  required: false,
                  max: 500,
                });
              case 'answers':
                return value.map((answer, idx) => validateAnswer({
                  currentAnswerNumber: idx,
                  answers: value,
                  name: `Answer ${idx + 1}`,
                  value: answer,
                  max: 500,
                }));
            }
          })(),
        },
      }));
    },
    [otherQuestions, final_code, disabled],
  );

  const handleAnswerChange = useCallback(
    id => ({ target: { name, value } }) => {
      if (disabled) return;
      const { answers } = formData.data;
      const updatedAnswers = answers.map((answer, idx) => (idx === id
        ? {
          ...answer,
          [name]: value,
          is_correct:
                name === 'is_correct'
                  ? answer.is_correct === true && value === false
                    ? true
                    : value
                  : answer.is_correct, // that need to imitate behavior like radio button
        }
        : name === 'is_correct'
          ? { ...answer, is_correct: false }
          : answer));
      onChange({ target: { name: 'answers', value: updatedAnswers } });
    },
    [formData.data.answers, disabled],
  );

  const addAnswer = useCallback(() => {
    onChange({
      target: {
        name: 'answers',
        value: [...formData.data.answers, GAME_DEF_FORM.DEFAULT_ANSWER],
      },
    });
  }, [formData.data.answers]);

  const deleteAnswer = useCallback(
    (id, wasCorrect) => () => {
      let hasCorrect = false;
      const newAnswers = formData.data.answers.reduce((result, answer, idx) => {
        if (idx !== id) {
          const is_correct = wasCorrect && !hasCorrect ? true : answer.is_correct;
          hasCorrect = true;
          return [...result, { ...answer, is_correct }];
        }
        return result;
      }, []);
      onChange({
        target: { name: 'answers', value: newAnswers },
      });
    },
    [formData.data.answers],
  );

  useEffect(() => {
    if (formIsEmpty && !importedData) {
      setFormData(prev => ({
        data: {
          ...GAME_DEF_FORM.DEFAULT_QUESTION,
          isNew: false,
          number: 1,
          answers: [{ ...GAME_DEF_FORM.DEFAULT_ANSWER }],
        },
        errors:
          globalErrors === null
            ? { ...GAME_DEF_CONFIG.DEFAULT_ERRORS.QUESTION }
            : { ...prev.errors },
      }));
    }
  }, [formIsEmpty, globalErrors, importedData]);

  const answersCount = Array.isArray(data.answers) ? data.answers.length : 0;
  const answersList = useMemo(
    () => (formData.data.answers || []).map((
      answer,
      id, // TODO: temprorary fix, before appropriate count of questions will done
    ) => (
      <Styled.RadioGroup key={id}>
        <Checkbox
          type="circle"
          name="is_correct"
          value={answer.is_correct}
          onChange={handleAnswerChange(id)}
          error={null}
          disabled={disabled}
        />
        <Input
          placeholder={`Answer #${id + 1}`}
          title={`Answer #${id + 1}`}
          name="answer"
          value={answer.answer}
          onChange={handleAnswerChange(id)}
          error={formData.errors.answers[id]}
        />
        <DeleteAnswer
          isVisible={answersCount >= 2 && !disabled}
          deleteAnswer={deleteAnswer(id, answer.is_correct)}
        />
      </Styled.RadioGroup>
    )),
    [formData.data.answers, formData.errors.answers, disabled],
  );

  return (
    <Styled.Wrapper>
      <Input
        placeholder="Name"
        name="name"
        title="Name"
        value={data.name}
        onChange={onChange}
        error={formData.errors.name}
      />
      <Input
        placeholder="Unlock code"
        name="unlock_code"
        title="Unlock code"
        value={data.unlock_code}
        onChange={onChange}
        error={formData.errors.unlock_code}
      />
      <Input
        placeholder="Security context"
        name="security_context"
        title="Security context"
        value={data.security_context}
        onChange={onChange}
        error={formData.errors.security_context}
      />
      <Input
        placeholder="Question intro - Vimeo ID"
        name="intro_vimeo_id"
        title="Vimeo ID"
        optional
        value={data.intro_vimeo_id}
        onChange={onChange}
        error={formData.errors.intro_vimeo_id}
      />
      <Input
        placeholder="Question"
        name="question"
        title="Question"
        value={data.question}
        onChange={onChange}
        error={formData.errors.question}
      />
      <Styled.AnswersSection>
        <Styled.Header>
          <Styled.Title>Answers</Styled.Title>
          <Styled.Tip>Select the correct answer</Styled.Tip>
        </Styled.Header>
        <Styled.AddAnswer
          onClick={addAnswer}
          isHidden={disabled || answersCount > 4}
        >
          <CircleWithSign sign="+" />
          <Styled.Label>Add an answer</Styled.Label>
        </Styled.AddAnswer>
      </Styled.AnswersSection>
      {answersList}
      <Input
        placeholder="Correct answer message"
        name="correct_answer_message"
        title="Correct answer message"
        value={data.correct_answer_message}
        onChange={onChange}
        error={formData.errors.correct_answer_message}
      />
      <Input
        placeholder="Incorrect answer message"
        name="incorrect_answer_message"
        title="Incorrect answer message"
        value={data.incorrect_answer_message}
        onChange={onChange}
        error={formData.errors.incorrect_answer_message}
      />
      <Input
        placeholder="Hint text"
        name="hint_text"
        title="Hint text"
        value={data.hint_text}
        onChange={onChange}
        error={formData.errors.hint_text}
      />
      {!disabled && (
        <DeleteQuestion number={data.number} deleteQuestion={deleteQuestion} />
      )}
    </Styled.Wrapper>
  );
}

/* QuestionForm type of props */

QuestionForm.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    unlock_code: PropTypes.string.isRequired,
    security_context: PropTypes.string,
    intro_vimeo_id: PropTypes.string,
    question: PropTypes.string.isRequired,
    correct_answer_message: PropTypes.string.isRequired,
    incorrect_answer_message: PropTypes.string.isRequired,
    hint_text: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string,
        is_correct: PropTypes.bool,
      }),
    ).isRequired,
  }).isRequired,
  otherQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      unlock_code: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
    }),
  ),
  deleteQuestion: PropTypes.func.isRequired,
  questionIsValid: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  final_code: PropTypes.string,
  disabled: PropTypes.bool,
};

QuestionForm.defaultProps = {
  disabled: false,
};

export default QuestionForm;
