import * as TYPES from './Storylines.types';

const initialState = {
  loading: false,
  loaded: false,
  total: 0,
  storylines: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.ADD_STORYLINE:
    case TYPES.GET_STORYLINES:
    case TYPES.UPDATE_STORYLINE:
    case TYPES.GET_STORYLINES_BY_COMPANY_ID:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_STORYLINE_SUCCESS:
      return {
        ...state,
        storylines: [
          { ...action.result },
          ...state.storylines,
        ],
        loading: false,
      };
    case TYPES.UPDATE_STORYLINE_SUCCESS: { // TODO: impelemt when BE will work
      const { oldId } = action.payload;
      return {
        ...state,
        storylines: state.storylines.map((storyline) => {
          if (storyline.id === oldId) {
            return { ...action.result };
          }
          return { ...storyline };
        }),
        loading: false,
      };
    }
    case TYPES.GET_STORYLINES_SUCCESS:
      return {
        ...state,
        storylines: action.result.results,
        total: action.result.count,
        loaded: true,
        loading: false,
      };
    case TYPES.GET_STORYLINES_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    case TYPES.ADD_STORYLINE_FAIL:
    case TYPES.GET_STORYLINES_FAIL:
    case TYPES.UPDATE_STORYLINE_FAIL:
    case TYPES.GET_STORYLINES_BY_COMPANY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
