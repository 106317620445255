/* Libs */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';

/* Components */

import {
  DownTriangle,
  ForwardTriangle,
  ManageGameAnswersList,
  ManageGameAnswerListItem,
} from 'components';

/* Constants */

import { COLORS_CONFIG } from 'config';
import { ANSWER_STATUS } from '_constants';

/* Styles */
import { useToggleBooleanHook } from 'hooks';
import * as Styled from './styles';


function ManageGameQuestionListItem({
  question,
  answers,
}) {
  const [isOpened, toggleOpen] = useToggleBooleanHook(false);
  const answersList = useMemo(() => answers.map(({ answer, status }, idx) => <ManageGameAnswerListItem key={idx} answer={answer} status={status} />), [answers]);
  return (
    <Styled.Wrapper>
      <Styled.Question onClick={toggleOpen}>
        <Styled.QuestionText>
          {question}
        </Styled.QuestionText>
        <Styled.Action>
          {
            isOpened
              ? <DownTriangle color={COLORS_CONFIG.SMOOTH_BROWN} />
              : <ForwardTriangle color={COLORS_CONFIG.SMOOTH_BROWN} />
          }
        </Styled.Action>
      </Styled.Question>
      <Collapse isOpened={isOpened}>
        <ManageGameAnswersList>
          {answersList}
        </ManageGameAnswersList>
      </Collapse>
    </Styled.Wrapper>
  );
}

/* ManageGameQuestionListItem type of props */

ManageGameQuestionListItem.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.string.isRequired,
    status: PropTypes.oneOf([
      ANSWER_STATUS.NOT_ANSWERED,
      ANSWER_STATUS.CORRECT,
      ANSWER_STATUS.WRONG,
    ]).isRequired,
  })).isRequired,
};

export default ManageGameQuestionListItem;
