/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { SmallTitle } from 'components/blocks/ui/SmallTitle/styles';
import { Wrapper as InputWrapper } from 'components/ui/Input/styles';
import { Wrapper as LoaderWrapper, Loader } from 'components/blocks/ui/Loader/styles';
import { Button } from 'components/ui/Button/styles';
import { Storyline } from '../AvailableStorylines/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${SmallTitle} {
    font-size: 14px;
  }
  ${Storyline} {
    margin: 16px 0 24px;
  }
  ${InputWrapper} {
    margin-top: 13px;
  }
  ${Button} {
    &:first-child {
      margin-right: 10px;
    }
  }
  ${LoaderWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: ${COLORS_CONFIG.WHITE$72};
  }
  ${Loader} {
    max-height: 100px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
`;
