import { store } from 'lib';
import { notify, removeNotifications } from 'reapop';
import { isEmpty, isUndefined } from 'utils/validation';
import { NOTIFICATIONS } from '_constants';
import { getError } from 'utils/custom';

const DEFAULT_NOTIFICATION = {
  title: '',
  message: NOTIFICATIONS.ERROR_DEFAULT,
  status: 'error',
  dismissible: true,
  dismissAfter: 5000,
};
const createNotification = (data, type) => {
  if (typeof data === 'string') {
    return store.dispatch(
      notify({
        ...DEFAULT_NOTIFICATION,
        ...data,
        message: data,
        status: type,
      }),
    );
  }
  const notification = {
    ...DEFAULT_NOTIFICATION,
    ...data,
    status: type,
    text:
      !data || isEmpty(data.message) || isUndefined(data.message)
        ? NOTIFICATIONS.ERROR_DEFAULT
        : type === 'error'
          ? getError(data.message)
          : data.message,
  };
  store.dispatch(notify(notification));
  return true;
};

const success = notification => createNotification(notification, 'success');
const error = notification => createNotification(notification, 'error');
const info = notification => createNotification(notification, 'info');
const warning = notification => createNotification(notification, 'warning');
const custom = notification => createNotification(notification);
const removeAllNotifications = () => removeNotifications();

export default {
  success,
  error,
  info,
  warning,
  custom,
  removeAllNotifications,
};
