/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG } from 'config';


export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 260px;
  z-index: 3;
  top: 100%;
  right: 0;
  padding: 15px;
  background-color: ${COLORS_CONFIG.WHITE};
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  border-radius: 4px;
`;
