import moment from 'moment';
import { getTimezoneName, getTimezoneOffset } from 'utils/custom';

const getUTCTime = ({ date, timezone }) => {
  if (!timezone) return date.utc();

  const timezoneName = getTimezoneName(timezone);
  const offset = getTimezoneOffset(timezoneName, date);

  return moment(date).subtract(offset, 'minutes');
};


export default getUTCTime;
