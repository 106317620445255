/* Libs */
import styled from 'styled-components';

// import { Wrapper as InputWrapper } from 'components/ui/Input/styles';
import { Button } from 'components/ui/Button/styles';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > *:not(:last-child) {
    margin-top: 24px;
  }
  & > *:last-child {
    margin-top: 56px;
  }
  & > *:first-child {
    margin-top: 0;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  ${Button} {
    margin: 0 5px;
  }
`;
