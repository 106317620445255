/* Libs */
import styled, { css } from 'styled-components';

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

import { Circle } from 'components/blocks/Markers/CircleFilled/styles';

export const Content = styled.div`
  cursor: pointer;
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
`;

export const Cell = styled.div`
  position: relative;
  ${({ hasEvent }) => hasEvent && css`
    ${Content} {
      border: 1.5px solid ${COLORS_CONFIG.SKY_BLUE};
      border-radius: 50%;
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    }
  `}
`;

export const Wrapper = styled.div`
  & ${Circle} {
    width: 6px;
    height: 6px;
  }
`;
