import styled from 'styled-components';

export const WaterMark = styled.div`
  position: fixed;
  bottom: 25px;
  right: 55px;
  z-index: 35;
  width: 120px;
  opacity: 0.3;
  height: 20px;
`;
