/* Libs */
import styled, { css } from 'styled-components';
import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';
import { ANSWER_STATUS } from '_constants';

export const Hint = styled.div`
  position: absolute;
  display: none;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 12px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Wrapper = styled.div`
  padding: 14px 86px 16px 24px;
  word-break: break-word;
  color: ${COLORS_CONFIG.DARK_GREY};
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
  border-radius: 8px;
  position: relative;
  ${({ status }) => status === ANSWER_STATUS.CORRECT && css`
    background: ${COLORS_CONFIG.SKY_BLUE};
  `}
  ${({ status }) => status === ANSWER_STATUS.WRONG && css`
    background: ${COLORS_CONFIG.ORANGE};
  `}
  
  ${({ status }) => [ANSWER_STATUS.WRONG, ANSWER_STATUS.CORRECT].includes(status) && css`
    border-color: ${COLORS_CONFIG.TRANSPARENT};
    & ${Hint} {
      color: ${COLORS_CONFIG.WHITE};
      display: block;
    }
  `}
  
  ${({ isDarkMode }) => isDarkMode && css`
      color: ${COLORS_CONFIG.WHITE};
      border: none;
      background: ${COLORS_CONFIG.NAVY_BLUE};
  `}
  
  
   ${({ status, isDarkMode }) => status === ANSWER_STATUS.CORRECT && isDarkMode && css`
    background: ${COLORS_CONFIG.LIGHT_BLUE_GRADIENT_3};
  `}
  ${({ status, isDarkMode }) => status === ANSWER_STATUS.WRONG && isDarkMode && css`
    background: ${COLORS_CONFIG.RED_GRADIENT};
  `}

`;
