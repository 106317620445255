import React from 'react';
import { Link } from 'react-router-dom';

import * as Styled from './styles';

const GoBackButton = ({ onClick, to, children }) => (
  <Styled.GoBack as={to ? Link : Styled.GoBack} to={to} onClick={onClick}>{children}</Styled.GoBack>
);

export default GoBackButton;
