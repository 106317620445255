/* Libs */
import styled, { css } from 'styled-components';

import {
  COLORS_CONFIG,
} from 'config';

import { Input as DefaultInput, Wrapper as InputWrapper } from 'components/ui/Input/styles';
import { Time } from 'components/blocks/ui/Timer/styles';

export const Logo = styled.div`
  justify-content: flex-start;
`;

export const Input = styled.div`
  height: 48px;
  justify-content: center;
  & ${DefaultInput} {
    background-color: ${COLORS_CONFIG.SILVER};
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
  & ${InputWrapper} {
    width: 204px;
  }
`;

export const Timer = styled.div`
  display: flex;
  justify-content: flex-end;
  & ${Time} {
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    font-size: 40px;
  }
`;

export const Wrapper = styled.div`
  height: 80px;
  background-color: ${COLORS_CONFIG.WHITE};
  display: flex;
  width: 100%;
  padding: 0 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  z-index: 2;
  position: relative;
  
  & > * {
    flex: 1;
    display: flex;
  }
  
  ${({ isDarkMode }) => isDarkMode && css`
      background-color: ${COLORS_CONFIG.NAVY_BLUE};
               
     & ${Timer} {
       & ${Time}{
        color: ${COLORS_CONFIG.WHITE};
       }
     }
     
     & ${Input}{
        & ${DefaultInput} {
           background-color: ${COLORS_CONFIG.TRANSPARENT};
           border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
           color: ${COLORS_CONFIG.WHITE}
           
           &::placeholder{
            color: ${COLORS_CONFIG.WHITE}
           }
         }
         & ${InputWrapper} {
            color: ${COLORS_CONFIG.WHITE}
         }
     }
  `}
  
`;
