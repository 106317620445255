/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Table } from 'components/blocks/Table/styles';
import { Cell as ActionCell } from 'components/blocks/TableCells/ActionsCell/styles';
import { Pagination } from 'components/ui/Pagination/styles';

// TODO: styles of action cell after logic is completed

export const SignupCampaign = styled.div`
  & ${Table} {
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    box-shadow: initial;
    & .rt-thead.-header {
      box-shadow: initial;
    }
    & .react-table {
      &__wrapper .rt-table {
        border-radius: initial;
        box-shadow: initial;
        & .rt-tr {
          padding: 0 20px !important;
        }
        & .rt-thead {
          padding-bottom: 16px;
          background-color: ${COLORS_CONFIG.TRANSPARENT};
        }
        & .rt-tbody {
          background-color: ${COLORS_CONFIG.TRANSPARENT};
          & .rt-tr-group {
            border-bottom-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
          }
          & .rt-tr-group:first-child {
            border-top:1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
          }
          & .rt-tr-group:last-child {
            border-bottom:1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
          }
        }
      }
    }
    & ${ActionCell} {
      & > *:not(:first-child)::before {
          background-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      }
      & div {
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 14px;
      }
    }
    ${Pagination} {
      margin-top: 40px;
    }
  }
`;


export const Book = styled.div`
  cursor: pointer;
    
  ${({ disabled }) => disabled && css`
    opacity: .5;
    pointer-events: none;
  `}  
`;

export const BookForTeamBox = styled.div`

`;

export const BookForTeam = styled.div`
  cursor: pointer;

  ${({ disabled }) => disabled && css`
    opacity: .5;
    pointer-events: none;
  `}  
`;

export default SignupCampaign;
