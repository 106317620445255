/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { TopPlaceCard } from 'components';

/* Constants */

import { PLACES_POSITION } from '_constants';

/* Styles */

import * as Styled from './styles';

function TopPlacesSection({
  first,
  second,
  third,
  isDarkMode
}) {
  return (
    <Styled.Wrapper>
      <TopPlaceCard isDarkMode={isDarkMode} {...second} place={PLACES_POSITION.SECOND} />
      <TopPlaceCard isDarkMode={isDarkMode} {...first} place={PLACES_POSITION.FIRST} />
      <TopPlaceCard isDarkMode={isDarkMode} {...third} place={PLACES_POSITION.THIRD} />
    </Styled.Wrapper>
  );
}

/* TopPlacesSection type of props */

TopPlacesSection.propTypes = {
  first: PropTypes.shape({
    time: PropTypes.string,
    teamName: PropTypes.string,
    location: PropTypes.string,
  }),
  second: PropTypes.shape({
    time: PropTypes.string,
    teamName: PropTypes.string,
    location: PropTypes.string,
  }),
  third: PropTypes.shape({
    time: PropTypes.string,
    teamName: PropTypes.string,
    location: PropTypes.string,
  }),
};

/* TopPlacesSection default props */

TopPlacesSection.defaultProps = {
  first: {},
  second: {},
  third: {},
};

export default TopPlacesSection;
