/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import {
  KitsEntity,
  LS_AdminStorylinesEntity,
} from '_entities';

/* Utils */

import { transformKitForEdit } from 'utils/transformers';
import { notification } from 'utils/services';
import { getError, hasPermission } from 'utils/custom';

/* Components */

import {
  Card,
  KitsTable,
  Pagination,
  AddKitModal,
  EditKitModal,
} from 'components';

/* Constants */

import { TABLES_CONFIG, KITS_TABLE } from 'config';
import { PERMISSION_NAMES } from '_constants';


/* Styles */

import * as Styled from './styles';

const MODALS_NAME = {
  EDIT: 'edit',
  ADD: 'add',
};

function KitsCard({
  kits,
  total,
  loading,
  companyId,
  getCompanyKits,
  isImpersonatedAdmin,
}) {
  const [openedKitForEdit, setOpenedKitForEdit] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const manageModal = name => () => setActiveModal(name);

  const [page, setPage] = useState(1);

  const handlePageChange = ({ selected }) => setPage(selected + 1);

  async function getKits({ currentPage = page, id = companyId } = {}) {
    try {
      const { results, count } = await getCompanyKits({ id, page: currentPage });

      if (!results.length && count) {
        setPage(1);
        await getKits({ currentPage: 1 });
      }
    } catch (error) {
      notification.error(getError(error));
    }
  }

  useEffect(() => {
    getKits({ currentPage: page });
  }, [page]);

  const updateAllKits = () => getKits();

  return (
    <Styled.Wrapper>
      <Card
        title="Kits"
        actions={hasPermission(PERMISSION_NAMES.ADD_KIT) && !isImpersonatedAdmin
          ? [{
            label: 'Add',
            action: manageModal(MODALS_NAME.ADD),
          }]
          : []
        }
      >
        <KitsTable
          loading={loading}
          data={kits.slice(0, 3)}
          tableSize={TABLES_CONFIG.SIZE.S}
          actions={{
            openEditKit: (id) => {
              manageModal(MODALS_NAME.EDIT)();
              setOpenedKitForEdit(kits.find(kit => kit.id === id));
            },
          }}
          pagination={(
            <Pagination
              pageCount={total / KITS_TABLE.PAGINATION.PER_PAGE}
              pageChangeHandler={handlePageChange}
              activePage={page}
            />
          )}
        />
      </Card>
      <AddKitModal
        open={activeModal === MODALS_NAME.ADD}
        onClose={manageModal(null)}
        updateAllKits={updateAllKits}
      />
      <EditKitModal
        open={activeModal === MODALS_NAME.EDIT}
        onClose={manageModal(null)}
        data={transformKitForEdit(openedKitForEdit || {})}
      />
    </Styled.Wrapper>
  );
}

/* KitsCard type of props */

KitsCard.propTypes = {
  kits: PropTypes.arrayOf(PropTypes.shape({})).isRequired, // TODO: define prop more correctly
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isImpersonatedAdmin: PropTypes.bool,
  getCompanyKits: PropTypes.func.isRequired,
};

KitsCard.defaultProps = {
  isImpersonatedAdmin: false,
};

export default connect(({ kits }) => ({
  kits: kits.kits,
  total: kits.total,
  loaded: kits.loaded,
  loading: kits.loading,
}), {
  getCompanyKits: KitsEntity.actions.getCompanyKits,
})(KitsCard);
