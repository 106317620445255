import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Logo } from 'components';
import { COLORS_CONFIG, LOGO_CONFIG } from 'config';


import * as Styled from './styles';

const GameCardPreviewModal = ({ children, open, onClose }) => {
  const modalStyle = {
    maxWidth: '90%',
    width: '800px',
    height: '600px',
    padding: '48px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <Modal
      open={open}
      backdropColor={COLORS_CONFIG.SMOOTH_BROWN}
      style={modalStyle}
      onClose={onClose}
    >
      <Logo
        type={LOGO_CONFIG.HORIZONTAL_BLUE_BLACK}
        wrapperStyle={{ width: '230px' }}
      />
      <Styled.Content>
        {children}
      </Styled.Content>
    </Modal>
  );
};

GameCardPreviewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

GameCardPreviewModal.defaultProps = {
  open: false,
};

export default GameCardPreviewModal;
