/* Libs */
import styled, { css } from 'styled-components';
import { PLACES_POSITION } from '_constants';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { TextEllipsis } from 'assets/styles/mixins';


export const Location = styled.div`
  font-size: 14px;
  line-height: 1.3;
  width: 100%;
  text-align: center;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  max-width: 200px;
  ${TextEllipsis}
`;

export const TeamName = styled.div`
  padding-top: 17px;
  padding-bottom: 8px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  max-width: 150px;
  ${TextEllipsis}
`;

export const Time = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.WHITE};
  width: 100%;
  text-align: center;
`;

export const Icon = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  & ${Time} {
    ${({ place, isNotFinished }) => place === PLACES_POSITION.FIRST && css`
      font-size: ${(isNotFinished ? '14px' : '16px')};
      bottom: 33%;
    `}
    ${({ place, isNotFinished }) => [
    PLACES_POSITION.SECOND,
    PLACES_POSITION.THIRD,
  ].includes(place)
    && css`
      font-size: ${(isNotFinished ? '9px' : '13px')};
      line-height: ${(isNotFinished ? '9px' : '13px')};
      bottom: calc( 30% + 1px );
    `}
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  ${({ isDarkMode }) => isDarkMode && css`
        
        & ${TeamName}, ${Location}{
          color: ${COLORS_CONFIG.WHITE}
        }
  `}
`;
