/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { CircleWithSign, Img } from 'components';

/* Contants */

import { COLORS_CONFIG } from 'config';

/* Styles */
import { SUCCESS_CROSS, ERROR_CROSS } from 'assets/icons/signs';
import * as Styled from './styles';

function Rule({
  passed,
  name,
}) {
  return (
    <Styled.Wrapper>
      <Img size={[20, 20]} src={passed ? SUCCESS_CROSS : ERROR_CROSS} />
      <Styled.Text>
        {name}
      </Styled.Text>
    </Styled.Wrapper>
  );
}

/* Rule type of props */

Rule.propTypes = {
  passed: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default Rule;
