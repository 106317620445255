/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';

import { Logo } from 'components/blocks/ui/Logo/styles';

export const Wrapper = styled.div`
  width: 596px;
  border-radius: 8px;
  height: 78%;
  min-height: 618px;
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  & ${Logo} {
    width: 160px;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 37px 48px 29px 48px;
`;
