/* Libs */
import styled from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import {
  // Select,
  Wrapper as SelectWrapper,
} from 'components/ui/Select/styles';
import {
  Wrapper as InputWrapper,
} from 'components/ui/Input/styles';
import {
  Wrapper as ToggleBarWrapper,
} from 'components/ui/ToggleBar/styles';
import {
  Wrapper as CheckboxWrapper,
  Shape,
  Label,
} from 'components/ui/Checkbox/styles';
import {
  Button,
} from 'components/ui/Button/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  ${ToggleBarWrapper} {
    margin-bottom: 27px;
  }
  ${InputWrapper} {
    margin-bottom: 23px;
  }
  ${SelectWrapper} {
    min-width: 100%;
    .ReactSelect {
      &__control {
        min-height: 51px;
        border-color: ${COLORS_CONFIG.SKY_BLUE};
        border-radius: 0;
      }
    }
  }
  ${CheckboxWrapper} {
    margin-top: 20px;
    ${Shape} {
      border: 2px solid ${COLORS_CONFIG.SMOOTH_BROWN};
      border-radius: 2px;
      & > div {
        background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
      }
    }
    ${Label} {
      font-size: 15px;
    }
  }
`;

export const Data = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 24px 32px 20px 15px;
  /* height: calc( 100% - 200px ); */
  height: 0;
  overflow-y: auto;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}
`;

export const Info = styled.div`
  flex-grow: 1;
`;

export const SmallTitle = styled.div`
  margin: 33px 0 10px;
  font-size: 13px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Deactivation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

export const DeactivationText = styled.div`
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 32px;
  width: 100%;
  min-height: 120px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10_20};
`;

export const DeleteUser = styled.div`
  position: relative;
  & ${Button} {
    margin-top: 9px;
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
  }
`;
