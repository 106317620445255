import styled from 'styled-components';

import { FONTS_CONFIG } from 'config';

export const Action = styled.div`
  margin-left: 32px;
  padding: 5px 0;
  cursor: pointer;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 12px;
  white-space: nowrap;
`;
