/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import Reapop from 'reapop';
import theme from 'reapop-theme-wybo';
import { connect } from 'react-redux';
import NotificationThemeCustomization from './styles';

function NotificationSystem({ isDarkMode }) {
  return (
    <>
      <Reapop theme={theme} />
      <NotificationThemeCustomization mode={isDarkMode} />
    </>
  );
}


NotificationSystem.propTypes = {
  isDarkMode: PropTypes.bool,
};

NotificationSystem.defaultProps = {
  isDarkMode: false,
};


export default connect(({ staticData }) => ({
  isDarkMode: staticData.isDarkMode,
}), null)(NotificationSystem);
