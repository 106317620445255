/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

import { Logo } from 'components';

import { LOGO_CONFIG } from 'config';
import { isEmpty } from 'utils/validation';
import * as Styled from './styles';


function Watermark({
  logo,
  isDarkMode,
}) {
  if (isEmpty(logo)) return null;
  return (
    <Styled.WaterMark>
      <Logo type={isDarkMode ? LOGO_CONFIG.HORIZONTAL_WHITE_WHITE : LOGO_CONFIG.HORIZONTAL_BLUE_BLACK} />
    </Styled.WaterMark>
  );
}

Watermark.propTypes = {
  logo: PropTypes.string,
  isDarkMode: PropTypes.bool,
};

Watermark.defaultProps = {
  logo: null,
  isDarkMode: false,
};

export default Watermark;
