/* Libs */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce, isNaN } from 'lodash';

/* Actions */

import { GameDefEntity } from '_entities';

/* Components */

import {
  TemplatesStruct,
  SearchInput,
  TemplatesTable,
  Pagination,
} from 'components';

/* Utils */
import { notification } from 'utils/services';
import { getError, replaceQueryParams } from 'utils/custom';

/* Constants */

import { URLS_CONFIG } from 'config';

const PAGE_TITLE = 'Templates';

function Templates({
  getTemplates,
  location: { search },
}) {
  const [data, setData] = useState({
    templates: [],
    total: null,
    loading: false,
  });
  const [searchInput, setSearch] = useState('');
  const [page, setPage] = useState(1);

  async function fetchTemplates({ currentPage = page, name = searchInput } = {}) {
    try {
      setData(prevState => ({
        ...prevState,
        loading: true,
      }));
      const { results, count } = await getTemplates({
        name,
        company: 'null',
        page: currentPage,
      });
      if (!results.length && count) {
        setPage(1);
        replaceQueryParams({ page: 1 });
        fetchTemplates({ currentPage: 1 });
        setData(prevState => ({
          ...prevState,
          loading: false,
        }));
      } else {
        replaceQueryParams({ page: currentPage });

        setData(prevState => ({
          ...prevState,
          templates: results,
          total: count,
          loading: false,
        }));
      }
    } catch (error) {
      notification.error(getError(error));
      setData(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  }

  const getTemplateRequest = useCallback(debounce(fetchTemplates, 300), []);


  useEffect(() => {
    const urlsParams = new URLSearchParams(search);
    const searchParamsPage = +urlsParams.get('page');
    const queryPage = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;
    setPage(queryPage);
    fetchTemplates({ currentPage: queryPage });
  }, []);


  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
    setPage(1);
    getTemplateRequest({ currentPage: 1, name: value });
  };
  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
    fetchTemplates({ currentPage: selected + 1 });
  };

  return (
    <TemplatesStruct
      title={PAGE_TITLE}
      search={(
        <SearchInput
          name="search"
          value={searchInput}
          onChange={handleSearch}
          placeholder="Search a template"
        />
      )}
    >
      <TemplatesTable
        data={data.templates}
        loading={data.loading}
        pagination={(
          <Pagination
            pageCount={data.total / 10}
            pageChangeHandler={handlePageChange}
            activePage={page}
            withInitialPage={false}
          />
        )}
      />
    </TemplatesStruct>
  );
}

/* Page Title */

Templates.title = PAGE_TITLE;
/* Page Url */

Templates.path = URLS_CONFIG.admin.templates;

Templates.propsTypes = {
  getTemplates: PropTypes.func.isRequired,
};

export default connect(null, {
  getTemplates: GameDefEntity.actions.getAllGameDef,
})(Templates);
