/* Libs */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Utils */
import { isEmpty, isNull } from 'utils/validation';
import { URLS_CONFIG } from 'config';
// import { hasPermission } from 'utils/custom';
// import { PERMISSION_NAMES } from '_constants';

function HostRoute({
  authorized, isHost, component: Component, ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const redirect = get(
          props,
          'location.state.from.pathname',
          URLS_CONFIG.auth.signin,
        );
        const history = [
          props.history.location.pathname,
          ...get(props, 'location.state.history', []),
        ];
        return isHost ? (
          // return hasPermission(PERMISSION_NAMES.CAN_VIEW_COMPANY_ADMIN_PAGES) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              search: get(props, 'location.state.from.search', ''),
              state: { from: props.location, history },
            }}
          />
        );
      }}
    />
  );
}

/* Component PropTypes */

HostRoute.propTypes = {
  authorized: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func])
    .isRequired,
  isHost: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth, staticData }) => ({
  authorized: !isEmpty(auth.token) && !isNull(auth.id),
  isHost: [
    staticData.roles.company_admin,
    staticData.roles.admin,
    staticData.roles.host,
    staticData.roles.company_host,
  ].includes(auth.role),
});

export default connect(mapStateToProps)(HostRoute);
