/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { SmallTitle } from 'components/blocks/ui/SmallTitle/styles';
import { Wrapper as LoaderWrapper } from 'components/blocks/ui/Loader/styles';
import { Pagination } from 'components/ui/Pagination/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const StorylinesList = styled.div`
  flex-grow: 1;
  margin: 16px 0;
  width: 100%;
  overflow: auto;
  height: 333px;
  ${() => CustomScroll({ scrollBG: COLORS_CONFIG.WHITE })}
`;

export const Storyline = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 14px 24px;
  width: 100%;
  height: 72px;
  background: ${COLORS_CONFIG.SKY_BLUE};
  border-radius: 8px;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StorylineName = styled.div`
  flex-grow: 1;
  margin-left: 16px;
  color: ${COLORS_CONFIG.WHITE};
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NoStorylines = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: center;
  height: 100%
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${SmallTitle} {
    font-size: 14px;
  }
  ${LoaderWrapper} {
    padding: 32px;
  }
  ${Pagination} {
    margin-bottom: 10px;
  }
  
  ${({ withScroll }) => withScroll && css`
      
      & ${StorylinesList}{
          padding: 0 16px 0 0;  
      }
  `}
`;
