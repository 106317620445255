/* Libs */
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Utils */
import { isEmpty, isNull } from 'utils/validation';
import { URLS_CONFIG } from 'config';
import { isStuckInLoop } from 'utils/custom';

// ----------------
function AuthRoute({
  authorized,
  isAdmin,
  isCompanyAdmin,
  isHost,
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const history = [props.history.location.pathname, ...get(props, 'location.state.history', [])];
        const canGoFrom = !isStuckInLoop(history, get(props, 'location.state.from.pathname', false));
        const newUrl = isAdmin
          ? URLS_CONFIG.admin.default
          : isCompanyAdmin
            ? URLS_CONFIG.companyAdmin.default
            : isHost
              ? URLS_CONFIG.host.default
              : URLS_CONFIG.auth.signin;
        let redirect = canGoFrom ? get(props, 'location.state.from.pathname', false) : false;
        redirect = redirect || newUrl;
        return !authorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              search: get(props, 'location.state.from.search', ''),
              state: {
                from: props.location,
                history,
              },
            }}
          />
        );
      }}
    />
  );
}

// Type of props
AuthRoute.propTypes = {
  authorized: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCompanyAdmin: PropTypes.bool.isRequired,
  isHost: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth, staticData }) => ({
  authorized: !isEmpty(auth.token) && !isNull(auth.id),
  isAdmin: auth.role === staticData.roles.admin,
  isCompanyAdmin: auth.role === staticData.roles.company_admin,
  isHost: [staticData.roles.host, staticData.roles.company_host].includes(auth.role),
});

export default connect(mapStateToProps)(AuthRoute);
