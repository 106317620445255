/* Libs */

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { merge, isEmpty } from 'lodash';

/* Utils */
import { validateNumber, validateText } from 'utils/validation/fields';
import {
  isValidNumbersField,
  hasDigit,
  isMoreThan,
  hasCapitalLetter,
} from 'utils/validation';
import { notification } from 'utils/services';
import {
  getError, hasPermission, getTimezoneName,
} from 'utils/custom';


/* Constants */
import { PASSWORD_RULES } from '_constants/rules';
import {
  NOTIFICATIONS,
  PARTICIPANTS_COUNT_RANGE,
  TIME_OPTIONS,
  MAX_NOTIFICATION_DAYS,
  PERMISSION_NAMES,
} from '_constants';

/* Actions */

import { AdminCompanyEntity } from '_entities';

/* Components */

import {
  Modal,
  SettingsSection,
  Select,
  Input,
  Switcher,
  Loader,
  ImgCropper,
  TimezoneSelect,
} from 'components';

/* Styles */
import * as Styled from './styles';

const DEFAULT_DATA = {
  introductionTime: null,
  debriefingTime: null,
  participantsPerSession: null,
  company_code: '',
  company_password: '',
  logo: '',
  timezone: null,
};

const DEFAULT_ERRORS = {
  participantsPerSession: false,
  notificationDays: null,
  company_code: null,
  company_password: null,
};

function GlobalSettingsModal({
  onClose,
  open,
  company: {
    introduction_time: introductionTime,
    debriefing_time: debriefingTime,
    participants_per_session: participantsPerSession,
    is_timer_counting_up: isTimerCountingUp,
    is_secret_mode: isSecretMode,
    company_code: companyCode,
    company_password: companyPassword,
    logo,
    days_to_notify: daysToNotify,
    timezone,
  },
  getCompany,
  updateCompany,
  companyId,
}) {
  if (!open) return null;
  if (!companyId) return notification.error('No company id !');

  const [loading, setLoading] = useState(false);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [data, updateData] = useState(DEFAULT_DATA);
  const [errors, setErrors] = useState(DEFAULT_ERRORS);
  const [isSecretModeOn, setSecretMode] = useState(false);
  const [isTimerCountingUpOn, setTimerType] = useState(false);
  const [isNotificationsSentOn, setNotificationsState] = useState(false);
  const [isOpenRules, setOpensRules] = useState(false);
  const refDaysBefore = useRef(null);
  const isMounted = useRef(false);
  const checkRules = (value) => {
    if (isEmpty(value)) return;
    return [
      { name: PASSWORD_RULES._8Char, passed: isMoreThan(value, 7, true) },
      { name: PASSWORD_RULES._1Capital, passed: hasCapitalLetter(value) },
      { name: PASSWORD_RULES.digits, passed: hasDigit(value) },
    ];
  };

  const changeData = ({ target: { name, value } }) => updateData({
    ...data,
    [name]: value,
  });

  const handleChange = ({ target: { name, value } }) => {
    const newErrors = { ...errors };

    switch (name) {
      case 'company_code':
        newErrors[name] = validateText({
          value,
          name,
          max: 254,
        });
        break;
      case 'company_password':
        newErrors[name] = validateText({
          value,
          name,
        }) || (checkRules(value).some(({ passed }) => !passed) && NOTIFICATIONS.ERROR_PASSWORD_DOES_NOT_MEET_REQUIREMENTS);
        break;
      default:
        break;
    }

    changeData({ target: { name, value: value.trim() } });
    setErrors(newErrors);
  };


  const validateOnBlur = ({ target: { name, value } }) => {
    const newErrors = { ...errors };

    switch (name) {
      case 'company_code':
        newErrors[name] = validateText({
          value,
          name,
          max: 254,
        });
        break;
      case 'company_password':
        newErrors[name] = validateText({
          value,
          name,
        }) || (checkRules(value).some(({ passed }) => !passed) && NOTIFICATIONS.ERROR_PASSWORD_DOES_NOT_MEET_REQUIREMENTS);
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const toggleSecretMode = () => setSecretMode(prev => !prev);
  const toggleTimerType = () => setTimerType(prev => !prev);
  const toggleNotifications = () => setNotificationsState(prev => !prev);
  const changeNotificationDays = ({ target: { name, value } }) => {
    if (!isValidNumbersField(value) || +value > MAX_NOTIFICATION_DAYS) return;
    changeData({ target: { name, value } });
    setErrors(prev => ({
      ...prev,
      notificationDays: validateNumber({ value, required: false }),
    }));
  };
  const changeNotificationDaysOnBlur = ({ target: { name, value } }) => {
    if (!isValidNumbersField(value) || +value > MAX_NOTIFICATION_DAYS) return;
    const notifyValue = isEmpty(value) ? 1 : value;
    changeData({ target: { name, value: notifyValue } });
    setErrors(prev => ({
      ...prev,
      notificationDays: validateNumber({ value: notifyValue, required: false }),
    }));
  };
  const changeParticipantsPerSession = ({ target: { name, value } }) => {
    if (!isValidNumbersField(value) || +value > PARTICIPANTS_COUNT_RANGE.MAX) return;
    changeData({ target: { name, value } });
    setErrors(prev => ({
      ...prev,
      participantsPerSession: validateNumber({ value, required: false }),
    }));
  };

  const changeParticipantsPerSessionOnBlur = ({ target: { name, value } }) => {
    if (!isValidNumbersField(value) || +value > PARTICIPANTS_COUNT_RANGE.MAX) return;
    const participantValue = isEmpty(value) ? PARTICIPANTS_COUNT_RANGE.MIN : value;
    changeData({ target: { name, value: participantValue } });
    setErrors(prev => ({
      ...prev,
      participantsPerSession: validateNumber({ value: participantValue, required: false }),
    }));
  };

  const submitHandler = async () => {
    const requestData = merge(
      { id: companyId },
      introductionTime === data.introductionTime ? {} : { introduction_time: data.introductionTime },
      debriefingTime === data.debriefingTime ? {} : { debriefing_time: data.debriefingTime },
      participantsPerSession == data.participantsPerSession ? {} : { participants_per_session: +data.participantsPerSession },
      isTimerCountingUpOn === isTimerCountingUp ? {} : { is_timer_counting_up: isTimerCountingUpOn },
      (
        !isNotificationsSentOn && Boolean(daysToNotify)
      )
      || (
        isNotificationsSentOn
        && data.notificationDays
        && data.notificationDays !== daysToNotify
      )
        ? { days_to_notify: isNotificationsSentOn ? +data.notificationDays : '' }
        : {},
      isSecretMode === isSecretModeOn ? {} : { is_secret_mode: isSecretModeOn },
      isSecretModeOn && data.company_code && (companyCode !== data.company_code)
        ? { company_code: data.company_code }
        : {},
      isSecretModeOn && data.company_password && (companyPassword !== data.company_password)
        ? { company_password: data.company_password }
        : {},
      timezone === data.timezone ? {} : { timezone: data.timezone },
      logo === data.logo ? {} : { logo: data.logo },
    );
    try {
      setSubmitLoading(true);
      await updateCompany(requestData);
      setSubmitLoading(false);
      notification.success(NOTIFICATIONS.SUCCESS_UPDATE_COMPANY);
      onClose();
    } catch (error) {
      setSubmitLoading(false);
      const newError = getError(error);

      if (newError.trim() === NOTIFICATIONS.ERROR_COMPANY_CODE_ALREADY_IN_USE) {
        setErrors(prev => ({
          ...prev,
          company_code: newError,
        }));
      } else {
        notification.error(getError(error));
      }
    }
  };

  const sectionParts = [
    {
      subTitle: 'Introduction time',
      components: [
        <Select
          name="introductionTime"
          key="introductionTime"
          options={TIME_OPTIONS}
          value={data.introductionTime}
          onChange={changeData}
          placeholder="Select introduction time"
          error={false}
          withoutReset
        />,
      ],
    },
    {
      subTitle: 'Debriefing time',
      components: [
        <Select
          name="debriefingTime"
          key="debriefingTime"
          options={TIME_OPTIONS}
          value={data.debriefingTime}
          onChange={changeData}
          placeholder="Select debriefing time"
          withoutReset
          error={false}
        />,
      ],
    },
    {
      subTitle: 'Participants per session',
      infoText: `The number of participants should be from ${PARTICIPANTS_COUNT_RANGE.MIN} to ${PARTICIPANTS_COUNT_RANGE.MAX}`,
      components: [
        <Input
          name="participantsPerSession"
          key="participantsPerSession"
          value={data.participantsPerSession}
          onChange={changeParticipantsPerSession}
          onBlur={changeParticipantsPerSessionOnBlur}
          placeholder="Enter participants per session"
          error={errors.participantsPerSession}
          hideErrorText
        />,
      ],
    },
    {
      subTitle: 'Game timer type',
      components: [
        <Switcher
          checked={isTimerCountingUpOn}
          onChange={toggleTimerType}
          checkedText="Count up"
          uncheckedText="Count down"
          key="timerType"
        />,
      ],
    },
  ]
    .concat(
      hasPermission(PERMISSION_NAMES.SET_NOTIFICATION_DAYS)
        ? [
          {
            subTitle: 'Send notifications',
            subTitleAdding: '(optional)',
            infoText: 'Notify a participant after how many days a session would begin',
            components: [
              <Switcher
                checked={isNotificationsSentOn}
                onChange={toggleNotifications}
                checkedText="Yes"
                uncheckedText="No"
                key="notifications"
              />,
            ],
          },
          {
            subTitle: 'Days before',
            components: [
              <Input
                name="notificationDays"
                key="notificationDays"
                value={data.notificationDays}
                onChange={changeNotificationDays}
                onBlur={changeNotificationDaysOnBlur}
                placeholder="Enter notification days"
                isDisabled={!isNotificationsSentOn}
                forwardedRef={refDaysBefore}
                error={errors.notificationDays}
                hideErrorText
              />,
            ],
          },
        ]
        : [],
    )
    .concat(
      {
        subTitle: 'Collect email addresses',
        subTitleAdding: '(optional)',
        components: [
          <Switcher
            checked={!isSecretModeOn}
            onChange={toggleSecretMode}
            checkedText="Yes"
            uncheckedText="No"
            key="collectEmailAddresses"
          />,
        ],
      },
      {
        subTitle: 'Assign a company code and password',
        infoText: 'As soon as collect email addresses setting is turned off these fields are required',
        components: [
          <Input
            name="company_code"
            key="company_code"
            value={data.company_code}
            onChange={handleChange}
            onBlur={validateOnBlur}
            placeholder="Enter company code"
            isDisabled={!isSecretModeOn}
            error={errors.company_code}
          />,
          <Input
            name="company_password"
            key="company_password"
            value={data.company_password}
            onChange={handleChange}
            onBlur={validateOnBlur}
            placeholder="Enter company password"
            isDisabled={!isSecretModeOn}
            rules={checkRules(data.company_password)}
            setOpensRules={setOpensRules}
            isOpenRules={isOpenRules}
            error={errors.company_password}
          />,
        ],
        full: true,
      },
      {
        subTitle: 'Sessions default timezone',
        infoText: 'If that field is empty than timezone will be determined from your OS',
        components: [
          <TimezoneSelect
            onChange={handleChange}
            value={data.timezone}
            selectProps={{
              name: 'timezone',
              searchable: true,
              placeholder: 'Choose your default timezone',
              withoutReset: true,
              error: isEmpty(data.timezone) ? null : false,
            }}
          />,
        ],
        full: true,
      },
      {
        subTitle: 'Company logo',
        subTitleAdding: '(optional)',
        full: true,
        components: [
          <ImgCropper
            value={data.logo}
            onChange={changeData}
            name="logo"
            key="logo"
          />,
        ],
      },
    );
  const isSubmitButtonEnable = () => (introductionTime !== data.introductionTime
      || debriefingTime !== data.debriefingTime
      || participantsPerSession != data.participantsPerSession
      || isTimerCountingUp !== isTimerCountingUpOn
      || data.logo != logo
      || timezone !== data.timezone
      || (!isNotificationsSentOn && Boolean(daysToNotify))
      || (isNotificationsSentOn
        && data.notificationDays
        && (
          isNotificationsSentOn !== Boolean(daysToNotify)
          || data.notificationDays != daysToNotify
        )
      )
      || (!isSecretModeOn && (isSecretMode !== isSecretModeOn))
      || (isSecretModeOn
        && data.company_code
        && data.company_password

        && checkRules(data.company_password).every(({ passed }) => passed)
        && (
          isSecretMode !== isSecretModeOn
          || companyCode !== data.company_code
          || companyPassword !== data.company_password
        )
      )) && !(errors.company_code
              || errors.company_password);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const {
          introduction_time,
          debriefing_time,
          participants_per_session,
          is_timer_counting_up,
          is_secret_mode,
          company_code,
          company_password,
          logo,
          days_to_notify,
          timezone: companyTimezone,
        } = await getCompany(companyId);

        setLoading(false);
        setTimerType(is_timer_counting_up);
        setSecretMode(is_secret_mode);
        setNotificationsState(Boolean(days_to_notify));
        updateData({
          introductionTime: introduction_time,
          debriefingTime: debriefing_time,
          participantsPerSession: participants_per_session,
          company_code,
          company_password,
          logo,
          notificationDays: days_to_notify || '',
          timezone: companyTimezone && getTimezoneName(companyTimezone),
        });
        setErrors(prev => ({
          ...prev,
          company_code: validateText({ value: company_code, required: false }),
          company_password: validateText({ value: company_password, required: false }),
          notificationDays: validateText({ value: days_to_notify, required: false }),
        }));
        isMounted.current = true;
      } catch (error) {
        isMounted.current = true;
        setLoading(false);
        notification.error(getError(error));
        onClose();
      }
    })();
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;
    if (isNotificationsSentOn) refDaysBefore.current.focus();
    else {
      changeData({ target: { name: 'notificationDays', value: '' } });
      setTimeout(() => setErrors(prev => ({
        ...prev,
        notificationDays: null,
      })), 300);
    }
  }, [isNotificationsSentOn]);

  return (
    <Modal
      title="Global settings"
      open={open}
      onClose={onClose}
      style={{ maxWidth: '680px' }}
    >
      <Styled.GlobalSettingsModal>
        <SettingsSection
          onClose={onClose}
          onSubmit={submitHandler}
          sectionParts={sectionParts}
          isSubmitButtonEnable={isSubmitButtonEnable()}
          loading={isSubmitLoading}
        />
        {loading && <Loader />}
      </Styled.GlobalSettingsModal>
    </Modal>
  );
}

// Type of props
GlobalSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    introduction_time: PropTypes.string,
    debriefing_time: PropTypes.string,
    participants_per_session: PropTypes.number,
    is_secret_mode: PropTypes.bool,
  }),
  getCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  companyId: PropTypes.number,
};

// Default value for props
GlobalSettingsModal.defaultProps = {
  company: {},
  companyId: null,
};

const mapStateToProps = state => ({
  company: state.adminCompany.company,
  companyId: state.auth.company,
});

export default connect(mapStateToProps, {
  getCompany: AdminCompanyEntity.actions.getCompany,
  updateCompany: AdminCompanyEntity.actions.updateCompany,
})(GlobalSettingsModal);
