/* Libs */

import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { Modal, SignupParticipantsConfirm } from 'components';

/* Styles */

// import * as Styled from './styles';


function SignupParticipantsConfirmModal({
  open,
  onClose,
  ...restProps
}) {
  if (!open) return null;

  return (
    <Modal
      open={open}
      style={{ maxWidth: '660px' }}
      onClose={onClose}
      withoutCloseCross
    >
      <SignupParticipantsConfirm {...restProps} />
    </Modal>
  );
}

// Type of props
SignupParticipantsConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignupParticipantsConfirmModal;
