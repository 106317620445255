
import { pickBy, isUndefined } from 'lodash';
import queryString from 'query-string';

import { GAME_DEF_TABLE } from 'config';
import * as TYPES from './GameDef.types';

export const getGameDefById = id => ({
  types: [
    TYPES.GET_GAME_DEF_BY_ID,
    TYPES.GET_GAME_DEF_BY_ID_SUCCESS,
    TYPES.GET_GAME_DEF_BY_ID_FAIL,
  ],
  promise: client => client.get(`/game-defs/${id}/`),
});

export const getCompanyGameDef = (id, { page = 1, perPage = GAME_DEF_TABLE.PAGINATION.PER_PAGE }) => ({
  types: [TYPES.GET_COMPANY_GAME_DEF, TYPES.GET_COMPANY_GAME_DEF_SUCCESS, TYPES.GET_COMPANY_GAME_DEF_FAIL],
  promise: client => client.get(`/game-defs/?company=${id}&offset=${(page - 1) * perPage}&limit=${perPage}`),
});

export const getAllGameDef = (data = {}) => {
  const {
    limit = 10,
    name,
    page = 1,
    perPage = 10,
    company = 'null',
  } = data;
  const offset = (page - 1) * perPage;
  const params = queryString.stringify(
    pickBy({
      company,
      limit,
      name,
      offset: page && perPage ? offset : undefined,
    }, item => !isUndefined(item)),
  );

  return {
    types: [TYPES.GET_ALL_GAME_DEF, TYPES.GET_ALL_GAME_DEF_SUCCESS, TYPES.GET_ALL_GAME_DEF_FAIL],
    promise: client => client.get(`/game-defs/${params ? `?${params}` : ''}`),
  };
};

export const createCompanyGameDef = data => ({
  types: [
    TYPES.CREATE_COMPANY_GAME_DEF,
    TYPES.CREATE_COMPANY_GAME_DEF_SUCCESS,
    TYPES.CREATE_COMPANY_GAME_DEF_FAIL,
  ],
  promise: client => client.post('/game-defs/', data),
});

export const updateCompanyGameDef = data => ({
  types: [
    TYPES.UPDATE_COMPANY_GAME_DEF,
    TYPES.UPDATE_COMPANY_GAME_DEF_SUCCESS,
    TYPES.UPDATE_COMPANY_GAME_DEF_FAIL,
  ],
  promise: client => client.put(`/game-defs/${data.id}/`, data),
});

export const deleteGameDef = id => ({
  types: [
    TYPES.DELETE_COMPANY_GAME_DEF,
    TYPES.DELETE_COMPANY_GAME_DEF_SUCCESS,
    TYPES.DELETE_COMPANY_GAME_DEF_FAIL,
  ],
  promise: client => client.delete(`/game-defs/${id}/`),
});

export const downloadGameDef = id => ({
  types: [TYPES.DOWNLOAD_GAME_DEF, TYPES.DOWNLOAD_GAME_DEF_SUCCESS, TYPES.DOWNLOAD_GAME_DEF_FAIL],
  promise: client => client.get(`/game-defs/${id}/download/`),
});

export const importGameDef = data => ({
  types: [TYPES.IMPORT_GAME_DEF, TYPES.IMPORT_GAME_DEF_SUCCESS, TYPES.IMPORT_GAME_DEF_FAIL],
  promise: client => client.post('/game-defs/import/', data),
});
