/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG } from 'config';

export const Logo = styled.div`
  display: flex;
  align-items: center;
  width: 205px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;


export const Wrapper = styled.div`
  position: relative;
  padding: 0 20px;
  height: 100%;
  background-color: ${COLORS_CONFIG.WHITE};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  
  ${({ isDarkMode }) => isDarkMode && css`
      background-color: ${COLORS_CONFIG.NAVY_BLUE};
  `}
`;
