/* Libs */
import styled, { css } from 'styled-components';

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

import { Content } from './Subcomponents/Cell/styles';

export const Wrapper = styled.div`
  & .react-calendar {
    width: 100%;
    border: none;

    &__tile {
      position: relative;
      cursor: initial;
      width: 32px;
      height: 50px;

      & ${Content}:hover {
        background-color: ${COLORS_CONFIG.SILVER};
      }

      &:enabled:focus,
      &:enabled:hover {
        cursor: initial;
        background: none;
      }

      & > abbr {
        display: none;
      }

      &--active {
        background: none;
        & ${Content} {
          background-color: ${({ isDayVisible }) => (isDayVisible ? `${COLORS_CONFIG.SKY_BLUE}!important` : 'none')};
          color: ${({ isDayVisible }) => COLORS_CONFIG[isDayVisible ? 'WHITE' : 'SMOOTH_BROWN']};
          font-family: ${({ isDayVisible }) => FONTS_CONFIG[isDayVisible ? 'MAIN_TEXT_FONT_BOLD' : 'MAIN_TEXT_FONT_REGULAR']};
        }
      }

      ${({ isCurrentDayVisible }) => isCurrentDayVisible && css`
        &--now {
          &:not(:disabled){
            background: none;
            & ${Content} {
              background-color: ${COLORS_CONFIG.SILVER};
            }
          }
        }
      `}
    }

    &__month-view {
      &__weekdays__weekday {
        margin-bottom: 16px;
        padding: 0;
        font-size: 14px;
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        & > abbr {
          text-decoration: none;
        }
      }
      &__days__day--neighboringMonth {
        & ${Content} {
          color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
          font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        }
      }
    }
  }
`;
