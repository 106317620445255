/* Libs */
import styled from 'styled-components';

/* Components */
import { SmallTitle } from 'components/blocks/ui/SmallTitle/styles';
import { Button } from 'components/ui/Button/styles';


export const Wrapper = styled.div`
  ${SmallTitle} {
    margin-bottom: 24px;
  }
`;

export const Block = styled.div`
  margin-bottom: 24px;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > *:first-child {
    flex: 1;
  }
  & > *:last-child {
    margin-left: 24px;
    flex: 1;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;

  ${Button} {
    margin: 0 5px;
  }
`;
