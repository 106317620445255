import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';

import { Button } from 'components/ui/Button/styles';


export const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 19px);
  right: 0;
  z-index: 2;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 4px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.CONFIRM_SHADOW};
  overflow: hidden;

  ${Button} {
    padding: 0 10px;
    min-width: 90px;
    height: 36px;
    border-radius: 0;
    font-size: 15px;
  }
`;

export const ConfirmText = styled.div`
  padding: 15px 13px;
  font-size: 17px;
`;
