/* Libs */
import React from 'react';
import PropTypes from 'prop-types';


/* Styles */

import * as Styled from './styles';

function BigTitle({
  children,
}) {
  return (
    <Styled.BigTitle>
      {children}
    </Styled.BigTitle>
  );
}

/* BigTitle type of props */

BigTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BigTitle;
