/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Components */

import { GAME_CONFIG } from 'config';
import {
  NormalView,
  AnonymousView,
} from './Views';

/* Constants */


/* Styles */

import * as Styled from './styles';

function Participants({
  mode,
  isAnonymous,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Title>
        Participants
      </Styled.Title>
      { isAnonymous ? <AnonymousView mode={mode} /> : <NormalView mode={mode} /> }
    </Styled.Wrapper>
  );
}

/* Participants type of props */

Participants.propTypes = {
  mode: PropTypes.oneOf(Object.values(GAME_CONFIG.MANAGER.MODE)).isRequired,
  isAnonymous: PropTypes.bool.isRequired,
};

export default connect(({ manageGame }) => ({
  isAnonymous: manageGame.gameSession.is_anonymous,
}))(Participants);
