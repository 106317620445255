/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Utils */
import { formIsValid } from 'utils/validation';
import { validateEmail } from 'utils/validation/fields';

/* Components */
import {
  Input,
  Button,
  LaunchHeader,
  ParticipantLaunchItem,
  ParticipantsLaunchList,
  CircleWithSign,
} from 'components';

/* Constants */
import { COLORS_CONFIG } from 'config';
import { COMMON_ERRORS } from '_constants/errors';

/* Styles */
import * as Styled from './styles';


const INITIAL_DATA = {
  email: null,
};

const DEFAULT_ERRORS = {
  email: null,
};

function Normal({
  location,
  utc_start_time,
  isFastSession,
  newParticipants,
  signedupParticipants,
  handleToggleActive,
  handleDelete,
  handleAdd,
  next,
  back,
  isDarkMode,
}) {
  const [formData, setFormData] = useState({
    data: INITIAL_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };

    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: 254,
          required: false,
        });
        break;
      default:
        break;
    }

    setFormData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: 254,
          required: false,
        });
        break;
      default:
        break;
    }
    setFormData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    }));
  };


  const submit = () => {
    if (newParticipants.find(participant => participant.email === formData.data.email)
      || signedupParticipants.find(participant => participant.email === formData.data.email)
    ) {
      setFormData(prev => ({
        ...prev,
        errors: {
          ...prev.errors,
          email: COMMON_ERRORS.participantIsAlreadyExists,
        },
        canSubmit: false,
      }));
      return;
    }
    handleAdd({ email: formData.data.email });
    setFormData(prev => ({
      ...prev,
      data: {
        ...prev.data,
        email: null,
      },
      canSubmit: false,
    }));
  };

  const {
    data: {
      email,
    },
    errors,
  } = formData;

  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <LaunchHeader
        title="Launching Game!"
        location={location}
        utcStartTime={utc_start_time}
        subtitle={!isFastSession}
      />
      <Styled.AddNew disabled={!formData.canSubmit}>
        <Input
          placeholder="Participant’s email"
          name="email"
          type="email"
          value={email}
          error={errors.email}
          onChange={handleChange}
          onBlur={validateOnBlur}
        />
        <CircleWithSign disabled={!formData.canSubmit} onClick={submit} color={COLORS_CONFIG.WHITE} sign="+" />
      </Styled.AddNew>
      <ParticipantsLaunchList>
        {newParticipants.map(participant => (
          <ParticipantLaunchItem
            email={participant.email}
            key={participant.email}
            deleteParticipant={handleDelete(participant.email)}
            isNew
          />
        ))}
        {signedupParticipants.map(participant => (
          <ParticipantLaunchItem
            email={participant.email}
            key={participant.email}
            isActive={participant.isActive}
            handleToggleActive={handleToggleActive(participant.id)}
            isNew={false}
          />
        ))}
      </ParticipantsLaunchList>
      <Styled.Actions>
        <Button onClick={next}>
          Next
        </Button>
        <Button onClick={back}>
          Cancel
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* Normal type of props */

Normal.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.shape({
      location: PropTypes.string.isRequired,
    }),
    PropTypes.number,
  ]).isRequired,
  utc_start_time: PropTypes.string,
  isFastSession: PropTypes.bool,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  signedupParticipants: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.isRequired,
    id: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
  })).isRequired,
  newParticipants: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.isRequired,
  })).isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

/* Normal default props */

Normal.defaultProps = {
  utc_start_time: null,
  isFastSession: false,
};

export default Normal;
