/* Libs */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { ManageGameEntity } from '_entities';

/* Utils */

import { isValidEmail } from 'utils/validation';
import { notification } from 'utils/services';

/* Components */

import {
  ManageGameParticipantListItem,
  Input,
  Img,
  ManageGameParticipantsList,
} from 'components';

/* Constants */

import { GAME_CONFIG } from 'config';
import { COMMON_ERRORS } from '_constants/errors';
import { NOTIFICATIONS } from '_constants';

/* Styles */

import { ADD_CIRCLE_BLUE } from 'assets/icons';
import * as Styled from './styles';


function NormalView({
  addParticipant,
  deleteParticipant,
  mode,
  participants,
  loading,
}) {
  const [newParticipantEmail, setNewParticipant] = useState('');
  const handleChange = ({ target: { value } }) => setNewParticipant(value);
  const submit = async () => {
    if (loading) return;
    if (!isValidEmail(newParticipantEmail)) {
      return notification.error(COMMON_ERRORS.email);
    }
    if (participants.find(participant => participant.email === newParticipantEmail)) {
      return notification.error(COMMON_ERRORS.participantIsAlreadyExists);
    }
    try {
      await addParticipant(newParticipantEmail);
      notification.success(NOTIFICATIONS.SUCCESS_ADDED_PARTICIPANT_EMAIL);
    } catch (error) {
      notification.error(error);
    }
    setNewParticipant('');
  };
  const participantsList = useMemo(() => participants.map(participant => (
    <ManageGameParticipantListItem
      mode={mode}
      id={participant.id}
      key={participant.id}
      email={participant.email}
      deleteParticipant={deleteParticipant}
      disabled={participants.length === 1}
    />
  )), [participants]);

  return (
    <Styled.Wrapper>
      { mode === GAME_CONFIG.MANAGER.MODE.EDIT
        && (
          <Styled.InputGroup>
            <Input
              placeholder="Participant’s email"
              name="newParticipantEmail"
              value={newParticipantEmail}
              onChange={handleChange}
            />
            <Img
              onClick={submit}
              src={ADD_CIRCLE_BLUE}
              size={[34, 34]}
              disabled={!isValidEmail(newParticipantEmail)}
              grayscaleOnDisabled
            />
          </Styled.InputGroup>
        )
      }
      <ManageGameParticipantsList>
        {participantsList}
      </ManageGameParticipantsList>
    </Styled.Wrapper>
  );
}

/* NormalView type of props */

NormalView.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  })),
  mode: PropTypes.oneOf(Object.values(GAME_CONFIG.MANAGER.MODE)).isRequired,
};

export default connect(({ manageGame }) => ({
  loading: manageGame.loading,
  participants: manageGame.participants,
}), {
  deleteParticipant: ManageGameEntity.actions.deleteParticipant,
  addParticipant: ManageGameEntity.actions.addParticipant,
})(NormalView);
