/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Utils */

import { goBack } from 'utils/custom';

/* Components */

import { GoLabel } from 'components';

/* Styles */

import * as Styled from './styles';

function AdminCompaniesStruct({
  title,
  actions,
  search,
  goBack,
  children,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Top>
        { get(goBack, 'enabled', false) && <GoLabel text={goBack.text} click={goBack} /> }
      </Styled.Top>
      <Styled.Header>
        <Styled.Title>
          {title}
          {search}
        </Styled.Title>
        <Styled.Actions>
          {actions.map(action => (action.title
            ? (
              <Styled.Action key={action.title} onClick={action.action}>
                {action.title}
              </Styled.Action>
            )
            : action
          ))}
        </Styled.Actions>
      </Styled.Header>
      <Styled.Content>
        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* AdminCompaniesStruct type of props */

AdminCompaniesStruct.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  goBack: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
  }),
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
    PropTypes.node,
  ])),
};

/* AdminCompaniesStruct default props */

AdminCompaniesStruct.defaultProps = {
  goBack: null,
  actions: [],
};

export default AdminCompaniesStruct;
