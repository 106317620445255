/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Button } from 'components/ui/Button/styles';

export const Actions = styled.div`
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 32px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;
export const Hint = styled.div`
  font-size: 18px;
  text-align: center;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  padding-top: 21px;
  padding-bottom: 24px;
`;

export const Statistic = styled.div`
  width: 100%;
`;

export const GameFailedModal = styled.div`
  padding: 58px 120px;
  width: 660px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${COLORS_CONFIG.WHITE};
  & ${Button}:first-child {
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    background-color: ${COLORS_CONFIG.WHITE};
  }
  
  ${({ isDarkMode }) => isDarkMode && css`
      background-color: ${COLORS_CONFIG.DARK_BLUE};
      
      & ${Button}{
        &:first-child {
          color: ${COLORS_CONFIG.WHITE};
          background-color: ${COLORS_CONFIG.TRANSPARENT};
        }
        &:last-child {
          color: ${COLORS_CONFIG.NAVY_BLUE};
          background-color: ${COLORS_CONFIG.WHITE};
        }
      }
      
      & ${Title}, ${Hint}{
        color: ${COLORS_CONFIG.WHITE};
      }
  `}
  
`;
