/* Libs */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';


function Accordion({
  header,
  content,
  parentRef = null,
  isActive: activeProp,
  setActive: setActiveProp,
  preopen,
  disabled,
}) {
  const [activeState, setActiveState] = useState(false);
  const myRef = useRef();
  const scrollToRef = (ref) => {
    if (!parentRef) return;
    parentRef.current.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };
  const executeScroll = () => scrollToRef(myRef);
  const toggleAccordion = useCallback(() => {
    if (disabled) return;
    if (activeProp !== null && setActiveProp) {
      setActiveProp();
      setTimeout(() => executeScroll(), 500);
    } else {
      setActiveState(!activeState);
    }
  });

  useEffect(() => {
    setActiveState(preopen);
  }, [setActiveState, preopen]);

  return (
    <Styled.Wrapper>
      <Styled.Expand ref={myRef} onClick={toggleAccordion}>
        {React.cloneElement(header, { active: activeProp === null ? activeState : activeProp, disabled })}
      </Styled.Expand>
      <Styled.Content active={activeProp === null ? activeState : activeProp}>
        {content}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* Accordion type of props */

Accordion.propTypes = {
  preopen: PropTypes.bool,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

/* Accordion default props */

Accordion.defaultProps = {
  preopen: false,
  disabled: false,
  isActive: null,
  setActive: null,
};

export default Accordion;
