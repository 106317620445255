/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Text = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  white-space: nowrap;
  &.small {
    font-size: 12px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & ${Text} {
    padding-left: 10px;
  }
`;
