/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 20vh;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS_CONFIG.WHITE};
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;

export const PleaseCreate = styled.div`
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  text-align: center;
`;
