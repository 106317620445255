/* Libs */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { validateDate, validateNumber, validateText } from 'utils/validation/fields';
import { formIsValid, isValidNumbersField } from 'utils/validation';


/* Components */

import {
  Input,
  Button,
  DatePicker,
  SmallTitle,
} from 'components';

/* Styles */

import moment from 'moment';
import * as Styled from './styles';

const LICENSES_MAX_VALUE = 2147483647;

function ContractAndLicenses({
  data: INITIAL_DATA,
  errors: INITIAL_ERRORS,
  updateData,
  nextStep,
  prevStep,
}) {
  const [dates, setDates] = useState({
    start_date: INITIAL_DATA.start_date,
    end_date: INITIAL_DATA.end_date,
  });
  const [licenses, setLicenses] = useState(INITIAL_DATA.licenses);
  const [datesErrors, setDatesErrors] = useState({
    start_date: INITIAL_ERRORS.start_date,
    end_date: INITIAL_ERRORS.end_date,
  });
  const [licensesError, setLicensesError] = useState(INITIAL_ERRORS.licenses);


  const handleLicensesChange = useCallback(
    ({ target: { name, value } }) => {
      const trimedValue = value.trim();
      const newValue = isValidNumbersField(trimedValue) ? trimedValue : licenses;

      setLicensesError(
        validateNumber({ value, name, max: LICENSES_MAX_VALUE }),
      );
      setLicenses(newValue);
    },
    [
      licenses,
    ],
  );

  const handleDateChange = useCallback(
    ({ target: { name, value } }) => {
      const newErrors = { ...datesErrors };

      newErrors[name] = validateDate({ value, name });

      setDatesErrors(newErrors);
      setDates(prev => ({
        ...prev,
        [name]: value,
      }));
    },
    [datesErrors],
  );

  const updateAllData = () => updateData(
    {
      licenses,
      start_date: dates.start_date,
      end_date: dates.end_date,
    },
    {
      licenses: licensesError,
      start_date: datesErrors.start_date,
      end_date: datesErrors.end_date,
    },
  );

  const handleBack = () => {
    updateAllData();
    prevStep();
  };

  const handleNext = () => {
    updateAllData();
    nextStep();
  };

  const topTitle = useMemo(() => (
    <SmallTitle>
      Contract
    </SmallTitle>
  ), []);

  const bottomTitle = useMemo(() => (
    <SmallTitle>
      Licenses
    </SmallTitle>
  ), []);

  const canSubmit = formIsValid(
    {
      licenses: validateText({ value: licenses, name: 'licenses' }),
      start_date: validateDate({ value: dates.start_date, name: 'start_date' }),
      end_date: validateDate({ value: dates.end_date, name: 'end_date' }),
    },
    ['licenses', 'start_date', 'end_date'],
  );

  const minDate = useMemo(() => moment(dates.start_date).toDate(), [dates.start_date]);

  return (
    <Styled.Wrapper>
      <Styled.Block>
        { topTitle }
        <Styled.InputGroup>
          <DatePicker
            placeholder="Start date"
            title="Start date"
            name="start_date"
            error={datesErrors.start_date}
            value={dates.start_date}
            onChange={handleDateChange}
          />
          <DatePicker
            placeholder="End date"
            title="End date"
            position="right"
            name="end_date"
            error={datesErrors.end_date}
            value={dates.end_date}
            minDate={minDate}
            onChange={handleDateChange}
          />
        </Styled.InputGroup>
      </Styled.Block>
      <Styled.Block>
        { bottomTitle }
        <Input
          placeholder="Count"
          title="Count"
          name="licenses"
          type="number"
          error={licensesError}
          value={licenses}
          onChange={handleLicensesChange}
          onBlur={handleLicensesChange}
        />
      </Styled.Block>
      <Styled.Buttons>
        <Button
          inverted
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          disabled={!canSubmit}
          onClick={handleNext}
        >
          Next
        </Button>
      </Styled.Buttons>
    </Styled.Wrapper>
  );
}

/* ContractAndLicenses type of props */

ContractAndLicenses.propTypes = {
  data: PropTypes.shape({
    end_date: PropTypes.string.isRequired,
    licenses: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    end_date: PropTypes.string.isRequired,
    licenses: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default ContractAndLicenses;
