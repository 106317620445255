import React, {
  useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  debounce, isEmpty, isNaN,
} from 'lodash';

/* Actions */

import { AdminCompanyEntity } from '_entities';

/* Constants */

import { STRUCT_IDS } from '_constants';

/* Components */

import { Pagination, ParticipantsSection } from 'components';

/* Utils */

import {
  exportCSV, getError, replaceQueryParams, removeAllSpaces,
} from 'utils/custom';
import { notification } from 'utils/services';
import { PARTICIPANTS_TABLE, URLS_CONFIG } from 'config';

const PAGE_TITLE = 'Company participants';

const CompanyParticipants = ({
  companyId,
  companyParticipants,
  getCompanyParticipants,
  downloadCompanyParticipants,
  match: { url },
  location: { search },
  anonymousParticipantsCount,
  participantsCount,
}) => {
  const [page, setPage] = useState(1);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [searchInput, setSearch] = useState('');

  const getParticipants = async ({
    email = searchInput,
    currentPage = page,
  } = {}) => {
    try {
      const { results, count } = await getCompanyParticipants({
        id: companyId,
        email,
        page: currentPage,
      });

      if (isEmpty(results) && count) {
        setPage(1);
        replaceQueryParams({ page: 1 });
        getParticipants({ currentPage: 1 });
        return;
      }
      replaceQueryParams({ page: currentPage });
      setTotalParticipants(count);
    } catch (e) {
      notification.error(getError(e));
    }
  };

  const debouncedGetParticipants = useCallback(debounce(getParticipants, 300), []);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const searchParamsPage = +urlParams.get('page');
    const queryPage = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;
    getParticipants({ currentPage: queryPage });
    setPage(queryPage);
  }, []);

  const handleSearch = ({ target: { value } }) => {
    setSearch(removeAllSpaces(value));
    debouncedGetParticipants({ email: removeAllSpaces(value), page: 1 });
  };

  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
    getParticipants({ currentPage: selected + 1 });
    document.querySelector(`#${STRUCT_IDS.COMPANY_ADMIN_PAGE_CONTENT}`).scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const download = useCallback(async () => {
    try {
      const downloadedParticipants = await downloadCompanyParticipants({ id: companyId });
      exportCSV(downloadedParticipants, 'company_participants');
    } catch (error) {
      notification.error(getError(error));
    }
  }, []);


  return (
    <ParticipantsSection
      title="Company participants"
      handleSearch={handleSearch}
      searchValue={searchInput}
      participants={companyParticipants}
      anonymousParticipantsCount={anonymousParticipantsCount}
      participantsCount={participantsCount}
      download={download}
      url={url}
      pagination={(
        <Pagination
          pageCount={totalParticipants / PARTICIPANTS_TABLE.PAGINATION.PER_PAGE}
          pageChangeHandler={handlePageChange}
          activePage={page}
          withInitialPage={false}
        />
      )}
    />
  );
};

/* Page Url */

CompanyParticipants.path = URLS_CONFIG.companyAdmin.reports.companyParticipants;

/* Page Title */

CompanyParticipants.title = PAGE_TITLE;

CompanyParticipants.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  companyParticipants: PropTypes.arrayOf({}),
  downloadCompanyParticipants: PropTypes.func.isRequired,
  getCompanyParticipants: PropTypes.func.isRequired,
  anonymousParticipantsCount: PropTypes.number,
  participantsCount: PropTypes.number,
};

CompanyParticipants.defaultProps = {
  companyParticipants: [],
  anonymousParticipantsCount: 0,
  participantsCount: 0,
};

const mapStateToProps = ({
  auth: { company },
  adminCompany: {
    companyParticipants: {
      results: companyParticipants,
      anonymous_participants_count: anonymousParticipantsCount,
      count: participantsCount,
    } = {},
  },
}) => ({
  companyId: company,
  companyParticipants,
  anonymousParticipantsCount,
  participantsCount,
});

const mapDispatch = {
  downloadCompanyParticipants:
      AdminCompanyEntity.actions.getCompanyReportsDownloadParticipants,
  getCompanyParticipants: AdminCompanyEntity.actions.getCompanyParticipants,
};

export default connect(mapStateToProps, mapDispatch)(CompanyParticipants);
