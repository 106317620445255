export default (data, dataType, extension, filename) => {
  const fileContent = `data:${dataType};charset=utf-8,${data}`;
  const encodedUri = encodeURI(fileContent);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${filename || 'unnamed'}.${extension}`);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link); // Required for FF
};
