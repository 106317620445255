// import get from 'lodash.get';
// import { UserEntity, StaticDataEntity } from '_entities';
import * as TYPES from './Auth.types';

const initialState = {
  loading: false,
  loaded: false,
  token: null,
  id: null,
  company: null,
  role: '',
  email: '',
  appCanStart: false,
  firstName: '',
  lastName: '',
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case TYPES.LOGOUT:
      return {
        ...initialState,
        appCanStart: true,
      };
    case TYPES.SIGNIN:
    case TYPES.GET_INITIAL_USER_DATA:
    case TYPES.UPDATE_TOKEN:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SIGNIN_SUCCESS: {
      const { user } = action.result;
      return {
        ...state,
        id: user.id,
        company: user.company,
        role: user.role,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        appCanStart: true,
        loading: false,
        loaded: true,
      };
    }
    case TYPES.GET_INITIAL_USER_DATA_SUCCESS: {
      const { result } = action;
      return {
        ...state,
        id: result.id,
        company: result.company,
        role: result.role,
        email: result.email,
        firstName: result.first_name,
        lastName: result.last_name,
        companyLogo: result.company_logo,
        companyTimezone: result.company_timezone,
        isSecretMode: result.is_secret_mode,
        appCanStart: true,
        loading: false,
        loaded: true,
      };
    }
    case TYPES.SIGNIN_FAIL:
    case TYPES.GET_INITIAL_USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case TYPES.START_WITHOUT_TOKEN:
      return {
        ...state,
        appCanStart: true,
        loading: false,
      };
    case TYPES.CHANGE_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
