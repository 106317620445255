/* Libs */
import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual, set } from 'lodash';
import { Prompt } from 'react-router-dom';

/* Components */
import {
  AddGameDefSection,
  AdminCompaniesStruct,
  Button,
  ContainerLoader, GoLabel,
  InputImage,
} from 'components/index';

/* Config */

import { NOTIFICATIONS_CONFIG } from 'config';

/* Actions */

import { GameDefEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';
import {
  checkGameDefFormStatus,
  isGameDefQuestionsValid,
  validateGameDefCodes,
  validateGameDefField,
  validateGameDefForm,
  validateGameDefQuestions,
} from 'utils/validation';
import {
  deepCopyObject, generateEmptyQuestion,
  getError,
} from 'utils/custom';
import { removeIds } from 'utils/transformers';

/* Constants */

import {
  GAME_DEF_FORM,
  GAME_DEF_FORM_DATA,
  LABEL_COLOR_TYPES, NOTIFICATIONS,
  TRIANGLE_TYPES,
} from '_constants';

/* Styles */

import * as Styled from './styles';

const DEFAULT_FORM_DATA = {
  data: { ...GAME_DEF_FORM_DATA.DEFAULT_DATA },
  errors: { ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS },
};

const IMAGE_POSITION = 'left';

const GameDefinitionForm = ({
  getGameDefById,
  importGameDef,
  title,
  formData,
  setFormData,
  loading,
  setLoading,
  handleSave,
  handleDelete,
  loadGameDef,
  companyId,
  mode,
  gameDefId,
  formChanged,
  toggleModal,
  goBack,
}) => {
  const [saved, setSaved] = useState(false);

  const questionsIsValid = useMemo(() => isGameDefQuestionsValid(
    formData.data.questions,
    formData.data.is_final_code_only,
    formData.data.final_code,
  ), [
    JSON.stringify(formData.data.questions),
    formData.data.final_code,
    formData.data.is_final_code_only,
  ]);

  const formIsEmpty = useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const { data: { id: defaultId, company: defaultCompany }, ...restDefaultData } = DEFAULT_FORM_DATA;
    // eslint-disable-next-line
    const { data: { id, company }, ...restFormData } = formData;
    return isEqual(restDefaultData, restFormData);
  }, [formData]);

  const formIsFilledAndValid = useMemo(() => (
    checkGameDefFormStatus(formData.errors, [questionsIsValid])
  ), [formData.errors, questionsIsValid]);

  const resetForm = () => {
    const copyOfDefault = deepCopyObject(DEFAULT_FORM_DATA);
    setFormData(prev => ({
      ...copyOfDefault,
      data: {
        ...copyOfDefault.data,
        company: prev.data.company,
        is_online_only: prev.data.is_online_only,
        ...(prev.data.id ? { id: prev.data.id } : {}),
      },
    }));
  };

  const applyGameDef = async (
    { target: { name, value } },
  ) => {
    if (!value) {
      resetForm();
      return;
    }
    try {
      notification.removeAllNotifications();
      setLoading(true);
      const gameDef = await getGameDefById(value);
      gameDef.storyline = null;
      delete gameDef.id;

      const newErrors = validateGameDefForm(gameDef);
      setFormData(prev => ({
        data: {
          ...gameDef,
          [name]: value,
          questions: removeIds(gameDef.questions),
          initial_template_name: gameDef.name,
          company: prev.data.company,
        },
        errors: {
          ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS,
          ...newErrors,
          game_def_template: false,
        },
      }));
      setLoading(false);
    } catch (error) {
      console.log(error);
      notification.removeAllNotifications();
      notification.error(getError(error));
      setLoading(false);
    }
  };

  const manageGameDefTemplate = (
    { target: { name, value } },
  ) => {
    notification.warning(NOTIFICATIONS_CONFIG.confirmTemplateUpdate(
      () => applyGameDef({ target: { name, value } }),
      notification.removeAllNotifications,
    ));
  };

  const handleFileInput = useCallback(async ({ target: { value } }) => {
    try {
      setLoading(true);
      const fd = new FormData();
      fd.append('game_def_csv', value);
      const response = await importGameDef(fd);
      let gameDefName = null;
      if (response.game_def_template) {
        gameDefName = await getGameDefById(response.game_def_template).then(({ name }) => name);
      }

      const isTemplateMode = [
        GAME_DEF_FORM.MODE.TEMPLATE,
        GAME_DEF_FORM.MODE.TEMPLATE_EDIT,
      ].includes(mode);

      const newData = {
        ...response,
        game_def_template: isTemplateMode ? null : response.game_def_template,
        initial_template_name: isTemplateMode ? null : gameDefName,
        questions: (response.questions || [generateEmptyQuestion(1)]).map((item, index) => ({
          ...item,
          number: index + 1,
        })),
      };

      const newErrors = validateGameDefForm(newData);

      setFormData({
        data: newData,
        errors: {
          ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS,
          ...newErrors,
        },
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      notification.error(NOTIFICATIONS.ERROR_DEFAULT);
      setLoading(false);
    }
  }, [setLoading, setFormData]);

  const onChange = ({
    target: {
      name,
      value,
      path,
      section,
      additionalData,
    },
  }) => {
    if (name === 'game_def_template') {
      return manageGameDefTemplate({ target: { name, value } });
    }

    const newError = validateGameDefField({
      name,
      value,
      section,
      additionalData: {
        ...additionalData,
        final_code: formData.data.final_code,
      },
    });

    let additionalErrors = section === 'questions'
      ? validateGameDefCodes(name, value, path, formData.data)
      : {};

    if (name === 'questions') {
      if (additionalData) {
        if (['add', 'change'].includes(additionalData.mode)) {
          additionalErrors = {
            ...additionalErrors,
            ...validateGameDefQuestions(value.length > formData.data.questions.length
              ? value.slice(
                0,
                additionalData.mode === 'change'
                  ? value.length
                  : -1,
              )
              : value, formData.data.final_code),
          };
        } else if (additionalData.mode === 'delete') {
          return setFormData((prev) => {
            const newData = set({ ...prev.data }, path || name, value);
            return {
              ...prev,
              data: newData,
              errors: {
                ...prev.errors,
                ...validateGameDefQuestions(newData.questions, newData.final_code, true),
              },
            };
          });
        }
      }
    }

    return setFormData(prev => ({
      ...prev,
      data: set({ ...prev.data }, path || name, value),
      errors: {
        ...prev.errors,
        ...additionalErrors,
        [(path || name)]: newError,
      },
    }));
  };

  const handleFormSave = useCallback(() => {
    setSaved(true);
    handleSave();
  }, [handleSave]);

  const actions = useMemo(() => {
    switch (mode) {
      case GAME_DEF_FORM.MODE.ADD:
        return (
          [
            <InputImage
              name="file"
              key="file"
              onChange={handleFileInput}
              placeholder="Import"
              acceptedFileTypes={['csv']}
              clearAfterUpload
              withPreview={false}
              imgPosition={IMAGE_POSITION}
              textWidth="auto"
            />,
            <Styled.Button
              disabled={formIsEmpty}
              onClick={
                () => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplateUpdate(
                  resetForm,
                  notification.removeAllNotifications,
                ))
              }
              key="cancel"
            >
              Reset
            </Styled.Button>,
            <Button
              isLoading={loading}
              disabled={!formIsFilledAndValid}
              key="save"
              onClick={handleFormSave}
            >
              Save game
            </Button>,
          ]
        );
      case GAME_DEF_FORM.MODE.EDIT:
        return (
          [
            <Styled.Button
              disabled={formIsEmpty}
              onClick={
                () => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplateUpdate(
                  resetForm,
                  notification.removeAllNotifications,
                ))
              }
              key="cancel"
            >
              Reset
            </Styled.Button>,
            <Button
              isLoading={loading}
              disabled={!(formIsFilledAndValid && formChanged)}
              key="save"
              onClick={handleFormSave}
            >
              Save edits
            </Button>,
          ]
        );
      case GAME_DEF_FORM.MODE.TEMPLATE:
        return (
          [
            <InputImage
              name="file"
              key="file"
              onChange={handleFileInput}
              placeholder="Import"
              acceptedFileTypes={['csv']}
              clearAfterUpload
              withPreview={false}
              imgPosition={IMAGE_POSITION}
              textWidth="auto"
            />,
            <Styled.Button
              disabled={formIsEmpty}
              onClick={
                () => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplateUpdate(
                  resetForm,
                  notification.removeAllNotifications,
                ))
              }
              key="cancel"
            >
              Reset
            </Styled.Button>,
            <Button
              isLoading={loading}
              disabled={!formIsFilledAndValid}
              key="save"
              onClick={handleFormSave}
            >
              Save game
            </Button>,
          ]
        );
      case GAME_DEF_FORM.MODE.TEMPLATE_EDIT:
        return (
          [
            <Button
              isLoading={loading}
              disabled={!formIsFilledAndValid || !formChanged}
              key="save"
              onClick={handleFormSave}
            >
              Save
            </Button>,
            <Button
              key="assign"
              onClick={toggleModal}
              disabled={formChanged}
            >
              Assign to a company
            </Button>,
          ]
        );
      default:
        return [];
    }
  }, [
    mode,
    formIsEmpty,
    formIsFilledAndValid,
    loading,
    handleSave,
    handleFileInput,
    formChanged,
    toggleModal,
  ]);

  const topActions = useMemo(() => {
    switch (mode) {
      case GAME_DEF_FORM.MODE.EDIT:
        return ([
          <GoLabel
            key="delete"
            text="Delete definition"
            click={
              () => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate({
                onSuccess: handleDelete,
                onCancel: notification.removeAllNotifications,
                message: 'Are you sure you want to delete this game definition?',
              }))
            }
            color={LABEL_COLOR_TYPES.BLUE}
          />,
          <GoLabel
            text="Export"
            key="export"
            click={loadGameDef(gameDefId)}
            triangleType={TRIANGLE_TYPES.FORWARD}
            color={LABEL_COLOR_TYPES.BLUE}
          />,
        ]);
      case GAME_DEF_FORM.MODE.TEMPLATE_EDIT:
        return ([
          <GoLabel
            text="Export"
            key="export"
            click={loadGameDef(gameDefId)}
            triangleType={TRIANGLE_TYPES.FORWARD}
            color={LABEL_COLOR_TYPES.BLUE}
          />,
        ]);
      default:
        return [];
    }
  }, [mode, handleDelete, loadGameDef]);

  useEffect(() => {
    if (!formChanged || saved) return;
    function beforeUnloadHandler(e) {
      e.preventDefault();
      e.returnValue = NOTIFICATIONS.ON_GD_LEAVE;
      return NOTIFICATIONS.ON_GD_LEAVE;
    }
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [formChanged]);

  return (
    <>
      <Prompt
        when={formChanged && !saved}
        message={NOTIFICATIONS.ON_GD_LEAVE}
      />
      <AdminCompaniesStruct
        title={title}
        goBack={goBack}
        topActions={topActions}
        actions={actions}
      >
        { loading ? (
          <ContainerLoader />
        )
          : (
            <AddGameDefSection
              data={formData.data}
              onChange={onChange}
              errors={formData.errors}
              mode={mode}
              companyId={companyId}
            />
          )}
      </AdminCompaniesStruct>
    </>
  );
};

GameDefinitionForm.propTypes = {
  getGameDefById: PropTypes.func.isRequired,
  title: PropTypes.string,
  formData: PropTypes.shape({
    data: PropTypes.shape({
      game_def_template: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      company: PropTypes.number,
      name: PropTypes.string,
      introduction_title: PropTypes.string,
      introduction_text: PropTypes.string,
      introduction_vimeo_id: PropTypes.string,
      instruction_title: PropTypes.string,
      instruction_text: PropTypes.string,
      instruction_vimeo_id: PropTypes.string,
      storyline_title: PropTypes.string,
      storyline_text: PropTypes.string,
      storyline_vimeo_id: PropTypes.string,
      storyline: PropTypes.number,
      debriefing_title: PropTypes.string,
      debriefing_text: PropTypes.string,
      debriefing_vimeo_id: PropTypes.string,
      reset_title: PropTypes.string,
      reset_text: PropTypes.string,
      reset_vimeo_id: PropTypes.string,
      time_limit: PropTypes.string,
      final_code: PropTypes.string,
      is_final_code_only: PropTypes.bool,
      questions: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    errors: PropTypes.shape({}).isRequired,
  }).isRequired,
  mode: PropTypes.oneOf([
    GAME_DEF_FORM.MODE.EDIT,
    GAME_DEF_FORM.MODE.ADD,
    GAME_DEF_FORM.MODE.TEMPLATE,
    GAME_DEF_FORM.MODE.TEMPLATE_EDIT,
  ]).isRequired,
  setFormData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  loadGameDef: PropTypes.func,
  companyId: PropTypes.number,
  gameDefId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formChanged: PropTypes.bool,
  toggleModal: PropTypes.func,
  goBack: PropTypes.shape({}).isRequired,
};

GameDefinitionForm.defaultProps = {
  title: 'Game Definition',
  loading: false,
  handleDelete: () => {},
  loadGameDef: () => {},
  toggleModal: () => {},
  formChanged: null,
  companyId: null,
  gameDefId: null,
};

export default connect(null, {
  getGameDefById: GameDefEntity.actions.getGameDefById,
  importGameDef: GameDefEntity.actions.importGameDef,
})(GameDefinitionForm);
