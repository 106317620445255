import get from 'lodash.get';

export default response => ({
  first: {
    time: get(response, '[0].time_completed'),
    teamName: get(response, '[0].team_name'),
    location: get(response, '[0].location'),
    status: get(response, '[0].status'),
    isFinished: get(response, '[0].is_finished'),
  },
  second: {
    time: get(response, '[1].time_completed'),
    teamName: get(response, '[1].team_name'),
    location: get(response, '[1].location'),
    status: get(response, '[1].status'),
    isFinished: get(response, '[1].is_finished'),
  },
  third: {
    time: get(response, '[2].time_completed'),
    teamName: get(response, '[2].team_name'),
    location: get(response, '[2].location'),
    status: get(response, '[2].status'),
    isFinished: get(response, '[2].is_finished'),
  },
});
