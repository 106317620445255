/* Libs */

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/* Actions */

import { GameEntity } from '_entities';

/* Config */

import { COLORS_CONFIG } from 'config';

/* Components */

import {
  Modal,
  Img,
  Button,
} from 'components';

/* Styles */
import {
  GOOD_JOB_WHITE,
} from 'assets/icons/game_play';
import { MODAL_BG_COLOR } from '_constants';
import * as Styled from './styles';

// ----------------
function SuccessAnsweredQuestionModal({
  onClose,
  open,
  question,
  isDarkMode,
}) {
  if (!open) return null;
  return (
    <Modal
      onlyContentCloseClick
      onClose={onClose}
      style={{ width: '660px', maxWidth: '660px' }}
      open={open}
      crossColor={COLORS_CONFIG.WHITE}
      backgroundColor={isDarkMode ? MODAL_BG_COLOR.DARK : MODAL_BG_COLOR.BLUE}
    >
      <Styled.SuccessAnsweredQuestionModal isDarkMode={isDarkMode}>
        <Styled.Icon><Img disabled src={GOOD_JOB_WHITE} size={[90, 90]} /></Styled.Icon>
        <Styled.Title>Good job!</Styled.Title>
        <Styled.Text>{question.correct_answer_message}</Styled.Text>
        <Button onClick={onClose}>Close</Button>
      </Styled.SuccessAnsweredQuestionModal>
    </Modal>
  );
}

// Type of props
SuccessAnsweredQuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
SuccessAnsweredQuestionModal.defaultProps = {};

export default connect(null, {
  setQuestionAnswered: GameEntity.actions.setQuestionAnswered,
})(SuccessAnsweredQuestionModal);
