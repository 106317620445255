/* Libs */
import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isUndefined } from 'lodash';

/* Actions */
import { AdminCompanyEntity } from '_entities';

/* Utils */
import { notification } from 'utils/services';
import {
  getError,
  convertSecondsToTimeString,
  convertTimeStringToSeconds,
  formatDateRange,
  getTimezoneTitle,
} from 'utils/custom';
import { multipleSessionsDataToRequestObject } from 'utils/transformers';

/* Components */
import {
  Button,
  Loader,
  Select,
  WeekdaysAccordion,
} from 'components';

/* Constants */
import {
  DATE_SETTINGS,
  MULTIPLE_SESSION_REQUEST_TYPES,
  NOTIFICATIONS_CONFIG,
} from 'config';
import { NOTIFICATIONS } from '_constants';

/* Styles */
import * as Styled from './styles';

const KIT_SCHEDULING_STATUS = {
  EVERY: 'every',
  SOME: 'some',
};

const ConfirmSessions = (props) => {
  const {
    prevStep,
    onClose,
    sessionDuration,
    formDateRange,
    campaign,
    dateFrom,
    dateTo,
    gameDef,
    kit,
    location,
    weekdays,
    getMultipleSessions,
    createMultipleSessions,
    loadDaysWithGameSession,
    timezone,
    // breakDuration,
    startTime,
    sessionsPerDay,
    debriefingTime,
    introductionTime,
    participantsPerSession,
    isTimerCountingUp,
    handleCalendarChange,
    meetingLink,
    isOnlineOnly,
    resetCampaignsFilter,
  } = props;

  const monthsFull = useMemo(() => moment.months());
  const wrapperScrollRef = useRef();
  const range = formatDateRange(dateFrom, dateTo);
  const sessionDurationString = convertSecondsToTimeString(sessionDuration);
  // const breakDurationString = convertSecondsToTimeString(convertTimeStringToSeconds(breakDuration));
  const months = formDateRange.map(({ month }) => month);

  const [weekdaysResult, setWeekdaysResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    month: months[0],
    week: 0,
  });
  const [excludeStartDatetimes, setExcludes] = useState([]);

  const monthsOptions = months.map(item => ({
    label: monthsFull[item],
    value: item,
  }));

  const handleFormChange = ({ target: { name, value } }) => {
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const weeksOptions = (months.length && formState.month !== null)
    ? formDateRange.find(({ month }) => month === formState.month)
      .weeks.map(({ start, end }, index) => {
        const week = (`${moment().month(formState.month).date(start).format(DATE_SETTINGS.FORMAT.DAY)} - ${moment().month(formState.month).date(end).format(DATE_SETTINGS.FORMAT.DAY)}`);
        return ({
          value: index,
          label: week,
        });
      })
    : [];

  const isKitsAlreadyScheduled = useMemo(() => {
    const days = Object.values(weekdaysResult);
    const sessions = days.reduce((acc, day) => acc.concat(...Object.values(day)), []);

    const everyDayIsAlreadyScheduled = sessions.every(time => excludeStartDatetimes.includes(time.start_time)
      || Array.isArray(time.start_time));
    if (everyDayIsAlreadyScheduled) return KIT_SCHEDULING_STATUS.EVERY;

    const someDayIsAlreadyScheduled = sessions.some(time => Array.isArray(time.start_time));
    if (someDayIsAlreadyScheduled) return KIT_SCHEDULING_STATUS.SOME;
    return false;
  }, [weekdaysResult, excludeStartDatetimes]);

  const onMonthChange = ({ target: { name, value } }) => {
    setFormState(prev => ({
      ...prev,
      [name]: value,
      week: 0,
    }));
  };

  const toggleExcludeStartDatetimes = startTime => (
    setExcludes(prev => (
      prev.includes(startTime)
        ? prev.filter(date => date !== startTime)
        : prev.concat(startTime)
    ))
  );

  const loadSessions = async (rangeStart, rangeEnd) => {
    try {
      setLoading(true);
      const requestObject = multipleSessionsDataToRequestObject(
        {
          campaign: campaign.id,
          kit,
          location,
          gameDef,
          dateFrom: rangeStart,
          dateTo: rangeEnd,
          weekdays,
          timezone,
          // breakDuration,
          startTime,
          sessionsPerDay,
          debriefingTime: convertTimeStringToSeconds(debriefingTime),
          introductionTime: convertTimeStringToSeconds(introductionTime),
          maxParticipants: Number(participantsPerSession),
          isTimerCountingUp,
          meetingLink,
        },
        MULTIPLE_SESSION_REQUEST_TYPES.GET,
        isOnlineOnly,
      );
      const result = await getMultipleSessions(requestObject);
      setWeekdaysResult(result);
    } catch (error) {
      notification.error(getError(error));
    } finally {
      setLoading(false);
    }
  };

  const createSessions = async () => {
    try {
      const requestObject = multipleSessionsDataToRequestObject(
        {
          campaign: campaign.id,
          kit,
          location,
          gameDef,
          dateFrom,
          dateTo,
          weekdays,
          timezone,
          // breakDuration,
          startTime,
          sessionsPerDay,
          debriefingTime: convertTimeStringToSeconds(debriefingTime),
          introductionTime: convertTimeStringToSeconds(introductionTime),
          maxParticipants: Number(participantsPerSession),
          excludeStartDatetimes,
          isTimerCountingUp,
          meetingLink,
        },
        MULTIPLE_SESSION_REQUEST_TYPES.CREATE,
        isOnlineOnly,
      );

      await createMultipleSessions(requestObject);
      notification.success(NOTIFICATIONS.SUCCESS_ADD_MULTIPLE_GAME_SESSION);

      if (handleCalendarChange) {
        handleCalendarChange({
          target: {
            name: 'date',
            value: new Date(dateFrom.split('-')),
          },
        });
      }

      if (loadDaysWithGameSession) {
        loadDaysWithGameSession(campaign.id, new Date(dateFrom.split('-')));
      }

      if (resetCampaignsFilter) {
        resetCampaignsFilter();
      }
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const saveSessions = () => {
    if (isKitsAlreadyScheduled === KIT_SCHEDULING_STATUS.EVERY) {
      notification.error(NOTIFICATIONS.KIT_ALREADY_SCHEDULED);
      return;
    }
    if (isKitsAlreadyScheduled === KIT_SCHEDULING_STATUS.SOME) {
      notification.warning(NOTIFICATIONS_CONFIG.confirmMultipleSessionsCreation(
        () => {
          createSessions();
          onClose();
        },
        notification.removeAllNotifications,
      ));
      return;
    }
    createSessions();
    onClose();
  };

  useEffect(() => {
    const monthRange = formDateRange.find(({ month }) => month === formState.month);
    if (monthRange) {
      const startTime = moment()
        .year(monthRange.year)
        .month(monthRange.month)
        .date(monthRange.weeks[formState.week].start)
        .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY);
      const endTime = moment()
        .year(monthRange.year)
        .month(monthRange.month)
        .date(monthRange.weeks[formState.week].end)
        .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY);
      loadSessions(startTime, endTime);
    }
  }, [formState.month, formState.week]);

  return (
    <>
      <Styled.Header>
        <Styled.HeaderColumn>
          <Styled.Title>
            Date
          </Styled.Title>
          <Styled.SubTitle>
            {range}
          </Styled.SubTitle>
        </Styled.HeaderColumn>
        <Styled.HeaderColumn>
          <Styled.Title>
            Session Duration
          </Styled.Title>
          <Styled.SubTitle>
            {sessionDurationString}
          </Styled.SubTitle>
        </Styled.HeaderColumn>
        {/* <Styled.HeaderColumn>
          <Styled.Title>
            Break Time
          </Styled.Title>
          <Styled.SubTitle>
            {breakDurationString}
          </Styled.SubTitle>
        </Styled.HeaderColumn> */}
        <Styled.HeaderColumn>
          <Styled.Title>
            Time Zone
          </Styled.Title>
          <Styled.SubTitle>
            {getTimezoneTitle(timezone)}
          </Styled.SubTitle>
        </Styled.HeaderColumn>
      </Styled.Header>
      <Styled.Data ref={wrapperScrollRef}>
        <Styled.Inputs>
          <Select
            name="month"
            onChange={onMonthChange}
            value={formState.month}
            options={monthsOptions}
            withoutReset
          />
          <Select
            name="week"
            onChange={handleFormChange}
            value={formState.week}
            options={weeksOptions}
            withoutReset
          />
        </Styled.Inputs>
        {
          loading
            ? <Loader />
            : !isUndefined(formState.month) && (
              <WeekdaysAccordion
                weekdays={weekdaysResult}
                weekdaysOptions={weekdays}
                sessions={sessionsPerDay}
                excludeStartDatetimes={excludeStartDatetimes}
                toggleSession={toggleExcludeStartDatetimes}
                currentRangeStr={`${monthsFull[formState.month]}, ${weeksOptions.find(({ value }) => value === formState.week).label}`}
                nodeEl={wrapperScrollRef}
              />
            )
        }
      </Styled.Data>
      <Styled.Actions>
        <Button onClick={prevStep}>Back</Button>
        <Button onClick={saveSessions}>Save sessions</Button>
      </Styled.Actions>
    </>
  );
};

/* ConfirmSessions type of props */
ConfirmSessions.propTypes = {
  prevStep: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  sessionDuration: PropTypes.number,
  formDateRange: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  gameDef: PropTypes.number.isRequired,
  kit: PropTypes.number.isRequired,
  location: PropTypes.number.isRequired,
  weekdays: PropTypes.arrayOf(PropTypes.string).isRequired,
  getMultipleSessions: PropTypes.func.isRequired,
  createMultipleSessions: PropTypes.func.isRequired,
  handleCalendarChange: PropTypes.func,
  loadDaysWithGameSession: PropTypes.func,
  timezone: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  sessionsPerDay: PropTypes.number.isRequired,
  // breakDuration: PropTypes.string,
  debriefingTime: PropTypes.string,
  introductionTime: PropTypes.string,
  participantsPerSession: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingLink: PropTypes.string,
  isOnlineOnly: PropTypes.bool,
  resetCampaignsFilter: PropTypes.func,
};

/* ConfirmSessions default props */
ConfirmSessions.defaultProps = {
  sessionDuration: 0,
  // breakDuration: '00:00:00',
  debriefingTime: null,
  introductionTime: null,
  participantsPerSession: null,
  loadDaysWithGameSession: null,
  meetingLink: '',
  isOnlineOnly: false,
  handleCalendarChange: null,
  resetCampaignsFilter: null,
};

export default connect(
  null,
  {
    getMultipleSessions: AdminCompanyEntity.actions.getMultipleGameSessions,
    createMultipleSessions: AdminCompanyEntity.actions.createMultipleGameSessions,
  },
)(ConfirmSessions);
