export const GET_COMPANY_KITS = '@PROJECT_NAME/GET_COMPANY_KITS';
export const GET_COMPANY_KITS_SUCCESS = '@PROJECT_NAME/GET_COMPANY_KITS_SUCCESS';
export const GET_COMPANY_KITS_FAIL = '@PROJECT_NAME/GET_COMPANY_KITS_FAIL';

export const ADD_COMPANY_KIT = '@PROJECT_NAME/ADD_COMPANY_KIT';
export const ADD_COMPANY_KIT_SUCCESS = '@PROJECT_NAME/ADD_COMPANY_KIT_SUCCESS';
export const ADD_COMPANY_KIT_FAIL = '@PROJECT_NAME/ADD_COMPANY_KIT_FAIL';

export const EDIT_COMPANY_KIT = '@PROJECT_NAME/EDIT_COMPANY_KIT';
export const EDIT_COMPANY_KIT_SUCCESS = '@PROJECT_NAME/EDIT_COMPANY_KIT_SUCCESS';
export const EDIT_COMPANY_KIT_FAIL = '@PROJECT_NAME/EDIT_COMPANY_KIT_FAIL';
