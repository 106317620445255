export const NONE = 'none';
export const BACK = 'back';
export const BACK_TOP = 'back_top';
export const BACK_DOWN = 'back_down';
export const FORWARD = 'forward';
export const FORWARD_TOP = 'forward_top';
export const FORWARD_DOWN = 'forward_down';

export default {
  NONE,
  BACK,
  BACK_TOP,
  BACK_DOWN,
  FORWARD,
  FORWARD_TOP,
  FORWARD_DOWN,
};
