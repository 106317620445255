/* eslint-disable global-require */
/* Libs */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/* Components */

import {
  AdminStruct,
} from 'components';

/* Constants */
import { URLS_CONFIG } from 'config';

// Admin subroutes
const ADMIN_SUB_ROUTES = [
  require('./ViewCompany').default,
  require('./Storylines').default,
  require('./Settings').default,
  require('./Companies').default,
  require('./Templates').default,
  require('./AddGameDef').default,
  require('./EditGameDef').default,
  require('./AddGameDefTemplate').default,
  require('./EditGameDefTemplate').default,
];

// CompanyAdmin subroutes
const COMPANY_ADMIN_SUB_ROUTES = [
  require('../CompanyAdmin/Company').default,
  require('../CompanyAdmin/Calendar').default,
  require('../CompanyAdmin/Campaigns').default,
  require('../CompanyAdmin/Users').default,
];

export default class AdminPage extends Component {
  // Static props
  static path = URLS_CONFIG.admin.default;

  render() {
    return (
      <AdminStruct>
        <Switch>
          {ADMIN_SUB_ROUTES.concat(COMPANY_ADMIN_SUB_ROUTES).map(route => (
            <Route exact path={route.path} component={route} key={route.path} />
          ))}
          <Redirect to={URLS_CONFIG.admin.companies} />
        </Switch>
      </AdminStruct>
    );
  }
}
