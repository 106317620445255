/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import { BigTitle } from 'components/blocks/ui/BigTitle/styles';
import { Button } from 'components/ui/Button/styles';
import {
  Wrapper as ShareLinkWrapper,
  Button as ShareLinkButton,
  Link,
} from 'components/ui/ShareLink/styles';

export const Date = styled.div`
  font-size: 18px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
`;

export const Text = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  color: ${COLORS_CONFIG.WHITE};
  text-align: center;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
`;

export const Line = styled.div`
  height: 2px;
  width:32px;
  margin: 24px 0px 24px 0px;
  background-color: ${COLORS_CONFIG.WHITE};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & ${Button} {
    background-color: ${COLORS_CONFIG.SKY_BLUE};
    color: ${COLORS_CONFIG.WHITE};
    border: 1px solid ${COLORS_CONFIG.WHITE};
    width: 193px;
    margin-top: 68px;
  }

  & ${BigTitle} {
    padding-bottom: 32px;
    color: ${COLORS_CONFIG.WHITE};
    text-align: center;
  }

  ${ShareLinkWrapper} {
    border-color: ${COLORS_CONFIG.SILVER};
    width: 90%;
  }

  ${ShareLinkButton} {
    background: ${COLORS_CONFIG.SILVER};
    color: ${COLORS_CONFIG.SKY_BLUE};
  }

  ${Link} {
    color: ${COLORS_CONFIG.WHITE};
  }
`;
