/* Libs */
import styled, { css } from 'styled-components';
import { CustomScroll } from 'assets/styles/mixins';
/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const Error = styled.div`
  color: ${COLORS_CONFIG.ORANGE};
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc( 100% + 2px );
  left: 0;
  width: 100%;
  display: none;
`;

export const Textarea = styled.textarea`
  position: relative;
  z-index: 3;
  width: 100%;
  min-height: 150px;
  padding: 15px 24px 15px 24px;
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
  background-color: ${COLORS_CONFIG.WHITE};
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  line-height: 22px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  resize: none;
  ${() => CustomScroll({ scrollBG: 'transparent' })}  
  cursor: auto;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 150px;
  ${({ hasError }) => hasError && css`
    ${Textarea} {
      border-color: ${COLORS_CONFIG.ORANGE};
      color: ${COLORS_CONFIG.ORANGE};
    }
    ${Error} {
      display: block;
    }
  `}
  ${({ hasError }) => hasError === false && css`
    ${Textarea} {
      border-color: ${COLORS_CONFIG.LIGHT_GREEN};
    }
  `}
`;

export const Title = styled.div`
  margin-bottom: 8px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 14px;
`;
