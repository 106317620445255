import { useEffect } from 'react';

export default () => {
  const userTimezoneOffset = new Date().getTimezoneOffset();

  const checkUserTimezone = () => {
    if (new Date().getTimezoneOffset() !== userTimezoneOffset) {
      window.location.reload();
    }
  };

  useEffect(() => {
    document.addEventListener('mouseenter', checkUserTimezone);
    return () => {
      document.removeEventListener('mouseenter', checkUserTimezone);
    };
  }, []);
};
