/* Libs */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

/* Actions */

import { GameEntity } from '_entities';

/* Utils */

import { isNull } from 'utils/validation';

/* Components */

import { GameStatisticList } from 'components';

/* Constants */

import { QUESTION_STATUS, ANSWER_STATUS } from '_constants';

/* Styles */

import * as Styled from './styles';

// TODO: it looks bad, need refactor
function GameStatistic({
  questions,
  timeLeft,
  timeLimit,
  penaltyTime,
  isFinished,
  setCompletedTime,
  isDarkMode
}) {
  let unlockedQuestions = 0;
  let incorrectAnswers = 0;

  questions.map((question) => {
    question.status !== QUESTION_STATUS.NOT_OPENED && unlockedQuestions++;
    question.answers.map(answer => answer.status === ANSWER_STATUS.WRONG && incorrectAnswers++);
  });

  const addedTime = moment.utc(0);
  const [hours, minutes, seconds] = penaltyTime.split(':');

  for (let i = 0; i < incorrectAnswers; i++) {
    addedTime.add({
      hours, minutes, seconds,
    });
  }

  const initialTime = moment.utc(
    moment.duration(timeLimit, 'HH:mm:ss').asMilliseconds() - timeLeft.valueOf()
  );
  const finalTime = moment.utc(initialTime + addedTime);
  // This calculations are valid when we reduce time counter
  // Do not delete it !!! (Logic could be back) 🦾 ━╤デ╦︻(▀̿̿Ĺ̯̿̿▀̿ ̿)
  // const finalTime = moment.utc(moment.duration(timeLimit, 'HH:mm:ss').asMilliseconds() - timeLeft.valueOf());
  // const initialTime = finalTime.clone().subtract({
  //   hours: addedTime.hours(),
  //   minutes: addedTime.minutes(),
  //   seconds: addedTime.seconds(),
  // });

  async function finishCurrentGame(attempts = 1) {
    try {
      const timeSpent = moment.utc(
        moment.utc(
          moment.duration(timeLimit, 'HH:mm:ss').asMilliseconds() - timeLeft.valueOf()
        ) + addedTime
      );

      await setCompletedTime({
        timeSpent: timeSpent.format('HH:mm:ss.SSSSSS'),
        currentTime: timeLeft.format('HH:mm:ss.SSS'),
      });
    } catch (error) {
      if (attempts < 12) {
        setTimeout(() => finishCurrentGame(++attempts), Math.exp(attempts));
      } else {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (isNull(isFinished)) {
      finishCurrentGame();
    }
  }, [isFinished]);

  return (
    <Styled.Wrapper>
      <GameStatisticList
        unlockedClues={unlockedQuestions}
        incorrectAnswers={incorrectAnswers}
        initialTime={initialTime.format('HH:mm:ss.SSS')}
        addedTime={addedTime.format('HH:mm:ss')}
        finalTime={finalTime.format('HH:mm:ss.SSS')}
        isDarkMode={isDarkMode}
      />
    </Styled.Wrapper>
  );
}

GameStatistic.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.oneOf(Object.values(QUESTION_STATUS)),
    answers: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.oneOf(Object.values(ANSWER_STATUS)),
    })).isRequired,
  })).isRequired,
  timeLeft: PropTypes.instanceOf(moment).isRequired,
  timeLimit: PropTypes.string.isRequired,
  penaltyTime: PropTypes.string.isRequired,
  isFinished: PropTypes.bool.isRequired,
  setCompletedTime: PropTypes.func.isRequired,
};

export default connect(({ game }) => ({
  questions: game.questions,
  timeLimit: game.gameDef.time_limit,
  isFinished: game.is_finished,
  penaltyTime: game.gameDef.penalty_time,
  timeLeft: game.timeLeft,
}), {
  setCompletedTime: GameEntity.actions.setCompletedTime,
})(GameStatistic);
