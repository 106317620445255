/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import { Modal, ShareLeaderboardSection } from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function ShareLeaderboard({
  onClose,
  open,
  ...restProps
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Share leaderboard"
      open={open}
    >
      <Styled.ShareLeaderboardModal>
        <ShareLeaderboardSection {...restProps} />
      </Styled.ShareLeaderboardModal>
    </Modal>
  );
}

// Type of props
ShareLeaderboard.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
ShareLeaderboard.defaultProps = {};
