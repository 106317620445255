import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const Link = styled(DefaultLink)`
  padding: 8px 0;
  width: 100%;
  height: 100%;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 14px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  & > div {
    padding-top: 0!important;
  }
`;

export const Button = styled.div`
  padding: 6px 0;
  cursor: pointer;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 14px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Wrapper = styled.div`
  margin-bottom: 8px;
  width: 100%;
  background-color: ${COLORS_CONFIG.WHITE};
  &:last-child {
    margin-bottom: 0;
  }
`;
