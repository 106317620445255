/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background: ${COLORS_CONFIG.BLACK$50};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 62px 108px 75px;
  width: 626px;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 8px;
  text-align: center;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 28px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  line-height: 1.2;
`;

export const Text = styled.div`
  margin-bottom: 20px;
  color: ${COLORS_CONFIG.GREY_CUSTOM_6};
  font-size: 18px;
  line-height: 1.1;
`;
