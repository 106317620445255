/* Libs */
import styled, { css } from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/* Styles */

import {
  Label as BackButton,
} from 'components/ui/GoLabel/styles';
import {
  Wrapper as SearchInputWrapper,
} from 'components/ui/SearchInput/styles';
import {
  Button as ButtonWrapper,
} from 'components/ui/Button/styles';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 32px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & ${BackButton} {
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
    color: ${COLORS_CONFIG.BLACK};
    font-size: 14px;
    line-height: 16px;
  }

  & ${SearchInputWrapper} {
    width: 335px;
    border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
    border-radius: 3px;
  }
`;

export const Title = styled.h2`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 30px;
  line-height: 40px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${SearchInputWrapper} {
    margin-left: 20px;
    margin-top: 2px;
  }
`;

export const ExportButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  height: 48px;
  border-radius: 24px;
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.WHITE};
  font-size: 16px;
  line-height: 19px;
`;


export const ParticipantsCount = styled.div`
  display: flex;
  align-self: flex-end;
  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${ButtonWrapper} {
    margin-top: 30px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 17px;
`;
