/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */

import {
  Wrapper as ListItemWrapper,
} from 'components/blocks/ListItems/Report/styles';
import { CustomScroll } from 'assets/styles/mixins';

export const Wrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  height: 100%;
  overflow: auto;
 ${CustomScroll}
 
  & .infinite-scroll-component{
    ${() => CustomScroll({ scrollBG: 'transparent' })}
  }

  & ${ListItemWrapper}:hover:not([disabled]) {
    background-color: #F7F7F7;
  }
`;

export const EmptyText = styled.div`
  color: ${COLORS_CONFIG.GREY_CUSTOM_6};
  font-size: 24px;
  text-align: center;
  font-weight: bold;
`;
