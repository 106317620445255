/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { isNull } from 'lodash';

/* Components */

import { Img } from 'components';

/* Constants */

import { PLACES_POSITION } from '_constants';
import { DATE_SETTINGS } from 'config';

/* Styles */

import {
  FIRST,
  SECOND,
  THIRD,
  FIRST_DARK,
  SECOND_DARK,
  THIRD_DARK,
} from 'assets/icons/places';

import * as Styled from './styles';

function TopPlaceCard({
  place,
  time,
  teamName,
  location,
  status,
  isFinished,
  isDarkMode,
}) {
  function getImgProps() {
    switch (place) {
      case PLACES_POSITION.FIRST:
        return {
          src: isDarkMode ? FIRST_DARK : FIRST,
          size: [191, 160],
          style: { bottom: '33%' },
        };
      case PLACES_POSITION.SECOND:
        return {
          src: isDarkMode ? SECOND_DARK : SECOND,
          size: [149, 110],
          style: { bottom: '27%' },
        };
      default:
        return {
          src: isDarkMode ? THIRD_DARK : THIRD,
          size: [149, 110],
          style: { bottom: '27%' },
        };
    }
  }

  const getLabel = () => (status
    ? isFinished
      ? moment(time, DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED).format(DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED)
      : 'DID NOT FINISH'
    : '00:00');

  return (
    <Styled.Wrapper
      isDarkMode={isDarkMode}
      place={place}
      isNotFinished={(!isFinished || isNull(isFinished)) && status}
    >
      <Styled.Icon>
        <Img disabled {...getImgProps()} />
        <Styled.Time>
          {getLabel()}
        </Styled.Time>
      </Styled.Icon>
      <Styled.TeamName>
        {teamName || 'Anonymous'}
      </Styled.TeamName>
      <Styled.Location>
        {location || 'Somewhere in the universe'}
      </Styled.Location>
    </Styled.Wrapper>
  );
}

/* TopPlaceCard type of props */

TopPlaceCard.propTypes = {
  place: PropTypes.oneOf([
    PLACES_POSITION.FIRST,
    PLACES_POSITION.SECOND,
    PLACES_POSITION.THIRD,
  ]),
};

/* TopPlaceCard default props */

TopPlaceCard.defaultProps = {};

export default TopPlaceCard;
