import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import {
  Wrapper as ShareLinkWrapper,
  Button as ShareLinkButton,
  Link,
} from 'components/ui/ShareLink/styles';
import { CancelIconWrapper } from 'components/blocks/Modal/styles';

export const Wrapper = styled.div`
  position: relative;
  padding: 48px;
  min-height: 100%;
  background: ${COLORS_CONFIG.SKY_BLUE};
  ${ShareLinkWrapper} {
    margin-top: 32px;
    border-color: ${COLORS_CONFIG.WHITE};
  }
  ${ShareLinkButton} {
    background: ${COLORS_CONFIG.WHITE};
    color: ${COLORS_CONFIG.SKY_BLUE};
  }
  ${Link} {
    color: ${COLORS_CONFIG.WHITE};
  }
`;

export const Cross = styled(CancelIconWrapper)`
  color: ${COLORS_CONFIG.WHITE};
`;

export const Title = styled.div`
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 24px;
  line-height: 1.33;
`;

export const Description = styled.div`
  margin-top: 16px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 14px;
  line-height: 1.29;
`;
