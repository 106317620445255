export const GET_USERS = '@PROJECT_NAME/GET_ADMIN_USERS';
export const GET_USERS_SUCCESS = '@PROJECT_NAME/GET_ADMIN_USERS_SUCCESS';
export const GET_USERS_FAIL = '@PROJECT_NAME/GET_ADMIN_USERS_FAIL';

export const ADD_USER = '@PROJECT_NAME/ADD_ADMIN_USER';
export const ADD_USER_SUCCESS = '@PROJECT_NAME/ADD_ADMIN_USER_SUCCESS';
export const ADD_USER_FAIL = '@PROJECT_NAME/ADD_ADMIN_USER_FAIL';

export const UPDATE_USER = '@PROJECT_NAME/UPDATE_ADMIN_USER';
export const UPDATE_USER_SUCCESS = '@PROJECT_NAME/UPDATE_ADMIN_USER_SUCCESS';
export const UPDATE_USER_FAIL = '@PROJECT_NAME/UPDATE_ADMIN_USER_FAIL';

export const DOWNLOAD_USERS = '@PROJECT_NAME/DOWNLOAD_ADMIN_USERS';
export const DOWNLOAD_USERS_SUCCESS = '@PROJECT_NAME/DOWNLOAD_ADMIN_USERS_SUCCESS';
export const DOWNLOAD_USERS_FAIL = '@PROJECT_NAME/DOWNLOAD_ADMIN_USERS_FAIL';

export const DELETE_USER = '@PROJECT_NAME/DELETE_ADMIN_USER';
export const DELETE_USER_SUCCESS = '@PROJECT_NAME/DELETE_ADMIN_USER_SUCCESS';
export const DELETE_USER_FAIL = '@PROJECT_NAME/DELETE_ADMIN_USER_FAIL';
