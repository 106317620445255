import * as TYPES from './StaticData.types';

export const bootstrap = () => ({
  types: [TYPES.BOOTSTRAP, TYPES.BOOTSTRAP_SUCCESS, TYPES.BOOTSTRAP_FAIL],
  promise: client => client.get('/get-constants/'),
});

export const setDarkMode = data => ({
  type: TYPES.SET_DARK_MODE,
  payload: data,
});
