/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG } from 'config';
import { CustomScroll } from 'assets/styles/mixins';


export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 0 53px 10px;
  position: relative;
  background-color: ${COLORS_CONFIG.SILVER};
  overflow: auto;
  
   ${CustomScroll}
`;


export const Struct = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  ${({ isDarkMode }) => isDarkMode && css`
      background-color: ${COLORS_CONFIG.DARK_BLUE};
      
      & ${Content}{
          background-color: ${COLORS_CONFIG.DARK_BLUE};  
          ${() => CustomScroll({
    scrollBG: COLORS_CONFIG.DARK_BLUE,
    thumbScrollBG: COLORS_CONFIG.OXFORD_BLUE,
  })}      
      }
  `}
`;
