/* Libs */
import React, {
  memo, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { removeAllSpaces } from 'utils/custom';
import { isEmpty } from 'utils/validation';

/* Components */

import { RulesTooltip, Img } from 'components';

/* Styles */

import { INFO_CIRCLE } from 'assets/icons';
import * as Styled from './styles';

function Input({
  placeholder,
  status,
  title,
  type,
  name,
  value,
  optional,
  error,
  rules,
  hideErrorText,
  onChange,
  onBlur,
  rulesPosition,
  setOpensRules,
  isOpenRules,
  forwardedRef,
  isDisabled,
  dark,
  ...rest
}) {
  const hasRules = Array.isArray(rules) && rules.length > 0;

  const handlePreventSpace = (e) => {
    if (e.keyCode === 32 && type === 'email') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const toggleShowRules = () => {
    if (!setOpensRules) return;
    setOpensRules(prev => !prev);
  };
  const handleRulesBlur = () => {
    if (!setOpensRules) return;
    setOpensRules(false);
  };

  const handleChange = ({ target: { name, value } }) => {
    onChange({ target: { name, value: type === 'email' ? removeAllSpaces(value) : value } });
  };

  const handleBlur = ({ target: { name, value } }) => {
    if (!onBlur) return;
    onBlur({ target: { name, value: type === 'email' ? removeAllSpaces(value) : value } });
    if (!hasRules) return;
    setOpensRules(false);
  };

  const additionalAttributes = useMemo(() => (type === 'email'
    ? {
      autoCapitalize: 'off',
      autoComplete: 'off',
      autoCorrect: 'off',
      spellCheck: 'off',
    }
    : {}), [type]);

  const hasError = useMemo(() => {
    if (!value && isDisabled) return null;
    return !isDisabled && error;
  }, [value, isDisabled, error]);

  return (
    <Styled.Wrapper
      hasError={hasError}
      hasRules={hasRules}
      rulesPosition={rulesPosition}
      disabled={isDisabled}
      dark={dark}
    >
      {optional && <Styled.Optional>(optional)</Styled.Optional>}
      {!isEmpty(title) && value && <Styled.Title>{title}</Styled.Title>}
      <Styled.Input
        placeholder={placeholder}
        type={type}
        name={name}
        value={value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handlePreventSpace}
        ref={forwardedRef}
        disabled={isDisabled}
        {...rest}
        {...additionalAttributes}
      />
      <Styled.Error dark={dark}>
        {error && !hideErrorText && error}
      </Styled.Error>
      {hasRules && (
        <>
          <Img
            onBlur={handleRulesBlur}
            onClick={toggleShowRules}
            size={[20, 20]}
            src={INFO_CIRCLE}
          />
          {
            isOpenRules && <RulesTooltip rules={rules} />
          }
        </>
      )}
    </Styled.Wrapper>
  );
}

/* Input type of props */

Input.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.shape({
    valid: PropTypes.bool,
    error: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  hideErrorText: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
  rules: PropTypes.arrayOf(PropTypes.shape({})), // TODO
};

/* Input default props */

Input.defaultProps = {
  autoComplete: 'new-password',
  type: 'text',
  optional: false,
  value: '',
  title: '',
  placeholder: '',
  hideErrorText: false,
  status: null,
  rules: [],
};

export default memo(Input);
