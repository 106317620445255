/* Libs */
import styled from 'styled-components';

import { default as Triangle } from 'components/blocks/Markers/BackTriangle/styles';
import { Wrapper as LoaderWrapper } from 'components/blocks/ui/Loader/styles';

import { COLORS_CONFIG } from 'config';
import { CustomScroll } from 'assets/styles/mixins';

export const Tooltip = styled.div`
  position: absolute;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 3px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.TOOLTIP_SHADOW};
  ${Triangle} {
    position: absolute;
    top: 15px;
    left: -12px;
    border-width: 8px 12px 8px 0;
  }
`;

export const Close = styled.div`
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 5px;
  padding: 2px;
  cursor: pointer;
`;

export const Content = styled.div`
  position: relative;
  padding: 16px 28px;
  width: 300px;
  min-height: 150px;
  max-height: 300px;
  overflow: auto;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}
  
  ${LoaderWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
