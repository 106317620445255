/* Libs */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { useToggleBooleanHook } from 'hooks';
import {
  ClosedQuestion,
  OpenedQuestion,
  QuestionModal,
  SuccessAnsweredQuestionModal,
} from 'components';

/* Constants */

import { QUESTION_STATUS } from '_constants';

/* Styles */

import * as Styled from './styles';

function QuestionCard({
  name,
  icon,
  code,
  hint_text,
  question,
  handleAnswer,
  status,
  isDarkMode
}) {
  const [isOpen, toggleModal] = useToggleBooleanHook(false);
  const [prevStatus, setPrevStatus] = useState(status);
  useEffect(() => {
    if (
      prevStatus === QUESTION_STATUS.NOT_OPENED
        && status === QUESTION_STATUS.OPENED
    ) {
      toggleModal();
    }
  }, [status]);
  function getCardContent() {
    if (status === QUESTION_STATUS.NOT_OPENED) {
      return (
        <ClosedQuestion
          name={name}
          icon={icon}
          isDarkMode={isDarkMode}
        />
      );
    }
    return (
      <OpenedQuestion
        name={name}
        code={code}
        hint={hint_text}
        status={status}
        securityContext={question.security_context}
        onClick={toggleModal}
        isDarkMode={isDarkMode}
      />
    );
  }
  return (
    <Styled.Wrapper status={status}>
      { getCardContent() }
      {
        status === QUESTION_STATUS.ANSWERED
          ? (
            <SuccessAnsweredQuestionModal
              open={isOpen}
              onClose={toggleModal}
              question={question}
              isDarkMode={isDarkMode}
            />
          )
          : (
            <QuestionModal
              open={isOpen}
              handleAnswer={handleAnswer(question)}
              onClose={toggleModal}
              question={question}
              isDarkMode={isDarkMode}
            />
          )
      }
    </Styled.Wrapper>
  );
}

/* QuestionCard type of props */

QuestionCard.propTypes = {
  status: PropTypes.oneOf([
    QUESTION_STATUS.NOT_OPENED,
    QUESTION_STATUS.OPENED,
    QUESTION_STATUS.ANSWERED,
  ]),
  icon: PropTypes.string,
  code: PropTypes.string.isRequired,
  hint_text: PropTypes.string.isRequired,
};

/* QuestionCard default props */

QuestionCard.defaultProps = {
  icon: '',
};

export default QuestionCard;
