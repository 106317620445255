export default (text) => {
  const textElement = document.createElement('input');
  textElement.style = {
    ...textElement.style,
    position: 'absolute',
    left: '-99999px',
    opacity: '0',
  };
  textElement.value = text;
  document.body.appendChild(textElement);
  textElement.focus();
  textElement.select();
  textElement.setSelectionRange(0, 99999);
  document.execCommand('copy');
  document.body.removeChild(textElement);
};
