import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';


export const VersionControl = styled.div`
  position: fixed;
  bottom: 5px;
  left: 10px;
  z-index: 35;
  padding: 3px;
  background: ${COLORS_CONFIG.WHITE};
  font-size: 12px;
  transition: opacity 0.3s ease;
  opacity: 0.1;
  border-radius: 5px;
  &:hover {
    opacity: 1;
  }
`;
