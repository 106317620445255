/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Wrapper as ImgWrapper } from 'components/blocks/ui/Img/styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  & ${ImgWrapper} {
    padding-left: 11px;
  } 
`;

export const Delimitr = styled.div`
  font-size: 30px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;

export const TimeBlock = styled.div`
  width: 55px;
  height: 44px;
  font-size: 30px;
  text-align: center;
  line-height: 44px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius:4px;
  border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_5};
`;
