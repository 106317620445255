/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import {
  CircleFilled,
} from 'components';

/* Styles */
import * as Styled from './styles';

const Radio = ({
  value,
  name,
  label,
  onChange,
  disabled,
  checked,
}) => (
  <Styled.Wrapper disabled={disabled}>
    <Styled.HtmlLabel>
      <Styled.InputWrapper>
        <Styled.Input
          name={name}
          value={value}
          type="radio"
          checked={checked}
          onChange={({ target: { checked } }) => onChange({ target: { name, value: checked } })}
          disabled={disabled}
        />
        <Styled.Shape checked={value}>
          <CircleFilled active={checked} />
        </Styled.Shape>
      </Styled.InputWrapper>
      <Styled.Label>{label}</Styled.Label>
    </Styled.HtmlLabel>
  </Styled.Wrapper>
);

Radio.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
};

Radio.defaultProps = {
  disabled: false,
  label: '',
};

export default Radio;
