/* Libs */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/* Components */

import { AdminSideBar } from 'components';

/* Styles */

import * as Styled from './styles';

function AdminStruct({
  children,
}) {
  useEffect(() => {
    document.querySelector('#admin-struct').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });


  return (
    <Styled.StructWrapper id="admin-struct">
      <Styled.SideBar>
        <AdminSideBar />
      </Styled.SideBar>
      <Styled.PageContent>
        {children}
      </Styled.PageContent>
    </Styled.StructWrapper>
  );
}

/* AdminStruct type of props */

AdminStruct.propTypes = {
  children: PropTypes.node.isRequired,
};

/* AdminStruct default props */

AdminStruct.defaultProps = {};

export default AdminStruct;
