/* Libs */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */
import {
  Logo,
  BigTitle,
  Button,
  TextButton,
} from 'components';

/* Constants */
import {
  LOGO_CONFIG,
  DATE_SETTINGS,
  URLS_CONFIG,
} from 'config';
import { NOTIFICATIONS } from '_constants';

/* Utils */
import {
  redirect,
  copyToClipboard,
  exportICS,
  truncateText,
  redirectWithError,
  getLocalDateFromUTCString,
  getTimezoneTitle,
} from 'utils/custom';
import { notification } from 'utils/services';

/* Styles */
import * as Styled from './styles';


function SignupParticipantsConfirm({
  location,
  startDate,
  inviteLink,
  editLink,
  meetingLink,
  localTimezone,
  sessionTimezone,
  token,
  gameSession,
  downloadCalendar,
  handleAnotherBook,
  allowMultipleSignup,
  participantsBooked,
}) {
  function copyInviteLink() {
    copyToClipboard(inviteLink);
    notification.success(NOTIFICATIONS.SUCCESS_COPY_INVITE_LINK);
  }

  function copyMeetingLink() {
    copyToClipboard(meetingLink);
    notification.success(NOTIFICATIONS.SUCCESS_COPY_MEETING_LINK);
  }

  const handleEdit = () => {
    localStorage.removeItem('alreadyBooked');
    redirect(`${editLink}&session=${gameSession}`);
  };

  const handleDownloadCalendar = async () => {
    try {
      const calendar = await downloadCalendar({ token, game_session: gameSession });
      exportICS(calendar, 'Living_Security_session');
    } catch (error) {
      redirectWithError(URLS_CONFIG.auth.signin, error);
    }
  };

  const timezone = moment.tz.guess();
  const startTime = getLocalDateFromUTCString(startDate);

  useEffect(() => {
    localStorage.setItem('alreadyBooked', 'true');
  }, []);

  return (
    <Styled.Wrapper>
      <Logo type={LOGO_CONFIG.VERTICAL_BLUE_BLACK} />
      <BigTitle>
        You’re confirmed. Invite others to join!
      </BigTitle>
      <Styled.Info>
        <Styled.InfoBlock>
          <b>Location: </b>
          {truncateText(location, 50) || 'Somewhere in the universe'}
        </Styled.InfoBlock>
        <Styled.InfoBlock>
          <b>Date and time: </b>
          {` ${startTime.format(DATE_SETTINGS.FORMAT.DAY_MONTH_DATE_AT_TIME)} ${getTimezoneTitle(timezone)}`}
        </Styled.InfoBlock>
      </Styled.Info>
      <Styled.Options>
        <Styled.OptionText>
          Invite your co-workers and friends to play with
        </Styled.OptionText>
        <TextButton onClick={copyInviteLink}>
          Copy invite link
        </TextButton>
      </Styled.Options>
      <Styled.Options>
        <Styled.OptionText>
          Need to change your time?
        </Styled.OptionText>
        <TextButton onClick={handleEdit}>
          Edit my session
        </TextButton>
      </Styled.Options>
      {
        meetingLink && (
          <Styled.Options>
            <Styled.OptionText>
              This event has a video call. Join!
            </Styled.OptionText>
            <TextButton onClick={copyMeetingLink}>
              Copy meeting link
            </TextButton>
          </Styled.Options>
        )
      }
      {
        allowMultipleSignup && (
          <Styled.Options>
            <Styled.OptionText>
              Want to take a part in more than 1 session?
            </Styled.OptionText>
            <TextButton onClick={handleAnotherBook}>
              Book a seat
            </TextButton>
          </Styled.Options>
        )
      }
      <Styled.Buttons>
        <Button onClick={handleDownloadCalendar}>
          Download calendar invite
        </Button>
      </Styled.Buttons>
    </Styled.Wrapper>
  );
}

SignupParticipantsConfirm.propTypes = {
  token: PropTypes.string.isRequired,
  gameSession: PropTypes.number.isRequired,
  downloadCalendar: PropTypes.func.isRequired,
  handleAnotherBook: PropTypes.func.isRequired,
  allowMultipleSignup: PropTypes.bool,
};

SignupParticipantsConfirm.defaultProps = {
  allowMultipleSignup: false,
};

export default SignupParticipantsConfirm;
