/* Libs */
import React, { useState, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Actions */
import { ParticipantEntity } from '_entities';

/* Components */
import { SignupParticipantsConfirmModal, BookForTeamModal } from 'components';

/* Constants */
import { NOTIFICATIONS, PARTICIPANT_SIGNUP_TYPE, VALIDATION_VALUES } from '_constants';
import { URLS_CONFIG } from 'config/index';

/* Utils */
import { validateEmail } from 'utils/validation/fields';
import { notification } from 'utils/services';
import {
  getError,
  redirect,
  redirectWithError,
  removeQueryParamsFromUrl,
} from 'utils/custom';
import {
  merge,
} from 'lodash';
import { formIsValid } from 'utils/validation';
import { SetEmail, BookSession } from './Steps';

/* Styles */
import * as Styled from './styles';

const MODAL_NAMES = {
  CONFIRM: 'Confirm',
  BOOK: 'Book',
};

const DEFAULT_DATA = {
  email: '',
};
const DEFAULT_ERRORS = {
  email: null,
};

function SignupCampaign({
  campaignGameSessions,
  participantSignup,
  participant_email: createdParticipantEmail,
  gameSession,
  inviteType,
  token,
  campaignName,
  allowMultipleSignup,
  downloadCalendar,
}) {
  const [step, setStep] = useState(1);
  const [formData, setData] = useState({
    data: {
      ...DEFAULT_DATA,
      email: createdParticipantEmail,
    },
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const [response, setResponse] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [selectedSession, setSelectedSession] = useState({});
  const [loading, setLoading] = useState(false);
  const [participantsDaTa, setParticipantsData] = useState(null);

  const moveToNextStep = () => setStep(step + 1);
  const moveToPrevStep = () => setStep(step - 1);

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      default:
        break;
    }
    setData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      default:
        break;
    }
    setData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    }));
  };


  const onNextClickHandler = () => {
    moveToNextStep();
  };

  const bookSession = async ({ sessionID, bookedTeam } = {}) => {
    try {
      const sessionData = merge(
        {
          game_session: sessionID,
          token,
          participant_email: formData.data.email,
        },
        activeModal === MODAL_NAMES.BOOK ? { team_participants_emails: bookedTeam } : {},
      );

      if (inviteType === PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_EDIT) {
        sessionData.game_session_to_delete = gameSession.id;
      }

      setLoading(true);

      const params = new URLSearchParams(window.location.search);
      if (params.has('session')) {
        const previousSession = campaignGameSessions.find(
          session => session.id === +params.get('session'),
        );
        if (!previousSession) {
          return redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.ERROR_NO_SESSION);
        }
        previousSession.signedup_participants.splice(0, 1);
        removeQueryParamsFromUrl(['session']);
      }

      const signupResponse = await participantSignup(sessionData);
      const bookedSession = campaignGameSessions.find(
        session => session.id === sessionID,
      );

      const localTimezone = moment.tz.guess();

      setSelectedSession({
        id: bookedSession.id,
        location: bookedSession.location.location,
        startTime: bookedSession.utc_start_time,
        meetingLink: bookedSession.meeting_link,
        localTimezone,
        sessionTimezone: bookedSession.timezone,
      });

      // push below added to show user incrementation of number of participants after booking
      bookedSession.signedup_participants.push({ email: null });
      if (bookedTeam && bookedTeam.length) bookedSession.signedup_participants.push(...bookedTeam);

      setResponse(signupResponse);
      setActiveModal(MODAL_NAMES.CONFIRM);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error(getError(error));
    }
  };

  const isEmailDisabled = [
    PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_DIRECT,
    PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_EDIT,
  ].includes(inviteType);

  const manageModal = name => () => setActiveModal(name);

  const closeHandler = () => {
    manageModal(null)();
  };

  const openBookForTeam = (data) => {
    setParticipantsData(data);
    manageModal(MODAL_NAMES.BOOK)();
  };

  const handleModalClose = async () => {
    redirect(URLS_CONFIG.auth.signin, NOTIFICATIONS.SUCCESS_BOOKING);
  };

  const handleAnotherBook = async () => {
    localStorage.removeItem('alreadyBooked');
    setActiveModal(false);
  };

  useEffect(() => {
    if (isEmailDisabled) {
      setStep(2);
    }
  }, [isEmailDisabled]);

  const getContent = () => {
    switch (step) {
      case 2:
        return (
          <BookSession
            campaignName={campaignName}
            participantEmail={formData.data.email}
            onBackClick={moveToPrevStep}
            campaignGameSessions={campaignGameSessions}
            inviteType={inviteType}
            gameSession={gameSession}
            bookSession={bookSession}
            openBookForTeam={openBookForTeam}
            loading={loading}
            isEmailDisabled={isEmailDisabled}
          />
        );
      case 1:
      default:
        return (
          <SetEmail
            campaignName={campaignName}
            participantEmail={formData.data.email}
            onParticipantEmailChange={handleChange}
            onParticipantEmailBlur={validateOnBlur}
            isEmailDisabled={isEmailDisabled}
            onNextClick={onNextClickHandler}
            inviteType={inviteType}
            emailError={formData.errors.email}
            isFormDisabled={formData.canSubmit}
          />
        );
    }
  };

  return (
    <Styled.Wrapper>
      {getContent()}
      <SignupParticipantsConfirmModal
        open={activeModal === MODAL_NAMES.CONFIRM}
        location={selectedSession.location}
        startDate={selectedSession.startTime}
        meetingLink={selectedSession.meetingLink}
        localTimezone={selectedSession.localTimezone}
        sessionTimezone={selectedSession.sessionTimezone}
        participantsBooked={selectedSession.participantsBooked}
        inviteLink={response.invite_link}
        editLink={response.edit_link}
        onClose={handleModalClose}
        handleAnotherBook={handleAnotherBook}
        token={token}
        gameSession={selectedSession.id}
        downloadCalendar={downloadCalendar}
        allowMultipleSignup={allowMultipleSignup}
      />
      <BookForTeamModal
        open={activeModal === MODAL_NAMES.BOOK}
        participantsDaTa={participantsDaTa}
        onClose={closeHandler}
        participantEmail={formData.data.email}
        bookSession={bookSession}
        loading={loading}
      />
    </Styled.Wrapper>
  );
}

/* SignupCampaign type of props */

SignupCampaign.propTypes = {
  campaignGameSessions: PropTypes.arrayOf(
    PropTypes.shape({
      utc_start_time: PropTypes.string.isRequired,
    }),
  ).isRequired,
  participantSignup: PropTypes.func.isRequired,
  participant_email: PropTypes.string,
  gameSession: PropTypes.object,
  inviteType: PropTypes.oneOf([
    PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_DIRECT,
    PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_PUBLIC,
    PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_EDIT,
  ]).isRequired,
  token: PropTypes.string.isRequired,
  campaignName: PropTypes.string.isRequired,
  checkParticipantToken: PropTypes.func.isRequired,
  downloadCalendar: PropTypes.func.isRequired,
};

/* SignupCampaign default props */

SignupCampaign.defaultProps = {
  participant_email: '',
  gameSession: {},
};

export default connect(
  ({ participant }) => ({
    token: participant.token,
    loading: participant.loading,
    inviteType: participant.inviteType,
    gameSession: participant.gameSession,
    participant_email: participant.participant_email,
    campaignGameSessions: participant.campaignGameSessions,
    campaignName: participant.campaignName,
    allowMultipleSignup: participant.allowMultipleSignup,
  }),
  {
    participantSignup: ParticipantEntity.actions.signup,
    checkParticipantToken: ParticipantEntity.actions.checkToken,
    downloadCalendar: ParticipantEntity.actions.downloadCalendar,
  },
)(memo(SignupCampaign));
