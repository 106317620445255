/* Libs */
import React from 'react';
import { history } from 'lib';

/* utils */

import { tokenService } from 'utils/services';

/* Components */

import { Logo, AdminSidebarItem, AdminSidebarList } from 'components';

/* Constants */

import { URLS_CONFIG, LOGO_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

const ADMIN_ROUTES = [
  {
    path: URLS_CONFIG.admin.companies,
    subroutes: ['game-definition'],
    label: 'Companies',
  },
  {
    path: URLS_CONFIG.admin.settings,
    label: 'Admin Settings',
  },
  // {
  //   path: URLS_CONFIG.admin.reports,
  //   label: 'Reports',
  // },
  {
    path: URLS_CONFIG.admin.storylines,
    label: 'Storylines',
  },
  {
    path: URLS_CONFIG.admin.templates,
    subroutes: ['add-template'],
    label: 'Templates',
  },
];

const getIsActive = route => history.location.pathname.includes(route.path)
    || (
      route.subroutes
      && route.subroutes.some(subroute => history.location.pathname.includes(subroute))
    ) || false;

function AdminSideBar() {
  return (
    <Styled.Wrapper>
      <Logo type={LOGO_CONFIG.VERTICAL_BLUE_WHITE} />
      <AdminSidebarList>
        {
          ADMIN_ROUTES.map(route => (
            <AdminSidebarItem
              key={route.label}
              url={route.path}
              isActive={getIsActive(route)}
              label={route.label}
            />
          ))
        }
      </AdminSidebarList>
      <Styled.Logout>
        <div onClick={tokenService.removeToken}>Logout</div>
      </Styled.Logout>
    </Styled.Wrapper>
  );
}

export default AdminSideBar;
