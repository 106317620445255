/* Libs */
import styled from 'styled-components';

/* Constants */
// import { FONTS_CONFIG, COLORS_CONFIG, MEDIA_QUERIES } from 'config';

// background-color: ${COLORS_CONFIG.SKY_BLUE};
// font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};

export const SettingsComponentsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    width: ${({ multiple }) => (multiple ? '47%' : '100%')};
  }
`;
