
import { addFormToRequestObject } from 'utils/transformers';
import { COMPANIES_TABLE } from 'config';
import * as TYPES from './Companies.types';

export const addCompany = data => ({
  types: [TYPES.ADD_COMPANY, TYPES.ADD_COMPANY_SUCCESS, TYPES.ADD_COMPANY_FAIL],
  promise: client => client.post('/companies/', addFormToRequestObject(data)),
});

export const getAllCompanies = ({
  page = 1, perPage = COMPANIES_TABLE.PAGINATION.PER_PAGE, isActive = true, name = '',
}) => ({
  types: [TYPES.GET_ALL_COMPANIES, TYPES.GET_ALL_COMPANIES_SUCCESS, TYPES.GET_ALL_COMPANIES_FAIL],
  promise: client => client.get(`/companies/?offset=${(page - 1) * perPage}&limit=${perPage}&is_active=${isActive}&name=${name}`),
});

export const activateCompany = id => ({
  types: [TYPES.ACTIVATE_COMPANY, TYPES.ACTIVATE_COMPANY_SUCCESS, TYPES.ACTIVATE_COMPANY_FAIL],
  promise: client => client.put(`/companies/${id}/`, { is_active: true }),
});
