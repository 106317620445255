export default ({
  data,
  value,
  label,
} = {}) => (
  Array.isArray(data)
    ? data.map(item => ({
      value: item[value],
      label: item[label],
    }))
    : []
);
