/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function CompanyAdminHeaderInfoMenuListItem({
  url,
  label,
  onClick,
}) {
  return (
    <Styled.Wrapper>
      {
        url
          ? (
            <Styled.Link to={url}>
              {label}
            </Styled.Link>
          )
          : (
            <Styled.Button onClick={onClick}>
              {label}
            </Styled.Button>
          )
      }
    </Styled.Wrapper>
  );
}

/* CompanyAdminHeaderInfoMenuListItem type of props */

CompanyAdminHeaderInfoMenuListItem.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CompanyAdminHeaderInfoMenuListItem.defaultProps = {
  url: null,
  onClick: null,
};

export default CompanyAdminHeaderInfoMenuListItem;
