/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Header } from 'components/blocks/ui/CardsHeader/styles';

export const Wrapper = styled.div`
  & ${Header} {
    padding-bottom: 10px;
  }
`;

export const Count = styled.div`
  font-size: 32px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
`;
