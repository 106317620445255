/* Libs */

import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

/* Components */

import { BackTriangle } from 'components';

/* Assets */

import { WARNING_SIGN } from 'assets/icons/signs';

/* Styles */

import { COLORS_CONFIG } from 'config';
import { useOutsideClickHook } from 'hooks/index';
import * as Styled from './styles';

function IconTooltip({
  src,
  items,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const tooltipRef = useRef(null);
  useOutsideClickHook(() => setIsVisible(false), tooltipRef);

  const onClick = useCallback((event) => {
    if (event) event.preventDefault();
    setIsVisible(prev => !prev);
  }, []);

  return (
    <Styled.Wrapper ref={tooltipRef}>
      <Styled.Img
        src={src || WARNING_SIGN}
        onClick={onClick}
      />
      { isVisible
        && (
          <Styled.ContentWrapper>
            <BackTriangle color={COLORS_CONFIG.WHITE} />
            <Styled.Content>
              {
                items.map(item => (
                  <Styled.Label key={item}>{item}</Styled.Label>
                ))
              }
            </Styled.Content>
          </Styled.ContentWrapper>
        )
      }
    </Styled.Wrapper>
  );
}

/* IconTooltip type of props */

IconTooltip.propTypes = {
  src: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};

/* IconTooltip default props */

IconTooltip.defaultProps = {
  src: null,
  items: [],
};

export default IconTooltip;
