/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 8px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  & > * {
    margin-top: 24px;
  }
  & > *:first-child {
    margin-top: 0px;
  }
`;
export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > *:first-child {
    flex: 1;
  }
  & > *:last-child {
    margin-left: 24px;
    flex: 1;
  }
  ${({ notSame }) => notSame && css`
    & > *:first-child {
      flex-grow: 0;
      min-width: 150px;
    }
    & > *:last-child {
      margin-left: 24px;
    }
  `}
`;

export const Label = styled.div`
  max-width: 50%;
  overflow: hidden;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  /* padding-left: 24px; */
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  & > div:last-child {
    padding-left: 5px;
    width: 100%;
    word-break: break-word;
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  }
`;
