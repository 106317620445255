export const GET_GAME_SESSION = '@PROJECT_NAME/GET_GAME_SESSION_MANAGE';
export const GET_GAME_SESSION_SUCCESS = '@PROJECT_NAME/GET_GAME_SESSION_MANAGE_SUCCESS';
export const GET_GAME_SESSION_FAIL = '@PROJECT_NAME/GET_GAME_SESSION_MANAGE_FAIL';

export const SET_NEW_GAME_TIME = '@PROJECT_NAME/SET_NEW_GAME_TIME_MANAGE';
export const SET_NEW_GAME_TIME_SUCCESS = '@PROJECT_NAME/SET_NEW_GAME_TIME_MANAGE_SUCCESS';
export const SET_NEW_GAME_TIME_FAIL = '@PROJECT_NAME/SET_NEW_GAME_TIME_MANAGE_FAIL';

export const DELETE_PARTICIPANT = '@PROJECT_NAME/DELETE_PARTICIPANT_MANAGE';
export const DELETE_PARTICIPANT_SUCCESS = '@PROJECT_NAME/DELETE_PARTICIPANT_MANAGE_SUCCESS';
export const DELETE_PARTICIPANT_FAIL = '@PROJECT_NAME/DELETE_PARTICIPANT_MANAGE_FAIL';

export const ADD_PARTICIPANT = '@PROJECT_NAME/ADD_PARTICIPANT_MANAGE';
export const ADD_PARTICIPANT_SUCCESS = '@PROJECT_NAME/ADD_PARTICIPANT_MANAGE_SUCCESS';
export const ADD_PARTICIPANT_FAIL = '@PROJECT_NAME/ADD_PARTICIPANT_MANAGE_FAIL';

export const CONTINUE_GAME = '@PROJECT_NAME/CONTINUE_GAME_MANAGE';
export const CONTINUE_GAME_SUCCESS = '@PROJECT_NAME/CONTINUE_GAME_MANAGE_SUCCESS';
export const CONTINUE_GAME_FAIL = '@PROJECT_NAME/CONTINUE_GAME_MANAGE_FAIL';

export const CLOSE_GAME = '@PROJECT_NAME/CLOSE_GAME_MANAGE';
export const CLOSE_GAME_SUCCESS = '@PROJECT_NAME/CLOSE_GAME_MANAGE_SUCCESS';
export const CLOSE_GAME_FAIL = '@PROJECT_NAME/CLOSE_GAME_MANAGE_FAIL';

export const RESET_GAME = '@PROJECT_NAME/RESET_GAME_MANAGE';
export const RESET_GAME_SUCCESS = '@PROJECT_NAME/RESET_GAME_MANAGE_SUCCESS';
export const RESET_GAME_FAIL = '@PROJECT_NAME/RESET_GAME_MANAGE_FAIL';

export const SET_ANONYMOUS_PARTICIPANTS = '@PROJECT_NAME/SET_ANONYMOUS_PARTICIPANTS_MANAGE';
export const SET_ANONYMOUS_PARTICIPANTS_SUCCESS = '@PROJECT_NAME/SET_ANONYMOUS_PARTICIPANTS_MANAGE_SUCCESS';
export const SET_ANONYMOUS_PARTICIPANTS_FAIL = '@PROJECT_NAME/SET_ANONYMOUS_PARTICIPANTS_MANAGE_FAIL';

export const RESET_MANAGE_GAME = '@PROJECT_NAME/RESET_MANAGE_GAME';
