/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

// import { CompanyAdminNavbarItem } from 'components';

/* Styles */

import * as Styled from './styles';

function CompanyAdminNavbarList({
  children,
}) {
  return (
    <Styled.List>
      {children}
    </Styled.List>
  );
}

/* CompanyAdminNavbarList type of props */

CompanyAdminNavbarList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CompanyAdminNavbarList;
