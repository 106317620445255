/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import * as Styled from './styles';


export default function SettingsElement({
  full,
  children,
}) {
  return (
    <Styled.SettingsElement full={full}>
      {children}
    </Styled.SettingsElement>
  );
}

// Type of props
SettingsElement.propTypes = {
  full: PropTypes.bool,
};

// Default value for props
SettingsElement.defaultProps = {
  full: false,
};
