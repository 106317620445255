/* Libs */
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

/* Utils */
import { notification } from 'utils/services';

/* Components */

import { Img } from 'components';

/* Constants */

import { NOTIFICATIONS, FILE_FORMATS } from '_constants';

/* Styles */

import { UPLOAD_ICON, IMPORT_ICON } from 'assets/icons/signs';
import * as Styled from './styles';


function InputImage({
  name,
  value,
  onChange,
  placeholder,
  error,
  acceptedFileTypes,
  withPreview,
  size,
  clearAfterUpload,
  imgPosition,
  textWidth,
}) {
  const inputRef = useRef(null);
  const acceptFiles = useMemo(() => acceptedFileTypes.map(fileType => `.${fileType}`)
    .join(','), [acceptedFileTypes]);

  const uploadFile = ({ target: { name: fileName, files } }) => {
    const fileExtension = files[0].name.split('.')
      .pop();
    if (!acceptedFileTypes.includes(fileExtension)) {
      return notification.error(NOTIFICATIONS.WRONG_FILE_TYPE);
    }
    onChange({
      target: {
        name: fileName,
        value: files[0],
      },
    });
    if (clearAfterUpload) {
      inputRef.current.value = '';
    }
  };

  return (
    <Styled.Wrapper height={size && size[1]}>
      <label>
        <Styled.HiddenInput
          type="file"
          name={name}
          accept={acceptFiles}
          onChange={uploadFile}
          ref={inputRef}
        />
        {
          withPreview
            ? (
              <Img disabled size={size} src={value} />
            )
            : (
              <Styled.Label imgPosition={imgPosition}>
                {acceptFiles.includes(FILE_FORMATS.CSV) && (
                  <Img disabled size={[15, 15]} src={IMPORT_ICON} />
                )}
                <Styled.Text textWidth={textWidth}>
                  {(value instanceof File && value.name) || value || placeholder}
                </Styled.Text>

                {acceptFiles.includes(FILE_FORMATS.JPG || FILE_FORMATS.PNG) && (
                  <Img disabled size={[24, 24]} src={UPLOAD_ICON} />
                )}
              </Styled.Label>
            )
        }

      </label>
    </Styled.Wrapper>
  );
}

/* InputImage type of props */

InputImage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  imgPosition: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  onChange: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  withPreview: PropTypes.bool,
  clearAfterUpload: PropTypes.bool,
  textWidth: PropTypes.string,
};

/* InputImage default props */

InputImage.defaultProps = {
  error: null,
  size: null,
  withPreview: false,
  placeholder: 'Click to choose a file',
  acceptedFileTypes: ['jpeg', 'png', 'jpg'],
  clearAfterUpload: false,
  textWidth: null,
};

export default InputImage;
