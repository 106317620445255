/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import { Modal, EditGameTimeForm } from 'components';

/* Styles */

import * as Styled from './styles';

export default function EditGameTime({
  onClose,
  data,
  open,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      open={open}
      style={Styled.modalStyles}
    >
      <Styled.EditGameTimeModal>
        <EditGameTimeForm onClose={onClose} data={data} />
      </Styled.EditGameTimeModal>
    </Modal>
  );
}

// Type of props
EditGameTime.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
EditGameTime.defaultProps = {};
