import axios from 'axios';
import { tokenService } from 'utils/services';
import { AUTH_TOKEN } from '_constants';

const _API = axios.create({
  // withCredentials: true
});

_API.interceptors.response.use((response) => {
  if (
    response.data.token
    && (response.config.url.includes('auth')
      || response.config.url.includes('bootstrap'))
  ) {
    tokenService.manageToken(response.data.token);
  }
  return Promise.resolve(response.data);
});

/**
 * Add API_URL to request url before sending request.
 */
_API.interceptors.request.use((config) => {
  const authToken = tokenService.getToken();
  if (authToken) config.headers[AUTH_TOKEN] = `JWT ${authToken}`;
  config.url = `${process.env.REACT_APP_API_URL}${config.url}`;
  // config.withCredentials = true;
  return config;
});

export default _API;
