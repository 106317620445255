import styled, { css } from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Label = styled.div`
  font-size: 16px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  padding-left: 24px;
`;

export const Link = styled(DefaultLink)`
  width: 100%;
  background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
  border-bottom: 1px solid ${COLORS_CONFIG.DARK_SMOOTH_BROWN};
  
  &:first-child{
    border-top: 1px solid ${COLORS_CONFIG.DARK_SMOOTH_BROWN};
  }
  
  &:hover {
    background-color: ${COLORS_CONFIG.DARK_SMOOTH_LIGHT_BROWN};
    & ${Label} {
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    }
  }
  ${({ props: { isActive } }) => isActive && css`
    background-color: ${COLORS_CONFIG.DARK_SMOOTH_BROWN};
      & ${Label} {
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
      }
      
      &:hover{
        background-color: ${COLORS_CONFIG.DARK_SMOOTH_BROWN};
      }
  `}
`;

export const Wrapper = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
  /* background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
  & :hover {
    background-color: ${COLORS_CONFIG.DARK_SMOOTH_BROWN};
    & ${Label} {
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    }
  } */
`;
