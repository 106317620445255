/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function AdminManageUsersSection({
  children,
  toggleBar,
}) {
  return (
    <Styled.Wrapper>
      { toggleBar }
      <Styled.Content>
        { children }
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* AdminManageUsersSection type of props */

AdminManageUsersSection.propTypes = {
  toggleBar: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default AdminManageUsersSection;
