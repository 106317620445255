import React, {
  useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  BigTitle,
  Checkbox,
  CircleWithSign,
  DeleteAnswer,
  DeleteQuestion,
  Input,
} from 'components';

/* Utils */

import { generateEmptyQuestion } from 'utils/custom';
import { validateQuestionForm } from 'utils/validation/fields';
import { isUndefined } from 'utils/validation';

/* Constants */

import { GAME_DEF_FORM, NOTIFICATIONS } from '_constants';

/* Config */

import { GAME_DEF_CONFIG } from 'config';

import * as Styled from './styles';

const SECTION_NAME = 'questions';

const QuestionsForm = ({
  data,
  errors,
  onChange,
  mode,
  disabled,
  finalCode,
  finalCodeOnly,
}) => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [invalidQuestions, setInvalidQuestions] = useState([]);

  const setValidityOfQuestions = () => {
    const newInvalidQuestions = data.reduce((acc, item) => {
      const { isValid } = validateQuestionForm({
        questionForValidate: item,
        otherQuestions: data.filter(
          otherQuestion => otherQuestion.number !== item.number,
        ),
        final_code: finalCode,
      });

      return isValid
        ? acc
        : acc.concat(item.number);
    }, []);
    setInvalidQuestions(newInvalidQuestions);
  };

  const currentQuestion = useMemo(() => (data && data[activeQuestion]) || [], [data, activeQuestion]);

  const addQuestion = (initial = false) => {
    if (mode === GAME_DEF_FORM.MODE.VIEW) return;
    if (disabled && initial !== true) return;
    const questionCount = data.length || 0;
    if (questionCount < GAME_DEF_CONFIG.MAX_QUESTIONS_COUNT) {
      const newQuestion = generateEmptyQuestion(questionCount + 1);
      onChange({
        target:
          {
            path: 'questions',
            name: 'questions',
            value: [...data, newQuestion],
            section: SECTION_NAME,
            additionalData: {
              mode: 'add',
            },
          },
      });
      setValidityOfQuestions();
      setActiveQuestion(questionCount);
    }
  };

  const addAnswer = useCallback(() => {
    onChange({
      target: {
        path: `questions[${activeQuestion}].answers`,
        name: 'answers',
        value: [...currentQuestion.answers, GAME_DEF_FORM.DEFAULT_ANSWER],
        section: SECTION_NAME,
      },
    });
  }, [activeQuestion, currentQuestion, data.answers]);

  const deleteQuestion = (id) => {
    const filteredQuestions = data.reduce((acc, item) => (item.number !== id
      ? acc.concat({
        ...item,
        number: acc.length + 1,
      })
      : acc), []);

    onChange({
      target: {
        path: 'questions',
        name: 'questions',
        value: filteredQuestions,
        section: SECTION_NAME,
        additionalData: {
          mode: 'delete',
        },
      },
    });
    setActiveQuestion(id - 2 >= 0 ? id - 2 : 0);
  };

  const answersCount = currentQuestion && Array.isArray(currentQuestion.answers)
    ? currentQuestion.answers.length : 0;

  const handleAnswerChange = useCallback(id => ({ target: { name, value } }) => {
    if (disabled) return;
    const { answers } = currentQuestion;
    const updatedAnswers = answers.map((answer, idx) => {
      if (name === 'is_correct' && answer.is_correct && value === false) {
        return answer;
      }
      if (idx === id) {
        return {
          ...answer,
          [name]: value,
        };
      }
      return name === 'is_correct'
        ? { ...answer, is_correct: false }
        : answer;
    });
    onChange({
      target: {
        path: `questions[${activeQuestion}].answers`,
        name: 'answers',
        value: updatedAnswers,
        section: SECTION_NAME,
      },
    });
  }, [currentQuestion.answers, disabled]);

  const deleteAnswer = useCallback((id, wasCorrect) => {
    let hasCorrect = false;
    const newAnswers = currentQuestion.answers
      .reduce((result, answer, idx) => {
        if (idx !== id) {
          const isCorrect = wasCorrect && !hasCorrect ? true : answer.is_correct;
          hasCorrect = true;
          return [...result, { ...answer, is_correct: isCorrect }];
        }
        return result;
      }, []);
    onChange({
      target: {
        path: `questions[${activeQuestion}].answers`,
        name: 'answers',
        value: newAnswers,
        section: SECTION_NAME,
      },
    });
  }, [currentQuestion.answers]);

  const otherQuestions = useMemo(() => (
    data.filter(question => question.number !== activeQuestion + 1) || []
  ),
  [activeQuestion && activeQuestion.number]);

  const changeActiveQuestion = (question) => {
    setValidityOfQuestions();
    setActiveQuestion(question.number - 1);
    onChange({
      target:
        {
          path: 'questions',
          name: 'questions',
          value: [...data],
          section: SECTION_NAME,
          additionalData: {
            mode: 'change',
          },
        },
    });
  };

  const handleOnChange = ({ target: { name, value } }) => {
    onChange({
      target: {
        path: `questions[${activeQuestion}].${name}`,
        value,
        name,
        section: SECTION_NAME,
        additionalData: {
          otherQuestions,
        },
      },
    });
  };

  const getError = (name, id) => {
    if (finalCodeOnly) return null;
    if (!isUndefined(id)) {
      return errors[`questions[${activeQuestion}].${name}`]
        && errors[`questions[${activeQuestion}].${name}`][id];
    }
    return errors[`questions[${activeQuestion}].${name}`];
  };

  const answersList = useMemo(() => (currentQuestion.answers || []).map((answer, id) => (
    <Styled.RadioGroup key={id}>
      <Checkbox
        type="circle"
        name="is_correct"
        value={answer.is_correct}
        onChange={handleAnswerChange(id)}
        error={getError('answers', id)}
        disabled={disabled}
      />
      <Input
        placeholder={`Answer #${id + 1}`}
        title={`Answer #${id + 1}`}
        name="answer"
        value={answer.answer}
        onChange={handleAnswerChange(id)}
        onBlur={handleAnswerChange(id)}
        error={getError('answers', id)}
      />
      <DeleteAnswer
        isVisible={answersCount >= 2 && !disabled}
        deleteAnswer={() => deleteAnswer(id, answer.is_correct)}
      />
    </Styled.RadioGroup>
  )),
  [
    activeQuestion,
    currentQuestion.answers,
    errors[`questions[${activeQuestion}].answers`],
    disabled,
  ]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <BigTitle>
          Questions
        </BigTitle>
        { data.length < 10 && (
          <Styled.AddQuestion onClick={addQuestion}>
            <CircleWithSign sign="+" />
            <Styled.Label>
              Add a question
            </Styled.Label>
          </Styled.AddQuestion>
        )}
      </Styled.Header>
      <Styled.QuestionsList>
        {
          data.map(question => (
            <Styled.QuestionTab
              key={question.number}
              hasError={activeQuestion + 1 === question.number ? null : invalidQuestions.includes(question.number)}
              onClick={() => changeActiveQuestion(question)}
              isActive={activeQuestion + 1 === question.number}
            >
              {question.number}
            </Styled.QuestionTab>
          ))
        }
      </Styled.QuestionsList>
      <Styled.QuestionWrapper>
        <Input
          placeholder="Name"
          name="name"
          title="Name"
          value={currentQuestion.name}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          error={getError('name')}
        />
        <Input
          placeholder="Unlock code"
          name="unlock_code"
          title="Unlock code"
          value={currentQuestion.unlock_code}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          error={getError('unlock_code')}
        />
        <Input
          placeholder="Security context"
          name="security_context"
          title="Security context"
          value={currentQuestion.security_context}
          onChange={handleOnChange}
          error={getError('security_context')}
          optional
        />
        <Input
          placeholder="Question intro - Vimeo ID"
          name="intro_vimeo_id"
          title="Vimeo ID"
          optional
          type="number"
          value={currentQuestion.intro_vimeo_id}
          onChange={handleOnChange}
          error={getError('intro_vimeo_id')}
        />
        <Input
          placeholder="Question"
          name="question"
          title="Question"
          value={currentQuestion.question}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          error={getError('question')}
        />
        <Styled.AnswersSection>
          <div>
            <Styled.Title>Answers</Styled.Title>
            <Styled.Tip>Select the correct answer</Styled.Tip>
          </div>
          <Styled.AddAnswer onClick={addAnswer} isHidden={disabled || answersCount > 4}>
            <CircleWithSign sign="+" />
            <Styled.Label>
              Add an answer
            </Styled.Label>
          </Styled.AddAnswer>
        </Styled.AnswersSection>
        {
          answersList
        }
        <Input
          placeholder="Correct answer message"
          name="correct_answer_message"
          title="Correct answer message"
          value={currentQuestion.correct_answer_message}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          error={getError('correct_answer_message')}
        />
        <Input
          placeholder="Incorrect answer message"
          name="incorrect_answer_message"
          title="Incorrect answer message"
          value={currentQuestion.incorrect_answer_message}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          error={getError('incorrect_answer_message')}
        />
        <Input
          placeholder="Hint text"
          name="hint_text"
          title="Hint text"
          value={currentQuestion.hint_text}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          error={getError('hint_text')}
          optional
        />
        { !disabled && data && data.length > 1
        && (
          <DeleteQuestion
            number={currentQuestion.number}
            deleteQuestion={deleteQuestion}
          />
        )
        }
      </Styled.QuestionWrapper>

      {
        disabled
        && (
          <Styled.OnlyFinalCodeMessage>
            {NOTIFICATIONS.ONLY_FINAL_CODE}
          </Styled.OnlyFinalCodeMessage>
        )
      }
    </Styled.Wrapper>
  );
};

QuestionsForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    unlock_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    intro_vimeo_id: PropTypes.string,
    question: PropTypes.string,
    correct_answer_message: PropTypes.string,
    incorrect_answer_message: PropTypes.string,
    hint_text: PropTypes.string,
  })).isRequired,
  mode: PropTypes.oneOf([
    GAME_DEF_FORM.MODE.EDIT,
    GAME_DEF_FORM.MODE.ADD,
    GAME_DEF_FORM.MODE.TEMPLATE,
    GAME_DEF_FORM.MODE.TEMPLATE_EDIT,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  finalCode: PropTypes.string,
  finalCodeOnly: PropTypes.bool,
};

QuestionsForm.defaultProps = {
  disabled: false,
  finalCode: 'null',
  finalCodeOnly: false,
};

export default QuestionsForm;
