/* Libs */
import styled from 'styled-components';

/* Constants */

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

/* Styles */

import { Select, Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import {
  Wrapper as DatePickerWrapper,
  DateLabel,
} from 'components/ui/DatePicker/styles';
import { Button } from 'components/ui/Button/styles';
import { CustomScroll } from 'assets/styles/mixins';

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  & ${DatePickerWrapper} {
    padding-top: 24px;

    & ${DateLabel} {
      height: 51px;
      border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      border-radius: 4px;
      &:hover {
        border-color: ${COLORS_CONFIG.SKY_BLUE};
      }
    }
  }
`;

export const Data = styled.div`
  flex: 1 1 auto;
  padding: 10px 40px 10px;
  /* height: calc( 100% - 200px ); */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* width: 866px */
  width: 100%;
  height: 0;
  justify-content: flex-start;

  ${() => CustomScroll({ scrollBG: COLORS_CONFIG.WHITE })}
`;

export const Title = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 8px;
`;

export const SubTitle = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 12px;
  line-height: 12px;
`;

export const HeaderColumn = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 16px;
  padding: 24px 40px 10px 40px;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;

  & ${SelectWrapper} {
    width: 210px;
    height: 48px;
    margin-right: 18px;

    & ${Select} {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 56px 32px 40px;
  width: 100%;
  /* height: 88px; */
  /* min-height: 80px; */
  /* min-height: 120px; */
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10_20};
  z-index: 11;

  & ${Button} {
    width: 160px;
    min-width: 160px;
    &:first-child {
      margin-right: 20px;
      padding: 0;
      height: initial;
      background-color: ${COLORS_CONFIG.TRANSPARENT};
      color: ${COLORS_CONFIG.BLACK};
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const AccordionHeader = styled.div`
  width: 100%;
`;
