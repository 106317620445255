/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  AVAILABLE_COLORS,
  COLORS_CONFIG,
} from 'config';

/* Styles */

import Triangle from './styles';

function ForwardTriangle({ color }) {
  return <Triangle color={color} />;
}

ForwardTriangle.propTypes = {
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
};

ForwardTriangle.defaultProps = {
  color: COLORS_CONFIG.SMOOTH_BROWN,
};

export default memo(ForwardTriangle);
