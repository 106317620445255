import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AdminCompanyEntity } from '_entities';

import { notification } from 'utils/services';
import { exportCSV, getError } from 'utils/custom';

import {
  // Select,
  Button,
} from 'components';

import * as Styled from './styles';

function ExportFile({
  // onClick,
  campaign,
  token,
  downloadLeaderboard,
  // submitText,
  // placeholder,
  // value,
  // ...restProps
}) {
  const [isLoading, setLoading] = useState(false);
  const clickHandler = async () => {
    try {
      setLoading(true);
      const leaderboard = await downloadLeaderboard({ campaign, token });
      exportCSV(leaderboard, 'leaderboard');
      setLoading(false);
    } catch (error) {
      notification.error(getError(error));
      setLoading(false);
    }
  };

  return (
    <Styled.Wrapper>
      {/* <Select
        placeholder={placeholder}
        value={value}
        options={[{ label: 'CSV', value: 'csv' }]}
        {...restProps}
      />
      <Button disabled={!value} onClick={clickHandler}>{submitText}</Button> */}
      <Button isLoading={isLoading} onClick={clickHandler}>Download .csv</Button>
    </Styled.Wrapper>
  );
}

ExportFile.propTypes = {
  // onClick: PropTypes.func,
  campaign: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  downloadLeaderboard: PropTypes.func.isRequired,
  // submitText: PropTypes.string,
  // placeholder: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ExportFile.defaultProps = {
  // onClick: () => { },
  // submitText: 'Export',
  // placeholder: 'Download as...',
  // value: null,
};

export default connect(null, {
  downloadLeaderboard: AdminCompanyEntity.actions.downloadLeaderboard,
})(ExportFile);
