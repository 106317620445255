/* Libs */
import styled, { css } from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import { Select, Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import { Button } from 'components/ui/Button/styles';
import { Wrapper as DatePickerWrapper } from 'components/ui/DatePicker/styles';
import { Input as MaskInput } from 'components/ui/MaskInput/styles';
import {
  Wrapper as GoLabelWrapper,
  Label as GoLabelLabel,
} from 'components/ui/GoLabel/styles';
import { Wrapper as InputWrapper, Input } from 'components/ui/Input/styles';
import { Wrapper as SwitcherWrapper } from 'components/ui/Switcher/styles';

import { Wrapper as TooltipWrapper } from 'components/blocks/ui/IconTooltip/styles';
import { Wrapper as AccordionWrapper } from 'components/blocks/ui/Accordion/styles';
import { CustomScroll, TextEllipsis } from 'assets/styles/mixins';

export const Wrapper = styled.div`
  flex-grow: 1;
  /* height: 100%; */
  position: relative;
  display: flex;
  flex-direction: column;
  ${SelectWrapper} {
    margin-top: 24px;
    ${Select} {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
  }
  ${DatePickerWrapper} {
    padding-top: 24px;
    ${MaskInput} {
      border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      border-radius: 4px;
      height: 53px;
      &:hover,
      &:focus {
        border-color: ${COLORS_CONFIG.SKY_BLUE};
      }
    }
  }
  ${GoLabelWrapper} {
    margin: 19px 0 5px;
  }
  ${GoLabelLabel} {
    font-size: 14px;
  }
  ${SwitcherWrapper},
  ${InputWrapper} {
    margin-top: 24px;
    ${Input} {
      border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
      border-radius: 4px;
      &:hover,
      &:focus {
        border-color: ${COLORS_CONFIG.SKY_BLUE};
      }
    }
  }
`;

export const Data = styled.div`
  flex: 1 1 auto;
  padding: 24px 32px 20px 32px;
  /* height: calc( 100% - 200px ); */
  height: 0;
  overflow-y: auto;
  ${() => CustomScroll({ scrollBG: COLORS_CONFIG.WHITE })}
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 8px;
`;

export const CampaignName = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 18px;
  ${TextEllipsis}
`;

export const Campaign = styled.div`
  padding-bottom: 16px;
  & ${Label}, & ${CampaignName} {
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  }
`;

export const Locations = styled.div`
  & > ${SelectWrapper} {
    padding-top: 16px;
  }
`;

export const NewLocation = styled.div`
  display: flex;
  padding-top: 12px;
  justify-content: flex-end;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  font-size: 12px;
  color: ${COLORS_CONFIG.SKY_BLUE};
`;

export const Options = styled.div`
  border-top: 1px solid ${COLORS_CONFIG.SILVER_LIGHT};
  ${InputWrapper} {
    margin-top: 24px;
  }
`;

export const ParticipantsCount = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 14px;
`;

export const ParticipantsList = styled.div`
  & ${SelectWrapper} {
    padding: 0px;
  }
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & ${TooltipWrapper} {
    padding-left: 0;
    margin-right: 2px;
  }
`;

export const Participants = styled.div`
  margin-top: 40px;
  ${AccordionWrapper} {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    ${Email} {
      padding-left: 18px;
      padding-right: 15px;
      &:first-child {
        padding-top: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const EmailText = styled.div`
  flex-grow: 1;
  margin-right: 10px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 16px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeleteEmail = styled.div`
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 2px solid ${COLORS_CONFIG.SKY_BLUE};
  border-radius: 50%;
  cursor: pointer;

  &:before,
  &:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 2px;
    background: ${COLORS_CONFIG.SKY_BLUE};
    content: '';
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const AddParticipantsWrapper = styled.div`
  margin-bottom: 16px;
`;

export const ParticipantsForm = styled.form`
  ${InputWrapper} {
    margin-top: 0;
  }
`;

export const AddEmail = styled.button`
  margin-left: auto;
  padding-top: 12px;
  width: max-content;
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
  border: none;
  outline: none;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  font-size: 12px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 32px;
  width: 100%;
  /* min-height: 120px; */
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10_20};
  z-index: 11;
`;

export const DeleteSession = styled.div`
  & ${Button} {
    margin-top: 9px;
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
  }
`;

export const AccordionHeader = styled.div`
  height: 53px;
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  background-color: ${COLORS_CONFIG.GREY_CUSTOM_7};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  width: 100%;
  position: relative;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 18px;
    width: 14px;
    height: 2px;
    background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
    transition: 0.1s ease;
    ${({ active }) =>
      !active &&
      css`
        width: 8px;
      `}
    ${({ empty }) =>
      empty &&
      css`
        display: none;
      `}
  }
  &:before {
    ${({ active }) =>
      !active &&
      css`
        transform: rotate(30deg);
        right: 24px;
      `}
  }
  &:after {
    ${({ active }) =>
      !active &&
      css`
        transform: rotate(-30deg);
      `}
  }
`;
