/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Button } from 'components';

/* Styles */
import * as Styled from './styles';

export default function ConfirmBlock({
  title,
  description,
  submitText,
  cancelText,
  onSubmit,
  onCancel,
  isLoading,
}) {
  return (
    <Styled.ConfirmBlock>
      {
        title && (
          <Styled.Title>
            {title}
          </Styled.Title>
        )
      }
      {
        description && (
          <Styled.Description>
            {description}
          </Styled.Description>
        )
      }
      <Styled.ConfirmActions>
        <Button onClick={onSubmit} isLoading={isLoading} disabled={isLoading}>{submitText}</Button>
        <Button onClick={onCancel}>{cancelText}</Button>
      </Styled.ConfirmActions>
    </Styled.ConfirmBlock>
  );
}

// Type of props
ConfirmBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// Default value for props
ConfirmBlock.defaultProps = {
  title: null,
  description: null,
  submitText: 'Submit',
  cancelText: 'Cancel',
};
