import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, BigTitle, Switcher } from 'components';

import { GAME_DEF_FORM } from '_constants';

import * as Styled from './styles';

const OnlineOnlyForm = ({
  onChange,
  data,
  mode,
}) => {
  const handleOnlineOnlyChange = ({ target: { value } }) => {
    onChange({ target: { name: 'is_online_only', value } });
    onChange({ target: { name: 'is_dark_mode', value } });
  };
  return (
    <Styled.Wrapper>
      <BigTitle>
        Additional Settings
      </BigTitle>
      <Styled.InputGroup>
        <Checkbox
          name="is_online_only"
          label="Online Mode*"
          value={data.is_online_only}
          onChange={handleOnlineOnlyChange}
          disabled={mode === GAME_DEF_FORM.MODE.EDIT}
        />
        <Switcher
          checked={data.is_dark_mode}
          onChange={value => onChange({ target: { name: 'is_dark_mode', value } })}
          checkedText="Dark Mode"
          uncheckedText="Light Mode"
        />
      </Styled.InputGroup>
      <Styled.Hint>
        *possible to set up only during game definition creation
      </Styled.Hint>
    </Styled.Wrapper>
  );
};

OnlineOnlyForm.propTypes = {
  data: PropTypes.shape({
    is_online_only: PropTypes.bool,
    is_dark_mode: PropTypes.bool,
  }).isRequired,
  mode: PropTypes.oneOf([
    GAME_DEF_FORM.MODE.EDIT,
    GAME_DEF_FORM.MODE.ADD,
    GAME_DEF_FORM.MODE.TEMPLATE,
    GAME_DEF_FORM.MODE.TEMPLATE_EDIT,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OnlineOnlyForm;
