/* Libs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Components */
import {
  ResetPasswordForm,
  AdminAuthStruct,
  ResetPasswordStruct,
} from 'components';

/* Constants */
import { URLS_CONFIG } from 'config';

const TITLE = 'Reset password';

class ResetPasswordPage extends Component {
  static title = TITLE;

  static path = URLS_CONFIG.auth.reset;

  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {
    return (
      <AdminAuthStruct>
        <ResetPasswordStruct title={TITLE}>
          <ResetPasswordForm />
        </ResetPasswordStruct>
      </AdminAuthStruct>
    );
  }
}

export default ResetPasswordPage;
