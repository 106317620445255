
import { pickBy, isUndefined } from 'lodash';
import queryString from 'query-string';

import { STORYLINES_TABLE } from 'config';
import * as TYPES from './Storylines.types';

export const getAllStorylines = ({ name, limit } = {}) => {
  const params = queryString.stringify(
    pickBy({
      name,
      limit,
    }, item => !isUndefined(item)),
  );
  return {
    types: [TYPES.GET_STORYLINES, TYPES.GET_STORYLINES_SUCCESS, TYPES.GET_STORYLINES_FAIL],
    promise: client => client.get(`/storylines/${params ? `?${params}` : ''}`),
  };
};

export const getStorylines = ({
  page = 1,
  perPage = STORYLINES_TABLE.PAGINATION.PER_PAGE,
  name = '',
}) => ({
  types: [TYPES.GET_STORYLINES, TYPES.GET_STORYLINES_SUCCESS, TYPES.GET_STORYLINES_FAIL],
  promise: client => client.get(`/storylines/?offset=${(page - 1) * perPage}&limit=${perPage}&name=${name}`),
});

export const getStorylinesByCompanyId = ({ id: company, name }) => {
  const params = queryString.stringify(
    pickBy({
      company,
      name,
    }, item => !isUndefined(item)),
  );
  return {
    types: [
      TYPES.GET_STORYLINES_BY_COMPANY_ID,
      TYPES.GET_STORYLINES_BY_COMPANY_ID_SUCCESS,
      TYPES.GET_STORYLINES_BY_COMPANY_ID_FAIL,
    ],
    promise: client => client.get(`/storylines/${params ? `?${params}` : ''}`),
  };
};

export const addStoryline = (data = {}) => ({
  types: [TYPES.ADD_STORYLINE, TYPES.ADD_STORYLINE_SUCCESS, TYPES.ADD_STORYLINE_FAIL],
  promise: client => client.post('/storylines/', data),
});

export const updateStoryline = ({ data = {}, id = null } = {}) => ({
  types: [TYPES.UPDATE_STORYLINE, TYPES.UPDATE_STORYLINE_SUCCESS, TYPES.UPDATE_STORYLINE_FAIL],
  promise: client => client.put(`/storylines/${id}/`, data),
  payload: { oldId: id },
});
