/* Libs */
import styled from 'styled-components';

import { SmallTitle } from 'components/blocks/ui/SmallTitle/styles';
import {
  Wrapper as SelectWrapper,
} from 'components/ui/Select/styles';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  padding: 24px 48px 40px;
  ${SelectWrapper} {
    .ReactSelect {
      &__control {
        border-color: ${COLORS_CONFIG.SKY_BLUE};
        border-radius: 0;
      }
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  ${SmallTitle} {
    margin-right: 8px;
    width: initial;
    font-size: 14px;
  }
`;

export const SubTitleAdding = styled.div`
  font-size: 12px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
`;
