export default {
  STEP: {
    INSTRUCTIONS: 'instruction',
    INTRODUCTION: 'introduction',
    STORYLINE: 'storyline',
    GAME_PLAY: 'game_play',
    GAME_FAILED: 'game_failed',
    GAME_SUCCESS: 'game_success',
    DEBRIEFING: 'debriefing',
    RESET: 'reset',
  },
  PINGABLE_STEPS: ['instructions', 'introductions', 'storyline', 'game_play'],
  STEPS_NAME: {
    instructions: 'Instructions',
    introductions: 'Introductions',
    storyline: 'Storyline',
    game_play: 'Game play',
    game_failed: 'Game failed',
    game_success: 'Game success',
    debriefing: 'Debriefing',
    reset: 'Reset',
  },
  SESSION_TYPES: {
    active: 'active',
    completed: 'completed',
  },
  NOT_FINISHED: 'DID NOT FINISH',
  MANAGER: {
    MODE: {
      VIEW: 'view',
      STOPPED: 'stopped',
      EDIT: 'edit',
    },
  },
  MAXIMUM_TIME: 90,
};
