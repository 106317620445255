/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* Utils */

import { redirect } from 'utils/custom';

/* Components */

import { Button, BigTitle } from 'components';

/* Constants */

import { URLS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function CanceledSession({
  linkForSignup,
}) {
  return (
    <Styled.Wrapper>
      <BigTitle>
        Your reservation has been canceled.
      </BigTitle>
      <Button
        onClick={() => redirect(linkForSignup)}
      >
        Reshedule
      </Button>
      {/* <Button
        onClick={() => redirect(URLS_CONFIG.auth.signin)}
      >
        No, thanks
      </Button> */}
    </Styled.Wrapper>
  );
}

/* CanceledSession type of props */

CanceledSession.propTypes = {
  linkForSignup: PropTypes.string.isRequired,
};

export default connect(({ participant }) => ({
  linkForSignup: participant.linkForSignup,
}))(CanceledSession);
