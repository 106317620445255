/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  ContainerLoader,
  StorylineCell,
} from 'components';

/* Styles */

import * as Styled from './styles';

function StorylinesTable({
  storylines,
  pagination,
  openEditUserSidebar,
  loading,
}) {
  const getRows = () => {
    const rows = [];
    for (let i = 0; i < storylines.length; i += 2) {
      rows.push(
        <Styled.Row key={i}>
          <StorylineCell openEditUserSidebar={openEditUserSidebar} storyline={storylines[i]} />
          <StorylineCell openEditUserSidebar={openEditUserSidebar} storyline={storylines[i + 1]} />
        </Styled.Row>,
      );
    }
    return rows;
  };
  return (
    <Styled.Wrapper loading={loading}>
      {
        loading
          ? <ContainerLoader friendlyMode />
          : (
            <Styled.Rows withPagination={!!pagination}>
              {getRows()}
            </Styled.Rows>
          )
      }
      {pagination}
    </Styled.Wrapper>
  );
}

/* StorylinesTable type of props */

StorylinesTable.propTypes = {
  storylines: PropTypes.array.isRequired,
};

export default StorylinesTable;
