import { COMMON_ERRORS } from '_constants/errors';
import { isEmpty } from 'lodash';

export default ({
  value = [],
  name,
  required = true,
}) => {
  let error = (isEmpty(value) && COMMON_ERRORS.isRequired(name));
  error = !required && isEmpty(value) ? null : error;
  return error;
};
