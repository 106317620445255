import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  & * {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Label = styled.span`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 14px;
  color: ${props => props.color || COLORS_CONFIG.ORANGE};
`;
