/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  BackTriangle,
  CircleWithSign,
} from 'components';

/* Constants */

import {
  COLORS_CONFIG,
  AVAILABLE_COLORS,
} from 'config';

/* Styles */

import * as Styled from './styles';

function CircleBack({ color, onClick }) {
  return (
    <Styled.Wrapper onClick={onClick}>
      <CircleWithSign
        color={color}
        sign={(
          <BackTriangle
            color={color}
          />
        )}
      />
    </Styled.Wrapper>
  );
}

/* CircleBack type of props */

CircleBack.propTypes = {
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
  onClick: PropTypes.func,
};

/* CircleBack default props */

CircleBack.defaultProps = {
  color: COLORS_CONFIG.SMOOTH_BROWN,
  onClick: null,
};

export default CircleBack;
