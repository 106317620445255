/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import StyledAdminSidebarList from './styles';

function AdminSidebarList({
  children,
}) {
  return (
    <StyledAdminSidebarList>
      {children}
    </StyledAdminSidebarList>
  );
}

/* AdminSidebarList type of props */

AdminSidebarList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminSidebarList;
