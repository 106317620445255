/* Libs */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

/* Actions */

import { LS_AdminSettingsEntity } from '_entities';

/* Utils */
import { validateEmail, validateText } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import {
  getError, getInitialTouched, pickTouchedDataObject,
} from 'utils/custom';
import { notification } from 'utils/services';

/* Components */
import {
  Input,
  Button,
  Switcher,
  ContainerLoader,
  ConfirmBlock,
} from 'components';

/* Styles */
import { NOTIFICATIONS } from '_constants';
import * as Styled from './styles';

const DEFAULT_ERRORS = {
  first_name: false,
  last_name: false,
  email: false,
  is_active: false,
};


function EditAdminForm({
  onClose,
  editUser,
  deleteUser,
  user: INITIAL_DATA,
  isHiddenDeleteButtons,
  loadAllUsers,
  showConfirmationModal,
  setShowConfirmationModal,
  clearSearchOnRequest,
}) {
  const [formData, setFormData] = useState({
    data: INITIAL_DATA,
    touchedData: getInitialTouched(INITIAL_DATA),
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const [loading, setLoading] = useState(false);


  const handleChange = ({ target: { name, value } }) => {
    const { errors, data, touchedData: oldTouchedData } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: 64,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: 254,
        });
        break;
      default:
        break;
    }
    const touchedData = { ...oldTouchedData, [name]: value !== INITIAL_DATA[name] };
    setFormData({
      data: { ...data, [name]: value },
      touchedData,
      errors: newErrors,
      canSubmit:
        formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email'])
        && Object.values(touchedData).some(value => Boolean(value)),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors, data, touchedData: oldTouchedData } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: 64,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: 254,
        });
        break;
      default:
        break;
    }
    const touchedData = { ...oldTouchedData, [name]: value !== INITIAL_DATA[name] };
    setFormData({
      data: { ...data, [name]: value },
      touchedData,
      errors: newErrors,
      canSubmit:
        formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email'])
        && Object.values(touchedData).some(value => Boolean(value)),
    });
  };
  const handleSave = async () => {
    try {
      setLoading(true);
      const { data, touchedData } = formData;
      await editUser({
        ...pickTouchedDataObject(data, touchedData),
        id: data.id,
        // role: getStaticData('roles').admin
      });

      data.is_active
        ? await clearSearchOnRequest()
        : await loadAllUsers();

      notification.success(data.is_active
        ? NOTIFICATIONS.SUCCESS_UPDATE_USER
        : NOTIFICATIONS.SUCCESS_DEACTIVATED_USER);

      onClose();
    } catch (error) {
      notification.error(getError(error));
      setLoading(false);
    }
  };
  const handleDelete = useCallback(debounce(async () => {
    try {
      setLoading(true);
      const { data } = formData;
      await deleteUser(data.id);
      await loadAllUsers();
      notification.success(NOTIFICATIONS.SUCCESS_DELETE_USER);
      onClose();
    } catch (error) {
      notification.error(getError(error));
      setLoading(false);
    }
  }, 300), []);

  const {
    data: {
      first_name,
      last_name,
      email,
      is_active,
    },
    errors,
  } = formData;

  if (showConfirmationModal) {
    return (
      <ConfirmBlock
        title="Are you sure you want to delete this user?"
        onSubmit={handleDelete}
        onCancel={() => setShowConfirmationModal(false)}
        submitText="Delete"
        cancelText="Back to edit"
        isLoading={loading}
      />
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Data>
        <Styled.Info>
          <Input
            name="first_name"
            title="First name"
            placeholder="First name"
            value={first_name}
            error={errors.first_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            name="last_name"
            title="Last name"
            placeholder="Last name"
            value={last_name}
            error={errors.last_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            type="email"
            name="email"
            title="Email"
            placeholder="Email"
            value={email}
            error={errors.email}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
        </Styled.Info>
        { !isHiddenDeleteButtons
          && (
            <Styled.Deactivation>
              <Styled.DeactivationText>Deactivate user</Styled.DeactivationText>
              <Switcher
                name="is_active"
                checked={!is_active}
                onChange={value => handleChange({ target: { name: 'is_active', value: !value } })}
              />
            </Styled.Deactivation>
          )
        }
      </Styled.Data>
      <Styled.Actions>
        <Button disabled={loading || !formData.canSubmit} onClick={handleSave}>
          Save
        </Button>
        { !isHiddenDeleteButtons
          && (
            <Styled.DeleteUser>
              <Button disabled={loading} onClick={() => setShowConfirmationModal(true)}>
                Delete user
              </Button>
            </Styled.DeleteUser>
          )
        }
      </Styled.Actions>
      { loading && <ContainerLoader /> }
    </Styled.Wrapper>
  );
}

EditAdminForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    is_active: PropTypes.bool.isRequired,
  }).isRequired,
  editUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  isHiddenDeleteButtons: PropTypes.bool.isRequired,
  showConfirmationModal: PropTypes.bool.isRequired,
  setShowConfirmationModal: PropTypes.func.isRequired,
};


export default connect(null, {
  editUser: LS_AdminSettingsEntity.actions.editUser,
  deleteUser: LS_AdminSettingsEntity.actions.deleteUser,
})(EditAdminForm);
