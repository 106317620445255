export default (params) => {
  if (!Array.isArray(params)) {
    console.warn('1st parameter given to "removeQueryParamsFromUrl" must be array');
    return;
  }
  const searchParams = new URLSearchParams(window.location.search);
  params.forEach((param) => {
    searchParams.delete(param);
  });

  if (window.history.pushState) {
    const newParams = searchParams.toString();
    const newUrl = `${window.location.origin}${window.location.pathname}${newParams ? `?${newParams}` : ''}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  } else {
    console.warn('history.pushState is not allowed in this browser');
  }
};
