/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Logo, Input, Button } from 'components';

/* Constants */
import { LOGO_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

function SetEmail({
  campaignName,
  participantEmail,
  onParticipantEmailChange,
  onParticipantEmailBlur,
  isEmailDisabled,
  onNextClick,
  emailError,
  isFormDisabled
}) {
  return (
    <Styled.Wrapper>
      <Logo type={LOGO_CONFIG.VERTICAL_BLUE_BLACK} />
      <Styled.Text>
        You've been invited to
        <Styled.CompanyName>
          <em>
            {campaignName}
          </em>
        </Styled.CompanyName>
        campaign. Press Next to choose date and book a session
      </Styled.Text>
      <Input
        type="email"
        name="email"
        value={participantEmail}
        error={emailError}
        onChange={onParticipantEmailChange}
        onBlur={onParticipantEmailBlur}
        isDisabled={isEmailDisabled}
        placeholder="Enter email address"
      />
      <Button
        onClick={onNextClick}
        disabled={!isFormDisabled}
      >
        Next
      </Button>
    </Styled.Wrapper>
  );
}

SetEmail.propTypes = {
  campaignName: PropTypes.string,
  participantEmail: PropTypes.string,
  onParticipantEmailChange: PropTypes.func,
  isEmailDisabled: PropTypes.bool,
  onNextClick: PropTypes.func,
  emailError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

SetEmail.defaultProps = {
  campaignName: '"No campaign name"',
  emailError: false,
};

export default SetEmail;
