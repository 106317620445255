import React, {
  useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  debounce, isEmpty, isNaN,
} from 'lodash';

/* Actions */
import { AdminCompanyEntity, LeaderboardEntity } from '_entities';

/* Constants */

import { STRUCT_IDS } from '_constants';

/* Components */
import { Pagination, ParticipantsSection } from 'components';

/* Utils */
import {
  exportCSV, getError, replaceQueryParams, removeAllSpaces,
} from 'utils/custom';
import { notification } from 'utils/services';
import { PARTICIPANTS_TABLE, URLS_CONFIG } from 'config';

const PAGE_TITLE = 'Campaign participants';

const CampaignParticipants = ({
  match: {
    params: { id },
    url,
  },
  location: { search },
  campaignParticipants,
  downloadCampaignParticipants,
  getCampaignParticipants,
  getCampaignNameById,
  anonymousParticipantsCount,
  participantsCount,
}) => {
  const [page, setPage] = useState(1);
  const [campaignName, setCampaignName] = useState('Campaign Participants');
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [searchInput, setSearch] = useState('');

  const getParticipants = async ({
    email = searchInput,
    currentPage = page,
  } = {}) => {
    try {
      const { results, count } = await getCampaignParticipants({
        id,
        email,
        page: currentPage,
      });

      if (isEmpty(results) && count) {
        setPage(1);
        replaceQueryParams({ page: 1 });
        getParticipants({ currentPage: 1 });
        return;
      }
      replaceQueryParams({ page: currentPage });
      setTotalParticipants(count);
    } catch (e) {
      notification.error(getError(e));
    }
  };

  const debouncedGetParticipants = useCallback(debounce(getParticipants, 300), []);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const searchParamsPage = +urlParams.get('page');
    const queryPage = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;
    getParticipants({ currentPage: queryPage });
    setPage(queryPage);
    (async () => {
      const { name } = await getCampaignNameById({ id });
      setCampaignName(`Participants at ${name}`);
    })();
  }, []);


  const handleSearch = ({ target: { value } }) => {
    setSearch(removeAllSpaces(value));
    debouncedGetParticipants({ email: removeAllSpaces(value), page: 1 });
  };

  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
    getParticipants({ currentPage: selected + 1 });
    document.querySelector(`#${STRUCT_IDS.COMPANY_ADMIN_PAGE_CONTENT}`).scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const download = useCallback(async () => {
    try {
      const downloadedParticipants = await downloadCampaignParticipants({ id });
      exportCSV(downloadedParticipants, 'campaign_participants');
    } catch (error) {
      notification.error(getError(error));
    }
  }, []);

  return (
    <ParticipantsSection
      title={campaignName}
      download={download}
      handleSearch={handleSearch}
      searchValue={searchInput}
      participants={campaignParticipants}
      anonymousParticipantsCount={anonymousParticipantsCount}
      participantsCount={participantsCount}
      url={url}
      pagination={(
        <Pagination
          pageCount={totalParticipants / PARTICIPANTS_TABLE.PAGINATION.PER_PAGE}
          pageChangeHandler={handlePageChange}
          activePage={page}
          withInitialPage={false}
        />
      )}
    />
  );
};

/* Page Url */

CampaignParticipants.path = URLS_CONFIG.companyAdmin.reports.campaignParticipants;

/* Page Title */

CampaignParticipants.title = PAGE_TITLE;

CampaignParticipants.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  campaignParticipants: PropTypes.arrayOf(),
  downloadCampaignParticipants: PropTypes.func.isRequired,
  getCampaignParticipants: PropTypes.func.isRequired,
  anonymousParticipantsCount: PropTypes.number,
  participantsCount: PropTypes.number,
};

CampaignParticipants.defaultProps = {
  campaignParticipants: [],
  anonymousParticipantsCount: 0,
  participantsCount: 0,
};

const mapStateToProps = ({
  auth: { company },
  adminCompany: {
    campaignParticipants: {
      results: campaignParticipants,
      anonymous_participants_count: anonymousParticipantsCount,
      count: participantsCount,
    } = {},
  },
}) => ({
  companyId: company,
  campaignParticipants,
  anonymousParticipantsCount,
  participantsCount,
});

const mapDispatch = {
  downloadCampaignParticipants:
    AdminCompanyEntity.actions.getCampaignReportsDownloadParticipants,
  getCampaignParticipants: AdminCompanyEntity.actions.getCampaignParticipants,
  getCampaignNameById: LeaderboardEntity.actions.getCampaignNameById,
};

export default connect(mapStateToProps, mapDispatch)(CampaignParticipants);
