import { useState } from 'react';

const useValidationOnBlurHook = () => {
  const [validationErrors, setValidationErrors] = useState({});

  const validateOnBlurWithMessage = msg => (e) => {
    const {
      target: { name, value },
    } = e;
    if (value) return;
    setValidationErrors(prevState => ({ ...prevState, [name]: msg }));
  };

  const withValidationReset = onChangeFn => (e) => {
    const {
      target: { name },
    } = e;
    onChangeFn(e);
    setValidationErrors(prevState => ({ ...prevState, [name]: null }));
  };

  return {
    validationErrors,
    validateOnBlurWithMessage,
    withValidationReset,
  };
};

export default useValidationOnBlurHook;
