/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function GameStatisticItem({
  name,
  value,
  description,
  bold,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Name bold={bold}>
          {name}
        </Styled.Name>
        <Styled.Value bold={bold}>
          {value}
        </Styled.Value>
      </Styled.Content>
      {
        description && (
          <Styled.Description>{description}</Styled.Description>
        )
      }
    </Styled.Wrapper>
  );
}

/* GameStatisticItem type of props */

GameStatisticItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string,
  bold: PropTypes.bool,
};

GameStatisticItem.defaultProps = {
  description: null,
  bold: false,
};

export default GameStatisticItem;
