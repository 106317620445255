/* Libs */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isEmpty,
  merge,
} from 'lodash';
import moment from 'moment';

/* Actions */

import {
  KitsEntity,
  LS_AdminStorylinesEntity,
} from '_entities';

/* Utils */

import { validateDate, validateText } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { notification } from 'utils/services';
import { getError, hasPermission } from 'utils/custom';
import { storylinesToOptions } from 'utils/transformers';

/* Components */

import {
  Button,
  Input,
  Select,
  DatePicker,
  ContainerLoader, IconTooltip,
} from 'components';


/* Constants */

import { INFO_CIRCLE_BLUE } from 'assets/icons';
import { NOTIFICATIONS, PERMISSION_NAMES } from '_constants';

/* Styles */

import * as Styled from './styles';


function EditKitForm({
  availableStorylines,
  data: INITIAL_DATA,
  editCompanyKit,
  getStorylines,
  onClose,
  loading,
}) {
  const [dates, setDates] = useState({
    licenseStart: INITIAL_DATA.license_start,
    licenseEnd: INITIAL_DATA.license_end,
  });
  const [nickname, setNickname] = useState(INITIAL_DATA.nickname);
  const [datesErrors, setDatesErrors] = useState({
    licenseStart: INITIAL_DATA.license_start ? false : null,
    licenseEnd: INITIAL_DATA.license_end ? false : null,
  });
  const [nicknameError, setNicknameError] = useState(isEmpty(INITIAL_DATA.nickname) ? null : false);
  const [loaded, setLoaded] = useState(true);
  const [storylines, setStorylines] = useState(storylinesToOptions(availableStorylines));

  useEffect(() => {
    (async () => {
      try {
        setLoaded(false);
        // endpoint has default limit, but we need to load all storylines
        const { results } = await getStorylines({ limit: 9999999 });
        if (results.length < 1) {
          notification.error(NOTIFICATIONS.ERROR_KIT_NO_STORYLINE);
          onClose();
          return;
        }
        setStorylines(storylinesToOptions(results));
        setLoaded(true);
      } catch (error) {
        notification.error(getError(error));
      }
    })();
  }, []);


  const handleFixedValueChange = useCallback(() => {}, []);

  const handleDateChange = useCallback(
    ({ target: { name, value } }) => {
      const newErrors = { ...datesErrors };

      switch (name) {
        case 'licenseStart':
          newErrors[name] = validateDate({
            value,
            name,
          });
          break;
        case 'licenseEnd':
          newErrors[name] = validateDate({
            value,
            name,
            required: false,
          });
          break;
        default:
          break;
      }

      setDates({
        ...dates,
        [name]: value,
      });
      setDatesErrors(newErrors);
    },
    [
      dates,
      datesErrors,
    ],
  );

  const handleNicknameChange = useCallback(
    ({ target: { name, value } }) => {
      setNickname(value);
      setNicknameError(
        validateText({
          value,
          name,
          max: 256,
        }),
      );
    },
    [],
  );

  const handleSave = async () => {
    try {
      await editCompanyKit(merge(
        {
          storyline: INITIAL_DATA.storyline,
          id: INITIAL_DATA.id,
        },
        INITIAL_DATA.license_start !== dates.licenseStart
          ? { license_start: dates.licenseStart }
          : {},
        INITIAL_DATA.license_end !== dates.licenseEnd
          ? { license_end: dates.licenseEnd || null }
          : {},
        INITIAL_DATA.nickname !== nickname
          ? { nickname }
          : {},
      ));

      notification.success(NOTIFICATIONS.SUCCESS_KIT_EDITED);
      onClose();
    } catch (error) {
      if (error.response.data) {
        Object.keys(error.response.data).forEach((key) => {
          switch (key) {
            case 'nickname':
              setNicknameError(error.response.data[key][0]);
              break;
            default:
              notification.error(getError(error));
              break;
          }
        });
      } else {
        notification.error(getError(error));
      }
    }
  };


  const minDate = useMemo(() => moment(dates.licenseStart).toDate(), [dates.licenseStart]);
  const canSubmit = formIsValid(
    {
      license_start: validateDate({ value: dates.licenseStart, name: 'license_start' }),
      nickname: validateText({ value: nickname, name: 'nickname', max: 256 }),
    },
    [
      'license_start',
      'nickname',
    ],
  ) && (!dates.licenseEnd || !datesErrors.licenseEnd);

  const unTouchedFields = () => (
    INITIAL_DATA.license_end !== dates.licenseEnd
    || INITIAL_DATA.license_start !== dates.licenseStart
    || INITIAL_DATA.nickname !== nickname
  );

  const handleDisabled = () => loading || !unTouchedFields() || !canSubmit;

  const fixedValues = useMemo(() => (
    <>
      <Styled.Input>
        { !loaded && <ContainerLoader friendlyMode /> }
        <Styled.InputLabel>
          Storyline
          <IconTooltip
            src={INFO_CIRCLE_BLUE}
            items={['Storyline can be changed only during kit creation']}
          />
        </Styled.InputLabel>
        <Select
          onChange={handleFixedValueChange}
          value={INITIAL_DATA.storyline}
          name="storyline"
          placeholder="Storyline"
          options={storylines}
          isDisabled
        />
      </Styled.Input>
      <Styled.Input>
        <Styled.InputLabel>
          Kit ID
          <IconTooltip
            src={INFO_CIRCLE_BLUE}
            items={['Kit ID can be changed only during kit creation']}
          />
        </Styled.InputLabel>
        <Input
          isDisabled
          placeholder="Kit id"
          name="kit_id"
          value={INITIAL_DATA.kit_id}
          onChange={handleFixedValueChange}
        />
      </Styled.Input>
    </>
  ), [loaded]);

  return (
    <Styled.Wrapper>
      <Styled.Input>
        <Styled.InputGroup>
          <DatePicker
            placeholder="Start date"
            title="Start date"
            name="licenseStart"
            error={datesErrors.licenseStart}
            value={dates.licenseStart}
            onChange={handleDateChange}
            onBlur={handleDateChange}
            disabled={!hasPermission(PERMISSION_NAMES.EDIT_KIT_DATES)}
          />
          <DatePicker
            placeholder="End date"
            title="End date"
            name="licenseEnd"
            error={datesErrors.licenseEnd}
            value={dates.licenseEnd}
            minDate={minDate}
            onChange={handleDateChange}
            onBlur={handleDateChange}
            position="right"
            disabled={!hasPermission(PERMISSION_NAMES.EDIT_KIT_DATES)}
          />
        </Styled.InputGroup>
      </Styled.Input>
      <Styled.Input>
        <Styled.InputLabel>
          Nickname
        </Styled.InputLabel>
        <Input
          placeholder="Nickname"
          name="nickname"
          value={nickname}
          onChange={handleNicknameChange}
          onBlur={handleNicknameChange}
          error={nicknameError}
        />
      </Styled.Input>
      { fixedValues }
      <Styled.Actions>
        <Button
          disabled={handleDisabled()}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* EditKitForm type of props */

EditKitForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    license_start: PropTypes.string.isRequired,
    license_end: PropTypes.string,
    kit_id: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    storyline: PropTypes.number.isRequired,
  }).isRequired,
  editCompanyKit: PropTypes.func.isRequired,
  getStorylines: PropTypes.func.isRequired,
};

export default connect(({
  kits,
  LS_adminStorylines,
}) => ({
  loading: kits.loading,
  availableStorylines: LS_adminStorylines.storylines,
}), {
  editCompanyKit: KitsEntity.actions.editCompanyKit,
  getStorylines: LS_AdminStorylinesEntity.actions.getAllStorylines,
})(EditKitForm);
