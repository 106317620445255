/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Constants */
import { AVATAR_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

function Avatar({
  initials,
  avatar,
  size,
}) {
  return (
    <Styled.Wrapper size={size}>
      <Styled.Avatar
        avatar={!!avatar}
        style={{
          backgroundImage: `url(${avatar || null})`,
        }}
      >
        {!avatar && initials}
      </Styled.Avatar>
    </Styled.Wrapper>
  );
}

// Type of props
Avatar.propTypes = {
  avatar: PropTypes.string,
  initials: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    AVATAR_CONFIG.SIZE.S,
    AVATAR_CONFIG.SIZE.M,
  ]),
};

// Default value for props
Avatar.defaultProps = {
  avatar: null,
  size: AVATAR_CONFIG.SIZE.S,
};

export default connect(({ auth: { firstName, lastName } }) => ({
  initials: `${(firstName || 'N').charAt(0)}${(lastName || 'N').charAt(0)}`,
}))(Avatar);
