/* Libs */
import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/* Components */

import {
  BackTriangle,
  Loader,
} from 'components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function Tooltip({
  visible,
  loading,
  children,
  parentRef,
  tooltipWidth,
}) {
  const tooltipRef = useRef(null);

  const [place, setPlace] = useState('bottom');
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [tooltipRight, setTooltipRight] = useState(0);

  useEffect(() => {
    if (!visible) return;

    const offsetBottom = window.innerHeight - parentRef.current.getBoundingClientRect().bottom;
    setPlace(offsetBottom - tooltipRef.current.offsetHeight < 10 ? 'top' : 'bottom');

    if (!tooltipRight) {
      setTooltipRight(tooltipRef.current.getBoundingClientRect().right);
    }
    if (!tooltipHeight) {
      setTooltipHeight(tooltipRef.current.offsetHeight);
    }
  }, [parentRef, visible]);

  const topStyle = useMemo(() => {
    if (!parentRef.current) return null;
    switch (place) {
      case 'bottom':
        return parentRef.current.getBoundingClientRect().top + 20;
      case 'top':
        if (window.innerHeight - parentRef.current.getBoundingClientRect().bottom < 0) {
          // if parentRef element is partially below bottom of viewport
          return window.innerHeight - tooltipHeight - 20;
        }
        return parentRef.current.getBoundingClientRect().top
          - tooltipHeight
          - 10;
      default:
        return parentRef.current.getBoundingClientRect().top + 20;
    }
  }, [parentRef, place, tooltipHeight, visible]);

  const tooltipStyle = useMemo(() => ({
    top: topStyle,
    left: (parentRef.current && parentRef.current.getBoundingClientRect().left) || 0,
    width: tooltipWidth || 'auto',
    marginRight: tooltipRight === window.innerWidth ? 20 : 0,
  }), [parentRef, topStyle, tooltipWidth, tooltipRight]);


  return ReactDOM.createPortal(
    <Styled.Tooltip
      visible={parentRef.current && visible}
      ref={tooltipRef}
      place={place}
      style={tooltipStyle}
    >
      <BackTriangle color={COLORS_CONFIG.WHITE} />
      <Styled.Content>
        {loading ? <Loader /> : children}
      </Styled.Content>
    </Styled.Tooltip>,
    document.querySelector('body'),
  );
}

/* Tooltip type of props */

Tooltip.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  parentRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

Tooltip.defaultProps = {
  visible: false,
  loading: false,
};

export default Tooltip;
