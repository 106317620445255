/* Libs */
import styled from 'styled-components';

import { default as Triangle } from 'components/blocks/Markers/TopTriangle/styles';

import { COLORS_CONFIG } from 'config';

export const Card = styled.div`
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 3px;
  padding: 20px 15px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;

export const Content = styled.div`
  position: relative;
  & ${Triangle} {
    position: absolute;
    left: 20px;
    top: -9px;
    z-index: 5;
    border-width: 0 9px 10px 9px;
  }
`;

export const Wrapper = styled.div`
  min-width: 250px;
  
`;
