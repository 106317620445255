import { generateEmptyQuestion } from 'utils/custom';
import { validateGameDefField } from 'utils/validation';

export default (data) => {
  const basicData = {
    data: {
      name: data.name,
      company: data.company,
      storyline: data.storyline,
      final_code: data.final_code,
      time_limit: data.time_limit,
      game_def_template: data.game_def_template,
      is_final_code_only: data.is_final_code_only,
    },
    section: 'basicInformation',
  };

  const introductionData = {
    data: {
      introduction_title: data.introduction_title,
      introduction_text: data.introduction_text,
      introduction_vimeo_id: data.introduction_vimeo_id,
    },
    section: 'introduction',
  };

  const instructionData = {
    data: {
      instruction_title: data.instruction_title,
      instruction_text: data.instruction_text,
      instruction_vimeo_id: data.instruction_vimeo_id,
    },
    section: 'instruction',
  };

  const storylineData = {
    data: {
      storyline_title: data.storyline_title,
      storyline_text: data.storyline_text,
      storyline_vimeo_id: data.storyline_vimeo_id,
    },
    section: 'storyline',
  };

  const debriefingData = {
    data: {
      debriefing_title: data.debriefing_title,
      debriefing_text: data.debriefing_text,
      debriefing_vimeo_id: data.debriefing_vimeo_id,
    },
    section: 'debriefing',
  };

  const resetData = {
    data: {
      reset_title: data.reset_title,
      reset_text: data.reset_text,
      reset_vimeo_id: data.reset_vimeo_id,
    },
    section: 'reset',
  };

  const { questions = [generateEmptyQuestion(1)] } = data;

  const basicInformationErrors = [
    basicData,
    introductionData,
    instructionData,
    storylineData,
    debriefingData,
    resetData,
  ].reduce((errors, item) => Object.entries(item.data).reduce((acc, [name, value]) => {
    errors[name] = validateGameDefField({
      name,
      value,
      section: item.section,
    });
    return errors;
  }, {}), {});

  const questionErrors = questions.reduce((acc, question, index, self) => {
    const otherQuestions = self.filter(q => q.name !== question.name);
    Object.entries(question).forEach(([name, questionValue]) => {
      acc[`questions[${index}].${name}`] = validateGameDefField({
        name,
        value: questionValue,
        section: 'questions',
        additionalData: {
          otherQuestions,
          finalCode: data.final_code,
        },
      });
    });
    return acc;
  }, {});

  return {
    ...basicInformationErrors,
    ...questionErrors,
  };
};
