/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { Image } from 'components/blocks/ui/Img/styles';


export const Wrapper = styled.div`
  position: relative;
  ${({ height }) => height && css`
    height: ${`${height}px`};
  `}
`;

export const HiddenInput = styled.input`
  position: absolute
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-indent: -9999px;
`;

export const Text = styled.div`
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-size: 12px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  padding: 0 8px 0 0;
  text-align: right;
  width: ${({ textWidth }) => textWidth || '200px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  & ${Image}{
    transform: translateY(-2px);
  }
  
  ${({ imgPosition }) => imgPosition === 'left' && css`
      & ${Text} {
          padding: 0 0 0 8px;
          font-size: 16px;
          font-family: GothamBold;
          line-height: 16px;
          text-align: left;
      }
      
      & ${Image}{
          transform: translateY(-1px);
      }
  
  `}
`;
