import { merge } from 'lodash';
import { getTimezoneOffset, getTimezoneName } from 'utils/custom';
import { MULTIPLE_SESSION_REQUEST_TYPES } from 'config';

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];


const multipleSessionsDataToRequestObject = (data, type, isOnlineOnly) => {
  const timezoneOffset = getTimezoneOffset(getTimezoneName(data.timezone));

  const requestData = ({
    campaign: data.campaign,
    kit: data.kit,
    game_def: data.gameDef,
    location: data.location,
    timezone: getTimezoneName(data.timezone),
    timezone_offset: timezoneOffset,
    start_time: data.startTime,
    sessions_per_day: data.sessionsPerDay,
    weekdays: data.weekdays.map(weekday => days.indexOf(weekday)),
    start_date: data.dateFrom,
    end_date: data.dateTo,
    // break_duration: data.breakDuration || '00:00:00',
    introduction_time: data.introductionTime || 0,
    debriefing_time: data.debriefingTime || 0,
    participants_per_session: data.maxParticipants || 0,
    is_timer_counting_up: data.isTimerCountingUp || false,
    ...(isOnlineOnly && { meeting_link: data.meetingLink }),
  });

  return (
    type === MULTIPLE_SESSION_REQUEST_TYPES.GET
      ? requestData
      : (
        merge(
          requestData,
          { exclude_start_datetimes: data.excludeStartDatetimes },
        )
      )
  );
};


export default multipleSessionsDataToRequestObject;
