
import { getPlacesFromResponse } from 'utils/transformers';

import { LEADERBOARD_TABLE } from 'config';
import * as TYPES from './Leaderboard.types';

const initialState = {
  loading: false,
  loaded: false,
  topPlaces: {},
  campaignName: '',
  page: 1,
  total: 0,
  list: [],
  campaign: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_LEADERBOARD:
    case TYPES.GET_TOP_PLACES:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SET_PAGE: {
      const { newPage } = action.payload;
      return {
        ...state,
        page: newPage,
      };
    }
    case TYPES.GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        list: action.result.results.map((position, idx) => ({
          ...position,
          rank: (state.page - 1) * LEADERBOARD_TABLE.PAGINATION.PER_PAGE + idx + 1,
        })),
        total: action.result.count,
        loading: false,
        loaded: true,
      };
    case TYPES.GET_TOP_PLACES_SUCCESS:
      return {
        ...state,
        topPlaces: getPlacesFromResponse(action.result.results),
        loading: false,
      };
    case TYPES.GET_CAMPAIGN_NAME_SUCCESS:
      return {
        ...state,
        campaignName: action.result.name,
      };
    case TYPES.GET_LEADERBOARD_FAIL:
    case TYPES.GET_TOP_PLACES_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
