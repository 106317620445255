/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { LeaderboardList, IconTooltip } from 'components';

/* Assets */

import { INFO_CIRCLE_BLUE } from 'assets/icons';

/* Styles */

import * as Styled from './styles';

function LeaderboardSection({ data, next, total }) {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>Leaderboard</Styled.Title>
        <IconTooltip
          src={INFO_CIRCLE_BLUE}
          items={['% - Questions answered correctly for the first time']}
        />
      </Styled.Header>
      <Styled.Main>
        <LeaderboardList data={data} next={next} total={total} />
      </Styled.Main>
    </Styled.Wrapper>
  );
}

/* LeaderboardSection type of props */

LeaderboardSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      team_name: PropTypes.string,
      time_completed: PropTypes.string,
    }),
  ),
  next: PropTypes.func.isRequired,
  total: PropTypes.number,
};

/* LeaderboardSection default props */

LeaderboardSection.defaultProps = {
  data: [],
  total: 0,
};

export default LeaderboardSection;
