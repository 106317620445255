/* Libs */
import styled from 'styled-components';

/* Constants */
import { FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

/* Components */
import { Wrapper as CardWrapper } from 'components/blocks/Card/styles';


export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  ${CardWrapper} {
    min-height: 100%;
  }
`;

export const TopRow = styled(Row)`
  margin-bottom: 44px;
  & > div {
    width: 31%;
  }
`;

export const BottomRow = styled(Row)`
  & > div {
    width: 48%;
  }
`;

export const CompanyStruct = styled.div`
  padding: 45px 70px;
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    padding: 35px 50px;
  }
`;

export const Title = styled.div`
  margin-bottom: 27px;
  max-width: 100%;
  font-size: 40px;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  overflow: hidden;
  text-overflow: ellipsis;
`;
