/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

/* Styles */

import { Loader } from 'components/blocks/ui/Loader/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const Error = styled.div`
  color: ${COLORS_CONFIG.ORANGE};
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc( 100% + 2px );
  left: 0;
  width: 100%;
  display: none;
`;

export const Title = styled.div`
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 1;
  padding: 2px 5px;
  background: ${COLORS_CONFIG.WHITE};
  font-size: 11px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 34px;
  ${({ hasError }) => hasError && css`
    .ReactSelect__control {
      border-color: ${COLORS_CONFIG.ORANGE}!important;
    }
    .ReactSelect__single-value {
      color: ${COLORS_CONFIG.ORANGE}!important;
    }
    ${Error} {
      display: block;
    }
  `}
  ${({ hasError }) => hasError === false && css`
    .ReactSelect__control {
      border-color: ${COLORS_CONFIG.LIGHT_GREEN}!important;
    }
  `}
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    
    ${Title} {
      color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
    }
 `}
  .ReactSelect {
    &__menu {
      z-index: 20;
      ${({ relativeMenu }) => !!relativeMenu && css`
        position: relative;
      `}
      
      > div {
         max-height: 200px; 
         
           &::-webkit-scrollbar {
             width: 12px;
             height: 18px;
             background: white;
           }
         
           &::-webkit-scrollbar-thumb {
             height: 6px;
             border: 2px solid rgba(0, 0, 0, 0);
             background-clip: padding-box;
             -webkit-border-radius: 7px;
             background-color: #C4C4C4;
           }
         
           &::-webkit-scrollbar-button {
             width: 0;
             height: 0;
             display: none;
           }
         
           &::-webkit-scrollbar-corner {
             background-color: transparent;
           }           
      }
    }

    &__control {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      color: ${COLORS_CONFIG.SMOOTH_BROWN};
      font-size: 16px;
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
      background-color: ${COLORS_CONFIG.TRANSPARENT};
      border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};

      &:hover,
      &--is-focused {
        border-color: ${COLORS_CONFIG.SKY_BLUE};
        box-shadow: 0 0 1px ${COLORS_CONFIG.SKY_BLUE};
      }
    }

    &__value-container {
      padding: 15px 24px;

      & > div {
        margin: 0;
      }

      &--is-multi {


        & > div {
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 0;
        }

        &.ReactSelect {

          &__value-container {

            &--has-value {
              padding: 11px 13px;
              /* max-height: 80px; */
              max-height: 240px;
              overflow: auto;
              
              &::-webkit-scrollbar {
                 width: 12px;
                 height: 18px;
                 background: white;
               }
             
               &::-webkit-scrollbar-thumb {
                 height: 6px;
                 border: 2px solid rgba(0, 0, 0, 0);
                 background-clip: padding-box;
                 -webkit-border-radius: 7px;
                 background-color: #C4C4C4;
               }
             
               &::-webkit-scrollbar-button {
                 width: 0;
                 height: 0;
                 display: none;
               }
             
               &::-webkit-scrollbar-corner {
                 background-color: transparent;
               }  
            }
          }
        }
      }
    }

    &__placeholder {
      margin: 0;
    }

    &__indicator {
      padding: 4px;
    }

    &__indicator-separator {
      display: none;
    }

    &__option {
      overflow: hidden;
      text-overflow: ellipsis;

      &--is-focused {
        background: ${COLORS_CONFIG.SILVER};
        cursor: pointer;
      }

      &--is-selected {
        pointer-events: none;
        background: ${COLORS_CONFIG.SKY_BLUE};
      }
    }

    &__group {
      padding: 0;

      .ReactSelect {

        &__option {
          text-align: center;
        }
      }
    }

    &__single-value {
      margin: 0;
      color: ${COLORS_CONFIG.SMOOTH_BROWN};
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    }

    &__multi-value {

      &__remove {

        &:hover {
          background: ${COLORS_CONFIG.SKY_BLUE$50};
        }
      }
    }

    & ${Loader} {
      height: 30px;
    }

    &.small {
      height: 100%;

      .ReactSelect {

        &__control {
          font-size: 14px;
          min-height: 32px;
        }

        &__value-container {
          padding: 2px 12px;
        }

        &__dropdown-indicator {
          padding: 5px;
        }

        &__single-value {
          font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
        }
      }
    }

    &--is-disabled {
      .ReactSelect__control {
        border-color: ${COLORS_CONFIG.SILVER_CUSTOM_7}!important;
      }
      
      .ReactSelect__single-value {
            margin: 0;
            color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
            font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
      }
      
      .ReactSelect__multi-value{
         background-color: ${COLORS_CONFIG.SILVER_LIGHT};
      }
      
      .ReactSelect__multi-value__label {
         color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
      }
      
      .ReactSelect__multi-value__remove{
        svg {
          path {
            fill: ${COLORS_CONFIG.SILVER_CUSTOM_7};
          }
        }
      }
      
      .ReactSelect__value-container--is-multi {
      
              &.ReactSelect {
      
                &__value-container {
      
                  &--has-value {
                  
                    &::-webkit-scrollbar {
                       width: 12px;
                       height: 18px;
                       background: transparent;
                     }
                   
                     &::-webkit-scrollbar-thumb {
                       height: 6px;
                       border: 2px solid rgba(0, 0, 0, 0);
                       background-clip: padding-box;
                       -webkit-border-radius: 7px;
                       background-color: #C4C4C4;
                     }
                   
                     &::-webkit-scrollbar-button {
                       width: 0;
                       height: 0;
                       display: none;
                     }
                   
                     &::-webkit-scrollbar-corner {
                       background-color: transparent;
                     }  
                  }
                }
              }
            }
    }
  }
`;

export const Select = styled.input`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  padding: 16px;
  background-color: ${COLORS_CONFIG.TRANSPARENT};
  border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
`;

export const Optional = styled.div`
  position: absolute;
  top: -10px;
  right: 20px;
  z-index: 1;
  padding: 2px 5px;
  background: ${COLORS_CONFIG.WHITE};
  font-size: 11px;
`;
