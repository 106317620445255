/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';

import { Button } from 'components/ui/Button/styles';
import { Wrapper as Tooltip } from 'components/blocks/ui/IconTooltip/styles';

export const Wrapper = styled.div`
  padding-top: 39px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  & ${Button} {
    width: 138px;
    min-width: 138px;
    height: 37px;
    border-radius: 24px;
    font-size: 14px;
    padding: initial;
    line-height: 37px;
  }
`;
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TimerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const Text = styled.div`
  margin-bottom: 10px;
  position: relative;
  ${Tooltip} {
    position: absolute;
    top: 0;
    right: -20px;
  }
`;

export const Delimitr = styled.div`
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: ':';
    font-size: 30px;
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
  }
  position: relative;
  width: 10px;
  padding:0 13px;
`;
