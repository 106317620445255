import { validateQuestionForm } from 'utils/validation/fields';

export default (questions, finalCodeOnly, finalCode) => {
  if (finalCodeOnly) return true;
  const questionsErrors = questions.reduce((acc, item, index, self) => {
    const { isValid } = validateQuestionForm({
      questionForValidate: item,
      otherQuestions: self.filter(
        otherQuestion => otherQuestion.number !== item.number,
      ),
      final_code: finalCode,
    });

    return acc.concat(isValid);
  }, []);
  return questionsErrors.every(item => !!item);
};
