/* Libs */
import React, {
  useRef, useState, useEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactCalendar from 'react-calendar';

/* Hooks */

import {
  useOutsideClickHook,
  useToggleBooleanHook,
} from 'hooks';

/* Components */

import {
  CircleForward,
  CircleBack,
  MaskInput,
} from 'components';

/* Constants */

import { DATE_SETTINGS } from 'config';

/* Styles */

import { CALENDAR } from 'assets/icons';
import { isEmpty } from 'utils/validation';
import { Cell } from './Subcomponents';
import * as Styled from './styles';

const VIEWS = {
  MONTH: 'month',
  YEAR: 'year',
};

function DatePicker({
  name,
  value,
  error,
  onChange,
  position,
  checkDates, // if that is enabled, we will enable only dates from availableDates
  placeholder,
  title,
  closeOnClick,
  availableDates, // TODO: create check for real dates
  editable,
  pickable,
  minDate,
  maxDate,
  disabled,
}) {
  const [isOpened, toggle] = useToggleBooleanHook(false);

  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(null);

  const [currentView, setView] = useState(VIEWS.MONTH);

  useEffect(() => {
    const momentObj = moment(
      value,
      [
        DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
        DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR,
        DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR_HYPHEN,
      ],
      null,
      true,
    );
    const isDateValid = momentObj.isValid();

    setInputValue(isDateValid
      ? momentObj.format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR)
      : value);
    setInputError(error);
  }, [value, error]);

  function handleInputChange({ target: { value: newValue } }) {
    const momentObject = moment(newValue, DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR, true);

    onChange({
      target: {
        name,
        value: momentObject.isValid()
          ? momentObject.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)
          : newValue,
      },
    });
  }

  const calendarRef = useRef(null);

  useOutsideClickHook(() => toggle(false), calendarRef);

  const preparedValue = moment(value, [
    DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
    DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR,
  ], true);

  const dateIsAvailable = (date) => {
    if (!checkDates) return true;
    return availableDates.some(availableDate => moment(availableDate).isSame(date, 'day'));
  };

  useEffect(() => {
    if (minDate && moment(value).isBefore(minDate)) {
      onChange({
        target: {
          name,
          value: moment(minDate).format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        },
      });
    }
  }, [minDate]);

  return (
    <Styled.Wrapper
      ref={calendarRef}
      currentView={currentView}
      disabled={disabled}
    >
      {!isEmpty(title) && value && <Styled.Title>{title}</Styled.Title>}
      <MaskInput
        value={inputValue}
        name={name}
        error={inputError || error}
        onChange={editable ? handleInputChange : undefined}
        mask="99/99/9999"
        placeholder={placeholder}
        icon={CALENDAR}
        onClick={pickable && !editable ? toggle : undefined}
        onIconClick={pickable ? toggle : undefined}
        isDisabled={disabled}
      />
      <Styled.Calendar
        isOpen={isOpened}
        position={position}
      >
        <ReactCalendar
          locale="en"
          onClickDay={(date) => {
            onChange({ target: { name, value: moment(date).format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY) } });
            closeOnClick && toggle();
          }}
          value={(preparedValue.isValid() && new Date(value.split('-'))) || undefined}
          nextLabel={<CircleForward />}
          prevLabel={<CircleBack />}
          calendarType="US"
          onDrillDown={({ view }) => setView(view)}
          onDrillUp={({ view }) => setView(view)}
          minDetail="year"
          maxDetail="month"
          next2Label={null}
          prev2Label={null}
          navigationLabel={({ date }) => moment(date).format(DATE_SETTINGS.FORMAT.MONTH_YEAR)}
          tileDisabled={({ date }) => (checkDates && currentView === VIEWS.MONTH ? !dateIsAvailable(date) : false)}
          tileContent={({ date }) => (
            <Cell
              day={moment(date).format(currentView === VIEWS.YEAR ? 'MMM' : 'D')}
              hasEvent={checkDates ? dateIsAvailable(date) : false}
            />
          )}
          name={name}
          minDate={minDate}
          maxDate={maxDate}
          onClickMonth={(date) => {
            onChange({ target: { name, value: moment(date).format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY) } });
          }}
        />
      </Styled.Calendar>
    </Styled.Wrapper>
  );
}

/* DatePicker type of props */

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func.isRequired,
  editable(props, propName, componentName) {
    if (!props.pickable && !props[propName]) {
      return new Error(
        `Prop ${propName} should be enabled if pickable prop is disabled in component ${componentName}.`,
      );
    }
  },
  pickable(props, propName, componentName) {
    if (!props.editable && !props[propName]) {
      return new Error(
        `Prop ${propName} should be enabled if editable prop is disabled in component ${componentName}.`,
      );
    }
  },
  name: PropTypes.string.isRequired,
  checkDates: PropTypes.bool,
  availableDates: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string, // TODO: style placeholder with errors
  closeOnClick: PropTypes.bool,
  error: PropTypes.any, // TODO
  position: PropTypes.oneOf(['left', 'center', 'right']),
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: null,
  checkDates: false,
  availableDates: [],
  position: 'left',
  error: null,
  placeholder: '',
  editable: true,
  pickable: true,
  closeOnClick: true,
  disabled: false,
};

export default memo(DatePicker);
