import queryString from 'query-string';
import { pickBy, isUndefined } from 'lodash';

import { LEADERBOARD_TABLE } from 'config';
import * as TYPES from './Leaderboard.types';


export const getTopPlaces = ({
  token,
  // campaign,
  location,
}) => {
  const params = queryString.stringify(
    pickBy({
      token,
      location,
      offset: 0,
      limit: LEADERBOARD_TABLE.TOP_PLACES_COUNT,
    }, item => !isUndefined(item)),
  );

  return {
    types: [TYPES.GET_TOP_PLACES, TYPES.GET_TOP_PLACES_SUCCESS, TYPES.GET_TOP_PLACES_FAIL],
    promise: client => client.get(`/game-sessions/result/${params ? `?${params}` : ''}`),
  };
};

export const getLeaderboard = ({
  token,
  // campaign,
  location,
  locations,
  page = 1,
  perPage = LEADERBOARD_TABLE.PAGINATION.PER_PAGE,
} = {}) => {
  const params = queryString.stringify(
    pickBy({
      token,
      location,
      locations,
      offset: (page - 1) * perPage,
      limit: perPage,
    }, item => !isUndefined(item)),
  );

  return {
    types: [TYPES.GET_LEADERBOARD, TYPES.GET_LEADERBOARD_SUCCESS, TYPES.GET_LEADERBOARD_FAIL],
    promise: client => client.get(`/game-sessions/result/${params ? `?${params}` : ''}`),
  };
};

export const getCampaignNameById = ({ id }) => ({
  types: [TYPES.GET_CAMPAIGN_NAME, TYPES.GET_CAMPAIGN_NAME_SUCCESS, TYPES.GET_CAMPAIGN_NAME_FAIL],
  promise: client => client.get(`/campaigns/${id}/name`),
});

export const setPage = newPage => ({
  type: TYPES.SET_PAGE,
  payload: { newPage },
});
