/* Libs */
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Utils */
import { tokenService } from 'utils/services';

function GameRoute({
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        tokenService.removeToken({ withRedirect: false });
        return <Component {...props} />;
      }}
    />
  );
}

/* Component PropTypes */

GameRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]).isRequired,
};

export default GameRoute;
