/* Libs */
import styled from 'styled-components';

/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/* Components */
import { Button } from 'components/ui/Button/styles';

// Component styles
export const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 32px;
  height: 100%;
  text-align: center;
  ${Button} {
    &:last-child {
      margin-top: 15px;
      background: ${COLORS_CONFIG.TRANSPARENT};
      color: ${COLORS_CONFIG.BLACK};
    }
  }
`;

export const Title = styled.p`
  font-size: 24px;
  line-height: 1.2;
`;

export const Description = styled.p`
  margin-top: 20px;
  color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
`;

export const ConfirmActions = styled.div`
  margin-top: 40px;
`;

export const ConfirmSubmit = styled.button`
  padding: 5px;
  background: none;
  outline: none;
  border: 2px solid ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
`;

export const ConfirmCancel = styled.button`
  margin-left: 20px;
  padding: 5px;
  background: none;
  border: 2px solid ${COLORS_CONFIG.SKY_BLUE};
  outline: none;
  cursor: pointer;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;
