/* Libs */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

/* Utils */

import {
  redirect,
  getLaunchGameLink,
  hasPermission,
  getSessionTimeCellData,
} from 'utils/custom';

/* Components */

import {
  SimpleTextCell,
  ActionsCell,
  GoLabel,
  Table,
  SessionTimeCell,
  HeaderSessionTimeCell,
  ParticipantsCell,
  KitCell,
} from 'components';

/* Constants */

import { TRIANGLE_TYPES, PERMISSION_NAMES, KIT_FIELD_TYPES } from '_constants';
import { SESSION_TIME_TYPES } from 'config';

/* Styles */

import * as Styled from './styles';

// DaySchedule columns cell structure
const getColumnsData = ({
  gameSessionStatuses,
  actions,
  tableTimeType,
  toggleTableTimeType,
  kitFieldType,
  setKitFieldType,
  companyTimezone,
}) => [
  {
    accessor: 'start_time',
    minWidth: 185,
    Header: () => (
      <HeaderSessionTimeCell
        value={tableTimeType}
        onToggle={toggleTableTimeType}
        checked={tableTimeType === SESSION_TIME_TYPES.SESSION_TIME}
      />
    ),
    Cell: (props) => {
      const {
        startTime,
        endTime,
        timezoneTitle,
        label,
      } = getSessionTimeCellData(tableTimeType, companyTimezone, props);

      return (
        <SessionTimeCell
          startTime={startTime}
          endTime={endTime}
          timezoneTitle={timezoneTitle}
          label={label}
        />
      );
    },
  },
  {
    accessor: 'kit',
    minWidth: 95,
    flexGrow: 2,
    Header: () => (
      <KitCell
        value={kitFieldType}
        options={Object.values(KIT_FIELD_TYPES)}
        onChange={value => setKitFieldType(value)}
      />
    ),
    Cell: ({ original: { kit: { kit_id: kitId, nickname } } }) => (
      <SimpleTextCell
        value={(kitFieldType === KIT_FIELD_TYPES.NICKNAME && nickname) ? nickname : kitId}
        size="small"
      />
    ),
  },
  {
    accessor: 'location',
    minWidth: 95,
    flexGrow: 3,
    Header: 'Location',
    Cell: props => <SimpleTextCell value={props.original.location && props.original.location.location} size="small" />,
  },
  {
    accessor: 'invited_participants_count',
    minWidth: 130,
    headerStyle: {
      overflow: 'visible',
    },
    Header: () => (
      <ParticipantsCell
        leftParticipants="Signed up participants"
        rightParticipants="Participants per session"
      />
    ),
    Cell: ({
      original: {
        invited_participants,
        actual_participants,
        participants_per_session,
        signedup_participants,
        status,
      },
    }) => (
      <SimpleTextCell
        value={`${status === gameSessionStatuses.scheduled ? signedup_participants.length : actual_participants.length} of ${participants_per_session}`}
        size="small"
      />
    ),
  },
].concat(
  {
    minWidth: 150,
    Header: '',
    Cell: ({
      original: { id, status },
    }) => (status === gameSessionStatuses.scheduled
      ? (
        <ActionsCell>
          {
            hasPermission(PERMISSION_NAMES.EDIT_SESSION) && (
              <div onClick={() => actions.openEditSession(id)}>Edit</div>
            )
          }
          <GoLabel
            text="Launch"
            triangleType={TRIANGLE_TYPES.FORWARD}
            click={() => redirect(getLaunchGameLink(id))}
          />
        </ActionsCell>
      )
      : (
        <ActionsCell>
          <div onClick={() => actions.openSessionInfoModal(id)}>View</div>
          <div onClick={() => actions.onSessionDelete(id, status)}>Delete</div>
        </ActionsCell>
      )),
  },
);

function DaySchedule({
  data,
  actions,
  loading,
  tableSize,
  pagination,
  gameSessionStatuses,
  companyTimezone,
}) {
  const [tableTimeType, setTableTimeType] = useState(SESSION_TIME_TYPES.LOCAL_TIME);
  const [kitFieldType, setKitFieldType] = useState(KIT_FIELD_TYPES.ID);

  const toggleTableTimeType = () => setTableTimeType(
    tableTimeType === SESSION_TIME_TYPES.SESSION_TIME
      ? SESSION_TIME_TYPES.LOCAL_TIME
      : SESSION_TIME_TYPES.SESSION_TIME,
  );
  const columnsData = useMemo(() => getColumnsData({
    gameSessionStatuses,
    actions,
    tableTimeType,
    toggleTableTimeType,
    kitFieldType,
    setKitFieldType,
    companyTimezone,
  }), [
    gameSessionStatuses,
    actions,
    tableTimeType,
    data,
    kitFieldType,
  ]);

  return (
    <Styled.DaySchedule>
      <Table
        defaultPageSize={data.length || null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={columnsData}
        data={data}
        pagination={pagination}
        loading={loading}
      />
    </Styled.DaySchedule>
  );
}

// Type of props
DaySchedule.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    kit: PropTypes.shape({
      id: PropTypes.number.isRequired,
      kit_id: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      id: PropTypes.number.isRequired,
      location: PropTypes.string.isRequired,
    }),
    participants_per_session: PropTypes.number.isRequired,
  })).isRequired,
  actions: PropTypes.shape({
    openEditSession: PropTypes.func.isRequired,
  }).isRequired,
  pagination: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};


const mapStateToProps = state => ({
  companyTimezone: get(state, 'adminCompany.company.timezone'),
});

export default connect(mapStateToProps, null)(DaySchedule);
