/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */
import { isValidEmail } from 'utils/validation';

/* Styles */
import * as Styled from './styles';


function ViewSessionParticipantsForm({
  data: {
    campaign,
    invitedParticipants,
    signedupParticipants,
    actualParticipants,
  },
}) {
  const blocks = [
    {
      title: 'Invited',
      emails: invitedParticipants,
    },
    {
      title: 'Signed up',
      emails: signedupParticipants,
    },
    {
      title: 'Actually played',
      emails: actualParticipants,
    },
  ];

  return (
    <Styled.Wrapper>
      <Styled.Data>
        <Styled.Campaign>
          <Styled.Label>
            Campaign
          </Styled.Label>
          <Styled.CampaignName>
            {campaign && campaign.name || 'No campaign name'}
          </Styled.CampaignName>
        </Styled.Campaign>
        <Styled.Participants>
          {
            blocks.map(({ title, emails }) => (
              <Styled.ParticipantsBlock key={title}>
                <Styled.Title>
                  <Styled.TitleText>{title}</Styled.TitleText>
                  <Styled.TitleNumber>{emails.length}</Styled.TitleNumber>
                </Styled.Title>
                {
                  !emails.length || isValidEmail(emails[0].email)
                    ? (
                      <Styled.Emails>
                        {emails.map(({ id, email }) => <Styled.Email key={id}>{email}</Styled.Email>)}
                      </Styled.Emails>
                    )
                    : <Styled.InfoText>This session has been used the "secret mode"</Styled.InfoText>
                }
              </Styled.ParticipantsBlock>
            ))
          }
        </Styled.Participants>
      </Styled.Data>
    </Styled.Wrapper>
  );
}

/* ViewSessionParticipantsForm type of props */
ViewSessionParticipantsForm.propTypes = {
  data: PropTypes.shape({
    campaign: PropTypes.shape({
      name: PropTypes.string,
    }),
    invitedParticipants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
    })),
    signedupParticipants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
    })),
    actualParticipants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
    })),
  }),
};

/* ViewSessionParticipantsForm default props */
ViewSessionParticipantsForm.defaultProps = {
  data: {
    campaign: {
      name: 'No campaign name',
    },
    invitedParticipants: [],
    signedupParticipants: [],
    actualParticipants: [],
  },
};

export default ViewSessionParticipantsForm;
