/* Libs */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty,
  isEqual,
  merge,
  debounce,
} from 'lodash';

/* Utils */
import { validateCampaigns, validateEmail, validateText } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import {
  getError,
  transformDataToOptions,
} from 'utils/custom';
import { notification } from 'utils/services';
import { VALIDATION_VALUES } from '_constants';

/* Components */
import {
  Select,
  Input,
  Checkbox,
  Switcher,
  Button,
  ConfirmBlock,
} from 'components';

/* Styles */
import * as Styled from './styles';

const DEFAULT_ERRORS = {
  first_name: false,
  last_name: false,
  email: false,
  campaigns: false,
  addToAllCampaigns: false,
  deactivatedUser: false,
  isCompanyAdmin: false,
};

const OBJECTS_PROPERTIES = {
  addToAllCampaigns: 'addToAllCampaigns',
};

function EditUserForm({
  data: INITIAL_DATA,
  onDelete,
  onSave,
  roles,
  getAllActiveCampaigns,
  allActiveCampaigns,
  company,
  isCurrentUser,
  showDeleteModal,
  setShowDeleteModal,
  loggedID,
}) {
  const [formData, setFormData] = useState({
    data: INITIAL_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const [selectedData, setSelectedData] = useState(formData.data);
  const isInitialMount = useRef(true);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: VALIDATION_VALUES.NAME_MAX_LENGTH,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      case 'campaigns':
        newErrors[name] = validateCampaigns({
          value,
          name,
        });
        break;
      case 'addToAllCampaigns':
        newErrors.campaigns = value
          ? null
          : validateCampaigns({
            value: data.campaigns,
            name: 'campaigns',
          });
        break;
      default:
        break;
    }

    setFormData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit:
              formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email']),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: VALIDATION_VALUES.NAME_MAX_LENGTH,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      case 'campaigns':
        newErrors[name] = validateCampaigns({
          value,
          name,
        });
        break;
      case 'addToAllCampaigns':
        newErrors.campaigns = value
          ? null
          : validateCampaigns({
            value: data.campaigns,
            name: 'campaigns',
          });
        break;
      default:
        break;
    }
    setFormData({
      data: { ...data, [name]: value },
      errors: newErrors,
      canSubmit:
          formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email']),
    });
  };

  const {
    data: {
      first_name,
      last_name,
      email,
      campaigns,
      addToAllCampaigns,
      deactivatedUser,
      userRole,
      id,
    },
    errors,
    canSubmit,
  } = formData;

  const handleSubmit = async () => {
    const requestData = merge(
      { id },
      first_name !== INITIAL_DATA.first_name ? { first_name } : {},
      last_name !== INITIAL_DATA.last_name ? { last_name } : {},
      email !== INITIAL_DATA.email ? { email } : {},
      ((!isEmpty(campaigns) && !isEmpty(INITIAL_DATA.campaigns)) && (campaigns.length !== INITIAL_DATA.campaigns.length))
        ? { campaigns }
        : {},
      addToAllCampaigns !== INITIAL_DATA.allActiveCampaigns
        ? {
          campaigns: addToAllCampaigns ? allActiveCampaigns.map(({ id }) => id)
            : campaigns,
        } : {},
      deactivatedUser !== INITIAL_DATA.deactivatedUser ? { is_active: !deactivatedUser } : {},
    );

    onSave(requestData);
  };

  useEffect(() => {
    (async () => {
      try {
        const { results, count } = await getAllActiveCampaigns({ company });

        if (results.length !== count) {
          await getAllActiveCampaigns({ company, limit: count });
        }

        if (count === campaigns.length) {
          handleChange({ target: { name: 'addToAllCampaigns', value: true } });
          setSelectedData(prevState => ({
            ...prevState,
            addToAllCampaigns: true,
          }));
        }
      } catch (error) {
        notification.error(getError(error));
      }
    })();

    if (!formData.data.hasOwnProperty(OBJECTS_PROPERTIES.addToAllCampaigns)) {
      setFormData(prev => ({
        ...prev,
        data: {
          ...prev.data,
          [OBJECTS_PROPERTIES.addToAllCampaigns]: false,
        },
      }));
      setSelectedData(prev => ({
        ...prev,
        [OBJECTS_PROPERTIES.addToAllCampaigns]: false,
      }));
    }
  }, []);

  const handleDelete = useCallback(debounce(async () => {
    try {
      setLoading(true);
      await onDelete();
      setShowDeleteModal(false);
    } catch (error) {
      notification.error(getError(error));
      setLoading(false);
    }
  }, 1000), []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (isEmpty(campaigns) || (campaigns.length !== allActiveCampaigns.length)) return;

    setFormData(prev => ({
      ...prev,
      data: {
        ...prev.data,
        addToAllCampaigns: true,
      },
    }));
  }, [campaigns]);

  if (showDeleteModal) {
    return (
      <ConfirmBlock
        title="Are you sure you want to delete this user?"
        onSubmit={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        submitText="Delete"
        cancelText="Back to edit"
        isLoading={loading}
      />
    );
  }

  const handleDisabled = () => (userRole === roles.host
    ? !(canSubmit)
      || isEqual(selectedData, formData.data)
      || (addToAllCampaigns
        ? !addToAllCampaigns && isEqual(setSelectedData.campaigns, campaigns)
        : addToAllCampaigns && (campaigns.length === allActiveCampaigns.length))
    : !canSubmit);

  return (
    <Styled.Wrapper>
      <Styled.Data>
        <Styled.Info>
          <Input
            name="first_name"
            title="First name"
            placeholder="First name"
            value={first_name}
            error={errors.first_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            name="last_name"
            title="Last name"
            placeholder="Last name"
            value={last_name}
            error={errors.last_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            type="email"
            name="email"
            title="Email"
            placeholder="Email"
            value={email}
            error={errors.email}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          {
            userRole !== roles.company_admin && (
              <>
                <Styled.SmallTitle>Campaigns</Styled.SmallTitle>
                <Select
                  name="campaigns"
                  placeholder="Campaigns"
                  value={campaigns}
                  error={errors.campaigns}
                  onChange={handleChange}
                  onBlur={validateOnBlur}
                  options={transformDataToOptions({ data: allActiveCampaigns, label: 'name', value: 'id' })}
                  isMulti
                  isDisabled={addToAllCampaigns}
                />
                <Checkbox
                  name="addToAllCampaigns"
                  value={addToAllCampaigns}
                  onChange={handleChange}
                  onBlur={validateOnBlur}
                  label="Add to all existing campaigns"
                />
              </>
            )
          }
        </Styled.Info>
        { id !== loggedID && (
          <Styled.Deactivation>
            <Styled.DeactivationText>Deactivate user</Styled.DeactivationText>
            <Switcher
              name="deactivatedUser"
              checked={deactivatedUser}
              onChange={value => handleChange({ target: { name: 'deactivatedUser', value } })}
            />
          </Styled.Deactivation>
        )
        }
      </Styled.Data>
      <Styled.Actions>
        <Button
          disabled={handleDisabled()}
          onClick={handleSubmit}
        >
          Save changes
        </Button>
        {
          !isCurrentUser && (
            <Styled.DeleteUser>
              <Button onClick={() => setShowDeleteModal(true)}>
                Delete user
              </Button>
            </Styled.DeleteUser>
          )
        }
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* EditUserForm type of props */
const {
  func, bool, number, arrayOf, shape, string,
} = PropTypes;

EditUserForm.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  onDelete: func,
  onSave: func.isRequired,
  roles: PropTypes.objectOf(Object).isRequired,
  getAllActiveCampaigns: func.isRequired,
  allActiveCampaigns: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    }),
  ),
  company: number.isRequired,
  isCurrentUser: bool.isRequired,
  showDeleteModal: bool.isRequired,
  setShowDeleteModal: func.isRequired,
};

/* EditUserForm default props */
EditUserForm.defaultProps = {
  onDelete: null,
  allActiveCampaigns: [],
};

export default EditUserForm;
