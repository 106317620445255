/* Libs */
import styled, { css } from 'styled-components';

import { Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import { COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  ${({ isDisabled }) => isDisabled && css`
    * {
      ${SelectWrapper} {
        .ReactSelect__control {
          border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1} !important;
          color: ${COLORS_CONFIG.SILVER_CUSTOM_1} !important;
        }
      }
      color: ${COLORS_CONFIG.SILVER_CUSTOM_1} !important;
      border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1} !important;
    }
  `}
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 11;
  padding-right: 24px;
  & > *:not(:first-child) {
    margin-top: 24px;
  }
`;
export const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 6;
  & > *:not(:first-child) {
    margin-top: 24px;
  }
`;
