/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import { FONTS_CONFIG, COLORS_CONFIG, MEDIA_QUERIES } from 'config';

// Component styles
export const Button = styled.button`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 193px;
  padding: 15px 45px;
  border: none;
  border-radius: 24px;
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  line-height: 16px;
  text-align: center;
  color: ${COLORS_CONFIG.WHITE};
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  overflow: hidden;
  white-space: nowrap;

  @media only screen and (max-width: ${MEDIA_QUERIES.xs}) {
    min-width: 160px;
    padding: 0 16px;
  }

  @media only screen and (max-width: ${MEDIA_QUERIES.xxs}) {
    padding: 0 10px;
  }

  &:hover {
    background-color: ${COLORS_CONFIG.SKY_BLUE$50};
    color: ${COLORS_CONFIG.WHITE$72};
  }
  ${({ disabled }) => disabled
    && css`
      opacity: 0.5;
      background-color: ${COLORS_CONFIG.SKY_BLUE$50};
      cursor: not-allowed;
      pointer-events: none;
    `}
  ${({ inverted }) => inverted
    && css`
      background: ${COLORS_CONFIG.TRANSPARENT};
      border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
      color: ${COLORS_CONFIG.SKY_BLUE};
    `}
  ${({ cancel }) => cancel
    && css`
      background: none;
      color: ${COLORS_CONFIG.SMOOTH_BROWN};
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
      &:hover {
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        background: ${COLORS_CONFIG.SILVER_LIGHT};
      }
    `}
`;

export const ButtonLink = styled(Button.withComponent('div'))`
  text-decoration: none;
`;
