/* Libs */
import styled from 'styled-components';

/* Components */
import { Wrapper as LogoWrapper } from 'components/blocks/ui/Logo/styles';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';

/* Constants */
import { COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 80px 45px;
  background: ${COLORS_CONFIG.WHITE};
  ${LogoWrapper} {
    margin-bottom: 45px;
    width: 110px;
    align-self: center;
  }
  ${BigTitle} {
    margin-bottom: 25px;
    text-align: center;
  }
`;

export const Info = styled.div`
  margin-bottom: 45px;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const InfoBlock = styled.div`
  text-align: left;
  margin-bottom: 8px;
  word-break: break-word;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Options = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
`;

export const OptionText = styled.div`
  margin-bottom: 6px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  text-align: center;
`;
