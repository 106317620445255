/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty,
} from 'lodash';

/* Components */
import {
  CampaignList,
  AddCircle,
} from 'components';

/* Utils */
import { hasPermission } from 'utils/custom';

/* Constants */
import { PERMISSION_NAMES } from '_constants';

/* Styles */
import * as Styled from './styles';


function CampaignsSidebar({
  toggleBar,
  createCampaignHandler,
  ...restProps
}) {
  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <Styled.Title>
          Campaigns
        </Styled.Title>
        {
          hasPermission(PERMISSION_NAMES.CREATE_CAMPAIGN) && !isEmpty(restProps.campaigns) && (
            <AddCircle onClick={createCampaignHandler} title="Create campaign" />
          )
        }
      </Styled.TitleContainer>
      {toggleBar}
      <CampaignList {...restProps} />
    </Styled.Wrapper>
  );
}

/* CampaignsSidebar type of props */

CampaignsSidebar.propTypes = {
  toggleBar: PropTypes.node.isRequired,
  createCampaignHandler: PropTypes.func.isRequired,
};

/* CalendarSidebar default props */

CampaignsSidebar.defaultProps = {};

export default CampaignsSidebar;
