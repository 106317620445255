/* Libs */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import { Select } from 'components';

/* Constants */
import { TIMEZONE_ARRAY } from '_constants';

/* Styles */

import * as Styled from './styles';


function TimezoneSelect({
  onChange,
  value,
  selectProps,
}) {
  const timezones = useMemo(() => TIMEZONE_ARRAY.map(({ timezoneName, timezoneTitle }) => (
    {
      label: timezoneTitle,
      value: timezoneName,
    })), [TIMEZONE_ARRAY]);

  return (
    <Styled.Wrapper>
      <Select
        value={value}
        options={timezones}
        onChange={onChange}
        {...selectProps}
      />
    </Styled.Wrapper>
  );
}

/* TimezoneSelect type of props */

TimezoneSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    searchable: PropTypes.bool,
  }),
};

/* TimezoneSelect default props */

TimezoneSelect.defaultProps = {
  value: null,
  selectProps: {
    placeholder: 'Timezone',
    name: 'timezone',
    searchable: true,
  },
};

export default memo(TimezoneSelect);
