/* Libs */
import React, {
  useState, useEffect, useMemo, useCallback, memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryBrushContainer,
  createContainer,
  VictoryTooltip,
} from 'victory';
import moment from 'moment';

import { min, max } from 'lodash';

/* Hooks */

import { resizeChart } from 'hooks';

/* Constants */

import { FONTS_CONFIG, COLORS_CONFIG, DATE_SETTINGS } from 'config';

/* Styles */

import * as Styled from './styles';

const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');

const getZoomDomain = data => (data && data.length ? { x: [0, data.length - 1] } : { x: [0, 0] });

function ActivityRangeChart({ data }) {
  const width = resizeChart();

  const [zoomDomain, setZoomDomain] = useState(getZoomDomain(data));

  const entireDomain = useMemo(() => {
    const dataYs = data.map(({ y }) => y);
    return {
      x: [0, data.length - 1 || 1],
      y: [min(dataYs), max(dataYs) + 1],
    };
  }, [data]);

  const handleZoom = useCallback((domain) => {
    setZoomDomain(domain);
  }, []);

  useEffect(() => {
    setZoomDomain(getZoomDomain(data));
  }, [data]);

  const dataToShow = useMemo(
    () => data.reduce((res, item, idx) => ((idx >= zoomDomain.x[0] - 1) && (idx <= zoomDomain.x[1] + 1)
      ? res.concat({
        x: idx,
        y: item.y,
      })
      : res), []),
    [zoomDomain],
  );

  const dataForBrush = useMemo(
    () => data.map((item, idx) => ({ x: idx, y: item.y })),
    [data],
  );

  const victoryLineLabels = ({ datum }) => (
    `${datum.y} sessions. ${moment((data[datum.x] && data[datum.x].x) || []).format(
      DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR_DOT,
    )}`
  );

  const tickValues = dataToShow.map(({ x }) => x);

  const tickFormat = t => (
    !!data[t] && moment(data[t].x).format(DATE_SETTINGS.FORMAT.MONTH_DAY_SHORT)
  ) || null;

  if (!data.length) return null;

  return (
    <Styled.Wrapper>
      <VictoryChart
        padding={{
          top: 40,
          bottom: 30,
          left: 40,
          right: 40,
        }}
        height={300}
        width={width}
        domain={entireDomain}
        containerComponent={(
          <VictoryZoomVoronoiContainer
            zoomDimension="x"
            zoomDomain={zoomDomain}
            onZoomDomainChange={handleZoom}
            style={{ height: 300 }}
          />
        )}
      >
        <VictoryLine
          interpolation="basis"
          labels={victoryLineLabels}
          labelComponent={(
            <VictoryTooltip
              cornerRadius={0}
              pointerLength={0}
              dy={-10}
              style={{
                fill: COLORS_CONFIG.WHITE,
              }}
              flyoutStyle={{
                fill: COLORS_CONFIG.SKY_BLUE,
                strokeWidth: 0,
              }}
            />
          )}
          style={{
            data: {
              stroke: COLORS_CONFIG.SKY_BLUE,
              strokeWidth: 1,
            },
          }}
          data={dataToShow}
        />
        <VictoryAxis
          tickValues={tickValues}
          tickFormat={tickFormat}
          tickCount={15}
          style={{
            axis: {
              stroke: COLORS_CONFIG.SILVER_CUSTOM_2,
              strokeWidth: 1,
            },
            tickLabels: {
              fontSize: 12,
              fontFamily: FONTS_CONFIG.MAIN_TEXT_FONT_BOLD,
              fill: COLORS_CONFIG.SMOOTH_BROWN,
            },
          }}
        />
      </VictoryChart>
      <Styled.Brush>
        <VictoryChart
          padding={{
            top: 0,
            left: 5,
            right: 5,
            bottom: 0,
          }}
          width={width}
          height={60}
          domain={entireDomain}
          containerComponent={(
            <VictoryBrushContainer
              padding={{
                top: 0,
                left: 5,
                right: 5,
                bottom: 0,
              }}
              width={width}
              height={60}
              brushDimension="x"
              domain={entireDomain}
              brushDomain={zoomDomain}
              onBrushDomainChange={handleZoom}
              style={{
                backgroundColor: COLORS_CONFIG.SILVER_CUSTOM_2,
                marginTop: '40px',
              }}
              handleStyle={{
                fill: COLORS_CONFIG.SKY_BLUE,
                strokeWidth: 1,
              }}
              brushStyle={{
                fillOpacity: 0,
              }}
            />
          )}
        >
          <VictoryAxis
            orientation="bottom"
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: {
                padding: 0,
                width: 0,
              },
            }}
          />
          <VictoryLine
            interpolation="catmullRom"
            style={{
              data: {
                stroke: COLORS_CONFIG.SKY_BLUE,
                strokeWidth: 1,
              },
            }}
            data={dataForBrush}
          />
        </VictoryChart>
      </Styled.Brush>
    </Styled.Wrapper>
  );
}

/* ActivityRangeChart type of props */

ActivityRangeChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(moment),
      ]),
      y: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
};

/* ActivityRangeChart default props */

ActivityRangeChart.defaultProps = {
  data: [],
};

export default memo(ActivityRangeChart);
