export default {
  landing: '/',
  logout: '/logout',
  public: {
    leaderboard: '/leaderboard',
  },
  auth: {
    default: '/auth',
    signin: '/auth/signin',
    signup: '/auth/signup',
    reset: '/auth/reset',
    newPassword: '/auth/new-password',
  },
  participant: {
    signup: '/accept-invite',
  },
  game: {
    launch: '/game/launch/:id',
    game: '/game/:token',
    default: '/game',
  },
  admin: {
    default: '/admin',
    companies: '/admin/companies',
    reports: '/admin/reports',
    templates: '/admin/templates',
    viewTemplate: '/admin/templates/:id',
    addTemplate: '/admin/add-template',
    settings: '/admin/settings',
    viewCompany: '/admin/companies/:id',
    storylines: '/admin/storylines',
    addGameDef: '/admin/add-game-definition',
    editGameDef: '/admin/edit-game-definition/:id',
  },
  companyAdmin: {
    default: '/companyAdmin',
    impersonate: '/companyAdmin/impersonate/:id',
    company: '/companyAdmin/company',
    calendar: '/companyAdmin/calendar',
    manageGame: '/companyAdmin/campaigns/manage-game/:id',
    campaigns: '/companyAdmin/campaigns',
    users: '/companyAdmin/users',
    reports: {
      default: '/companyAdmin/reports',
      main: '/companyAdmin/reports/main',
      companyParticipants: '/companyAdmin/reports/company/participants',
      companyParticipantView:
        '/companyAdmin/reports/company/participants/:participantId',
      campaign: '/companyAdmin/reports/campaign/:id',
      campaignParticipants: '/companyAdmin/reports/campaign/:id/participants',
      campaignParticipantView:
        '/companyAdmin/reports/campaign/:campaignId/participants/:participantId',
    },
  },
  host: {
    default: '/host',
    calendar: '/host/calendar',
    campaigns: '/host/campaigns',
    manageGame: '/host/campaigns/manage-game/:id',
  },
  support: 'https://support.livingsecurity.com/hc/en-us/sections/360006354634-Experience-Manager',
};
