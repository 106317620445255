/* Libs */
import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;
