/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { goBack } from 'utils/custom';

/* Constants */

import { URLS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function ManageGame({
  children,
  teamName,
  status,
  timer,
  actions,
}) {
  return (
    <Styled.Wrapper>
      <Styled.GoBack onClick={() => goBack({ url: URLS_CONFIG.companyAdmin.campaigns })}>
        Back
      </Styled.GoBack>
      <Styled.TopPart>
        <Styled.Information>
          <Styled.TeamName>
            {teamName}
          </Styled.TeamName>
          <Styled.Status>
            Status:
            {' '}
            <Styled.StatusName>{status}</Styled.StatusName>
          </Styled.Status>
        </Styled.Information>
        <Styled.Actions>
          <Styled.ActionsList>
            {actions}
          </Styled.ActionsList>
          <Styled.Timer>
            {timer}
          </Styled.Timer>
        </Styled.Actions>
      </Styled.TopPart>
      <Styled.Content>
        <Styled.LeftSide>
          {children[0]}
        </Styled.LeftSide>
        <Styled.RightSide>
          {children[1]}
        </Styled.RightSide>
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* ManageGame type of props */

ManageGame.propTypes = {
  teamName: PropTypes.string,
  children(props, propName, componentName) {
    if (!Array.isArray(props[propName])) {
      return new Error(`You should provide array of children to ${componentName}`);
    }
    if (props[propName].length > 2) {
      return new Error(`${componentName} can only render 2 children, but received ${props[propName].length}`);
    }
    if (props[propName].length != 2) {
      return new Error(`You shoud provide 2 children to ${componentName}`);
    }
  },
};

ManageGame.defaultProps = {
  teamName: 'No team name',
};

export default ManageGame;
