/* Libs */
import styled from 'styled-components';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

/* Styles */

import { Triangle } from 'components/blocks/Markers/ForwardTriangle/styles';
import {
  Wrapper as CardWrapper,
} from 'components/blocks/Card/styles';
import {
  Title as CardHeaderTitle,
} from 'components/blocks/ui/CardsHeader/styles';
import { LinkTo } from 'components/ui/LinkTo/styles';


export const Wrapper = styled.div`
  height: 100%;
  min-width: 230px;

  & ${CardWrapper} {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: relative;
    padding: 20px;

    & ${CardHeaderTitle} {
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
      font-size: 27px;
      line-height: 32px;
    }
  }

  & ${LinkTo} {
    & a {
      display: flex;
      align-items: baseline;
      cursor: pointer;
      & span {
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 12px;
        line-height: 14px;
      }

      & ${Triangle} {
        margin-left: 5px;
      }
    }
  }
`;

export const Value = styled.h2`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 27px;
  line-height: 32px;
`;

export const Label = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 12px;
  line-height: 12px;
  margin: 10px 0;
`;

export const Badge = styled.div`
  position: absolute;
  top: 16px;
  right: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 16px;
  line-height: 14px;
`;

export const Link = styled.div`
  display: flex;
  align-items: baseline;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 12px;
  line-height: 14px;
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;

  & ${Triangle} {
    margin-left: 5px;
  }
`;
