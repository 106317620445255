import { checkEveryObjectValue } from 'utils/validation';

export default function (errors, additionalFields) {
  const basicInformationErrors = {
    name: errors.name,
    final_code: errors.final_code,
    time_limit: errors.time_limit,
  };

  const introductionDataErrors = {
    introduction_title: errors.introduction_title,
    introduction_text: errors.introduction_text,
    introduction_vimeo_id: errors.introduction_vimeo_id,
  };

  const instructionDataErrors = {
    instruction_title: errors.instruction_title,
    instruction_text: errors.instruction_text,
    instruction_vimeo_id: errors.instruction_vimeo_id,
  };

  const storylineDataErrors = {
    storyline_title: errors.storyline_title,
    storyline_text: errors.storyline_text,
    storyline_vimeo_id: errors.storyline_vimeo_id,
  };

  const debriefingDataErrors = {
    debriefing_title: errors.debriefing_title,
    debriefing_text: errors.debriefing_text,
    debriefing_vimeo_id: errors.debriefing_vimeo_id,
  };

  const resetDataErrors = {
    reset_title: errors.reset_title,
    reset_text: errors.reset_text,
    reset_vimeo_id: errors.reset_vimeo_id,
  };

  return [
    checkEveryObjectValue(basicInformationErrors, (item => item === false)),
    checkEveryObjectValue(introductionDataErrors),
    checkEveryObjectValue(instructionDataErrors),
    checkEveryObjectValue(storylineDataErrors),
    checkEveryObjectValue(debriefingDataErrors),
    checkEveryObjectValue(resetDataErrors),
    ...additionalFields,
  ].every(item => !!item);
}
