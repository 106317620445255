/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */
import { Button } from 'components';

/* Constants */
import {
  DATE_SETTINGS, GAME_CONFIG,
} from 'config';

/* Styles */
import { getSessionStatus, getStaticData, getModalDateTitle } from 'utils/custom';
import * as Styled from './styles';

function ParticipantSessionInfoSection({
  session: {
    team_name: teamName,
    location,
    signedup_participants_count: signedUpParticipantsCount,
    actual_participants_count: actualParticipantsCount,
    participants_per_session: participantsPerSession,
    time_completed: timeCompleted,
    actual_start_time: actualStartTime,
    utc_start_time: startTime,
    utc_end_time: endTime,
    game_session_status: gameSessionStatus,
    status,
  },
  onClose,
}) {
  const data = [
    {
      id: 1,
      title: 'Team',
      text: teamName,
    },
    {
      id: 2,
      title: 'Time completed',
      text: (() => {
        const statuses = getStaticData('gamePlayStatuses');
        return [statuses.instructions, statuses.game_play, statuses.game_failed].includes(status)
          || timeCompleted === null
          ? GAME_CONFIG.NOT_FINISHED
          : moment(timeCompleted, DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED)
            .format(DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED);
      })(),
    },
    {
      id: 3,
      title: 'Start time',
      text: moment(startTime).utc().format('LT'),
    },
    {
      id: 4,
      title: 'Actual start time',
      text: moment(actualStartTime).utc().format('LT'),
    },
    {
      id: 5,
      title: 'Participants signed up',
      text: signedUpParticipantsCount,
    },
    {
      id: 6,
      title: 'Actual participants',
      text: actualParticipantsCount,
    },
    {
      id: 7,
      title: 'Maximum participants',
      text: participantsPerSession,
    },
    {
      id: 8,
      title: 'Status',
      text: getSessionStatus(gameSessionStatus).charAt(0).toUpperCase() + getSessionStatus(gameSessionStatus).slice(1),
    },
  ];


  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>
          {getModalDateTitle(startTime, endTime)}
        </Styled.Title>
        <Styled.Location>
          {`Location: ${location}`}
        </Styled.Location>
      </Styled.Header>
      <Styled.Content>
        {
          data.map(({ id, title, text }) => (
            <Styled.Item key={id}>
              <Styled.ItemTitle>{title}</Styled.ItemTitle>
              <Styled.ItemText>{text}</Styled.ItemText>
            </Styled.Item>
          ))
        }
      </Styled.Content>
      <Button onClick={onClose}>
        Close
      </Button>
    </Styled.Wrapper>
  );
}

/* ParticipantSessionInfoSection type of props */

ParticipantSessionInfoSection.propTypes = {
  session: PropTypes.shape({
    team_name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    signedup_participants_count: PropTypes.number.isRequired,
    actual_participants_count: PropTypes.number.isRequired,
    time_completed: PropTypes.string.isRequired,
    actual_start_time: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ParticipantSessionInfoSection.defaultProps = {
  location: 'No location',
};

export default ParticipantSessionInfoSection;
