export const CHECK_TOKEN = '@PROJECT_NAME/CHECK_TOKEN_PARTICIPANT';
export const CHECK_TOKEN_SUCCESS = '@PROJECT_NAME/CHECK_TOKEN_PARTICIPANT_SUCCESS';
export const CHECK_TOKEN_FAIL = '@PROJECT_NAME/CHECK_TOKEN_PARTICIPANT_FAIL';

export const SIGNUP = '@PROJECT_NAME/SIGNUP_PARTICIPANT';
export const SIGNUP_SUCCESS = '@PROJECT_NAME/SIGNUP_PARTICIPANT_SUCCESS';
export const SIGNUP_FAIL = '@PROJECT_NAME/SIGNUP_PARTICIPANT_FAIL';

export const DOWNLOAD_CALENDAR = '@PROJECT_NAME/DOWNLOAD_CALENDAR_PARTICIPANT';
export const DOWNLOAD_CALENDAR_SUCCESS = '@PROJECT_NAME/DOWNLOAD_CALENDAR_PARTICIPANT_SUCCESS';
export const DOWNLOAD_CALENDAR_FAIL = '@PROJECT_NAME/DOWNLOAD_CALENDAR_PARTICIPANT_FAIL';

export const FINISH_SIGNUP = '@PROJECT_NAME/FINISH_SIGNUP_PARTICIPANT_FAIL';
