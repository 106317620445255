/* Libs */
import styled from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const SmallTitle = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  text-align: start;
  width: 100%;
`;
