import { getTimezoneName, getTimeInUTCFormat } from 'utils/custom';

const getLocalDateFromUTCString = (date, timezone) => {
  const UTCStringDate = date.substr(-1) === 'Z' ? date : new Date(`${date}Z`).toISOString();
  const timezoneName = getTimezoneName(timezone);

  return getTimeInUTCFormat(UTCStringDate, timezoneName);
};

export default getLocalDateFromUTCString;
