import {
  tokenService,
  staticDataService,
} from 'utils/services';
import { setRoles } from 'utils/custom/getPermissions';
import { setUserRole } from 'utils/custom/hasPermission';

export default async () => {
  tokenService.startApp().then(({ role }) => {
    setUserRole(role);
  });
  staticDataService.getData().then(({ user_roles: userRoles }) => {
    setRoles(userRoles);
  });
};
