/* Libs */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Actions */

import { AuthEntity } from '_entities';

/* Utils */
import { redirect, getError } from 'utils/custom';
import { validateEmail } from 'utils/validation/fields';
import { useToggleBooleanHook } from 'hooks';
import { notification } from 'utils/services';
import { VALIDATION_VALUES, NOTIFICATIONS } from '_constants';
import { URLS_CONFIG } from 'config';

/* Components */

import {
  Input,
  Button,
  SuccessResetEmailModal,
} from 'components';

/* Styles */

import * as Styled from './styles';

function ResetPassword({
  resetPassword,
}) {
  const [formData, setData] = useState({
    email: '',
    error: null,
    loading: false,
  });
  const [isOpen, toggleOpen] = useToggleBooleanHook(false);
  const handleChange = ({ target: { value } }) => {
    setData({
      email: value,
      error: validateEmail({
        value,
        name: 'email',
        max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
      }),
    });
  };

  const validateOnBlur = ({ target: { value } }) => {
    setData(prev => ({
      ...prev,
      error: validateEmail({
        value,
        name: 'email',
        max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
      }),
    }));
  };

  const submit = async () => {
    setData({ ...formData, loading: true });
    try {
      await resetPassword(formData.email);
      setData({ loading: false });
      toggleOpen();
    } catch (error) {
      const errorText = getError(error).trim();
      setData({
        ...formData,
        loading: false,
        ...(NOTIFICATIONS.ERROR_NOT_FOUND === errorText && { error: errorText }),
      });

      if (NOTIFICATIONS.ERROR_NOT_FOUND !== errorText) {
        notification.error(getError(error));
      }
    }
  };
  function toLogin() {
    redirect(URLS_CONFIG.auth.signin);
  }
  const { email, error, loading } = formData;
  return (
    <Styled.Wrapper>
      <Input
        name="email"
        type="email"
        value={email}
        onChange={handleChange}
        onBlur={validateOnBlur}
        placeholder="Email"
        error={error}
      />
      <Button
        isLoading={loading}
        disabled={loading || error !== false}
        onClick={submit}
      >
        Submit
      </Button>
      <Styled.SignIn>
        Remembered your password?
        <span onClick={() => redirect(URLS_CONFIG.auth.signin)}> Login </span>
      </Styled.SignIn>
      <SuccessResetEmailModal
        open={isOpen}
        onClose={toLogin}
      />
    </Styled.Wrapper>
  );
}

/* ResetPassword type of props */

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  resetPassword: AuthEntity.actions.resetPassword,
})(ResetPassword);
