/* Libs */
import React from 'react';

/* Components */
import { ConfirmSessions } from './Steps';
import SetupSessions from './Steps/SetupSessions/component';

/* Styles */
import * as Styled from './styles';

function MultipleSessionForm({ currentStep, ...rest }) {
  const manageStep = () => {
    switch (currentStep) {
      case 1:
        return (<ConfirmSessions {...rest} />);
      default:
        return (<SetupSessions {...rest} />);
    }
  };

  return (
    <Styled.Wrapper>
      {manageStep()}
    </Styled.Wrapper>
  );
}

export default MultipleSessionForm;
