/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function KitCell({
  options,
  value,
  onChange,
}) {
  return (
    <Styled.Cell>
      {
        options.map(option => (
          <Styled.Option
            active={option === value}
            onClick={() => onChange(option)}
            key={`kit-cell-${option}`}
          >
            {option}
          </Styled.Option>
        ))
      }
    </Styled.Cell>
  );
}

/* KitCell type of props */

KitCell.propTypes = {
  value: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

/* KitCell default props */

KitCell.defaultProps = {
  value: '',
};

export default KitCell;
