/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { GameStatisticItem } from 'components';

/* Styles */

import * as Styled from './styles';

function GameStatisticList({
  unlockedClues,
  incorrectAnswers,
  initialTime,
  addedTime,
  finalTime,
  isDarkMode
}) {
  return (
    <Styled.List isDarkMode={isDarkMode}>
      <GameStatisticItem name="Unlocked Clues:" value={unlockedClues} />
      <GameStatisticItem name="Incorrect Answers:" value={incorrectAnswers} />
      <GameStatisticItem name="Initial Time:" value={initialTime} />
      <GameStatisticItem name="Penalty Time:" value={addedTime} />
      <GameStatisticItem
        name="Final Time:"
        value={finalTime}
        description={addedTime === '00:00:00' ? null : `${initialTime} + ${addedTime}`}
        bold
      />
    </Styled.List>
  );
}

/* GameStatisticList type of props */

GameStatisticList.propTypes = {
  unlockedClues: PropTypes.number.isRequired,
  incorrectAnswers: PropTypes.number.isRequired,
  initialTime: PropTypes.string.isRequired,
  addedTime: PropTypes.string.isRequired,
  finalTime: PropTypes.string.isRequired,
};

export default GameStatisticList;
