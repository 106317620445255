import { KITS_TABLE } from 'config';
import { store } from 'lib';
import { omit } from 'lodash';
import get from 'lodash.get';
import * as TYPES from './Kits.types';

export const addCompanyKit = (data) => {
  const storyline = get(
    store.getState(),
    'LS_adminStorylines.storylines',
    [],
  ).find(storyLine => storyLine.id === data.storyline); // TODO: remove this when BE will send storyline with name in response
  return {
    types: [TYPES.ADD_COMPANY_KIT, TYPES.ADD_COMPANY_KIT_SUCCESS, TYPES.ADD_COMPANY_KIT_FAIL],
    promise: client => client.post('/kits/', data),
    payload: { storyline },
  };
};

export const editCompanyKit = (data) => {
  const storyline = get(
    store.getState(),
    'LS_adminStorylines.storylines',
    [],
  ).find(storyLine => storyLine.id === data.storyline); // TODO: remove this when BE will send storyline with name in response

  return {
    types: [TYPES.EDIT_COMPANY_KIT, TYPES.EDIT_COMPANY_KIT_SUCCESS, TYPES.EDIT_COMPANY_KIT_FAIL],
    promise: client => client.put(`/kits/${data.id}/`, omit(data, ['storyline', 'id'])),
    payload: { storyline, oldId: data.id },
  };
};

export const getCompanyKits = ({
  id,
  page = 1,
  perPage = KITS_TABLE.PAGINATION.PER_PAGE,
}) => ({
  types: [TYPES.GET_COMPANY_KITS, TYPES.GET_COMPANY_KITS_SUCCESS, TYPES.GET_COMPANY_KITS_FAIL],
  promise: client => client.get(`/kits/?company=${id}&offset=${(page - 1) * perPage}&limit=${perPage}`),
});
