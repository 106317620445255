/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Wrapper as DeleteAnswerWrapper } from 'components/ui/DeleteAnswer/styles';
import { Wrapper as InputWrapper } from 'components/ui/Input/styles';

export const Wrapper = styled.div`
  padding: 8px 32px 56px 32px;
  & > * {
    margin-top: 24px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > *:first-child {
    width: 48px;
  }
  & ${InputWrapper} {
    flex: 1;
  }
  & ${DeleteAnswerWrapper} {
    padding-left: 10px;
  }
`;

export const Label = styled.div`
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  padding-left: 8px;
`;

export const Header = styled.div`
`;

export const AnswersSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;

export const AddAnswer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;
  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`;

export const Title = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 18px;
`;

export const Tip = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 14px;
`;
