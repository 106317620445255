/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { DATE_SETTINGS } from 'config';
import {
  getLocalDateFromUTCString,
  getTimezoneTitle,
  truncateText,
} from 'utils/custom';

/* Styles */
import * as Styled from './styles';


function LaunchHeader({
  title,
  subtitle,
  location: { location },
  utcStartTime,
  isAnonymous,
  companyTimezone,
}) {
  const startDate = utcStartTime && getLocalDateFromUTCString(utcStartTime, companyTimezone).format(DATE_SETTINGS.FORMAT.DAY_MONTH_DATE_AT_TIME);
  const timezone = getTimezoneTitle(companyTimezone);

  return (
    <Styled.Wrapper isAnonymous={isAnonymous}>
      <Styled.Title>
        {title}
      </Styled.Title>
      {
        subtitle && (
          <Styled.SubTitle>
            <Styled.Location>
              <b>Location:&nbsp;</b>
              {truncateText(location, 40)}
            </Styled.Location>
            <b>Date and time:</b>
            {` ${startDate} ${timezone}`}
          </Styled.SubTitle>
        )
      }
      <Styled.Line />
    </Styled.Wrapper>
  );
}

/* LaunchHeader type of props */

LaunchHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isAnonymous: PropTypes.bool,
};

LaunchHeader.defaultProps = {
  isAnonymous: false,
  subtitle: null,
};


const mapStateToProps = state => ({
  companyTimezone: get(state, 'adminCompany.company.timezone'),
});

export default connect(mapStateToProps, null)(LaunchHeader);
