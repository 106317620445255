/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function ActionsCell({ children }) {
  return <Styled.Cell>{children}</Styled.Cell>;
}

/* ActionsCell type of props */

ActionsCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActionsCell;
