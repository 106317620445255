export const ADD_KIT = 'ADD_KIT';
export const VIEW_KIT = 'VIEW_KIT';
export const EDIT_SESSION = 'EDIT_SESSION';
export const RESEND_INVITE = 'RESEND_INVITE';
export const EDIT_LICENSING = 'EDIT_LICENSING';
export const LOAD_GAME_DEFS = 'LOAD_GAME_DEFS';
export const EDIT_KIT_DATES = 'EDIT_KIT_DATES';
export const ADD_NEW_SESSION = 'ADD_NEW_SESSION';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const LOGOUT_IN_HEADER = 'LOGOUT_IN_HEADER';
export const OPEN_SUPPORT_PAGE = 'OPEN_SUPPORT_PAGE';
export const LAUNCH_NEW_SESSION = 'LAUNCH_NEW_SESSION';
export const ACTIVATE_CAMPAIGNS = 'ACTIVATE_CAMPAIGNS';
export const ADD_GAME_DEFINITION = 'ADD_GAME_DEFINITION';
export const VIEW_GAME_DEFINITION = 'VIEW_GAME_DEFINITION';
export const SET_NOTIFICATION_DAYS = 'SET_NOTIFICATION_DAYS';
export const EDIT_POINT_OF_CONTACT = 'EDIT_POINT_OF_CONTACT';
export const CHANGE_GLOBAL_SETTINGS = 'CHANGE_GLOBAL_SETTINGS';
export const EDIT_USER_LICENSE_USAGE = 'EDIT_USER_LICENSE_USAGE';
export const CHANGE_CAMPAIGN_SETTINGS = 'CHANGE_CAMPAIGN_SETTINGS';
export const BACK_TO_COMPANIES_IN_HEADER = 'BACK_TO_COMPANIES_IN_HEADER';
export const CAN_VIEW_COMPANY_ADMIN_PAGES = 'CAN_VIEW_COMPANY_ADMIN_PAGES';
export const INVITE_PARTICIPANTS_TO_CAMPAIGN = 'INVITE_PARTICIPANTS_TO_CAMPAIGN';

export default {
  ADD_KIT,
  VIEW_KIT,
  EDIT_SESSION,
  RESEND_INVITE,
  EDIT_LICENSING,
  LOAD_GAME_DEFS,
  EDIT_KIT_DATES,
  ADD_NEW_SESSION,
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  LOGOUT_IN_HEADER,
  OPEN_SUPPORT_PAGE,
  LAUNCH_NEW_SESSION,
  ACTIVATE_CAMPAIGNS,
  ADD_GAME_DEFINITION,
  VIEW_GAME_DEFINITION,
  SET_NOTIFICATION_DAYS,
  EDIT_POINT_OF_CONTACT,
  CHANGE_GLOBAL_SETTINGS,
  EDIT_USER_LICENSE_USAGE,
  CHANGE_CAMPAIGN_SETTINGS,
  BACK_TO_COMPANIES_IN_HEADER,
  CAN_VIEW_COMPANY_ADMIN_PAGES,
  INVITE_PARTICIPANTS_TO_CAMPAIGN,
};
