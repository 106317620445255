/* Libs */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce, isNaN } from 'lodash';

/* Actions */

import { LS_AdminStorylinesEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';
import { getError, replaceQueryParams } from 'utils/custom';

/* Components */

import {
  AddCircle,
  Pagination,
  SearchInput,
  StorylinesSection,
  AddStorylineModal,
  AdminStorylinesStruct,
  ContainerLoader, EditStorylineModal,
} from 'components';

/* Constants */

import { URLS_CONFIG, STORYLINES_TABLE } from 'config';

const PAGE_TITLE = 'Storylines';

const SIDEBAR_NAMES = {
  ADD: 'Add',
  EDIT: 'Edit',
};

function Storylines({
  loaded,
  total,
  storylines,
  getStorylines,
  loading,
  location: { search },
}) {
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [editData, setEditData] = useState({});
  const [page, setPage] = useState(1);
  const [searchInput, setSearch] = useState('');

  const manageActiveSideBar = name => setActiveSidebar(name);


  const openEditUserSidebar = (id) => {
    setEditData(storylines.find(storyline => storyline.id === id));
    manageActiveSideBar(SIDEBAR_NAMES.EDIT);
  };

  async function getStorylinePerPage({ currentPage = page, name = searchInput } = {}) {
    try {
      const result = await getStorylines({ page: currentPage, name });
      if (!result.results.length && result.count) {
        setPage(1);
        replaceQueryParams({ page: 1 });
        getStorylinePerPage({ currentPage: 1 });
      } else {
        replaceQueryParams({ page: currentPage });
      }
    } catch (error) {
      notification.error(getError(error));
    }
  }

  const getStorylinesRequest = useCallback(debounce(getStorylinePerPage, 300), []);

  const loadAllStorylines = async () => {
    try {
      await getStorylinePerPage({ currentPage: 1, name: '' });
      setPage(1);
      setSearch('');
    } catch (error) {
      notification.error(getError(error));
    }
  };

  useEffect(() => {
    const urlsParams = new URLSearchParams(search);
    const searchParamsPage = +urlsParams.get('page');
    const queryPage = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;
    setPage(queryPage);

    getStorylinePerPage({ currentPage: queryPage });
  }, []);

  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
    getStorylinePerPage({ currentPage: selected + 1, name: searchInput });
  };
  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
    setPage(1);
    getStorylinesRequest({ currentPage: 1, name: value });
  };

  return (
    <AdminStorylinesStruct
      title={PAGE_TITLE}
      search={(
        <SearchInput
          name="search"
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search a storyline"
        />
      )}
      actions={[<AddCircle
        key="Add a storyline"
        title="Add a storyline"
        onClick={() => manageActiveSideBar(SIDEBAR_NAMES.ADD)}
      />]}
    >
      {
        !loaded
          ? <ContainerLoader />
          : (
            <StorylinesSection
              storylines={storylines}
              loading={loading}
              openEditUserSidebar={openEditUserSidebar}
              pagination={(
                <Pagination
                  pageCount={total / STORYLINES_TABLE.PAGINATION.PER_PAGE}
                  pageChangeHandler={handlePageChange}
                  activePage={page}
                />
              )}
            />
          )
      }
      <AddStorylineModal
        open={activeSidebar === SIDEBAR_NAMES.ADD}
        onClose={() => manageActiveSideBar(null)}
        getAllStorylines={loadAllStorylines}
      />
      <EditStorylineModal
        open={activeSidebar === SIDEBAR_NAMES.EDIT}
        onClose={() => manageActiveSideBar(null)}
        data={editData}
        loadAllStorylines={loadAllStorylines}
      />
    </AdminStorylinesStruct>
  );
}

/* Page Url */

Storylines.path = URLS_CONFIG.admin.storylines;

/* Page Title */

Storylines.title = PAGE_TITLE;

/* Storylines type of props */

Storylines.propTypes = {
  loaded: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  storylines: PropTypes.array.isRequired,
  getStorylines: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default connect(({ LS_adminStorylines }) => ({
  loaded: LS_adminStorylines.loaded,
  loading: LS_adminStorylines.loading,
  total: LS_adminStorylines.total,
  storylines: LS_adminStorylines.storylines,
}), {
  getStorylines: LS_AdminStorylinesEntity.actions.getStorylines,
})(Storylines);
