/* Libs */
import styled, { css } from 'styled-components';

import { isNull } from 'utils/validation';

import { COLORS_CONFIG } from 'config';

export const Image = styled.button`
  ${({ img }) => css`
    background-image: url(${img});
    ${isNull(img) && css`
      background-color: ${COLORS_CONFIG.SILVER_LIGHT};
    `}
  `}
  border: none;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: ${({ onClick, disabled }) => (typeof onClick === 'function' && !disabled ? 'pointer' : 'default')}
  ${({ disabled, grayscaleOnDisabled }) => disabled && grayscaleOnDisabled && css`
    filter: grayscale(1);
  `}
  ${({ width, height, relative }) => css`
    height: ${height}${relative ? '%' : 'px'};
    width: ${width}${relative ? '%' : 'px'};
  `}
`;

export const Wrapper = styled.div`
  ${({ isCenter }) => isCenter && css`
    & ${Image}{
      margin: 0 auto;
    }
  `}
`;
