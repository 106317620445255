import { history } from 'lib';
import { isEmpty } from 'utils/validation';
import { notification } from 'utils/services';

/**
 * if message provided it will call new notification
 */
export default (url = '/', message = '') => {
  if (url.includes('http') || !isEmpty(url.split('/')[0])) {
    return window.location.replace(url);
  }
  if (!isEmpty(message)) {
    notification.success(message);
  }
  history.push(url);
};
