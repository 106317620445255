/* Libs */
import styled from 'styled-components';

import {
  Button,
} from 'components/ui/Button/styles';

import {
  Wrapper as InputWrapper,
} from 'components/ui/Input/styles';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const ErrorLabel = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS_CONFIG.ORANGE};
  bottom: 70px;
  left: 0px;
  position: absolute;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & ${InputWrapper}:first-child {
    margin-bottom: 30px;
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
  }
  & ${Button} {
    margin-top: 48px;
    width: 193px;
  }
`;
