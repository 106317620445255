/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

import { COLORS_CONFIG, AVAILABLE_COLORS } from 'config';

/* Styles */
import { Img } from 'components';
import * as Styled from './styles';

const ButtonWithIcon = ({
  onClick, icon, label, size, color,
}) => (
  <Styled.Wrapper onClick={onClick}>
    { icon && (
      <Img src={icon} size={size} />
    )}
    <Styled.Label color={color}>
      {label}
    </Styled.Label>
  </Styled.Wrapper>
);

ButtonWithIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.arrayOf(PropTypes.number),
  color: PropTypes.oneOf(AVAILABLE_COLORS),
};

ButtonWithIcon.defaultProps = {
  icon: '',
  label: '',
  size: [20, 20],
  color: COLORS_CONFIG.ORANGE,
};

export default ButtonWithIcon;
