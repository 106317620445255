/* Libs */
import styled from 'styled-components';
import { CustomScroll } from 'assets/styles/mixins';
/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Tooltip } from 'components/blocks/ui/Tooltip/styles';
import { Wrapper as TooltipWrapper } from 'components/ui/TextWithTooltip/styles';

export const Wrapper = styled.div`
  padding: 24px 32px 20px 32px;
  overflow: visible;
`;

export const ContentWrapper = styled.div`
  overflow: auto;
  position: relative;
  ${() => CustomScroll({ scrollBG: 'transparent' })}  
`;

export const GroupWrapper = styled.div`
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 18px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Participant = styled.div`
  margin-bottom: 10px;
  ${TooltipWrapper} {
    position: unset;
    ${Tooltip} {
      top: unset;
      width: calc(100% - 60px); 
    }
  }
`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 10px;
  margin-left: 10px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  text-align: center;
  border-radius: 50%;
`;
