
import { store } from 'lib';
import { DATE_SETTINGS } from 'config';
import * as TYPES from './ManageGame.types';

export const getGameSession = id => ({
  types: [TYPES.GET_GAME_SESSION, TYPES.GET_GAME_SESSION_SUCCESS, TYPES.GET_GAME_SESSION_FAIL],
  promise: client => client.get(`/game-sessions/result/${id}/get-game/`),
});

export const setNewGameTime = ({ currentTime, winTime }) => {
  const gameSessionId = store.getState().manageGame.gameSession.id;
  const request = {
    current_time: currentTime.format(DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED),
  };
  if (winTime) {
    request.time_completed = winTime.format(DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED);
  }
  return {
    types: [TYPES.SET_NEW_GAME_TIME, TYPES.SET_NEW_GAME_TIME_SUCCESS, TYPES.SET_NEW_GAME_TIME_FAIL],
    promise: client => client.put(`/game-sessions/result/${gameSessionId}/`, request),
    payload: { newTime: currentTime },
  };
};

export const deleteParticipant = (id) => {
  const gameSessionId = store.getState().manageGame.gameSession.id;
  return {
    types: [TYPES.DELETE_PARTICIPANT, TYPES.DELETE_PARTICIPANT_SUCCESS, TYPES.DELETE_PARTICIPANT_FAIL],
    promise: client => client.post('/game-sessions/set-actual-participants/', {
      game_session: gameSessionId,
      participants_to_delete: [id],
    }),
    payload: { deletedParticipantId: id },
  };
};

export const addParticipant = (email) => {
  const gameSessionId = store.getState().manageGame.gameSession.id;
  return {
    types: [TYPES.ADD_PARTICIPANT, TYPES.ADD_PARTICIPANT_SUCCESS, TYPES.ADD_PARTICIPANT_FAIL],
    promise: client => client.post('/game-sessions/set-actual-participants/', {
      game_session: gameSessionId,
      new_participants_emails: [email],
    }),
  };
};

export const continueGame = () => {
  const gameSessionId = store.getState().manageGame.gameSession.id;
  return {
    types: [TYPES.CONTINUE_GAME, TYPES.CONTINUE_GAME_SUCCESS, TYPES.CONTINUE_GAME_FAIL],
    promise: client => client.get(`/game-sessions/result/${gameSessionId}/continue-game/`),
  };
};

export const resetGame = () => {
  const gameSessionId = store.getState().manageGame.gameSession.id;
  return {
    types: [TYPES.RESET_GAME, TYPES.RESET_GAME_SUCCESS, TYPES.RESET_GAME_FAIL],
    promise: client => client.get(`/game-sessions/result/${gameSessionId}/reset-game/`),
  };
};

export const closeGame = () => {
  const gameSessionId = store.getState().manageGame.gameSession.id;
  const newStatus = store.getState().staticData.gameSessionStatuses.completed;
  return {
    types: [TYPES.CLOSE_GAME, TYPES.CLOSE_GAME_SUCCESS, TYPES.CLOSE_GAME_FAIL],
    promise: client => client.get(`/game-sessions/result/${gameSessionId}/close-game/`),
    payload: { newStatus },
  };
};

export const setAnonymousParticipants = ({ newCount, oldCount }) => {
  const gameSessionId = store.getState().manageGame.gameSession.id;
  return {
    types: [
      TYPES.SET_ANONYMOUS_PARTICIPANTS,
      TYPES.SET_ANONYMOUS_PARTICIPANTS_SUCCESS,
      TYPES.SET_ANONYMOUS_PARTICIPANTS_FAIL,
    ],
    promise: client => client.post('/game-sessions/set-actual-participants/', {
      game_session: gameSessionId,
      anonymous_participants_count: newCount,
      anonymous_participants_count_to_delete: oldCount,
    }),
  };
};

export const resetManageGame = () => ({
  type: TYPES.RESET_MANAGE_GAME,
});
