/* Libs */
import styled from 'styled-components';

import { Wrapper as LogoWrapper } from 'components/blocks/ui/Logo/styles';

export const Wrapper = styled.div`
  width: 400px;
  & ${LogoWrapper} {
    padding-bottom: 60px;
    & > img {
      width: 255px;
    }
  }
`;
