/* Libs */
import React, {
  useState, useEffect, memo, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { generateEmptyQuestion } from 'utils/custom';

/* Constants */

import { GAME_DEF_CONFIG } from 'config';
import { GAME_DEF_FORM } from '_constants';

/* Components */

import { BigTitle, AddGameDefinitionForms, CircleWithSign } from 'components';

/* Styles */

import { validateQuestionForm } from 'utils/validation/fields';
import { isNull } from 'utils/validation';
import * as Styled from './styles';

const QuestionSection = memo(
  ({
    data,
    mode,
    final_code,
    disabled,
    formName,
    game_def_template,
    importedData,
    globalErrors,
    formIsEmpty,
    handleChange: changeQuestions,
  }) => {
    const [activeQuestion, setActiveQuestion] = useState(
      data[0] ? data[0] : null,
    );

    const [prevTemplate, setPrevTemplate] = useState(game_def_template);
    const [invalidQuestions, setInvalidQuestions] = useState([]);
    function setValidityOfQuestion(questionNumber) {
      return (isValid) => {
        if (isNull(questionNumber)) return;
        if (isValid) {
          return setInvalidQuestions(
            invalidQuestions.filter(question => question !== questionNumber),
          );
        }
        if (invalidQuestions.includes(questionNumber)) return;
        setInvalidQuestions([...invalidQuestions, questionNumber]);
      };
    }

    useEffect(() => {
      handleChange(data);
    }, [invalidQuestions]);

    const handleChange = (questions) => {
      changeQuestions(
        { target: { name: 'questions', value: questions } },
        { formName, isValid: invalidQuestions.length < 1 },
      );
    };

    const getInvalidQuestions = useCallback(
      questions => questions.reduce((result, question) => {
        const { isValid } = validateQuestionForm({
          questionForValidate: question,
          otherQuestions: questions.filter(
            otherQuestion => otherQuestion.number !== question.number,
          ),
          final_code,
        });
        if (!isValid) {
          result.push(question.number);
        }
        return result;
      }, []),
      [final_code],
    );

    const onChange = (updatedQuestion) => {
      const questions = data.map(question => (updatedQuestion.number === question.number
        ? { ...updatedQuestion }
        : question));
      const newInvalidQuestions = getInvalidQuestions(questions);
      if (
        !invalidQuestions
        || !newInvalidQuestions.every(newQuestion => invalidQuestions.includes(newQuestion))
      ) {
        setInvalidQuestions(newInvalidQuestions);
      }
      setActiveQuestion({ ...updatedQuestion });
      handleChange(questions);
    };

    /**
     *
     *  Initial - mean that we always need to have 1 question, even if disabled
     *  disabled - need for preventing adding questions if final code only option
     *
     * @param {Boolean} initial
     */
    const addQuestion = (initial = false) => {
      if (mode === GAME_DEF_FORM.MODE.VIEW) return;
      if (disabled && initial !== true) return;
      const questionCount = data.length || 0;
      if (questionCount < GAME_DEF_CONFIG.MAX_QUESTIONS_COUNT) {
        const newQuestion = generateEmptyQuestion(questionCount + 1);
        handleChange([...data, newQuestion]);
        setActiveQuestion(newQuestion);
        setValidityOfQuestion(newQuestion.number)(false);
      }
    };

    const deleteQuestion = (number) => {
      if (mode === GAME_DEF_FORM.MODE.VIEW) return;
      const newQuestionsSet = data
        .filter(question => question.number !== number)
        .map((question, id) => ({ ...question, number: id + 1 }));
      handleChange(newQuestionsSet);
      setInvalidQuestions(
        invalidQuestions.filter(question => question !== number),
      );
      setActiveQuestion(newQuestionsSet[newQuestionsSet.length - 1]);
    };

    useEffect(() => {
      if (activeQuestion && activeQuestion.isNew) {
        setValidityOfQuestion(activeQuestion.number)(false);
      }
    }, [activeQuestion]);

    const otherQuestions = useMemo(
      () => (activeQuestion
          && data.filter(question => question.number !== activeQuestion.number))
        || [],
      [activeQuestion && activeQuestion.number],
    );

    useEffect(() => {
      if (data.length < 1) {
        return addQuestion(true);
      }
      if (!activeQuestion && data.length !== 0) {
        return setActiveQuestion(data[0]);
      }
    }, [data, game_def_template]);

    useEffect(() => {
      setPrevTemplate(game_def_template);
      setInvalidQuestions(getInvalidQuestions(data));
      if (data.length !== 0) {
        return setActiveQuestion(data[0]);
      }
    }, [game_def_template]);

    useEffect(() => {
      setInvalidQuestions(getInvalidQuestions(data));
    }, [final_code]);

    useEffect(() => {
      if (importedData) {
        setActiveQuestion(data[0]);
      }
    }, [importedData]);

    return (
      <Styled.Wrapper>
        <Styled.Header>
          <BigTitle>Questions</BigTitle>
          <Styled.AddQuestion onClick={addQuestion}>
            <CircleWithSign sign="+" />
            <Styled.Label>Add a question</Styled.Label>
          </Styled.AddQuestion>
        </Styled.Header>
        <Styled.QuestionsList>
          {data.map(question => (
            <Styled.QuestionTab
              key={question.number}
              hasError={
                activeQuestion.number === question.number
                  ? null
                  : invalidQuestions.includes(question.number)
              }
              onClick={() => setActiveQuestion(question)}
              isActive={activeQuestion.number === question.number}
            >
              {question.number}
            </Styled.QuestionTab>
          ))}
        </Styled.QuestionsList>
        <AddGameDefinitionForms.QuestionForm
          data={
            prevTemplate === game_def_template
              ? activeQuestion || generateEmptyQuestion(0)
              : generateEmptyQuestion(0)
          }
          importedData={importedData}
          questionIsValid={validity => activeQuestion
            && setValidityOfQuestion(activeQuestion.number)(validity)
          }
          game_def_template={game_def_template}
          handleChange={onChange}
          final_code={final_code}
          deleteQuestion={deleteQuestion}
          otherQuestions={otherQuestions}
          disabled={mode === GAME_DEF_FORM.MODE.VIEW}
          globalErrors={globalErrors}
          formIsEmpty={formIsEmpty}
        />
        {disabled && (
          <Styled.OnlyFinalCodeMessage>
            You can't create questions with "Final code only" option.
          </Styled.OnlyFinalCodeMessage>
        )}
      </Styled.Wrapper>
    );
  },
);

/* QuestionSection type of props */

QuestionSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      game_def: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      number: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      unlock_code: PropTypes.string.isRequired,
      intro_vimeo_id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      correct_answer_message: PropTypes.string.isRequired,
      incorrect_answer_message: PropTypes.string.isRequired,
      hint_text: PropTypes.string.isRequired,
      answers: PropTypes.array.isRequired,
    }),
  ).isRequired,
  mode: PropTypes.oneOf([
    GAME_DEF_FORM.MODE.EDIT,
    GAME_DEF_FORM.MODE.ADD,
    GAME_DEF_FORM.MODE.TEMPLATE,
  ]).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  final_code: PropTypes.string,
};

export default QuestionSection;
