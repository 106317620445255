/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { default as Triangle } from 'components/blocks/Markers/BackTriangle/styles';

export const Wrapper = styled.div`
  left: 155px;
  top:9px;
  position: absolute;
`;
export const Message = styled.div`
  font-size: 17px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  padding: 14px 13px 20px 13px;
  /* padding: 20px 15px; */
`;
export const Content = styled.div`
  position: relative;
  & ${Triangle} {
    position: absolute;
    left: -9px;
    top: 15px;
    border-width: 9px 10px 9px 0;
  }
  width: 182px;
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 3px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;
export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const Action = styled.div`
  font-size: 15px;
  padding: 13px 0;
  cursor: pointer;
  text-align: center;
  width: 50%;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  background-color: ${COLORS_CONFIG.SILVER};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  border-bottom-right-radius: 3px;
  &:hover {
      background-color: ${COLORS_CONFIG.SILVER_LIGHT};
    }
  ${({ isConfirm }) => isConfirm && css`
    background-color: ${COLORS_CONFIG.SKY_BLUE};
    color: ${COLORS_CONFIG.WHITE};
    border-bottom-right-radius: initial;
    border-bottom-left-radius: 3px;
    &:hover {
      background-color: ${COLORS_CONFIG.SKY_BLUE$50};
    }
  `}
`;
