/* Libs */
import React, {
  useMemo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Utils */

import { isValidDate } from 'utils/validation';

/* Components */

import {
  Accordion,
  IconTooltip,
  DownTriangle,
  ForwardTriangle,
  RemoveButton,
  Img,
} from 'components';

/* Constants */
import { COLORS_CONFIG } from 'config';

/* Styles */
import { WARNING_SIGN } from 'assets/icons/signs';
import * as Styled from './styles';

const weekdaysFull = moment.weekdays();

function WeekdaysAccordion({
  weekdays,
  sessions,
  toggleSession,
  excludeStartDatetimes,
  currentRangeStr,
  nodeEl,
}) {
  const [activeDay, setActiveDay] = useState(null);
  const toggleActiveDay = day => () => setActiveDay(prev => (prev === day ? null : day));
  const days = Object.keys(weekdays);
  const dayRange = sessions => (
    `${sessions[0].split(' - ')[0]} - ${sessions[sessions.length - 1].split(' - ')[1]}`
  );
  const daysOptions = Object.values(weekdays);

  const createContent = useCallback((index) => {
    if (!daysOptions.length) return null;
    const keys = daysOptions[index] ? Object.keys(daysOptions[index]) : [];
    const values = daysOptions[index] ? Object.values(daysOptions[index]) : [];

    return (
      keys.map((item, i) => (
        <Styled.Item key={item}>
          <Styled.Label />
          <Styled.Range>{item}</Styled.Range>
          <Styled.Sessions>
            {
              Array.isArray(values[i].start_time)
                ? <IconTooltip items={values[i].start_time} />
                : (
                  <RemoveButton
                    onClick={() => toggleSession(values[i].start_time)}
                    isActive={excludeStartDatetimes.includes(values[i].start_time)}
                  />
                )
            }
          </Styled.Sessions>
        </Styled.Item>
      ))
    );
  }, [excludeStartDatetimes]);

  const checkIsKitScheduled = day => Object.values(weekdays[day]).some(time => Array.isArray(time.start_time));

  const rows = useMemo(() => days.map((day, i) => (
    <Accordion
      isActive={day === activeDay}
      setActive={toggleActiveDay(day)}
      key={i}
      parentRef={nodeEl}
      header={(
        <Styled.Header active={day === activeDay}>
          <Styled.Label>{weekdaysFull[moment(day).weekday()]}</Styled.Label>
          <Styled.Range>{dayRange(Object.keys(daysOptions[i]))}</Styled.Range>
          <Styled.Sessions>
            {`${sessions} session${sessions > 1 ? 's' : ''}`}
            {checkIsKitScheduled(day) && (
              <Img src={WARNING_SIGN} size={[15, 15]} />
            )}
          </Styled.Sessions>
          { day === activeDay
            ? <DownTriangle color={COLORS_CONFIG.SMOOTH_BROWN} />
            : <ForwardTriangle color={COLORS_CONFIG.SMOOTH_BROWN} />
          }
        </Styled.Header>
      )}
      content={(
        <Styled.Content>
          {createContent(i)}
        </Styled.Content>
      )}
    />
  )), [weekdays, excludeStartDatetimes, activeDay]);

  return (
    <Styled.Wrapper>
      <Styled.Rows>
        <Styled.WeekLabel>
          { currentRangeStr
              && (
                <>
                  <Styled.WeekLabelLine />
                  <Styled.WeekLabelTitle>
                    {currentRangeStr}
                  </Styled.WeekLabelTitle>
                </>
              )
          }
        </Styled.WeekLabel>
        {rows}
      </Styled.Rows>
    </Styled.Wrapper>
  );
}

/* WeekdaysAccordion type of props */

WeekdaysAccordion.propTypes = {
  weekdays: PropTypes.objectOf((propValue, key, componentName, location, propFullName) => {
    if (!isValidDate(key)) {
      return new Error(
        `Invalid prop \`${propFullName}\` supplied to`
        + ` \`${componentName}\`. Validation failed.`,
      );
    }
  }),
  sessions: PropTypes.number.isRequired,
  toggleSession: PropTypes.func,
  excludeStartDatetimes: PropTypes.arrayOf(PropTypes.string),
  currentRangeStr: PropTypes.string,
};

/* WeekdaysAccordion default props */

WeekdaysAccordion.defaultProps = {
  toggleSession: null,
  excludeStartDatetimes: [],
  currentRangeStr: null,
};

export default WeekdaysAccordion;
