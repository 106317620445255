/* Libs */
import styled from 'styled-components';

/* Constants */
import { COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  width: 282px;
  height: 300px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  & > * {
    border-radius: 8px;
    height: 100%;
  }
`;
