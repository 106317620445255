/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';

export const Wrapper = styled.div``;

export const Text = styled.div`
  max-width: 100%;
  font-size: 16px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;
