/* Libs */
import styled, { css } from 'styled-components';

import {
  FONTS_CONFIG,
  COLORS_CONFIG,
} from 'config';

// import { Label } from 'components/ui/GoLabel/styles';
import {
  Wrapper as SelectWrapper,
} from 'components/ui/Select/styles';
import {
  Wrapper as ToggleWrapper,
  Item,
} from 'components/ui/ToggleBar/styles';
import { Wrapper as InputWrapper } from 'components/ui/SearchInput/styles';


export const Wrapper = styled.div`
  position: relative;
  padding: 40px;
  min-height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
  width: 100%;
`;

export const Title = styled.div`
  flex-grow: 1;
  margin-right: 40px;
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  & ${InputWrapper} {
    border-radius: 3px;
    margin-left: 20px;
    width: 300px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 250px;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 34px 0 40px;
  & ${SelectWrapper} {
    width: 200px;
  }
  & ${ToggleWrapper} {
    background: ${COLORS_CONFIG.TRANSPARENT};
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
  & ${Item} {
    ${({ active }) => active
    && css`
      background: red;
      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
        content: '';
      }
    `}
  }
`;
