/* Libs */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/* Constants */

import { STRUCT_IDS } from '_constants';

/* Components */

import {
  TableActionSidebar,
  EditUserForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

export default function EditUserSidebar({
  open,
  title,
  onClose,
  id,
  ...restProps
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleClose = () => {
    setShowDeleteModal(false);
    onClose();
  };

  if (!open) return null;
  return (
    <TableActionSidebar
      open={open}
      title={title}
      onClose={handleClose}
      id={id}
      showTitle={!showDeleteModal}
    >
      <Styled.EditUserSidebar>
        <EditUserForm
          {...restProps}
          onClose={handleClose}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      </Styled.EditUserSidebar>
    </TableActionSidebar>
  );
}

// Type of props
EditUserSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

// Default value for props
EditUserSidebar.defaultProps = {
  id: STRUCT_IDS.COMPANY_ADMIN_PAGE,
};
