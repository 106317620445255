export default data => ({
  name: data.name,
  location: data.location,
  contract: {
    start_date: data.start_date,
    end_date: data.end_date,
  },
  licenses: data.licenses,
  admin: {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
  },
});
