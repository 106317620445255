/* Libs */
import styled from 'styled-components';

// import { Button } from 'components/ui/Button/styles';

// import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
