/* Libs */
import styled from 'styled-components';


import { Wrapper as InputWrapper } from 'components/ui/Input/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > ${InputWrapper} {
    margin-top: 24px;
  }
  & > *:last-child{
    margin-top: 56px;
  }
`;
