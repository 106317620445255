/* Libs */
import styled from 'styled-components';

import { default as DefaultButton } from 'components/ui/Button';
import { COLORS_CONFIG } from 'config';

export const Button = styled(DefaultButton)`
    width: 110px;
    height: 48px;
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    &:hover {
        background-color: initial;
        color: initial;
    }
    cursor: pointer;
`;
