/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { Button, Img } from 'components';

/* Styles */

import { AIRPLANE } from 'assets/icons';
import * as Styled from './styles';

function SuccessLetterSend({
  onOk,
}) {
  return (
    <Styled.Wrapper>
      <Img size={[84, 84]} src={AIRPLANE} />
      <Styled.Label>
        Password recovery email successfully sent!
      </Styled.Label>
      <Button onClick={onOk}>
        OK
      </Button>
    </Styled.Wrapper>
  );
}

/* SuccessLetterSend type of props */

SuccessLetterSend.propTypes = {
  onOk: PropTypes.func.isRequired,
};

export default SuccessLetterSend;
