/* Libs */
import styled, { css } from 'styled-components';
import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';
import { QUESTION_STATUS } from '_constants';
import { CustomScroll } from 'assets/styles/mixins';

export const Text = styled.div`
  font-size: 14px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
export const Label = styled(Text)`
  padding-left: 16px;
`;
export const Name = styled.div`
  line-height: 16px;
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  word-break: break-word;  
`;
export const Code = styled(Text)`
  padding-top: 8px;
  line-height: 14px;
`;
export const Hint = styled(Text)`
  padding: 16px 0 24px 0;
  flex-grow: 1;
  word-break: break-word;
`;

export const Wrapper = styled.div`
  transition: .3s;
  & > * {
    color: ${COLORS_CONFIG.WHITE};
  }
  background: ${COLORS_CONFIG.DARK_GREY};
  ${({ status }) => status === QUESTION_STATUS.ANSWERED && css`
    background-color: ${COLORS_CONFIG.SKY_BLUE};
    & ${Label} {
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    }
  `}
  display: flex;
  flex-direction: column;
  padding: 32px 32px 16px 32px;
  overflow-y: auto;
  
   ${() => CustomScroll({ scrollBG: 'transparent' })}
  
  ${({ isDarkMode, status }) => isDarkMode && css`
      background: ${status === QUESTION_STATUS.ANSWERED
    ? COLORS_CONFIG.LIGHT_BLUE_GRADIENT_2
    : COLORS_CONFIG.LIGHT_BLUE_GRADIENT_1};
    
     ${() => CustomScroll({
    scrollBG: 'transparent',
    thumbScrollBG: COLORS_CONFIG.OXFORD_BLUE,
  })}
  `}
  
  &:hover{
    cursor: pointer;
  }
`;
