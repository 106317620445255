import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Tooltip, PortalTooltip } from 'components';

/* Styles */
import { truncateText } from 'utils/custom';
import * as Styled from './styles';

const TextWithTooltip = ({
  text, tooltipText, maxLength, children, withPortalTooltip,
}) => {
  const wrapperRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouse = (status) => {
    if (maxLength === null || text.length < maxLength) return undefined;
    return () => setShowTooltip(status);
  };

  const TooltipComponent = useMemo(() => (withPortalTooltip ? PortalTooltip : Tooltip), [withPortalTooltip]);

  return (
    <Styled.Wrapper
      onMouseEnter={handleMouse(true)}
      onMouseLeave={handleMouse(false)}
      ref={wrapperRef}
    >
      {truncateText(text, maxLength)}
      {children}
      <TooltipComponent visible={showTooltip} parentRef={wrapperRef}>
        {tooltipText || text}
      </TooltipComponent>
    </Styled.Wrapper>
  );
};

TextWithTooltip.propTypes = {
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  maxLength: PropTypes.number,
  withPortalTooltip: PropTypes.bool,
};

TextWithTooltip.defaultProps = {
  text: '',
  tooltipText: '',
  maxLength: null,
  withPortalTooltip: false,
};

export default TextWithTooltip;
