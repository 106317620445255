import { LS_AdminCompanyEntity } from '_entities';
import * as TYPES from './GameDef.types';

const initialState = {
  loading: false,
  gameDefs: [],
  total: 0,
  loaded: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LS_AdminCompanyEntity.types.RESET_COMPANY_STATE:
      return {
        ...initialState,
      };
    case TYPES.GET_COMPANY_GAME_DEF:
    case TYPES.CREATE_COMPANY_GAME_DEF:
    case TYPES.UPDATE_COMPANY_GAME_DEF:
    case TYPES.DELETE_COMPANY_GAME_DEF:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_COMPANY_GAME_DEF_SUCCESS:
      return {
        ...state,
        gameDefs: action.result.results,
        total: action.result.count,
        loaded: true,
        loading: false,
      };
    case TYPES.CREATE_COMPANY_GAME_DEF_FAIL:
    case TYPES.UPDATE_COMPANY_GAME_DEF_FAIL:
    case TYPES.DELETE_COMPANY_GAME_DEF_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_COMPANY_GAME_DEF_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
};

export default reducer;
