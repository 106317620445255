import { validateGameDefField } from './index';

export default (name, value, path, data) => {
  const additionalErrors = {};
  if (name === 'final_code') {
    const { questions } = data;
    questions.forEach((question, i, self) => {
      if (!question.unlock_code) return;
      additionalErrors[`questions[${i}].unlock_code`] = validateGameDefField({
        name: 'unlock_code',
        value: question.unlock_code,
        section: 'questions',
        additionalData: {
          otherQuestions: self.filter((item, index) => index !== i),
          final_code: value,
        },
      });
    });
  } else if (['name', 'unlock_code', 'question'].includes(name)) {
    const { questions, final_code: finalCode } = data;
    const currentIndex = +path.match(/\d/)[0];
    questions.forEach((question, i, self) => {
      additionalErrors[`questions[${i}].${name}`] = validateGameDefField({
        name,
        value: i === currentIndex ? value : question[name],
        section: 'questions',
        additionalData: {
          otherQuestions: self.map((item, index) => ({
            ...item,
            [name]: index === currentIndex ? value : item[name],
          })).filter((item, index) => index !== i),
          final_code: finalCode,
        },
      });
    });
  }
  return additionalErrors;
};
