/* Libs */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

/* Components */
import {
  SimpleTextCell,
  ActionsCell,
  _2LinesCell,
  ColumnCell,
  GoLabel,
  Table,
} from 'components';

/* Utils */
import getFullName from 'utils/custom/getFullName';

/* Constants */
import { USER_TYPES, TABLES_CONFIG } from 'config';
import { TRIANGLE_TYPES, LABEL_COLOR_TYPES } from '_constants';

/* Styles */
import * as Styled from './styles';

const getColumnsData = ({ userType, actions }) => [
  {
    accessor: 'name',
    Header: 'Name',
    flexGrow: 3,
    Cell: props => (
      <SimpleTextCell
        value={getFullName(props.original.first_name, props.original.last_name)}
        size="small"
      />
    ),
  },
  {
    accessor: 'email',
    Header: 'Email',
    flexGrow: 3,
    Cell: props => <SimpleTextCell value={props.original.email} size="small" />,
  },
  {
    accessor: 'role',
    Header: 'Role',
    Cell: props => <SimpleTextCell value={`LS ${props.original.role.charAt(0).toUpperCase() + props.original.role.slice(1)}`} size="small" />,
  },
].concat(
  userType !== USER_TYPES.ACTIVE && {
    accessor: 'deactivationDate',
    Header: 'Deactivation Date',
    Cell: props => <SimpleTextCell value={moment(props.original.deactivation_date).format('L')} size="small" />,
  },
).concat(
  [
    {
      accessor: 'action',
      minWidth: userType === USER_TYPES.ACTIVE ? 80 : 100,
      Header: '',
      Cell: ({ original: { id } }) => (
        <ActionsCell>
          {userType !== USER_TYPES.ACTIVE
            ? (
              <GoLabel
                text="Activate"
                triangleType={TRIANGLE_TYPES.FORWARD}
                color={LABEL_COLOR_TYPES.BLUE}
                click={() => actions.activateUser(id)}
              />
            )
            : (
              <GoLabel
                text="View"
                triangleType={TRIANGLE_TYPES.FORWARD}
                click={() => actions.openEditUser(id)}
              />
            )
          }
        </ActionsCell>
      ),
    },
  ],
).filter(column => typeof column === 'object' && column !== null);


export default function Admins({
  data,
  tableSize,
  userType,
  actions,
  loading,
  pagination,
}) {
  return (
    <Styled.Admins>
      <Table
        defaultPageSize={data.length || null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={getColumnsData({ userType, actions })}
        data={data}
        loading={loading}
        pagination={pagination}
      />
    </Styled.Admins>
  );
}

// Type of props
const {
  number, string, arrayOf, bool, oneOf, shape, func,
} = PropTypes;

Admins.propTypes = {
  data: arrayOf(
    shape({
      id: number.isRequired,
      first_name: string,
      last_name: string,
      email: string.isRequired,
      role: string.isRequired,
      is_active: bool.isRequired,
      deactivation_date: string,
    }),
  ),
  tableSize: oneOf(Object.values(TABLES_CONFIG.SIZE)),
  userType: oneOf(Object.values(USER_TYPES)).isRequired,
  actions: shape(),
};

Admins.defaultProps = {
  data: [],
  tableSize: TABLES_CONFIG.SIZE.S,
};
