/* Libs */
import styled, { css, keyframes } from 'styled-components';


const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Refresh = styled.img`
  width: 25px;
  height: auto;
  object-fit: contain;
  ${({ isLoading }) => !isLoading && css`
    &:active {
      animation: ${rotate} 0.4s linear;
    }
  `}
  ${({ isLoading }) => isLoading && css`
    animation: ${rotate} 1s linear infinite;
  `}
`;
