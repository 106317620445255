/* Libs */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

/* Components */

import { ConfirmAction } from 'components';
import {
  useOutsideClickHook,
  useToggleBooleanHook,
} from 'hooks';

/* Styles */

import * as Styled from './styles';

function DeleteQuestion({
  number,
  deleteQuestion,
}) {
  const [isOpen, toggle] = useToggleBooleanHook(false);

  const close = () => {
    toggle(false);
  };

  const onConfirm = () => {
    toggle(false);
    deleteQuestion(number);
  };

  const sectionRef = useRef(null);
  useOutsideClickHook(close, sectionRef);
  return (
    <Styled.Wrapper
      ref={sectionRef}
    >
      <Styled.Label
        onClick={toggle}
      >
        Delete question
      </Styled.Label>
      <ConfirmAction
        isOpen={isOpen}
        onConfirm={onConfirm}
        onReject={close}
      />
    </Styled.Wrapper>
  );
}

/* DeleteQuestion type of props */

DeleteQuestion.propTypes = {
  number: PropTypes.number.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
};

export default DeleteQuestion;
