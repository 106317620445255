/* Libs */
import styled from 'styled-components';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
  REPORTS_CONFIG,
} from 'config';

/* Styles */

import {
  Wrapper as ToggleWrapper,
} from 'components/ui/ToggleBar/styles';
import {
  Wrapper as DatePickerWrapper,
} from 'components/ui/DatePicker/styles';
import {
  Input as DatePickerInput,
} from 'components/ui/MaskInput/styles';


export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 531px;
  background: ${COLORS_CONFIG.WHITE}; 
  border-radius: 4px;
  padding: 20px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;

export const Header = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;

  & ${DatePickerWrapper} {
    width: 165px;
    padding-top: 16px;
    margin-right: 22px;

    & ${DatePickerInput} {
      height: 35px;
      padding: 0;
      padding-left: 11px;
    }
  }

  & ${ToggleWrapper} {
    height: 32px;
    margin: 0 100px;
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
`;

export const Title = styled.h2`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 20px;
  line-height: 24px;
`;

export const Main = styled.div`
  height: calc( 100% - 100px );
  max-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  height: 300px;
  width: ${({ type }) => (type === REPORTS_CONFIG.ACTIVITY_TYPES.DAY ? '90%' : '100%')};
`;

export const ArrowButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${COLORS_CONFIG.WHITE_CUSTOM_2};
  color: ${COLORS_CONFIG.BLACK};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 16px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.65;
  }
`;

export const ButtonPlaceholder = styled.div`
  width: 30px;
  height: 30px;
`;
