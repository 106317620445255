import styled from 'styled-components';
import { COLORS_CONFIG } from '../../../config';


export const Wrapper = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
`;
