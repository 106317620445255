/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { CustomScroll, TextEllipsis } from 'assets/styles/mixins';
import { Button } from 'components/ui/Button/styles';

export const Link = styled.div`
  height: 48px;
  padding: 16px 18px 14px;
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
  width: 243px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Action = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & ${Button} {
    width: 84px;
    min-width: 84px;
    font-size: 12px;
    line-height: 12px;
    border: none;
    border-radius: initial;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  display: flex;
  align-items: center;
`;
export const CompanyName = styled.span`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  margin-right: 5px;
  max-width: 300px;
  ${TextEllipsis}
`;

export const Header = styled.div`
  width: 100%;
  height: 80px;
  min-height: 80px;
  position: relative;
  &::after{
    content: '';
    top: 100%;
    width: 100%;
    position: absolute;
    height: 1px;
    box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
    border-bottom: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
`;

export const Page = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 53px;
  position: relative;
  background-color: ${COLORS_CONFIG.SILVER};
  overflow: auto;
  padding-bottom: 40px;
  
   ${CustomScroll}
`;

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 25px;
`;

export const Struct = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  ${({ isDarkMode }) => isDarkMode && css`
      background-color: ${COLORS_CONFIG.DARK_BLUE};
      
      & ${Title}, ${Link}{
        color: ${COLORS_CONFIG.WHITE}
      }
      
      & ${Page}{
          background-color: ${COLORS_CONFIG.DARK_BLUE};
          ${() => CustomScroll({
    scrollBG: 'transparent',
    thumbScrollBG: COLORS_CONFIG.OXFORD_BLUE,
  })}     
      }
  `}
`;

export const Content = styled.div``;
