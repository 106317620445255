import styled from 'styled-components';

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 6px 4px 0;
  border-color: transparent ${({ color }) => color} transparent transparent;
`;

export default Triangle;
