/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  CircleFilled,
} from 'components';

/* Constants */

import {
  COLORS_CONFIG,
} from 'config';

/* Styles */

import * as Styled from './styles';

function Cell({
  day,
  hasEvent,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Cell hasEvent={hasEvent}>
        <Styled.Content>
          {day}
        </Styled.Content>
        <Styled.EventMarker>
          <CircleFilled
            active={hasEvent}
            color={COLORS_CONFIG.ORANGE}
            colorInactive={COLORS_CONFIG.TRANSPARENT}
          />
        </Styled.EventMarker>
      </Styled.Cell>
    </Styled.Wrapper>
  );
}

/* Cell type of props */

Cell.propTypes = {
  day: PropTypes.string.isRequired,
  hasEvent: PropTypes.bool.isRequired,
};

export default Cell;
