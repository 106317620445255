/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG } from 'config';
import { GAME_PLAY_BG } from 'assets/icons/game_play';


export const Container = styled.div`
  width: 520px;
  max-height: 80%;
  padding: 0px 48px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  background: ${COLORS_CONFIG.SKY_BLUE};
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center; 
  background-color: ${COLORS_CONFIG.SMOOTH_BROWN}; 
  
  ${({ isDarkMode }) => isDarkMode
      && css`
       background-color: ${COLORS_CONFIG.DARK_BLUE};
       background-image: url(${GAME_PLAY_BG});
       background-size: contain;
       background-repeat: no-repeat;
       background-position: center center; 
       
       & ${Container} {
         background: ${COLORS_CONFIG.LIGHT_BLUE_GRADIENT};
       }
   `}
`;
