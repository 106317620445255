/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */
import { URLS_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

function CompanyLicenseExpiredBlock({ isLSAdmin }) {
  return (
    <Styled.Wrapper>
      <Styled.Text>
        {
          isLSAdmin
            ? (
              <>
                {`
                  Company's contract has expired. You should extend the period of licensing
                   in that company for a possibility to create and edit sessions/campaigns/users
                `}
              </>
            )
            : (
              <>
                {'Company\'s contract has expired. You should contact Living Security '}
                <Styled.Link href={URLS_CONFIG.support} target="_blank">Support</Styled.Link>
              </>
            )
        }
      </Styled.Text>
    </Styled.Wrapper>
  );
}

CompanyLicenseExpiredBlock.propTypes = {
  isLSAdmin: PropTypes.bool,
};

CompanyLicenseExpiredBlock.defaultProps = {
  isLSAdmin: true,
};

export default CompanyLicenseExpiredBlock;
