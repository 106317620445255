/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const InputWrapper = styled.div`
  display: block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 100%;
  height: 100%;
`;

export const Shape = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS_CONFIG.WHITE};
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
  width: 24px;
  height: 24px;
  & > * {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
  }
  ${({ type }) => type === 'circle' && css`
    border-radius: 12px;
    & > * {
      width: 18px;
      height: 18px;
      border-radius: 18px;
    }
  `}
  ${({ disabled }) => disabled && css`
    background-color: ${COLORS_CONFIG.SILVER};
  `}
`;

export const HtmlLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 16px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  padding-left: 16px;
  user-select: none;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
`;

export const Wrapper = styled.div`
  width: 100%;
  & ${Label},
  & ${InputWrapper} {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;
