/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LS_AdminStorylinesEntity } from '_entities';

/* Utils */

import { formIsValid } from 'utils/validation';
import { fileToBase64 } from 'utils/transformers';
import {
  getFileNameFromUrl,
  getError,
  getInitialTouched,
  pickTouchedDataObject,
} from 'utils/custom';
import { validateText, validateStorylineImg } from 'utils/validation/fields';
import { notification } from 'utils/services';

/* Components */

import {
  Input,
  Button,
  InputImage, Img,
} from 'components';
import { INFO_CIRCLE_RED } from 'assets/icons';

/* Constants */

import { NOTIFICATIONS } from '_constants';

/* Styles */

import * as Styled from './styles';

function EditStorylineForm({
  data: INITIAL_DATA,
  updateStoryline,
  loading,
  onClose,
  loadAllStorylines,
}) {
  const DEFAULT_ERRORS = {
    image: false,
    name: false,
    description: INITIAL_DATA.description ? false : null,
  };

  const [formData, setData] = useState({
    data: {
      ...INITIAL_DATA,
      image: getFileNameFromUrl(INITIAL_DATA.image),
    },
    touchedData: getInitialTouched({
      ...INITIAL_DATA,
      image: getFileNameFromUrl(INITIAL_DATA.image),
    }),
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data, touchedData: oldTouchedData } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'image':
        newErrors[name] = validateStorylineImg({ value, name });
        break;
      case 'name':
        newErrors[name] = validateText({
          value,
          name,
          min: 2,
        });
        break;
      case 'description':
        newErrors[name] = validateText({
          value,
          name,
          required: false,
        });
        break;
      default:
        break;
    }

    const touchedData = { ...oldTouchedData, [name]: value !== INITIAL_DATA[name] };

    setData({
      data: { ...data, [name]: value },
      touchedData,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['image', 'name'])
        && Object.values(touchedData).some(value => Boolean(value)),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors, data, touchedData: oldTouchedData } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'name':
        newErrors[name] = validateText({
          value,
          name,
          min: 2,
        });
        break;
      case 'description':
        newErrors[name] = validateText({
          value,
          name,
          required: false,
        });
        break;
      default:
        break;
    }

    const touchedData = { ...oldTouchedData, [name]: value !== INITIAL_DATA[name] };

    setData({
      data: { ...data, [name]: value },
      touchedData,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['image', 'name'])
        && Object.values(touchedData).some(value => Boolean(value)),
    });
  };

  const handleSave = async () => {
    try {
      const { data, touchedData } = formData;
      const requestData = { ...pickTouchedDataObject(data, touchedData) };
      const requestFormData = new FormData();

      Object.keys(requestData).forEach((key) => {
        requestFormData.append(key, formData.data[key]);
      });

      if (!(formData.data.image instanceof File)) {
        requestFormData.delete('image');
      }

      await updateStoryline({
        data: requestFormData,
        id: data.id,
      });
      await loadAllStorylines();
      notification.success(NOTIFICATIONS.SUCCESS_UPDATE_STORYLINE);
      onClose();
    } catch (error) {
      const { errors } = formData;
      const newErrors = Object.entries(error.response.data).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, { ...errors });

      if (newErrors) {
        setData(prev => ({
          ...prev,
          errors: newErrors,
          canSubmit: formIsValid({ ...newErrors }, ['image', 'name']),
        }));
      } else {
        notification.error(getError(error));
      }
    }
  };

  const { data, errors, canSubmit } = formData;

  return (
    <Styled.Wrapper>
      <Styled.ImageInput>
        <Styled.Text>
          Upload an icon
        </Styled.Text>
        <InputImage
          name="image"
          error={errors.image}
          value={data.image}
          onChange={handleChange}
        />
      </Styled.ImageInput>
      <Input
        placeholder="Name storyline"
        title="Name storyline"
        name="name"
        error={errors.name}
        value={data.name}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Input
        placeholder="Description"
        title="Description"
        name="description"
        error={errors.description}
        value={data.description}
        onChange={handleChange}
      />
      <Styled.ImageError>
        {
          errors.image && (
            <>
              <Img disabled src={INFO_CIRCLE_RED} size={[13, 13]} />
              {errors.image}
            </>
          )
        }
      </Styled.ImageError>
      <Button
        disabled={!canSubmit || loading}
        isLoading={loading}
        onClick={handleSave}
      >
        Update storyline
      </Button>
    </Styled.Wrapper>
  );
}

/* EditStorylineForm type of props */

EditStorylineForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateStoryline: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
};

export default connect(({ LS_adminStorylines }) => ({
  loading: LS_adminStorylines.loading,
}), {
  updateStoryline: LS_AdminStorylinesEntity.actions.updateStoryline,
})(EditStorylineForm);
