/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { PARTICIPANT_SIGNUP_TYPE } from '_constants';
import {
  AcceptInvite,
  ConfirmMessage,
} from './Steps';


function SignupSession({
  step,
  steps,
  changeStep,
}) {
  function manageStep() {
    switch (step) {
      case steps.acceptInvite:
        return (
          <AcceptInvite
            nextStep={changeStep(steps.confirmMessage)}
          />
        );
      case steps.confirmMessage:
        return <ConfirmMessage />;
      default:
        break;
    }
  }

  return manageStep();
}

/* SignupSession type of props */

SignupSession.propTypes = {
  participantSignup: PropTypes.func.isRequired,
  gameSession: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  inviteType: PropTypes.oneOf([
    PARTICIPANT_SIGNUP_TYPE.SESSION_DIRECT,
    PARTICIPANT_SIGNUP_TYPE.SESSION_PUBLIC,
    PARTICIPANT_SIGNUP_TYPE.SESSION_EDIT,
  ]).isRequired,
  participant_email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default memo(SignupSession);
