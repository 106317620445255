/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  AVAILABLE_COLORS,
} from 'config';

/* Components */

import { CircleFilled } from 'components';

/* Styles */

import * as Styled from './styles';

function ProgressBarPointers({
  current,
  total,
  activeColor,
  inactiveColor,
}) {
  const circles = [];
  for (let i = 1; i <= total; i++) {
    circles.push(
      <CircleFilled
        key={i}
        active={i === current}
        color={activeColor}
        colorInactive={inactiveColor}
      />,
    );
  }
  return (
    <Styled.Wrapper>
      { circles }
    </Styled.Wrapper>
  );
}

/* ProgressBarPointers type of props */

ProgressBarPointers.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  activeColor: PropTypes.oneOf([...AVAILABLE_COLORS]),
  inactiveColor: PropTypes.oneOf([...AVAILABLE_COLORS]),
};

export default ProgressBarPointers;
