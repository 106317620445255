/* Libs */
import styled, { css } from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

import { Label } from 'components/ui/GoLabel/styles';
import {
  Wrapper as SelectWrapper,
} from 'components/ui/Select/styles';
import {
  Wrapper as ToggleWrapper,
  Item,
} from 'components/ui/ToggleBar/styles';
import { Pagination } from 'components/ui/Pagination/styles';
import { Wrapper as ImgWrapper, Image } from 'components/blocks/ui/Img/styles';
import {
  Tooltip,
  Content as TooltipContent,
} from 'components/blocks/ui/Tooltip/styles';
import { Wrapper as AddCircleWrapper } from 'components/ui/AddCircle/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const WrapperLoader = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 30px 38px 44px;
  min-height: 100%;
  ${Pagination} {
    margin-top: 40px;
  }
  
  ${CustomScroll}
  
   @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      padding: 23px 38px 44px;
   }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
     margin: 0 0 4px;
  }
  
`;

export const Title = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 12px 40px 12px 0;
  ${ImgWrapper} {
    cursor: pointer;
  }
`;

export const TitleText = styled.div`
  margin-right: 10px;
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 290px;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
     max-width: 250px;
  }
`;

export const TitleInfo = styled.div`
  position: relative;
  
  ${Image}{
    transform: translateY(-3px);
  }
  ${Tooltip} {
    z-index: 2;
    top: -12px;
    left: calc(100% + 18px);
  }
`;

export const TooltipBlock = styled.div`
  margin-bottom: 27px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TooltipTitle = styled.div`
  margin-bottom: 10px;
  font-size: 17px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const TooltipText = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 250px;
  ${AddCircleWrapper} {
    margin-left: 32px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Statistics = styled.div`
  display: flex;
  align-items: center;
`;

export const StatisticsItem = styled.div`
  padding: 0 32px;
  border-right: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
    border-right: none;
  }
`;

export const StatisticsNumbers = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 24px;
  ${ImgWrapper} {
    cursor: pointer;
  }
`;

export const StatisticsText = styled.div`
  margin-top: 8px;
  font-size: 12px;
`;

export const ParticipantsTooltip = styled.div`
  position: relative;
  margin-left: 5px;
  ${Tooltip} {
    z-index: 2;
    top: -16px;
    left: calc(100% + 15px);
  }
  ${TooltipContent} {
    padding: 10px;
    width: 250px;
    min-height: 80px;
    font-size: 13px;
  }
`;

export const ParticipantsDescription = styled.div`
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Share = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;

  & ${Label} {
    padding-right: 9px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}) {
    justify-content: flex-start;
    margin-top: 24px;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  & ${SelectWrapper} {
    width: 200px;
  }
  & ${ToggleWrapper} {
    background: ${COLORS_CONFIG.TRANSPARENT};
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
  & ${Item} {
    ${({ active }) => active
    && css`
      background: red;
      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
        content: '';
      }
    `}
  }
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    flex-wrap: wrap;
    ${SelectWrapper} {
      margin: 10px 0;
    }
  }
`;

export const NoCampaignsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;


export const NoCampaignsTitle = styled.div`
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  margin: 58px 0 14px;
`;

export const NoCampaignsText = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 35px;
`;
