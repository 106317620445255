/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

import { INFO_CIRCLE_BLUE } from 'assets/icons';

/* Components */
import {
  FormActions,
  SmallTitle,
  SettingsComponentsWrapper,
  SettingsElement, IconTooltip,
} from 'components';

/* Styles */
import * as Styled from './styles';


function SettingsSection({
  onClose,
  onSubmit,
  sectionParts,
  isSubmitButtonEnable,
  loading
}) {
  return (
    <Styled.Wrapper>
      <Styled.Title>Sessions</Styled.Title>
      <Styled.Content>
        {
          sectionParts.map(({
            subTitle, subTitleAdding, components, full, infoText,
          }, idx) => (
            <SettingsElement full={full} key={idx}>
              {
                (subTitle || subTitleAdding) && (
                  <Styled.SubTitle>
                    {subTitle && <SmallTitle>{subTitle}</SmallTitle>}
                    {subTitleAdding && <Styled.SubTitleAdding>{subTitleAdding}</Styled.SubTitleAdding>}
                    { infoText && (
                      <IconTooltip
                        src={INFO_CIRCLE_BLUE}
                        items={[infoText]}
                      />
                    )
                    }
                  </Styled.SubTitle>
                )
              }
              {components && <SettingsComponentsWrapper components={components} />}
            </SettingsElement>
          ))
        }
      </Styled.Content>
      <FormActions
        onSubmit={onSubmit}
        onCancel={onClose}
        submitText="Save settings"
        isSubmitButtonEnable={isSubmitButtonEnable}
        loading={loading}
      />
    </Styled.Wrapper>
  );
}

/* SettingsSection type of props */

SettingsSection.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitButtonEnable: PropTypes.bool.isRequired,
};

/* SettingsSection default props */

SettingsSection.defaultProps = {
};

export default SettingsSection;
