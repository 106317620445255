/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Components */

import { Img, Tooltip } from 'components';

/* Assets */

import { INFO_CIRCLE_BLUE } from 'assets/icons';
/* Styles */

import * as Styled from './styles';

function ParticipantsCell({
  leftParticipants,
  rightParticipants,
}) {
  const [tooltipIsVisible, setTooltipVisibility] = useState(false);
  const openTooltip = () => setTooltipVisibility(true);
  const closeTooltip = () => setTooltipVisibility(false);

  return (
    <Styled.Cell>
      <Styled.CellText># of Participants</Styled.CellText>
      {
        (leftParticipants && rightParticipants) && (
          <Styled.CellInfo>
            <Img
              src={INFO_CIRCLE_BLUE}
              onClick={openTooltip}
              size={[14, 14]}
            />
            <Tooltip visible={tooltipIsVisible} onClose={closeTooltip}>
              <Styled.CellTooltipText>{leftParticipants}</Styled.CellTooltipText>
              <Styled.CellTooltipText>of</Styled.CellTooltipText>
              <Styled.CellTooltipText>{rightParticipants}</Styled.CellTooltipText>
            </Tooltip>
          </Styled.CellInfo>
        )
      }
    </Styled.Cell>
  );
}

/* ParticipantsCell type of props */

ParticipantsCell.propTypes = {
  leftParticipants: PropTypes.string,
  rightParticipants: PropTypes.string,
};

/* ParticipantsCell default props */

ParticipantsCell.defaultProps = {
  leftParticipants: null,
  rightParticipants: null,
};

export default ParticipantsCell;
