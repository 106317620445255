/* Libs */
import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, debounce } from 'lodash';

/* Actions */

import { AdminCompanyEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';
import {
  exportCSV, getError, showErrorsFromBackend,
} from 'utils/custom';

/* Components */

import {
  ActivitySection,
  ReportsSection,
  ReportStatisticsSection as StatisticsSection,
  ExportsMenu,
  FilterSection,
  Loader,
  LinkTo,
  ForwardTriangle,
} from 'components';

/* Constants */

import { URLS_CONFIG, DATE_SETTINGS, COLORS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

const PAGE_TITLE = 'Main';

function Main({
  // campaigns,
  startDate,
  companyId,
  getCompany,
  getGeneral,
  getActivity,
  getCampaigns,
  getLocations,
  getCampaignActivity,
  downloadCampaignReport,
  downloadCompanyReport,
  companyName,
}) {
  const [general, setGeneralData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [filterChangeLoading, setFilterChangeLoading] = useState(false);
  const [totalCampaigns, setTotalCampaigns] = useState(null);
  const [totalLocations, setTotalLocations] = useState(null);
  const [page, setPage] = useState(1);
  const [locationPage, setLocationPage] = useState(1);
  const [fetchedCampaigns, setFetchedCampaigns] = useState([]);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getCompany(companyId);
  }, [companyId, getCompany]);


  useEffect(() => {
    (async () => {
      try {
        const { results, count } = await getLocations({ page: locationPage, withoutStoreChanging: true });
        setTotalLocations(count);
        setFetchedLocations(prev => prev.concat(results));
      } catch (error) {
        notification.error(getError(error));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const campaignsResponse = await getCampaigns({
          company: companyId,
        });
        setTotalCampaigns(campaignsResponse.count);
        setFetchedCampaigns(campaignsResponse.results);
        setLoaded(true);
      } catch (error) {
        notification.error(getError(error));
      }
    })();
  }, [companyId, getCampaigns, startDate]);

  const reportsData = useMemo(
    () => fetchedCampaigns.map(
      ({
        id,
        is_active: isActive,
        name,
        created,
        sessions_completed: sessionsCompleted,
        participants_actual: participantsActual,
        correct_answers: correctAnswers,
      }) => ({
        id,
        isActive,
        title: name,
        createdAt: moment(created).format(
          DATE_SETTINGS.FORMAT.MONTH_SHORT_DAY_YEAR,
        ),
        sessionsCompleted,
        participantsActual: participantsActual || 0,
        correctAnswers: correctAnswers || 0,
      }),
    ),
    [fetchedCampaigns],
  );

  const handleFiltersChange = useCallback(async (filters) => {
    try {
      setFilterChangeLoading(true);

      const generalResponse = await getGeneral({ id: companyId, ...filters });
      setLoaded(true);
      setFilterChangeLoading(false);
      setFilterData(filters);
      setGeneralData(generalResponse);
      setPage(1);
    } catch (error) {
      setFilterChangeLoading(false);
      showErrorsFromBackend(error);
    }
  }, [companyId, getGeneral]);

  const activityProps = useMemo(
    () => ({
      id: companyId,
      locations: get(filterData, 'locations', []),
      dateFrom: get(filterData, 'dateFrom'),
      dateTo: get(filterData, 'dateTo'),
    }),
    [companyId, filterData],
  );

  const getNext = async () => {
    setPage(prev => prev + 1);
    const response = await getCampaigns({
      company: companyId,
      page: page + 1,
    });
    setFetchedCampaigns(prev => prev.concat(response.results));
  };

  const handleSearchLocations = useCallback(debounce(async (location) => {
    try {
      const { results: locations } = await getLocations({
        location,
        page: 1,
        withoutStoreChanging: true,
      });
      setLocationPage(1);
      setFetchedLocations(locations);
    } catch (error) {
      notification.error(getError(error));
    }
  }, 300), []);

  const getNextLocation = async () => {
    try {
      setFilterChangeLoading(true);
      setLocationPage(prev => prev + 1);
      const locationResponse = await getLocations({
        page: locationPage + 1,
        withoutStoreChanging: true,
      });
      setFetchedLocations(prev => prev.concat(locationResponse.results));
      setFilterChangeLoading(false);
    } catch (error) {
      setFilterChangeLoading(false);
      notification.error(getError(error));
    }
  };

  const correctStartDate = useMemo(
    () => (moment(startDate).isAfter(moment()) ? null : startDate),
    [startDate],
  );

  const exportCompanyCSV = useCallback(async (withParticipants = false) => {
    const dateFrom = moment(startDate).format(
      DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
    );
    const dateTo = moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY);

    try {
      const report = await downloadCompanyReport({
        id: companyId,
        dateFrom,
        dateTo,
        withParticipants,
      });
      exportCSV(report, `${companyName}_report_from_${dateFrom}_to_${dateTo}`);
    } catch (error) {
      notification.error(getError(error));
    }
  }, [companyId, companyName, downloadCompanyReport, startDate]);

  const exportActions = useMemo(() => [
    {
      text: 'Overall report',
      onClick: () => exportCompanyCSV(),
    },
    {
      text: 'Overall report with participants',
      onClick: () => exportCompanyCSV(true),
    },
  ], [exportCompanyCSV]);

  if (!loaded) {
    return (
      <Styled.Wrapper>
        <Loader />
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>Overall Company Report</Styled.Title>
        <Styled.Actions>
          <ExportsMenu actions={exportActions} />
          <LinkTo
            to={URLS_CONFIG.companyAdmin.reports.companyParticipants}
            text="View participants"
          >
            <ForwardTriangle color={COLORS_CONFIG.SKY_BLUE} />
          </LinkTo>
        </Styled.Actions>
      </Styled.Header>
      <Styled.Row>
        <FilterSection
          onChange={handleFiltersChange}
          startDate={correctStartDate}
          loading={filterChangeLoading}
          next={getNextLocation}
          locations={fetchedLocations}
          totalLocations={totalLocations}
          handleSearchLocations={handleSearchLocations}
        />
        <Styled.Stats>
          <StatisticsSection data={general} loading={filterChangeLoading} />
          <ReportsSection
            data={reportsData}
            next={getNext}
            total={totalCampaigns}
            exportRequest={downloadCampaignReport}
            getCampaignActivity={getCampaignActivity}
          />
        </Styled.Stats>
      </Styled.Row>

      <ActivitySection request={getActivity} options={activityProps} />

    </Styled.Wrapper>
  );
}

/* Page Url */

Main.path = URLS_CONFIG.companyAdmin.reports.main;

/* Page Title */

Main.title = PAGE_TITLE;

/* OverallCompanyReport type of props */

Main.propTypes = {
  // campaigns: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     founder_id: PropTypes.number,
  //     is_active: PropTypes.bool,
  //     name: PropTypes.string,
  //     created: PropTypes.string,
  //     sessions_completed: PropTypes.number,
  //     participants_actual: PropTypes.number,
  //     correct_answers: PropTypes.number,
  //   }),
  // ),
  // startDate: PropTypes,
  companyId: PropTypes.number.isRequired,
  getCompany: PropTypes.func.isRequired,
  getGeneral: PropTypes.func.isRequired,
  getActivity: PropTypes.func.isRequired,
  getCampaigns: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  getCampaignActivity: PropTypes.func.isRequired,
  downloadCampaignReport: PropTypes.func.isRequired,
  downloadCompanyReport: PropTypes.func.isRequired,
  companyName: PropTypes.string,
};

/* OverallCompanyReport default props */

Main.defaultProps = {
  // campaigns: [],
  companyName: 'No campaign name',
};

const mapState = ({
  adminCompany: {
    campaigns,
    company: { contract: { start_date: startDate } = {}, name },
  },
  auth: { company },
}) => ({
  campaigns,
  companyId: company,
  startDate,
  companyName: name,
});

const mapDispatch = {
  getCompany: AdminCompanyEntity.actions.getCompany,
  getGeneral: AdminCompanyEntity.actions.getCompanyReportsGeneral,
  getActivity: AdminCompanyEntity.actions.getcompanyReportsActivity,
  getCampaignActivity: AdminCompanyEntity.actions.getCampaignReportsActivity,
  getCampaigns: AdminCompanyEntity.actions.getCampaigns,
  getLocations: AdminCompanyEntity.actions.getLocations,
  downloadCampaignReport: AdminCompanyEntity.actions.downloadCampaignReport,
  downloadCompanyReport: AdminCompanyEntity.actions.downloadCompanyReport,
};

export default connect(mapState, mapDispatch)(Main);
