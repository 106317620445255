/* Libs */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

/* Hooks */

import {
  useOutsideClickHook,
} from 'hooks';


/* Components */

// import { CompanyAdminHeaderInfoMenuListItem } from 'components';

/* Styles */

import * as Styled from './styles';

function CompanyAdminHeaderInfoMenuList({
  children,
  onClose,
}) {
  const listRef = useRef(null);
  useOutsideClickHook(onClose, listRef);

  return (
    <Styled.List ref={listRef}>
      {children}
    </Styled.List>
  );
}

/* CompanyAdminHeaderInfoMenuList type of props */

CompanyAdminHeaderInfoMenuList.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyAdminHeaderInfoMenuList;
