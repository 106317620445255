/* Media queries config */
export default {
  /**
   * The Standard Bootstrap 4.x Breakpoints
   */
  ls: '1199px',
  md: '991px',
  sm: '768px',
  xs: '575px',

  /**
   * The Custom App Breakpoints
   */
  xmd: '1024px',
  xxs: '460px',
  xxxs: '375px',
};
