/* Libs */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Constants */

import { DATE_SETTINGS, GAME_CONFIG } from 'config';

/* Styles */

import { getStaticData } from 'utils/custom';
import * as Styled from './styles';

function LeaderboardListItem({
  place,
  team_name,
  first_answered_percentage,
  time_completed,
  status,
}) {
  const time = useMemo(() => {
    const statuses = getStaticData('gamePlayStatuses');
    return [statuses.instructions, statuses.game_play, statuses.game_failed].includes(status)
      || time_completed === null
      ? GAME_CONFIG.NOT_FINISHED
      : moment(time_completed, DATE_SETTINGS.FORMAT.HOURS_MINUTES_SECONDS)
        .format(DATE_SETTINGS.FORMAT.MINUTES_SECONDS_FULL)
        .replace('0m', '');
  }, [status, time_completed]);

  return (
    <Styled.Wrapper>
      <Styled.Place>{place}</Styled.Place>
      <Styled.Team>{team_name}</Styled.Team>
      <Styled.Value>
        {first_answered_percentage || 0}
        %
      </Styled.Value>
      <Styled.Time>{time}</Styled.Time>
    </Styled.Wrapper>
  );
}

/* LeaderboardListItem type of props */

LeaderboardListItem.propTypes = {
  place: PropTypes.number.isRequired,
  team_name: PropTypes.string.isRequired,
  first_answered_percentage: PropTypes.number,
  time_completed: PropTypes.string.isRequired,
};

/* LeaderboardListItem default props */

LeaderboardListItem.defaultProps = {};

export default LeaderboardListItem;
