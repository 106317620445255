/* Libs */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/* Actions */
import { GameEntity } from '_entities';

/* Components */
import {
  Img,
  Modal,
  AnswersList,
  Button,
  PreviewCard,
} from 'components';

/* Constants */
import { ANSWER_STATUS, MODAL_BG_COLOR } from '_constants';

/* Styles */
import { INFO_TIP, INFO_TIP_DARK } from 'assets/icons';
import { UNLOCKED, LOCKED_DARK } from 'assets/icons/locks';
import * as Styled from './styles';


// ----------------
function QuestionModal({
  onClose,
  open,
  question,
  handleAnswer,
  setQuestionAnswered,
  isDarkMode,
}) {
  const [needToShowContext, setNeedToShowContext] = useState(Boolean(
    question.security_context && question.security_context.length,
  ));
  const [needToShowIntro, setNeedToShowIntro] = useState(Boolean(
    question.intro_vimeo_id && question.intro_vimeo_id.length,
  ));

  useEffect(() => {
    const successAnswer = question.answers.find(({ status }) => status === ANSWER_STATUS.CORRECT);

    if (successAnswer && open) {
      setQuestionAnswered(question);
    }
  }, [
    question,
    question.answers,
    setQuestionAnswered,
    open,
  ]);

  if (!open) return null;

  const hasIncorrectAnswers = question.answers.some(answer => answer.status === ANSWER_STATUS.WRONG);

  const getTitle = () => {
    if (needToShowContext) return 'Security context';
    if (needToShowIntro) return 'Question intro';
    return question.name;
  };

  const titleAdditionalComponent = (
    <Styled.Icon>
      <Img src={isDarkMode ? LOCKED_DARK : UNLOCKED} size={[28, 42]} />
    </Styled.Icon>
  );

  const getTitleAdditionalComponent = () => {
    if (needToShowContext) return titleAdditionalComponent;
    if (needToShowIntro) return null;
    return titleAdditionalComponent;
  };

  const getModalContent = () => {
    if (needToShowContext) {
      return (
        <Styled.ContextWrapper isDarkMode={isDarkMode}>
          <Styled.Context>
            {question.security_context}
          </Styled.Context>
          <Button
            onClick={() => setNeedToShowContext(false)}
          >
            Confirm
          </Button>
        </Styled.ContextWrapper>
      );
    }

    if (needToShowIntro) {
      return (
        <Styled.Intro isDarkMode={isDarkMode}>
          <PreviewCard vimeoId={question.intro_vimeo_id} />
          <Button
            onClick={() => setNeedToShowIntro(false)}
          >
            Next
          </Button>
        </Styled.Intro>
      );
    }

    return (
      <>
        <Styled.QuestionModal isDarkMode={isDarkMode} hasIncorrectAnswers={hasIncorrectAnswers}>
          <Styled.Title>{question.question}</Styled.Title>
          <AnswersList
            isDarkMode={isDarkMode}
            handleAnswer={handleAnswer}
            answers={question.answers}
          />
        </Styled.QuestionModal>
        {hasIncorrectAnswers && (
          <Styled.Hint isDarkMode={isDarkMode}>
            <Img
              src={isDarkMode ? INFO_TIP_DARK : INFO_TIP}
              size={[15, 16]}
            />
            <Styled.Text>
              {question.incorrect_answer_message}
            </Styled.Text>
          </Styled.Hint>
        )}
      </>
    );
  };

  return (
    <Modal
      onlyContentCloseClick
      onClose={onClose}
      open={open}
      title={getTitle()}
      titleAdditionalComponent={getTitleAdditionalComponent()}
      style={{ width: '660px', maxWidth: '660px' }}
      backgroundColor={isDarkMode && MODAL_BG_COLOR.DARK}
    >
      { getModalContent() }
    </Modal>
  );
}

QuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  setQuestionAnswered: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool,
  question: PropTypes.shape({
    security_context: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    question: PropTypes.string,
    incorrect_answer_message: PropTypes.string,
    name: PropTypes.string,
    intro_vimeo_id: PropTypes.string,
  }).isRequired,
};

QuestionModal.defaultProps = {
  isDarkMode: false,
};

export default connect(null, {
  setQuestionAnswered: GameEntity.actions.setQuestionAnswered,
})(QuestionModal);
