/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

import { Wrapper as CellWrapper } from 'components/blocks/TableCells/StorylineCell/styles';

export const Row = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${COLORS_CONFIG.SILVER};
  height: 132px;
  padding: 25px 0px;
  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
  & > ${CellWrapper} {
    /* flex: 1; */
    width: 50%;
    &:not(:first-child) {
      border-left: 1px solid ${COLORS_CONFIG.SILVER};
      padding-left: 57px;
      padding-right: 16px;
    }
    &:not(:last-child) {
      padding-right: 57px;
    }
  }
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    height: 110px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 35px 32px;
  
  ${({ loading }) => loading && css`
      .pagination {
        visibility: hidden;
      }
  `}
  
`;

export const Rows = styled.div`
  ${({ withPagination }) => withPagination && css`
      padding-bottom: 35px;
  `}
`;
