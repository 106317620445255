/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import CircleWithSign from 'components/blocks/Markers/CircleWithSign';

/* Constants */

import { QUESTION_STATUS } from '_constants';
import { COLORS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function OpenedQuestion({
  name,
  code,
  hint,
  status,
  onClick,
  isDarkMode
}) {
  const isAnswered = status === QUESTION_STATUS.ANSWERED;
  return (
    <Styled.Wrapper isDarkMode={isDarkMode} status={status} onClick={onClick}>
      <Styled.Header>
        <Styled.Name>
          { name }
        </Styled.Name>
        <Styled.Code>
          { `Code: ${code}` }
        </Styled.Code>
      </Styled.Header>
      <Styled.Hint>
        { isAnswered && hint }
      </Styled.Hint>
      <Styled.Action>
        <CircleWithSign color={COLORS_CONFIG.WHITE} sign={isAnswered ? '✓' : ''} />
        <Styled.Label>{ isAnswered ? 'Complete!' : 'Tap to complete' }</Styled.Label>
      </Styled.Action>
    </Styled.Wrapper>
  );
}

/* OpenedQuestion type of props */

OpenedQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    QUESTION_STATUS.OPENED,
    QUESTION_STATUS.ANSWERED,
  ]),
};

/* OpenedQuestion default props */

OpenedQuestion.defaultProps = {
  icon: '',
};

export default OpenedQuestion;
