/* Libs */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

/* Actions */

import { ManageGameEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';

/* Components */

import { NumberPicker, Button, IconTooltip } from 'components';

/* Constants */

import { DATE_SETTINGS } from 'config';
import { NOTIFICATIONS } from '_constants';

/* Styles */

import { INFO_CIRCLE_BLUE } from 'assets/icons';
import * as Styled from './styles';

const { TIMER_FORMAT } = DATE_SETTINGS.FORMAT;

function EditGameTimeForm({
  loading,
  onClose,
  gameTime,
  timeLimit,
  setNewGameTime,
  needUpdateCompletedTime,
}) {
  const maxDuration = useMemo(() => moment.duration(timeLimit, TIMER_FORMAT), [timeLimit]);

  const [previousCurrentDuration] = useState(moment.duration(gameTime.format(TIMER_FORMAT), TIMER_FORMAT));
  const [duration, setDuration] = useState(() => {
    const currentTime = moment.duration(gameTime.format(TIMER_FORMAT), TIMER_FORMAT);
    return {
      currentTime,
      completedTime: moment.duration(
        maxDuration.asMilliseconds() - currentTime.asMilliseconds(),
      ),
    };
  });
  useEffect(() => {
    setDuration(prev => ({
      ...prev,
      currentTime: moment.duration(gameTime.format(TIMER_FORMAT), TIMER_FORMAT),
    }));
  }, [gameTime]);

  const saveTimeAttempt = async () => {
    try {
      const currentTime = moment.utc(0).add(duration.currentTime.asMilliseconds(), 'ms');
      await setNewGameTime({
        currentTime,
        winTime: needUpdateCompletedTime
          ? moment.utc(duration.completedTime.asMilliseconds())
          : null,
      });
      notification.success(
        needUpdateCompletedTime
          ? NOTIFICATIONS.SUCCESS_CHANGED_COMPLETED_TIME
          : NOTIFICATIONS.SUCCESS_CHANGED_CURRENT_TIME,
      );
      onClose();
    } catch (error) {
      notification.error(error);
    }
  };

  const handleChange = type => ({ target: { name, value: newValue } }) => {
    const durationName = type === 'completed' ? 'completedTime' : 'currentTime';
    const newDuration = duration[durationName].clone();
    const value = parseInt(newValue, 10);
    switch (name) {
      case 'seconds':
        newDuration.subtract(duration[durationName].seconds(), 's').add(value >= 60 ? 0 : value, 's');
        break;
      case 'minutes':
        newDuration.subtract(Math.floor(duration[durationName].asMinutes()), 'm').add(value, 'm');
        break;

      default:
        break;
    }
    if (newDuration.asMilliseconds() === 0) newDuration.add(1, 's');
    const newTime = newDuration.asMilliseconds() - maxDuration.asMilliseconds() > 0 ? maxDuration.clone() : newDuration;
    const newSecondaryTime = moment.duration(maxDuration.asMilliseconds() - newTime.asMilliseconds());

    setDuration({
      currentTime: type === 'current' ? newTime : newSecondaryTime,
      completedTime: type === 'completed' ? newTime : newSecondaryTime,
    });
  };

  const getMaxDuration = time => (Math.floor(time.asMinutes()) === Math.floor(maxDuration.asMinutes())
    ? maxDuration.seconds().toString().padStart(2, '0')
    : 60);

  const { currentTime, completedTime } = duration;

  return (
    <Styled.Wrapper>
      <Styled.Form>
        <Styled.Text>
          Current game time:
        </Styled.Text>
        <Styled.TimerWrapper>
          <NumberPicker
            max={Math.floor(maxDuration.asMinutes())}
            min={0}
            onChange={handleChange('current')}
            name="minutes"
            value={Math.floor(currentTime.asMinutes())}
          />
          <Styled.Delimitr />
          <NumberPicker
            max={getMaxDuration(currentTime)}
            min={Math.floor(currentTime.asMinutes()) === 0 ? 1 : -1}
            onChange={handleChange('current')}
            name="seconds"
            value={currentTime.seconds().toString().padStart(2, '0')}
          />
        </Styled.TimerWrapper>
      </Styled.Form>
      <Styled.Form>
        <Styled.Text>
          Completed game time:
          {!needUpdateCompletedTime && (
            <IconTooltip
              src={INFO_CIRCLE_BLUE}
              items={['Completed game time can be changed when the game will be ended']}
            />
          )}
        </Styled.Text>
        <Styled.TimerWrapper>
          <NumberPicker
            max={Math.floor(maxDuration.asMinutes())}
            min={0}
            onChange={handleChange('completed')}
            name="minutes"
            value={Math.floor(completedTime.asMinutes())}
          />
          <Styled.Delimitr />
          <NumberPicker
            max={getMaxDuration(completedTime)}
            min={Math.floor(completedTime.asMinutes()) === 0 ? 1 : -1}
            onChange={handleChange('completed')}
            name="seconds"
            value={completedTime.seconds().toString().padStart(2, '0')}
          />
        </Styled.TimerWrapper>
      </Styled.Form>
      <Button
        disabled={loading || previousCurrentDuration.asMilliseconds() === currentTime.asMilliseconds()}
        onClick={saveTimeAttempt}
        isLoading={loading}
      >
        Save
      </Button>
    </Styled.Wrapper>
  );
}

/* EditGameTimeForm type of props */

EditGameTimeForm.propTypes = {
  gameTime: PropTypes.instanceOf(moment).isRequired,
  onClose: PropTypes.func.isRequired,
  setNewGameTime: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  needUpdateCompletedTime: PropTypes.bool.isRequired,
  timeLimit: PropTypes.string.isRequired,
};

export default connect(({ manageGame, staticData }) => ({
  gameTime: manageGame.gameTime,
  loading: manageGame.loading,
  timeLimit: manageGame.gameSession.game_def.time_limit,
  needUpdateCompletedTime: [
    staticData.gamePlayStatuses.game_failed,
    staticData.gamePlayStatuses.game_success,
    staticData.gamePlayStatuses.debriefing,
    staticData.gamePlayStatuses.reset,
  ].includes(manageGame.result.status),
}), {
  setNewGameTime: ManageGameEntity.actions.setNewGameTime,
})(EditGameTimeForm);
