import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, GameStatistic } from 'components';
import { GAME_CONFIG } from 'config/index';

import * as Styled from './styles';


function GameResults({
  isDarkMode,
  seeLeaderboard,
  addPicture,
  step,
}) {
  const [isSuccess, setIsFailed] = useState(step === GAME_CONFIG.STEP.GAME_SUCCESS);
  const getResultHeader = () => (
    <>
      <Styled.Title>
        {isSuccess
          ? 'Congratulations!'
          : 'Sorry, but you failed.'}
      </Styled.Title>
      <Styled.Hint>
        {isSuccess
          ? 'You successfully completed the Living Security Escape Room.'
          : 'Come back later to try more!'}
      </Styled.Hint>
    </>
  );

  return (
    <Styled.ContentWrapper isDarkMode={isDarkMode}>
      {getResultHeader()}
      <Styled.Statistic>
        <GameStatistic isDarkMode={isDarkMode} />
      </Styled.Statistic>
      <Styled.Actions center={!isSuccess}>
        <Button onClick={seeLeaderboard}>
          See Leaderboard
        </Button>
        {
          isSuccess && (
            <Button onClick={addPicture}>
              Add picture
            </Button>
          )
        }
      </Styled.Actions>
    </Styled.ContentWrapper>
  );
}

GameResults.propTypes = {
  isDarkMode: PropTypes.bool,
  seeLeaderboard: PropTypes.func.isRequired,
  addPicture: PropTypes.func.isRequired,
};

GameResults.defaultProps = {
  isDarkMode: false,
};

export default GameResults;
