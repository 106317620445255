/* Libs */
import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, isNaN, isEmpty } from 'lodash';

/* Actions */

import { LS_AdminCompaniesEntity } from '_entities';

/* Utils */
import { NOTIFICATIONS } from '_constants';
import { notification } from 'utils/services';
import {
  getError,
  replaceQueryParams,
  redirect,
  getImpersonateLink,
  checkLastPageEmptiness,
} from 'utils/custom';


/* Components */

import {
  AdminCompaniesStruct,
  AddCompanyModal,
  CompaniesTable,
  AddCircle,
  Pagination,
  ContainerLoader,
  ToggleBar,
  SearchInput,
} from 'components';

/* Constants */

import {
  URLS_CONFIG, COMPANIES_TABLE, COMPANY_TYPES, PAGINATION,
} from 'config';

/* Styles */

// import * as Styled from './styles';


const PAGE_TITLE = 'Companies';

function Companies({
  getCompanies,
  activateCompany,
  data,
  location: { search },
  loading,
}) {
  const [stateData, setData] = useState({
    page: 1,
    search: '',
    type: COMPANY_TYPES.ACTIVE,
  });
  const [isAddCompanyModalOpen, setModalState] = useState(false);

  const toggleModal = () => setModalState(!isAddCompanyModalOpen);
  const impersonate = (company) => {
    redirect(getImpersonateLink(company));
  };

  const getAllCompanies = async ({
    currentPage = stateData.page,
    companyName = stateData.search,
    companyNameType = stateData.type,
  } = {}) => {
    if (currentPage < 0) return;
    try {
      const result = await getCompanies({
        name: companyName,
        page: currentPage,
        isActive: companyNameType === COMPANY_TYPES.ACTIVE,
      });
      if (!result.results.length && result.count) {
        setData(prev => ({
          ...prev,
          page: 1,
        }));
        getAllCompanies({ currentPage: 1, companyName: '', companyNameType });
      }
      replaceQueryParams({ page: currentPage, type: companyNameType });
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const fetchCompanies = useCallback(debounce(getAllCompanies, 300), []);

  const handleCompanyTypeChange = ({ target: { value } }) => {
    setData({
      search: stateData.search,
      page: 1,
      type: value,
    });
    getAllCompanies({ currentPage: 1, companyName: stateData.search, companyNameType: value });
  };

  const onSearchChange = ({ target: { value } }) => {
    setData(prev => ({
      ...prev,
      page: 1,
      search: value,
    }));
    fetchCompanies({ companyName: value, companyNameType: stateData.type });
  };

  const handlePageChange = ({ selected }) => {
    setData(prev => ({
      ...prev,
      page: selected + 1,
    }));
    getAllCompanies({ currentPage: selected + 1 });
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const searchParamsPage = +params.get('page');
    const queryPage = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;
    const validCompanyTypes = [COMPANY_TYPES.ACTIVE, COMPANY_TYPES.INACTIVE];
    const searchDataType = params.get('type');
    const queryType = validCompanyTypes.includes(searchDataType)
      ? searchDataType
      : stateData.type;

    setData(prev => ({
      ...prev,
      type: queryType,
      page: queryPage,
    }));
    getAllCompanies({ currentPage: queryPage, companyName: '', companyNameType: queryType });
  }, []);

  const companiesToShow = useMemo(() => (
    data.companies.filter(company => (
      company.is_active === (stateData.type === COMPANY_TYPES.ACTIVE)
    ))
  ), [data.companies, stateData.type]);

  const activate = async (companyId) => {
    try {
      await activateCompany(companyId);
      if (checkLastPageEmptiness({ total: data.total, perPage: PAGINATION.PER_PAGE, page: stateData.page })) {
        setData(prev => ({
          ...prev,
          page: prev.page - 1,
          search: '',
        }));
        getAllCompanies({ currentPage: stateData.page - 1, companyName: '' });
      } else {
        setData(prev => ({
          ...prev,
          search: '',
        }));
        getAllCompanies({ companyName: '' });
      }
      notification.success(NOTIFICATIONS.SUCCESS_COMPANY_ACTIVATION);
    } catch (e) {
      notification.error(getError(e));
    }
  };


  return (
    <AdminCompaniesStruct
      title={PAGE_TITLE}
      search={(
        <SearchInput
          name="search"
          onChange={onSearchChange}
          value={stateData.search}
          placeholder="Search a company"
        />
      )}
      actions={[
        <AddCircle
          key="Add a company"
          title="Add a company"
          onClick={toggleModal}
        />,
      ]}
      botActions={[
        (
          <ToggleBar
            name="companyType"
            key="company_type"
            onChange={handleCompanyTypeChange}
            active={stateData.type}
            options={[
              COMPANY_TYPES.ACTIVE,
              COMPANY_TYPES.INACTIVE,
            ]}
          />
        ),
      ]}
    >
      {
        loading
          ? <ContainerLoader />
          : !loading && !isEmpty(data.length)
            ? 'no results'
            : (
              <>
                <CompaniesTable
                  data={companiesToShow}
                  companyType={stateData.type}
                  pagination={(
                    <Pagination
                      pageCount={data.total / COMPANIES_TABLE.PAGINATION.PER_PAGE}
                      pageChangeHandler={handlePageChange}
                      activePage={stateData.page}
                      withInitialPage={false}
                    />
                  )}
                  actions={{ impersonate, activate }}
                />
                <AddCompanyModal
                  open={isAddCompanyModalOpen}
                  onClose={toggleModal}
                />
              </>
            )
      }
    </AdminCompaniesStruct>
  );
}

/* Page Url */

Companies.path = URLS_CONFIG.admin.companies;

/* Page Title */

Companies.title = PAGE_TITLE;

/* Companies type of props */

Companies.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.object),
  }),
};

/* Companies default props */

Companies.defaultProps = {};

export default connect(({ LS_adminCompanies }) => ({
  data: {
    companies: LS_adminCompanies.companies,
    total: LS_adminCompanies.total,
  },
  loading: LS_adminCompanies.loading,
}), {
  getCompanies: LS_AdminCompaniesEntity.actions.getAllCompanies,
  activateCompany: LS_AdminCompaniesEntity.actions.activateCompany,
})(Companies);
