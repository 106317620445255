export default {
  QUESTION_FILTERS: {
    FROM_STRONG_TO_WEAK: 'From strong to weak',
    FROM_WEAK_TO_STRONG: 'From weak to strong',
  },
  PARTICIPANT_VIEW_TYPES: {
    CALENDAR: 'calendar',
    ALL: 'all',
  },
  ACTIVITY_TYPES: {
    DAY: 'day',
    RANGE: 'range',
  },
};
