import moment from 'moment';
import getWeeksInMonth from '../getWeeksInMonth';

const createMonthObject = ({ cursorDate, startDate, endDate }) => ({
  month: cursorDate.month(),
  year: cursorDate.year(),
  weeks: getWeeksInMonth(cursorDate.month(), cursorDate.year(), {
    startDate: cursorDate.month() === startDate.month() ? startDate.toDate() : null,
    endDate: cursorDate.month() === endDate.month() ? endDate.toDate() : null,
  }),
});

export default (startDateStr, endDateStr) => {
  const startDate = moment(startDateStr);
  const endDate = moment(endDateStr);
  const cursorDate = startDate.clone();

  const months = [];
  while (cursorDate.month() !== endDate.month()) {
    months.push(createMonthObject({ cursorDate, startDate, endDate }));
    cursorDate.add(1, 'month');
  }

  if (cursorDate.month() === endDate.month()) {
    months.push(createMonthObject({ cursorDate, startDate, endDate }));
  }

  return months;
};
