/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import { Modal, SuccessLetterSend } from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function SuccessresetEmail({
  onClose,
  open,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title={null}
      open={open}
    >
      <Styled.Modal>
        <SuccessLetterSend onOk={onClose} />
      </Styled.Modal>
    </Modal>
  );
}

// Type of props
SuccessresetEmail.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
SuccessresetEmail.defaultProps = {};
