/* Libs */
import styled from 'styled-components';

import {
  Button,
} from 'components/ui/Button/styles';

import {
  Wrapper as InputWrapper,
  Input,
} from 'components/ui/Input/styles';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & ${InputWrapper}:first-child {
    margin-bottom: 24px;
  }
  & ${Button} {
    margin-top: 48px;
    width: 193px;
  }
`;

export const Reset = styled.div`
  padding-top: 26px;
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.WHITE};
  & > span {
    color: ${COLORS_CONFIG.SKY_BLUE};
    cursor: pointer;
  }
`;
