/* Libs */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  TRIANGLE_TYPES,
  LABEL_COLOR_TYPES,
  MODAL_BG_COLOR
} from '_constants';

/* Components */

import {
  Modal, InviteParticipantsSection, ParticipantsInvitedSuccess, GoLabel,
} from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function InviteParticipantsModal({
  onClose,
  open,
  modalType,
  MODAL_TYPES,
  ...restProps
}) {
  if (!open) return null;

  const [isLinkOpen, setLinkState] = useState(false);
  const toggleLink = () => setLinkState(!isLinkOpen);

  return (
    <Modal
      onClose={onClose}
      title={modalType === MODAL_TYPES.REGULAR ? 'Invite participants' : null}
      open={open}
      // onlyContentCloseClick
      backgroundColor={modalType !== MODAL_TYPES.REGULAR && MODAL_BG_COLOR.BLUE}
      titleAdditionalComponent={modalType === MODAL_TYPES.REGULAR
        ? (
          <GoLabel
            text="Shareable link"
            triangleType={TRIANGLE_TYPES[`FORWARD_${isLinkOpen ? 'TOP' : 'DOWN'}`]}
            color={LABEL_COLOR_TYPES.BLUE}
            click={toggleLink}
          />
        )
        : null
      }
    >
      <Styled.InviteParticipantsModal>
        {
          modalType === MODAL_TYPES.REGULAR
            ? (
              <InviteParticipantsSection
                onClose={onClose}
                isLinkOpen={isLinkOpen}
                {...restProps}
              />
            )
            : (
              <ParticipantsInvitedSuccess
                onClose={onClose}
                {...restProps}
              />
            )
        }
      </Styled.InviteParticipantsModal>
    </Modal>
  );
}

// Type of props
InviteParticipantsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
InviteParticipantsModal.defaultProps = {};
