/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  AVAILABLE_COLORS,
  COLORS_CONFIG,
} from 'config';


/* Styles */
import Triangle from './styles';

function TopTriangle({ color }) {
  return <Triangle color={color} />;
}

TopTriangle.propTypes = {
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
};

TopTriangle.defaultProps = {
  color: COLORS_CONFIG.SMOOTH_BROWN,
};

export default TopTriangle;
