export const RESET_COMPANY_STATE = '@PROJECT_NAME/RESET_COMPANY_STATE';

export const GET_COMPANY_BY_ID = '@PROJECT_NAME/GET_COMPANY_BY_ID';
export const GET_COMPANY_BY_ID_SUCCESS = '@PROJECT_NAME/GET_COMPANY_BY_ID_SUCCESS';
export const GET_COMPANY_BY_ID_FAIL = '@PROJECT_NAME/GET_COMPANY_BY_ID_FAIL';

export const UPDATE_COMPANY_CONTRACT = '@PROJECT_NAME/UPDATE_COMPANY_CONTRACT';
export const UPDATE_COMPANY_CONTRACT_SUCCESS = '@PROJECT_NAME/UPDATE_COMPANY_CONTRACT_SUCCESS';
export const UPDATE_COMPANY_CONTRACT_FAIL = '@PROJECT_NAME/UPDATE_COMPANY_CONTRACT_FAIL';

export const UPDATE_COMPANY_LICESES_COUNT = '@PROJECT_NAME/UPDATE_COMPANY_LICESES_COUNT';
export const UPDATE_COMPANY_LICESES_COUNT_SUCCESS = '@PROJECT_NAME/UPDATE_COMPANY_LICESES_COUNT_SUCCESS';
export const UPDATE_COMPANY_LICESES_COUNT_FAIL = '@PROJECT_NAME/UPDATE_COMPANY_LICESES_COUNT_FAIL';

export const UPDATE_POC = '@PROJECT_NAME/UPDATE_POC';
export const UPDATE_POC_SUCCESS = '@PROJECT_NAME/UPDATE_POC_SUCCESS';
export const UPDATE_POC_FAIL = '@PROJECT_NAME/UPDATE_POC_FAIL';

export const RESEND_COMPANY_ADMIN_INVITE = '@PROJECT_NAME/RESEND_COMPANY_ADMIN_INVITE';
export const RESEND_COMPANY_ADMIN_INVITE_SUCCESS = '@PROJECT_NAME/RESEND_COMPANY_ADMIN_INVITE_SUCCESS';
export const RESEND_COMPANY_ADMIN_INVITE_FAIL = '@PROJECT_NAME/RESEND_COMPANY_ADMIN_INVITE_FAIL';

export const DELETE_COMPANY = '@PROJECT_NAME/DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = '@PROJECT_NAME/DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = '@PROJECT_NAME/DELETE_COMPANY_FAIL';

export const CHANGE_COMPANY_ACTIVITY = '@PROJECT_NAME/CHANGE_COMPANY_ACTIVITY';
export const CHANGE_COMPANY_ACTIVITY_SUCCESS = '@PROJECT_NAME/CHANGE_COMPANY_ACTIVITY_SUCCESS';
export const CHANGE_COMPANY_ACTIVITY_FAIL = '@PROJECT_NAME/CHANGE_COMPANY_ACTIVITY_FAIL';


export const GET_UNIQUE_COMPANY_NAME = '@PROJECT_NAME/GET_UNIQUE_COMPANY_NAME';
export const GET_UNIQUE_COMPANY_NAME_SUCCESS = '@PROJECT_NAME/GET_UNIQUE_COMPANY_NAME_SUCCESS';
export const GET_UNIQUE_COMPANY_NAME_FAIL = '@PROJECT_NAME/GET_UNIQUE_COMPANY_NAME_FAIL';
