/* Libs */
import styled from 'styled-components';

/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

// Component styles
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
`;

export const Link = styled.div`
  width: 0;
  flex-grow: 1;
  display: block;
  padding: 0 16px;
  border: 0;
  background: none;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &[disabled] {
    background: none;
  }
  &::-webkit-scrollbar{
    width: 0;
    height:0;
  } 
  &:focus{
    overflow-x: visible;
    text-overflow: initial;
  }
`;

export const Button = styled.button`
  padding: 0 25px;
  height: 48px;
  background: ${COLORS_CONFIG.SKY_BLUE};
  border: none;
  color: ${COLORS_CONFIG.WHITE};
  font-size: 12px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};

  &:hover {
    opacity: .5;
  }

  &[disabled] {
    opacity: .5;
    cursor: default;
  }
`;
