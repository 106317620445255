/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LS_AdminCompanyEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';
import { getError, hasPermission } from 'utils/custom';


/* Components */

import {
  Card,
  GoLabel,
  EditPOCModal,
} from 'components';

/* Constants */

import {
  NOTIFICATIONS,
  TRIANGLE_TYPES,
  LABEL_COLOR_TYPES,
  PERMISSION_NAMES,
} from '_constants';

/* Styles */

import * as Styled from './styles';

function PointOfContactCard({
  resendInvite,
  first_name,
  last_name,
  email,
  id,
  isImpersonatedAdmin,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const resendAdminInvite = async () => {
    try {
      await resendInvite({ user: id });
      notification.success(NOTIFICATIONS.COMPANY_ADMIN_SUCCESS_RESEND_INVITE);
    } catch (error) {
      notification.error(getError(error));
    }
  };
  return (
    <Styled.Wrapper>
      <Card
        title="Point of contact"
        actions={hasPermission(PERMISSION_NAMES.EDIT_POINT_OF_CONTACT) && !isImpersonatedAdmin
          ? [{
            label: 'Edit',
            action: () => setIsOpen(true),
          }]
          : []
        }
      >
        <Styled.Name>
          {`${first_name || ''} ${last_name || ''}`}
        </Styled.Name>
        <Styled.Email>
          {email}
        </Styled.Email>
        {
          hasPermission(PERMISSION_NAMES.RESEND_INVITE) && (
            <GoLabel
              text="Resend invite"
              triangleType={TRIANGLE_TYPES.FORWARD}
              color={LABEL_COLOR_TYPES.BROWN}
              click={resendAdminInvite}
            />
          )
        }
      </Card>
      <EditPOCModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        data={{
          first_name,
          last_name,
          email,
          id,
        }}
      />
    </Styled.Wrapper>
  );
}

/* PointOfContactCard type of props */

PointOfContactCard.propTypes = {
  first_name: PropTypes.string.isRequired,
  resendInvite: PropTypes.func.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isImpersonatedAdmin: PropTypes.bool,
};

PointOfContactCard.defaultProps = {
  isImpersonatedAdmin: false,
};

export default connect(null, {
  resendInvite: LS_AdminCompanyEntity.actions.resendCompanyAdminInvite,
})(PointOfContactCard);
