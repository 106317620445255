/* Libs */
import styled, { css } from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/* Styles */
import { default as Triangle } from 'components/blocks/Markers/TopTriangle/styles';
import { Wrapper as RulesTooltipWrapper } from 'components/blocks/ui/RulesTooltip/styles';
import { Wrapper as ImgWrapper } from 'components/blocks/ui/Img/styles';
import { TOOLTIP_POSITION } from '_constants';

export const Error = styled.div`
  color: ${({ dark }) => (dark ? COLORS_CONFIG.RED_DARK : COLORS_CONFIG.ORANGE)};;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc( 100% + 2px );
  left: 0;
  width: 100%;
  display: none;
`;

export const Title = styled.div`
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 1;
  padding: 2px 5px;
  background: ${COLORS_CONFIG.WHITE};
  font-size: 11px;
`;

export const Optional = styled.div`
  position: absolute;
  top: -10px;
  right: 20px;
  z-index: 1;
  padding: 2px 5px;
  background: ${COLORS_CONFIG.WHITE};
  font-size: 11px;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  padding: 15px 34px 15px 24px;
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
  ${({ disabled }) => disabled && css`
    color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
    &:focus,
    &:active {
      & ~ ${RulesTooltipWrapper} {
        display: none;
      }
    }
  `}
`;

export const Wrapper = styled.div`
  position:relative;
  width: 100%;
  min-height: 48px;
  ${({ hasError, dark }) => hasError && css`
    ${Input} {
      border-color: ${dark ? COLORS_CONFIG.RED_DARK : COLORS_CONFIG.ORANGE};
      color: ${dark ? COLORS_CONFIG.RED_DARK : COLORS_CONFIG.ORANGE};
    }
    ${Error} {
      display: block;
    }
  `}
  ${({ hasError }) => hasError === false && css`
    ${Input} {
      border-color: ${COLORS_CONFIG.LIGHT_GREEN};
    }
  `}
  ${({ hasRules }) => hasRules && css`
    ${Input} {
      padding: 15px 44px 15px 24px;
    }
  `}
  
  ${({ disabled }) => disabled && css`
      pointer-events: none;
      
      ${Title} {
        color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
      }
  
      ${Input} {
        border-color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
        background: ${COLORS_CONFIG.TRANSPARENT};
      }
   `}
  
  & > ${ImgWrapper} {
    position: absolute;
    right: 14px;
    top: 15px;
  }
  
  ${({ rulesPosition }) => {
    switch (rulesPosition) {
      case TOOLTIP_POSITION.BOTTOM:
        return css`
             & ${RulesTooltipWrapper} {
               left: calc( 100% - 52px );
               position: absolute;
               z-index: 5;
               top: 67px;
             }
           `;
      case TOOLTIP_POSITION.RIGHT:
        return css`
             & ${RulesTooltipWrapper} {
               left: calc( 100% + 20px );
               position: absolute;
               z-index: 5;
               top: 0px;
             }
             
             & ${Triangle} {
                 position: absolute;
                 left: -9px;
                 top: 13px;
                 z-index: 5;
                 border-width: 9px 10px 9px 0;
                 border-color: transparent ${COLORS_CONFIG.WHITE} transparent transparent;
               }
           `;
      default:
        return css`
         & ${RulesTooltipWrapper} {
           left: calc( 100% - 52px );
           position: absolute;
           z-index: 5;
           top: 67px;
         }
       `;
    }
  }}
`;
