/* Libs */
import styled from 'styled-components';


export const EditGameTimeModal = styled.div`
  height: 100%;
`;

export const modalStyles = {
  width: '296px',
  minWidth: '296px',
  height: '420px',
  minHeight: '249px',
  padding: '21px',
  overflow: 'visible',
};
