/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { GameEntity } from '_entities';

/* Components */

import {
  // Timer,
  Logo,
} from 'components';
import {
  // GAME_CONFIG,
  LOGO_CONFIG,
} from 'config';

/* Styles */

import * as Styled from './styles';

function BeforeGameStruct({
  children,
  isDarkMode,
  // changeStep,
  // introductionTime,
}) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <Styled.Container>
        <Logo type={isDarkMode ? LOGO_CONFIG.HORIZONTAL_BLUE_WHITE : LOGO_CONFIG.HORIZONTAL_BLUE_BLACK} />
        <Styled.Content>
          {children}
        </Styled.Content>
      </Styled.Container>
      <Styled.TimerWrapper>
        {/* <Timer
          time={introductionTime}
          onExpired={ () => changeStep(GAME_CONFIG.STEP.GAME_PLAY)}
        /> */}
      </Styled.TimerWrapper>
    </Styled.Wrapper>
  );
}

/* BeforeGameStruct type of props */

BeforeGameStruct.propTypes = {
  children: PropTypes.node.isRequired,
  // introductionTime: PropTypes.string.isRequired,
  // changeStep: PropTypes.func.isRequired,
};

export default connect(
  ({ game }) => ({
    introductionTime: game.gameSession.introduction_time,
  }),
  {
    changeStep: GameEntity.actions.changeStep,
  },
)(BeforeGameStruct);
