/* Libs */
import styled, { css } from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';
import {
  Wrapper as InputWrapper,
} from 'components/ui/Input/styles';

import { CustomScroll, TextEllipsis } from 'assets/styles/mixins';
import { Wrapper as TooltipWrapper } from 'components/blocks/ui/IconTooltip/styles';
import { Wrapper as LogoWrapper } from 'components/blocks/ui/Logo/styles';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 80px 45px;
  background: ${COLORS_CONFIG.WHITE};
  ${LogoWrapper} {
    margin-bottom: 25px;
    width: 110px;
    align-self: center;
  }
  ${BigTitle} {
    margin-bottom: 25px;
    text-align: center;
  }
`;

export const Data = styled.div`
  margin-bottom: 25px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}
`;

export const TitleWrapper = styled.div`
  margin: 0 0 12px;
`;

export const Title = styled.span`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const LinkWrapper = styled.div`
  margin: 0 0 14px;
`;

export const Link = styled.span`
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
  margin-left: 5px;
`;

export const HostBox = styled.div`
  margin: 0 0 15px;
  display: flex;
  align-items: center;
`;

export const HostEmail = styled.div`
    max-width: 100%;
    ${TextEllipsis}
`;
export const HostLabel = styled.span`
  color: ${COLORS_CONFIG.SMOOTH_LIGHT_BROWN};
  margin-left: 5px;
`;

export const ParticipantsForm = styled.form`
  margin: 0 0 20px;
    
  ${InputWrapper} {
    margin-top: 0;
  }
`;

export const ParticipantsList = styled.div`
  height: 100px;
  overflow: auto;
  padding-right: 10px;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & ${TooltipWrapper} {
    padding-left: 0;
    margin-right: 2px;
  }
`;

export const EmailText = styled.div`
  flex-grow: 1;
  margin-right: 10px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 16px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeleteEmail = styled.div`
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 2px solid ${COLORS_CONFIG.SKY_BLUE};
  border-radius: 50%;
  cursor: pointer;

  &:before,
  &:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 2px;
    background: ${COLORS_CONFIG.SKY_BLUE};
    content: '';
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const AddEmail = styled.button`
  margin-left: auto;
  padding-top: 12px;
  width: max-content;
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
  border: none;
  outline: none;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  font-size: 12px;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: .5;
  `}
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
