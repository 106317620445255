import moment from 'moment';
import { DATE_SETTINGS } from 'config';
import { NOTIFICATIONS } from '_constants';

export default function (dateFrom, dateTo, currentFormat = DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY, newFormat) {
  if (moment(dateFrom).isAfter(moment(dateTo))) {
    throw new Error(NOTIFICATIONS.DATE_FROM_CANT_BE_AFTER_DATE_TO);
  }
  const toFormat = newFormat || currentFormat;
  let currentDate = moment(dateFrom, currentFormat).format(toFormat);
  const datesArray = [currentDate];
  const formattedTo = moment(dateTo).format(toFormat);

  while (currentDate !== formattedTo) {
    const newDate = moment(currentDate, currentFormat)
      .add(1, 'day')
      .format(toFormat);

    datesArray.push(newDate);
    currentDate = newDate;
  }
  return datesArray;
}
