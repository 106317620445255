/* Libs */
import React from 'react';
import moment from 'moment';

import { DATE_SETTINGS } from 'config';

import * as Styled from './styles';

function VersionControl() {
  return (
    <Styled.VersionControl>
      {`Version: ${moment(__COMMIT_DATE__).format(
        DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR_HYPHEN,
      )}, ${__COMMIT_HASH__}`}
    </Styled.VersionControl>
  );
}

export default VersionControl;
