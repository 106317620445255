import styled from 'styled-components';

import { FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  padding: 44px 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;

export const Description = styled.div`
  font-size: 20px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  margin-bottom: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const CancelButton = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  margin-left: 44px;
  line-height: 48px;
`;
