/* Libs */
import styled from 'styled-components';

/* Constants */
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Text = styled.div`
  margin: auto;
  padding: 20px 30px;
  max-width: 450px;
  background-color: ${COLORS_CONFIG.WHITE};
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  border-radius: 8px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
`;

export const Link = styled.a`
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  text-decoration: underline;
`;
