/* Libs */
import styled, { css } from 'styled-components';
import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';
import { CustomScroll } from 'assets/styles/mixins';

export const Icon = styled.div`
  width: 64px;
  margin-top: 33px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Name = styled.div`
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  padding-bottom: 14px;
  padding: 22px;
  word-break: break-word;
  text-align: center;
`;
export const Line = styled.div`
  width: 24px;
  height: 2px;
  background-color: ${COLORS_CONFIG.SILVER_CUSTOM_1}; 
`;
export const Tip = styled.div`
  padding-top: 16px;
  font-size: 14px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${COLORS_CONFIG.WHITE};
  & > * {
    color: ${COLORS_CONFIG.DARK_GREY};
  }
  overflow-y: auto;
  padding: 15px;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}
  
  ${({ isDarkMode }) => isDarkMode && css`
        background-color: ${COLORS_CONFIG.NAVY_BLUE};
        
        & > * {
            color: ${COLORS_CONFIG.WHITE};
        }
        
        & ${Line}{
            background-color: ${COLORS_CONFIG.WHITE};           
        }
        
        ${() => CustomScroll({
    scrollBG: 'transparent',
    thumbScrollBG: COLORS_CONFIG.OXFORD_BLUE,
  })}
        
   `}
`;
