/* Libs */
import styled from 'styled-components';

/* Constants */

import { FONTS_CONFIG } from 'config';

/* Styles */

import { Select } from 'components/ui/Select/styles';


export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 500px;

  & ${Select} {
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  }
`;
