/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LS_AdminSettingsEntity } from '_entities';

/* Utils */
import { validateEmail, validateText } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { getError, getStaticData } from 'utils/custom';
import { notification } from 'utils/services';

/* Components */
import {
  Input,
  Button,
} from 'components';

/* Styles */
import ContainerLoader from 'components/blocks/ui/ContainerLoader';
import { NOTIFICATIONS } from '_constants';
import * as Styled from './styles';

const INITIAL_DATA = {
  first_name: null,
  last_name: null,
  email: null,
};

const DEFAULT_ERRORS = {
  first_name: null,
  last_name: null,
  email: null,
};


function AddAdminForm({
  onClose,
  addUser,
  clearSearchOnRequest,
}) {
  const [formData, setFormData] = useState({
    data: INITIAL_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };

    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: 64,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: 254,
        });
        break;
      default:
        break;
    }

    setFormData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email']),
    });
  };

  const handleEmailInput = ({ target: { name, value } }) => handleChange({
    target: {
      name,
      value,
    },
  });

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: 64,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: 254,
        });
        break;
      default:
        break;
    }
    setFormData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email']),
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const { data } = formData;
      await addUser({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role: getStaticData('roles').admin,
      });
      await clearSearchOnRequest();
      notification.success(NOTIFICATIONS.SUCCESS_ADD_USER);
      onClose();
    } catch (error) {
      notification.error(getError(error));
      setLoading(false);
    }
  };

  const {
    data: {
      first_name,
      last_name,
      email,
    },
    errors,
  } = formData;

  return (
    <Styled.Wrapper>
      <Styled.Data>
        <Styled.Info>
          <Input
            name="first_name"
            title="First name"
            placeholder="First name"
            value={first_name}
            error={errors.first_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            name="last_name"
            title="Last name"
            placeholder="Last name"
            value={last_name}
            error={errors.last_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            type="email"
            name="email"
            title="Email"
            placeholder="Email"
            value={email}
            error={errors.email}
            onChange={handleEmailInput}
            onBlur={validateOnBlur}
          />
        </Styled.Info>
      </Styled.Data>
      <Styled.Actions>
        <Button disabled={!formData.canSubmit} onClick={handleSave}>
          Add LS Admin
        </Button>
      </Styled.Actions>
      {loading && <ContainerLoader />}
    </Styled.Wrapper>
  );
}

AddAdminForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
};


export default connect(null, {
  addUser: LS_AdminSettingsEntity.actions.addUser,
})(AddAdminForm);
