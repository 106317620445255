/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Wrapper as ImageWrapper } from 'components/blocks/ui/Img/styles';
import {
  Tooltip,
  Content as TooltipContent,
} from 'components/blocks/ui/Tooltip/styles';
import { default as Triangle } from 'components/blocks/Markers/BackTriangle/styles';


export const Cell = styled.div`
  position: relative;
  padding-right: 20px;
  ${ImageWrapper} {
    cursor: pointer;
  }
  ${Tooltip} {
    top: -2px;
    left: 23px;
    z-index: 2;
  }
  ${TooltipContent} {
    padding: 5px 10px;
    width: initial;
    min-width: 150px;
    max-width: 270px;
    min-height: 60px;
  }
  ${Triangle} {
    position: absolute;
    top: 3px;
    left: -13px;
    border-width: 5px 7px 5px 8px;
    border-color: transparent ${COLORS_CONFIG.WHITE} transparent transparent ;
  }
`;

export const CellInfo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CellText = styled.div`
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 12px;
`;

export const CellTooltipText = styled.div`
  margin-bottom: 5px;
  font-size: 11px;
  text-align: center;
  white-space: normal;
  &:last-child {
    margin-bottom: 0;
  }
`;
