/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Button } from 'components/ui/Button/styles';


export const Title = styled.div`
  font-size: 32px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.WHITE};
  padding-top: 32px;
`;
export const Text = styled.div`
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.WHITE};
  padding-top: 30px;
  padding-bottom: 75px;
  word-break: break-word;
`;

export const Icon = styled.div`
  width: 64px;
  height: 64px;
  color: ${COLORS_CONFIG.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SuccessAnsweredQuestionModal = styled.div`
  padding: 80px;
  width: 660px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & ${Button} {
    min-width: 160px;
    color: ${COLORS_CONFIG.SKY_BLUE};
    background: ${COLORS_CONFIG.WHITE};
  }
  
  ${({ isDarkMode }) => isDarkMode && css`
       background: ${COLORS_CONFIG.DARK_BLUE};
       
       & ${Button} {
         min-width: 160px;
         color: ${COLORS_CONFIG.NAVY_BLUE};
         background: ${COLORS_CONFIG.WHITE};
       }
  `}
`;
