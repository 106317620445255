import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const DeleteCompanyButton = ({ onClick }) => (
  <Styled.Wrapper onClick={onClick}>
    Delete company
  </Styled.Wrapper>
);

DeleteCompanyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DeleteCompanyButton;
