/* Libs */
import styled, { css } from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/* Styles */

import { Wrapper as DatePickerWrapper } from 'components/ui/DatePicker/styles';
import { Input as DatePickerInput } from 'components/ui/MaskInput/styles';
import { Input as InputWrapper } from 'components/ui/Input/styles';
import { Button } from 'components/ui/Button/styles';
import {
  Shape as CheckboxShape,
  Wrapper as CheckboxWrapper,
  Label as CheckboxLabel,
  HtmlLabel as CheckboxLabelWrapper,
} from 'components/ui/Checkbox/styles';
import { CustomScroll } from 'assets/styles/mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 350px;
  width: 100%;
  min-height: 403px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px;
  background-color: #fff;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  justify-content: space-between;
  & ${InputWrapper} {
    background: none;
  }
  & ${Button} {
    align-self: center;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
`;

export const Title = styled.h1`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 20px;
  line-height: 24px;
`;

export const Dates = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;

  & ${DatePickerWrapper} {
    background: none;

    & ${DatePickerInput} {
      height: 48px;
      padding: 0;
      padding-left: 11px;
      background: none;
    }
  }
`;

export const Delimiter = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 14px;
  height: 1px;
  margin: 0 5px;
  background-color: ${COLORS_CONFIG.SKY_BLUE};
`;

export const Locations = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 7px;
`;

export const CheckboxList = styled.div`
  height: calc(100% - 112px);
  margin-top: 15px;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ empty }) => empty && css`
    justify-content: center;
  `}
  
  & .infinite-scroll-component{
      ${() => CustomScroll({ scrollBG: 'transparent' })}
  }
  
  .infinite-scroll-component__outerdiv{
    width: 100%;
    margin: 0 0 10px;
  }
  
  & ${CheckboxWrapper} {
    margin-bottom: 17px;
  }
  & ${CheckboxShape} {
    width: 19px;
    height: 19px;
    border-radius: 2px;
    border-color: ${COLORS_CONFIG.SMOOTH_BROWN};
  }
  ${CheckboxLabel} {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${CheckboxLabelWrapper} {
    width: fit-content;
    max-width: 100%;
  }
`;

export const EmptyText = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  text-align: center;
`;
