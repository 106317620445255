/* Libs */
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

/* Components */
import {
  CircleWithSign,
  BackSign,
  FrontSign,
} from 'components';

/* Constants */

import { PAGINATION, COLORS_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';


// ----------------
function Pagination({
  pageCount,
  activePage,
  pageChangeHandler,
  withInitialPage,
  isDarkMode,
}) {
  if (pageCount <= 1) return null;
  return (
    <Styled.Pagination isDarkMode={isDarkMode}>
      <ReactPaginate
        containerClassName="pagination"
        marginPagesDisplayed={PAGINATION.MARGIN}
        pageRangeDisplayed={PAGINATION.RANGE}
        activeClassName="active"
        breakClassName="break-me"
        previousLabel={<CircleWithSign color={isDarkMode ? COLORS_CONFIG.LIGHT_BLUE_GRADIENT_2 : COLORS_CONFIG.SMOOTH_BROWN} sign={<BackSign />} />}
        onPageChange={pageChangeHandler}
        breakLabel={<div>...</div>}
        nextLabel={<CircleWithSign color={isDarkMode ? COLORS_CONFIG.LIGHT_BLUE_GRADIENT_2 : COLORS_CONFIG.SMOOTH_BROWN} sign={<FrontSign />} />}
        forcePage={activePage ? activePage - 1 : 0}
        pageCount={Math.ceil(pageCount)}
        {...activePage > 1 && withInitialPage && { initialPage: activePage - 1 }}
      />
    </Styled.Pagination>
  );
}

// Type of props
Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageChangeHandler: PropTypes.func.isRequired,
  withInitialPage: PropTypes.bool,
  isDarkMode: PropTypes.bool,
};

Pagination.defaultProps = {
  withInitialPage: true,
  isDarkMode: false,
};

export default memo(Pagination);
