import Introduction from './Introduction';
import Instructions from './Instructions';
import Debriefing from './Debriefing';
import Storyline from './Storyline';
import Reset from './Reset';

export default {
  Introduction,
  Instructions,
  Debriefing,
  Storyline,
  Reset,
};
