/* Libs */
import styled from 'styled-components';

import { Logo } from 'components/blocks/ui/Logo/styles';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  & ${Logo} {
    width: 175px;
  }
`;

export const Title = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 48px;
  color: ${COLORS_CONFIG.WHITE};
  padding-top: 35px;
  text-align: center;
`;

export const Content = styled.div`
  padding-top: 50px;
  width: 400px;
  margin: 0 auto;
`;
