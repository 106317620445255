/* Libs */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  EndGameStruct,
  ContainerLoader,
  Img,
  EndGameSection,
  ConfirmBlock,
} from 'components';
import { GameEntity, StaticDataEntity } from '_entities';
import { redirect } from 'utils/custom';
import {
  EXIT_BLACK, EXIT_WHITE, ARROW_LEFT_BLACK, ARROW_LEFT_WHITE,
} from 'assets/icons';
import { isEmpty } from 'utils/validation';
import { GAME_CONFIG, NOTIFICATIONS_CONFIG, URLS_CONFIG } from 'config';
import { notification } from 'utils/services';
import * as Styled from './styles';


function EndGame({
  loading,
  isDarkMode,
  hasAfterGamePart,
  changeStep,
  token,
  campaignId,
  step,
  setStaticDataDarkMode,
}) {
  const [localStep, setLocalStep] = useState(1);

  const getFinishStep = () => {
    if (hasAfterGamePart) {
      changeStep(GAME_CONFIG.STEP.DEBRIEFING);
    } else {
      setStaticDataDarkMode(false);
      redirect(URLS_CONFIG.auth.signin);
    }
  };

  const seeLeaderboard = () => setLocalStep(3);
  const addPicture = () => setLocalStep(2);
  const backToCongratulation = () => setLocalStep(1);


  const handleExitGame = () => notification.warning(NOTIFICATIONS_CONFIG.confirmExitGame(
    () => getFinishStep(),
    notification.removeAllNotifications,
  ));

  function getMainContent() {
    switch (localStep) {
      case 1:
        return (
          <EndGameSection.GameResults
            isDarkMode={isDarkMode}
            seeLeaderboard={seeLeaderboard}
            addPicture={addPicture}
            step={step}
          />
        );
      case 2:
        return (
          <EndGameSection.TeamPhoto
            isDarkMode={isDarkMode}
            backToCongratulation={backToCongratulation}
          />
        );
      case 3:
        return (
          <EndGameSection.Leaderboard
            token={token}
            campaignId={campaignId}
            isDarkMode={isDarkMode}
          />
        );
      default:
        break;
    }
  }

  function getLeftComponent() {
    switch (localStep) {
      case 1:
        return (
          <Styled.Exit isDarkMode={isDarkMode} onClick={handleExitGame}>
            <Img disabled size={[17, 13]} src={isDarkMode ? EXIT_WHITE : EXIT_BLACK} />
            Exit Game
          </Styled.Exit>
        );
      case 2:
      case 3:
        return (
          <Styled.Back isDarkMode={isDarkMode} onClick={backToCongratulation}>
            <Img disabled size={[20, 13]} src={isDarkMode ? ARROW_LEFT_WHITE : ARROW_LEFT_BLACK} />
            Back
          </Styled.Back>
        );
      default:
        break;
    }
  }


  return (
    <EndGameStruct
      isDarkMode={isDarkMode}
      leftSideHeader={getLeftComponent()}
    >
      {loading
        ? <ContainerLoader />
        : getMainContent()
      }
    </EndGameStruct>
  );
}

export default connect(({ game }) => ({
  token: game.campaignToken,
  campaignId: game.gameSession.campaign,
  isDarkMode: game.gameDef.is_dark_mode,
  loading: game.loading,
  hasAfterGamePart: !isEmpty(game.gameDef.debriefing_text)
     || !isEmpty(game.gameDef.reset_text)
     || !isEmpty(game.gameDef.debriefing_vimeo_id)
     || !isEmpty(game.gameDef.reset_vimeo_id)
     || !isEmpty(game.gameDef.debriefing_title)
     || !isEmpty(game.gameDef.reset_title),
}), {
  changeStep: GameEntity.actions.changeStep,
  setStaticDataDarkMode: StaticDataEntity.actions.setDarkMode,
})(EndGame);
