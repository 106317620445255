/* Libs */
import React from 'react';

/* Utils */
import { tokenService } from 'utils/services';

/* Components */
import { Button } from 'components';

/* Styles */
import * as Styled from './styles';


function ExpiredLicenseMessage({

}) {
  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Title>Your license has expired!</Styled.Title>
        <Styled.Text>To continue using service, please call Living Security.</Styled.Text>
        <Button onClick={tokenService.removeToken}>Logout</Button>
      </Styled.Content>
    </Styled.Wrapper>
  );
}

ExpiredLicenseMessage.propTypes = {
};

ExpiredLicenseMessage.defaultProps = {
};

export default ExpiredLicenseMessage;
