/* Libs */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/* Constants */
import { STRUCT_IDS } from '_constants';

/* Components */
import {
  TableActionSidebar,
  EditSessionForm,
} from 'components';
import { useValidationOnBlurHook } from 'hooks';

/* Styles */
import * as Styled from './styles';

export default function EditSessionSidebar({
  open,
  title,
  onClose,
  id,
  ...restProps
}) {
  const [confirmSidebar, setConfirmSidebarState] = useState(false);

  const handleClose = () => {
    setConfirmSidebarState(false);
    onClose();
  };

  const validationProps = useValidationOnBlurHook();

  if (!open) return null;
  return (
    <TableActionSidebar
      open={open}
      title={title}
      onClose={handleClose}
      id={id}
      showTitle={!confirmSidebar}
    >
      <Styled.EditSessionSidebar>
        <EditSessionForm
          {...restProps}
          onClose={handleClose}
          confirmSidebar={confirmSidebar}
          setConfirmSidebarState={setConfirmSidebarState}
          validationProps={validationProps}
        />
      </Styled.EditSessionSidebar>
    </TableActionSidebar>
  );
}

// Type of props
EditSessionSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

// Default value for props
EditSessionSidebar.defaultProps = {
  id: STRUCT_IDS.COMPANY_ADMIN_PAGE,
};
