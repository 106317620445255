/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  BackTriangle,
  ForwardTriangle,
  DownTriangle,
  TopTriangle,
  // LinkTo,
} from 'components';

/* Constants */

import {
  TRIANGLE_TYPES,
  LABEL_COLOR_TYPES,
} from '_constants';

/* Styles */

import { Link } from 'react-router-dom';
import * as Styled from './styles';

function GoLabel({
  triangleType,
  text,
  click,
  color,
  to,
}) {
  return (
    <Styled.Wrapper as={to ? Link : Styled.Wrapper} to={to} color={color} onClick={click}>
      {triangleType === TRIANGLE_TYPES.BACK && <BackTriangle />}
      {triangleType === TRIANGLE_TYPES.BACK_TOP && <TopTriangle />}
      {triangleType === TRIANGLE_TYPES.BACK_DOWN && <DownTriangle />}
      <Styled.Label triangleType={triangleType}>
        {text}
      </Styled.Label>
      {triangleType === TRIANGLE_TYPES.FORWARD && <ForwardTriangle />}
      {triangleType === TRIANGLE_TYPES.FORWARD_TOP && <TopTriangle />}
      {triangleType === TRIANGLE_TYPES.FORWARD_DOWN && <DownTriangle />}
    </Styled.Wrapper>
  );
}

/* GoLabel type of props */

GoLabel.propTypes = {
  text: PropTypes.string.isRequired,
  triangleType: PropTypes.oneOf([
    TRIANGLE_TYPES.NONE,
    TRIANGLE_TYPES.BACK,
    TRIANGLE_TYPES.BACK_TOP,
    TRIANGLE_TYPES.BACK_DOWN,
    TRIANGLE_TYPES.FORWARD,
    TRIANGLE_TYPES.FORWARD_TOP,
    TRIANGLE_TYPES.FORWARD_DOWN,
  ]),
  color: PropTypes.oneOf([
    LABEL_COLOR_TYPES.BROWN,
    LABEL_COLOR_TYPES.BLUE,
  ]),
  // eslint-disable-next-line consistent-return
  click: (props, propName) => {
    if (!props.to && (props[propName] === undefined || typeof (props[propName]) !== 'function')) {
      return new Error('Please provide a "click" or "to" prop to "GoLabel" component');
    }
  },
  to: PropTypes.string,
};

/* GoLabel default props */

GoLabel.defaultProps = {
  triangleType: TRIANGLE_TYPES.NONE,
  color: LABEL_COLOR_TYPES.BROWN,
  click: null,
  to: null,
};

export default memo(GoLabel);
