import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  AddGameDefForms,
} from 'components';

/* Constants */

import { GAME_DEF_FORM } from '_constants';

/* Styles */

import pick from 'lodash.pick';
import * as Styled from './styles';

const AddGameDefSection = ({
  data,
  errors,
  onChange,
  mode,
  companyId,
}) => {
  const basicInformationData = useMemo(() => pick(data, [
    'name',
    'company',
    'storyline',
    'final_code',
    'time_limit',
    'game_def_template',
    'is_final_code_only',
    'initial_template_name',
  ]), [
    data.name,
    data.company,
    data.storyline,
    data.final_code,
    data.time_limit,
    data.game_def_template,
    data.is_final_code_only,
    data.initial_template_name,
  ]);

  const questionsData = useMemo(() => (Array.isArray(data.questions) ? data.questions : []), [data.questions]);

  const introductionData = useMemo(() => pick(data, [
    'introduction_title',
    'introduction_text',
    'introduction_vimeo_id',
  ]), [data.introduction_title, data.introduction_text, data.introduction_vimeo_id]);

  const instructionData = useMemo(() => pick(data, [
    'instruction_title',
    'instruction_text',
    'instruction_vimeo_id',
  ]), [data.instruction_title, data.instruction_text, data.instruction_vimeo_id]);

  const storylineData = useMemo(() => pick(data, [
    'storyline_title',
    'storyline_text',
    'storyline_vimeo_id',
  ]), [data.storyline_title, data.storyline_text, data.storyline_vimeo_id]);

  const debriefingData = useMemo(() => pick(data, [
    'debriefing_title',
    'debriefing_text',
    'debriefing_vimeo_id',
  ]), [data.debriefing_title, data.debriefing_text, data.debriefing_vimeo_id]);

  const resetData = useMemo(() => pick(data, [
    'reset_title',
    'reset_text',
    'reset_vimeo_id',
  ]), [data.reset_title, data.reset_text, data.reset_vimeo_id]);

  const onlineOnlyData = useMemo(() => pick(data, [
    'is_online_only',
    'is_dark_mode',
  ]), [data.is_online_only, data.is_dark_mode]);

  return (
    <Styled.Wrapper>
      <Styled.Left>
        <AddGameDefForms.BasicInformationForm
          mode={mode}
          data={basicInformationData}
          errors={errors}
          onChange={onChange}
          companyId={companyId}
        />
        <AddGameDefForms.QuestionsForm
          mode={mode}
          data={questionsData}
          errors={errors}
          onChange={onChange}
          disabled={data.is_final_code_only}
          finalCode={basicInformationData.final_code}
          finalCodeOnly={basicInformationData.is_final_code_only}
        />
      </Styled.Left>
      <Styled.Right>
        <AddGameDefForms.OnlineOnlyForm
          data={onlineOnlyData}
          onChange={onChange}
          mode={mode}
        />
        <AddGameDefForms.StepForm
          step={GAME_DEF_FORM.STEPS.introduction}
          data={introductionData}
          errors={errors}
          onChange={onChange}
        />
        <AddGameDefForms.StepForm
          step={GAME_DEF_FORM.STEPS.instruction}
          data={instructionData}
          errors={errors}
          onChange={onChange}
        />
        <AddGameDefForms.StepForm
          step={GAME_DEF_FORM.STEPS.storyline}
          data={storylineData}
          errors={errors}
          onChange={onChange}
        />
        <AddGameDefForms.StepForm
          step={GAME_DEF_FORM.STEPS.debriefing}
          data={debriefingData}
          errors={errors}
          onChange={onChange}
        />
        <AddGameDefForms.StepForm
          step={GAME_DEF_FORM.STEPS.reset}
          data={resetData}
          errors={errors}
          onChange={onChange}
        />
      </Styled.Right>
    </Styled.Wrapper>
  );
};

AddGameDefSection.propTypes = {
  data: PropTypes.shape({
    game_def_template: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    company: PropTypes.number,
    name: PropTypes.string,
    introduction_title: PropTypes.string,
    introduction_text: PropTypes.string,
    introduction_vimeo_id: PropTypes.string,
    instruction_title: PropTypes.string,
    instruction_text: PropTypes.string,
    instruction_vimeo_id: PropTypes.string,
    storyline_title: PropTypes.string,
    storyline_text: PropTypes.string,
    storyline_vimeo_id: PropTypes.string,
    storyline: PropTypes.number,
    debriefing_title: PropTypes.string,
    debriefing_text: PropTypes.string,
    debriefing_vimeo_id: PropTypes.string,
    reset_title: PropTypes.string,
    reset_text: PropTypes.string,
    reset_vimeo_id: PropTypes.string,
    time_limit: PropTypes.string,
    final_code: PropTypes.string,
    is_final_code_only: PropTypes.bool,
    questions: PropTypes.arrayOf(PropTypes.shape({})),
    initial_template_name: PropTypes.string,
    is_dark_mode: PropTypes.bool,
    is_online_only: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([
    GAME_DEF_FORM.MODE.EDIT,
    GAME_DEF_FORM.MODE.ADD,
    GAME_DEF_FORM.MODE.TEMPLATE,
    GAME_DEF_FORM.MODE.TEMPLATE_EDIT,
  ]).isRequired,
  companyId: PropTypes.number,
  errors: PropTypes.shape({}).isRequired,
};

AddGameDefSection.defaultProps = {
  companyId: null,
};

export default AddGameDefSection;
