/* Libs */
import styled, { css } from 'styled-components';

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  ${({ autoSize }) => autoSize && css`
    width: 60%;
    height: 60%;
  `}
  background-color: ${({
    active,
    color,
    colorInactive,
  }) => (active ? color : colorInactive)};
`;

export default Circle;
