/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */
import { FONT_WEIGHTS } from '_constants';

/* Styles */

import * as Styled from './styles';

function DeletedCell({ at, by, fontFamily }) {
  return (
    <Styled.Wrapper>
      <Styled.Cell fontFamily={fontFamily}>{at}</Styled.Cell>
      <Styled.Cell fontFamily={fontFamily}>
        By:
        {' '}
        <Styled.Role>
          {by}
        </Styled.Role>
      </Styled.Cell>
    </Styled.Wrapper>
  );
}

/* DeletedCell type of props */

DeletedCell.propTypes = {
  at: PropTypes.string,
  by: PropTypes.string,
  fontFamily: PropTypes.oneOf([
    FONT_WEIGHTS.REGULAR,
    FONT_WEIGHTS.BOLD,
  ]),
};

/* DeletedCell default props */

DeletedCell.defaultProps = {
  at: ' ---- ',
  by: ' ---- ',
  fontFamily: FONT_WEIGHTS.REGULAR,
};

export default DeletedCell;
