/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'components';

/* Styles */

import * as Styled from './styles';

function ContainerLoader({ friendlyMode, isDarkMode }) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode} friendlyMode={friendlyMode}>
      <Styled.Positioner>
        <Styled.Shadow />
        <Loader />
      </Styled.Positioner>
    </Styled.Wrapper>
  );
}

ContainerLoader.propTypes = {
  friendlyMode: PropTypes.bool,
  isDarkMode: PropTypes.bool,
};

ContainerLoader.defaultProps = {
  friendlyMode: false,
  isDarkMode: false,
};

export default ContainerLoader;
