/* Libs */
import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  values,
  merge,
  debounce,
  isEmpty,
  isInteger,
  isNaN,
  get,
} from 'lodash';

/* Utils */

import { notification, fastSessionService } from 'utils/services';
import {
  getError,
  showErrorsFromBackend,
  hasPermission,
  getLeaderboardShareLink,
  transformDataToOptions,
  redirect,
  getLaunchGameLink,
  replaceQueryParams,
  getStaticData,
  checkLastPageEmptiness,
  exportZIP,
  getTimezoneName,
} from 'utils/custom';

/* Components */

import {
  CompanyAdminStruct,
  CampaignsSidebar,
  CampaignsMainSection,
  ToggleBar,
  Select,
  EditSessionSidebar,
  ViewSessionParticipantsSibedar,
  ShareLeaderboardModal,
  InviteParticipantsModal,
  CreateCampaignModal,
  CampaignSettingsModal,
  Pagination,
  AddCircle,
  LaunchSessionSidebar,
  AddSessionSidebar,
  Loader,
  CompanyLicenseExpiredBlock,
} from 'components';

/* Actions */

import { AdminCompanyEntity, AuthEntity } from '_entities';

/* Constants */

import {
  CAMPAIGN_TYPES,
  URLS_CONFIG,
  DATE_SETTINGS,
  SESSION_TYPES,
  CAMPAIGNS_LIST,
  GAME_SESSIONS_TABLE,
} from 'config';
import { NOTIFICATIONS, PERMISSION_NAMES, SESSION_LAUNCH_TYPES } from '_constants';


/* Styles */

import * as Styled from './styles';

const PAGE_TITLE = 'Campaigns';

const DEFAULT_DATA = {
  campaignType: CAMPAIGN_TYPES.ACTIVE,
  campaign: null,
  sessionType: SESSION_TYPES.SCHEDULED,
  filterBy: null,
};

const SIDEBAR_NAMES = {
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW_PARTICIPANTS: 'View participants',
  LAUNCH: 'Launch',
};

const MODAL_NAMES = {
  SHARE_LEADERBOARD: 'Share leaderboard',
  INVITE_PARTICIPANTS: 'Invite participants',
  CREATE_CAMPAIGN: 'Create campaign',
  CAMPAIGN_SETTINGS: 'Campaign settings',
};

const MODAL_TYPES = {
  REGULAR: 'regular',
  SUCCESS: 'success',
};

function Campaigns({
  location: { search },
  getCampaigns,
  getCampaignWithPosition,
  getLocations,
  getLocation,
  addNewLocation,
  getGameSessions,
  getKits,
  getKit,
  removeKit,
  getGameDefs,
  getGameDef,
  removeGameDef,
  deleteGameSession,
  editGameSession,
  addGameSession,
  updateCampaign,
  removeCampaign,
  // getGameSession,
  getLeaderboard,
  campaigns,
  locations,
  gameSessions,
  loading,
  company,
  kits,
  gameDefs,
  leaderboard,
  addCampaign,
  gameSessionStatuses,
  weekdays,
  downloadSession,
  getStorylines,
  userCompany,
  getCompany,
  role,
  roles,
  getInitialUserData,
  companyTimezone,
}) {
  const isGameSessionsMount = useRef(false);
  const isInitialLoad = useRef(false);
  const [data, changeData] = useState(DEFAULT_DATA);
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [openedSessionForEdit, setOpenedSessionForEdit] = useState({});
  const [openedSessionForViewParticipants, setOpenedSessionForViewParticipants] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [modalType, setModalType] = useState(MODAL_TYPES.REGULAR);
  const [campaignsPage, setCampaignsPage] = useState(1);
  const [gameSessionsPage, setGameSessionsPage] = useState(1);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalGameSessions, setTotalGameSessions] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const isLSAdmin = role === getStaticData('roles').admin;
  const isContractIgnored = [roles.host, roles.company_host].includes(role);


  // const handleChange = ({ target: { name, value } }) => changeData({ ...data, [name]: value });

  const changeCampaignType = ({ target: { value } }) => {
    changeCampaignPage({
      selected: 0,
      changedData: {
        ...data,
        campaignType: value,
      },
    });
  };

  const changeCampaignPage = ({ selected, changedData = data }) => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }

    setCampaignsPage(selected + 1);
    loadCampaigns({ page: selected + 1, changedData });
  };

  const loadCampaigns = async ({ page = campaignsPage, changedData = data } = {}) => {
    try {
      const { count, results: newCampaigns } = await getCampaigns({
        company,
        isActive: changedData.campaignType === CAMPAIGN_TYPES.ACTIVE,
        page,
      });

      setTotalCampaigns(count);
      changeCampaign({
        id: newCampaigns.length ? newCampaigns[0].id : DEFAULT_DATA.campaign,
        changedData,
        storedCampaigns: newCampaigns,
      });
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const changeCampaign = ({ id = data.campaign, changedData = data, storedCampaigns = campaigns } = {}) => {
    const fullCampaign = storedCampaigns.find(campaign => campaign.id === id);

    if (fullCampaign && fullCampaign.storyline) {
      getKits({ company, storyline: fullCampaign.storyline.founder_id });
      getGameDefs({ company, storyline: fullCampaign.storyline.founder_id });
    }

    changeSessionType({
      storedCampaigns,
      changedData: {
        ...changedData,
        campaign: id,
      },
      target: { value: DEFAULT_DATA.sessionType },
    });
  };

  const changeSessionType = ({ target: { value: sessionType }, changedData = data, ...restData }) => {
    changeFilter({
      ...restData,
      changedData: {
        ...changedData,
        sessionType,
      },
      target: { value: DEFAULT_DATA.filterBy },
    });
  };

  const changeFilter = ({ target: { value: filterBy }, changedData = data, ...restData }) => {
    changeData({ ...changedData, filterBy });
    changeGameSessionPage({
      ...restData,
      changedData: {
        ...changedData,
        filterBy,
      },
      selected: 0,
    });
  };

  const resetCampaignsFilter = () => {
    changeFilter({ target: { value: DEFAULT_DATA.filterBy } });
  };

  const changeGameSessionPage = ({ selected, ...restData }) => {
    setGameSessionsPage(selected + 1);
    loadGameSessions({ ...restData, page: selected + 1 });
  };

  const loadGameSessions = useCallback(async ({
    page = gameSessionsPage,
    changedData: {
      campaign,
      filterBy: location,
      sessionType,
    } = data,
    changedData: initialData,
    storedCampaigns = campaigns,
  } = {}) => {
    try {
      if (!campaign) return;

      const fullCampaign = storedCampaigns.find(({ id }) => id === campaign);

      if (!fullCampaign) return;
      const requestData = {
        // campaign,
        campaign: fullCampaign.founder_id,
        location,
        offset: (page - 1) * GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE,
        limit: GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE,
        deleted: sessionType === SESSION_TYPES.DELETED,
      };
      const { count, results } = sessionType === SESSION_TYPES.LEADERBOARD
        ? await getLeaderboard({
          ...requestData,
          token: fullCampaign.token,
          perPage: requestData.limit,
          page,
        })
        : await getGameSessions(merge(requestData, {
          status: (() => {
            switch (sessionType) {
              case SESSION_TYPES.SCHEDULED:
                return gameSessionStatuses.scheduled;
              case SESSION_TYPES.ACTIVE:
                return [gameSessionStatuses.started, gameSessionStatuses.stopped];
              case SESSION_TYPES.DELETED:
                return null;
              case SESSION_TYPES.COMPLETED:
              default:
                return gameSessionStatuses.completed;
            }
          })(),
        }));

      if (count && !results.length) {
        return changeGameSessionPage({ selected: 0, changedData: initialData, storedCampaigns });
      }

      setTotalGameSessions(count);
      replaceQueryParams({
        campaign: String(campaign),
        sessionType,
        filter: String(location),
        page,
      });
    } catch (error) {
      notification.error(getError(error));
    }
  }, [
    campaigns,
    changeGameSessionPage,
    data,
    gameSessionStatuses.completed,
    gameSessionStatuses.scheduled,
    gameSessionStatuses.started,
    gameSessionStatuses.stopped,
    gameSessionsPage,
    getGameSessions,
    getLeaderboard,
  ]);

  const manageSidebar = name => () => setActiveSidebar(name);
  const manageModal = name => () => setActiveModal(name);
  const closeModal = () => setActiveModal(null);
  const getTableData = () => {
    switch (data.sessionType) {
      case SESSION_TYPES.LEADERBOARD:
        return leaderboard.map((item, id) => ({
          ...item,
          rank: GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE * (gameSessionsPage - 1) + id + 1,
        }));
      case SESSION_TYPES.SCHEDULED:
      case SESSION_TYPES.ACTIVE:
      case SESSION_TYPES.COMPLETED:
      default:
        return gameSessions;
    }
  };

  const closeHandler = () => {
    openedSessionForEdit.invalidGameDef && removeGameDef(openedSessionForEdit.game_def.id);
    openedSessionForEdit.invalidKit && removeKit(openedSessionForEdit.kit.id);

    manageSidebar(null)();
    setOpenedSessionForEdit({});
  };
  const closeViewParticipantsSidebar = () => {
    manageSidebar(null)();
    setOpenedSessionForViewParticipants({});
  };
  const closeSuccessModal = () => {
    setModalType(MODAL_TYPES.REGULAR);
    closeModal();
  };
  const openAddSessionSidebar = async () => {
    try {
      !('is_secret_mode' in userCompany) && await getCompany(company);
      manageSidebar(SIDEBAR_NAMES.ADD)();
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const openLaunchSessionSidebar = () => {
    manageSidebar(SIDEBAR_NAMES.LAUNCH)();
  };
  const openViewSessionParticipantsSidebar = (id) => {
    const gameSession = gameSessions.find(session => session.id === id);

    if (!gameSession) {
      notification.error(NOTIFICATIONS.ERROR_VIEW_SESSION_PARTICIPANTS);
      return;
    }

    setOpenedSessionForViewParticipants(gameSession);
    manageSidebar(SIDEBAR_NAMES.VIEW_PARTICIPANTS)();
  };
  const onEditSessionDelete = async () => {
    try {
      await deleteGameSession({
        sessionId: openedSessionForEdit.id,
        campaignId: data.campaign,
        status: gameSessionStatuses.scheduled,
      });

      notification.success(NOTIFICATIONS.SUCCESS_DELETE_SESSION);

      gameSessions.length === 1
        && gameSessionsPage === Math.ceil(totalGameSessions / GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE)
        && gameSessionsPage !== 1
        ? changeGameSessionPage({ selected: gameSessionsPage })
        : loadGameSessions();

      closeHandler();
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const openEditSessionSidebar = async (id) => {
    const session = gameSessions.find(gameSession => gameSession.id === id);
    const selectedCampaign = campaigns.find(({ id }) => id === data.campaign);

    if (!session || !selectedCampaign) {
      return notification.error(getError());
    }

    try {
      /**
       * TODO: WARNING!
       * If kit_id or kit storyline will back editing
       * add same logic as for gameDef.
       */
      const isGameDefAbsent = !gameDefs.find(({ id }) => id === session.game_def.id);
      const isKitAbsent = !kits.find(({ founder_id }) => founder_id === session.kit.founder_id);

      // TODO: use promise all
      setOpenedSessionForEdit({
        ...session,
        invalidGameDef: isGameDefAbsent
          && await getGameDef({ id: session.game_def.id })
            .then(({ deleted, storyline }) => Boolean(deleted) || (storyline !== selectedCampaign.storyline.id)),
        invalidKit: isKitAbsent
          && await getKit({ id: session.kit.id })
            .then(({ deleted, storyline }) => Boolean(deleted) || (storyline.id !== selectedCampaign.storyline.id)),
      });
      manageSidebar(SIDEBAR_NAMES.EDIT)();
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const handleSubmit = async (requestData) => {
    try {
      await updateCampaign({
        id: data.campaign,
        ...requestData,
      });
    } catch (error) {
      notification.error(getError(error));
      return;
    }

    setModalType(MODAL_TYPES.SUCCESS);
  };
  const saveHandler = async (requestData) => {
    try {
      setLoading(true);
      const sendingData = merge(
        requestData,
        requestData.location
          ? {
            location: typeof requestData.location === 'number'
              ? requestData.location
              : await addNewLocation(requestData.location)
                .then(({ id }) => id),
          }
          : {},
      );

      if (activeSidebar === SIDEBAR_NAMES.EDIT) {
        await editGameSession(sendingData);
        notification.success(NOTIFICATIONS.SUCCESS_EDIT_GAME_SESSION);

        if (requestData.start_time) loadGameSessions();
      } else {
        await addGameSession({
          ...sendingData,
          is_anonymous: userCompany.is_secret_mode,
        });
        notification.success(NOTIFICATIONS.SUCCESS_ADD_GAME_SESSION);

        changeSessionType({ target: { value: SESSION_TYPES.SCHEDULED } });
      }
      setLoading(false);
      closeHandler();
    } catch (error) {
      setLoading(false);
      showErrorsFromBackend(error);
    }
  };
  const loadNewDataForLaunchSessionSidebar = async (campaign) => {
    const fullCampaign = campaigns.find(({ id }) => id === campaign);
    const newCampaign = fullCampaign
      || await getCampaign({ id: campaign, withoutStoreChanging: true });

    return Promise.all([
      getKits({ company, storyline: newCampaign.storyline.founder_id, withoutStoreChanging: true }),
      getGameDefs({ company, storyline: newCampaign.storyline.founder_id, withoutStoreChanging: true }),
    ])
      .then(
        ([
          { results: kits },
          { results: gameDefs },
        ]) => ({
          kits,
          gameDefs,
          campaigns: fullCampaign
            ? campaigns
            : campaigns.concat(newCampaign),
        }),
      );
  };
  const launchSession = async (requestData) => {
    const selectedGameDef = gameDefs.find(({ id }) => id === requestData.game_def);

    fastSessionService.saveFastSessionData(merge(
      {
        ...requestData,
        is_anonymous: userCompany.is_secret_mode,
      },
      selectedGameDef ? { is_dark_mode: selectedGameDef.is_dark_mode } : {},
    ));
    // const { id } = await addGameSession({
    //   ...requestData,
    //   location: typeof requestData.location === 'number'
    //     ? requestData.location
    //     : await addNewLocation(requestData.location).then(({ id }) => id),
    //   start_time: moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY_HOURS_MINUTES),
    // });
    // redirect(getLaunchGameLink(id));
    redirect(getLaunchGameLink(SESSION_LAUNCH_TYPES.FAST));
  };

  const isLastItemOnLastPage = checkLastPageEmptiness({ total: totalCampaigns, perPage: CAMPAIGNS_LIST.PAGINATION.PER_PAGE, page: campaignsPage });

  const closeCampaign = async () => {
    const requestData = {
      id: data.campaign,
      is_active: false,
    };

    try {
      await updateCampaign(requestData);
      notification.success(NOTIFICATIONS.SUCCESS_CLOSE_CAMPAIGN);
      if (isLastItemOnLastPage) {
        setCampaignsPage(prev => prev - 1);
        await loadCampaigns({ page: campaignsPage - 1 });
      } else {
        await loadCampaigns();
      }
      closeSuccessModal();
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const deleteCampaign = async () => {
    try {
      await removeCampaign(data.campaign);

      notification.success(NOTIFICATIONS.SUCCESS_DELETE_CAMPAIGN);

      campaigns.length === 1
        && campaignsPage === Math.ceil(totalCampaigns / CAMPAIGNS_LIST.PAGINATION.PER_PAGE)
        && campaignsPage !== 1
        ? changeCampaignPage({ selected: campaignsPage })
        : loadCampaigns();

      closeSuccessModal();
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const exportSession = async (id) => {
    try {
      exportZIP(`game-sessions/result/${id}/download-game-zip/`);
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const changeCampaignSettings = async (requestData) => {
    try {
      const { id } = await updateCampaign(requestData);

      notification.success(NOTIFICATIONS.SUCCESS_UPDATE_CAMPAIGN);

      changeCampaign({
        id,
        storedCampaigns: campaigns.map(campaign => (campaign.id === requestData.id
          ? {
            ...campaign,
            id,
          }
          : campaign)),
      });
      closeSuccessModal();
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const activateCampaign = async () => {
    const requestData = {
      id: data.campaign,
      is_active: true,
    };

    try {
      await updateCampaign(requestData);
      notification.success(NOTIFICATIONS.SUCCESS_ACTIVATE_CAMPAIGN);
      if (isLastItemOnLastPage) {
        setCampaignsPage(prev => prev - 1);
        await loadCampaigns({ page: campaignsPage - 1 });
      } else {
        await loadCampaigns();
      }
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const createCampaign = async (requestData) => {
    const { public_invite_link: publicInviteLink } = await addCampaign(requestData);

    changeCampaignType({ target: { value: CAMPAIGN_TYPES.ACTIVE } });

    return publicInviteLink;
  };

  const fetchGameDefs = ({
    gameDef, callback, campaigns: storedCampaigns = campaigns, campaign = data.campaign,
  }) => {
    const storyline = (storedCampaigns.find(({ id }) => campaign === id) || { storyline: {} }).storyline.founder_id;

    getGameDefs({
      company, storyline, gameDef, withoutStoreChanging: true,
    }).then(({ results: gameDefs }) => {
      callback(transformDataToOptions({ data: gameDefs, value: 'id', label: 'name' }));
    });
  };
  const loadGameDefs = debounce(fetchGameDefs, 300);
  const fetchLocations = (location, callback) => {
    getLocations({ location, withoutStoreChanging: true }).then(({ results: locations }) => {
      callback(transformDataToOptions({ data: locations, value: 'id', label: 'location' }));
    });
  };
  const loadLocations = debounce(fetchLocations, 300);


  const isCompanyContractLoaded = isContractIgnored
    || (!isEmpty(userCompany) && !isEmpty(userCompany.contract));
  const isValidCompany = isContractIgnored
    || (isCompanyContractLoaded && moment(userCompany.contract.end_date).isSameOrAfter());

  useEffect(() => {
    async function fetchData() {
      try {
        const params = new URLSearchParams(search);
        const queryCampaign = params.get('campaign');
        const querySessionType = params.get('sessionType');
        const queryFilter = params.get('filter');
        const queryPage = +params.get('page');

        const campaignNumber = queryCampaign && Number(queryCampaign);
        const filterNumber = queryFilter && Number(queryFilter);
        const page = (isNaN(queryPage) || (queryPage === 0)) ? 1 : queryPage;

        const searchedCampaign = isInteger(campaignNumber)
          && await getCampaignWithPosition({ id: campaignNumber })
            .then(res => res)
            .catch(() => false);

        const { results: locations } = await getLocations();
        const searchedLocationObject = isInteger(filterNumber)
          && (
            locations.find(({ id }) => id === filterNumber)
            || await getLocation(filterNumber)
              .then(res => res)
              .catch(err => err)
          );
        const searchedLocation = searchedLocationObject && searchedLocationObject.id;

        const isValidCampaign = searchedCampaign;
        const isValidSessionType = isValidCampaign && querySessionType && values(SESSION_TYPES).includes(querySessionType);
        const isValidFilter = isValidCampaign && searchedLocation;
        const isValidPage = isValidCampaign && isInteger(page);

        const sessionType = isValidSessionType ? querySessionType : DEFAULT_DATA.sessionType;
        const filter = isValidFilter ? filterNumber : DEFAULT_DATA.filterBy;

        const campaignType = isValidCampaign
          ? searchedCampaign.campaign.is_active
            ? CAMPAIGN_TYPES.ACTIVE
            : CAMPAIGN_TYPES.CLOSED
          : DEFAULT_DATA.campaignType;
        const campaignsPage = isValidCampaign
          ? Math.ceil(searchedCampaign.position / CAMPAIGNS_LIST.PAGINATION.PER_PAGE)
          : 1;

        if (campaignsPage !== 1) {
          isInitialLoad.current = true;
          setCampaignsPage(campaignsPage);
        }

        const { count, results: campaigns } = await getCampaigns({
          company,
          isActive: campaignType === CAMPAIGN_TYPES.ACTIVE,
          page: campaignsPage,
        });

        setTotalCampaigns(count);

        const firstCampaignId = campaigns.length ? campaigns[0].id : DEFAULT_DATA.campaign;
        const campaign = isValidCampaign ? searchedCampaign.campaign.id : firstCampaignId;
        const { storyline } = isValidCampaign ? searchedCampaign.campaign : (campaigns[0] || {});

        if (storyline && storyline.founder_id) {
          getKits({ company, storyline: storyline.founder_id });
          getGameDefs({ company, storyline: storyline.founder_id });
        }

        if (!isValidCampaign || !isValidSessionType || !isValidFilter || !isValidPage) {
          replaceQueryParams({
            campaign: String(campaign),
            sessionType,
            filter: String(filter),
            page,
          });
        }

        const changedData = {
          ...data,
          campaign,
          campaignType,
          sessionType,
          filterBy: filter,
        };

        changeData(changedData);

        page === 1
          ? loadGameSessions({ page, changedData, storedCampaigns: campaigns })
          : changeGameSessionPage({ selected: page - 1, changedData, storedCampaigns: campaigns });
      } catch (error) {
        notification.error(getError(error));
      }
    }

    isValidCompany && fetchData();
  }, [isValidCompany]);

  useEffect(() => {
    if (!isGameSessionsMount.current) {
      isGameSessionsMount.current = true;
      return;
    }

    !isEmpty(userCompany)
      && (data.sessionType === SESSION_TYPES.COMPLETED)
      && gameSessions.length
      && (gameSessions[0].is_anonymous !== userCompany.is_secret_mode)
      && loadGameSessions();
  }, [
    userCompany,
  ]);

  const selectedCampaign = campaigns.find(({ id }) => id === data.campaign) || {};

  const participantEmails = useMemo(() => {
    // need to concat signed up participants coz of signed up, but not invited users (which registered via shared link)
    if (!openedSessionForEdit.invited_participants || !openedSessionForEdit.signedup_participants) return [];
    return openedSessionForEdit.invited_participants.concat(openedSessionForEdit.signedup_participants).reduce((acc, item) => (
      acc.some(({ id }) => id === item.id) ? acc : acc.concat(item)
    ), []);
  },
  [openedSessionForEdit.invited_participants, openedSessionForEdit.signedup_participants]);

  const userTimezone = getTimezoneName(openedSessionForEdit.timezone || companyTimezone);
  const sessionSidebarProps = {
    loadGameSessions,
    data: {
      campaign: data.campaign ? selectedCampaign : {},

      id: openedSessionForEdit.id || null,
      // kit: (openedSessionForEdit.kit && openedSessionForEdit.kit.id) || null,
      kit: (openedSessionForEdit.kit && openedSessionForEdit.kit.founder_id) || null,
      timezone: userTimezone,
      timezone_offset: openedSessionForEdit.timezone_offset || null,
      // gameDef: openedSessionForEdit.game_def || null,
      gameDef: (openedSessionForEdit.game_def && openedSessionForEdit.game_def.id) || null,
      // date: openedSessionForEdit.start_time ? moment(openedSessionForEdit.start_time).utc().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY) : null,
      dateFrom: moment(openedSessionForEdit.start_time).utc().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
      // time: openedSessionForEdit.start_time ? moment(openedSessionForEdit.start_time).utc().format(DATE_SETTINGS.FORMAT.HOURS_MINUTES) : null,
      startTime: openedSessionForEdit.start_time ? moment(openedSessionForEdit.start_time).utc().format(DATE_SETTINGS.FORMAT.HOURS_MINUTES) : null,
      location: (openedSessionForEdit.location && openedSessionForEdit.location.id) || null,
      maxParticipants: openedSessionForEdit.max_participants || null,
      invitedParticipants: openedSessionForEdit.invited_participants_count || 0,
      participantEmails,
      signedUpParticipants: openedSessionForEdit.signedup_participants || [],
      introductionTime: openedSessionForEdit.introduction_time || selectedCampaign.introduction_time,
      debriefingTime: openedSessionForEdit.debriefing_time || selectedCampaign.debriefing_time,
      participantsPerSession: openedSessionForEdit.participants_per_session || selectedCampaign.participants_per_session,
      isTimerCountingUp: isEmpty(openedSessionForEdit) ? selectedCampaign.is_timer_counting_up : openedSessionForEdit.is_timer_counting_up,
      isSecretSession: ('is_anonymous' in openedSessionForEdit) ? openedSessionForEdit.is_anonymous : userCompany.is_secret_mode,
      autoSignUp: openedSessionForEdit.auto_signup || false,
      meetingLink: openedSessionForEdit.meeting_link || '',
      kits,
      gameDefs,
      locations,
      activeSidebar,
      SIDEBAR_NAMES,
      weekdays,
    },
    getLocations,
    loadGameDefs,
    resetCampaignsFilter,
  };

  const launchSessionData = {
    campaign: data.campaign,
    campaigns,
    kits,
    gameDefs,
    locations,
    timezone: userTimezone,
    introductionTime: selectedCampaign.introduction_time,
    debriefingTime: selectedCampaign.debriefing_time,
  };

  const calendarSidebar = isValidCompany
    ? (
      <CampaignsSidebar
        toggleBar={(
          <ToggleBar
            name="campaignType"
            onChange={changeCampaignType}
            active={data.campaignType}
            options={[
              CAMPAIGN_TYPES.ACTIVE,
              CAMPAIGN_TYPES.CLOSED,
            ]}
          />
        )}
        campaigns={campaigns}
        currentCampaign={data.campaign}
        campaignChangeHandler={changeCampaign}
        createCampaignHandler={manageModal(MODAL_NAMES.CREATE_CAMPAIGN)}
        pagination={(
          <Pagination
            activePage={campaignsPage}
            pageChangeHandler={changeCampaignPage}
            pageCount={totalCampaigns / CAMPAIGNS_LIST.PAGINATION.PER_PAGE}
          />
        )}
      />
    )
    : null;

  const campaignsContent = isValidCompany
    ? (
      <>
        <CampaignsMainSection
          isActiveCampaigns={data.campaignType === CAMPAIGN_TYPES.ACTIVE}
          campaignsType={data.campaignType}
          campaigns={campaigns}
          createCampaignHandler={manageModal(MODAL_NAMES.CREATE_CAMPAIGN)}
          campaign={selectedCampaign}
          actions={
            data.campaignType === CAMPAIGN_TYPES.ACTIVE
              ? (
                <>
                  {
                    hasPermission(PERMISSION_NAMES.ADD_NEW_SESSION) && (
                      <AddCircle
                        key="Add session"
                        onClick={openAddSessionSidebar}
                        title="Add session"
                      />
                    )
                  }
                  {
                    <AddCircle
                      key="Launch session"
                      onClick={openLaunchSessionSidebar}
                      title="Launch session"
                    />
                  }
                  {
                    hasPermission(PERMISSION_NAMES.INVITE_PARTICIPANTS_TO_CAMPAIGN) && (
                      <Styled.Action key="Invite participants" onClick={manageModal(MODAL_NAMES.INVITE_PARTICIPANTS)}>
                        Invite participants
                      </Styled.Action>
                    )
                  }
                  {
                    hasPermission(PERMISSION_NAMES.CHANGE_CAMPAIGN_SETTINGS) && (
                      <Styled.Action key="Settings" onClick={manageModal(MODAL_NAMES.CAMPAIGN_SETTINGS)}>
                        Settings
                      </Styled.Action>
                    )
                  }
                </>
              )
              : hasPermission(PERMISSION_NAMES.ACTIVATE_CAMPAIGNS) && (
                <Styled.Action key="Activate campaign" onClick={activateCampaign}>
                  Activate campaign
                </Styled.Action>
              )
          }
          shareLeaderboard={manageModal(MODAL_NAMES.SHARE_LEADERBOARD)}
          toggleBar={(
            <ToggleBar
              name="sessionType"
              onChange={changeSessionType}
              active={data.sessionType}
              options={[
                SESSION_TYPES.SCHEDULED,
                SESSION_TYPES.ACTIVE,
                SESSION_TYPES.COMPLETED,
                SESSION_TYPES.LEADERBOARD,
                SESSION_TYPES.DELETED,
              ]}
            />
          )}
          filter={(
            <Select
              name="filterBy"
              placeholder="Filter by location"
              value={data.filterBy}
              onChange={changeFilter}
              options={transformDataToOptions({ data: locations, value: 'id', label: 'location' })}
              defaultOptions={transformDataToOptions({ data: locations, value: 'id', label: 'location' })}
              loadOptions={loadLocations}
              async
              searchable
            />
          )}
          pagination={(
            <Pagination
              activePage={gameSessionsPage}
              pageChangeHandler={changeGameSessionPage}
              pageCount={totalGameSessions / GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE}
              withInitialPage={false}
            />
          )}
          tableData={getTableData()}
          tooltipData={{
            gameDefs: gameDefs.map(({ name }) => name),
            kits: kits.map(({ nickname, kit_id }) => ({ nickname, kit_id })),
            storyline: (selectedCampaign.storyline || {}).name,
          }}
          loading={loading}
          sessionType={data.sessionType}
          campaignType={data.campaignType}
          openEditSession={openEditSessionSidebar}
          openViewSessionParticipants={openViewSessionParticipantsSidebar}
          exportSession={exportSession}
          loadGameSessions={loadGameSessions}
        />
        <EditSessionSidebar
          open={activeSidebar === SIDEBAR_NAMES.EDIT}
          title="Edit session"
          onClose={closeHandler}
          onSave={saveHandler}
          isLoading={isLoading}
          saveText="Save changes"
          onDelete={onEditSessionDelete}
          {...sessionSidebarProps}
        />
        <AddSessionSidebar
          open={activeSidebar === SIDEBAR_NAMES.ADD}
          onClose={closeHandler}
          onSave={saveHandler}
          isLoading={isLoading}
          {...sessionSidebarProps}
        />
        <ViewSessionParticipantsSibedar
          open={SIDEBAR_NAMES.VIEW_PARTICIPANTS === activeSidebar}
          title="Participants view"
          onClose={closeViewParticipantsSidebar}
          data={{
            campaign: data.campaign ? selectedCampaign : {},
            invitedParticipants: openedSessionForViewParticipants.invited_participants,
            signedupParticipants: openedSessionForViewParticipants.signedup_participants,
            actualParticipants: openedSessionForViewParticipants.actual_participants,
          }}
        />
        <LaunchSessionSidebar
          open={SIDEBAR_NAMES.LAUNCH === activeSidebar}
          title="Launch session"
          saveText="Launch"
          onClose={closeHandler}
          onSave={launchSession}
          data={launchSessionData}
          loadCampaigns={loadCampaigns}
          loadGameDefs={loadGameDefs}
          loadLocations={loadLocations}
          loadNewData={loadNewDataForLaunchSessionSidebar}
          getInitialUserData={getInitialUserData}
          role={role}
          roles={roles}
        />
        <ShareLeaderboardModal
          open={activeModal === MODAL_NAMES.SHARE_LEADERBOARD}
          onClose={closeModal}
          link={getLeaderboardShareLink({
            campaignId: selectedCampaign.id,
            token: selectedCampaign.token,
          })}
          campaign={selectedCampaign.id}
          token={selectedCampaign.token}
        />
        <InviteParticipantsModal
          open={activeModal === MODAL_NAMES.INVITE_PARTICIPANTS}
          onClose={closeSuccessModal}
          link={selectedCampaign.public_invite_link}
          campaignName={selectedCampaign.name}
          onSubmit={handleSubmit}
          MODAL_TYPES={MODAL_TYPES}
          modalType={modalType}
        />
        <CreateCampaignModal
          open={activeModal === MODAL_NAMES.CREATE_CAMPAIGN}
          onClose={closeSuccessModal}
          company={company}
          onCreateCampaign={createCampaign}
          onLoadStorylines={getStorylines}
        />
        <CampaignSettingsModal
          open={activeModal === MODAL_NAMES.CAMPAIGN_SETTINGS}
          onClose={closeSuccessModal}
          campaign={selectedCampaign}
          changeCampaignSettings={changeCampaignSettings}
          closeCampaign={closeCampaign}
          deleteCampaign={deleteCampaign}
        />
      </>
    )
    : <CompanyLicenseExpiredBlock isLSAdmin={isLSAdmin} />;

  return (
    <CompanyAdminStruct sidebar={calendarSidebar}>
      {
        isCompanyContractLoaded
          ? campaignsContent
          : <Loader />
      }
    </CompanyAdminStruct>
  );
}

/* Page Url */

Campaigns.path = URLS_CONFIG.companyAdmin.campaigns;

/* Page Title */

Campaigns.title = PAGE_TITLE;

/* Campaigns type of props */
// TODO: add ALL propTypes
Campaigns.propTypes = {
  getCampaigns: PropTypes.func.isRequired,
  getCampaignWithPosition: PropTypes.func.isRequired,
  getGameSessions: PropTypes.func.isRequired,
  getGameSession: PropTypes.func.isRequired,
  getKits: PropTypes.func.isRequired,
  getKit: PropTypes.func.isRequired,
  getGameDef: PropTypes.func.isRequired,
  removeGameDef: PropTypes.func.isRequired,
  getStorylines: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  company: PropTypes.number,
  deleteGameSession: PropTypes.func.isRequired,
  editGameSession: PropTypes.func.isRequired,
  addGameSession: PropTypes.func.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  removeCampaign: PropTypes.func.isRequired,
  getLeaderboard: PropTypes.func.isRequired,
  downloadSession: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  getInitialUserData: PropTypes.func.isRequired,
};

/* Campaigns default props */

Campaigns.defaultProps = {
  company: null,
};

const mapStateToProps = state => ({
  campaigns: state.adminCompany.campaigns,
  locations: state.adminCompany.locations,
  gameSessions: state.adminCompany.gameSessions,
  loading: state.adminCompany.loading,
  company: state.auth.company,
  userCompany: state.adminCompany.company,
  kits: state.adminCompany.kits,
  gameDefs: state.adminCompany.gameDefs,
  leaderboard: state.adminCompany.leaderboard,
  gameSessionStatuses: state.staticData.gameSessionStatuses,
  weekdays: state.staticData.weekdays,
  role: state.auth.role,
  roles: state.staticData.roles,
  companyTimezone: get(state, 'adminCompany.company.timezone'),
});

export default connect(mapStateToProps, {
  getCampaigns: AdminCompanyEntity.actions.getCampaigns,
  getCampaignWithPosition: AdminCompanyEntity.actions.getCampaignWithPosition,
  getLocations: AdminCompanyEntity.actions.getLocations,
  getLocation: AdminCompanyEntity.actions.getLocation,
  addNewLocation: AdminCompanyEntity.actions.addNewLocation,
  getGameSessions: AdminCompanyEntity.actions.getGameSessions,
  getGameSession: AdminCompanyEntity.actions.getGameSession,
  getKits: AdminCompanyEntity.actions.getKits,
  getKit: AdminCompanyEntity.actions.getKit,
  removeKit: AdminCompanyEntity.actions.removeKit,
  getGameDefs: AdminCompanyEntity.actions.getGameDefs,
  getGameDef: AdminCompanyEntity.actions.getGameDef,
  removeGameDef: AdminCompanyEntity.actions.removeGameDef,
  getStorylines: AdminCompanyEntity.actions.getStorylines,
  deleteGameSession: AdminCompanyEntity.actions.deleteGameSession,
  editGameSession: AdminCompanyEntity.actions.editGameSession,
  addGameSession: AdminCompanyEntity.actions.addGameSession,
  updateCampaign: AdminCompanyEntity.actions.updateCampaign,
  removeCampaign: AdminCompanyEntity.actions.deleteCampaign,
  getLeaderboard: AdminCompanyEntity.actions.getLeaderboard,
  addCampaign: AdminCompanyEntity.actions.addCampaign,
  downloadSession: AdminCompanyEntity.actions.downloadSession,
  getCompany: AdminCompanyEntity.actions.getCompany,
  getInitialUserData: AuthEntity.actions.getInitialUserData,
})(Campaigns);
