/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Utils */
import { getStaticData } from 'utils/custom';

/* Styles */
import * as Styled from './styles';


function UserName({
  firstName,
  lastName,
  role,
}) {
  const getUserFullName = () => {
    if (role === getStaticData('roles').company_host) return 'Host (secret mode)';

    return firstName || lastName
      ? `${firstName} ${lastName}`
      : 'No name';
  };

  return (
    <Styled.UserName>
      {getUserFullName()}
    </Styled.UserName>
  );
}

/* UserName type of props */

UserName.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
};

/* UserName default props */

UserName.defaultProps = {
  firstName: null,
  lastName: null,
  role: null,
};

export default connect(({ auth }) => ({
  firstName: auth.firstName,
  lastName: auth.lastName,
  role: auth.role,
}))(UserName);
