/* Libs */
import styled from 'styled-components';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

/* Styles */

import {
  Wrapper as CardWrapper,
} from 'components/blocks/Card/styles';
import {
  Title as CardHeaderTitle,
} from 'components/blocks/ui/CardsHeader/styles';
import {
  Button as ButtonWrapper,
} from 'components/ui/Button/styles';


export const Wrapper = styled.div`
  width: 100%;
  max-height: 500px;
  margin: 17px 0 0;

  & ${CardWrapper} {
    position: relative;
    width: 100%;
    min-height: 150px;
    height: 100%;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & ${CardHeaderTitle} {
      font-size: 20px;
      line-height: 24px;
    }
  }
  & ${ButtonWrapper} {
    width: 111px;
  }
`;

export const Title = styled.h2`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 20px;
  line-height: 24px;
`;

export const Main = styled.div`
  height: calc( 100% - 30px );
  width: 100%;
  display: flex;
  align-items: center;
`;
