import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from 'reapop';
import {
  GameEntity,
  AuthEntity,
  KitsEntity,
  GameDefEntity,
  StaticDataEntity,
  LeaderboardEntity,
  ParticipantEntity,
  AdminCompanyEntity,
  ManageGameEntity,
  LS_AdminCompanyEntity,
  LS_AdminSettingsEntity,
  LS_AdminCompaniesEntity,
  LS_AdminStorylinesEntity,
} from '_entities';

export default combineReducers({
  auth: AuthEntity.reducer,
  game: GameEntity.reducer,
  kits: KitsEntity.reducer,
  gameDef: GameDefEntity.reducer,
  manageGame: ManageGameEntity.reducer,
  staticData: StaticDataEntity.reducer,
  notifications: notificationsReducer(),
  leaderboard: LeaderboardEntity.reducer,
  participant: ParticipantEntity.reducer,
  adminCompany: AdminCompanyEntity.reducer,
  LS_adminSettings: LS_AdminSettingsEntity.reducer,
  LS_adminCompanies: LS_AdminCompaniesEntity.reducer,
  LS_adminCompanyView: LS_AdminCompanyEntity.reducer,
  LS_adminStorylines: LS_AdminStorylinesEntity.reducer,
});
