/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VimeoPlayer from '@livingsecurity/react-vimeo';

/* Utils */

import { isEmpty } from 'utils/validation';

/* Components */

import { BigTitle, Button } from 'components';

/* Constants */

import { GAME_STEP_TITLES } from '_constants';

/* Styles */

import { GAME_CONFIG } from 'config';
import * as Styled from './styles';

function Storyline({
  vimeoId,
  title,
  text,
  next,
  back,
  removeCurrentStep,
  isDarkMode
}) {
  const [errorInVideo, setErrorInVideo] = useState(false);

  useEffect(() => {
    if (errorInVideo && isEmpty(text)) {
      removeCurrentStep();
    }
  }, [errorInVideo]);

  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <BigTitle>
        {title || GAME_STEP_TITLES.storyline}
      </BigTitle>
      <Styled.Content>
        {
          (!isEmpty(vimeoId) && !errorInVideo)
            ? (
              <VimeoPlayer
                videoId={vimeoId}
                autoplay
                onError={() => setErrorInVideo(true)}
              />
            )
            : <div dangerouslySetInnerHTML={{ __html: text }} />
        }
      </Styled.Content>
      <Styled.Actions>

        { back && (
          <Button
            onClick={back}
          >
                    Back
          </Button>
        )}

        <Button
          onClick={next}
        >
        Start
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* Storyline type of props */

Storyline.propTypes = {
  removeCurrentStep: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  title: PropTypes.string,
  vimeoId: PropTypes.string,
  text: PropTypes.string,
};

Storyline.defaultProps = {
  title: '',
  vimeoId: '',
  text: '',
};

export default Storyline;
