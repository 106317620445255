export const UPDATE_GAME_TIME = '@PROJECT_NAME/UPDATE_GAME_TIME';
export const UPDATE_GAME_TIME_SUCCESS = '@PROJECT_NAME/UPDATE_GAME_TIME_SUCCESS';
export const UPDATE_GAME_TIME_FAIL = '@PROJECT_NAME/UPDATE_GAME_TIME_FAIL';

export const GET_GAME_SESSION = '@PROJECT_NAME/GET_GAME_SESSION';
export const GET_GAME_SESSION_SUCCESS = '@PROJECT_NAME/GET_GAME_SESSION_SUCCESS';
export const GET_GAME_SESSION_FAIL = '@PROJECT_NAME/GET_GAME_SESSION_FAIL';

export const SET_ACTUAL_PARTICIPANTS = '@PROJECT_NAME/SET_ACTUAL_PARTICIPANTS';
export const SET_ACTUAL_PARTICIPANTS_SUCCESS = '@PROJECT_NAME/SET_ACTUAL_PARTICIPANTS_SUCCESS';
export const SET_ACTUAL_PARTICIPANTS_FAIL = '@PROJECT_NAME/SET_ACTUAL_PARTICIPANTS_FAIL';

export const GET_GAME_INFO = '@PROJECT_NAME/GET_GAME_INFO';
export const GET_GAME_INFO_SUCCESS = '@PROJECT_NAME/GET_GAME_INFO_SUCCESS';
export const GET_GAME_INFO_FAIL = '@PROJECT_NAME/GET_GAME_INFO_FAIL';

export const LAUNCH_GAME = '@PROJECT_NAME/LAUNCH_GAME';
export const LAUNCH_GAME_SUCCESS = '@PROJECT_NAME/LAUNCH_GAME_SUCCESS';
export const LAUNCH_GAME_FAIL = '@PROJECT_NAME/LAUNCH_GAME_FAIL';

export const LAUNCH_FAST_GAME = '@PROJECT_NAME/LAUNCH_FAST_GAME';
export const LAUNCH_FAST_GAME_SUCCESS = '@PROJECT_NAME/LAUNCH_FAST_GAME_SUCCESS';
export const LAUNCH_FAST_GAME_FAIL = '@PROJECT_NAME/LAUNCH_FAST_GAME_FAIL';

export const CHANGE_STEP = '@PROJECT_NAME/CHANGE_STEP';
export const CHANGE_STEP_SUCCESS = '@PROJECT_NAME/CHANGE_STEP_SUCCESS';
export const CHANGE_STEP_FAIL = '@PROJECT_NAME/CHANGE_STEP_FAIL';
export const TIMER_TICK = '@PROJECT_NAME/TIMER_TICK';

export const SET_QUESTION_IS_OPEN = '@PROJECT_NAME/SET_QUESTION_IS_OPEN';

export const HANDLE_ANSWER_CLICK = '@PROJECT_NAME/HANDLE_ANSWER_CLICK';
export const HANDLE_ANSWER_CLICK_SUCCESS = '@PROJECT_NAME/HANDLE_ANSWER_CLICK_SUCCESS';
export const HANDLE_ANSWER_CLICK_FAIL = '@PROJECT_NAME/HANDLE_ANSWER_CLICK_FAIL';

export const FINISH_GAME = '@PROJECT_NAME/FINISH_GAME';
export const FINISH_GAME_SUCCESS = '@PROJECT_NAME/FINISH_GAME_SUCCESS';
export const FINISH_GAME_FAIL = '@PROJECT_NAME/FINISH_GAME_FAIL';

export const SET_COMPLETED_TIME = '@PROJECT_NAME/SET_COMPLETED_TIME';
export const SET_COMPLETED_TIME_SUCCESS = '@PROJECT_NAME/SET_COMPLETED_TIME_SUCCESS';
export const SET_COMPLETED_TIME_FAIL = '@PROJECT_NAME/SET_COMPLETED_TIME_FAIL';

export const SET_QUESTION_ANSWERED = '@PROJECT_NAME/SET_QUESTION_ANSWERED';

export const SET_EXPIRE_DATE = '@PROJECT_NAME/SET_EXPIRE_DATE';

export const SEND_TEAM_PHOTO = '@PROJECT_NAME/SEND_TEAM_PHOTO';
export const SEND_TEAM_PHOTO_SUCCESS = '@PROJECT_NAME/SEND_TEAM_PHOTO_SUCCESS';
export const SEND_TEAM_PHOTO_FAIL = '@PROJECT_NAME/SEND_TEAM_PHOTO_FAIL';
