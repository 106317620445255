/* Libs */
import styled from 'styled-components';

import { Circle, Sign } from 'components/blocks/Markers/CircleWithSign/styles';

export const Wrapper = styled.div`
  & ${Circle} {
    width: 26px;
    height: 26px;
    border-width: 1px;
  }
  & ${Sign} {
    left: 45%;
  }
`;
