/* Libs */
import styled from 'styled-components';

/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

/* Styles */
import { Wrapper as IconTooltipWrapper } from 'components/blocks/ui/IconTooltip/styles';
import { GoBack } from 'components/ui/GoBackButton/styles';
import { LinkTo } from 'components/ui/LinkTo/styles';
import { Triangle } from 'components/blocks/Markers/ForwardTriangle/styles';
import { Wrapper as AnswersWrapper } from 'components/blocks/Sections/Report/Answers/styles';

export const Stats = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${AnswersWrapper} {
    margin-top: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 32px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;

  & ${GoBack} {
    margin-bottom: 20px;
  }
`;

export const Column = styled.div`
  &:first-child {
    flex-grow: 1;
    margin-right: 30px;
    width: 0;
  }
`;

export const Title = styled.h2`
  display: inline-block;
  position: relative;
  max-width: 80%;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 30px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${IconTooltipWrapper} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  padding-right: 20px;
`;

export const ExportButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  height: 48px;
  border-radius: 24px;
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.WHITE};
  font-size: 16px;
  line-height: 19px;
`;


export const SubTitle = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.BLACK};
  font-size: 17px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  & > ${SubTitle}:first-child {
    margin-right: 25px;
  }
  & > ${SubTitle}:last-child {
    flex: 1;
  }
`;


export const Row = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-gap: 20px;
  margin-bottom: 17px;
  width: 100%;
  @media (max-width: calc(${BREAKPOINTS.LAPTOP} - 1px)) {
    grid-template-columns: 350px 1fr;
  }
`;

export const Reports = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 500px;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 4px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
  & ${LinkTo} {
    & a {
      display: flex;
      align-items: baseline;
      cursor: pointer;
      justify-content: flex-end;
      & span {
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 12px;
        line-height: 14px;
      }

      & ${Triangle} {
        margin-left: 5px;
      }
    }
  }
`;
