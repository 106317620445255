import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  position: relative;
`;

export const Text = styled.div`
  padding: 5px 0;
  cursor: pointer;
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-size: 14px;
`;
