import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';

export const Wrapper = styled.div`
  display: flex;
  padding: 32px;
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 8px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & ${BigTitle} {
    text-transform: capitalize;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  & > *:first-child {
    flex: 1;
    width: 100px;
  }
  & > *:last-child {
    padding-left: 2px;
    flex: 1;
  }
`;

export const Hint = styled.div`
  align-self: flex-start;
  margin: 20px 0 -10px;
  font-size: 12px;
`;
