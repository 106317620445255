/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
/* Utils */
import { LS_AdminCompanyEntity } from '_entities';
import { validateText } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { notification } from 'utils/services';
import { getError } from 'utils/custom';
import { NOTIFICATIONS } from '_constants';

/* Components */

import {
  Input,
  Button,
  SmallTitle,
} from 'components';

/* Styles */
import * as Styled from './styles';

function CompanyInformation({
  data: INITIAL_DATA,
  errors: INITIAL_ERRORS,
  updateData,
  nextStep,
  getCompanyName,
}) {
  const [formData, setData] = useState({
    data: INITIAL_DATA,
    errors: INITIAL_ERRORS,
    canSubmit: INITIAL_DATA.name
      && formIsValid(
        {
          name: validateText({
            value: INITIAL_DATA.name.trim(),
            name: 'name',
            min: 2,
          }),
        },
        ['name'],
      ),
  });

  useEffect(() => {
    const { data } = formData;
    if (!isEmpty(data.name) && !isEmpty(data.location)) {
      setData(prev => ({
        ...prev,
        errors: {
          name: false,
          location: false,
        },
      }
      ));
    }
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'name':
        newErrors[name] = validateText({ value: value.trim(), name, min: 2 });
        break;
      case 'location':
        newErrors[name] = validateText({ value: value.trim(), name, min: 2 });
        break;
      default:
        break;
    }
    setData({
      data: { ...data, [name]: value },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['name', 'location']),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };

    switch (name) {
      case 'name':
        newErrors[name] = validateText({ value: value.trim(), name, min: 2 });
        break;
      case 'location':
        newErrors[name] = validateText({ value: value.trim(), name, min: 2 });
        break;
      default:
        break;
    }
    setData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['first_name', 'location']),
    }));
  };

  const handleSubmit = async () => {
    const { errors, data } = formData;
    try {
      const isUniqCompanyName = await getCompanyName(data.name)
        .then(res => !res);

      if (isUniqCompanyName) {
        updateData(data, errors);
        nextStep();
      } else {
        setData(prev => ({
          ...prev,
          errors: {
            ...errors,
            name: NOTIFICATIONS.ERROR_UQNIUE_NAME,
          },
        }
        ));
      }
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const { data, errors, canSubmit } = formData;

  return (
    <Styled.Wrapper>
      <SmallTitle>
        Company information
      </SmallTitle>
      <Input
        placeholder="Company name"
        title="Company name"
        error={errors.name}
        name="name"
        value={data.name}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Input
        title="Address (Street, City, State, Zip)"
        placeholder="Address (Street, City, State, Zip)"
        error={errors.location}
        name="location"
        value={data.location}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Button
        disabled={!canSubmit}
        onClick={handleSubmit}
      >
        {' '}
        Next
        {' '}
      </Button>
    </Styled.Wrapper>
  );
}

/* CompanyInformation type of props */

CompanyInformation.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
  }).isRequired,
  updateData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default connect(null, {
  getCompanyName: LS_AdminCompanyEntity.actions.getUniqueCompanyName,
})(CompanyInformation);
