/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';
import { Error } from 'components/ui/Input/styles';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';
import { Button } from 'components/ui/Button/styles';

export const Line = styled.div`
  display: flex;
  padding-top: 16px;
  &:first-child{
    padding-top: 0;
  }
  & > div {
    font-size: 18px;
    color: ${COLORS_CONFIG.WHITE};
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
    padding-left: 16px;
  }
  & > div:first-child {
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    padding-left: 0;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 40px;
  width: 100%;
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & ${Button} {
    background-color: ${COLORS_CONFIG.WHITE};
    color: ${COLORS_CONFIG.SKY_BLUE};
    width: 193px;
    margin: 40px auto 0px auto;
  }
  & ${BigTitle} {
    padding-bottom: 32px;
    color: ${COLORS_CONFIG.WHITE};
    text-align: center;
  }
`;
