/* Libs */
import React, {
  useState, useRef, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';

/* Actions */

import { AdminCompanyEntity } from '_entities';

/* Utils */

import { getCampaignParticipantsLink, exportCSV, getError } from 'utils/custom';

/* Components */

import {
  ActivitySection,
  QuestionsStatisticSection,
  ReportStatisticsSection as StatisticsSection,
  LeaderboardSection,
  FilterSection,
  AnswersSection,
  GoBackButton,
  ExportsMenu,
  IconTooltip,
  ForwardTriangle,
  LinkTo,
} from 'components';

import { notification } from 'utils/services';
/* Constants */

import { URLS_CONFIG, DATE_SETTINGS, COLORS_CONFIG } from 'config';

/* Assets */

import { INFO_CIRCLE_BLUE } from 'assets/icons';

/* Styles */

import * as Styled from './styles';

const PAGE_TITLE = 'Campaign';

function Campaign({
  match,
  campaigns,
  getCampaign,
  getAnswers,
  getGeneral,
  getActivity,
  getLeaderboard,
  downloadCampaignReport,
  getLocations,
}) {
  const [leaderboardPage, setLeaderboardPage] = useState(1);
  const [totalLeaderboards, setTotalLeaderboards] = useState(null);

  const wrapperRef = useRef(null);
  const campaignId = get(match, 'params.id', null);
  const [campaign, setCampaign] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filterChangeLoading, setFilterChangeLoading] = useState(false);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [totalLocations, setTotalLocations] = useState(null);
  const [locationPage, setLocationPage] = useState(1);
  const [campaignData, setCampaignData] = useState({
    answers: null,
    general: {},
  });

  const handleSearchLocations = useCallback(debounce(async (location) => {
    try {
      const { results: locations } = await getLocations({
        location,
        page: 1,
        withoutStoreChanging: true,
      });
      setLocationPage(1);
      setFetchedLocations(locations);
    } catch (error) {
      notification.error(getError(error));
    }
  }, 300), []);

  useEffect(() => {
    (async () => {
      let neededCampaign = campaigns.find(({ id }) => id === +campaignId);
      if (!neededCampaign) {
        neededCampaign = await getCampaign({ id: +campaignId });
      }
      setCampaign(neededCampaign);
      const { results, count } = await getLocations({ page: locationPage, withoutStoreChanging: true });
      setTotalLocations(count);
      setFetchedLocations(prev => prev.concat(results));
    })();
  }, []);


  useEffect(() => {
    if (get(wrapperRef, 'current', false)) {
      wrapperRef.current.scrollIntoView();
    }
  }, [campaign]);

  const activityProps = useMemo(
    () => ({
      id: campaignId,
      locations: get(filterData, 'locations', null),
      dateFrom: get(filterData, 'dateFrom', null),
      dateTo: get(filterData, 'dateTo', null),
    }),
    [filterData],
  );

  if (!campaign) return null;

  const getNextLeaderboard = async (page) => {
    setLeaderboardPage(prev => page || prev + 1);
    const response = await getLeaderboard({
      id: campaignId,
      token: campaign.token,
      locations: filterData.locations,
      with_first_answered_percents: true,
      page: page || leaderboardPage + 1,
    });
    setCampaignData(prev => ({
      ...prev,
      leaderboard: prev.leaderboard.concat(response.results),
    }));
    setTotalLeaderboards(response.count);
  };

  const handleFiltersChange = async (filters) => {
    try {
      setFilterChangeLoading(true);
      const [answers, general, leaderboard] = await Promise.all([
        getAnswers({ id: campaignId, ...filters }),
        getGeneral({ id: campaignId, ...filters }),
        getLeaderboard({
          id: campaignId,
          token: campaign.token,
          locations: filters.locations,
          with_first_answered_percents: true,
          page: 1,
        }),
      ]);
      setLeaderboardPage(1);
      setFilterChangeLoading(false);
      setFilterData(filters);
      setCampaignData({
        answers,
        general,
        leaderboard: get(leaderboard, 'results', []),
      });
      setTotalLeaderboards(leaderboard.count);
    } catch (error) {
      setFilterChangeLoading(false);
      notification.error(getError(error));
    }
  };

  const exportCampaignCSV = async (overall) => {
    const dates = {
      dateFrom: moment(overall ? campaign.created : filterData.dateFrom).format(
        DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
      ),
      dateTo: moment(overall ? [] : filterData.dateTo).format(
        DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
      ),
    };
    const report = await downloadCampaignReport({
      id: campaignId,
      ...dates,
    });
    exportCSV(
      report,
      `${campaign.name}_report_from_${dates.dateFrom}_to_${dates.dateTo}`,
    );
  };


  const getNextLocation = async () => {
    try {
      setFilterChangeLoading(true);
      setLocationPage(prev => prev + 1);
      const locationResponse = await getLocations({
        page: locationPage + 1,
        withoutStoreChanging: true,
      });
      setFetchedLocations(prev => prev.concat(locationResponse.results));
      setFilterChangeLoading(false);
    } catch (error) {
      setFilterChangeLoading(false);
      notification.error(getError(error));
    }
  };

  const exportActions = [
    {
      text: 'Overall report',
      onClick: () => exportCampaignCSV(true),
    },
    {
      text: 'Report for chosen dates',
      onClick: () => exportCampaignCSV(),
    },
  ];

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.Header>
        <Styled.Column>
          <GoBackButton to={URLS_CONFIG.companyAdmin.reports.main}>
            Back
          </GoBackButton>
          <Styled.TitleWrapper>
            <Styled.Title>
              Living security at
              {' '}
              {campaign.name}
            </Styled.Title>
            {!campaign.is_active && (
              <IconTooltip
                src={INFO_CIRCLE_BLUE}
                items={['This campaign is inactive.']}
              />
            )}
          </Styled.TitleWrapper>
          <Styled.SubTitleWrapper>
            <Styled.SubTitle>
              Date created:
              {' '}
              {moment(campaign.created).format(
                DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR_DOT,
              )}
            </Styled.SubTitle>
            <Styled.SubTitle>
              Storyline:
              {' '}
              {get(campaign, 'storyline.name', '')}
            </Styled.SubTitle>
          </Styled.SubTitleWrapper>
        </Styled.Column>
        <Styled.Column>
          <Styled.Actions>
            <ExportsMenu actions={exportActions} />
            <LinkTo
              to={getCampaignParticipantsLink(campaignId)}
              text="View participants"
            >
              <ForwardTriangle color={COLORS_CONFIG.SKY_BLUE} />
            </LinkTo>
          </Styled.Actions>
        </Styled.Column>
      </Styled.Header>
      <Styled.Row>
        <FilterSection
          startDate={campaign.created}
          onChange={handleFiltersChange}
          loading={filterChangeLoading}
          next={getNextLocation}
          locations={fetchedLocations}
          totalLocations={totalLocations}
          handleSearchLocations={handleSearchLocations}
        />
        <Styled.Stats>
          <StatisticsSection
            data={campaignData.general}
            link={getCampaignParticipantsLink(campaignId)}
            loading={filterChangeLoading}
          />
          <AnswersSection
            loading={filterChangeLoading}
            first={get(
              campaignData,
              'answers.answered_first_time_percentage',
              null,
            )}
            second={get(
              campaignData,
              'answers.answered_second_time_percentage',
              null,
            )}
          />
        </Styled.Stats>
      </Styled.Row>
      <Styled.Row>
        <LeaderboardSection data={campaignData.leaderboard} next={getNextLeaderboard} total={totalLeaderboards} />
        <QuestionsStatisticSection
          data={get(campaignData, 'answers.answered_first_time_stat', [])}
        />
      </Styled.Row>
      <ActivitySection request={getActivity} options={activityProps} />
    </Styled.Wrapper>
  );
}

/* Page Url */

Campaign.path = URLS_CONFIG.companyAdmin.reports.campaign;

/* Page Title */

Campaign.title = PAGE_TITLE;

/* OverallCompanyReport type of props */

Campaign.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  ),
  getCampaign: PropTypes.func.isRequired,
  getAnswers: PropTypes.func.isRequired,
  getGeneral: PropTypes.func.isRequired,
  getActivity: PropTypes.func.isRequired,
  getLeaderboard: PropTypes.func.isRequired,
  downloadCampaignReport: PropTypes.func.isRequired,
};

/* OverallCompanyReport default props */

Campaign.defaultProps = {
  campaigns: [],
};

const mapState = ({ adminCompany: { campaigns } }) => ({
  campaigns,
});

const mapDispatch = {
  getCampaign: AdminCompanyEntity.actions.getCampaign,
  getAnswers: AdminCompanyEntity.actions.getCampaignReportsAnswers,
  getGeneral: AdminCompanyEntity.actions.getCampaignReportsGeneral,
  getActivity: AdminCompanyEntity.actions.getCampaignReportsActivity,
  getLeaderboard: AdminCompanyEntity.actions.getLeaderboard,
  downloadCampaignReport: AdminCompanyEntity.actions.downloadCampaignReport,
  getLocations: AdminCompanyEntity.actions.getLocations,
};

export default connect(mapState, mapDispatch)(Campaign);
