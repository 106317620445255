import moment from 'moment';
import { getTimezoneName, getTimezoneOffset } from 'utils/custom';

const getTimeInUTCFormat = (dateString, timezone) => {
  const timezoneName = getTimezoneName(timezone);
  const offset = getTimezoneOffset(timezoneName, dateString);

  return moment(dateString).utc().add(offset, 'minutes');
};

export default getTimeInUTCFormat;
