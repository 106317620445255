/* Libs */
import styled, { css } from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  margin: 0 0 9px  
`;

export const SubTitle = styled.div`
  margin: 0 0 13px
  width: 100%;
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.WHITE};
`;

export const Location = styled.div`
  color: ${COLORS_CONFIG.WHITE};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Line = styled.div`
  width: 32px;
  height: 2px;
  background-color: ${COLORS_CONFIG.WHITE};
`;

export const Wrapper = styled.div`
  padding-top: 33px;
  padding-bottom: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({ isAnonymous }) => isAnonymous && css`
    padding-bottom: 16px;
    ${SubTitle} {
      padding-bottom: 16px;
    }
  `}
`;
