import React from 'react';
import { Img, Logo } from 'components/index';
import { LOGO_CONFIG } from 'config/index';
import { connect } from 'react-redux';
import * as Styled from './styles';


function AfterGameHeader({
  leftSideHeader,
  rightSideHeader,
  isDarkMode,
  logo,
}) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <Styled.Logo>
        {
          logo
            ? (
              <Img
                disabled
                src={logo}
                size={[205, 80]}
              />
            )
            : (
              <Logo
                type={isDarkMode
                  ? LOGO_CONFIG.HORIZONTAL_BLUE_WHITE
                  : LOGO_CONFIG.HORIZONTAL_BLUE_BLACK}
              />
            )
        }
      </Styled.Logo>
      {
        leftSideHeader && (
          <Styled.Left>
            {leftSideHeader}
          </Styled.Left>
        )
      }
      {
        rightSideHeader && (
          <Styled.Right>
            {rightSideHeader}
          </Styled.Right>
        )
      }
    </Styled.Wrapper>
  );
}


/* AfterGameHeader type of props */

AfterGameHeader.propTypes = {};

/* AfterGameHeader default props */

AfterGameHeader.defaultProps = {};

export default connect(({ game }) => ({
  logo: game.logo,
}))(AfterGameHeader);
