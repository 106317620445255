import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';

export const GoBack = styled.div`
  display: block;
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  font-size: 15px;
  line-height: 18px;
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid ${COLORS_CONFIG.SKY_BLUE};
    border-right: 1px solid ${COLORS_CONFIG.SKY_BLUE};
    transform: translateY(-5px) rotate(135deg) ;
  }
`;
