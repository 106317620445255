/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Circle } from 'components/blocks/Markers/CircleWithSign/styles';

export const Wrapper = styled.div`
  position: relative;
  padding-top: 23px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isEditable }) => (isEditable ? 'space-between' : 'center')};
`;

export const Action = styled.div`
  & ${Circle} {
    width: 32px;
    height: 32px;
  }
`;
export const ParticipantsCount = styled.input`
  padding-top: 5px;
  font-size: 88px;
  line-height: 88px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_5};
  border-radius: 4px;
  width: 130px;
  height: 88px;
  background-color: ${COLORS_CONFIG.WHITE};
  text-align: center;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  ${({ disabled }) => disabled && css`
    border: none;
  `}
`;
