import styled from 'styled-components';

export const Sign = styled.div`
  width: 8px;
  height: 8px;
  border: 1px ${({ color }) => color} solid;
  border-bottom: none;
  border-right: none;
  transform: rotate(130deg);
`;
