/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

/* Styles */

// import { LinkTo } from 'components/ui/LinkTo/styles';
import { Button } from 'components/ui/Button/styles';
import { Tooltip, Content as TooltipContent } from 'components/blocks/ui/Tooltip/styles';
import { Wrapper as TextWithTooltip } from 'components/ui/TextWithTooltip/styles';
import { default as Triangle } from 'components/blocks/Markers/BackTriangle/styles';

export const Label = styled.div`
  height: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 5px;
`;

export const SubTitle = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 12px;
  line-height: 14px;
`;

export const ExportButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  height: 48px;
  border-radius: 24px;
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.WHITE};
  font-size: 16px;
  line-height: 19px;
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 110px;
  height: 35px;
  align-items: center;
  margin: 0 20px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding: 0 40px 0 20px;
  width: 100%;
  min-height: 87px;
  background-color: ${props => (props.disabled ? '#FFF0F0' : '#ffffff')};
  & ${Button} {
    justify-self: flex-end;
  }
  & ${Label}:first-child {
    & > ${SubTitle} {
      color: ${COLORS_CONFIG.GREY_CUSTOM_6};
      font-size: 14px;
      line-height: 17px;
      @media (max-width: calc(${BREAKPOINTS.LAPTOP} - 1px)) {
        font-size: 12px;
        line-height: 1.2;
      }
    }
  }
  @media (max-width: calc(${BREAKPOINTS.LAPTOP} - 1px)) {
    padding: 5px 10px;
    ${Button} {
      padding: 0;
      min-width: 150px;
      height: 40px;
      font-size: 15px;
    }
  }
`;

export const CampaignName = styled.div`
  ${TextWithTooltip} {
    display: flex;
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
    position: relative;
    @media (max-width: calc(${BREAKPOINTS.LAPTOP} - 1px)) {
      font-size: 15px;
      line-height: 1.2;
    }
  }
  ${Tooltip} {
    top: 26px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.31);
    z-index: 2;
    ${Triangle} {
      top: -5px;
      left: 8px;
      border-width: 0 4px 6px 4px;
      border-color: transparent transparent ${COLORS_CONFIG.WHITE} transparent;
    }
    ${TooltipContent} {
      min-height: unset;
      width: auto;
      font-size: 14px;
      padding: 6px 10px;
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
