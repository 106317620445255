import { BECompanyToFECompanyFormat } from 'utils/transformers';
import { COMPANIES_TABLE } from 'config';
import * as TYPES from './Companies.types';

const initialState = {
  loading: false,
  companies: [],
  total: 0,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // case TYPES.ADD_COMPANY:
    case TYPES.ACTIVATE_COMPANY:
    case TYPES.GET_ALL_COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.result.count,
        companies: action.result.results.map(result => BECompanyToFECompanyFormat(result)),
      };
    case TYPES.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [
          BECompanyToFECompanyFormat(action.result),
          ...state.companies.slice(0, COMPANIES_TABLE.PAGINATION.PER_PAGE - 1),
        ],
        total: state.total + 1,
        loading: false,
      };
    case TYPES.ACTIVATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map((company) => {
          if (company.id !== action.result.id) return company;
          return {
            ...company,
            is_active: action.result.is_active,
          };
        }),
        total: state.total - 1,
        loading: false,
      };
    // case TYPES.ADD_COMPANY_FAIL:
    case TYPES.ACTIVATE_COMPANY_FAIL:
    case TYPES.GET_ALL_COMPANIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
