import { ADMINS_TABLE } from 'config';
import * as TYPES from './Settings.types';

const initialState = {
  loading: false,
  users: [],
  total: 0,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_USERS:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.result.results,
        total: action.result.count,
        loading: false,
      };
    case TYPES.ADD_USER_SUCCESS: {
      const newUsers = [action.result, ...state.users];
      return {
        ...state,
        users: newUsers.length > ADMINS_TABLE.PAGINATION.PER_PAGE
          ? newUsers.slice(0, ADMINS_TABLE.PAGINATION.PER_PAGE)
          : newUsers,
        total: state.total + 1,
      };
    }
    case TYPES.UPDATE_USER_SUCCESS: {
      const shouldRemoveUser = state.users.some(user => user.id === action.result.id && user.is_active !== action.result.is_active);
      const filteredUsers = state.users.reduce((result, user) => (user.id === action.result.id
        ? shouldRemoveUser
          ? result
          : [...result, {
            ...user,
            ...action.result,
          }]
        : [...result, user]),
      []);
      return {
        ...state,
        users: filteredUsers,
        total: shouldRemoveUser ? state.total - 1 : state.total,
      };
    }
    case TYPES.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload.id),
        total: state.total - 1,
      };
    case TYPES.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
