/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import { COLORS_CONFIG, AVATAR_CONFIG, FONTS_CONFIG } from 'config';

// Component styles
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ size }) => size === AVATAR_CONFIG.SIZE.S && css`
    width: 32px;
    min-width: 32px;
    height: 32px;
  `}
  ${({ size }) => size === AVATAR_CONFIG.SIZE.M && css`
    width: 43px;
    min-width: 43px;
    height: 43px;
  `}
`;

export const Avatar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-position: 50% 50%;
  background-color: ${COLORS_CONFIG.SILVER_LIGHT};
  background-repeat: no-repeat;
  background-size: cover;
  ${({ avatar }) => !avatar && css`
    font-size: 16px;
    color: ${COLORS_CONFIG.WHITE};
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  `}
`;
