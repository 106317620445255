/* Libs */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Utils */
import { isEmpty, isNull } from 'utils/validation';
import { URLS_CONFIG } from 'config';
import { impersonateService } from 'utils/services';

function CompanyAdminRoute({
  authorized,
  isAdmin,
  isCompanyAdmin,
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        let impesonatedCorrectly = isCompanyAdmin;
        if (isAdmin) {
          impesonatedCorrectly = impersonateService.canImpersonate();
        }
        const history = [props.history.location.pathname, ...get(props, 'location.state.history', [])];
        let redirect = get(props, 'location.state.from.pathname', URLS_CONFIG.auth.signin);
        redirect = impesonatedCorrectly
          ? redirect
          : isAdmin
            ? URLS_CONFIG.admin.default
            : URLS_CONFIG.auth.signin;
        return impesonatedCorrectly
          ? (
          // return hasPermission(PERMISSION_NAMES.CAN_VIEW_COMPANY_ADMIN_PAGES) ? (
            <Component {...props} />
          )
          : (
            <Redirect
              to={{
                pathname: redirect,
                search: get(props, 'location.state.from.search', ''),
                state: {
                  from: props.location,
                  history,
                },
              }}
            />
          );
      }}
    />
  );
}

/* Component PropTypes */

CompanyAdminRoute.propTypes = {
  authorized: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isCompanyAdmin: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth, staticData }) => ({
  authorized: !isEmpty(auth.token) && !isNull(auth.id),
  isCompanyAdmin: [staticData.roles.company_admin, staticData.roles.admin].includes(auth.role),
  isAdmin: staticData.roles.admin === auth.role,
});

export default connect(mapStateToProps)(CompanyAdminRoute);
