/* Libs */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { notification } from 'utils/services';
import { getError } from 'utils/custom';

/* Constants */

import { STORYLINES_LIST } from 'config';

/* Components */

import { ProgressBarPointers, Pagination } from 'components';
import { formIsValid } from 'utils/validation';
import { validateText } from 'utils/validation/fields';
import { NOTIFICATIONS } from '_constants';
import moment from 'moment';
import {
  AvailableStorylines,
  CampaignName,
} from './Steps';


/* Styles */

import * as Styled from './styles';

const DEFAULT_DATA = {
  selectedStoryline: {},
  campaign_name: '',
};

const DEFAULT_ERRORS = {
  campaign_name: null,
};

function CreateCampaignForm({
  onClose,
  company,
  setLink,
  onCreateCampaign,
  onLoadStorylines,
}) {
  const storylinesAreMounted = useRef(false);
  const [currentStep, changeStep] = useState(1);
  const [formData, setFormData] = useState({
    data: DEFAULT_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalStorylines, setTotalStorylines] = useState(0);
  const [storylines, setStorylines] = useState([]);

  const handlePageChange = ({ selected }) => setPage(selected + 1);
  const nextStep = () => changeStep(currentStep + 1);
  const previousStep = () => changeStep(currentStep - 1);

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'campaign_name':
        newErrors[name] = validateText({ value: value.trim(), name, min: 2 });
        break;
      default:
        break;
    }
    setFormData({
      data: { ...data, [name]: value },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['campaign_name']),
    });
  };


  const validationOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'campaign_name':
        newErrors[name] = validateText({ value: value.trim(), name, min: 2 });
        break;
      default:
        break;
    }
    setFormData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['campaign_name']),
    }));
  };


  const handleStorylineDoubleClick = (value) => {
    handleChange({ target: { name: 'selectedStoryline', value } });
    nextStep();
  };


  const createCampaign = async () => {
    const { data: { selectedStoryline, campaign_name } } = formData;
    try {
      setLoading(true);
      const publicInviteLink = await onCreateCampaign({
        storyline: selectedStoryline.id,
        company,
        name: campaign_name,
        timezone: moment.tz.guess(),
      });

      setLoading(false);

      if (!publicInviteLink) return;

      setLink(publicInviteLink);
      notification.success(NOTIFICATIONS.SUCCESS_CREATE_CAMPAIGN);
      onClose();
    } catch (error) {
      setLoading(false);
      if (getError(error).trim() === NOTIFICATIONS.ERROR_CAMPAIGN_EXISTS) {
        setFormData(prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            campaign_name: getError(error).trim(),
          },
          canSubmit: false,
        }));
        return;
      }
      notification.error(getError(error));
    }
  };

  const manageStep = () => {
    switch (currentStep) {
      case 2:
        return (
          <CampaignName
            nextStep={nextStep}
            previousStep={previousStep}
            formData={formData}
            onChange={handleChange}
            onBlur={validationOnBlur}
            handleSubmit={createCampaign}
            loading={loading}
          />
        );
      case 1:
      default:
        return (
          <AvailableStorylines
            nextStep={nextStep}
            handleChange={handleChange}
            onDoubleClick={handleStorylineDoubleClick}
            selectedStoryline={formData.data.selectedStoryline}
            storylines={storylines}
            loading={loading}
            pagination={(
              <Pagination
                activePage={page}
                pageChangeHandler={handlePageChange}
                pageCount={totalStorylines / STORYLINES_LIST.PAGINATION.PER_PAGE}
              />
            )}
          />
        );
    }
  };

  const loadStorylines = async () => {
    try {
      setLoading(true);

      const { results: storylines, count } = await onLoadStorylines({ page, company });

      setStorylines(storylines);
      setTotalStorylines(count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error(getError(error));
    }
  };

  useEffect(() => {
    loadStorylines();
  }, []);

  useEffect(() => {
    if (!storylinesAreMounted.current) {
      storylinesAreMounted.current = true;
      return;
    }

    loadStorylines();
  }, [page]);

  return (
    <Styled.Wrapper>
      <Styled.Content>
        {manageStep()}
      </Styled.Content>
      <ProgressBarPointers
        current={currentStep}
        total={2}
      />
    </Styled.Wrapper>
  );
}

/* CreateCampaignForm type of props */

CreateCampaignForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
  setLink: PropTypes.func.isRequired,
  onCreateCampaign: PropTypes.func.isRequired,
  onLoadStorylines: PropTypes.func.isRequired,
};

/* CreateCampaignForm default props */

CreateCampaignForm.defaultProps = {};

export default CreateCampaignForm;
