/* Libs */
import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  Input,
  Textarea,
  BigTitle,
  GameCardPreviewModal,
  PreviewCard,
} from 'components';

/* Constants */

import { GAME_DEF_FORM } from '_constants';

/* Styles */

import * as Styled from './styles';

const StepForm = memo(({
  step, data, errors, onChange,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      onChange({
        target: {
          name,
          value,
          section: step,
        },
      });
    },
    [step],
  );

  return (
    <Styled.Wrapper>
      <BigTitle>
        {step}
        <Styled.Optional>(optional)</Styled.Optional>
      </BigTitle>
      <Styled.PreviewWrapper>
        <Styled.Preview onClick={() => setPreviewOpen(true)}>
          preview
        </Styled.Preview>
        <Input
          placeholder="Title"
          name={`${step}_title`}
          value={data[`${step}_title`]}
          onChange={handleChange}
          error={errors[`${step}_title`]}
        />
      </Styled.PreviewWrapper>
      <Textarea
        placeholder="Add Message"
        name={`${step}_text`}
        value={data[`${step}_text`]}
        onChange={handleChange}
        error={errors[`${step}_text`]}
      />
      <Input
        placeholder="Vimeo ID"
        type="number"
        name={`${step}_vimeo_id`}
        value={data[`${step}_vimeo_id`]}
        onChange={handleChange}
        error={errors[`${step}_vimeo_id`]}
      />
      <GameCardPreviewModal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
      >
        <PreviewCard
          step="introduction"
          title={data[`${step}_title`]}
          text={data[`${step}_text`]}
          vimeoId={data[`${step}_vimeo_id`]}
        />
      </GameCardPreviewModal>
    </Styled.Wrapper>
  );
});

/* StepForm type of props */

StepForm.propTypes = {
  step: PropTypes.oneOf(Object.values(GAME_DEF_FORM.STEPS)).isRequired,
  data: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
};

export default StepForm;
