/* Libs */
import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Email = styled.div`
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  width: 34px;
`;
