/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import { ANSWER_STATUS } from '_constants';

/* Styles */

import * as Styled from './styles';

function AnswerListItem({
  answer,
  status,
  clicked,
  handleAnswer,
  isDarkMode
}) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode} onClick={clicked ? null : handleAnswer} status={status}>
      {answer}
      <Styled.Hint>{ status === ANSWER_STATUS.CORRECT ? 'Correct!' : 'Wrong!' }</Styled.Hint>
    </Styled.Wrapper>
  );
}

/* AnswerListItem type of props */

AnswerListItem.propTypes = {
  answer: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    ANSWER_STATUS.NOT_ANSWERED,
    ANSWER_STATUS.CORRECT,
    ANSWER_STATUS.WRONG,
  ]),
};

export default AnswerListItem;
