import moment from 'moment';

export default ({
  kits = [],
  identifier = 'id',
} = {}) => {
  const now = moment();

  return (
    kits.reduce((arr, kit) => {
      const expired = moment(kit.license_end).isBefore(now);
      return (
        arr.concat({
          value: kit[identifier],
          label: `${kit.nickname ? `${kit.nickname} (id: ${kit.kit_id})` : `id: ${kit.kit_id}`} ${expired ? '(expired)' : ''}`,
          disabled: expired,
          founder_id: kit.founder_id || null,
        })
      );
    }, [])
  );
};
