/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import { STRUCT_IDS } from '_constants';

/* Components */

import { CompanyAdminHeader, Watermark } from 'components';

/* Styles */

import { connect } from 'react-redux';
import * as Styled from './styles';

function CompanyAdminStruct({
  sidebar,
  children,
  logo
}) {
  return (
    <Styled.Struct>
      <Styled.Header>
        <CompanyAdminHeader />
      </Styled.Header>
      <Styled.Page id={STRUCT_IDS.COMPANY_ADMIN_PAGE}>
        {sidebar && (
          <Styled.Sidebar>
            {sidebar}
          </Styled.Sidebar>
        )}
        <Styled.Content id={STRUCT_IDS.COMPANY_ADMIN_PAGE_CONTENT}>
          {children}
        </Styled.Content>
      </Styled.Page>
      <Watermark logo={logo} />
    </Styled.Struct>
  );
}

/* CompanyAdminStruct type of props */

CompanyAdminStruct.propTypes = {
  sidebar: PropTypes.node,
  children: PropTypes.node.isRequired,
};

/* CompanyAdminStruct default props */

CompanyAdminStruct.defaultProps = {
  sidebar: null,
};


export default connect(({ adminCompany, auth }) => ({
  logo: (adminCompany.company && adminCompany.company.logo) || auth.companyLogo,
}))(CompanyAdminStruct);
