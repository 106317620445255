/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Table } from 'components/blocks/Table/styles';
import { Cell as ActionCell } from 'components/blocks/TableCells/ActionsCell/styles';

// TODO: styles of action cell after logic is completed

export const LeaderboardTable = styled.div`
  margin: 35px 0;

  & ${Table} {
    & ${ActionCell} {
      & > *:not(:first-child)::before {
          background-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      }
      & div {
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  
  ${({ isDarkMode }) => isDarkMode && css`
        background-color: ${COLORS_CONFIG.DARK_BLUE};
        
        & ${Table}{
            background-color: ${COLORS_CONFIG.TRANSPARENT};
            box-shadow: initial;
            & .rt-thead.-header {
              box-shadow: initial;
            }
            
            & .rt-noData {
              color: ${COLORS_CONFIG.WHITE};
            }
            
            & .react-table {
              &__wrapper .rt-table {
                border-radius: initial;
                box-shadow: initial;
                & .rt-thead {
                  padding-top: 0px!important;
                  background-color: ${COLORS_CONFIG.TRANSPARENT};
                  
                  .rt-th > div{
                    color: ${COLORS_CONFIG.WHITE};
                  }
                }
                & .rt-tbody {
                  background-color: ${COLORS_CONFIG.TRANSPARENT};
                  & .rt-tr {
                    padding: 24px 52px 22px 52px!important;
                    .rt-td > div{
                      color: ${COLORS_CONFIG.WHITE};
                    }
                  }
                  & .rt-tr-group {
                    border-bottom-color: ${COLORS_CONFIG.OXFORD_BLUE};
                  }
                  & .rt-tr-group:first-child {
                    border-top:1px solid ${COLORS_CONFIG.OXFORD_BLUE};
                  }
                  & .rt-tr-group:last-child {
                    border-bottom:1px solid ${COLORS_CONFIG.OXFORD_BLUE};
                  }
                }
              }
            }
        }
  `}
`;

export default LeaderboardTable;
