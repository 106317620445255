export default {
  VERTICAL_WHITE_WHITE: 'VERTICAL_WHITE_WHITE',
  VERTICAL_BLUE_WHITE: 'VERTICAL_BLUE_WHITE',
  VERTICAL_BLUE_BLACK: 'VERTICAL_BLUE_BLACK',
  VERTICAL_BLACK_BLACK: 'VERTICAL_BLACK_BLACK',

  HORIZONTAL_BLACK_BLACK: 'HORIZONTAL_BLACK_BLACK',
  HORIZONTAL_BLUE_BLACK: 'HORIZONTAL_BLUE_BLACK',
  HORIZONTAL_BLUE_WHITE: 'HORIZONTAL_BLUE_WHITE',
  HORIZONTAL_WHITE_WHITE: 'HORIZONTAL_WHITE_WHITE',

  HORIZONTAL_BLUE_BLACK_BLACK: 'HORIZONTAL_BLUE_BLACK_BLACK',
  HORIZONTAL_BLACK_BLACK_BLACK: 'HORIZONTAL_BLACK_BLACK_BLACK',
  HORIZONTAL_BLUE_BLUE_WHITE: 'HORIZONTAL_BLUE_BLUE_WHITE',
  HORIZONTAL_WHITE_BLUE_WHITE: 'HORIZONTAL_WHITE_BLUE_WHITE',
};
