/* Libs */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */

import { Img, EditGameTimeModal } from 'components';

/* Constants */

import { TIME } from '_constants';
import { GAME_CONFIG } from 'config';

/* Utils */

import { getStaticData } from 'utils/custom';

/* Styles */

import { EDIT } from 'assets/icons';
import { useToggleBooleanHook } from 'hooks';
import * as Styled from './styles';


function ManageGameTimer({
  gameTime,
  mode,
  statusCode,
}) {
  const [isOpenedForm, toggleForm] = useToggleBooleanHook(false);
  const closeModal = useMemo(() => () => toggleForm(false), []);
  const statuses = useMemo(() => getStaticData('gamePlayStatuses'), []);

  if (statusCode === 5) return null;
  return (
    <Styled.Wrapper>
      <Styled.TimeBlock>
        {(gameTime.minutes() + gameTime.hours() * TIME.MINUTES_IN_HOUR).toString().padStart(2, '0')}
      </Styled.TimeBlock>
      <Styled.Delimitr>:</Styled.Delimitr>
      <Styled.TimeBlock>
        {gameTime.seconds().toString().padStart(2, '0')}
      </Styled.TimeBlock>
      {
        mode !== GAME_CONFIG.MANAGER.MODE.VIEW
        && (
          <Img
            src={EDIT}
            size={[34, 34]}
            onClick={toggleForm}
            disabled={statusCode === statuses.game_failed}
            grayscaleOnDisabled
          />
        )
      }
      <EditGameTimeModal
        open={isOpenedForm}
        onClose={closeModal}
      />
    </Styled.Wrapper>
  );
}

/* ManageGameTimer type of props */

ManageGameTimer.propTypes = {
  gameTime: PropTypes.instanceOf(moment).isRequired,
  mode: PropTypes.oneOf(Object.values(GAME_CONFIG.MANAGER.MODE)).isRequired,
  statusCode: PropTypes.number.isRequired,
};

export default ManageGameTimer;
