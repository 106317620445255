/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import * as Styled from './styles';

function CampaignList({
  currentCampaign,
  campaigns,
  campaignChangeHandler,
  pagination,
}) {
  const hasPagination = campaigns.length > 0 && Boolean(pagination);

  return (
    <Styled.Wrapper>
      <Styled.Content>
        {
          campaigns.length
            ? (
              <Styled.List>
                {
                  campaigns.map(({ name, id }) => (
                    <Styled.Item
                      key={id}
                      active={currentCampaign === id}
                      onClick={() => campaignChangeHandler({ id })}
                    >
                      <Styled.ItemText>{name}</Styled.ItemText>
                    </Styled.Item>
                  ))
                }
              </Styled.List>
            )
            : null
        }
        {hasPagination && pagination}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* CampaignList type of props */

CampaignList.propTypes = {
  currentCampaign: PropTypes.number,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ),
  campaignChangeHandler: PropTypes.func.isRequired,
  pagination: PropTypes.node.isRequired,
};

/* CampaignList default props */

CampaignList.defaultProps = {
  currentCampaign: null,
  campaigns: [],
};

export default CampaignList;
