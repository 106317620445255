/* Libs */
import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { AdminCompanyEntity, ManageGameEntity } from '_entities';

/* Utils */

import { getGameLink, redirect } from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import { ContainerLoader } from 'components';

/* Constants */

import { NOTIFICATIONS } from '_constants';
import { NOTIFICATIONS_CONFIG, GAME_CONFIG, URLS_CONFIG } from 'config';


/* Styles */

import * as Styled from './styles';


function StoppedGameActions({
  resetGame,
  continueGame,
  closeGame,
  loading,
  mode,
  deleteGameSession,
  campaignId,
  sessionId,
  sessionStatus,
  role,
  sessionType,
}) {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => setDisabled(loading), [loading]);

  const urlRolePath = useMemo(() => (['host', 'company_host'].includes(role) ? 'host' : 'companyAdmin'),
    [role]);

  const continueCurrentGame = async () => {
    try {
      setDisabled(true);
      const { token } = await continueGame();
      redirect(getGameLink(token));
    } catch (error) {
      setDisabled(false);
      notification.error(error);
    }
  };

  const closeCurrentGame = async () => {
    try {
      setDisabled(true);
      await closeGame();
      setDisabled(false);
      redirect(
        `${URLS_CONFIG[urlRolePath].campaigns}?campaign=${campaignId}&sessionType=${sessionType}`,
        NOTIFICATIONS.SUCCESS_GAME_CLOSED,
      );
    } catch (error) {
      setDisabled(false);
      notification.error(error);
    }
  };

  const resetCurrentGame = async () => {
    try {
      setDisabled(true);
      const { token } = await resetGame();
      redirect(getGameLink(token));
    } catch (error) {
      setDisabled(false);
      notification.error(error);
    }
  };

  const handleDeleteSession = async () => {
    try {
      await deleteGameSession({ campaignId, sessionId, status: sessionStatus });
      redirect(
        `${URLS_CONFIG[urlRolePath].campaigns}?campaign=${campaignId}&sessionType=${sessionType}`,
        NOTIFICATIONS.SUCCESS_DELETE_SESSION,
      );
    } catch (error) {
      notification.error(error);
    }
  };

  const continueGameAction = useCallback(() => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
    {
      onSuccess: continueCurrentGame,
      onCancel: notification.removeAllNotifications,
      message: 'You will redirect to current game',
    },
  )), []);
  const resetGameAction = useCallback(() => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
    {
      onSuccess: resetCurrentGame,
      onCancel: notification.removeAllNotifications,
      message: 'You will start game from scratch',
    },
  )), []);
  const closeGameAction = useCallback(() => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
    {
      onSuccess: closeCurrentGame,
      onCancel: notification.removeAllNotifications,
      message: 'You will close this game with FAILED status',
    },
  )), []);

  const deleteSessionAction = useCallback(() => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
    {
      onSuccess: handleDeleteSession,
      onCancel: notification.removeAllNotifications,
      message: 'Are you sure you want to delete this session?',
    },
  )), []);

  return (
    <Styled.Wrapper>
      { disabled && <ContainerLoader />}
      {mode === GAME_CONFIG.MANAGER.MODE.STOPPED && (
        <>
          <Styled.Action type="continue" onClick={continueGameAction}>
            Continue
          </Styled.Action>
          <Styled.Action type="reset" onClick={resetGameAction}>
            Reset
          </Styled.Action>
          <Styled.Action type="close" onClick={closeGameAction}>
            Close
          </Styled.Action>
          {!['host', 'company_host'].includes(role) && (
            <Styled.Action type="delete" onClick={deleteSessionAction}>
              Delete
            </Styled.Action>
          )}
        </>
      )}
      {mode === GAME_CONFIG.MANAGER.MODE.EDIT && !['host', 'company_host'].includes(role) && (
        <Styled.Action type="delete" onClick={deleteSessionAction}>
          Delete
        </Styled.Action>
      )}
    </Styled.Wrapper>
  );
}

/* StoppedGameActions type of props */

StoppedGameActions.propTypes = {
  resetGame: PropTypes.func.isRequired,
  continueGame: PropTypes.func.isRequired,
  closeGame: PropTypes.func.isRequired,
  deleteGameSession: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(Object.values(GAME_CONFIG.MANAGER.MODE)).isRequired,
  campaignId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  sessionStatus: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  sessionType: PropTypes.oneOf(Object.values(GAME_CONFIG.SESSION_TYPES)).isRequired,
};

/* StoppedGameActions default props */

StoppedGameActions.defaultProps = {};

export default connect(state => ({
  campaignId: state.manageGame.gameSession.campaign,
  sessionId: state.manageGame.gameSession.id,
  sessionStatus: state.manageGame.gameSession.status,
  role: state.auth.role,
}), {
  resetGame: ManageGameEntity.actions.resetGame,
  continueGame: ManageGameEntity.actions.continueGame,
  closeGame: ManageGameEntity.actions.closeGame,
  deleteGameSession: AdminCompanyEntity.actions.deleteGameSession,
})(StoppedGameActions);
