import { COMMON_ERRORS } from '_constants/errors';
import { isEmpty } from 'utils/validation';

export default ({
  value, name, initial = false,
}) => {
  let error = (isEmpty(value) && COMMON_ERRORS.isRequired(name));
  error = error && initial ? null : error;
  return error;
};
