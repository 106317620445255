/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Constants */
import {
  TABLES_CONFIG,
  USER_TYPES,
} from 'config';

/* Components */

import {
  Loader,
  UsersTable,
} from 'components';

/* Styles */
import * as Styled from './styles';

function UsersMainSection({
  actions,
  toggleBar,
  filter,
  tableData,
  loading,
  userType,
  resendEmailToUser,
  openEditUser,
  activateUser,
  pagination,
  search,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.TitleWrapper>
          <Styled.Title>
            Users
          </Styled.Title>
          {search}
        </Styled.TitleWrapper>
        <Styled.Actions>
          {actions}
        </Styled.Actions>
      </Styled.Header>
      <Styled.Filters>
        {toggleBar}
        {filter}
      </Styled.Filters>
      {
        loading
          ? <Loader />
          : (
            <UsersTable
              data={tableData}
              tableSize={TABLES_CONFIG.SIZE.M}
              userType={userType}
              actions={{
                resendEmailToUser,
                openEditUser,
                activateUser,
              }}
              pagination={pagination}
            />
          )
      }
    </Styled.Wrapper>
  );
}

/* UsersMainSection type of props */
const {
  arrayOf, node, bool, string, func, shape, number, oneOf,
} = PropTypes;

UsersMainSection.propTypes = {
  actions: arrayOf(node).isRequired,
  toggleBar: node.isRequired,
  filter: node.isRequired,
  tableData: arrayOf(
    shape({
      id: number.isRequired,
      first_name: string,
      last_name: string,
      email: string.isRequired,
      role: string.isRequired,
      campaigns: arrayOf(Object),
      is_active: bool.isRequired,
      deactivation_date: string,
    }),
  ),
  loading: bool.isRequired,
  userType: oneOf(Object.values(USER_TYPES)).isRequired,
  resendEmailToUser: func.isRequired,
  openEditUser: func.isRequired,
  activateUser: func.isRequired,
};

/* UsersMainSection default props */

UsersMainSection.defaultProps = {
  tableData: [],
};

export default UsersMainSection;
