export const GET_CAMPAIGNS = '@PROJECT_NAME/GET_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAIL = '@PROJECT_NAME/GET_CAMPAIGNS_FAIL';

export const FIND_CAMPAIGNS = '@PROJECT_NAME/FIND_CAMPAIGNS';
export const FIND_CAMPAIGNS_SUCCESS = '@PROJECT_NAME/FIND_CAMPAIGNS_SUCCESS';
export const FIND_CAMPAIGNS_FAIL = '@PROJECT_NAME/FIND_CAMPAIGNS_FAIL';

export const GET_CAMPAIGN = '@PROJECT_NAME/GET_CAMPAIGN';
export const GET_CAMPAIGN_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAIL = '@PROJECT_NAME/GET_CAMPAIGN_FAIL';

export const FIND_CAMPAIGN = '@PROJECT_NAME/FIND_CAMPAIGN';
export const FIND_CAMPAIGN_SUCCESS = '@PROJECT_NAME/FIND_CAMPAIGN_SUCCESS';
export const FIND_CAMPAIGN_FAIL = '@PROJECT_NAME/FIND_CAMPAIGN_FAIL';

export const GET_CAMPAIGN_WITH_POSITION = '@PROJECT_NAME/GET_CAMPAIGN_WITH_POSITION';
export const GET_CAMPAIGN_WITH_POSITION_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGN_WITH_POSITION_SUCCESS';
export const GET_CAMPAIGN_WITH_POSITION_FAIL = '@PROJECT_NAME/GET_CAMPAIGN_WITH_POSITION_FAIL';

export const GET_ALL_ACTIVE_CAMPAIGNS = '@PROJECT_NAME/GET_ALL_ACTIVE_CAMPAIGNS';
export const GET_ALL_ACTIVE_CAMPAIGNS_SUCCESS = '@PROJECT_NAME/GET_ALL_ACTIVE_CAMPAIGNS_SUCCESS';
export const GET_ALL_ACTIVE_CAMPAIGNS_FAIL = '@PROJECT_NAME/GET_ALL_ACTIVE_CAMPAIGNS_FAIL';

export const GET_GAME_SESSIONS = '@PROJECT_NAME/GET_GAME_SESSIONS';
export const GET_GAME_SESSIONS_SUCCESS = '@PROJECT_NAME/GET_GAME_SESSIONS_SUCCESS';
export const GET_GAME_SESSIONS_FAIL = '@PROJECT_NAME/GET_GAME_SESSIONS_FAIL';

export const GET_GAME_SESSION = '@PROJECT_NAME/GET_GAME_SESSION';
export const GET_GAME_SESSION_SUCCESS = '@PROJECT_NAME/GET_GAME_SESSION_SUCCESS';
export const GET_GAME_SESSION_FAIL = '@PROJECT_NAME/GET_GAME_SESSION_FAIL';

export const ADD_GAME_SESSION = '@PROJECT_NAME/ADD_GAME_SESSION';
export const ADD_GAME_SESSION_SUCCESS = '@PROJECT_NAME/ADD_GAME_SESSION_SUCCESS';
export const ADD_GAME_SESSION_FAIL = '@PROJECT_NAME/ADD_GAME_SESSION_FAIL';

export const EDIT_GAME_SESSION = '@PROJECT_NAME/EDIT_GAME_SESSION';
export const EDIT_GAME_SESSION_SUCCESS = '@PROJECT_NAME/EDIT_GAME_SESSION_SUCCESS';
export const EDIT_GAME_SESSION_FAIL = '@PROJECT_NAME/EDIT_GAME_SESSION_FAIL';

export const DELETE_GAME_SESSION = '@PROJECT_NAME/DELETE_GAME_SESSION';
export const DELETE_GAME_SESSION_SUCCESS = '@PROJECT_NAME/DELETE_GAME_SESSION_SUCCESS';
export const DELETE_GAME_SESSION_FAIL = '@PROJECT_NAME/DELETE_GAME_SESSION_FAIL';

export const ADD_DAY_WITH_GAME_SESSION = '@PROJECT_NAME/ADD_DAY_WITH_GAME_SESSION';

export const GET_DAYS_WITH_GAME_SESSION = '@PROJECT_NAME/GET_DAYS_WITH_GAME_SESSION';
export const GET_DAYS_WITH_GAME_SESSION_SUCCESS = '@PROJECT_NAME/GET_DAYS_WITH_GAME_SESSION_SUCCESS';
export const GET_DAYS_WITH_GAME_SESSION_FAIL = '@PROJECT_NAME/GET_DAYS_WITH_GAME_SESSION_FAIL';

export const GET_KITS = '@PROJECT_NAME/GET_KITS';
export const GET_KITS_SUCCESS = '@PROJECT_NAME/GET_KITS_SUCCESS';
export const GET_KITS_FAIL = '@PROJECT_NAME/GET_KITS_FAIL';

export const FIND_KITS = '@PROJECT_NAME/FIND_KITS';
export const FIND_KITS_SUCCESS = '@PROJECT_NAME/FIND_KITS_SUCCESS';
export const FIND_KITS_FAIL = '@PROJECT_NAME/FIND_KITS_FAIL';

export const GET_KIT = '@PROJECT_NAME/GET_KIT';
export const GET_KIT_SUCCESS = '@PROJECT_NAME/GET_KIT_SUCCESS';
export const GET_KIT_FAIL = '@PROJECT_NAME/GET_KIT_FAIL';

export const REMOVE_KIT = '@PROJECT_NAME/REMOVE_KIT';

export const GET_GAME_DEFS = '@PROJECT_NAME/GET_GAME_DEFS';
export const GET_GAME_DEFS_SUCCESS = '@PROJECT_NAME/GET_GAME_DEFS_SUCCESS';
export const GET_GAME_DEFS_FAIL = '@PROJECT_NAME/GET_GAME_DEFS_FAIL';

export const FIND_GAME_DEFS = '@PROJECT_NAME/FIND_GAME_DEFS';
export const FIND_GAME_DEFS_SUCCESS = '@PROJECT_NAME/FIND_GAME_DEFS_SUCCESS';
export const FIND_GAME_DEFS_FAIL = '@PROJECT_NAME/FIND_GAME_DEFS_FAIL';

export const GET_GAME_DEF = '@PROJECT_NAME/GET_GAME_DEF';
export const GET_GAME_DEF_SUCCESS = '@PROJECT_NAME/GET_GAME_DEF_SUCCESS';
export const GET_GAME_DEF_FAIL = '@PROJECT_NAME/GET_GAME_DEF_FAIL';

export const REMOVE_GAME_DEF = '@PROJECT_NAME/REMOVE_GAME_DEF';

export const GET_STORYLINES = '@PROJECT_NAME/GET_STORYLINES';
export const GET_STORYLINES_SUCCESS = '@PROJECT_NAME/GET_STORYLINES_SUCCESS';
export const GET_STORYLINES_FAIL = '@PROJECT_NAME/GET_STORYLINES_FAIL';

export const GET_LOCATIONS = '@PROJECT_NAME/GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = '@PROJECT_NAME/GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = '@PROJECT_NAME/GET_LOCATIONS_FAIL';

export const FIND_LOCATIONS = '@PROJECT_NAME/FIND_LOCATIONS';
export const FIND_LOCATIONS_SUCCESS = '@PROJECT_NAME/FIND_LOCATIONS_SUCCESS';
export const FIND_LOCATIONS_FAIL = '@PROJECT_NAME/FIND_LOCATIONS_FAIL';

export const GET_LOCATION = '@PROJECT_NAME/GET_LOCATION';
export const GET_LOCATION_SUCCESS = '@PROJECT_NAME/GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = '@PROJECT_NAME/GET_LOCATION_FAIL';

export const ADD_NEW_LOCATION = '@PROJECT_NAME/ADD_NEW_LOCATION';
export const ADD_NEW_LOCATION_SUCCESS = '@PROJECT_NAME/ADD_NEW_LOCATION_SUCCESS';
export const ADD_NEW_LOCATION_FAIL = '@PROJECT_NAME/ADD_NEW_LOCATION_FAIL';

export const UPDATE_CAMPAIGN = '@PROJECT_NAME/UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_SUCCESS = '@PROJECT_NAME/UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAIL = '@PROJECT_NAME/UPDATE_CAMPAIGN_FAIL';

export const DELETE_CAMPAIGN = '@PROJECT_NAME/DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS = '@PROJECT_NAME/DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAIL = '@PROJECT_NAME/DELETE_CAMPAIGN_FAIL';

export const ADD_CAMPAIGN = '@PROJECT_NAME/ADD_CAMPAIGN';
export const ADD_CAMPAIGN_SUCCESS = '@PROJECT_NAME/ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_FAIL = '@PROJECT_NAME/ADD_CAMPAIGN_FAIL';

export const GET_COMPANY = '@PROJECT_NAME/GET_COMPANY';
export const GET_COMPANY_SUCCESS = '@PROJECT_NAME/GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = '@PROJECT_NAME/GET_COMPANY_FAIL';

export const UPDATE_COMPANY = '@PROJECT_NAME/UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESS = '@PROJECT_NAME/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = '@PROJECT_NAME/UPDATE_COMPANY_FAIL';

export const DOWNLOAD_LEADERBOARD = '@PROJECT_NAME/DOWNLOAD_LEADERBOARD';
export const DOWNLOAD_LEADERBOARD_SUCCESS = '@PROJECT_NAME/DOWNLOAD_LEADERBOARD_SUCCESS';
export const DOWNLOAD_LEADERBOARD_FAIL = '@PROJECT_NAME/DOWNLOAD_LEADERBOARD_FAIL';

export const DOWNLOAD_USERS = '@PROJECT_NAME/DOWNLOAD_USERS';
export const DOWNLOAD_USERS_SUCCESS = '@PROJECT_NAME/DOWNLOAD_USERS_SUCCESS';
export const DOWNLOAD_USERS_FAIL = '@PROJECT_NAME/DOWNLOAD_USERS_FAIL';

export const DOWNLOAD_SESSION = '@PROJECT_NAME/DOWNLOAD_SESSION';
export const DOWNLOAD_SESSION_SUCCESS = '@PROJECT_NAME/DOWNLOAD_SESSION_SUCCESS';
export const DOWNLOAD_SESSION_FAIL = '@PROJECT_NAME/DOWNLOAD_SESSION_FAIL';

export const GET_USERS = '@PROJECT_NAME/GET_USERS';
export const GET_USERS_SUCCESS = '@PROJECT_NAME/GET_USERS_SUCCESS';
export const GET_USERS_FAIL = '@PROJECT_NAME/GET_USERS_FAIL';

export const ADD_USER = '@PROJECT_NAME/ADD_USER';
export const ADD_USER_SUCCESS = '@PROJECT_NAME/ADD_USER_SUCCESS';
export const ADD_USER_FAIL = '@PROJECT_NAME/ADD_USER_FAIL';

export const UPDATE_USER = '@PROJECT_NAME/UPDATE_USER';
export const UPDATE_USER_SUCCESS = '@PROJECT_NAME/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = '@PROJECT_NAME/UPDATE_USER_FAIL';

export const DELETE_USER = '@PROJECT_NAME/DELETE_USER';
export const DELETE_USER_SUCCESS = '@PROJECT_NAME/DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = '@PROJECT_NAME/DELETE_USER_FAIL';

export const RESEND_EMAIL = '@PROJECT_NAME/RESEND_EMAIL';
export const RESEND_EMAIL_SUCCESS = '@PROJECT_NAME/RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAIL = '@PROJECT_NAME/RESEND_EMAIL_FAIL';

export const GET_LEADERBOARD = '@PROJECT_NAME/GET_LEADERBOARD';
export const GET_LEADERBOARD_SUCCESS = '@PROJECT_NAME/GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAIL = '@PROJECT_NAME/GET_LEADERBOARD_FAIL';

export const GET_MULTIPLE_GAME_SESSIONS = '@PROJECT_NAME/GET_MULTIPLE_GAME_SESSIONS';
export const GET_MULTIPLE_GAME_SESSIONS_SUCCESS = '@PROJECT_NAME/GET_MULTIPLE_GAME_SESSIONS_SUCCESS';
export const GET_MULTIPLE_GAME_SESSIONS_FAIL = '@PROJECT_NAME/GET_MULTIPLE_GAME_SESSIONS_FAIL';

export const ADD_MULTIPLE_GAME_SESSIONS = '@PROJECT_NAME/ADD_MULTIPLE_GAME_SESSIONS';
export const ADD_MULTIPLE_GAME_SESSIONS_SUCCESS = '@PROJECT_NAME/ADD_MULTIPLE_GAME_SESSIONS_SUCCESS';
export const ADD_MULTIPLE_GAME_SESSIONS_FAIL = '@PROJECT_NAME/ADD_MULTIPLE_GAME_SESSIONS_FAIL';

/* Reports */

export const GET_CAMPAIGN_REPORTS_ACTIVITY = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_ACTIVITY';
export const GET_CAMPAIGN_REPORTS_ACTIVITY_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_ACTIVITY_SUCCESS';
export const GET_CAMPAIGN_REPORTS_ACTIVITY_FAIL = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_ACTIVITY_FAIL';

export const GET_CAMPAIGN_REPORTS_ANSWERS = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_ANSWERS';
export const GET_CAMPAIGN_REPORTS_ANSWERS_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_ANSWERS_SUCCESS';
export const GET_CAMPAIGN_REPORTS_ANSWERS_FAIL = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_ANSWERS_FAIL';

export const GET_CAMPAIGN_REPORTS_GENERAL = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_GENERAL';
export const GET_CAMPAIGN_REPORTS_GENERAL_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_GENERAL_SUCCESS';
export const GET_CAMPAIGN_REPORTS_GENERAL_FAIL = '@PROJECT_NAME/GET_CAMPAIGN_REPORTS_GENERAL_FAIL';

export const DOWNLOAD_CAMPAIGN_PARTICIPANTS = '@PROJECT_NAME/DOWNLOAD_CAMPAIGN_PARTICIPANTS';
export const DOWNLOAD_CAMPAIGN_PARTICIPANTS_SUCCESS = '@PROJECT_NAME/DOWNLOAD_CAMPAIGN_PARTICIPANTS_SUCCESS';
export const DOWNLOAD_CAMPAIGN_PARTICIPANTS_FAIL = '@PROJECT_NAME/DOWNLOAD_CAMPAIGN_PARTICIPANTS_FAIL';

export const GET_COMPANY_REPORTS_GENERAL = '@PROJECT_NAME/GET_COMPANY_REPORTS_GENERAL';
export const GET_COMPANY_REPORTS_GENERAL_SUCCESS = '@PROJECT_NAME/GET_COMPANY_REPORTS_GENERAL_SUCCESS';
export const GET_COMPANY_REPORTS_GENERAL_FAIL = '@PROJECT_NAME/GET_COMPANY_REPORTS_GENERAL_FAIL';


export const DOWNLOAD_COMPANY_PARTICIPANTS = '@PROJECT_NAME/DOWNLOAD_COMPANY_PARTICIPANTS';
export const DOWNLOAD_COMPANY_PARTICIPANTS_SUCCESS = '@PROJECT_NAME/DOWNLOAD_COMPANY_PARTICIPANTS_SUCCESS';
export const DOWNLOAD_COMPANY_PARTICIPANTS_FAIL = '@PROJECT_NAME/DOWNLOAD_COMPANY_PARTICIPANTS_FAIL';

export const GET_COMPANY_REPORTS_ACTIVITY = '@PROJECT_NAME/GET_COMPANY_REPORTS_ACTIVITY';
export const GET_COMPANY_REPORTS_ACTIVITY_SUCCESS = '@PROJECT_NAME/GET_COMPANY_REPORTS_ACTIVITY_SUCCESS';
export const GET_COMPANY_REPORTS_ACTIVITY_FAIL = '@PROJECT_NAME/GET_COMPANY_REPORTS_ACTIVITY_FAIL';

export const GET_COMPANY_PARTICIPANTS = '@PROJECT_NAME/GET_COMPANY_PARTICIPANTS';
export const GET_COMPANY_PARTICIPANTS_SUCCESS = '@PROJECT_NAME/GET_COMPANY_PARTICIPANTS_SUCCESS';
export const GET_COMPANY_PARTICIPANTS_FAIL = '@PROJECT_NAME/GET_COMPANY_PARTICIPANTS_FAIL';

export const GET_CAMPAIGN_PARTICIPANTS = '@PROJECT_NAME/GET_CAMPAIGN_PARTICIPANTS';
export const GET_CAMPAIGN_PARTICIPANTS_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGN_PARTICIPANTS_SUCCESS';
export const GET_CAMPAIGN_PARTICIPANTS_FAIL = '@PROJECT_NAME/GET_CAMPAIGN_PARTICIPANTS_FAIL';

export const DOWNLOAD_CAMPAIGN_REPORT = '@PROJECT_NAME/DOWNLOAD_CAMPAIGN_REPORT';
export const DOWNLOAD_CAMPAIGN_REPORT_SUCCESS = '@PROJECT_NAME/DOWNLOAD_CAMPAIGN_REPORT_SUCCESS';
export const DOWNLOAD_CAMPAIGN_REPORT_FAIL = '@PROJECT_NAME/DOWNLOAD_CAMPAIGN_REPORT_FAIL';

export const DOWNLOAD_COMPANY_REPORT = '@PROJECT_NAME/DOWNLOAD_COMPANY_REPORT';
export const DOWNLOAD_COMPANY_REPORT_SUCCESS = '@PROJECT_NAME/DOWNLOAD_COMPANY_REPORT_SUCCESS';
export const DOWNLOAD_COMPANY_REPORT_FAIL = '@PROJECT_NAME/DOWNLOAD_COMPANY_REPORT_FAIL';

export const RESET_COMPANY = '@PROJECT_NAME/RESET_COMPANY';
