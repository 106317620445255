import styled from 'styled-components';

// import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  font-size: 0;
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
`;

export const Text = styled.span`
  margin-left: 8px;
  font-size: 16px;
`;
