/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Styles */
import * as Styled from './styles';

// ----------------
function TextArea({
  placeholder,
  status,
  title,
  name,
  error,
  ...rest
}) {
  return (
    <Styled.Wrapper hasError={error}>
      {title && (<Styled.Title>{title}</Styled.Title>)}
      <Styled.Textarea
        placeholder={placeholder}
        name={name}
        {...rest}
      />
      <Styled.Error>
        {error && error}
      </Styled.Error>
    </Styled.Wrapper>
  );
}

// Type of props
TextArea.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.object,
  value: PropTypes.string.isRequired,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
};

// Default value for props
TextArea.defaultProps = {
  title: null,
};

export default memo(TextArea);
