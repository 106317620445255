/* Libs */
import styled, { css } from 'styled-components';
import { ANSWER_STATUS } from '_constants';
import { COLORS_CONFIG } from 'config';

const STATUS_COLORS = {
  [ANSWER_STATUS.WRONG]: COLORS_CONFIG.ORANGE,
  [ANSWER_STATUS.CORRECT]: COLORS_CONFIG.LIGHT_GREEN,
  [ANSWER_STATUS.NOT_ANSWERED]: COLORS_CONFIG.SILVER_CUSTOM_4,
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0px 16px 0px 17px;
`;
export const Answer = styled.div`
  font-size: 15px;
  line-height: 18px;
  padding-right: 16px;
  word-break: break-all;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  flex: 1;
  ${({ isCorrect }) => isCorrect && css`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${COLORS_CONFIG.DARK_GREY};
  `}
`;
export const Status = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${({ status }) => STATUS_COLORS[status]};
`;
