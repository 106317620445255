/* Libs */
import styled from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  padding: 51px 75px 45px 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 363px;
  width: 496px;
`;

export const Label = styled.div`
  font-size: 21px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  text-align: center;
  line-height: 25px;
  padding-top: 53px;
  padding-bottom: 35px;
`;
