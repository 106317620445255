/* Libs */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/* Actions */

import { LS_AdminCompaniesEntity } from '_entities';

/* Utils */

import { getError } from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import { ProgressBarPointers } from 'components';

/* Constants */

import { NOTIFICATIONS } from '_constants';
import {
  PointOfContact,
  CompanyInformation,
  ContractAndLicenses
} from './Steps';

/* Styles */

import * as Styled from './styles';

const DEFAULT_DATA = {
  // CompanyInformation
  name: '',
  location: '',
  // ContractAndLicenses
  start_date: '',
  end_date: '',
  licenses: '',
  // PointOfContact
  first_name: '',
  last_name: '',
  email: ''
};

const DEFAULT_ERRORS = {
  // CompanyInformation
  name: null,
  location: null,
  // ContractAndLicenses
  start_date: null,
  end_date: null,
  licenses: null,
  // PointOfContact
  first_name: null,
  last_name: null,
  email: null
};

function AddCompanyForm({ onClose, addCompany }) {
  const [currentStep, changeStep] = useState(1);
  const [data, setData] = useState(DEFAULT_DATA);
  const [errors, setErrors] = useState(DEFAULT_ERRORS);
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const nextStep = () => changeStep(currentStep + 1);
  const prevStep = () => changeStep(currentStep - 1);
  const updateData = (newData, newErrors) => {
    setData({ ...data, ...newData });
    setErrors({ ...errors, ...newErrors });
  };
  const saveCompany = async () => {
    try {
      setLoading(true);
      await addCompany(data);
      notification.success(NOTIFICATIONS.SUCCESS_ADD_COMPANY);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      notification.error(getError(error));
      setResponseError(getError(error));
    }
  };

  const manageStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <CompanyInformation
            nextStep={nextStep}
            updateData={updateData}
            data={pick(data, ['location', 'name'])}
            errors={pick(errors, ['location', 'name'])}
          />
        );
      case 2:
        return (
          <ContractAndLicenses
            nextStep={nextStep}
            prevStep={prevStep}
            updateData={updateData}
            data={pick(data, ['start_date', 'licenses', 'end_date'])}
            errors={pick(errors, ['start_date', 'licenses', 'end_date'])}
          />
        );
      case 3:
        return (
          <PointOfContact
            prevStep={prevStep}
            save={saveCompany}
            updateData={updateData}
            loading={loading}
            responseError={responseError}
            data={pick(data, ['first_name', 'last_name', 'email'])}
            errors={pick(errors, ['first_name', 'last_name', 'email'])}
          />
        );
      default:
        return (
          <CompanyInformation
            nextStep={nextStep}
            updateData={updateData}
            data={pick(data, ['location', 'name'])}
          />
        );
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Content>{manageStep()}</Styled.Content>
      <ProgressBarPointers current={currentStep} total={3} />
    </Styled.Wrapper>
  );
}

/* AddCompanyForm type of props */

AddCompanyForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

/* AddCompanyForm default props */

AddCompanyForm.defaultProps = {};

export default connect(null, {
  addCompany: LS_AdminCompaniesEntity.actions.addCompany
})(AddCompanyForm);
