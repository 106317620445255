/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG, TABLES_CONFIG } from 'config';

const flexGrow1 = `${TABLES_CONFIG.CLASSES.TABLE_CELL_FLEX_GROW_BASE}1`;
const flexGrow2 = `${TABLES_CONFIG.CLASSES.TABLE_CELL_FLEX_GROW_BASE}2`;
const flexGrow3 = `${TABLES_CONFIG.CLASSES.TABLE_CELL_FLEX_GROW_BASE}3`;
const flexGrow4 = `${TABLES_CONFIG.CLASSES.TABLE_CELL_FLEX_GROW_BASE}4`;
const flexGrow5 = `${TABLES_CONFIG.CLASSES.TABLE_CELL_FLEX_GROW_BASE}5`;

// Component styles
export const EmptyWrapper = styled.div`
  position: absolute;
  top: calc(50% + 40px);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
`;

export const Table = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 8px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  ${({ hasPagination }) => hasPagination && css`
    padding-bottom: 18px;
  `};
  .react-table__wrapper {
    border: none;
    ${({ hasPagination }) => hasPagination && css`
      padding-bottom: 18px;
    `};
    .rt-noData {

      top: calc(50% + 40px);
      background-color: transparent;
      font-size: 18px;
      font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
      line-height: 22px;
      color: ${COLORS_CONFIG.BLACK_1};
    }

    .rt-table {
      border-radius: 8px;
      .rt-thead {
        /* height: 40px; */
        background-color: ${COLORS_CONFIG.WHITE};

        .rt-tr {
          padding: 0 16px;

          .rt-th {
            display: flex;
            align-items: center;
            width: 100%;
            border: none;
            font-size: 12px;
            padding: 6px 6px 6px 0;
            & > div {
              font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
              color: ${COLORS_CONFIG.SMOOTH_BROWN};
              white-space: nowrap;
            }
            &:last-child {
              padding: 6px 0;
            }
            .rt-resizable-header-content {
              height: 16px;
              font-size: 12px;
              font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
              line-height: 16px;
              letter-spacing: 0.25px;
              text-transform: uppercase;
              color: ${COLORS_CONFIG.WHITE$50};
            }
            &.${flexGrow1} {
              flex-grow: 1 !important;
            }
            &.${flexGrow2} {
              flex-grow: 2 !important;
            }
            &.${flexGrow3} {
              flex-grow: 3 !important;
            }
            &.${flexGrow4} {
              flex-grow: 4 !important;
            }
            &.${flexGrow5} {
              flex-grow: 5 !important;
            }
          }
        }
      }

      .rt-tbody {
        background-color: ${COLORS_CONFIG.WHITE};

        .rt-tr-group {
          border-bottom: 1px solid ${COLORS_CONFIG.SILVER};
          flex: 0;

          &:last-child {
            border-bottom: none;
          }

          .rt-tr {
            padding: 0 16px;

            .rt-td {
              display: flex;
              align-items: center;
              border: none;
              font-size: 16px;
              font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
              line-height: 22px;
              color: ${COLORS_CONFIG.WHITE};
              padding: 6px 6px 6px 0;
              /* overflow: visble; */
              &:last-child {
                padding: 6px 0;
              }
              &.${flexGrow1} {
                flex-grow: 1 !important;
              }
              &.${flexGrow2} {
                flex-grow: 2 !important;
              }
              &.${flexGrow3} {
                flex-grow: 3 !important;
              }
              &.${flexGrow4} {
                flex-grow: 4 !important;
              }
              &.${flexGrow5} {
                flex-grow: 5 !important;
              }
            }
          }
        }
      }
    }
  }

  ${({ withoutBackground }) => withoutBackground
    && css`
      .react-table__wrapper {
        .rt-table {
          .rt-tbody {
            background-color: transparent;
          }
        }
      }
    `}

  ${({ rowMinHeight }) => rowMinHeight === 'small'
    && css`
      .react-table__wrapper {
        .rt-table {
          min-height: 124px;

          .rt-tbody {
            .rt-tr-group {
              min-height: 62px;
            }
          }
        }
      }
    `}

  ${({ rowMinHeight }) => rowMinHeight === 'middle'
    && css`
      .react-table__wrapper {
        .rt-table {
          min-height: 172px;

          .rt-tbody {
            .rt-tr-group {
              min-height: 86px;
            }
          }
        }
      }
    `}

  ${({ tableSize }) => tableSize === TABLES_CONFIG.SIZE.M
    && css`
    .react-table__wrapper {
      .rt-thead .rt-tr {
        padding: 48px 52px 0px 52px!important;
      }
      .rt-tbody .rt-tr {
        padding: 35px 52px 27px 52px!important;
      }
    }
  `}

  ${({ tableSize }) => tableSize === TABLES_CONFIG.SIZE.S
    && css`
    .react-table__wrapper {
      .rt-thead .rt-tr {
        padding: 0!important;
      }
      .rt-tbody .rt-tr {
        padding: 24px 0px 24px 0px!important;
      }
    }
  `}
`;
