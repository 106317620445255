import moment from 'moment';
import { DATE_SETTINGS } from 'config';

/**
 * @param {Object} target
 * @param {string} target.name
 * @param {string} target.value
 * @param {Object} from
 * @param {string} from.name
 * @param {string} from.value
 * @param {Object} to
 * @param {string} to.name
 * @param {string} to.value
 * @param {number} monthRange
 * @param {string} format
 */
export default ({
  target: {
    name,
    value,
  },
  from,
  to,
  monthRange = 6,
  format = DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
  changeToSameDate,
}) => {
  if (name === from.name) {
    const dateFromLocal = moment(value);
    const dateToLocal = moment(to.value);
    if (
      dateFromLocal.isAfter(dateToLocal)
      || dateFromLocal.clone().add(monthRange, 'months').isBefore(dateToLocal)
    ) {
      return ({
        [from.name]: value,
        [to.name]: (changeToSameDate
          ? dateFromLocal
          : dateFromLocal.add(1, 'day')).format(format),
      });
    }
  } else if (name === to.name) {
    const dateToLocal = moment(value);
    const dateFromLocal = moment(from.value);

    if (Math.abs(dateFromLocal.diff(dateToLocal, 'months')) > monthRange) {
      return ({
        [to.name]: value,
        [from.name]: (changeToSameDate
          ? dateToLocal
          : dateToLocal.subtract(1, 'day')).format(format),
      });
    }
  }

  return ({ [name]: value });
};
