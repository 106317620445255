/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  position: relative;
`;

export const UpperLine = styled.div`
  padding-bottom: 6px;
  font-size: 14px;
  line-height: 14px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  display: flex;
`;

export const SessionTime = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
`;

export const Label = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 10px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  text-align: center;
  border-radius: 50%;
  left: 150px;
  top: -5px;
`;
