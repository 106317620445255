/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';

import { Wrapper as InputWrapper } from 'components/ui/Input/styles';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';

export const Wrapper = styled.div`
  display: flex;
  padding: 32px;
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 8px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > *:not(:first-child) {
    margin-top: 24px;
  }
  & ${BigTitle} {
    text-transform: capitalize;
  }
`;

export const Optional = styled.div`
  text-transform: lowercase;
  font-size: 11px;
`;

export const Preview = styled.div`
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
  align-self: flex-end;
`;

export const PreviewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & ${InputWrapper} {
    margin-top: 0;
  }
`;
