/* Libs */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';


/* Utils */

import { getSessionTimeCellData, getStaticData } from 'utils/custom';


/* Components */

import {
  SimpleTextCell,
  ActionsCell,
  Table,
  HeaderSessionTimeCell,
  SessionTimeCell,
  GoLabel,
} from 'components';


/* Constants */

import { TRIANGLE_TYPES } from '_constants';
import {
  SESSION_TIME_TYPES,
  DATE_SETTINGS,
  REPORTS_CONFIG, GAME_CONFIG,
} from 'config';


/* Styles */

import * as Styled from './styles';


// ParticipantsViewTable columns cell structure
const getColumnsData = ({
  actions,
  tableTimeType,
  toggleTableTimeType,
  companyTimezone,
}) => ([
  {
    accessor: 'start_time',
    minWidth: 110,
    maxWidth: 120,
    Header: () => (
      <HeaderSessionTimeCell
        value={tableTimeType}
        onToggle={toggleTableTimeType}
        checked={tableTimeType === SESSION_TIME_TYPES.SESSION_TIME}
      />
    ),
    Cell: (props) => {
      const {
        startTime,
        endTime,
        timezoneTitle,
        label,
      } = getSessionTimeCellData(tableTimeType, companyTimezone, props);

      return (
        <SessionTimeCell
          startTime={startTime}
          endTime={endTime}
          timezoneTitle={timezoneTitle}
          label={label}
        />
      );
    },
  },
  {
    minWidth: 90,
    accessor: 'team',
    Header: 'Team',
    Cell: props => <SimpleTextCell value={props.original.team_name} size="small" />,
  },
  {
    minWidth: 100,
    accessor: 'location',
    Header: 'Location',
    Cell: props => <SimpleTextCell value={get(props, 'original.location', null)} size="small" />,
  },
  {
    minWidth: 100,
    accessor: 'time',
    Header: 'Time completed',
    Cell: ({ original }) => {
      const statuses = getStaticData('gamePlayStatuses');
      const time = [statuses.instructions, statuses.game_play, statuses.game_failed].includes(original.status)
        || original.time_completed === null
        ? GAME_CONFIG.NOT_FINISHED
        : moment(
          original.time_completed,
          DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED,
        ).format(DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED);

      return (
        <SimpleTextCell
          value={time}
          size="small"
        />
      );
    },
  },
  {
    minWidth: 50,
    Header: '',
    Cell: ({ original: { game_session: id } }) => (
      <ActionsCell>
        <GoLabel
          text="View"
          triangleType={TRIANGLE_TYPES.FORWARD}
          click={() => actions.openParticipantsSidebar(id)}
        />
      </ActionsCell>
    ),
  },
]
);

function ParticipantsViewTable({
  data,
  tableSize,
  pagination,
  loading,
  actions,
  companyTimezone,
}) {
  const [tableTimeType, setTableTimeType] = useState(SESSION_TIME_TYPES.LOCAL_TIME);
  const toggleTableTimeType = () => setTableTimeType(
    tableTimeType === SESSION_TIME_TYPES.SESSION_TIME
      ? SESSION_TIME_TYPES.LOCAL_TIME
      : SESSION_TIME_TYPES.SESSION_TIME,
  );

  const columnsData = useMemo(() => getColumnsData({
    actions,
    tableTimeType,
    toggleTableTimeType,
    companyTimezone,
  }), [
    actions,
    tableTimeType,
    data,
  ]);

  return (
    <Styled.ParticipantTable>
      <Table
        defaultPageSize={data.length ? data.length : null}
        rowMinHeight="medium"
        tableSize={tableSize}
        columns={columnsData}
        loading={loading}
        data={data}
        sortable={false}
        pagination={pagination}
      />
    </Styled.ParticipantTable>
  );
}

// Type of props

ParticipantsViewTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    rank: PropTypes.string.number,
    team_name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = state => ({
  companyTimezone: get(state, 'adminCompany.company.timezone'),
});

export default connect(mapStateToProps, null)(ParticipantsViewTable);
