/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import { COLORS_CONFIG } from 'config';

// Component styles
export const LinkTo = styled.div`
  position: relative;

  ${({ size }) => size === 'big'
    && css`
      text-align: center;
    `}

  a {
    text-decoration: none;
    
    ${({ disabled }) => disabled
      && css`
          cursor: default;
      `}

    span {
      transition: color 0.2s ease-out;
    }
  }
`;

export const SpanText = styled.span`
  ${({ size }) => size === 'small'
    && css`
      font-size: 14px;
      line-height: 19px;
      color: ${COLORS_CONFIG.DEEP_SKY_BLUE};

      &:hover {
        color: ${COLORS_CONFIG.BLUE_NEON};
      }
    `}

  ${({ size }) => size === 'big'
    && css`
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: ${COLORS_CONFIG.DEEP_SKY_BLUE};

      &:hover {
        color: ${COLORS_CONFIG.BLUE_NEON};
      }
    `}
`;
