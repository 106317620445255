/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Utils */

import { goBack as goBackUtil } from 'utils/custom';

/* Components */

import { GoLabel } from 'components';

/* Constants */
import {
  TRIANGLE_TYPES, STRUCT_IDS,
} from '_constants';

/* Styles */

import * as Styled from './styles';

function AdminSettingsStruct({
  title,
  actions,
  topActions,
  goBack,
  children,
  search,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Top>
        { get(goBack, 'enabled', false)
          && (
            <GoLabel
              triangleType={TRIANGLE_TYPES.BACK}
              text={goBack.text}
              click={goBack.action || goBackUtil}
            />
          )
        }
        <Styled.TopActions>
          {topActions.map(action => (React.isValidElement(action)
            ? action
            : (
              <Styled.Action key={action.title} onClick={action.action}>
                {action.title}
              </Styled.Action>
            )
          ))}
        </Styled.TopActions>
      </Styled.Top>
      <Styled.Header>
        <Styled.TitleWrapper>
          <Styled.Title>
            {title}
          </Styled.Title>
          {search}
        </Styled.TitleWrapper>
        <Styled.Actions>
          {actions.map(action => (action.title
            ? (
              <Styled.Action key={action.title} onClick={action.action}>
                {action.title}
              </Styled.Action>
            )
            : action
          ))}
        </Styled.Actions>
      </Styled.Header>
      <Styled.Content id={STRUCT_IDS.ADMIN_SETTINGS_PAGE}>
        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* AdminSettingsStruct type of props */

AdminSettingsStruct.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  goBack: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    action: PropTypes.func,
  }),
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
    PropTypes.node,
  ])),
  topActions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
    PropTypes.node,
  ])),
};

/* AdminSettingsStruct default props */

AdminSettingsStruct.defaultProps = {
  goBack: null,
  actions: [],
  topActions: [],
};

export default AdminSettingsStruct;
