import { useEffect } from 'react';

export default (onOuterClick, innerRef) => {
  useEffect(
    () => {
      function handleClick(e) {
        innerRef.current
          && !innerRef.current.contains(e.target)
          && onOuterClick(e);
      }

      /* istanbul ignore else */
      if (innerRef.current) {
        document.addEventListener('click', handleClick);
      }

      // unmount previous first in case input have changed
      return () => document.removeEventListener('click', handleClick);
    },
    [onOuterClick, innerRef], // invoke again, if inputs have changed
  );
};
