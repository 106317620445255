/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */

import {
  Wrapper as QuestionStatisticWrapper,
} from 'components/blocks/ListItems/QuestionStatistic/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-right: 15px;
  ${() => CustomScroll({ scrollBG: COLORS_CONFIG.WHITE })}

  & > ${QuestionStatisticWrapper} {
    border-bottom: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_2};

    &:last-child {
      border: none;
    }
  }
`;
