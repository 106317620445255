/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Constants */
import { AVATAR_CONFIG } from 'config';

/* Components */

import {
  SmallTitle,
  Button,
  Avatar,
  Input,
  Loader,
} from 'components';

/* Styles */

import * as Styled from './styles';
import { Storyline, StorylineName } from '../AvailableStorylines/styles';

function CampaignName({
  onChange,
  onBlur,
  formData,
  previousStep,
  handleSubmit,
  loading,
}) {
  const { data, errors, canSubmit } = formData;

  return (
    <Styled.Wrapper>
      <SmallTitle>
        Select an available storyline:
      </SmallTitle>
      <Storyline selected>
        <Avatar avatar={data.selectedStoryline.image} size={AVATAR_CONFIG.SIZE.M} />
        <StorylineName>{data.selectedStoryline.name}</StorylineName>
      </Storyline>
      <SmallTitle>
        Name your campaign
      </SmallTitle>
      <Input
        placeholder="Enter your campaign name"
        name="campaign_name"
        value={data.campaign_name}
        error={errors.campaign_name}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Styled.Actions>
        <Button
          onClick={previousStep}
          inverted
        >
          Back
        </Button>
        <Button
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          Complete
        </Button>
      </Styled.Actions>
      {loading && <Loader />}
    </Styled.Wrapper>
  );
}

/* CampaignName type of props */

CampaignName.propTypes = {
  previousStep: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CampaignName;
