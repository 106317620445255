/* Libs */
import React, {
  useState, useMemo, useEffect, memo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash.get';

/* Utils */

import {
  generateTimeOptions,
  handleDateRangeChange,
  generateDateArray,
} from 'utils/custom';


/* Components */

import {
  ToggleBar,
  DatePicker,
  ActivityDayChart,
  ActivityRangeChart,
} from 'components';

/* Constants */

import {
  REPORTS_CONFIG,
  DATE_SETTINGS,
} from 'config';

/* Styles */

import * as Styled from './styles';

const mapHours = (hours = []) => (
  generateTimeOptions(60, DATE_SETTINGS.FORMAT.HOUR).map((item) => {
    const hourData = hours.find(({ hour }) => hour === Number(item.value));

    return ({
      x: item.label,
      y: get(hourData, 'game_sessions_count', 0),
    });
  })
);

const mapDays = (days = [], dateFrom, dateTo) => (
  generateDateArray(dateFrom, dateTo, DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)
    .map((date) => {
      const report = days.find(day => day.date === date);
      if (!report) {
        return {
          x: moment(date),
          y: 0,
        };
      }
      return {
        x: moment(report.date),
        y: report.game_sessions_count,
      };
    })
);

const DEFAULT_DATA = {
  type: REPORTS_CONFIG.ACTIVITY_TYPES.RANGE,
  dateFrom: moment().subtract(1, 'day').format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
  dateTo: moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
  dayRangeStart: 7,
};

const amountOfShownHours = 13;

function ActivitySection({
  request,
  options,
}) {
  const [formData, setFormData] = useState({ ...DEFAULT_DATA });
  const [chartState, setChartState] = useState({
    day: [],
    range: [],
  });
  const {
    type,
    dateFrom,
    dayRangeStart,
    dateTo,
  } = formData;

  const handleDateChange = ({ target }) => {
    const dateRange = handleDateRangeChange({
      target,
      from: {
        name: 'dateFrom',
        value: dateFrom,
      },
      to: {
        name: 'dateTo',
        value: dateTo,
      },
    });
    setFormData(prev => ({ ...prev, ...dateRange }));
  };

  const handleChange = ({ target: { name, value } }) => {
    switch (name) {
      case 'dateFrom':
      case 'dateTo':
        handleDateChange({ target: { name, value } });
        break;
      default:
        setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const showPrev = () => (
    setFormData(prev => ({
      ...prev,
      dayRangeStart: prev.dayRangeStart - 1,
    }))
  );

  const showNext = () => (
    setFormData(prev => ({
      ...prev,
      dayRangeStart: prev.dayRangeStart + 1,
    }))
  );

  useEffect(() => {
    if (!options.locations || moment(dateFrom).isAfter(moment(dateTo))) return;
    (async () => {
      const isDay = (type === REPORTS_CONFIG.ACTIVITY_TYPES.DAY || dateFrom === dateTo);
      const data = await request({
        ...options,
        dateFrom,
        dateTo: isDay ? dateFrom : dateTo,
        is_range: !isDay,
      });

      setChartState((prev) => {
        const newState = isDay
          ? mapHours(data)
          : mapDays(data, dateFrom, dateTo);

        return {
          ...prev,
          [isDay ? 'day' : 'range']: newState,
        };
      });
    })();
  }, [dateFrom, dateTo, type, options.locations, options.id]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      type: options.dateFrom === options.dateTo
        ? REPORTS_CONFIG.ACTIVITY_TYPES.DAY
        : REPORTS_CONFIG.ACTIVITY_TYPES.RANGE,
      dateFrom: options.dateFrom || prev.dateFrom,
      dateTo: options.dateTo || prev.dateTo,
    }));
  }, [options.dateFrom, options.dateTo]);

  const dayChartData = useMemo(() => (
    chartState.day && chartState.day.length
      ? chartState.day.slice(dayRangeStart, dayRangeStart + amountOfShownHours)
      : []
  ), [chartState.day, dateFrom, dayRangeStart]);

  const rangeChartData = useMemo(() => (
    chartState.range
    && chartState.range.length
      ? chartState.range
      : []
  ), [formData.type, chartState.range]);

  const dayReportView = useMemo(() => (
    <>
      {(dayRangeStart > 0)
        ? (
          <Styled.ArrowButton
            onClick={showPrev}
          >
            {'<'}
          </Styled.ArrowButton>
        )
        : <Styled.ButtonPlaceholder />
      }
      <Styled.ChartWrapper type={REPORTS_CONFIG.ACTIVITY_TYPES.DAY}>
        <ActivityDayChart
          yMaxDomain={Math.max.apply(null, chartState.day.map(({ y }) => +y))}
          data={dayChartData}
        />
      </Styled.ChartWrapper>
      {(dayRangeStart + 13 <= 24)
        ? (
          <Styled.ArrowButton
            onClick={showNext}
          >
            {'>'}
          </Styled.ArrowButton>
        )
        : <Styled.ButtonPlaceholder />
      }
    </>
  ), [dayRangeStart, showPrev, chartState.day, dayChartData, showNext]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>Activity</Styled.Title>
        <ToggleBar
          name="type"
          onChange={handleChange}
          active={type}
          options={[
            REPORTS_CONFIG.ACTIVITY_TYPES.DAY,
            REPORTS_CONFIG.ACTIVITY_TYPES.RANGE,
          ]}
        />
        <DatePicker
          name="dateFrom"
          placeholder={(type === REPORTS_CONFIG.ACTIVITY_TYPES.RANGE) ? 'Pick start date' : 'Pick a day'}
          value={dateFrom}
          onChange={handleChange}
          withIcon
        />
        { (type === REPORTS_CONFIG.ACTIVITY_TYPES.RANGE)
            && (
              <DatePicker
                name="dateTo"
                placeholder="Pick end date"
                value={dateTo}
                onChange={handleChange}
                minDate={moment(dateFrom).toDate()}
                maxDate={moment(dateFrom).add(6, 'months').toDate()}
                withIcon
              />
            )
        }
      </Styled.Header>
      <Styled.Main>
        {
          (type === REPORTS_CONFIG.ACTIVITY_TYPES.DAY || dateFrom === dateTo)
            ? dayReportView
            : (
              <Styled.ChartWrapper
                type={REPORTS_CONFIG.ACTIVITY_TYPES.RANGE}
              >
                <ActivityRangeChart data={rangeChartData} />
              </Styled.ChartWrapper>
            )
        }
      </Styled.Main>
    </Styled.Wrapper>
  );
}

/* ActivitySection type of props */

ActivitySection.propTypes = {
  onChange: PropTypes.func,
  request: PropTypes.func,
  options: PropTypes.object,
};

/* Activity default props */

ActivitySection.defaultProps = {
  onChange: null,
  request: null,
  options: null,
};

export default memo(ActivitySection);
