/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { Wrapper as InputWrapper } from 'components/ui/SearchInput/styles';

export const Title = styled.div`
  font-size: 40px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  display: flex;
  align-items: center;
  & ${InputWrapper} {
    border-radius: 3px;
    margin-left: 20px;
    width: 300px;
  }
`;

export const Action = styled.div``;

export const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & ${Title} {
    flex: 2;
  }
  & ${Actions} {
    flex: 3;
  }
`;

export const Top = styled.div`
  position: absolute;
  z-index: 1;
  top: 30px;
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 72px 40px 50px 40px;
  min-height: 100vh;
  & ${Top},
  & ${Header} {
    padding-left: 8px;
  }
`;


export const Content = styled.div`
  padding-top: 35px;
`;
