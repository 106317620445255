import {
  // We could move this format back later
  // DATE_SETTINGS,
  SESSION_TIME_TYPES,
} from 'config';
import { getSessionTimeData, getTimezoneName, getLocalDateFromUTCString } from 'utils/custom';
import { TIMEZONE_ARRAY } from '_constants';

const getLabelValue = ({
  localStart,
  sessionStart,
}) => {
  if (sessionStart < localStart) return '-1';
  if (sessionStart > localStart) return '+1';
  return null;
};

const getSessionTimeCellData = (type, companyTimezone, props) => {
  const {
    timezone: propsTimezone,
    utc_start_time: propsStartTime,
    utc_end_time: propsEndTime,
  } = props.original;

  const isSessionTimeType = type === SESSION_TIME_TYPES.SESSION_TIME;

  const {
    timezone,
    startTime,
    endTime,
  } = getSessionTimeData({
    type,
    companyTimezone,
    propsTimezone,
    propsStartTime,
    propsEndTime,
  });


  // get label
  const localStart = getLocalDateFromUTCString(propsStartTime, getTimezoneName(companyTimezone)).date();
  const sessionStart = getLocalDateFromUTCString(propsStartTime, propsTimezone).date();
  const label = isSessionTimeType ? getLabelValue({ localStart, sessionStart }) : null;

  const { timezoneTitle } = TIMEZONE_ARRAY.find(({ timezoneName }) => timezone === timezoneName);

  return ({
    timezoneTitle,
    startTime: startTime.format('LT'),
    endTime: endTime.format('LT'),
    label,
    // We could move this format back later
    // downValues: [
    //   moment(propsEndTime).utcOffset(offset).format('LT'),
    //   // We could move this format back later
    //   // moment.tz(timezone).format(DATE_SETTINGS.FORMAT.TIMEZONE_OFFSET),`
    //   abbreviation,
    // ],
  });
};

export default getSessionTimeCellData;
