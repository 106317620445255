/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

function TimeCompletedCell({
  timeCompleted,
  isFinished,
}) {
  const getFormatedTime = () => {
    const [time, milliseconds] = timeCompleted.split('.');

    return `${time}${milliseconds ? `.${milliseconds.slice(0, 3)}` : ''}`;
  };

  return (
    <Styled.Cell>
      {
        isFinished
          ? <Styled.Time>{timeCompleted ? getFormatedTime() : ' ---- '}</Styled.Time>
          : <Styled.Finish>DID NOT FINISH</Styled.Finish>
      }
    </Styled.Cell>
  );
}

/* TimeCompletedCell type of props */

TimeCompletedCell.propTypes = {
  timeCompleted: PropTypes.string,
  isFinished: PropTypes.bool,
};

/* TimeCompletedCell default props */

TimeCompletedCell.defaultProps = {
  timeCompleted: null,
  isFinished: true,
};

export default TimeCompletedCell;
