import { get, isEmpty } from 'lodash';
import { NOTIFICATIONS } from '_constants';

const getError = (error) => {
  if (!error || isEmpty(error)) {
    return NOTIFICATIONS.ERROR_DEFAULT; // maybe we need return false or undefined
  }
  if (typeof error === 'string') return error;
  let errorMsg = get(error, 'response.data.error')
    || get(error, 'response.data.message')
    || get(error, 'response.data.payload.message')
    || false;
  if (typeof errorMsg === 'string') return errorMsg;
  const isResponse = error.response;
  if (isResponse) {
    errorMsg = getErrorDeep(error.response.data);
  }
  return typeof errorMsg === 'string' ? errorMsg : NOTIFICATIONS.ERROR_DEFAULT;
};

export default getError;

function getErrorDeep(error, fieldName = '') {
  // debugger;
  if (typeof error === 'string') return error;
  let returnError = false;
  const isArray = Array.isArray(error);
  const isObject = typeof error === 'object' && error !== null && !isArray;
  if (isObject) {
    Object.keys(error).map((key) => {
      if (!returnError) {
        returnError = getErrorDeep(error[key]);
        // returnError = getErrorDeep(error[key], key); //UNCOMMENT if you need to see full way of error
      }
    });
  }

  if (isArray) {
    error.map((singleError) => {
      if (!returnError) {
        returnError = getErrorDeep(singleError);
        // returnError = getErrorDeep(singleError, fieldName); //UNCOMMENT if you need to see full way of error
      }
    });
  }
  return typeof returnError === 'string' ? `${fieldName} ${returnError}` : false;
}
