import moment from 'moment';

export default function generateTimeLimits({
  interval,
  limit,
}) {
  const momentObject = moment().startOf('day');
  const result = [];
  let minutes = 0;
  while (minutes < limit) {
    momentObject.add(interval, 'minutes');
    minutes += interval;
    result.push({
      value: momentObject.format('HH:mm:ss'),
      label: `${minutes} min`,
    });
  }
  return result;
}
