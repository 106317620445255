/* Libs */
import styled from 'styled-components';

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

import { Circle } from 'components/blocks/Markers/CircleFilled/styles';

export const EventMarker = styled.div`
  position: absolute;
  top: calc( 100% + 1px );
  left: 50%;
  transform: translateX(-50%);
`;

export const Content = styled.div`
  cursor: pointer;
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
`;

export const Cell = styled.div`
  position: relative;
  & ${EventMarker} {
    display: ${({ hasEvent }) => (hasEvent ? 'block' : 'none')};
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top:0;
  left:50%;
  transform: translate(-50%, 0%);
  & ${Circle} {
    width: 6px;
    height: 6px;
  }
`;
