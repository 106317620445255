/* Libs */
import PropTypes from 'prop-types';
import React from 'react';

/* Styles */
import { AVAILABLE_COLORS, COLORS_CONFIG } from 'config';
import * as Styled from './styles';

// ----------------
export default function ButtonLoader({ size, color, stretch }) {
  return (
    <Styled.ButtonLoader color={color} size={size} stretch={stretch}>
      <Styled.Span />
      <Styled.Span />
      <Styled.Span />
    </Styled.ButtonLoader>
  );
}

// Type of props
ButtonLoader.propTypes = {
  stretch: PropTypes.bool,
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
  size: PropTypes.oneOf(['small', 'middle', 'big']),
};

// Default value for props
ButtonLoader.defaultProps = {
  color: COLORS_CONFIG.BLUE_NEON,
  size: 'middle',
  stretch: false,
};
