/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import { AddCircle } from 'components';

/* Utils */

import { URLS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function TemplatesStruct({
  children,
  search,
  title,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>
          {title}
          {search}
        </Styled.Title>
        <Styled.Actions>
          <AddCircle
            key="Add a template"
            title="Add a template"
            to={URLS_CONFIG.admin.addTemplate}
          />
        </Styled.Actions>
      </Styled.Header>
      <Styled.Content>
        { children }
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* TemplatesStruct type of props */

TemplatesStruct.propTypes = {
  title: PropTypes.string.isRequired,
  search: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(TemplatesStruct);
