/* Libs */

import PropTypes from 'prop-types';
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
/* Actions */

import { LS_AdminCompaniesEntity } from '_entities';

/* Components */

import {
  Modal, Select, Button,
} from 'components';

/* Styles */

import { redirect } from 'utils/custom';
import { URLS_CONFIG } from 'config';
import * as Styled from './styles';

function AssignModal({
  getAllCompanies,
  templateId,
  onClose,
  open,
}) {
  const [companyId, setCompanyId] = useState(null);
  const [options, setOptions] = useState([]);
  const fetchCompanies = useCallback(debounce((companyName, callback) => {
    getAllCompanies({ name: companyName }).then(({ results }) => {
      const newOptions = results.map(result => ({ label: result.name, value: result.id }));
      callback(newOptions);
      setOptions(newOptions);
    });
  }, 300), [getAllCompanies, setOptions]);

  useEffect(() => {
    if (!open) {
      setCompanyId(null);
      setOptions([]);
    }
  }, [open]);

  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      open={open}
      style={{ width: '496px', height: 'auto', minHeight: '254px' }}
    >
      <Styled.AssignModal>
        <Styled.Title>
          Assign to a company
        </Styled.Title>
        <Select
          value={companyId}
          loadOptions={fetchCompanies}
          options={options}
          name="companyId"
          placeholder="Enter name..."
          async
          searchable
          onChange={({ target: { value } }) => setCompanyId(value)}
          relativeMenu
          error={companyId ? false : null}
        />
        <Button
          disabled={!companyId}
          onClick={() => redirect(`${URLS_CONFIG.admin.addGameDef}?company_id=${companyId}&template_id=${templateId}`)}
        >
Assign
        </Button>
      </Styled.AssignModal>
    </Modal>
  );
}

// Type of props
AssignModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default connect(null, {
  getAllCompanies: LS_AdminCompaniesEntity.actions.getAllCompanies,
})(AssignModal);
