/* Libs */
import styled from 'styled-components';


import { Header } from 'components/blocks/ui/CardsHeader/styles';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  & ${Header} {
    padding-bottom: 10px;
  }
`;

export const Name = styled.div`
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Email = styled.div`
  padding-top: 16px;
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SKY_BLUE};
  padding-bottom: 24px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
