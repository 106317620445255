/* Libs */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/* Constants */
import { URLS_CONFIG } from 'config';

// Auth subroutes
const AUTH_SUB_ROUTES = [
  require('./Signin').default,
  require('./Signup').default,
  require('./ResetPassword').default,
  require('./NewPassword').default,
];

// ----------------
export default class AuthPage extends Component {
  // Static props
  static path = URLS_CONFIG.auth.default;

  render() {
    return (
      <div>
        <Switch>
          {AUTH_SUB_ROUTES.map(route => (
            <Route path={route.path} component={route} key={route.path} />
          ))}
          <Redirect to={URLS_CONFIG.auth.signin} />
        </Switch>
      </div>
    );
  }
}
