/* Libs */

import { css } from 'styled-components';
import COLORS_CONFIG from 'config/layout/colors';

/* Mixins */

export const TextEllipsis = () => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


export const CustomScroll = ({
  scrollBG = COLORS_CONFIG.SILVER,
  thumbScrollBG = COLORS_CONFIG.SILVER_CUSTOM_5,
}) => css`
  
 ::-webkit-scrollbar {
       width: 14px;
       height: 18px;
       background: ${scrollBG};
   }
   ::-webkit-scrollbar-thumb {
       height: 6px;
       border: 4px solid rgba(0, 0, 0, 0);
       background-clip: padding-box;
       -webkit-border-radius: 7px;
       background-color: ${thumbScrollBG};
   }
   ::-webkit-scrollbar-button {
       width: 0;
       height: 0;
       display: none;
   }
   ::-webkit-scrollbar-corner {
       background-color: transparent;
   }

`;
