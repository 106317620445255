import { transformDataToOptions } from 'utils/custom';

export default ({
  gameDefs = [],
} = {}) => transformDataToOptions({
  data: gameDefs.map(gameDef => (gameDef.deleted
    ? {
      ...gameDef,
      name: `${gameDef.name} (old version)`,
    }
    : gameDef)),
  value: 'id',
  label: 'name',
});
