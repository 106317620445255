import GamePlay from './GamePlay';
import AfterGame from './AfterGame';
import BeforeGame from './BeforeGame';
import Questions from './Questions';
import EndGame from './EndGame';

export default {
  GamePlay,
  Questions,
  BeforeGame,
  AfterGame,
  EndGame,
};
