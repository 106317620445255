/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Input, Wrapper as InputWrapper } from 'components/ui/Input/styles';

export const Wrapper = styled.div`
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 4px;
  padding: 20px 21px 23px 21px;
  & ${InputWrapper} {
    min-height: initial;
    padding-right: 15px;
    & ${Input} {
      padding: 8px 0px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid ${COLORS_CONFIG.SKY_BLUE};
      line-height: 16px;
      color: ${COLORS_CONFIG.SMOOTH_BROWN};
      &::placeholder {
        color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
      }
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
