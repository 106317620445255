import { COMMON_ERRORS } from '_constants/errors';
import { isEmpty, isNumber } from 'lodash';

export default ({
  value = null,
  name,
  required = true,
}) => {
  let error = isEmpty(isNumber(value) ? value.toString() : value) && COMMON_ERRORS.isRequired(name);
  error = !required && isEmpty(value) ? null : error;
  return error;
};
