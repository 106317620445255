/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';

import { Wrapper as LogoWrapper } from 'components/blocks/ui/Logo/styles';


export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
  & ${LogoWrapper} {
    width: 230px;
  }
`;

export const Content = styled.div`
  padding-top: 45px;
  & > * {
    height: 100%;
    & .vimeo{
      &-loading{
        display: none;
      }
      &-embed {
        & iframe {
          width: 705px!important;
          height: 297px!important;
        }
      }
  }
  }
`;
