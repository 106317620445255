
import { DATE_SETTINGS } from 'config';


const getModalDateTitle = (startTime, endTime) => {
  const isForTwoDays = !startTime.isSame(endTime, 'day');

  const title = `${startTime.format(DATE_SETTINGS.FORMAT.MONTH_SHORT_DAY_SHORT)}${isForTwoDays
    ? ` - ${endTime.format(DATE_SETTINGS.FORMAT.MONTH_SHORT_DAY_SHORT)}, `
    : ', '} ${startTime.format('LT')} - ${endTime.format('LT')} `;

  return title;
};

export default getModalDateTitle;
