/* Libs */
import React, { Component } from 'react';
import WeekCalendar from 'react-week-calendar';

/* Component */
import { BigTitle } from 'components';

/* Styles */
// import * as Styled from './styles';

export default class WeekCalendarHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
    window.location.reload();
  }

  render() {
    const { hasError } = this.state;

    return hasError
      ? <BigTitle>Your timezone was changed. Page will be reloaded.</BigTitle>
      : <WeekCalendar {...this.props} />;
  }
}
