/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import { Wrapper as ImgWrapper } from 'components/blocks/ui/Img/styles';
import { Wrapper as ConfirmMessageWrapper } from 'components/ui/ConfirmAction/styles';

export const Wrapper = styled.div`
  position: relative;
  & ${ImgWrapper} {
    cursor: pointer;
  }
  & ${ConfirmMessageWrapper} {
    left: calc( 100% + 10px );
    top: -15px;  
    z-index: 10;
  }
`;

export const Label = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 16px;
  cursor: pointer;
  color: ${COLORS_CONFIG.SKY_BLUE};
`;
