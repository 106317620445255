/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { getPercentColorLevel } from 'utils/custom';

/* Styles */

import * as Styled from './styles';

function QuestionStatisticListItem({
  label,
  value,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Label>{label}</Styled.Label>
      <Styled.Value color={getPercentColorLevel(value)}>
        {value}
%
      </Styled.Value>
    </Styled.Wrapper>
  );
}

/* QuestionStatisticListItem type of props */

QuestionStatisticListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

/* QuestionStatisticListItem default props */

QuestionStatisticListItem.defaultProps = {};

export default QuestionStatisticListItem;
