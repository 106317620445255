/* Libs */
import styled from 'styled-components';

import { Wrapper as ListItemWrapper } from 'components/blocks/ListItems/ManageGame/Answer/styles';

export const Wrapper = styled.div`
  & ${ListItemWrapper} {
    padding-top: 25px;
  }
  & ${ListItemWrapper}:last-child {
    padding-bottom: 25px;
  }
`;
