import styled from 'styled-components';

// import { COLORS_CONFIG } from 'config';

// import { Wrapper as Select } from 'components/ui/Select/styles';
// import { Button } from 'components/ui/Button/styles';


export const Wrapper = styled.div``;
/* ${Select} {

.ReactSelect {

  &__control {
    min-height: 50px;
    border-radius: 0;
    border-color: ${COLORS_CONFIG.SKY_BLUE};
  }
}
} */
/* ${Button} {
  margin: 15px auto 0;
}
`; */
