/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
} from 'victory';

/* Hooks */

import { resizeChart } from 'hooks';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

/* Components */

import { ChartTooltip } from 'components';

function ActivityDayChart({
  data,
  yMaxDomain,
}) {
  const width = resizeChart();

  const onMouseOver = () => (
    [
      {
        target: 'data',
        mutation: props => ({
          style: Object.assign({}, props.style, { fill: COLORS_CONFIG.SKY_BLUE }),
        })
        ,
      },
      {
        target: 'labels',
        mutation: () => ({ active: true }),
      },
    ]
  );
  const onMouseOut = () => ([
    {
      target: 'data',
      mutation: () => null,
    },
    {
      target: 'labels',
      mutation: () => ({ active: false }),
    },
  ]);

  if (!data.length) return null;

  return (
    <VictoryChart
      height={450}
      width={width}
      maxDomain={{ y: yMaxDomain }}
    >
      <VictoryBar
        barWidth={60}
        data={data}
        labelComponent={(<ChartTooltip />)}
        labels={(({ datum }) => `${datum.y} sessions`)}
        style={{
          data: { fill: COLORS_CONFIG.GREY_BLUE },
        }}
        events={[{
          target: 'data',
          eventHandlers: {
            onMouseOver,
            onMouseOut,
          },
        }]}
      />
      <VictoryAxis
        minDomain={{ x: 0, y: 0 }}
        maxDomain={{ x: 0, y: yMaxDomain }}
        style={{
          axis: {
            strokeWidth: 0,
          },
          tickLabels: {
            fontSize: 12,
            fontFamily: FONTS_CONFIG.MAIN_TEXT_FONT_BOLD,
            fill: COLORS_CONFIG.SMOOTH_BROWN,
          },
        }}
      />
    </VictoryChart>
  );
}

/* ActivityDayChart type of props */

ActivityDayChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      y: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  yMaxDomain: PropTypes.number,
};

/* ActivityDayChart default props */

ActivityDayChart.defaultProps = {
  data: [],
  yMaxDomain: 0,
};

export default ActivityDayChart;
