/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Constants */
import { STRUCT_IDS } from '_constants';
import { ADD_SESSION_TYPES } from 'config';

/* Components */
import {
  TableActionSidebar,
  AddSessionForm,
} from 'components';

/* Styles */
import * as Styled from './styles';

function AddSessionSidebar({
  open,
  title,
  onClose,
  id,
  ...restProps
}) {
  const [formType, setFormType] = useState(ADD_SESSION_TYPES.SINGLE);

  useEffect(() => {
    if (!open && formType !== ADD_SESSION_TYPES.SINGLE) {
      setFormType(ADD_SESSION_TYPES.SINGLE);
    }
  }, [open]);
  if (!open) return null;
  return (
    <TableActionSidebar
      open={open}
      title={title}
      onClose={onClose}
      id={id}
    >
      <Styled.AddSessionSidebar>
        <AddSessionForm
          {...restProps}
          onClose={onClose}
          formType={formType}
          setFormType={setFormType}
        />
      </Styled.AddSessionSidebar>
    </TableActionSidebar>
  );
}

// Type of props
AddSessionSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

// Default value for props
AddSessionSidebar.defaultProps = {
  id: STRUCT_IDS.COMPANY_ADMIN_PAGE,
  title: null,
};

export default AddSessionSidebar;
