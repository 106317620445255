/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  ReportsList,
  Card,
} from 'components';

/* Styles */

import * as Styled from './styles';

function ReportsSection({
  data,
  next,
  total,
  exportRequest,
  getCampaignActivity,
}) {
  return (
    <Styled.Wrapper>
      <Card title="Campaigns">
        <Styled.Main>
          <ReportsList
            data={data}
            next={next}
            total={total}
            exportRequest={exportRequest}
            getCampaignActivity={getCampaignActivity}
          />
        </Styled.Main>
      </Card>
    </Styled.Wrapper>
  );
}

/* ReportsSection type of props */

ReportsSection.propTypes = {
  exportRequest: PropTypes.func.isRequired,
  getCampaignActivity: PropTypes.func.isRequired,
};

/* ReportsSection default props */

ReportsSection.defaultProps = {};

export default memo(ReportsSection);
