import get from 'lodash.get';

export default kit => ({
  id: kit.id,
  license_start: kit.license_start,
  license_end: kit.license_end,
  storyline: get(kit, 'storyline.id', ''),
  kit_id: kit.kit_id,
  nickname: kit.nickname,
});
