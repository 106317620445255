/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { QuestionCard, Img } from 'components';

/* Styles */

import { NO_QUESTIONS, NO_QUESTIONS_DARK } from 'assets/icons';
import * as Styled from './styles';

function Questions({
  handleAnswerClick,
  questions,
  isDarkMode,
}) {
  return (
    <Styled.Wrapper haveQuestions={questions.length > 0}>
      { questions.length < 1 && <Styled.NotFound><Img disabled src={isDarkMode ? NO_QUESTIONS_DARK : NO_QUESTIONS} size={[487, 453]} /></Styled.NotFound>}
      { questions.map(question => (
        <QuestionCard
          handleAnswer={handleAnswerClick}
          question={question}
          key={question.question}
          name={question.name}
          code={question.unlock_code}
          status={question.status}
          hint_text={question.hint_text || ''}
          isDarkMode={isDarkMode}
        />
      ))}
    </Styled.Wrapper>
  );
}

/* Questions type of props */

Questions.propTypes = {
  handleAnswerClick: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
};

export default Questions;
