/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';
import { CustomScroll } from 'assets/styles/mixins';


export const Struct = styled.div`
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  
`;

export const Header = styled.div`
  position: relative;
  z-index: 4;
  width: 100%;
  height: 80px;
  min-height: 80px;
  &::after{
    content: '';
    top: 100%;
    width: 100%;
    position: absolute;
    height: 1px;
    box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
    border-bottom: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
`;

export const Sidebar = styled.div`
  padding: 40px 32px 0;
  background-color: ${COLORS_CONFIG.WHITE};
  overflow: auto;
  
  ${CustomScroll}
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    padding: 25px 20px;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  position: relative;
  z-index: 2;
  overflow: auto;
  ${CustomScroll}
`;

export const Page = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${COLORS_CONFIG.SILVER};
  overflow: hidden;
  & ${Sidebar} {
    flex: 1.6;
    overflow: hidden;
  }
  & ${Content} {
    flex: 5;
  }
`;
