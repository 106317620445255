/* Libs */
import styled, { css } from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

import {
  Wrapper as SelectWrapper,
  Select,
} from 'components/ui/Select/styles';
import {
  Wrapper as AddCircleWrapper,
} from 'components/ui/AddCircle/styles';
import {
  Wrapper as ToggleWrapper,
  Item as TogglerItem,
} from 'components/ui/ToggleBar/styles';
import {
  Tooltip,
  Content as TooltipContent,
} from 'components/blocks/ui/Tooltip/styles';

export const Wrapper = styled.div`
  position: relative;
  padding: 44px 38px;
  min-height: 100%;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      padding: 30px 38px 44px;
  }

  .weekCalendar__scaleHeader{
    opacity: 0;
  }
  
  .weekCalendar {
    padding-top: 48px;

    &__headerWrapper,
    &__headerColumn,
    &__scaleCell,
    &__scaleHeader,
    &__scaleColumn {
      background: none;
      border: none;
    }

    &__scaleHeader,
    &__header {
      height: 48px;
      line-height: 48px;
    }

    &__scaleHeader {
      background: ${COLORS_CONFIG.SILVER};
    }

    &__scaleColumn {
      padding-top: 48px;
    }

    &__scaleCell {
      opacity: 0;

      span {
        position: relative;
        top: -50%;
      }

      &:nth-child(6n + 1) {
        opacity: 1;
      }

      &:first-child {
        opacity: 0;
      }
    }

    .calendarBody {
      border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};

      &__column {

        &:last-child {
          border: none;
        }
      }

      &__cell {
        pointer-events: none;
        background: none;
        border-color: ${COLORS_CONFIG.TRANSPARENT};

        &:nth-child(6n) {
          border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
        }

        &:last-child {
          border-color: ${COLORS_CONFIG.TRANSPARENT};
        }
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    .weekCalendar {
      padding-left: 90px;

      &__header {
        padding-left: 90px;
      }

      &__scaleColumn,
      &__scaleHeader {
        width: 90px;
      }
    }
  }
`;

export const EventContainer = styled.div`
  padding: 3px 0;
  width: auto;
  margin: 0 3px;
  height: 100%;
  cursor: pointer;
  background: ${COLORS_CONFIG.ORANGE};
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.2;
  overflow: hidden;
  border: none;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$30};
  
  ${({ deactivated }) => deactivated && css`
      background: ${COLORS_CONFIG.GREY_CUSTOM_6};
  `}
`;

export const Title = styled.div`
  padding-top: 14px;
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Date = styled.div`
  margin: 14px 20px 14px 0;
  display: flex;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    margin: 14px 20px 38px 0;
  }
`;

export const CalendarTooltip = styled.div`
  position: relative;
  margin-right: 5px;
  ${Tooltip} {
    z-index: 2;
    top: -16px;
    left: calc(100% + 15px);
  }
  ${TooltipContent} {
    padding: 10px;
    width: 280px;
    min-height: 50px;
    font-size: 13px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  & ${SelectWrapper} {
    width: 162px;
    height: 34px;
    & ${Select} {
      font-size: 14px;
      padding: 9px 12px 10px;
    }
  }
  & ${AddCircleWrapper} {
    margin-right: 30px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 28px;
  width: 100%;
  
  & ${ToggleWrapper} {
    height: 32px;
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
    & ${TogglerItem}{
      padding: 0 20px;
    }  
  }
`;
