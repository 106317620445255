/* Libs */
import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const MenuTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  & > :last-child{
    margin-left: 16px;
  }
`;
