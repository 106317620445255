/* Libs */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Actions */
import { AuthEntity } from '_entities';

/* Utils */
import { getError } from 'utils/custom';
import { formIsValid, isValid } from 'utils/validation';
import { validateEmail, validatePassword } from 'utils/validation/fields';
import { notification } from 'utils/services';

/* Components */
import {
  Button,
  LinkTo,
  Input,
} from 'components';

/* Constants */
import { URLS_CONFIG } from 'config';

class SignUp extends Component {
  state = {
    data: {
      email: '',
      password: '',
    },
    errors: {
      email: null,
      password: null,
    },
    canSubmit: false,
  };

  static propTypes = {
    signup: PropTypes.func.isRequired,
    location: PropTypes.shape({}).isRequired, // TODO: change prop
    history: PropTypes.shape({}).isRequired, // TODO: change prop
  };

  onChange = ({ target: { name, value } }) => {
    const { errors, isValid: isValidate } = this.state;
    switch (name) {
      case 'email':
        errors[name] = validateEmail({ value, name });
        break;
      case 'password':
        errors[name] = validatePassword({ value, name });
        break;
      default:
        break;
    }

    isValidate[name] = errors[name] === false;

    this.setState(prevState => ({
      data: { ...prevState.data, [name]: value },
      errors: { ...errors },
      canSubmit: formIsValid({ ...errors }, ['email', 'password']),
    }));
  };

  onSend = async () => {
    const { signup } = this.props;
    const { data } = this.state;

    try {
      await signup(data); // autoredirect in AuthRoute, when user logged in
    } catch (error) {
      notification.error(getError(error));
    }
  };

  static title = 'Sign up';

  static path = URLS_CONFIG.auth.signup;


  render() {
    const { data, errors, canSubmit } = this.state;
    return (
      <div>
        <div>Sign up</div>
        <div>
          <Input
            placeholder="ExampleEmail@mail.com"
            onChange={this.onChange}
            status={{ statusText: errors.email, valid: isValid(errors.email) }}
            value={data.email}
            // title="Enter your e-mail*"
            name="email"
            type="email"
          />
          <Input
            placeholder="Min 8 char"
            onChange={this.onChange}
            status={{
              statusText: errors.password,
              valid: isValid(errors.password),
            }}
            // title="Enter your password*"
            value={data.password}
            name="password"
          />
        </div>
        <Button onClick={this.onSend} disabled={!canSubmit}>
          Sign in
        </Button>
        <LinkTo
          style={{ margin: '0 12px' }}
          text="Login if you have an account"
          size="big"
          to={URLS_CONFIG.auth.signin}
        />
      </div>
    );
  }
}

export default connect(
  null,
  {
    signup: AuthEntity.actions.signup,
  },
)(SignUp);
