/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { redirect } from 'utils/custom';

/* Components */

import {
  SimpleTextCell, ActionsCell, GoLabel, Table,
} from 'components';

/* Constants */

import { TRIANGLE_TYPES } from '_constants';

/* Styles */

import * as Styled from './styles';

// Paricipants columns cell structure
const getColumnsData = ({ url }) => [
  {
    accessor: 'invited',
    Header: 'Invited',
    Cell: ({ original: { invited } }) => (
      <SimpleTextCell value={invited || '--/--'} size="small" />
    ),
  },
  {
    accessor: 'registered',
    Header: 'Signed up',
    Cell: ({ original: { signed } }) => (
      <SimpleTextCell value={signed || '--/--'} size="small" />
    ),
  },
  {
    accessor: 'actual',
    Header: 'Actually played',
    Cell: ({ original: { actual } }) => (
      <SimpleTextCell value={actual || '--/--'} size="small" />
    ),
  },
  {
    width: 120,
    accessor: 'action',
    Header: '',
    Cell: ({ original: { id, actual } }) => (
      <ActionsCell>
        {actual && (
          <GoLabel
            triangleType={TRIANGLE_TYPES.FORWARD}
            text="Expand"
            click={() => redirect(`${url}/${id}`)}
          />
        )}
      </ActionsCell>
    ),
  },
];

function ParticipantsTable({
  data, tableSize, actions, pagination, url,
}) {
  return (
    <Styled.Participants id="REPORTS_PARTICIPANTS_TABLE">
      <Table
        defaultPageSize={data.length || null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={getColumnsData({ actions, url })}
        data={data}
        pagination={pagination}
      />
    </Styled.Participants>
  );
}

/* ParticipantsTable type of props */

ParticipantsTable.propTypes = {
  url: PropTypes.string.isRequired,
};

/* ParticipantsTable default props */

ParticipantsTable.defaultProps = {};

export default ParticipantsTable;
