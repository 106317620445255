/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function LaunchGameStruct({ children, isDarkMode }) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <Styled.Container>
        {children}
      </Styled.Container>
    </Styled.Wrapper>
  );
}

/* LaunchGameStruct type of props */

LaunchGameStruct.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LaunchGameStruct;
