/* Libs */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

/* Utils */

// import {} from 'utils';

/* Components */

import {
  QuestionStatisticList,
  Select,
  Card,
} from 'components';

/* Constants */

import {
  REPORTS_CONFIG,
} from 'config';

/* Styles */

import * as Styled from './styles';

function QuestionsSection({
  data,
}) {
  const [filter, setFilter] = useState(REPORTS_CONFIG.QUESTION_FILTERS.FROM_STRONG_TO_WEAK);
  const handleChangeFilter = ({ target: { value } }) => (
    setFilter(value)
  );

  const filterSelectOptions = useMemo(() => (
    Object.values(REPORTS_CONFIG.QUESTION_FILTERS).map(item => ({
      label: item,
      value: item,
    }))
  ), []);
  const filteredData = useMemo(() => (
    data.sort((a, b) => (
      a.percentage === b.percentage
        ? 0
        : a.percentage > b.percentage
          ? 1
          : -1
    ) * (REPORTS_CONFIG.QUESTION_FILTERS.FROM_STRONG_TO_WEAK === filter ? -1 : 1))
  ), [data, filter]);

  return (
    <Styled.Wrapper>
      <Card title="Questions">
        <Styled.SubTitle>
          Based on correct answeres for the 1st time
        </Styled.SubTitle>
        <Select
          name="filter"
          placeholder="Select filter"
          value={filter}
          options={filterSelectOptions}
          onChange={handleChangeFilter}
          withoutReset
        />
        <Styled.Main>
          <QuestionStatisticList data={filteredData} />
        </Styled.Main>
      </Card>
    </Styled.Wrapper>
  );
}

/* QuestionsSection type of props */

QuestionsSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

/* QuestionsSection default props */

QuestionsSection.defaultProps = {
  data: [],
};

export default QuestionsSection;
