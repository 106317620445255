/* Libs */
import styled from 'styled-components';
import { Wrapper as ItemWrapper } from 'components/blocks/ListItems/ParticipantLaunch/styles';
import { CustomScroll } from 'assets/styles/mixins';
import { COLORS_CONFIG } from 'config/index';
import BREAKPOINTS from '_constants/BREAKPOINTS';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  overflow-y: auto;
  height: 280px;
  padding-right: 16px;
  padding-bottom: 16px;
  
  ${() => CustomScroll({
    scrollBG: 'transparent',
    thumbScrollBG: COLORS_CONFIG.WHITE,
  })}
  
  & ${ItemWrapper}:not(:last-child) {
    padding-bottom: 12px;
  }
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    height: 195px;
  }
`;
