/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LS_AdminStorylinesEntity } from '_entities';

/* Utils */

import { validateText, validateStorylineImg } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { notification } from 'utils/services';
import { getError } from 'utils/custom';

/* Components */

import {
  Input, Button, InputImage, Img,
} from 'components';
import { INFO_CIRCLE_RED } from 'assets/icons';

/* Constants */

import { NOTIFICATIONS } from '_constants';

/* Styles */

import * as Styled from './styles';

const DEFAULT_ERRORS = Object.freeze({
  image: null,
  name: null,
  description: null,
});

const INITIAL_DATA = {
  image: '',
  name: '',
  description: '',
};

function AddStorylineForm({
  addStoryline,
  loading,
  onClose,
  getAllStorylines,
}) {
  const [formData, setData] = useState({
    data: INITIAL_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'image':
        newErrors[name] = validateStorylineImg({
          value,
          name,
        });
        break;
      case 'name':
        newErrors[name] = validateText({
          value,
          name,
          min: 2,
        });
        break;
      case 'description':
        newErrors[name] = validateText({
          value,
          name,
          required: false,
        });
        break;
      default:
        break;
    }
    setData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['image', 'name']),
    });
  };

  const handleSave = async () => {
    try {
      const requestFormData = new FormData();

      Object.keys(formData.data).forEach((key) => {
        requestFormData.append(key, formData.data[key]);
      });

      await addStoryline(requestFormData);
      await getAllStorylines();
      notification.success(NOTIFICATIONS.SUCCESS_STORYLINE_ADDED);
      onClose();
    } catch (error) {
      const { errors } = formData;
      const newErrors = Object.entries(error.response.data)
        .reduce(
          (acc, [key, value]) => {
            acc[key] = value;
            return acc;
          },
          { ...errors },
        );

      if (newErrors) {
        setData(prev => ({
          ...prev,
          errors: newErrors,
          canSubmit: formIsValid({ ...newErrors }, ['image', 'name']),
        }));
      } else {
        notification.error(getError(error));
      }
    }
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'name':
        newErrors[name] = validateText({
          value,
          name,
          min: 2,
        });
        break;
      case 'description':
        newErrors[name] = validateText({
          value,
          name,
          required: false,
        });
        break;
      default:
        break;
    }
    setData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['image', 'name']),
    }));
  };

  const { data, errors, canSubmit } = formData;
  return (
    <Styled.Wrapper>
      <Styled.ImageInput>
        <Styled.Text>Upload an icon</Styled.Text>
        <InputImage
          name="image"
          error={errors.image}
          value={data.image}
          onChange={handleChange}
        />
      </Styled.ImageInput>
      <Input
        placeholder="Name storyline"
        title="Name storyline"
        name="name"
        error={errors.name}
        value={data.name}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Input
        placeholder="Description"
        title="Description"
        name="description"
        error={errors.description}
        value={data.description}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Styled.ImageError>
        {
          errors.image && (
            <>
              <Img disabled src={INFO_CIRCLE_RED} size={[13, 13]} />
              {errors.image}
            </>
          )
        }
      </Styled.ImageError>
      <Button
        disabled={!canSubmit || loading}
        isLoading={loading}
        onClick={handleSave}
      >
        Save storyline
      </Button>
    </Styled.Wrapper>
  );
}

/* addStorylineForm type of props */

AddStorylineForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addStoryline: PropTypes.func.isRequired,
  getAllStorylines: PropTypes.func.isRequired,
};

export default connect(
  ({ LS_adminStorylines }) => ({
    loading: LS_adminStorylines.loading,
  }),
  {
    addStoryline: LS_AdminStorylinesEntity.actions.addStoryline,
  },
)(AddStorylineForm);
