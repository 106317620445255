/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { isNull } from 'utils/validation';

/* Styles */

import * as Styled from './styles';

function NumberPicker({
  onChange,
  incrementable,
  decrementable,
  incrementDisabled,
  decrementDisabled,
  name,
  value,
  max,
  min,
}) {
  const handleArrowClick = increase => () => {
    const nextValue = increase ? +value + 1 : +value - 1;
    onChange({ target: { name, value: nextValue } });
  };
  const isIncrementDisabled = incrementDisabled || (!isNull(max) && +value + 1 > max);
  const isDecrementDisabled = decrementDisabled || (!isNull(min) && +value - 1 < min);
  return (
    <Styled.Wrapper>
      { incrementable && <Styled.Arrow onClick={isIncrementDisabled ? null : handleArrowClick(true)} direction="top" /> }
      <Styled.Input
        value={value && value.toString ? value.toString().padStart(2, '0') : value}
        name={name}
        onChange={onChange}
      />
      { decrementable && <Styled.Arrow onClick={isDecrementDisabled ? null : handleArrowClick(false)} direction="bottom" /> }
    </Styled.Wrapper>
  );
}

/* NumberPicker type of props */

NumberPicker.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  incrementable: PropTypes.bool,
  decrementable: PropTypes.bool,
  incrementDisabled: PropTypes.bool,
  decrementDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

/* NumberPicker default props */

NumberPicker.defaultProps = {
  incrementable: true,
  decrementable: true,
  incrementDisabled: false,
  decrementDisabled: false,
  max: null,
  min: null,
};

export default memo(NumberPicker);
