/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { CardsHeader } from 'components';

/* Styles */

import * as Styled from './styles';

function Card({
  title,
  actions,
  children,
}) {
  return (
    <Styled.Wrapper>
      <CardsHeader
        title={title}
        actions={actions}
      />
      { children }
    </Styled.Wrapper>
  );
}

/* Card type of props */

Card.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
    PropTypes.node,
  ])),
  children: PropTypes.node.isRequired,
};

/* Card default props */

Card.defaultProps = {
  actions: [],
};

export default Card;
