/* Libs */
import styled, { css } from 'styled-components';
import {
  Button
} from 'components/ui/Button/styles';
import { Error } from 'components/ui/Input/styles';
import { COLORS_CONFIG } from 'config';

export const Actions = styled.div`
  background-color:${COLORS_CONFIG.SKY_BLUE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 31px;
  width: 100%;
  & ${Button} {
    width: 160px;
    min-width: 160px;
    background-color: ${COLORS_CONFIG.WHITE};
    color: ${COLORS_CONFIG.SKY_BLUE};
    &:last-child {
      margin-top: 37px;
      padding: 0;
      height: initial;
      background-color: ${COLORS_CONFIG.TRANSPARENT};
      color: ${COLORS_CONFIG.WHITE};
    }
  }
`;


export const Wrapper = styled.div`
   & ${Error}{
     color: ${COLORS_CONFIG.RED_DARK};
  }
 
  ${({ isDarkMode }) => isDarkMode && css`
  
     & ${Actions} {
       background: ${COLORS_CONFIG.TRANSPARENT};
       
       & ${Button} {
           background-color: ${COLORS_CONFIG.WHITE};
           color: ${COLORS_CONFIG.SOFT_BLUE};
           &:last-child {
             margin-top: 30px;
             padding: 0;
             height: initial;
             background-color: ${COLORS_CONFIG.TRANSPARENT};
             color: ${COLORS_CONFIG.WHITE};
           }
        }
     }
   `}
`;
