/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import { Button } from 'components/ui/Button/styles';

export const Wrapper = styled.div`
  position: relative;
  padding: 55px 25px;

  ${Button} {
    margin: 0 auto;
  }
`;

export const Header = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLORS_CONFIG.SILVER_LIGHT};
`;

export const Title = styled.div`
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Location = styled.div`
  margin-top: 10px;
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-size: 14px;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
`;

export const Item = styled.div`
  margin-bottom: 25px;
  padding-right: 20px;
  width: 50%;
  &:nth-child(even) {
    margin-right: 0;
  }
`;

export const ItemTitle = styled.div`
  max-width: 100%;
  font-size: 15px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemText = styled.div`
  max-width: 100%;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ centralize }) => (centralize ? 'center' : 'space-between')};
  ${Button} {
    margin: 0;
  }
`;
