/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import * as Styled from './styles';

function EmptyBlockText({ text }) {
  return (
    <Styled.Wrapper>
      <Styled.Text>{text}</Styled.Text>
    </Styled.Wrapper>
  );
}

EmptyBlockText.propTypes = {
  text: PropTypes.string,
};

EmptyBlockText.defaultProps = {
  text: 'Nothing found.',
};

export default EmptyBlockText;
