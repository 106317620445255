/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import { COLORS_CONFIG } from 'config';

export const Span = styled.span`
  display: block;
  border-radius: 50%;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.2s;
  }
`;

export const ButtonLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;


  ${Span} {
    ${({ color }) => css`
        background-color: ${color};
      `}

    ${({ size }) => size === 'small'
      && css`
        width: 6px;
        height: 6px;
        margin: 2px;
      `}

    ${({ size }) => size === 'middle'
      && css`
        width: 8px;
        height: 8px;
        margin: 3px;
      `}

    ${({ size }) => size === 'big'
      && css`
        width: 12px;
        height: 12px;
        margin: 4px;
      `}


    ${({ stretch }) => stretch
      && css`
        width: 12px;
        height: 12px;
        margin: 4px;
      `}
  }
`;
