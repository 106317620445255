export default (month, year, { startDate, endDate }) => {
  const weeks = [];
  const firstDate = startDate || new Date(year, month, 1);
  const lastDate = endDate || new Date(year, month + 1, 0);
  const numDays = lastDate.getDate();

  let start = startDate ? firstDate.getDate() : 1;
  let end = start - firstDate.getDay() + 6 > numDays
    ? numDays
    : start - firstDate.getDay() + 6;

  while (start <= numDays) {
    weeks.push({ start, end });
    start = end + 1;
    end += 7;
    if (end > numDays) {
      end = numDays;
    }
  }

  return weeks;
};
