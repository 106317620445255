export default {
  MAX_QUESTIONS_COUNT: 10,
  REQUIRED_FIELDS: {
    QUESTION: [
      'correct_answer_message',
      'incorrect_answer_message',
      'name',
      'question',
      'unlock_code',
      'answers',
    ],
  },
  DEFAULT_ERRORS: {
    QUESTION: {
      correct_answer_message: null,
      hint_text: null,
      incorrect_answer_message: null,
      intro_vimeo_id: null,
      name: null,
      question: null,
      unlock_code: null,
      answers: [null],
      security_context: null,
    },
  },
};
