/* Libs */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'assets/styles/cropper.scss';
/* Utils */

import { isEmpty } from 'utils/validation';
import { fileToBase64, urlToFile } from 'utils/transformers';
import { useToggleBooleanHook } from 'hooks';

/* Components */

import { InputImage, Img } from 'components';

/* Constants */

import { LOGO_PLACEHOLDER } from 'assets/icons/logo';
import { RED_CROSS_CIRCLE } from 'assets/icons/signs';

/* Styles */

import { notification } from 'utils/services';
import { NOTIFICATIONS_CONFIG } from 'config';
import * as Styled from './styles';


function ImgCropper({
  value,
  onChange,
  name,
  placeholder,
}) {
  const cropper = useRef(null);
  const preview = useRef(null);
  const wrapper = useRef(null);
  const hasImage = !isEmpty(value);
  const [cropperWidth, setCropperWidth] = useState(0);
  const [isEditing, toggleEditing] = useToggleBooleanHook(false);
  const [editingImage, setEditingImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const deleteImg = () => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
    {
      onSuccess: () => onChange({ target: { name, value: '' } }),
      onCancel: notification.removeAllNotifications,
      message: 'Are you sure that you want to delete logo?',
    },
  ));

  useEffect(() => {
    async function setPreview() {
      let newPreviewImage = null;
      if (hasImage) {
        newPreviewImage = value instanceof File ? await fileToBase64(value) : value;
      }
      setPreviewImage(newPreviewImage);
    }
    setPreview();
  }, [value]);
  const changeImage = async ({ target: { value } }) => {
    setEditingImage(await fileToBase64(value));
    toggleEditing(true);
  };

  const applyImage = async () => {
    const file = await urlToFile(cropper.current.getCroppedCanvas().toDataURL(), 'company_logo.jpeg', 'image/jpeg');
    onChange({ target: { name, value: file } });
    toggleEditing(false);
  };

  useEffect(() => {
    setCropperWidth(wrapper.current.offsetWidth / 2);
  }, []);

  return (
    <Styled.Wrapper ref={wrapper} isEditing={isEditing}>
      {
        isEditing
          ? (
            <Styled.CroppedSection>
              <Styled.LeftSide>
                <Cropper
                  ref={cropper}
                  src={editingImage}
                  style={{ height: 300, width: cropperWidth }}
                  dragMode="none"
                  preview={preview}
                  viewMode={1}
                  aspectRatio={205 / 80}
                  guides={false}
                />
              </Styled.LeftSide>
              <Styled.RightSide>
                <Styled.Preview ref={preview} />
                <Styled.Actions>
                  <Styled.Action onClick={toggleEditing} type="cancel">
                    Cancel
                  </Styled.Action>
                  <Styled.Action onClick={applyImage} type="apply">
                    Apply
                  </Styled.Action>
                </Styled.Actions>
              </Styled.RightSide>
            </Styled.CroppedSection>

          )
          : (
            <Styled.PreviewWrapper hasImage={hasImage}>
              <Styled.DeleteImg>
                <Img onClick={deleteImg} src={RED_CROSS_CIRCLE} size={[18, 18]} />
              </Styled.DeleteImg>
              <InputImage
                name="logo"
                onChange={changeImage}
                size={[205, 80]}
                withPreview
                value={previewImage || placeholder}
              />
            </Styled.PreviewWrapper>
          )
      }

    </Styled.Wrapper>
  );
}

/* ImgCropper type of props */

ImgCropper.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  // placeholder: PropTypes.string,
};

/* ImgCropper default props */

ImgCropper.defaultProps = {
  placeholder: LOGO_PLACEHOLDER,
};

export default ImgCropper;
