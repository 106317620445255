/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    flex-wrap: wrap;
  }
`;

export const Title = styled.div`
  margin-right: 10px;
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    margin: 10px 10px 10px 0;
  }
`;
