/* Libs */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

/* Utils */

import { redirect, getViewCompanyLink } from 'utils/custom';

/* Components */
import {
  SimpleTextCell,
  ActionsCell,
  Table,
  GoLabel,
  LinkTo,
} from 'components';

/* Constants */

import { COMPANIES_TABLE, DATE_SETTINGS, COMPANY_TYPES } from 'config';
import { TRIANGLE_TYPES, LABEL_COLOR_TYPES } from '_constants';

/* Styles */
import * as Styled from './styles';

// Companies columns cell structure
const getColumnsData = ({ companyType, actions }) => [
  {
    accessor: 'name',
    minWidth: 95,
    // maxWidth: 200,
    flexGrow: 3,
    Header: 'Company name',
    Cell: props => <SimpleTextCell value={props.value} size="small" />,
  },
  {
    accessor: 'first_name',
    minWidth: 95,
    // maxWidth: 200,
    flexGrow: 2,
    Header: 'Point of contact',
    Cell: ({
      original: {
        first_name,
        last_name,
      },
    }) => <SimpleTextCell value={`${first_name} ${last_name}`} size="small" />,
  },
  {
    accessor: 'licenses',
    minWidth: 95,
    // maxWidth: 100,
    Header: 'Licenses',
    Cell: props => <SimpleTextCell value={props.value} size="small" />,
  },
  {
    accessor: companyType === COMPANY_TYPES.ACTIVE ? 'start_date' : 'deactivation_date',
    minWidth: 95,
    Header: companyType === COMPANY_TYPES.ACTIVE ? 'Start date' : 'Deactivation date',
    Cell: (props) => {
      const formattedDate = props.value ? moment(props.value).format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR) : '-';
      return <SimpleTextCell value={formattedDate} size="small" />;
    },
  },
  {
    accessor: 'actions',
    minWidth: companyType === COMPANY_TYPES.ACTIVE ? 200 : 90,
    // maxWidth: companyType === COMPANY_TYPES.ACTIVE ? 200 : 90,
    Header: '',
    Cell: ({
      original: {
        id,
      },
    }) => (
      <ActionsCell>
        {
          companyType === COMPANY_TYPES.ACTIVE ? (
            <>
              <LinkTo to={getViewCompanyLink(id)}>Manage</LinkTo>
              <div onClick={() => actions.impersonate(id)}>Impersonate</div>
            </>
          ) : (
            <>
              <GoLabel
                text="Activate"
                triangleType={TRIANGLE_TYPES.FORWARD}
                color={LABEL_COLOR_TYPES.BLUE}
                click={() => actions.activate(id)}
              />
            </>
          )
        }
      </ActionsCell>
    ),
  },
];


export default function CompaniesTable({
  data, actions, pagination, companyType,
}) {
  return (
    <Styled.CompaniesTable>
      <Table
        defaultPageSize={data.length > 10 ? 10 : data.length}
        rowMinHeight="small"
        columns={getColumnsData({ actions, companyType })}
        pagination={pagination}
        sortable={COMPANIES_TABLE.sortable}
        data={data}
      />
    </Styled.CompaniesTable>
  );
}

// Type of props
CompaniesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    // first_name: PropTypes.string.isRequired,
    // last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    licenses: PropTypes.number.isRequired,
    // start_date: PropTypes.string.isRequired,
    start_date: PropTypes.string,
  })).isRequired,
};
