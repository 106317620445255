/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Wrapper as ImgWrapper } from 'components/blocks/ui/Img/styles';
import { Button } from 'components/ui/Button/styles';

export const Wrapper = styled.div`
  padding: 30px 48px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
  & > ${Button} {
    margin-top: 30px;
    width: 193px;
  }
`;

export const ImageError = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${COLORS_CONFIG.ORANGE};
  font-size: 12px;
  height: 14px;
  
  ${ImgWrapper} {
    margin-right: 5px;
    transform: translateY(-1px);
  }
`;

export const ImageInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 16px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK}
`;
