/* Libs */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
/* Actions */

import { ParticipantEntity } from '_entities';

/* Utils */

import { getError, redirect, redirectWithError } from 'utils/custom';

/* Components */
import {
  ParticipantCanceledSessionForm,
  ParticipantSignupCampaignForm,
  ParticipantSignupSessionForm,
  ParticipantSignupStruct,
  ContainerLoader,
  Logo,
  Loader,
} from 'components';

/* Constants */
import { URLS_CONFIG, LOGO_CONFIG } from 'config';
import { PARTICIPANT_SIGNUP_TYPE, NOTIFICATIONS } from '_constants';
import { ANSWER_ERRORS } from '_constants/errors';

/* Styles */
import * as Styled from './styles';

const PAGE_TITLE = 'Participant Signup';

const STEPS = {
  acceptInvite: 'acceptInvite',
  confirmMessage: 'confirmMessage',
};

function ParticipantSignup({
  loaded,
  inviteType,
  checkParticipantToken,
  participant_email,
  gameSession,
  participantSignup,
  participantToken,
}) {
  const isMounted = useRef(false);
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(STEPS.acceptInvite);

  const changeStep = desiredStep => () => setStep(desiredStep);

  async function initialLoad() {
    const token = new URLSearchParams(window.location.search).get('token');

    if (!token) {
      return redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.ERROR_NO_TOKEN);
    }

    if (localStorage.getItem('alreadyBooked')) {
      localStorage.removeItem('alreadyBooked');
      redirect(URLS_CONFIG.auth.signin, NOTIFICATIONS.SUCCESS_BOOKING);
    }

    try {
      await checkParticipantToken(token);
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    } catch (error) {
      if (getError(error).trim() === ANSWER_ERRORS.multipleBookingNotAllowed) {
        return redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.MULTIPLE_BOOKING_NOT_ALLOWED);
      }
      redirectWithError(URLS_CONFIG.auth.signin, error);
    }
  }

  useEffect(() => {
    if (isEmpty(inviteType) || inviteType !== PARTICIPANT_SIGNUP_TYPE.SESSION_DIRECT) return;

    (async () => {
      try {
        setLoading(true);
        await participantSignup({
          game_session: gameSession.id,
          token: participantToken,
          participant_email,
        });
        setLoading(false);
        setStep(STEPS.confirmMessage);
      } catch (error) {
        redirectWithError(URLS_CONFIG.auth.signin, error);
      }
    })();
  }, [inviteType]);


  useEffect(() => {
    initialLoad();
  }, []);

  function getWayOfRegistration() {
    return inviteType === PARTICIPANT_SIGNUP_TYPE.CANCELED
      ? <ParticipantCanceledSessionForm />
      : (
        <ParticipantSignupSessionForm
          step={step}
          steps={STEPS}
          changeStep={changeStep}
        />
      );
  }

  function getContent() {
    // Comment this logic because of infinity redirect
    // if (loaded && !inviteType) return redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.ERROR_DEFAULT);
    if (loaded && !inviteType) return null;

    if (!loaded) return <ContainerLoader />;

    return [
      PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_PUBLIC,
      PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_DIRECT,
      PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_EDIT,
    ].includes(inviteType)
      ? <ParticipantSignupCampaignForm loaded={loaded} />
      : isLoading
        ? <Loader />
        : (
          <Styled.Wrapper>
            <Logo type={LOGO_CONFIG.VERTICAL_WHITE_WHITE} />
            <Styled.Content>
              {loaded && getWayOfRegistration()}
            </Styled.Content>
          </Styled.Wrapper>
        );
  }

  return (
    <ParticipantSignupStruct>
      { getContent() }
    </ParticipantSignupStruct>
  );
}

/* Page Url */

ParticipantSignup.path = URLS_CONFIG.participant.signup;

/* Page Title */

ParticipantSignup.title = PAGE_TITLE;

/* Component PropTypes */

ParticipantSignup.propTypes = {
  checkParticipantToken: PropTypes.func.isRequired,
  inviteType: PropTypes.oneOf(Object.values(PARTICIPANT_SIGNUP_TYPE)),
  loaded: PropTypes.bool.isRequired,
};

export default connect(({ participant }) => ({
  participantToken: participant.token,
  loaded: participant.loaded,
  inviteType: participant.inviteType,
  gameSession: participant.gameSession,
  participant_email: participant.participant_email,
}), {
  checkParticipantToken: ParticipantEntity.actions.checkToken,
  participantSignup: ParticipantEntity.actions.signup,
})(ParticipantSignup);
