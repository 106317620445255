/* Libs */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { debounce } from 'lodash';
import { history } from 'lib';

/* Hooks */

import { useHandleTimezoneChangeHook } from 'hooks';

/* Utils */

import { notification } from 'utils/services';
import {
  getError,
  getSessionStatus,
  goBack,
  transformDataToOptions,
} from 'utils/custom';

/* Components */

import {
  CalendarSidebar,
  CalendarHeader,
  ToggleBar,
  Select,
  ViewParticipantsSidebar,
  SearchInput,
  GoBackButton,
  ParticipantsViewTable,
  Pagination,
  ParticipantSessionInfoModal,
} from 'components';

/* Actions */

import { AdminCompanyEntity } from '_entities';

/* Constants */

import {
  URLS_CONFIG,
  TABLE_TYPES,
  DATE_SETTINGS,
  TABLES_CONFIG,
  GAME_SESSIONS_TABLE,
} from 'config';
import { CALENDAR_STYLES, SESSION_STATUSES } from '_constants';

/* Styles */

import WeekCalendar from 'react-week-calendar';
import * as Styled from './styles';

const PAGE_TITLE = 'ParticipantView';

const DEFAULT_DATA = {
  campaign: null,
  campaignData: null,
  date: new Date(),
  timeRange: TABLE_TYPES.WEEK,
  location: null,
  teamName: '',
};

function ParticipantView({
  match: {
    params: { participantId, campaignId },
  },
  getCampaigns,
  getCampaign,
  getDaysWithGameSession,
  getLocations,
  campaigns,
  locations,
  loading,
  company,
  daysWithGameSession,
  getLeaderboard,
  leaderboard,
  getGameSession,
}) {
  const isCampaignParticipant = Boolean(campaignId);

  const [data, setData] = useState({ ...DEFAULT_DATA });
  const [page, setPage] = useState(1);
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [fetchedSessions, setFetchedSessions] = useState({});
  const [currentSession, setCurrentSession] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useHandleTimezoneChangeHook();

  const manageSidebar = sidebarData => () => setActiveSidebar(sidebarData);

  const handleChange = ({ target: { name, value } }) => setData({ ...data, [name]: value });

  const handlePageChange = ({ selected }) => setPage(selected + 1);

  const handleDayChange = e => (data.timeRange === TABLE_TYPES.WEEK
    ? setData({
      ...data,
      timeRange: TABLE_TYPES.DAY,
      [e.target.name]: e.target.value,
    })
    : handleChange(e));
  const openParticipantsSidebar = id => manageSidebar({
    name: 'participant',
    id,
  })();

  const closeHandler = () => manageSidebar(null)();

  const handleCampaignChange = async ({ target: { name, value } }) => {
    setData({
      ...data,
      [name]: value,
      location: DEFAULT_DATA.location,
    });
  };

  const loadDaysWithGameSession = async () => {
    const startDay = moment(data.date)
      .startOf('month')
      .startOf('week');
    const endDay = moment(data.date)
      .endOf('month')
      .endOf('week');

    try {
      await getDaysWithGameSession({
        campaign: data.campaignData.founder_id,
        fromDate: startDay.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        toDate: endDay.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        participant: participantId,
      });
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const fetchCampaigns = (campaign, callback) => {
    getCampaigns({
      campaign,
      isActive: true,
      withoutStoreChanging: true,
      perPage: 10,
      company,
    }).then(({ results: fetchedCampaigns }) => {
      callback(
        transformDataToOptions({
          data: fetchedCampaigns,
          value: 'id',
          label: 'name',
        }),
      );
    });
  };

  const loadCampaigns = debounce(fetchCampaigns, 300);

  const fetchLocations = (location, callback) => {
    getLocations({ location, withoutStoreChanging: true }).then(
      ({ results: fetchedLocations }) => {
        callback(
          transformDataToOptions({
            data: fetchedLocations,
            value: 'id',
            label: 'location',
          }),
        );
      },
    );
  };

  const loadLocations = debounce(fetchLocations, 300);

  const fetchLeaderboard = useCallback(() => {
    (async () => {
      const token = data.campaignData && data.campaignData.token;
      if (token) {
        const params = {
          token,
          participant: participantId,
          team_name: data.teamName,
          location: data.location,
          page,
        };
        if (data.timeRange === TABLE_TYPES.WEEK) {
          params.from_date = moment(data.date)
            .startOf('week')
            .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY);
          params.to_date = moment(data.date)
            .endOf('week')
            .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY);
        } else {
          params.from_date = moment(data.date).format(
            DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
          );
          params.to_date = moment(data.date).format(
            DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
          );
        }
        const result = await getLeaderboard(params);
        if (result.count !== totalCount) {
          setTotalCount(result.count);
        }
      }
    })();
  }, [
    data.teamName,
    data.campaignData,
    data.date,
    data.date.location,
    data.location,
    data.timeRange,
    page,
  ]);

  const tableData = useMemo(() => ((
    data.timeRange === TABLE_TYPES.DAY
      && data.calendarStyle === CALENDAR_STYLES.ALL
  )
    ? leaderboard
    : leaderboard.reduce((arr, session) => {
      const start = moment(session.start_time).utc();
      const end = moment(session.end_time).utc();

      /**
       * Split session on 2 parts because react-week-calendar render only
       * last part of session which starts on one day and ends on another.
       * Moment-s methods endOf and startOf don't work and module need
       * ONLY moment objects in format as come from backend.
       */
      return arr.concat(
        start.date() === end.date()
          ? {
            start,
            end,
            ...session,
          }
          : [
            {
              start,
              end: moment(`${start.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)}T23:59:59Z`).utc(),
              ...session,
            },
            {
              start: moment(`${end.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)}T00:00:00Z`).utc(),
              end,
              ...session,
            },
          ],
      );
    }, [])),
  [data.calendarStyle, data.timeRange, leaderboard]);

  const debouncedFetchLeaderboard = useCallback(
    debounce(fetchLeaderboard, 300),
    [fetchLeaderboard],
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const campaign = isCampaignParticipant
          ? await getCampaign({ id: campaignId })
          : await getCampaigns({
            company,
            perPage: 10,
          }).then(({ results }) => results[0]);

        getLocations();

        if (!campaign) return;

        setData({
          ...data,
          campaign: campaign.id,
          campaignData: campaign,
        });
      } catch (error) {
        notification.error(getError(error));
      }
    }

    if (company) fetchData();
  }, []);

  const openSessionInfoModal = (session) => {
    if (!session) {
      notification.error(getError());
      return;
    }

    setCurrentSession(session);
    setShowModal(true);
  };

  const generateEvent = session => (
    <Styled.EventContainer
      disabled={getSessionStatus(session.game_session_status) !== SESSION_STATUSES.NAME.SCHEDULED}
      onClick={() => openSessionInfoModal(session)}
    />
  );

  useEffect(() => {
    (async () => {
      if (!activeSidebar) return;

      if (fetchedSessions[activeSidebar.id]) {
        setCurrentSession(fetchedSessions[activeSidebar.id]);
      } else {
        const result = await getGameSession(activeSidebar.id);
        setFetchedSessions(prev => ({ ...prev, [activeSidebar.id]: result }));
        setCurrentSession(result);
      }
    })();
  }, [activeSidebar]);

  useEffect(() => {
    debouncedFetchLeaderboard();
  }, [data.teamName]);

  useEffect(() => {
    setPage(1);
  }, [
    data.location,
    data.date,
    data.campaignData,
    data.timeRange,
  ]);

  useEffect(fetchLeaderboard, [
    data.location,
    data.date,
    data.campaignData,
    data.timeRange,
    page,
  ]);

  useEffect(() => {
    const campaign = campaigns.find(({ id }) => id === data.campaign);
    setData(prev => ({
      ...prev,
      campaignData: campaign,
    }));
  }, [data.campaign]);

  useEffect(() => {
    if (data.campaign) {
      loadDaysWithGameSession();
    }
  }, [moment(data.date).month(), data.campaignData, data.campaign]);

  const handleContentCalendarHeaderBackClick = () => handleChange({
    target: {
      name: 'date',
      value: new Date(
        moment(data.date).subtract(
          1,
          data.timeRange === TABLE_TYPES.DAY ? 'd' : 'w',
        ),
      ),
    },
  });

  const handleContentCalendarHeaderForwardClick = () => handleChange({
    target: {
      name: 'date',
      value: new Date(
        moment(data.date).add(
          1,
          data.timeRange === TABLE_TYPES.DAY ? 'd' : 'w',
        ),
      ),
    },
  });

  const contentCalendarHeaderFormat = DATE_SETTINGS.FORMAT[
    data.timeRange === TABLE_TYPES.WEEK ? 'MONTH_DAY' : 'DAY_MONTH_DATE'
  ];
  const campaignOptions = useMemo(() => transformDataToOptions({
    data: campaigns,
    value: 'id',
    label: 'name',
  }).map(option => ({
    ...option,
    label:
      campaigns.some(campaign => option.value === campaign.id && !campaign.is_active)
        ? `${option.label} (inactive)`
        : option.label,
  })), [campaigns]);

  const participantsSidebarData = useMemo(() => {
    if (!currentSession) return [];
    return {
      invited_participants: currentSession.invited_participants,
      signedup_participants: currentSession.signedup_participants,
      actual_participants: currentSession.actual_participants,
    };
  }, [currentSession]);

  return (
    <Styled.Page>
      <Styled.Sidebar>
        <CalendarSidebar
          campaign={data.campaign}
          campaignPicker={(
            <Select
              name="campaign"
              placeholder="Select campaign"
              value={data.campaign}
              onChange={handleCampaignChange}
              options={campaignOptions}
              defaultOptions={campaignOptions}
              loadOptions={loadCampaigns}
              async
              searchable
              withoutReset
              isDisabled={isCampaignParticipant}
            />
          )}
          toggleBar={(
            <ToggleBar
              name="timeRange"
              onChange={handleChange}
              active={data.timeRange}
              options={[TABLE_TYPES.DAY, TABLE_TYPES.WEEK]}
            />
          )}
          calendarData={{
            value: data.date,
            name: 'date',
            onChange: handleChange,
            events: daysWithGameSession,
            isDayVisible: data.timeRange !== TABLE_TYPES.WEEK,
            onDayChange: handleDayChange,
          }}
        />
      </Styled.Sidebar>
      <Styled.Content>
        <Styled.Header>
          <Styled.Controls>
            <Styled.Row>
              {
                history.length > 1 && (
                  <GoBackButton onClick={goBack}>Back</GoBackButton>
                )
              }
            </Styled.Row>
          </Styled.Controls>
          <Styled.Controls>
            <Styled.Row>
              <Styled.Date>
                <CalendarHeader
                  date={data.date}
                  dateInterval={data.timeRange === TABLE_TYPES.WEEK}
                  onBackClick={handleContentCalendarHeaderBackClick}
                  onForwardClick={handleContentCalendarHeaderForwardClick}
                  format={contentCalendarHeaderFormat}
                  calendarType={data.timeRange}
                />
              </Styled.Date>
            </Styled.Row>
            <Styled.Row>
              <SearchInput
                name="teamName"
                value={data.teamName}
                onChange={handleChange}
                placeholder="Search by team name"
              />
              <Select
                name="location"
                placeholder="Filter by location"
                value={data.location}
                onChange={handleChange}
                options={transformDataToOptions({
                  data: locations,
                  value: 'id',
                  label: 'location',
                })}
                defaultOptions={transformDataToOptions({
                  data: locations,
                  value: 'id',
                  label: 'location',
                })}
                loadOptions={loadLocations}
                async
                searchable
              />
            </Styled.Row>
          </Styled.Controls>
        </Styled.Header>
        <Styled.CalendarWrapper>
          {data.timeRange === TABLE_TYPES.DAY
            ? (
              <ParticipantsViewTable
                data={leaderboard}
                tableSize={TABLES_CONFIG.SIZE.S}
                actions={{
                  openParticipantsSidebar,
                }}
                date={data.date}
                pagination={(
                  <Pagination
                    pageCount={totalCount / GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE}
                    pageChangeHandler={handlePageChange}
                    activePage={page}
                  />
                )}
                loading={loading}
              />
            )
            : (
              <WeekCalendar
                firstDay={
                  (
                    data.date.getTimezoneOffset() >= 0
                      ? moment(data.date).startOf('day')
                      : moment(data.date).endOf('day')
                  )
                    .utc()
                    .startOf('week')
                }
                numberOfDays={7}
                cellHeight={7}
                useModal={false}
                dayFormat={DATE_SETTINGS.FORMAT.SHORT_DAY}
                scaleFormat="LT"
                scaleUnit={10}
                selectedIntervals={tableData}
                eventSpacing={0}
                eventComponent={generateEvent}
              />
            )
          }
        </Styled.CalendarWrapper>
      </Styled.Content>
      <ViewParticipantsSidebar
        title="Participants view"
        open={activeSidebar && activeSidebar.name === 'participant'}
        onClose={closeHandler}
        data={participantsSidebarData}
      />
      <ParticipantSessionInfoModal
        open={showModal}
        onClose={() => setShowModal(false)}
        session={currentSession}
      />
    </Styled.Page>
  );
}

/* Page Url */

ParticipantView.path = URLS_CONFIG.companyAdmin.reports.viewParticipant;

/* Page Title */

ParticipantView.title = PAGE_TITLE;

/* ParticipantView type of props */

ParticipantView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      participantId: PropTypes.string.isRequired,
      campaignId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  getCampaigns: PropTypes.func.isRequired,
  getCampaign: PropTypes.func.isRequired,
  getDaysWithGameSession: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  campaigns: PropTypes.arrayOf({}), // TODO: write correct PropType
  locations: PropTypes.arrayOf({}), // TODO: write correct PropType
  loading: PropTypes.bool.isRequired,
  company: PropTypes.number,
  daysWithGameSession: PropTypes.arrayOf({}), // TODO: write correct PropType
  getLeaderboard: PropTypes.func.isRequired,
  leaderboard: PropTypes.arrayOf({}), // TODO: write correct PropType
  getGameSession: PropTypes.func.isRequired,
};

/* ParticipantView default props */

ParticipantView.defaultProps = {
  company: null,
  campaigns: [],
  locations: [],
  daysWithGameSession: [],
  leaderboard: [],
};

const mapStateToProps = state => ({
  campaigns: state.adminCompany.campaigns,
  gameSessions: state.adminCompany.gameSessions,
  gameSessionStatuses: state.staticData.gameSessionStatuses,
  loading: state.adminCompany.loading,
  company: state.auth.company,
  kits: state.adminCompany.kits,
  gameDefs: state.adminCompany.gameDefs,
  locations: state.adminCompany.locations,
  daysWithGameSession: state.adminCompany.daysWithGameSession,
  role: state.auth.role,
  roles: state.staticData.roles,
  leaderboard: state.adminCompany.leaderboard,
});

export default connect(mapStateToProps, {
  getCampaigns: AdminCompanyEntity.actions.getCampaigns,
  getCampaign: AdminCompanyEntity.actions.getCampaign,
  getGameSessions: AdminCompanyEntity.actions.getGameSessions,
  getDaysWithGameSession: AdminCompanyEntity.actions.getDaysWithGameSession,
  removeKit: AdminCompanyEntity.actions.removeKit,
  getLocations: AdminCompanyEntity.actions.getLocations,
  getCompany: AdminCompanyEntity.actions.getCompany,
  getLeaderboard: AdminCompanyEntity.actions.getLeaderboard,
  getGameSession: AdminCompanyEntity.actions.getGameSession,
})(ParticipantView);
