/* Libs */
import styled, { css } from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const Action = styled.div`
  width: 140px;
  height: 39px;
  border-radius: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  text-align: center;
  cursor: pointer;
  line-height: 40px;
  ${({ type }) => type === 'close' && css`
    color: ${COLORS_CONFIG.WHITE};
    background-color: ${COLORS_CONFIG.SOFT_ORANGE};
  `}
  ${({ type }) => type === 'delete' && css`
    color: ${COLORS_CONFIG.WHITE};
    background-color: ${COLORS_CONFIG.ORANGE};
  `}
  ${({ type }) => type === 'continue' && css`
    color: ${COLORS_CONFIG.WHITE};
    background-color: ${COLORS_CONFIG.SKY_BLUE};
  `}
  ${({ type }) => type === 'reset' && css`
    color: ${COLORS_CONFIG.SKY_BLUE};
    border:2px solid ${COLORS_CONFIG.SKY_BLUE};
    line-height: 36px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  & ${Action}:not(:first-child) {
    margin-left: 12px;
  }
`;
