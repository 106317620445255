/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import { Modal, AddKitForm } from 'components';

/* Styles */

import * as Styled from './styles';

export default function AddKit({
  onClose,
  open,
  updateAllKits,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Add a kit"
      open={open}
    >
      <Styled.AddKitModal>
        <AddKitForm onClose={onClose} updateAllKits={updateAllKits} />
      </Styled.AddKitModal>
    </Modal>
  );
}

// Type of props
AddKit.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
AddKit.defaultProps = {};
