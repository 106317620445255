/* Libs */
import PropTypes from 'prop-types';
import React from 'react';

/* Constants */

import { STRUCT_IDS } from '_constants';

/* Components */

import {
  TableActionSidebar,
  ViewSessionParticipantsForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

export default function ViewSessionParticipantsSibedar({
  open,
  title,
  onClose,
  id,
  ...restProps
}) {
  if (!open) return null;
  return (
    <TableActionSidebar
      open={open}
      title={title}
      onClose={onClose}
      id={id}
    >
      <Styled.ViewSessionParticipantsSibedar>
        <ViewSessionParticipantsForm {...restProps} />
      </Styled.ViewSessionParticipantsSibedar>
    </TableActionSidebar>
  );
}

// Type of props
ViewSessionParticipantsSibedar.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

// Default value for props
ViewSessionParticipantsSibedar.defaultProps = {
  id: STRUCT_IDS.COMPANY_ADMIN_PAGE,
};
