import { COMMON_ERRORS } from '_constants/errors';
import {
  isEmpty,
  isLessThan,
  isMoreThan,
  isNull,
  isValidEmail,
  isCyrillic,
} from 'utils/validation';

export default ({
  value,
  name,
  required = true,
  initial = false,
  min = null,
  max = null,
}) => {
  let error = (isEmpty(value) && COMMON_ERRORS.isRequired(name))
    || (!isValidEmail(value) && COMMON_ERRORS.email)
    || (isCyrillic(value) && COMMON_ERRORS.email)
    || (!isNull(min)
          && isLessThan(value, min, true)
          && COMMON_ERRORS.lessThan(name, min))
    || (!isNull(max)
          && isMoreThan(value, max, true)
          && COMMON_ERRORS.moreThan(name, max));
  error = !required && isEmpty(value) ? null : error;
  error = error && initial ? null : error;
  return error;
};
