/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import { FONT_WEIGHTS } from '_constants';

/* Components */

import { Img } from 'components';

/* Assets */

import { CLOCK_WHITE, CLOCK_BLUE } from 'assets/icons/clocks';

/* Styles */

import * as Styled from './styles';

function HeaderSessionTimeCell({
  value,
  fontWeight,
  onToggle,
  checked,
}) {
  return (
    <Styled.Cell fontWeight={fontWeight}>
      {value}
      <Img
        src={checked ? CLOCK_WHITE : CLOCK_BLUE}
        onClick={onToggle}
        size={[17, 17]}
      />
    </Styled.Cell>
  );
}

/* HeaderSessionTimeCell type of props */

HeaderSessionTimeCell.propTypes = {
  value: PropTypes.node,
  fontWeight: PropTypes.oneOf([
    FONT_WEIGHTS.REGULAR,
    FONT_WEIGHTS.BOLD,
  ]),
  onToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

/* HeaderSessionTimeCell default props */

HeaderSessionTimeCell.defaultProps = {
  value: '',
  fontWeight: FONT_WEIGHTS.BOLD,
};

export default HeaderSessionTimeCell;
