export const ADD_COMPANY = '@PROJECT_NAME/ADD_COMPANY';
export const ADD_COMPANY_SUCCESS = '@PROJECT_NAME/ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAIL = '@PROJECT_NAME/ADD_COMPANY_FAIL';

export const GET_ALL_COMPANIES = '@PROJECT_NAME/GET_ALL_COMPANIES';
export const GET_ALL_COMPANIES_SUCCESS = '@PROJECT_NAME/GET_ALL_COMPANIES_SUCCESS';
export const GET_ALL_COMPANIES_FAIL = '@PROJECT_NAME/GET_ALL_COMPANIES_FAIL';

export const ACTIVATE_COMPANY = '@PROJECT_NAME/ACTIVATE_COMPANY';
export const ACTIVATE_COMPANY_SUCCESS = '@PROJECT_NAME/ACTIVATE_COMPANY_SUCCESS';
export const ACTIVATE_COMPANY_FAIL = '@PROJECT_NAME/ACTIVATE_COMPANY_FAIL';
