/* Libs */
import styled, { css } from 'styled-components';

import {
  COLORS_CONFIG,
} from 'config';

export const Logo = styled.div`
  width: 205px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  
`;

export const Right = styled.div`
  margin-left: auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  min-height: 80px;
  position: relative;
  background-color: ${COLORS_CONFIG.WHITE};
  display: flex;
  width: 100%;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  z-index: 2;
  
  ${({ isDarkMode }) => isDarkMode && css`
      background-color: ${COLORS_CONFIG.NAVY_BLUE};
  `}
`;
