import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

export const Title = styled.div`
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    font-size: 17px;
  }
`;

export const Action = styled.div`
  font-size: 12px;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
`;

export const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  & > *:not(:first-child) {
    position: relative;
    padding: 0 16px;
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 18px;
      background-color: ${COLORS_CONFIG.SILVER};
    }
  }
  & > a {
    font-size: 12px;
    font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
    color: ${COLORS_CONFIG.SKY_BLUE};
    cursor: pointer;
  }
  & > *:first-child {
    padding-right: 16px;
    @media (max-width: ${BREAKPOINTS.TABLET}) {
      padding-right: 5px;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  & ${Title} {
    flex-grow: 1;
  }
`;
