import queryString from 'query-string';

export default ({
  dateFrom,
  dateTo,
  locations,
  ...rest
}) => queryString.stringify({
  from_date: dateFrom,
  to_date: dateTo,
  locations,
  ...rest,
});
