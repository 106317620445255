/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */
import { copyToClipboard } from 'utils/custom';
import { notification } from 'utils/services';


/* Constants */
import { NOTIFICATIONS } from '_constants';


/* Styles */
import * as Styled from './styles';


export default function ShareLink({
  link,
  buttonText,
  onClick,
}) {
  const copySessionLink = () => {
    copyToClipboard(link);
    notification.success(NOTIFICATIONS.SUCCESS_COPIED);
    onClick();
  };

  return (
    <Styled.Wrapper>
      <Styled.Link tabIndex="0">
        {link || 'No link'}
      </Styled.Link>
      <Styled.Button disabled={!link} onClick={copySessionLink}>{buttonText}</Styled.Button>
    </Styled.Wrapper>
  );
}

// Type of props
ShareLink.propTypes = {
  link: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

// Default value for props
ShareLink.defaultProps = {
  link: null,
  buttonText: 'Copy',
  onClick: () => { },
};
