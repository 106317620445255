/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const UpperLine = styled.div`
  padding-bottom: 6px;
  font-size: 16px;
  line-height: 16px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const DownLine = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
`;
