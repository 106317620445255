/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

// import { Modal, AddGameDefinitionForm } from 'components';

/* Styles */

import * as Styled from './styles';

// TODO: propbably remove it later
export default function AddGameDefinition({
  onClose,
  open,
}) {
  return null;
  // if (!open) return null;
  // return (
  //   <Modal
  //     onlyContentCloseClick
  //     onClose={onClose}
  //     title="Add a game definition"
  //     open={open}
  //   >
  //     <Styled.AddGameDefinitionModal>
  //       <AddGameDefinitionForm onClose={onClose}/>
  //     </Styled.AddGameDefinitionModal>
  //   </Modal>
  // );
}

// Type of props
AddGameDefinition.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
AddGameDefinition.defaultProps = {};
