/* Libs */
import React, { useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Async from 'react-select/async';
import Creatable from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import cn from 'classnames';
import { isUndefined, isNull } from 'lodash';

/* Utils */
import { isEmpty } from 'utils/validation';

/* Components */

import { Loader } from 'components';

/* Styles */

import * as Styled from './styles';

function Select({
  title,
  placeholder,
  value,
  className,
  onChange,
  onBlur,
  name,
  size,
  options,
  defaultOptions,
  searchable,
  async,
  creatable,
  fromLocation,
  isMulti,
  isDisabled,
  forwardedRef,
  isOptionDisabled,
  withoutReset,
  error,
  optional,
  type,
  relativeMenu,
  ...rest
}) {
  const [localOptions, setLocalOptions] = useState([]);
  const [search, setSearch] = useState('');

  const resetOptionGroup = {
    options: [
      {
        value: null,
        label: '---- RESET ----',
      },
    ],
  };

  const handleInputChange = (item) => {
    if (type === 'number' && /[^\d]/g.test(item)) return;
    setSearch(item);
  };

  const handleChange = (item) => {
    if (async && !isMulti) {
      const isItemInOptions = defaultOptions.some(({ value }) => value === item.value);

      setLocalOptions(
        isItemInOptions || isNull(item.value)
          ? defaultOptions
          : [
            item,
            ...defaultOptions,
          ],
      );
    }

    onChange({
      target: {
        name,
        value: isMulti
          ? item && item.map(({ value: itemValue }) => itemValue)
          : item.value,
      },
    });
  };

  const handleBlur = () => {
    if (!onBlur) return;
    setLocalOptions(defaultOptions);
    onBlur({
      target: {
        name,
        value,
      },
    });
  };


  const calculateValue = () => {
    if (isUndefined(value) || isNull(value) || isEmpty(value)) return null;

    return isMulti
      ? options.filter(option => value.includes(option.value))
      : (
        (async && localOptions.length)
          ? localOptions
          : options
      ).find(option => option.value === value || (option.founder_id && option.founder_id === value))
      || { value, label: value };
  };

  const newDefaultOptions = localOptions.length ? localOptions : defaultOptions;

  const propsSet = {
    placeholder,
    value: calculateValue(),
    className: cn('ReactSelect', className, size),
    classNamePrefix: 'ReactSelect',
    onChange: handleChange,
    onBlur: handleBlur,
    options: (withoutReset || isNull(value) || isMulti) ? options : [resetOptionGroup, ...options],
    defaultOptions: (withoutReset || isNull(value) || isMulti)
      ? newDefaultOptions
      : [resetOptionGroup, ...newDefaultOptions],
    isSearchable: searchable,
    inputValue: search,
    onInputChange: handleInputChange,
    isMulti,
    isDisabled,
    ref: forwardedRef,
    isOptionDisabled,
    ...rest,
  };

  const hasError = useMemo(() => {
    if (!value && isDisabled) return null;
    return !isDisabled && error;
  }, [value, isDisabled, error]);

  return (
    <Styled.Wrapper
      hasError={hasError}
      relativeMenu={relativeMenu}
      disabled={isDisabled}
    >
      {optional && <Styled.Optional>(optional)</Styled.Optional>}
      {!isEmpty(title) && !isNull(value) && <Styled.Title>{title}</Styled.Title>}
      {
        async
          ? creatable
            ? <AsyncCreatableSelect {...propsSet} components={{ LoadingIndicator: () => <Loader /> }} />
            : <Async {...propsSet} components={{ LoadingIndicator: () => <Loader /> }} />
          : creatable
            ? <Creatable {...propsSet} />
            : <ReactSelect {...propsSet} />
      }
      <Styled.Error>
        {(!isDisabled && error) && error}
      </Styled.Error>
    </Styled.Wrapper>
  );
}

/* Select type of props */

Select.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
  ]),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  searchable: PropTypes.bool,
  async: PropTypes.bool,
  creatable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  withoutReset: PropTypes.bool,
  relativeMenu: PropTypes.bool,
};

/* Select default props */

Select.defaultProps = {
  autoComplete: 'new-password',
  options: [],
  title: '',
  placeholder: 'Select...',
  value: null,
  className: null,
  size: null,
  defaultOptions: [],
  searchable: false,
  async: false,
  creatable: false,
  isMulti: false,
  isDisabled: false,
  isOptionDisabled: null,
  withoutReset: false,
  relativeMenu: false,
  onBlur: null,
};

export default memo(Select);
