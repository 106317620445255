/* Libs */
import styled, { css } from 'styled-components';


export const CreateCampaignModal = styled.div`
  ${({ succesModal }) => succesModal && css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `
}
`;
