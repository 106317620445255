/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import {
  Input,
  LaunchHeader,
  Button,
} from 'components';

/* Constants */
import { COLORS_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

function TeamName({
  back,
  launch,
  loading,
  location,
  utc_start_time,
  isFastSession,
  isDarkMode,
  onChange,
  onBlur,
  data,
}) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <LaunchHeader
        title="Name your team!"
        subtitle={!isFastSession}
        location={location}
        utcStartTime={utc_start_time}
      />
      <Input
        name="team_name"
        value={data.team_name}
        error={data.error}
        onChange={onChange}
        onBlur={onBlur}
        placeholder="Name of the team"
      />
      <Styled.Actions>
        <Button
          onClick={launch}
          isLoading={loading}
          loadingColor={COLORS_CONFIG.SKY_BLUE}
          disabled={!data.canSubmit || loading}
        >
          Launch
        </Button>
        <Button onClick={back}>
          Back
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* TeamName type of props */
TeamName.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.shape({
      location: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.number,
  ]),
  utc_start_time: PropTypes.string,
  launch: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isFastSession: PropTypes.bool,
};

/* TeamName default props */
TeamName.defaultProps = {
  utc_start_time: null,
  isFastSession: false,
};

export default TeamName;
