/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { notification } from 'utils/services';

/* Components */

import { Img } from 'components';

/* Constants */

import { NOTIFICATIONS_CONFIG, GAME_CONFIG } from 'config';
import { NOTIFICATIONS } from '_constants';

/* Styles */

import { MINUS_CIRCLE_RED } from 'assets/icons';
import * as Styled from './styles';

function ManageGameParticipantListItem({
  email,
  mode,
  id,
  deleteParticipant,
  disabled,
}) {
  const confirmDelete = () => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
    {
      onSuccess: async () => {
        if (disabled) return;
        await deleteParticipant(id);
        notification.success(NOTIFICATIONS.SUCCESS_DELETED_PARTICIPANT_EMAIL);
      },
      onCancel: notification.removeAllNotifications,
      message: `Are you sure that you wanna delete ${email}`,
    },
  ));
  return (
    <Styled.Wrapper>
      <Styled.Email>
        {email}
      </Styled.Email>
      {
        mode === GAME_CONFIG.MANAGER.MODE.EDIT
        && (
          <Styled.Action>
            <Img
              onClick={confirmDelete}
              src={MINUS_CIRCLE_RED}
              size={[20, 20]}
              disabled={disabled}
              grayscaleOnDisabled
            />
          </Styled.Action>
        )
      }
    </Styled.Wrapper>
  );
}

/* ManageGameParticipantListItem type of props */

ManageGameParticipantListItem.propTypes = {
  email: PropTypes.string.isRequired,
  deleteParticipant: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  mode: PropTypes.oneOf(Object.values(GAME_CONFIG.MANAGER.MODE)).isRequired,
  disabled: PropTypes.bool,
};

ManageGameParticipantListItem.defaultProps = {
  disabled: false,
};


export default ManageGameParticipantListItem;
