/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  font-family: ${props => FONTS_CONFIG[`MAIN_TEXT_FONT_${props.fontWeight}`]};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Switch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 3px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  border-radius: 50%;
  background-color: ${({ checked }) => (checked ? COLORS_CONFIG.SKY_BLUE : COLORS_CONFIG.WHITE)};
`;

export const Img = styled.img`
  object-fit: contain;
`;

export default Cell;
