/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

/* Styles */

import {
  Wrapper as StatisticsWrapper,
} from 'components/blocks/Sections/Report/Statistics/styles';
import {
  Wrapper as FilterWrapper,
} from 'components/blocks/Sections/Report/Filter/styles';
import {
  Wrapper as ActivityWrapper,
} from 'components/blocks/Sections/Report/Activity/styles';
import { Triangle } from 'components/blocks/Markers/ForwardTriangle/styles';
import { LinkTo } from 'components/ui/LinkTo/styles';


export const Stats = styled.div`
  width: 67%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: calc(${BREAKPOINTS.LAPTOP} - 1px)) {
    width: 60%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 32px;

  & ${FilterWrapper} {
    max-width: 31%;
    @media (max-width: calc(${BREAKPOINTS.LAPTOP} - 1px)) {
      max-width: 35%;
    }
  }
  & ${ActivityWrapper} {
    margin-bottom: 17px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 30px;
  line-height: 40px;
`;

export const ExportButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  height: 48px;
  border-radius: 24px;
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.WHITE};
  font-size: 16px;
  line-height: 19px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 17px;
`;

export const Reports = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 500px;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 4px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
  & ${LinkTo} {
    & a {
      display: flex;
      align-items: baseline;
      cursor: pointer;
      justify-content: flex-end;
      & span {
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 12px;
        line-height: 14px;
      }

      & ${Triangle} {
        margin-left: 5px;
      }
    }
  }
`;
