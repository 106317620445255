/* Colors config */
export default {
  TRANSPARENT: 'transparent',

  /* Black colors group */
  BLACK$50: 'rgba(0, 0, 0, 0.5)',
  BLACK$70: 'rgba(0, 0, 0, 0.7)',
  BLACK_1: 'rgba(17, 20, 26, 0.7)',
  BLACK: '#000000',

  /* White colors group */
  WHITE$72: 'rgba(255, 255, 255, 0.72)',
  WHITE$30: 'rgba(255, 255, 255, 0.30)',
  WHITE: '#FFFFFF',
  WHITE_CUSTOM_1: '#fcf9f9',
  WHITE_CUSTOM_2: '#f0f5f9',

  /* Grey colors group */
  GREY_CUSTOM_1: 'rgba(255, 255, 255, 0.15)',
  GREY_CUSTOM_2: 'rgba(34, 39, 52, 0.75)',
  GREY_CUSTOM_3: 'rgba(34, 39, 52, 0.98)',
  GREY_CUSTOM_4: 'rgba(34, 39, 52, 0.05)',
  GREY_CUSTOM_5: '#acacac',
  GREY_CUSTOM_6: '#797979',
  GREY_CUSTOM_7: '#F8F8F8',
  DARK_GREY: '#212126',
  SILVER: '#eaeaea',
  SILVER_LIGHT: '#d8d8d8',
  SILVER_CUSTOM_1: '#bbbbbb',
  SILVER_CUSTOM_2: '#e9e9e9',
  SILVER_CUSTOM_3: '#FBFBFB',
  SILVER_CUSTOM_4: '#CCCCCC',
  SILVER_CUSTOM_5: '#C4C4C4',
  SILVER_CUSTOM_6: '#CACACA',
  SILVER_CUSTOM_7: '#949494',
  SILVER_CUSTOM_8: '#D0D0D0',

  /* Blue colors group */
  DARK_BLUE: '#081F35',
  DARK_BLUE$72: 'rgba(8,31,53, 0.72)',
  NAVY_BLUE: '#051829',
  NAVY_BLUE$70: 'rgba(18, 20, 32, 0.7);',
  OXFORD_BLUE: '#303E57',
  BLUE_NEON: '#3034ED',
  DEEP_SKY_BLUE: '#00ACED',
  LIGHT_AQUA: '#349ef3',
  SKY_BLUE: '#4eb3d4', // 6DBBDA
  SOFT_BLUE: '#37ACEE',
  SKY_BLUE$50: 'rgba(78, 179, 212, 0.5)',
  LIGHT_SEA_GREEN: 'rgb(36,151,193)',
  GREY_BLUE: 'rgb(214, 229, 241)',
  LIGHT_BLUE_GRADIENT: 'linear-gradient(128.56deg, #19A1ED 0%, #5FBBEF 115.44%), #212126',
  LIGHT_BLUE_GRADIENT_1: 'linear-gradient(135.68deg, #19A1ED 0%, #735FEF 115.44%), #212126',
  LIGHT_BLUE_GRADIENT_2: 'linear-gradient(136.85deg, #4EB3D4 -1.66%, #4E94D4 102.04%)',
  LIGHT_BLUE_GRADIENT_3: 'linear-gradient(96.38deg, #4CAED1 -5.39%, rgba(76, 129, 209, 0.55) 110.73%);',


  /* Brown colors group */
  SMOOTH_BROWN: '#373636',
  SMOOTH_BROWN$70: 'rgba(55, 54, 54, 0.7)',
  DARK_SMOOTH_BROWN: '#2c2b2b',
  DARK_SMOOTH_LIGHT_BROWN: '#404040',
  SMOOTH_LIGHT_BROWN: '#979797',

  /* Red colors group */
  RED_DARK: '#ff2400',
  RED_AMARANTH: '#FA254C',
  ORANGE: '#F16153',
  RED_GRADIENT: 'linear-gradient(94.6deg, #F16153 0%, #D63324 105.72%)',
  SOFT_ORANGE: '#F2BA49',

  /* Green colors group */
  GREEN_EMERALD: '#30ED79',
  GREEN_CUSTOM_1: '#77CB85',
  LIGHT_GREEN: '#4BD059',
  SHADOWS: {
    BLACK$10: '0 0 14px 0 rgba(0, 0, 0, 0.1)',
    BLACK$10_10: '0 10px 14px 0 rgba(0, 0, 0, 0.1)',
    BLACK$10_20: '0 0 14px 0 rgba(0, 0, 0, 0.1)',
    BLACK$30: '0 0 10px 0 rgba(0,0,0,0.3)',
    TOOLTIP_SHADOW: '0px 15px 25px rgba(0, 0, 0, 0.31)',
    CONFIRM_SHADOW: '0px 6px 11px rgba(0, 0, 0, 0.1)',
    NOTIFY_SHADOW: '0px 4px 7px rgba(0, 0, 0, 0.14)',
    MIDDLE_SHADOW: '0 2px 16px 0 rgba(0, 0, 0, 0.5)',
    BACKDROP_BROWN: 'rgba(55, 54, 54, 0.6)',
    BOX_SHADOW: 'box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.1)',
  },
};
