/* Libs */
import React from 'react';
import PropTypes from 'prop-types';


/* Components */

import { BackTriangle } from 'components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function ConfirmAction({
  isOpen,
  onConfirm,
  onReject,
}) {
  if (!isOpen) return null;
  return (
    <Styled.Wrapper>
      <Styled.Content>
        <BackTriangle color={COLORS_CONFIG.WHITE} />
        <Styled.Message>
          Are you sure?
        </Styled.Message>
        <Styled.Actions>
          <Styled.Action isConfirm onClick={onConfirm}>
            Yes
          </Styled.Action>
          <Styled.Action onClick={onReject}>
            No
          </Styled.Action>
        </Styled.Actions>
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* ConfirmAction type of props */

ConfirmAction.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ConfirmAction;
