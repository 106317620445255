/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  CircleFilled,
  Img,
} from 'components';

/* Assets */

import { CHECK_MARK } from 'assets/icons';

/* Styles */

import * as Styled from './styles';

const Checkbox = memo(({
  value,
  name,
  label,
  onChange,
  disabled,
  type,
}) => (
  <Styled.Wrapper disabled={disabled}>
    <Styled.HtmlLabel>
      <Styled.InputWrapper>
        <Styled.Input
          name={name}
          type="checkbox"
          checked={value}
          onChange={({ target: { checked } }) => onChange({ target: { name, value: checked } })}
          disabled={disabled}
        />
        <Styled.Shape
          type={type}
          checked={value}
          disabled={disabled}
        >
          { type === 'circle'
            ? <CircleFilled active={!disabled} />
            : <Img size={[15, 15]} src={CHECK_MARK} disabled={disabled} />
          }
        </Styled.Shape>
      </Styled.InputWrapper>
      <Styled.Label>{label}</Styled.Label>
    </Styled.HtmlLabel>
  </Styled.Wrapper>
));

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['squre', 'circle']),
};

Checkbox.defaultProps = {
  disabled: false,
  type: 'squre',
  label: '',
};

export default Checkbox;
