/* Libs */
import styled from 'styled-components';

import { Header } from 'components/blocks/ui/CardsHeader/styles';
import { Table } from 'components/blocks/Table/styles';

export const Wrapper = styled.div`
  & ${Header} {
    padding-bottom: 10px;
  }
  & ${Table} {
    & .rt-table {
      min-height: 0px;
      & .rt-tbody {
        .rt-tr {
          padding: 0px!important;
        }
        .rt-tr-group {
          min-height: 0px;
        }
      }
    }
  }
`;

export default Wrapper;
