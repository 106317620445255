/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Button } from 'components/ui/Button/styles';
import {
  Wrapper as PreviewWrapper,
  Content as PreviewContent,
} from 'components/blocks/Cards/Game/Preview/styles';


export const Context = styled.div`
  font-size: 18px;
  margin: 20px 0;
  word-break: break-word;
`;

export const Title = styled.div`
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.DARK_GREY};
  padding-top: 40px;
  padding-bottom: 32px;
  word-break: break-word;
`;

export const Text = styled.div`
  font-size: 13px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  margin-left: 10px;
  word-break: break-word;
`;

export const Hint = styled.div`
  display: flex;
  background: ${COLORS_CONFIG.SILVER};
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  padding: 14px 48px 30px;
  margin-top: 18px;

  ${({ isDarkMode }) => isDarkMode && css`
    background: ${COLORS_CONFIG.DARK_BLUE};
    & ${Text}{
      color: ${COLORS_CONFIG.WHITE};
    }

    & svg {
      fill: ${COLORS_CONFIG.WHITE};
    }
  `}
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContextWrapper = styled.div`
  padding: 0 48px 0 48px;
  width: 660px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 270px;
  max-height: calc(100vh - 130px);
  & ${Button} {
    align-self: center;
    margin-bottom: 30px;
  }

  ${({ isDarkMode }) => isDarkMode && css`
     & ${Title}, ${Text}, ${ContextWrapper}, ${Context}{
      color: ${COLORS_CONFIG.WHITE};
     }
     
     & ${Button} {
        background-color: ${COLORS_CONFIG.WHITE};
        color: ${COLORS_CONFIG.NAVY_BLUE};
      }
  `}
`;

export const QuestionModal = styled.div`
  padding: 0px 48px 0px 48px;
  width: 660px;
  ${({ hasIncorrectAnswers }) => !hasIncorrectAnswers && css`
    padding-bottom: 56px;
  `}

  ${({ isDarkMode }) => isDarkMode && css`
     & ${Title}, ${Text}, ${ContextWrapper}, ${Context}{
      color: ${COLORS_CONFIG.WHITE};
     }
  `}
`;

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 0 48px 20px;
  height: 100%;
  min-height: 350px;

  ${PreviewWrapper},
  ${PreviewContent},
  .vimeo,
  .vimeo-embed {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .vimeo {

    &-embed {
      display: flex !important;

      iframe {
        flex-grow: 1;
        width: 100%;
        height: 100%;
      }
    }
  }

  ${Button} {
    margin: 20px auto 0;
  }
  
   ${({ isDarkMode }) => isDarkMode && css`
     ${Button} {
         margin: 20px auto 0;
         background-color: ${COLORS_CONFIG.WHITE};
         color: ${COLORS_CONFIG.NAVY_BLUE};
     }
  `}
`;
