/* Libs */
import styled from 'styled-components';

/* Constants */
import { BREAKPOINTS } from '_constants';


export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  grid-auto-rows: 1fr;
  @media (max-width: calc(${BREAKPOINTS.LAPTOP} - 1px)) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
