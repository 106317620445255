/* Libs */
import styled, { css } from 'styled-components';

import { Input, Wrapper as InputWrapper, Error } from 'components/ui/Input/styles';
import {
  Button,
} from 'components/ui/Button/styles';

import { COLORS_CONFIG } from 'config';
import { Circle, Sign } from 'components/blocks/Markers/CircleWithSign/styles';

export const AddNew = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 36px;
  padding-right: 16px;
  & ${InputWrapper} {
    min-height: initial;
    width: 85%;
    & ${Input} {
      padding: 8px 0px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid ${COLORS_CONFIG.WHITE};
      line-height: 16px;
      color: ${COLORS_CONFIG.WHITE};
      &::placeholder {
        color: ${COLORS_CONFIG.WHITE};
      }
    }
  }
  
  & ${Circle}{
    ${({ disabled }) => disabled && css`
      border-color: ${COLORS_CONFIG.SILVER_CUSTOM_8};
      cursor: default;
    `}
    & ${Sign} {
      ${({ disabled }) => disabled && css`
        color: ${COLORS_CONFIG.SILVER_CUSTOM_8};
      `}
    }
  }
  
`;
export const Actions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color:${COLORS_CONFIG.SKY_BLUE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 34px 0 22px;
  width: 100%;
  height: 153px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10_20};
  & ${Button} {
    width: 160px;
    min-width: 160px;
    background-color: ${COLORS_CONFIG.WHITE};
    color: ${COLORS_CONFIG.SKY_BLUE};
    &:last-child {
      margin-top: 30px;
      padding: 0;
      height: initial;
      background-color: ${COLORS_CONFIG.TRANSPARENT};
      color: ${COLORS_CONFIG.WHITE};
    }
  }
`;

export const Wrapper = styled.div`
  padding-bottom: 153px;
  
  & ${Error}{
   color: ${COLORS_CONFIG.RED_DARK};
  }
  
  ${({ isDarkMode }) => isDarkMode
  && css`
        
         & ${Actions} {
           background: ${COLORS_CONFIG.SOFT_BLUE};
           box-shadow: ${COLORS_CONFIG.SHADOWS.BOX_SHADOW};
           
           & ${Button} {
               background-color: ${COLORS_CONFIG.WHITE};
               color: ${COLORS_CONFIG.SOFT_BLUE};
               &:last-child {
                 margin-top: 30px;
                 padding: 0;
                 height: initial;
                 background-color: ${COLORS_CONFIG.TRANSPARENT};
                 color: ${COLORS_CONFIG.WHITE};
               }
            }
         }
   `}
`;
