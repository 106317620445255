/* Libs */
import styled from 'styled-components';

/* Constants */
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

/* Styles */

import {
  Select,
  Wrapper as SelectWrapper,
  Title as SelectTitle,
} from 'components/ui/Select/styles';
import {
  Wrapper as DatePickerWrapper,
} from 'components/ui/DatePicker/styles';
import {
  Input as DatePickerInput,
} from 'components/ui/MaskInput/styles';
import { Input, Title as InputTitle } from 'components/ui/Input/styles';
import { CustomScroll, TextEllipsis } from 'assets/styles/mixins';

const getBGColor = ({ active, filled, disabled }) => {
  if (disabled) return COLORS_CONFIG.SILVER_CUSTOM_1;

  if (active) {
    return filled
      ? COLORS_CONFIG.SKY_BLUE
      : COLORS_CONFIG.ORANGE;
  }

  return COLORS_CONFIG.TRANSPARENT;
};

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 866px;
  display: flex;
  flex-direction: column;

  & ${SelectWrapper} {
    & ${Select} {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  min-height: 48px;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 27px;
`;

export const RowWithTwo = styled.div`
  width: 100%;
  display: flex;
  min-height: 48px;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 27px;
`;

export const Data = styled.div`
  flex: 1 1 auto;
  padding: 20px 56px 0px 40px;
  /* height: calc(100% - 200px); */
  height: 0;
  /* overflow-y: scroll; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}

  & ${DatePickerInput} {
    height: 48px;
    padding-left: 11px;
  }

  & > ${Row}:first-child {
    margin-bottom: 5px;
  }
  
  & ${InputTitle} {
    left: 5px;
  }

  & ${Input} {
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    height: 48px;
    padding-left: 11px;
    border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
    &:hover,
    &:focus {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
    &::placeholder {
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
    }
  }
`;

export const Column = styled.div`
  width: 336px;
  height: 100%;
  display: flex;
  flex-direction: column;
  & ${SelectWrapper} {
    & .ReactSelect {
      height: 48px;
      min-height: 48px;

      &__control {
        border-radius: 0;
      }
      &__value-container {
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  ${SelectTitle} {
    left: 5px;
  }
  & ${DatePickerWrapper} {
    height: 48px;
  }

  &:last-child {
    width: 370px;

    & ${DatePickerWrapper}:first-child {
      width: 176px;
    }
    & ${DatePickerWrapper}:last-child {
      width: 182px;
    }
    & ${RowWithTwo} {
      & ${SelectWrapper}:first-child {
        width: 176px;
        height: 48px;
      }
      & ${SelectWrapper}:last-child {
        width: 182px;
        height: 48px;
      }
    }
  }
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 8px;
`;

export const CampaignName = styled.div`
  font-size: 18px;
  line-height: 18px;
  width: 100%;   
  ${TextEllipsis}
`;

export const Campaign = styled.div`
  padding: 32px 56px 13px 40px;
  & ${Label},
  & ${CampaignName} {
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  }
`;

export const Locations = styled.div``;

export const WeekDay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: none;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 12px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${({ active }) => (active ? COLORS_CONFIG.WHITE : COLORS_CONFIG.SMOOTH_BROWN)};
  background-color: ${props => getBGColor(props)};

  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)')};
  }
`;

export const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 32px 32px;
  width: 100%;
  /* height: 88px; */
  /* min-height: 80px; */
  /* min-height: 120px; */
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10_20};
  z-index: 11;
`;

export const ExtraOptions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #ECECEC;
`;

export const ExtraOptionLabel = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-size: 14px;
  line-height: 17px;
  margin-right: 5px;
`;
