/* Libs */

import {
  Router, Switch, Redirect, Route,
} from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { history } from 'lib/ConfigureStore';
import { store } from 'lib';
import ReactDOM from 'react-dom';

/* Base App styles */
import 'assets/styles/reset.scss';
import 'assets/styles/fonts.scss';
import 'assets/styles/base.scss';
import 'assets/styles/react-calendar.scss';
import 'assets/styles/react-week-calendar.scss';
import '!style-loader!css-loader!font-awesome/css/font-awesome.min.css';

/* Hooks */
import { useHandleTimezoneChangeHook } from 'hooks';

/* Utils */
import { bootstrapService } from 'utils/services';

/* Components */
import {
  NotificationSystem,
  ContainerLoader,
  VersionControl,
} from 'components';

// App RBA
import AuthRoute from 'containers/Auth/AuthRoute';
import HostRoute from 'containers/Host/HostRoute';
import AdminRoute from 'containers/Admin/AdminRoute';
import CompanyAdminRoute from 'containers/CompanyAdmin/CompanyAdminRoute';

// App main routes
import AuthPage from 'containers/Auth';
import GamePage from 'containers/Game';
import HostPage from 'containers/Host';
import AdminPage from 'containers/Admin/index.js';
import PublicPages from 'containers/Public';
import CompanyAdminPage from 'containers/CompanyAdmin';
import ParticipantSignup from 'containers/Auth/ParticipantSignup';
import Impersonate from 'containers/CompanyAdmin/Impersonate';
// import LandingPage from 'containers/Landing'; /* UNCOMMENT FOR LANDING */

/* Constants */

import { URLS_CONFIG } from 'config';

// ----------------
function Main({ appCanStart, staticDataLoaded }) {
  useEffect(() => {
    bootstrapService();
  }, []);
  useHandleTimezoneChangeHook();
  if (!appCanStart || !staticDataLoaded) return <ContainerLoader />;
  return (
    <>
      <NotificationSystem />
      <VersionControl />
      <Router history={history}>
        <Switch>
          {/* <Route exact path={URLS_CONFIG.landing} component={LandingPage} /> /* UNCOMMENT FOR LANDING */}
          {PublicPages.map(route => (
            <Route path={route.path} component={route} key={route.path} />
          ))}
          <AuthRoute
            path={URLS_CONFIG.participant.signup}
            component={ParticipantSignup}
          />
          <AuthRoute path={URLS_CONFIG.auth.default} component={AuthPage} />
          <AdminRoute path={URLS_CONFIG.admin.default} component={AdminPage} />
          <Route path={URLS_CONFIG.game.default} component={GamePage} />

          <Route
            path={URLS_CONFIG.companyAdmin.impersonate}
            component={Impersonate}
          />
          <CompanyAdminRoute
            path={URLS_CONFIG.companyAdmin.default}
            component={CompanyAdminPage}
          />

          <HostRoute path={URLS_CONFIG.host.default} component={HostPage} />

          <Redirect to={URLS_CONFIG.auth.default} />
        </Switch>
      </Router>
    </>
  );
}

Main.propTypes = {
  staticDataLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ staticData, auth }) => ({
  staticDataLoaded: staticData.loaded,
  appCanStart: auth.appCanStart,
});

const MainRouter = connect(mapStateToProps, null)(Main);

function App() {
  return (
    <div>
      <Provider store={store}>
        <MainRouter />
      </Provider>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
