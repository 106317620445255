/* Libs */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/* Components */

import { CompanyAdminStruct } from 'components';

/* Constants */

import { URLS_CONFIG } from 'config';

/* Reports subroutes */
const REPORTS_SUB_ROUTES = [
  require('./Main').default,
  require('./CompanyParticipants').default,
  require('./CampaignParticipants').default,
  require('./Campaign').default,
];

const PAGE_TITLE = 'Reports';

function Reports() {

  return (
    <CompanyAdminStruct>
      <Switch>
        <Route
          path={URLS_CONFIG.companyAdmin.reports.companyParticipantView}
          component={require('./ParticipantView').default}
          key={URLS_CONFIG.companyAdmin.reports.companyParticipantView}
        />
        <Route
          path={URLS_CONFIG.companyAdmin.reports.campaignParticipantView}
          component={require('./ParticipantView').default}
          key={URLS_CONFIG.companyAdmin.reports.campaignParticipantView}
        />
        {REPORTS_SUB_ROUTES.map(route => (
          <Route path={route.path} exact component={route} key={route.path} />
        ))}
        <Redirect to={URLS_CONFIG.companyAdmin.reports.main} />
      </Switch>
    </CompanyAdminStruct>
  );
}

/* Page Url */

Reports.path = URLS_CONFIG.companyAdmin.reports.default;

/* Page Title */

Reports.title = PAGE_TITLE;

export default Reports;
