/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG } from 'config';
import { default as Triangle } from 'components/blocks/Markers/BackTriangle/styles';

export const ContentWrapper = styled.div`
  position: absolute;
  z-index: 3;
  left: 30px;
  top: -7px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  ${Triangle} {
    position: absolute;
    top: 5px;
    left: -7px;
    border-width: 8px 12px 8px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  min-width: 200px;
  max-width: 300px;
  max-height: 300px;
  z-index: 1;
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 3px;
  padding: 15px;
`;

export const Wrapper = styled.div`
  width: 20px;
  height: 15px;
  position: relative;
  padding-left: 5px;
`;

export const Img = styled.img`
  width: 15px;
  height: 15px;
  object-fit: contain;
  cursor: pointer;
`;

export const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  text-align: left;
`;
