import { GAME_CONFIG } from 'config';
import { store } from 'lib';
import { getStaticData } from 'utils/custom';
import * as TYPES from './Game.types';

export const getGameSession = id => ({
  types: [TYPES.GET_GAME_SESSION, TYPES.GET_GAME_SESSION_SUCCESS, TYPES.GET_GAME_SESSION_FAIL],
  promise: client => client.get(`/game-sessions/${id}/`),
});

export const getGameInfo = token => ({
  types: [TYPES.GET_GAME_INFO, TYPES.GET_GAME_INFO_SUCCESS, TYPES.GET_GAME_INFO_FAIL],
  promise: client => client.post('/game-sessions/get-current-game-data/', { token }),
  payload: { token },
});

export const setActualParticipants = data => ({
  types: [TYPES.SET_ACTUAL_PARTICIPANTS, TYPES.SET_ACTUAL_PARTICIPANTS_SUCCESS, TYPES.SET_ACTUAL_PARTICIPANTS_FAIL],
  promise: client => client.post('/game-sessions/set-actual-participants/', data),
});

export const launchGame = data => ({
  types: [TYPES.LAUNCH_GAME, TYPES.LAUNCH_GAME_SUCCESS, TYPES.LAUNCH_GAME_FAIL],
  promise: client => client.post('/game-sessions/result/', data),
});

export const updateGameTime = currentTime => ({
  types: [TYPES.UPDATE_GAME_TIME, TYPES.UPDATE_GAME_TIME_SUCCESS, TYPES.UPDATE_GAME_TIME_FAIL],
  promise: (client, getState) => client.put(`/game-sessions/result/${getState().game.gameSessionId}/`, {
    current_time: currentTime.format('HH:mm:ss.SSS'),
    token: getState().game.token,
  }),
});

export const launchFastGame = data => ({
  types: [TYPES.LAUNCH_FAST_GAME, TYPES.LAUNCH_FAST_GAME_SUCCESS, TYPES.LAUNCH_FAST_GAME_FAIL],
  promise: client => client.post('/game-sessions/fast/', data),
});

export const setCompletedTime = ({ timeSpent, currentTime }) => {
  const { gameSessionId, token, step } = store.getState().game;
  const isFinished = GAME_CONFIG.STEP.GAME_SUCCESS === step;

  return {
    types: [
      TYPES.SET_COMPLETED_TIME,
      TYPES.SET_COMPLETED_TIME_SUCCESS,
      TYPES.SET_COMPLETED_TIME_FAIL,
    ],
    promise: client => client.put(`/game-sessions/result/${gameSessionId}/`, {
      time_completed: timeSpent,
      current_time: currentTime,
      token,
      is_finished: isFinished,
    }),
  };
};

// unused
export const finishGame = () => {
  const { token } = store.getState().game;
  return {
    types: [TYPES.FINISH_GAME, TYPES.FINISH_GAME_SUCCESS, TYPES.FINISH_GAME_FAIL],
    promise: client => client.post('/game-sessions/end-game/', { token }),
  };
};

export const setQuestionIsOpened = question => ({
  type: TYPES.SET_QUESTION_IS_OPEN,
  payload: { question },
});

export const changeStep = (step) => {
  const { token, gameSessionId } = store.getState().game;
  const BEStatus = getStaticData('gamePlayStatuses')[step];
  return {
    types: [TYPES.CHANGE_STEP, TYPES.CHANGE_STEP_SUCCESS, TYPES.CHANGE_STEP_FAIL],
    promise: client => client.put(`/game-sessions/result/${gameSessionId}/`, {
      token,
      status: BEStatus,
    }),
    payload: { step },
  };
};

export const gameFailed = () => changeStep(GAME_CONFIG.STEP.GAME_FAILED);

export const gameSuccess = () => changeStep(GAME_CONFIG.STEP.GAME_SUCCESS);

export const timerTick = newTime => ({
  type: TYPES.TIMER_TICK,
  payload: { newTime },
});

export const setExpireDate = expireDate => ({
  type: TYPES.SET_EXPIRE_DATE,
  payload: { expireDate },
});

export const handleAnswerClick = ({ question, answer, isCorrect }) => {
  const { gameSessionId, gameDefId, token } = store.getState().game;
  return {
    types: [
      TYPES.HANDLE_ANSWER_CLICK,
      TYPES.HANDLE_ANSWER_CLICK_SUCCESS,
      TYPES.HANDLE_ANSWER_CLICK_FAIL,
    ],
    promise: client => client.post('/game-sessions/answer/', {
      game_session: gameSessionId,
      game_def: gameDefId,
      question: question.question,
      token,
      is_correct: isCorrect,
      answer: answer.answer,
    }),
    payload: { question, answer },
  };
};

export const setQuestionAnswered = question => ({
  type: TYPES.SET_QUESTION_ANSWERED,
  payload: { question },
});

export const sendTeamPhoto = ({
  gameSessionId,
  ...data
} = {}) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  formData.append('email_text', 'email_text');

  return {
    types: [
      TYPES.SEND_TEAM_PHOTO,
      TYPES.SEND_TEAM_PHOTO_SUCCESS,
      TYPES.SEND_TEAM_PHOTO_FAIL,
    ],
    promise: client => client.post(`/game-sessions/result/${gameSessionId}/send-game-emails/`, formData),
  };
};
