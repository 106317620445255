/* Libs */
import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Expand = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
`;

export const Content = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  padding-top: 5px;
`;
