
import queryString from 'query-string';

import { ADMINS_TABLE } from 'config';
import * as TYPES from './Settings.types';

export const addUser = data => ({
  types: [TYPES.ADD_USER, TYPES.ADD_USER_SUCCESS, TYPES.ADD_USER_FAIL],
  promise: client => client.post('/users/', data),
});

export const editUser = ({ id, ...data }) => ({
  types: [TYPES.UPDATE_USER, TYPES.UPDATE_USER_SUCCESS, TYPES.UPDATE_USER_FAIL],
  promise: client => client.put(`/users/${id}/`, data),
  id,
});

export const activateUser = id => editUser({ id, is_active: true });

export const deleteUser = id => ({
  types: [TYPES.DELETE_USER, TYPES.DELETE_USER_SUCCESS, TYPES.DELETE_USER_FAIL],
  promise: client => client.delete(`/users/${id}/`),
  payload: { id },
});

export const getUsers = ({
  isActive,
  page = 1,
  role,
  name,
  perPage = ADMINS_TABLE.PAGINATION.PER_PAGE,
} = {}) => {
  const params = queryString.stringify({
    is_active: isActive,
    name,
    role,
    offset: (page - 1) * perPage,
    limit: perPage,
  });

  return {
    types: [TYPES.GET_USERS, TYPES.GET_USERS_SUCCESS, TYPES.GET_USERS_FAIL],
    promise: client => client.get(`/users/${params ? `?${params}` : ''}`),
  };
};

export const downloadUsers = ({ role }) => {
  const params = queryString.stringify({
    role,
  });
  return {
    types: [TYPES.DOWNLOAD_USERS, TYPES.DOWNLOAD_USERS_SUCCESS, TYPES.DOWNLOAD_USERS_FAIL],
    promise: client => client.get(`/users/download/${params ? `?${params}` : ''}`),
  };
};
