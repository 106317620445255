/**
 *
 * Pick touched data from data
 *
 * @param {Object} data All available data
 * @param {Object} touchedData Touched data in follow format { [field]: Boolean }
 */
function pickTouchedDataObject(data, touchedData) {
  if (typeof data === 'object' && data !== null
    && typeof data === 'object' && data !== null) {
    return Object.keys(data).reduce((resultData, key) => {
      if (touchedData[key]) {
        resultData[key] = data[key];
      }
      return resultData;
    }, {});
  }
  console.warn('Provided data or touched data is not an object, return empty object.');
  return {};
}

export default pickTouchedDataObject;
