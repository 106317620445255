/* Libs */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


/* Actions */

import { LS_AdminSettingsEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';
import {
  getError, replaceQueryParams, getStaticData, exportCSV, checkLastPageEmptiness,
} from 'utils/custom';

/* Components */

import {
  AdminSettingsStruct,
  AddCircle,
  GoLabel,
  AdminManageUsersSection,
  ToggleBar,
  AdminsTable,
  Pagination,
  AddAdminSidebar,
  EditAdminSidebar, SearchInput,
} from 'components';

/* Constants */

import {
  URLS_CONFIG, TABLES_CONFIG, USER_TYPES, ADMINS_TABLE,
} from 'config';
import { TRIANGLE_TYPES, LABEL_COLOR_TYPES, NOTIFICATIONS } from '_constants';
import { debounce, isNaN } from 'lodash';


/* Styles */

const PAGE_TITLE = 'Admin Settings';

const SIDEBAR_TYPES = {
  ADD: 'add',
  EDIT: 'edit',
};

function AdminSettings({
  getUsers,
  users,
  total,
  loading,
  activateUser: activateUserRequest,
  downloadUsers,
  location: { search },
  loggedUserId,
}) {
  const [openedSidebar, setOpenedSidebar] = useState(null);
  const [sidebarData, setSidebarData] = useState({});
  const manageSidebar = sidebarType => (data = {}) => {
    if (openedSidebar === sidebarType) {
      return setOpenedSidebar(null);
    }
    setOpenedSidebar(sidebarType);
    setSidebarData(data);
  };

  const userForEdit = useMemo(() => users.find(user => user.id === sidebarData), [sidebarData]);
  const [userType, setUserType] = useState(USER_TYPES.ACTIVE);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const mapUsers = users.filter(user => user.is_active === (userType === USER_TYPES.ACTIVE));

  async function getAdminUsers({
    currentPage = page,
    userTypes = userType,
    name = searchInput,
  } = {}) {
    if (page < 0) return;
    try {
      const result = await getUsers({
        page: currentPage,
        isActive: userTypes === USER_TYPES.ACTIVE,
        role: getStaticData('roles').admin,
        name,
      });
      if (!result.results.length && result.count) {
        setPage(1);
        setUserType(userTypes);
        getAdminUsers({
          currentPage: 1,
          userTypes,
        });
        replaceQueryParams({
          page: 1,
          type: userTypes,
        });
      } else {
        setUserType(userTypes);
        replaceQueryParams({
          page: currentPage,
          type: userTypes,
        });
      }
    } catch (error) {
      notification.error(getError(error));
    }
  }

  const fetchAdminUsers = useCallback(debounce(getAdminUsers, 300), []);


  const handleUserTypeChange = ({ target: { value } }) => {
    setUserType(value);
    setPage(1);
    getAdminUsers({
      currentPage: 1,
      userTypes: value,
      name: searchInput,
    });
  };

  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
    getAdminUsers({
      currentPage: selected + 1,
      userTypes: userType,
    });
  };


  const handleSearch = ({ target: { value } }) => {
    setSearchInput(value);
    setPage(1);
    fetchAdminUsers({
      currentPage: 1,
      userTypes: userType,
      name: value,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const searchParamsPage = +params.get('page');
    const queryPage = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;
    const validUserTypes = [USER_TYPES.ACTIVE, USER_TYPES.INACTIVE];
    const getUserType = params.get('type');
    const queryUserType = validUserTypes.includes(getUserType)
      ? getUserType
      : userType;
    setPage(queryPage);
    setUserType(
      queryUserType,
    );
    getAdminUsers({
      currentPage: queryPage,
      userTypes: queryUserType,
    });
  }, []);

  const isLastItemOnLastPage = checkLastPageEmptiness({
    total,
    perPage: ADMINS_TABLE.PAGINATION.PER_PAGE,
    page,
  });

  async function activateUser(id) {
    try {
      await activateUserRequest(id);

      if (isLastItemOnLastPage) {
        setPage(prev => prev - 1);
        await getAdminUsers({
          currentPage: page - 1,
          name: '',
        });
      } else {
        await getAdminUsers({ name: '' });
      }
      setSearchInput('');
      notification.success(NOTIFICATIONS.SUCCESS_ACTIVATE_USER);
    } catch (error) {
      notification.error(getError(error));
    }
  }

  async function clearSearchOnRequest() {
    try {
      setPage(1);
      setSearchInput('');
      await getAdminUsers({
        currentPage: 1,
        name: '',
      });
    } catch (error) {
      notification.error(getError(error));
    }
  }

  async function loadAllUsers() {
    try {
      if (isLastItemOnLastPage) {
        setPage(prev => prev - 1);
        await getAdminUsers({
          currentPage: page - 1,
          name: '',
        });
      } else {
        await getAdminUsers({ name: '' });
      }
      setSearchInput('');
    } catch (error) {
      notification.error(getError(error));
    }
  }

  const shareUsers = async () => {
    try {
      const downloadedUsers = await downloadUsers({ role: getStaticData('roles').admin });
      exportCSV(downloadedUsers, 'LS_Admins');
    } catch (error) {
      notification.error(getError(error));
    }
  };

  return (
    <AdminSettingsStruct
      title={PAGE_TITLE}
      search={(
        <SearchInput
          name="search"
          onChange={handleSearch}
          value={searchInput}
          placeholder="Search by name or email"
        />
      )}
      actions={[
        <AddCircle
          key="Add LS Admin"
          title="Add LS Admin"
          onClick={manageSidebar(SIDEBAR_TYPES.ADD)}
        />,
        <GoLabel
          key="Share users"
          text="Share users"
          triangleType={TRIANGLE_TYPES.FORWARD}
          color={LABEL_COLOR_TYPES.BLUE}
          click={shareUsers}
        />,
      ]}
    >
      <AdminManageUsersSection
        toggleBar={(
          <ToggleBar
            name="userType"
            onChange={handleUserTypeChange}
            active={userType}
            options={[
              USER_TYPES.ACTIVE,
              USER_TYPES.INACTIVE,
            ]}
          />
        )}
      >
        <AdminsTable
          data={mapUsers}
          tableSize={TABLES_CONFIG.SIZE.M}
          loading={loading}
          userType={userType}
          actions={{
            openEditUser: manageSidebar(SIDEBAR_TYPES.EDIT),
            activateUser,
          }}
          pagination={(
            <Pagination
              activePage={page}
              pageChangeHandler={handlePageChange}
              pageCount={total / ADMINS_TABLE.PAGINATION.PER_PAGE}
            />
          )}
        />
        <AddAdminSidebar
          open={openedSidebar === SIDEBAR_TYPES.ADD}
          onClose={manageSidebar(null)}
          title="Add LS Admin"
          clearSearchOnRequest={clearSearchOnRequest}
        />
        <EditAdminSidebar
          open={openedSidebar === SIDEBAR_TYPES.EDIT}
          onClose={manageSidebar(null)}
          nonDeletableUsersId={[loggedUserId]}
          user={userForEdit}
          title="Edit LS Admin"
          loadAllUsers={loadAllUsers}
          clearSearchOnRequest={clearSearchOnRequest}
        />
      </AdminManageUsersSection>
    </AdminSettingsStruct>
  );
}

/* Page Url */

AdminSettings.path = URLS_CONFIG.admin.settings;

/* Page Title */

AdminSettings.title = PAGE_TITLE;

/* AdminSettings type of props */

AdminSettings.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  loggedUserId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  downloadUsers: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
};

export default connect(({ LS_adminSettings, auth }) => ({
  loading: LS_adminSettings.loading,
  users: LS_adminSettings.users,
  total: LS_adminSettings.total,
  page: LS_adminSettings.page,
  loggedUserId: auth.id,
}), {
  getUsers: LS_AdminSettingsEntity.actions.getUsers,
  downloadUsers: LS_AdminSettingsEntity.actions.downloadUsers,
  activateUser: LS_AdminSettingsEntity.actions.activateUser,
})(AdminSettings);
