/* Libs */
import styled from 'styled-components';

/* Constants */
import { COLORS_CONFIG } from 'config';

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  height: 20px;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 16px;
  background-color: ${({ isActive }) => (
    isActive
      ? COLORS_CONFIG.ORANGE
      : COLORS_CONFIG.GREY_CUSTOM_5
  )};
  color: ${COLORS_CONFIG.WHITE};
`;

export const Label = styled.p`
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  margin-left: 5px;
`;
