/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  RuleItem,
} from 'components';

/* Styles */

import * as Styled from './styles';

function RulesList({ rules }) {
  return (
    <Styled.Wrapper>
      {
        rules.map(rule => (
          <RuleItem
            key={rule.name}
            name={rule.name}
            passed={rule.passed}
          />
        ))
      }
    </Styled.Wrapper>
  );
}

/* RulesList type of props */

RulesList.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    passed: PropTypes.bool.isRequired,
  })).isRequired,
};

export default RulesList;
