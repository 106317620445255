/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG } from 'config';

export const Positioner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: ${COLORS_CONFIG.BLACK$70};
  color: ${COLORS_CONFIG.WHITE};
  & + div {
    z-index: 15;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  ${({ friendlyMode }) => friendlyMode && css`
    ${Shadow} {
      border-radius: 0;
      filter: blur(5px);
      background-color: ${COLORS_CONFIG.WHITE$72};
    }
  `}
  
  ${({ isDarkMode }) => isDarkMode && css`
    ${Shadow} {
      border-radius: 0;
      filter: blur(5px);
      background-color: ${COLORS_CONFIG.DARK_BLUE$72};
    }
  `}
`;
