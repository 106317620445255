/* Libs */
import styled from 'styled-components';
import {
  COLORS_CONFIG,
  FONTS_CONFIG,
  TABLE_TYPES,
} from 'config';


export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FormattedDate = styled.div`
  width: ${props => (props.calendarType === TABLE_TYPES.DAY ? '320px' : '380px')};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 24px;
  line-height: 1;
  white-space: nowrap;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  & > * {
    cursor: pointer;
  }
  & > *:last-child {
    margin-left: 16px;
  }
`;
