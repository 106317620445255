import { SIZES } from '_constants';

export default {
  SIZE: {
    M: SIZES.MEDIUM,
    S: SIZES.SMALL,
  },
  CLASSES: {
    TABLE_CELL_FLEX_GROW_BASE: 'table-cell-flex-grow-',
  },
};
