/* Libs */
import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { debounce } from 'lodash';

/* Components */

import {
  Loader,
  CalendarHeader,
  DayScheduleTable,
  WeekCalendar,
  Img,
  Tooltip,
} from 'components';

/* Constants */

import {
  DATE_SETTINGS,
  TABLES_CONFIG,
  TABLE_TYPES,
} from 'config';
import { getSessionStatus, getStaticData } from 'utils/custom';

/* Constants */

import { CALENDAR_STYLES, SESSION_STATUSES } from '_constants';

/* Styles */
import { INFO_CIRCLE_BLUE } from 'assets/icons';
import * as Styled from './styles';

function CalendarMainSection({
  actions,
  date,
  tableData,
  openEditSession,
  openSessionInfoModal,
  onSessionDelete,
  calendarType,
  onChange,
  name,
  loading,
  gameSessionStatuses,
  pagination,
  campaign,
  styleToggleBar,
  calendarStyle,
}) {
  const [columns, setColumns] = useState(7);
  const [isDoubleClick, setIsDoubleClick] = useState(false);
  const [tooltipIsVisible, setTooltipVisibility] = useState(false);

  const openTooltip = () => setTooltipVisibility(true);
  const closeTooltip = () => setTooltipVisibility(false);


  // TODO: move to hook
  let debouncedClickEvents;

  const onClickHandler = (id) => {
    if (isDoubleClick) return;
    debouncedClickEvents = debouncedClickEvents || [];

    const callback = debounce(() => {
      openSessionInfoModal(id);
      debouncedClickEvents = [];
    }, 200);

    debouncedClickEvents.push(callback);

    callback();
  };

  const onDoubleClickHandler = (id, status) => {
    if (status !== getStaticData('gameSessionStatuses').scheduled) return;
    setIsDoubleClick(true);
    if (debouncedClickEvents.length > 0) {
      debouncedClickEvents.map(debounce => debounce.cancel());
      debouncedClickEvents = [];
    }

    openEditSession(id);
    setTimeout(() => setIsDoubleClick(false), 1000);
  };

  const generateEvent = ({ id, status }) => (
    <Styled.EventContainer
      deactivated={getSessionStatus(status) !== SESSION_STATUSES.NAME.SCHEDULED}
      onClick={() => onClickHandler(id)}
      onDoubleClick={() => onDoubleClickHandler(id, status)}
    />
  );

  // react-week-calendar component crashes if numberOfDays prop is static and we renders one component after another
  useEffect(() => {
    setColumns(calendarType === TABLE_TYPES.WEEK ? 7 : 1);
  }, [calendarType, calendarStyle]);

  const calendar = useMemo(() => {
    if (loading) return (<Loader />);
    if (calendarType === TABLE_TYPES.WEEK) {
      const firstDay = (
        date.getTimezoneOffset() >= 0
          ? moment(date).startOf('day')
          : moment(date).endOf('day')
      )
        .utc()
        .startOf('week');

      return (
        <WeekCalendar
          firstDay={firstDay}
          numberOfDays={columns}
          cellHeight={7}
          useModal={false}
          dayFormat={DATE_SETTINGS.FORMAT.SHORT_DAY}
          scaleFormat="LT"
          scaleUnit={10}
          selectedIntervals={tableData}
          eventSpacing={0}
          eventComponent={generateEvent}
          // onEventClick={({ id }) => openSessionInfoModal(id)}
        />
      );
    }

    if (calendarStyle === CALENDAR_STYLES.ALL) {
      return (
        <DayScheduleTable
          data={tableData}
          tableSize={TABLES_CONFIG.SIZE.M}
          actions={{
            openEditSession,
            openSessionInfoModal,
            onSessionDelete,
          }}
          date={date}
          gameSessionStatuses={gameSessionStatuses}
          pagination={pagination}
          loading={loading}
        />
      );
    }

    const firstDay = (
      date.getTimezoneOffset() >= 0
        ? moment(date).startOf('day')
        : moment(date).endOf('day')
    )
      .utc();

    return (
      <WeekCalendar
        firstDay={firstDay}
        numberOfDays={columns}
        cellHeight={7}
        useModal={false}
        dayFormat={DATE_SETTINGS.FORMAT.DAY_LONG_DAY_SHORT}
        scaleFormat="LT"
        scaleUnit={10}
        selectedIntervals={tableData}
        eventSpacing={0}
        eventComponent={generateEvent}
      />
    );
  }, [
    loading,
    calendarType,
    calendarStyle,
    tableData,
    openEditSession,
    date,
    gameSessionStatuses,
    pagination,
    columns,
    generateEvent,
  ]);

  return (
    <Styled.Wrapper>
      {
        campaign ? (
          <>
            <Styled.Header>
              <Styled.Date>
                <Styled.CalendarTooltip>
                  <Img
                    size={[17, 17]}
                    src={INFO_CIRCLE_BLUE}
                    onClick={openTooltip}
                  />
                  <Tooltip
                    visible={tooltipIsVisible}
                    onClose={closeTooltip}
                  >
                    <div>
                      Red blocks and records in the table
                      <br />
                      displayed by local time of a user
                      <br />
                      (or by default user timezone from GS)
                    </div>
                  </Tooltip>
                </Styled.CalendarTooltip>


                <CalendarHeader
                  date={date}
                  dateInterval={calendarType === TABLE_TYPES.WEEK}
                  onBackClick={() => onChange({
                    target: {
                      name,
                      value: new Date(
                        moment(date).subtract(1, calendarType === TABLE_TYPES.DAY ? 'd' : 'w'),
                      ),
                    },
                  })}
                  onForwardClick={() => onChange({
                    target: {
                      name,
                      value: new Date(
                        moment(date).add(1, calendarType === TABLE_TYPES.DAY ? 'd' : 'w'),
                      ),
                    },
                  })}
                  format={DATE_SETTINGS.FORMAT[
                    calendarType === TABLE_TYPES.WEEK ? 'MONTH_DAY' : 'DAY_MONTH_DATE'
                  ]}
                  calendarType={calendarType}
                />
              </Styled.Date>
              <Styled.Actions>
                {actions}
              </Styled.Actions>
              { calendarType === TABLE_TYPES.DAY && styleToggleBar }
            </Styled.Header>
            { calendar }
          </>
        ) : (
          <Styled.Title>No selected campaign</Styled.Title>
        )
      }
    </Styled.Wrapper>
  );
}

/* CalendarMainSection type of props */

CalendarMainSection.propTypes = {
  actions: PropTypes.node.isRequired,
  openEditSession: PropTypes.func.isRequired,
  openSessionInfoModal: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.node.isRequired,
  campaign: PropTypes.number,
  styleToggleBar: PropTypes.node,
  calendarStyle: PropTypes.oneOf(Object.values(CALENDAR_STYLES)),
};

/* CalendarMainSection default props */

CalendarMainSection.defaultProps = {
  campaign: null,
  styleToggleBar: null,
  calendarStyle: CALENDAR_STYLES.ALL,
};

export default CalendarMainSection;
