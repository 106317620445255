/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LeaderboardEntity, StaticDataEntity } from '_entities';

/* Utils */

import {
  getError,
  redirectWithError,
  replaceQueryParams,
  getLeaderboardShareLink, copyToClipboard,
} from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import {
  Pagination,
  ContainerLoader,
  LeaderboardTable,
  TopPlacesSection,
  LeaderboardStruct,
} from 'components';

/* Constants */

import { URLS_CONFIG, LEADERBOARD_TABLE } from 'config';
import { NOTIFICATIONS } from '_constants';
import { isNaN } from 'lodash';

function Leaderboard({
  list,
  page,
  total,
  loaded,
  setPage,
  loading,
  topPlaces,
  campaignName,
  getTopPlaces,
  getLeaderboard,
  getCampaignNameById,
  setStaticDataDarkMode,
}) {
  const [token, setToken] = useState(null);
  const [campaignId, setCampaign] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [shareLink, setLink] = useState('');

  async function getAllLeaderboard({
    currentPage = page,
    userToken = token,
    campaign = campaignId,
    darkMode = isDarkMode,
  } = {}) {
    if (page < 0) return;
    try {
      const { results, count } = await getLeaderboard({ campaign, token: userToken, page: currentPage });
      replaceQueryParams({
        campaign, token: userToken, darkmode: darkMode, page: currentPage,
      });
      if (!results.length && count) {
        setPage(1);
        replaceQueryParams({
          campaign, token: userToken, darkmode: darkMode, page: 1,
        });
        getAllLeaderboard({ currentPage: 1 });
      } else {
        replaceQueryParams({
          campaign: campaignId, token: userToken, darkmode: darkMode, page: currentPage,
        });
      }
    } catch (error) {
      notification.error(getError(error));
    }
  }

  useEffect(() => {
    loaded && getAllLeaderboard({ currentPage: page });
  }, [page]);

  useEffect(() => {
    const { location: { search } } = window;
    const urlsParams = new URLSearchParams(search);
    const queryToken = urlsParams.get('token');
    const queryCampaign = urlsParams.get('campaign');
    const searchParamsPage = +urlsParams.get('page');
    const queryParamsIsDarkMode = Boolean(urlsParams.get('darkmode') === 'true');
    const queryPage = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;

    if (!queryToken) {
      return redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.ERROR_NO_TOKEN);
    }
    if (!queryCampaign) {
      return redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.ERROR_NO_CAMPAIGN);
    }

    replaceQueryParams({
      campaign: queryCampaign, token: queryToken, darkmode: queryParamsIsDarkMode, page: queryPage,
    });
    setPage(queryPage);
    setLink(getLeaderboardShareLink({ token: queryToken, campaignId: queryCampaign, isDarkMode: queryParamsIsDarkMode }));
    setToken(queryToken);
    setCampaign(queryCampaign);
    setIsDarkMode(queryParamsIsDarkMode);
    setStaticDataDarkMode(queryParamsIsDarkMode);
  }, []);

  useEffect(() => {
    if (!campaignId || !token) return;
    Promise.all([
      getTopPlaces({
        token,
        campaign: campaignId,
      }),
      getAllLeaderboard(),
      getCampaignNameById({
        id: campaignId,
      }),
    ]).catch(error => redirectWithError(URLS_CONFIG.auth.signin, error));
  }, [token, campaignId]);

  const handlePageChange = ({ selected }) => setPage(selected + 1);

  const handleCopy = () => {
    copyToClipboard(shareLink);
    notification.success(NOTIFICATIONS.SUCCESS_COPIED);
  };

  return (
    !loaded
      ? <ContainerLoader isDarkMode={isDarkMode} />
      : (
        <LeaderboardStruct
          isDarkMode={isDarkMode}
          shareLink={shareLink}
          companyName={campaignName}
          handleCopy={handleCopy}
        >
          <TopPlacesSection
            first={topPlaces.first}
            second={topPlaces.second}
            third={topPlaces.third}
            isDarkMode={isDarkMode}
          />
          <LeaderboardTable
            isDarkMode={isDarkMode}
            data={list}
            loading={loading}
            pagination={(
              <Pagination
                pageCount={total / LEADERBOARD_TABLE.PAGINATION.PER_PAGE}
                pageChangeHandler={handlePageChange}
                activePage={page}
                isDarkMode={isDarkMode}
              />
            )}
          />
        </LeaderboardStruct>
      )
  );
}

Leaderboard.title = 'Leaderboard';

Leaderboard.path = URLS_CONFIG.public.leaderboard;

/* Leaderboard type of props */

Leaderboard.propTypes = {
  list: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  getTopPlaces: PropTypes.func.isRequired,
  campaignName: PropTypes.string.isRequired,
  getLeaderboard: PropTypes.func.isRequired,
  getCampaignNameById: PropTypes.func.isRequired,
  topPlaces: PropTypes.shape({}).isRequired,
};

/* Leaderboard default props */

Leaderboard.defaultProps = {};

export default connect(({ leaderboard }) => ({
  list: leaderboard.list,
  page: leaderboard.page,
  total: leaderboard.total,
  loaded: leaderboard.loaded,
  loading: leaderboard.loading,
  topPlaces: leaderboard.topPlaces,
  campaignName: leaderboard.campaignName,
}), {
  setPage: LeaderboardEntity.actions.setPage,
  getTopPlaces: LeaderboardEntity.actions.getTopPlaces,
  getLeaderboard: LeaderboardEntity.actions.getLeaderboard,
  getCampaignNameById: LeaderboardEntity.actions.getCampaignNameById,
  setStaticDataDarkMode: StaticDataEntity.actions.setDarkMode,
})(Leaderboard);
