import styled, { css } from 'styled-components';

import CircleDefault from 'components/blocks/Markers/CircleDefault/styles';
import { Sign as BackSign } from 'components/blocks/Markers/BackSign/styles';
import { Sign as FrontSign } from 'components/blocks/Markers/FrontSign/styles';

import { COLORS_CONFIG } from 'config';

export const Sign = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-size: 21px;
  font-weight: bold;
  line-height: 1;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover{
    background: transparent;
  }
`;

export const Circle = styled(CircleDefault)`
  position: relative;
  cursor: pointer;
  border-color: ${({ color }) => color};
  border-width: 1px;
  ${({ disabled }) => disabled && css`
    border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
    cursor: default;
  `}
  & ${Sign} {
    color: ${({ color }) => color};
    ${({ disabled }) => disabled && css`
      color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
    `}
  }
  
  & ${BackSign} {
    position: absolute;
    top: 7px;
    left: 9px;
  }
  & ${FrontSign} {
    position: absolute;
    top: 7px;
    left: 5px;
  }  
`;
