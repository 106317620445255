/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  VictoryLine,
} from 'victory';

/* Constants */

import {
  COLORS_CONFIG,
} from 'config';

const defaultChart = [
  { x: 1, y: 1 },
  { x: 2, y: 1 },
];

function CampaignPreviewChart({
  width,
  height,
  data,
}) {
  const chartData = (data && data.length)
    ? data
    : defaultChart;

  return (
    <VictoryLine
      height={height}
      width={width}
      padding={{
        top: 0, left: 0, right: 0, bottom: 1,
      }}
      data={chartData}
      interpolation="bundle"
      style={{
        data: {
          stroke: COLORS_CONFIG.SKY_BLUE,
          strokeWidth: 1,
        },
      }}
      labels={() => ''}
    />
  );
}

/* CampaignPreviewChart type of props */

CampaignPreviewChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
      ]),
      y: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
};

/* CampaignPreviewChart default props */

CampaignPreviewChart.defaultProps = {
  data: null,
  height: 50,
  width: 100,
};

export default CampaignPreviewChart;
