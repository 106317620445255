/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import {
  Modal,
  EditLicenseCountForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function EditLicenseCountModal({
  onClose,
  open,
  data,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Edit license count"
      open={open}
    >
      <Styled.EditLicenseCount>
        <EditLicenseCountForm onClose={onClose} data={data} />
      </Styled.EditLicenseCount>
    </Modal>
  );
}

// Type of props
EditLicenseCountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    licenses: PropTypes.number.isRequired,
  }).isRequired,
};

// Default value for props
EditLicenseCountModal.defaultProps = {};
