/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { AfterGameHeader } from 'components';

/* Styles */

import * as Styled from './styles';

function EndGameStruct({
  children,
  isDarkMode,
  leftSideHeader,
  rightSideHeader,
}) {
  return (
    <Styled.Struct isDarkMode={isDarkMode}>
      <AfterGameHeader isDarkMode={isDarkMode} leftSideHeader={leftSideHeader} right={rightSideHeader} />
      <Styled.Content>
        {children}
      </Styled.Content>
    </Styled.Struct>
  );
}

/* EndGameStruct type of props */

EndGameStruct.propTypes = {
  children: PropTypes.node.isRequired,
};

/* EndGameStruct default props */

EndGameStruct.defaultProps = {
};

export default EndGameStruct;
