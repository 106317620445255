import { tokenService } from 'utils/services';

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => next => (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const {
      promise, types, payload, ...rest
    } = action;
    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, ...(payload && { payload }), type: REQUEST });

    const actionPromise = promise(client, getState);
    actionPromise
      .then(
        (result) => {
          next({
            ...rest,
            result,
            type: SUCCESS,
            staticData: getState().staticData,
            ...(payload && { payload }),
          });
        },
        (error) => {
          // logout user if expired token or permission denied
          if (error.response && [401, 403].includes(error.response.status)) {
            tokenService.removeToken();
          }
          next({
            ...rest,
            error,
            type: FAILURE,
            ...(payload && { payload }),
          });
        },
      )
      .catch((error) => {
        // eslint-disable-next-line
          console.error('MIDDLEWARE ERROR:', error);
        next({ ...rest, error, type: FAILURE });
      });

    return actionPromise;
  };
}
