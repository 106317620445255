/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LS_AdminCompanyEntity } from '_entities';

/* Utils */

import { validateText, validateEmail } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { notification } from 'utils/services';
import { getError, getInitialTouched, pickTouchedDataObject } from 'utils/custom';

/* Components */

import {
  Input,
  Button,
} from 'components';

/* Constants */

import { NOTIFICATIONS } from '_constants';

/* Styles */

import * as Styled from './styles';

const DEFAULT_ERRORS = {
  email: false,
  first_name: false,
  last_name: false,
};

function EditPOCForm({
  data: INITIAL_DATA,
  updatePOC,
  companyId,
  onClose,
  loading,
}) {
  const [formData, setData] = useState({
    data: INITIAL_DATA,
    touchedData: getInitialTouched(INITIAL_DATA),
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const handleChange = ({ target: { name, value } }) => {
    const { errors, data, touchedData: oldTouchedData } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({ value, name, max: 254 });
        break;
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({ value, name, max: 64 });
        break;
      default:
        break;
    }
    const touchedData = { ...oldTouchedData, [name]: value !== INITIAL_DATA[name] };
    setData({
      data: { ...data, [name]: value },
      touchedData,
      errors: newErrors,
      canSubmit:
        formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email'])
        && Object.values(touchedData).some(value => Boolean(value)),
    });
  };

  const handleSave = async () => {
    try {
      const { data, touchedData } = formData;
      await updatePOC({
        ...pickTouchedDataObject(data, touchedData),
        id: data.id,
        // role: getStaticData('roles').admin
      });
      notification.success(NOTIFICATIONS.SUCCESS_UPDATE_POC);
      onClose();
    } catch (error) {
      const newError = getError(error);
      if (error.response && error.response.data) {
        const responseErrors = Object.entries(error.response.data)
          .reduce((acc, [key, value]) => {
            [acc[key]] = value;
            return acc;
          }, {});
        setData(prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            ...responseErrors,
          },
          canSubmit:
            formIsValid({ ...prev.errors, email: newError }, ['first_name', 'last_name', 'email'])
            && Object.values(prev.touchedData).some(value => Boolean(value)),
        }));
      }
      notification.error(newError);
    }
  };

  const { data, errors, canSubmit } = formData;
  return (
    <Styled.Wrapper>
      <Input
        placeholder="First name"
        title="First name"
        name="first_name"
        error={errors.first_name}
        value={data.first_name}
        onChange={handleChange}
      />
      <Input
        placeholder="Last name"
        title="Last name"
        name="last_name"
        error={errors.last_name}
        value={data.last_name}
        onChange={handleChange}
      />
      <Input
        placeholder="Email"
        title="Email"
        name="email"
        type="email"
        error={errors.email}
        value={data.email}
        onChange={handleChange}
      />
      <Button
        disabled={!canSubmit || loading}
        onClick={handleSave}
      >
Save
      </Button>
    </Styled.Wrapper>
  );
}

/* EditPOCForm type of props */

EditPOCForm.propTypes = {
  data: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  companyId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  updatePOC: PropTypes.func.isRequired,
};

export default connect(({ LS_adminCompanyView }) => ({
  companyId: LS_adminCompanyView.company.id,
  loading: LS_adminCompanyView.loading,
}), {
  updatePOC: LS_AdminCompanyEntity.actions.updatePOC,
})(EditPOCForm);
