/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLORS_CONFIG.SILVER};
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.div`
  font-size: 18px;
  font-family: ${({ bold }) => bold ? FONTS_CONFIG.MAIN_TEXT_FONT_BOLD : FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;

export const Value = styled.div`
  font-size: 18px;
  font-family: ${({ bold }) => bold ? FONTS_CONFIG.MAIN_TEXT_FONT_BOLD : FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;

export const Description = styled.div`
  margin-top: 10px;
  text-align: right;
  font-size: 14px;
  color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
`;
