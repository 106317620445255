import COMMON_ERRORS from '_constants/errors/COMMON_ERRORS';

const BYTES_IN_MEGA_BYTE = 1e6;
const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
export default ({
  value, name, customErrors = {}, initial = false,
}) => {
  const bytes = value && value instanceof File && value.size;
  if (!bytes) return COMMON_ERRORS.img;
  const error = (bytes / BYTES_IN_MEGA_BYTE > 5 && COMMON_ERRORS.imgSize)
      || (!ALLOWED_TYPES.includes(value.type) && COMMON_ERRORS.imgFormats);
  return initial && error ? null : error;
};
