/* Libs */
import styled from 'styled-components';

/* Constants */
import { BREAKPOINTS } from '_constants';


export const Column = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:first-child){
    margin-top: 22px;
    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      margin-top: 10px;
    }
  }
`;

export const LeftColumn = styled(Column)`
  flex: 3;
  min-width: 339px;
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    min-width: 265px;
  }
`;

export const RightColumn = styled(Column)`
  margin-left: 32px;
  flex: 5;
  min-width: 569px;
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    margin-left: 10px;
    min-width: 300px;
  }
`;

export const CompanyViewStruct = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
