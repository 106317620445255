import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import BREAKPOINTS from '_constants/BREAKPOINTS';

import { Button } from 'components/ui/Button/styles';
import {
  Wrapper as ImageWrapper,
  Image,
} from 'components/blocks/ui/Img/styles';


export const Title = styled.div`
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 32px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 50px auto;
  width: 565px;
  height: 375px;
  background-color: ${COLORS_CONFIG.WHITE};

  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
     margin: 30px auto 20px;
  }

  ${({ image }) => css`
    background: url(${image}) center / cover no-repeat;
  `}
`;

export const Cross = styled.div`
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
  padding: 2px 5px;
  background: ${COLORS_CONFIG.SMOOTH_BROWN};
  color: ${COLORS_CONFIG.WHITE};;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
`;

export const Content = styled.div`
  position: relative;
  width: 515px;
  height: 317px;

  &:hover {

    ${Cross} {
      display: block;
    }
  }
`;

export const Preview = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${ImageWrapper} {
    width: 100%;
    height: 100%;
  }

  ${Image} {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
    cursor: default;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${COLORS_CONFIG.SILVER};
  opacity: 1;
  cursor: pointer;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }

  ${({ isHighlighted }) => isHighlighted && css`
    opacity: .8;
  `}

  ${({ isHidden }) => isHidden && css`
    opacity: 0;

    &:hover {
      opacity: 0;
    }
  `}
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Icon = styled.div`
  width: 62px;
  height: 55px;
  ${({ src }) => src && css`
    background: url(${src}) no-repeat center / contain;
  `};
`;

export const Text = styled.div`
  margin-top: 25px;
  width: 200px;
  font-size: 16px;
  text-align: center;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};

  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    margin-top: 20px;
  }
`;

export const Wrapper = styled.div`
  padding: 35px 0;
  height: fit-content;

  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    padding: 20px 0;
  }

  ${Button} {
    margin: 0 auto;
  }

  ${({ isDarkMode }) => isDarkMode && css`

    ${Title}, ${Text} {
      color: ${COLORS_CONFIG.WHITE};
    }

    ${Container}, ${Label}  {
      background-color: ${COLORS_CONFIG.NAVY_BLUE};
    }
  `}
`;
