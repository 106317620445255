export const GET_LEADERBOARD = '@PROJECT_NAME/GET_LEADERBOARD';
export const GET_LEADERBOARD_SUCCESS = '@PROJECT_NAME/GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAIL = '@PROJECT_NAME/GET_LEADERBOARD_FAIL';

export const GET_TOP_PLACES = '@PROJECT_NAME/GET_TOP_PLACES';
export const GET_TOP_PLACES_SUCCESS = '@PROJECT_NAME/GET_TOP_PLACES_SUCCESS';
export const GET_TOP_PLACES_FAIL = '@PROJECT_NAME/GET_TOP_PLACES_FAIL';

export const GET_CAMPAIGN_NAME = '@PROJECT_NAME/GET_CAMPAIGN_NAME';
export const GET_CAMPAIGN_NAME_SUCCESS = '@PROJECT_NAME/GET_CAMPAIGN_NAME_SUCCESS';
export const GET_CAMPAIGN_NAME_FAIL = '@PROJECT_NAME/GET_CAMPAIGN_NAME_FAIL';

export const SET_PAGE = '@PROJECT_NAME/SET_PAGE_LEADERBOARD';
