/* Libs */
import styled from 'styled-components';

import { Circle } from 'components/blocks/Markers/CircleWithSign/styles';
import {
  Button,
} from 'components/ui/Button/styles';
import { Input } from 'components/ui/Input/styles';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Subtitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 25px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;
export const Action = styled.div`
  & ${Circle} {
    width: 32px;
    height: 32px;
  }
`;
export const MainSection = styled.div`
  width: 260px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const ParticipantsCount = styled.div`
  font-size: 88px;
  line-height: 88px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const InputParticipantsCountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  & ${Input} {
      border: none;
      background: transparent;
      font-size: 88px;
      line-height: 88px;
      color: ${COLORS_CONFIG.WHITE};
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
      text-align: center;
   }
`;

export const Actions = styled.div`
  padding: 55px 0 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & ${Button} {
    width: 160px;
    min-width: 160px;
    background-color: ${COLORS_CONFIG.WHITE};
    color: ${COLORS_CONFIG.SKY_BLUE};
    &:last-child {
      margin-top: 30px;
      padding: 0;
      height: initial;
      background-color: ${COLORS_CONFIG.TRANSPARENT};
      color: ${COLORS_CONFIG.WHITE};
    }
  }
`;

export const Wrapper = styled.div`
`;
