/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  background-color: ${COLORS_CONFIG.SILVER};
  border-radius: 16px;
  display: inline-block;
  height: 32px;
  display: flex;
  align-items: center;
  width: max-content;
`;

export const Inline = styled.div`
  display: ${props => (props.sameWidth ? 'grid' : 'flex')};
  height: 32px;
  grid-template-columns: ${props => `repeat(${props.count}, 1fr)`};
  position: relative;
`;

export const Item = styled.div`
  padding: 0px 36px;
  border-radius: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  cursor: pointer;
  text-transform: capitalize;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  position: relative;
  &:first-child {
    left: -1px;
  }
  &:last-child {
    right: -1px;
  }
  ${({ active }) => active
    && css`
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
      color: ${COLORS_CONFIG.WHITE};
      cursor: default;
      background-color: ${COLORS_CONFIG.SKY_BLUE};
    `}
`;
