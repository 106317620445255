/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  snakeCase,
  lowerCase,
  isEmpty,
} from 'lodash';


/* Utils */
import { validateEmail, validateText, validateCampaigns } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { getError, transformDataToOptions } from 'utils/custom';
import { notification } from 'utils/services';
import { VALIDATION_VALUES } from '_constants';

/* Components */
import {
  Select,
  Input,
  Checkbox,
  Button,
  ToggleBar,
} from 'components';

/* Styles */
import * as Styled from './styles';

const INITIAL_DATA = {
  first_name: null,
  last_name: null,
  email: null,
  campaigns: [],
  addToAllCampaigns: false,
};

const DEFAULT_ERRORS = {
  first_name: null,
  last_name: null,
  campaigns: null,
  email: null,
};

function AddUserForm({
  onSave,
  roles,
  getAllActiveCampaigns,
  allActiveCampaigns,
  company,
}) {
  const [formData, setFormData] = useState({
    data: INITIAL_DATA,
    userRole: roles.host,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: VALIDATION_VALUES.NAME_MAX_LENGTH,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      case 'campaigns':
        newErrors[name] = validateCampaigns({
          value,
          name,
        });
        break;
      case 'addToAllCampaigns':
        newErrors.campaigns = value
          ? null
          : validateCampaigns({
            value: data.campaigns,
            name: 'campaigns',
          });
        break;
      default:
        break;
    }

    setFormData(prev => ({
      ...prev,
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email']),
    }));
  };
  const handleChangeUserRole = ({ target: { value } }) => setFormData(prev => ({
    ...prev,
    data: {
      ...prev.data,
      campaigns: [],
      addToAllCampaigns: false,
    },
    userRole: snakeCase(value),
  }));


  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({
          value,
          name,
          max: VALIDATION_VALUES.NAME_MAX_LENGTH,
        });
        break;
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      case 'campaigns':
        newErrors[name] = validateCampaigns({
          value,
          name,
        });
        break;
      case 'addToAllCampaigns':
        newErrors.campaigns = value
          ? null
          : validateCampaigns({
            value: data.campaigns,
            name: 'campaigns',
          });
        break;
      default:
        break;
    }
    setFormData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['first_name', 'last_name', 'email']),
    }));
  };

  const {
    userRole,
    data: {
      first_name,
      last_name,
      email,
      campaigns,
      addToAllCampaigns,
    },
    errors,
    canSubmit,
  } = formData;

  const handleSubmit = async () => {
    const data = {
      first_name,
      last_name,
      email,
      role: userRole,
      company,
      campaigns: addToAllCampaigns ? allActiveCampaigns.map(({ id }) => id) : campaigns,
    };

    onSave(data);
  };

  useEffect(() => {
    (async () => {
      try {
        let activeCampaigns;
        const { results, count } = await getAllActiveCampaigns({ company });

        activeCampaigns = results;

        if (results.length !== count) {
          const { results } = await getAllActiveCampaigns({ company, limit: count });
          activeCampaigns = results;
        }

        if (count === activeCampaigns.length && !isEmpty(activeCampaigns)) {
          setFormData(prev => ({
            ...prev,
            data: {
              ...prev.data,
              campaigns: activeCampaigns.map(({ id }) => id),
              addToAllCampaigns: true,
            },
            canSubmit: false,
          }));
        }
      } catch (error) {
        notification.error(getError(error));
      }
    })();
  }, []);

  const handleDisabled = () => (userRole === roles.host
    ? !(canSubmit && (addToAllCampaigns || !isEmpty(campaigns)))
    : !canSubmit);

  return (
    <Styled.Wrapper>
      <Styled.Data>
        <Styled.Info>
          <ToggleBar
            name="userRole"
            onChange={handleChangeUserRole}
            active={lowerCase(userRole)}
            options={[
              lowerCase(roles.host),
              lowerCase(roles.company_admin),
            ]}
          />
          <Input
            name="first_name"
            title="First name"
            placeholder="First name"
            value={first_name}
            error={errors.first_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            name="last_name"
            title="Last name"
            placeholder="Last name"
            value={last_name}
            error={errors.last_name}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          <Input
            type="email"
            name="email"
            title="Email"
            placeholder="Email"
            value={email}
            error={errors.email}
            onChange={handleChange}
            onBlur={validateOnBlur}
          />
          {
            userRole !== roles.company_admin && (
              <>
                <Styled.SmallTitle>Campaigns</Styled.SmallTitle>
                <Select
                  name="campaigns"
                  placeholder="Campaigns"
                  value={campaigns}
                  error={errors.campaigns}
                  onChange={handleChange}
                  onBlur={validateOnBlur}
                  options={transformDataToOptions({ data: allActiveCampaigns, label: 'name', value: 'id' })}
                  isMulti
                  isDisabled={addToAllCampaigns}
                />
                <Checkbox
                  name="addToAllCampaigns"
                  value={addToAllCampaigns}
                  onChange={handleChange}
                  label="Add to all existing campaigns"
                  disabled={isEmpty(allActiveCampaigns)}
                />
              </>
            )
          }
        </Styled.Info>
      </Styled.Data>
      <Styled.Actions>
        <Button
          disabled={handleDisabled()}
          onClick={handleSubmit}
        >
          {`Add ${userRole === roles.host ? 'host' : 'company admin'}`}
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* EditUserForm type of props */
const {
  func, number, arrayOf, shape, string,
} = PropTypes;

AddUserForm.propTypes = {
  onSave: func.isRequired,
  getAllActiveCampaigns: func.isRequired,
  allActiveCampaigns: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    }),
  ),
  company: number.isRequired,
  roles: PropTypes.objectOf(Object).isRequired,
};

/* EditUserForm default props */
AddUserForm.defaultProps = {
  allActiveCampaigns: [],
};

export default AddUserForm;
