const NUMBERS_RULE_WITH_LEADING_ZERO = /^[0-9]+$|^$/;
const NUMBERS_RULE_WITHOUT_LEADING_ZERO = /^[1-9][0-9]*$|^$/;

const isValidNumbersField = (str, withLeadingZero = false) => (
  withLeadingZero
    ? NUMBERS_RULE_WITH_LEADING_ZERO
    : NUMBERS_RULE_WITHOUT_LEADING_ZERO
).test(str);

export default isValidNumbersField;
