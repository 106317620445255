/* Libs */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/* Components */

import { QuestionsSection, AddGameDefinitionForms } from 'components';

/* Styles */

import { GAME_DEF_FORM } from '_constants';
import * as Styled from './styles';

const AddGameDefinitionSection = memo(
  ({
    data, handleChange, mode, importedData, errors, formIsEmpty,
  }) => {
    const basicInformationData = useMemo(
      () => pick(data, [
        'name',
        'company',
        'storyline',
        'final_code',
        'time_limit',
        'game_def_template',
        'initial_template_name',
        'is_final_code_only',
      ]),
      [
        data.name,
        data.company,
        data.storyline,
        data.final_code,
        data.time_limit,
        data.initial_template_name,
        data.game_def_template,
        data.is_final_code_only,
      ],
    );

    const questionsData = useMemo(
      () => (Array.isArray(data.questions) ? data.questions : []),
      [data.questions],
    );

    const introductionData = useMemo(
      () => pick(data, [
        'introduction_title',
        'introduction_text',
        'introduction_vimeo_id',
      ]),
      [
        data.introduction_title,
        data.introduction_text,
        data.introduction_vimeo_id,
      ],
    );

    const instructionData = useMemo(
      () => pick(data, [
        'instruction_title',
        'instruction_text',
        'instruction_vimeo_id',
      ]),
      [
        data.instruction_title,
        data.instruction_text,
        data.instruction_vimeo_id,
      ],
    );

    const storylineData = useMemo(
      () => pick(data, ['storyline_title', 'storyline_text', 'storyline_vimeo_id']),
      [data.storyline_title, data.storyline_text, data.storyline_vimeo_id],
    );

    const debriefingData = useMemo(
      () => pick(data, [
        'debriefing_title',
        'debriefing_text',
        'debriefing_vimeo_id',
      ]),
      [data.debriefing_title, data.debriefing_text, data.debriefing_vimeo_id],
    );

    const resetData = useMemo(
      () => pick(data, ['reset_title', 'reset_text', 'reset_vimeo_id']),
      [data.reset_title, data.reset_text, data.reset_vimeo_id],
    );

    return (
      <Styled.Wrapper isDisabled={mode === GAME_DEF_FORM.MODE.VIEW}>
        <Styled.Left>
          <AddGameDefinitionForms.BasicInformationForm
            formName="basicInformation"
            mode={mode}
            handleChange={handleChange}
            data={basicInformationData}
            importedData={importedData}
            globalErrors={errors.basicInformation}
          />
          <QuestionsSection
            disabled={data.is_final_code_only}
            data={questionsData}
            importedData={importedData}
            final_code={data.final_code}
            formName="questions"
            game_def_template={data.game_def_template}
            mode={mode}
            handleChange={handleChange}
            globalErrors={errors.questions}
            formIsEmpty={formIsEmpty}
          />
        </Styled.Left>
        <Styled.Right>
          <AddGameDefinitionForms.IntroductionForm
            formName="intoduction"
            handleChange={handleChange}
            data={introductionData}
          />
          <AddGameDefinitionForms.InstructionsForm
            formName="instruction"
            handleChange={handleChange}
            data={instructionData}
          />
          <AddGameDefinitionForms.StorylineForm
            formName="storyline"
            handleChange={handleChange}
            data={storylineData}
          />
          <AddGameDefinitionForms.DebriefingForm
            formName="debriefing"
            handleChange={handleChange}
            data={debriefingData}
          />
          <AddGameDefinitionForms.ResetForm
            formName="reset"
            handleChange={handleChange}
            data={resetData}
          />
        </Styled.Right>
      </Styled.Wrapper>
    );
  },
);

/* AddGameDefinitionSection type of props */

AddGameDefinitionSection.propTypes = {
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([
    GAME_DEF_FORM.MODE.EDIT,
    GAME_DEF_FORM.MODE.ADD,
    GAME_DEF_FORM.MODE.VIEW,
    GAME_DEF_FORM.MODE.TEMPLATE,
  ]).isRequired,
  errors: PropTypes.shape({
    basicInformation: PropTypes.bool,
    questions: PropTypes.bool,
    introduction: PropTypes.bool,
    instruction: PropTypes.bool,
    storyline: PropTypes.bool,
    debriefing: PropTypes.bool,
    reset: PropTypes.bool,
  }),
};

AddGameDefinitionSection.defaultProps = {
  errors: {},
};

export default AddGameDefinitionSection;
