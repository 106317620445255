/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function Img({
  src,
  size,
  relative,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onBlur,
  tip,
  center,
  disabled,
  grayscaleOnDisabled,
}) {
  const [width, height] = size;
  const handleClick = (event) => {
    if (!onClick || disabled) return;
    onClick(event);
  };

  const handleBlur = () => {
    if (!onBlur || disabled) return;
    onBlur();
  };

  return (
    <Styled.Wrapper isCenter={center}>
      <Styled.Image
        relative={relative}
        img={src}
        width={width}
        height={height}
        disabled={disabled}
        grayscaleOnDisabled={grayscaleOnDisabled}
        onClick={handleClick}
        onBlur={handleBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-tip={tip}
      />
    </Styled.Wrapper>
  );
}

/* Img type of props */

Img.propTypes = {
  center: PropTypes.bool,
  src: PropTypes.string,
  size: PropTypes.arrayOf(PropTypes.number),
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  relative: PropTypes.bool,
  disabled: PropTypes.bool,
  grayscaleOnDisabled: PropTypes.bool,
};

/* Img default props */

Img.defaultProps = {
  onClick: () => {},
  onBlur: () => {},
  center: false,
  onMouseEnter: null,
  onMouseLeave: null,
  src: null,
  size: [75, 75],
  relative: false,
  disabled: false,
  grayscaleOnDisabled: false,
};

export default memo(Img, (prevProps, nextProps) => Object.entries(nextProps).reduce(
  (propsIsSame, [key, prop]) => (propsIsSame
    ? Array.isArray(prop)
      ? prop.reduce((isSame, value, idx) => isSame && value === prevProps[key][idx], true)
      : prop === prevProps[key]
    : false),
  true,
));
