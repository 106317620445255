/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { GameEntity, StaticDataEntity } from '_entities';

/* Utils */

import { redirect } from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import { AfterGameStruct, GameCards } from 'components';

/* Constants */

import { GAME_CONFIG, URLS_CONFIG } from 'config';
import { isEmpty } from 'utils/validation';

function AfterGame({
  step,
  changeStep,
  debriefing_title,
  debriefing_text,
  reset_title,
  reset_text,
  debriefing_vimeo_id,
  reset_vimeo_id,
  hasResetStep,
  hasDebriefingStep,
  isDarkMode,
  setStaticDataDarkMode,
}) {
  const setStep = step => () => changeStep(step);

  async function exitGame() {
    try {
      redirect(URLS_CONFIG.auth.signin);
      setStaticDataDarkMode(false);
    } catch (error) {
      notification.error(error);
    }
  }

  function manageDisplay() {
    switch (step) {
      case GAME_CONFIG.STEP.DEBRIEFING:
        return (
          <GameCards.Debriefing
            next={hasResetStep ? setStep(GAME_CONFIG.STEP.RESET) : exitGame}
            title={debriefing_title}
            text={debriefing_text}
            vimeoId={debriefing_vimeo_id}
            hasNextStep={hasResetStep}
            isDarkMode={isDarkMode}
          />
        );
      case GAME_CONFIG.STEP.RESET:
      default:
        return (
          <GameCards.Reset
            next={exitGame}
            back={setStep(GAME_CONFIG.STEP.DEBRIEFING)}
            hasBackStep={hasDebriefingStep}
            title={reset_title}
            text={reset_text}
            vimeoId={reset_vimeo_id}
            isDarkMode={isDarkMode}
          />
        );
    }
  }

  return (
    <AfterGameStruct isDarkMode={isDarkMode}>
      {manageDisplay()}
    </AfterGameStruct>
  );
}

/* AfterGame type of props */

AfterGame.propTypes = {
  step: PropTypes.oneOf([
    GAME_CONFIG.STEP.DEBRIEFING,
    GAME_CONFIG.STEP.RESET,
  ]),
  changeStep: PropTypes.func.isRequired,
  debriefing_title: PropTypes.string,
  debriefing_text: PropTypes.string,
  reset_title: PropTypes.string,
  reset_text: PropTypes.string,
  debriefing_vimeo_id: PropTypes.string,
  reset_vimeo_id: PropTypes.string,
  hasResetStep: PropTypes.bool.isRequired,
  hasDebriefingStep: PropTypes.bool.isRequired,
};

/* AfterGame default props */

AfterGame.defaultProps = {};

export default connect(({ game: { gameDef } }) => ({
  debriefing_title: gameDef.debriefing_title,
  debriefing_text: gameDef.debriefing_text,
  reset_title: gameDef.reset_title,
  reset_text: gameDef.reset_text,
  isDarkMode: gameDef.is_dark_mode,
  debriefing_vimeo_id: gameDef.debriefing_vimeo_id,
  reset_vimeo_id: gameDef.reset_vimeo_id,
  hasResetStep: !isEmpty(gameDef.reset_text)
    || !isEmpty(gameDef.reset_vimeo_id)
    || !isEmpty(gameDef.reset_title),
  hasDebriefingStep: !isEmpty(gameDef.debriefing_text)
    || !isEmpty(gameDef.debriefing_vimeo_id)
    || !isEmpty(gameDef.debriefing_title),
}), {
  changeStep: GameEntity.actions.changeStep,
  setStaticDataDarkMode: StaticDataEntity.actions.setDarkMode,
})(AfterGame);
