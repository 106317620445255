import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';


export const Label = styled.div`
  font-size: 16px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    font-size: 14px;
  }
`;

export const Link = styled(DefaultLink)`
  position: relative;
  /* min-width: 113px; */
  padding: 0 20px;
  height: 100%;
  background-color: ${COLORS_CONFIG.WHITE};
  pointer-events: ${props => (props.same ? 'none' : 'initial')};
  &:after {
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: ${COLORS_CONFIG.ORANGE};
    content: '';
  }
  &:hover {
    &:after {
      display: block;
      background: ${COLORS_CONFIG.SKY_BLUE};
    }
  }
  ${Label} {
    font-family: ${props => (props.active ? FONTS_CONFIG.MAIN_TEXT_FONT_BOLD : FONTS_CONFIG.MAIN_TEXT_FONT_BOOK)};
  }
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 12px;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
