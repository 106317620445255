/* Libs */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/* Constants */
import { URLS_CONFIG } from 'config';
import URLS from 'config/common/URLS';

// CompanyAdmin subroutes
const COMPANY_ADMIN_SUB_ROUTES = [
  require('./Company').default,
  require('./Calendar').default,
  require('./Campaigns').default,
  require('./ManageGame').default,
  require('./Reports').default,
  require('./Users').default,
];

export default class AdminPage extends Component {
  // Static props
  static path = URLS_CONFIG.companyAdmin.default;

  render() {
    return (
      <Switch>
        {COMPANY_ADMIN_SUB_ROUTES.map(route => (
          <Route path={route.path} exact={route.path === URLS.companyAdmin.campaigns} component={route} key={route.path} />
        ))}
        <Redirect to={URLS_CONFIG.companyAdmin.calendar} />
      </Switch>
    );
  }
}
