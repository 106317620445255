/* Libs */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Actions */

import { LS_AdminCompanyEntity } from '_entities';

import {
  redirectWithError,
  redirect,
  getImpersonateLink,
  getError,
} from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import {
  AdminViewCompanyStruct,
  AdminCompaniesStruct,
  GameDefinitionsCard,
  PointOfContactCard,
  LicenseUsageCard,
  ContainerLoader,
  LicensingCard,
  KitsCard,
  GoLabel,
  DeleteCompanyButton,
  DeactivateCompanyButton,
  DeleteCompanyModal,
  SmallConfirmationModal,
} from 'components';

/* Constants */

import { URLS_CONFIG } from 'config';
import { TRIANGLE_TYPES, LABEL_COLOR_TYPES } from '_constants';

const PAGE_TITLE = 'ViewCompany';

const MODALS_NAME = {
  DEACTIVATE: 'deactivate',
  DELETE: 'delete',
};

function ViewCompany({
  getCompanyById,
  resetCompany,
  deleteCompany,
  changeCompanyActivityStatus,
  match: { params: { id } },
  company,
  loaded,
}) {
  const [activeModal, setActiveModal] = useState(null);
  const manageModals = name => () => setActiveModal(name);

  const impersonate = () => {
    redirect(getImpersonateLink(id));
  };

  const callDeleteCompany = () => deleteCompany(id);
  const deactivateCompany = async () => {
    try {
      await changeCompanyActivityStatus(id, false);
      redirect('/companies', 'Company was successfully deactivated');
    } catch (e) {
      notification.error(getError(e));
    }
  };

  useEffect(() => {
    async function getCompanyInfo() {
      try {
        await getCompanyById(id);
      } catch (error) {
        get(error, 'response.status', null) === 404
          && redirectWithError(URLS_CONFIG.admin.companies, error);
      }
    }
    getCompanyInfo();
    return () => {
      resetCompany();
    };
  }, []);

  return (
    <AdminCompaniesStruct
      title={company.name || PAGE_TITLE}
      goBack={{
        enabled: true,
        text: 'Back to companies',
        href: URLS_CONFIG.admin.companies,
      }}
      actions={[
        <GoLabel
          key="Impersonate"
          text="Impersonate"
          triangleType={TRIANGLE_TYPES.FORWARD}
          color={LABEL_COLOR_TYPES.BLUE}
          click={impersonate} // TODO: make an appropriate action
        />,
      ]}
      botActions={[
        <DeactivateCompanyButton
          key="deactivate"
          onClick={manageModals(MODALS_NAME.DEACTIVATE)}
        />,
        <DeleteCompanyButton
          key="delete_company"
          onClick={manageModals(MODALS_NAME.DELETE)}
        />,
      ]}
    >
      {
        !loaded
          ? <ContainerLoader />
          : (
            <>
              <AdminViewCompanyStruct
                leftColumn={(
                  <>
                    <LicensingCard
                      data={company.contract || {}}
                    />
                    <LicenseUsageCard
                      used={company.used_user_licenses || 0}
                      total={company.licenses || 0}
                    />
                    <PointOfContactCard
                      first_name={get(company, 'admin.first_name', '')}
                      last_name={get(company, 'admin.last_name', '')}
                      email={get(company, 'admin.email', '')}
                      id={get(company, 'admin.id', '')}
                    />
                  </>
                )}
                rightColumn={(
                  <>
                    <GameDefinitionsCard
                      companyId={id}
                    />
                    <KitsCard
                      companyId={id}
                    />
                  </>
                )}
              />
            </>
          )
      }
      <DeleteCompanyModal
        open={activeModal === MODALS_NAME.DELETE}
        onConfirm={callDeleteCompany}
        onClose={manageModals(null)}
      />
      <SmallConfirmationModal
        open={activeModal === MODALS_NAME.DEACTIVATE}
        onConfirm={deactivateCompany}
        onClose={manageModals(null)}
        description="Are you sure you want to deactivate this company?"
        confirmText="Deactivate"
      />
    </AdminCompaniesStruct>
  );
}

/* Page Url */

ViewCompany.path = URLS_CONFIG.admin.viewCompany;

/* Page Title */

ViewCompany.title = PAGE_TITLE;

/* ViewCompany type of props */

ViewCompany.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getCompanyById: PropTypes.func.isRequired,
  resetCompany: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  changeCompanyActivityStatus: PropTypes.func.isRequired,
};

export default connect(({ LS_adminCompanyView }) => ({
  company: { ...LS_adminCompanyView.company },
  loaded: LS_adminCompanyView.loaded,
}), {
  getCompanyById: LS_AdminCompanyEntity.actions.getCompanyById,
  resetCompany: LS_AdminCompanyEntity.actions.resetCompany,
  deleteCompany: LS_AdminCompanyEntity.actions.deleteCompany,
  changeCompanyActivityStatus: LS_AdminCompanyEntity.actions.changeCompanyActivityStatus,
})(ViewCompany);
