import generateEmptyQuestion from 'utils/custom/generateEmptyQuestion';

export default {
  DEFAULT_DATA: {
    game_def_template: null,
    company: null,
    name: '',
    introduction_title: '',
    introduction_text: '',
    introduction_vimeo_id: '',
    instruction_title: '',
    instruction_text: '',
    instruction_vimeo_id: '',
    storyline_title: '',
    storyline_text: '',
    storyline_vimeo_id: '',
    storyline: null,
    debriefing_title: '',
    debriefing_text: '',
    debriefing_vimeo_id: '',
    reset_title: '',
    reset_text: '',
    reset_vimeo_id: '',
    time_limit: null,
    final_code: '',
    is_final_code_only: false,
    is_online_only: false,
    is_dark_mode: false,
    questions: [
      generateEmptyQuestion(1),
    ],
  },
  DEFAULT_FORMS_ERRORS: {
    basicInformation: null,
    questions: null,
    introduction: null,
    instruction: null,
    storyline: null,
    debriefing: null,
    reset: null,
  },
};
