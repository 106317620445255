/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';
import { Wrapper as Img } from 'components/blocks/ui/Img/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px 0;
`;

export const Warning = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${COLORS_CONFIG.ORANGE};
  font-size: 15px;
  line-height: 1.4;
  & ${Img} {
    margin-right: 5px;
  }
`;
