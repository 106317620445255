/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';

import { Wrapper as Select } from 'components/ui/Select/styles';

export const AssignModal = styled.div`
  padding: 40px 48px 38px 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  & ${Select} {
    margin-bottom: 30px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  align-self: flex-start;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  margin-bottom: 30px;
`;
