/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Button } from 'components';

/* Styles */

import * as Styled from './styles';

function FormActions({
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  isSubmitButtonEnable,
  loading,
}) {
  return (
    <Styled.Wrapper>
      {onSubmit && <Button isLoading={loading} disabled={!isSubmitButtonEnable} onClick={onSubmit}>{submitText}</Button>}
      {onCancel && <Button cancel onClick={onCancel}>{cancelText}</Button>}
    </Styled.Wrapper>
  );
}

/* FormActions type of props */

FormActions.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  isSubmitButtonEnable: PropTypes.bool,
};

FormActions.defaultProps = {
  onSubmit: null,
  onCancel: null,
  submitText: 'Submit',
  cancelText: 'Cancel',
  isSubmitButtonEnable: true,
};

export default FormActions;
