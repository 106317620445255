import { useState, useEffect } from 'react';

export default () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateDimensions = () => setWidth(window.innerWidth);

    setWidth(window.innerWidth);
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return width;
};
