/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function RemoveButton({ onClick, isActive }) {
  return (
    <Styled.Button onClick={onClick}>
      <Styled.Icon isActive={isActive}>x</Styled.Icon>
      <Styled.Label>Remove</Styled.Label>
    </Styled.Button>
  );
}

/* RemoveButton type of props */

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

/* RemoveButton default props */

RemoveButton.defaultProps = {};

export default RemoveButton;
