import { SESSION_STATUSES } from '_constants';
import * as TYPES from './AdminCompany.types';
import AuthEntity from '../Auth';


const initialState = {
  loading: false,
  campaigns: [],
  gameSessions: [],
  kits: [],
  gameDefs: [],
  locations: [],
  company: {},
  users: [],
  allActiveCampaigns: [],
  leaderboard: [],
  daysWithGameSession: [],
  activityReport: [],
  companyParticipants: {},
  campaignParticipants: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_CAMPAIGNS:
    case TYPES.GET_CAMPAIGN:
    case TYPES.GET_CAMPAIGN_WITH_POSITION:
    case TYPES.GET_GAME_SESSIONS:
    case TYPES.GET_KITS:
    case TYPES.GET_KIT:
    case TYPES.GET_GAME_DEFS:
    case TYPES.GET_GAME_DEF:
    case TYPES.GET_LOCATIONS:
    case TYPES.GET_LOCATION:
    case TYPES.ADD_NEW_LOCATION:
    case TYPES.DELETE_GAME_SESSION:
    case TYPES.ADD_GAME_SESSION:
    case TYPES.ADD_MULTIPLE_GAME_SESSIONS:
    case TYPES.EDIT_GAME_SESSION:
    case TYPES.ADD_CAMPAIGN:
    case TYPES.GET_COMPANY:
    case TYPES.GET_USERS:
    case TYPES.ADD_USER:
    case TYPES.GET_LEADERBOARD:
    case TYPES.GET_COMPANY_PARTICIPANTS:
    case TYPES.GET_CAMPAIGN_PARTICIPANTS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_CAMPAIGNS_FAIL:
    case TYPES.GET_CAMPAIGN_FAIL:
    case TYPES.GET_CAMPAIGN_WITH_POSITION_FAIL:
    case TYPES.GET_GAME_SESSIONS_FAIL:
    case TYPES.GET_KITS_FAIL:
    case TYPES.GET_KIT_FAIL:
    case TYPES.GET_GAME_DEFS_FAIL:
    case TYPES.GET_GAME_DEF_FAIL:
    case TYPES.GET_LOCATIONS_FAIL:
    case TYPES.GET_LOCATION_FAIL:
    case TYPES.ADD_NEW_LOCATION_FAIL:
    case TYPES.DELETE_GAME_SESSION_FAIL:
    case TYPES.ADD_GAME_SESSION_FAIL:
    case TYPES.EDIT_GAME_SESSION_FAIL:
    case TYPES.ADD_CAMPAIGN_FAIL:
    case TYPES.GET_COMPANY_FAIL:
    case TYPES.GET_USERS_FAIL:
    case TYPES.ADD_USER_FAIL:
    case TYPES.GET_LEADERBOARD_FAIL:
    case TYPES.GET_COMPANY_PARTICIPANTS_FAIL:
    case TYPES.GET_CAMPAIGN_PARTICIPANTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.result.results,
        loading: false,
      };

    case TYPES.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.concat(action.result),
        loading: false,
      };

    case TYPES.GET_CAMPAIGN_WITH_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_GAME_SESSIONS_SUCCESS:
      return {
        ...state,
        gameSessions: action.result.results,
        campaigns: action.payload.isScheduledSessions
          ? state.campaigns.map(campaign => (campaign.id === action.payload.campaignId
            ? {
              ...campaign,
              sessions_scheduled: action.result.count,
            }
            : campaign))
          : state.campaigns,
        loading: false,
      };

    case TYPES.GET_GAME_SESSION_SUCCESS:
      return {
        ...state,
        gameSessions: state.gameSessions.map(
          gameSession => (gameSession.id === action.result.id
            ? {
              ...action.result,
            }
            : gameSession),
        ),
        loading: false,
      };

    case TYPES.GET_KITS_SUCCESS:
      return {
        ...state,
        kits: action.result.results,
        loading: false,
      };

    case TYPES.GET_KIT_SUCCESS:
      return {
        ...state,
        kits: state.kits.concat(action.result),
        loading: false,
      };

    case TYPES.REMOVE_KIT:
      return {
        ...state,
        kits: state.kits.filter(({ id }) => id !== action.payload.id),
      };

    case TYPES.GET_GAME_DEFS_SUCCESS:
      return {
        ...state,
        gameDefs: action.result.results,
        loading: false,
      };

    case TYPES.GET_GAME_DEF_SUCCESS:
      return {
        ...state,
        gameDefs: state.gameDefs.concat(action.result),
        loading: false,
      };

    case TYPES.REMOVE_GAME_DEF:
      return {
        ...state,
        gameDefs: state.gameDefs.filter(({ id }) => id !== action.payload),
      };

    case TYPES.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.result.results,
        loading: false,
      };

    case TYPES.GET_LOCATION_SUCCESS:
      return {
        ...state,
        locations: state.locations.concat(action.result),
        loading: false,
      };

    case TYPES.ADD_NEW_LOCATION_SUCCESS:
      return {
        ...state,
        locations: state.locations.concat(action.result),
      };

    case TYPES.DELETE_GAME_SESSION_SUCCESS: {
      const deletedSession = state.gameSessions.find(({ id }) => id === action.sessionId)
        || state.leaderboard.find(({ game_session: gameSessionId }) => gameSessionId === action.sessionId);
      const isCompletedSession = action.payload.status === SESSION_STATUSES.NAME.COMPLETED;
      const deletedSignupParticipants = deletedSession.signedup_participants
        ? deletedSession.signedup_participants.length
        : deletedSession.signedup_participants_count;
      const deletedActualParticipants = deletedSession.actual_participants
        ? deletedSession.actual_participants.length
        : deletedSession.actual_participants_count;

      return {
        ...state,
        campaigns: state.campaigns.map(campaign => (campaign.id === action.campaignId
          ? {
            ...campaign,
            [`sessions_${action.payload.status}`]: campaign[`sessions_${action.payload.status}`] - 1,
            participants_registered: campaign.participants_registered - deletedSignupParticipants,
            participants_actual: campaign.participants_actual - deletedActualParticipants,
            completed_sessions_participants_per_session_sum: isCompletedSession
              ? campaign.completed_sessions_participants_per_session_sum - deletedSession.participants_per_session
              : campaign.completed_sessions_participants_per_session_sum,
          }
          : campaign)),
        loading: false,
      };
    }

    case TYPES.ADD_MULTIPLE_GAME_SESSIONS_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign => (campaign.id === action.payload.campaign
          ? {
            ...campaign,
            sessions_scheduled: campaign.sessions_scheduled + action.result.created_session_count,
          }
          : campaign
        )),
        loading: false,
      };

    case TYPES.ADD_GAME_SESSION_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign => (campaign.id === action.result.campaign
          ? {
            ...campaign,
            sessions_scheduled: campaign.sessions_scheduled + 1,
            participants_registered: action.result.auto_signup
              ? campaign.participants_registered + action.result.signedup_participants.length
              : campaign.participants_registered,
          }
          : campaign)),
        loading: false,
      };

    case TYPES.EDIT_GAME_SESSION_SUCCESS:
      return {
        ...state,
        gameSessions: state.gameSessions.map(
          gameSession => (gameSession.id === action.result.id
            ? {
              ...gameSession,
              ...action.result,
            }
            : gameSession),
        ),
        campaigns: action.payload.isParticipantsListChanged
          ? state.campaigns.map(campaign => (campaign.id === action.result.campaign
            ? {
              ...campaign,
              participants_registered: campaign.participants_registered + (
                action.result.signedup_participants.length - state.gameSessions.find(
                  gameSession => gameSession.id === action.result.id,
                ).signedup_participants.length
              ),
            }
            : campaign))
          : state.campaigns,
        loading: false,
      };

    case TYPES.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign => (campaign.id === action.id
          ? {
            ...campaign,
            ...action.result,
          }
          : campaign)),
        loading: false,
      };

    case TYPES.ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.result,
        loading: false,
      };

    case TYPES.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          ...action.result,
        },
        loading: false,
      };

    case TYPES.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.result.results,
        loading: false,
      };

    case TYPES.GET_ALL_ACTIVE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        allActiveCampaigns: action.result.results,
        loading: false,
      };

    case TYPES.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user => (user.id === action.id
          ? {
            ...user,
            ...action.result,
          }
          : user)),
        loading: false,
      };

    case TYPES.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(({ id }) => id !== action.id),
        loading: false,
      };

    case TYPES.GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.result.results,
        loading: false,
      };

    case TYPES.ADD_DAY_WITH_GAME_SESSION:
      return {
        ...state,
        daysWithGameSession: state.daysWithGameSession.concat(action.payload.date),
      };
    case TYPES.GET_DAYS_WITH_GAME_SESSION_SUCCESS:
      return {
        ...state,
        daysWithGameSession: action.result,
        loading: false,
      };

    case AuthEntity.types.LOGOUT:
      return {
        ...initialState,
      };
    case TYPES.GET_COMPANY_REPORTS_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityReport: action.result,
      };
    case TYPES.GET_COMPANY_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        companyParticipants: action.result,
      };
    case TYPES.GET_CAMPAIGN_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        campaignParticipants: action.result,
      };
    case TYPES.RESET_COMPANY:
      return {
        ...state,
        company: {},
      };
    default:
      return state;
  }
};

export default reducer;
