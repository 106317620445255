/* Libs */
import styled, { css } from 'styled-components';

import { Wrapper as ListWrapper, Value, Name } from 'components/blocks/ListItems/GameStatistic/styles';
import { COLORS_CONFIG } from 'config';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & ${ListWrapper}:not(:first-child) {
    margin-top: 20px;
  }
  & ${ListWrapper}:last-child {
    border:none;
    padding-bottom: 0;
    & ${Value} {
      font-size: 24px;
    }
  }
  
  ${({ isDarkMode }) => isDarkMode && css`
        & ${Value}, ${Name}{
          color: ${COLORS_CONFIG.WHITE};
        }
        
        & ${ListWrapper} {
          border-bottom: 1px solid ${COLORS_CONFIG.OXFORD_BLUE};
        }
  `}
`;
