/* Libs */
import React, { PureComponent } from 'react';
import Transition from 'react-transition-group/Transition';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/* Components */
// import { SvgIcon } from 'components';

/* Styles */
import * as Styled from './styles';

// ----------------
export default class Modal extends PureComponent {
  // Type of props
  static propTypes = {
    // onlyContentCloseClick: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    titleAdditionalComponent: PropTypes.node,
    additionalComponentAlign: PropTypes.oneOf(['left', 'right']),
    withoutCloseCross: PropTypes.bool,
    backgroundColor: PropTypes.string,
  };

  // Default value for props
  static defaultProps = {
    title: null,
    titleAdditionalComponent: null,
    additionalComponentAlign: 'right',
    onClose: () => { },
    withoutCloseCross: false,
  };

  // Data
  element = null;

  // Bind methods
  onEscape = this.onEscape.bind(this);

  // -------- Methods --------

  // Life cycle
  componentDidMount() {
    document.addEventListener('keydown', this.onEscape);
    this.element = document.querySelector('body');
    this.forceUpdate();
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.element.classList.add('modal-open');
    } else {
      this.element.classList.remove('modal-open');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape);
    this.element.classList.remove('modal-open');
  }

  onEscape({ keyCode }) {
    if (this.props.onClose && this.props.open && keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const {
      // onlyContentCloseClick,
      children,
      onClose,
      title,
      style,
      open,
      titleAdditionalComponent,
      additionalComponentAlign,
      backdropColor,
      withoutCloseCross,
      backgroundColor,
      crossColor,
      withoutScroll,
    } = this.props;

    if (!this.element) return null;

    return ReactDOM.createPortal(
      <Transition
        in={open}
        timeout={300}
      >
        {state => state !== 'exited' && (
          <Styled.OverallModalWrapper backgroundColor={backgroundColor} state={state}>
            <Styled.Backdrop
              // onClick={!onlyContentCloseClick ? onClose : null}
              backdropColor={backdropColor}
            />
            <Styled.Modal withoutScroll={withoutScroll} style={style}>
              {
                (title || titleAdditionalComponent) && (
                  <Styled.ModalHeader
                    aca={additionalComponentAlign}
                    hasAdd={titleAdditionalComponent}
                  >
                    {title && <Styled.Title>{title}</Styled.Title>}
                    {titleAdditionalComponent}
                  </Styled.ModalHeader>
                )
              }
              {
                !withoutCloseCross && (
                  <Styled.CancelIconWrapper onClick={onClose} crossColor={crossColor} />
                )
              }
              {children}
            </Styled.Modal>
          </Styled.OverallModalWrapper>
        )
        }
      </Transition>,
      this.element,
    );
  }
}
