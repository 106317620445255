const NAME = {
  SCHEDULED: 'scheduled',
  STARTED: 'start',
  COMPLETED: 'completed',
  STOPPED: 'stopped',
};

const LIST = [
  NAME.SCHEDULED,
  NAME.STARTED,
  NAME.COMPLETED,
  NAME.STOPPED,
];


export default {
  NAME,
  LIST,
};
