/* Libs */
import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.img`
  height: 100%;
`;
