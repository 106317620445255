/* Libs */
import styled from 'styled-components';

/* Styles */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

/* Styles */

import { Table } from 'components/blocks/Table/styles';
import { Cell as ActionCell } from 'components/blocks/TableCells/ActionsCell/styles';


export const Participants = styled.div`
  margin-top: 15px;
  width: 100%;

  & ${Table} {
    & .rt-td {
      width: 29%;
      &:last-child {
        width: 10%;
      }
    } 
  }
`;
