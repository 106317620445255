/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function ParticipantSignupStruct({ children }) {
  return (
    <Styled.Wrapper>
      {children}
    </Styled.Wrapper>
  );
}

ParticipantSignupStruct.propTypes = {
  children: PropTypes.node,
};

ParticipantSignupStruct.defaultProps = {
  children: null,
};

export default ParticipantSignupStruct;
