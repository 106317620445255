/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

// import {} from 'utils';

/* Components */

import {
  AnswerListItem,
} from 'components';

/* Constants */

// import {} from 'constants';

/* Styles */

import * as Styled from './styles';

function AnswerList({
  answers,
  handleAnswer,
  isDarkMode,
}) {
  return (
    <Styled.Wrapper>
      { answers.map(answer => (
        <AnswerListItem
          handleAnswer={handleAnswer(answer, answer.is_correct)}
          clicked={answer.clicked}
          answer={answer.answer}
          status={answer.status}
          key={answer.answer}
          isDarkMode={isDarkMode}
        />
      ))}
    </Styled.Wrapper>
  );
}

/* AnswerList type of props */

AnswerList.propTypes = {};

/* AnswerList default props */

AnswerList.defaultProps = {};

export default AnswerList;
