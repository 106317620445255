import { COMMON_ERRORS, QUESTION_ERRORS } from '_constants/errors';
import { isEmpty, isMoreThan, isNull } from 'utils/validation';

export default ({
  value,
  anotherQuestions,
  name = 'name',
  max = 500,
  customErrors = {},
  initial = false,
  required = true,
  regex = null,
}) => {
  let error = (required && isEmpty(value) && COMMON_ERRORS.isRequired(name))
    || (!isNull(max) && isMoreThan(value, max, true) && COMMON_ERRORS.lessThan(name, max))
    || (!isNull(regex) && !regex.test(value) && COMMON_ERRORS.onlyDigitsAndLetters)
    || (!!anotherQuestions.find(question => value === question.name) && QUESTION_ERRORS.nameMustBeUniq);
  error = error && initial ? null : error;
  return error;
};
