import { LS_AdminCompanyEntity } from '_entities';
import * as TYPES from './Kits.types';

const initialState = {
  loading: false,
  kits: [],
  total: 0,
  loaded: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LS_AdminCompanyEntity.types.RESET_COMPANY_STATE:
      return {
        ...initialState,
      };
    case TYPES.ADD_COMPANY_KIT:
    case TYPES.EDIT_COMPANY_KIT:
    case TYPES.GET_COMPANY_KITS:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_COMPANY_KIT_SUCCESS:
      return {
        ...state,
        kits: [{
          ...action.result,
          storyline: action.payload.storyline,
        },
        ...state.kits],
        total: state.total + 1,
        loading: false,
      };
    case TYPES.EDIT_COMPANY_KIT_SUCCESS:
      return {
        ...state,
        kits: state.kits.map(kit => (kit.id === action.payload.oldId
          ? { ...kit, ...action.result, storyline: action.payload.storyline }
          : kit)),
        loading: false,
      };
    case TYPES.GET_COMPANY_KITS_SUCCESS:
      return {
        ...state,
        kits: action.result.results,
        total: action.result.count,
        loaded: true,
        loading: false,
      };
    case TYPES.ADD_COMPANY_KIT_FAIL:
    case TYPES.EDIT_COMPANY_KIT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_COMPANY_KITS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
};

export default reducer;
