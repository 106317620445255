/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


const DIRECTIONS = {
  top: 225,
  left: 135,
  right: 315,
  bottom: 45,
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_5};
  border-radius: 4px;
  width: 64px;
  height: 64px;
  background-color: ${COLORS_CONFIG.WHITE};
  text-align: center;
  font-size: 34px;
  line-height: 64px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Arrow = styled.div`
  &::before{
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid ${COLORS_CONFIG.BLACK};
    border-right: 1px solid ${COLORS_CONFIG.BLACK};
    ${({ onClick }) => typeof onClick !== 'function' && css`
      border-bottom-color: ${COLORS_CONFIG.SILVER_CUSTOM_6};
      border-right-color: ${COLORS_CONFIG.SILVER_CUSTOM_6};
      cursor: not-allowed;
    `}
    ${({ direction }) => css`
    transform: translate(-50%, -50%) ${`rotate(${DIRECTIONS[direction]}deg)`};
  `}  
  }
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  ${({ onClick }) => typeof onClick !== 'function' && css`
    cursor: not-allowed;
  `}
`;
