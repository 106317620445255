import React, { useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import mergeImages from 'merge-images';

import {
  NOTIFICATIONS,
  FILE_FORMATS,
  FILE_SIZES,
} from '_constants';

import {
  GameEntity,
} from '_entities';

import {
  fileToBase64,
  // Will be used later
  // getCorrectDomainURL,
} from 'utils/transformers';
import {
  getError,
} from 'utils/custom';
import { notification } from 'utils/services';

import {
  Button,
  Img,
} from 'components';

import {
  INPUT_FILE_WHITE,
  INPUT_FILE_DARK,
} from 'assets/icons';
// Will be used later
// import emirates from 'assets/emirates.jpg';
import * as Styled from './styles';


function TeamPhoto({
  isDarkMode,
  sendTeamPhoto,
  storylineImage,
  isAnonymous,
  gameSessionId,
  token,
  backToCongratulation,
}) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [requestImage, setRequestImage] = useState(null);
  const [isHighlighted, setHighlighted] = useState(false);
  const containerRef = useRef(null);

  const MAX_FILE_SIZE_IN_MB = 5;

  const acceptedFileTypes = [FILE_FORMATS.JPG, FILE_FORMATS.PNG];
  const acceptFiles = useMemo(() => acceptedFileTypes.join(','), [acceptedFileTypes]);

  const removeImage = () => setImage(null);

  const uploadFile = async (files) => {
    const file = files[0];
    const fileExtension = `.${file.name.split('.').pop()}`;
    const fileSize = file.size;

    if (!acceptedFileTypes.includes(fileExtension)) {
      return notification.error(NOTIFICATIONS.WRONG_FILE_TYPE);
    }

    if (fileSize > (MAX_FILE_SIZE_IN_MB * FILE_SIZES.MB)) {
      return notification.error(NOTIFICATIONS.TOO_BIG_FILE(MAX_FILE_SIZE_IN_MB));
    }

    setRequestImage(file);
    setImage(await fileToBase64(file));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // library1
      // Will be used later
      // mergeImages(
      //   [
      //     image,
      //     // Will be used later
      //     // getCorrectDomainURL(storylineImage),
      //   ],
      //   {
      //     crossOrigin: 'anonymous',
      //   },
      // )
      //   .then(b64 => console.log('b64', b64));

      // library2
      html2canvas(containerRef.current).then(async (canvas) => {
        // Will be used later
        // document.body.appendChild(canvas);

        await sendTeamPhoto({
          token,
          // Will be used later
          // image: canvas.toDataURL('image/jpeg'),
          image: requestImage,
          gameSessionId,
        });

        setLoading(false);
        notification.success(
          isAnonymous
            ? NOTIFICATIONS.SUCCESS_SEND_MESSAGE_TO_HOST
            : NOTIFICATIONS.SUCCESS_SEND_MESSAGE_TO_PARTICIPANTS,
        );
        backToCongratulation();
      });
    } catch (error) {
      setLoading(false);
      notification.error(getError(error));
    }
  };

  const preventActions = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const enterElement = (e) => {
    preventActions(e);
    setHighlighted(true);
  };

  const handleDragEnter = (e) => {
    enterElement(e);
  };

  const handleDragOver = (e) => {
    enterElement(e);
  };

  const handleDragLeave = (e) => {
    preventActions(e);
    setHighlighted(false);
  };

  const handleDrop = (e) => {
    preventActions(e);
    setHighlighted(false);
    uploadFile(e.dataTransfer.files);
  };

  const handleInputChange = (e) => {
    const { target: { files } } = e;

    uploadFile(files);

    e.target.value = '';
  };

  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      {/* <img src={storylineImage} /> */}
      <Styled.Title>Add your team photo</Styled.Title>
      <Styled.Container
        ref={containerRef}
        // Will be used later
        // image={emirates}
        image={storylineImage}
      >
        <Styled.Content>
          {
            image && (
              <>
                <Styled.Cross onClick={removeImage}>x</Styled.Cross>
                <Styled.Preview>
                  <Img src={image} />
                </Styled.Preview>
              </>
            )
          }
          <Styled.Label
            isHighlighted={isHighlighted}
            isHidden={Boolean(image)}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <Styled.HiddenInput
              type="file"
              name="teamPhoto"
              accept={acceptFiles}
              onChange={handleInputChange}
            />
            <Styled.Icon src={isDarkMode ? INPUT_FILE_WHITE : INPUT_FILE_DARK} />
            <Styled.Text>
              Click to upload the team photo or drag it here
            </Styled.Text>
          </Styled.Label>
        </Styled.Content>
      </Styled.Container>
      <Button onClick={handleSubmit} isLoading={loading} disabled={loading || !image}>
        {`Send to ${isAnonymous ? 'host' : 'all participants'}`}
      </Button>
    </Styled.Wrapper>
  );
}

TeamPhoto.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  sendTeamPhoto: PropTypes.func.isRequired,
  storylineImage: PropTypes.string.isRequired,
  isAnonymous: PropTypes.bool.isRequired,
  gameSessionId: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  backToCongratulation: PropTypes.func.isRequired,
};

export default connect(({ game }) => ({
  storylineImage: game.storylineImage,
  isAnonymous: game.isAnonymous,
  gameSessionId: game.gameSessionId,
  token: game.token,
}), {
  sendTeamPhoto: GameEntity.actions.sendTeamPhoto,
})(TeamPhoto);
