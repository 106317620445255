/* Libs */
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */
import {
  Select,
  Input,
  CalendarHeader,
  CalendarExpanded,
  SignupCampaignTable,
  BigTitle,
  GoBackButton,
  ContainerLoader,
} from 'components';

/* Constants */
import { DATE_SETTINGS } from 'config';
import { PARTICIPANT_SIGNUP_TYPE } from '_constants';

/* Utils */
import { transformDataToOptions } from 'utils/custom';

/* Styles */
import * as Styled from './styles';

const DEFAULT_DATA = {
  email: '',
  location: null,
  date: new Date(),
};

function BookSession({
  campaignName,
  participantEmail,
  onBackClick,
  campaignGameSessions,
  inviteType,
  gameSession,
  bookSession,
  loading,
  openBookForTeam,
  isEmailDisabled,
}) {
  const isSignupMount = useRef(false);
  const sidebarRef = useRef(null);
  const [data, setData] = useState({
    ...DEFAULT_DATA,
    email: participantEmail,
  });
  const [locations, setLocations] = useState([]);
  const [calendarSessions, setCalendarSessions] = useState([]);
  const [tableSessions, setTableSessions] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const filterCalendarSessions = () => {
    setCalendarLoading(true);
    setCalendarSessions(
      campaignGameSessions.reduce(
        (sessions, { location: { id }, start_time: startTime }) => (!data.location || id === data.location
          ? sessions.concat(moment(startTime).utc().format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR))
          : sessions),
        [],
      ),
    );
    setCalendarLoading(false);
  };

  const filterTableSessions = () => {
    setTableLoading(true);
    setTableSessions(
      campaignGameSessions.filter(
        ({ location: { id }, start_time: startTime }) => (!data.location || id === data.location)
          && moment(moment(startTime).utc().format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR)).isSame(data.date, 'day'),
      ),
    );
    setTableLoading(false);
  };

  const handleChange = useCallback(
    ({ target: { name, value } }) => setData(prevData => ({ ...prevData, [name]: value })),
    [],
  );

  const handleDayChange = ({ target: { name, value } }) => {
    handleChange({ target: { name, value } });
    sidebarRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const moveToNextMonth = useCallback(
    () => handleChange({
      target: {
        name: 'date',
        value: new Date(moment(data.date).add(1, 'M')),
      },
    }),
    [data.date],
  );

  const moveToPrevMonth = useCallback(
    () => handleChange({
      target: {
        name: 'date',
        value: new Date(moment(data.date).subtract(1, 'M')),
      },
    }),
    [data.date],
  );

  const moveToNextDay = useCallback(
    () => handleChange({
      target: {
        name: 'date',
        value: new Date(moment(data.date).add(1, 'd')),
      },
    }),
    [data.date],
  );

  const moveToPrevDay = useCallback(
    () => handleChange({
      target: {
        name: 'date',
        value: new Date(moment(data.date).subtract(1, 'd')),
      },
    }),
    [data.date],
  );

  const actions = useMemo(
    () => ({
      bookSession,
      openBookForTeam,
    }),
    [bookSession, openBookForTeam],
  );

  const locationOptions = useMemo(
    () => transformDataToOptions({
      data: locations,
      value: 'id',
      label: 'location',
    }),
    [locations],
  );

  useEffect(() => {
    if (inviteType === PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_EDIT) {
      setData({
        ...data,
        date: new Date(
          moment(gameSession.start_time)
            .utc()
            .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        ),
      });
    }

    setLocations(
      campaignGameSessions.reduce(
        (result, { location }) => (result.find(({ id }) => id === location.id)
          ? result
          : result.concat(location)),
        [],
      ),
    );
    setCalendarSessions(
      campaignGameSessions.map(
        ({ start_time: startTime }) => moment(startTime).utc().format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR),
      ),
    );
  }, []);

  useEffect(() => {
    filterTableSessions();
  }, [data.date, data.location]);

  useEffect(() => {
    if (!isSignupMount.current) {
      isSignupMount.current = true;
      return;
    }

    filterCalendarSessions();
  }, [data.location]);

  return (
    <Styled.Wrapper>
      {
        loading && <ContainerLoader friendlyMode />
      }
      <Styled.Sidebar>
        <Input
          type="email"
          name="email"
          title="Email"
          placeholder="Email address"
          value={participantEmail}
          isDisabled
        />
        <Select
          name="location"
          title="Location"
          placeholder="Select location"
          onChange={handleChange}
          value={data.location}
          options={locationOptions}
        />
        <CalendarHeader
          date={data.date}
          onBackClick={moveToPrevMonth}
          onForwardClick={moveToNextMonth}
        />
        <CalendarExpanded
          name="date"
          value={data.date}
          events={calendarSessions}
          onDayChange={handleDayChange}
        />
      </Styled.Sidebar>
      <Styled.Content ref={sidebarRef}>
        {!isEmailDisabled && (
          <GoBackButton onClick={onBackClick}>Back</GoBackButton>
        )}
        <BigTitle>{campaignName}</BigTitle>
        <CalendarHeader
          date={data.date}
          onBackClick={moveToPrevDay}
          onForwardClick={moveToNextDay}
          format={DATE_SETTINGS.FORMAT.DAY_MONTH_DATE}
        />
        <Styled.Note>
          Please note timezone before selecting.
        </Styled.Note>
        <SignupCampaignTable
          data={tableSessions}
          actions={actions}
          date={data.date}
          loading={calendarLoading || tableLoading}
        />
      </Styled.Content>
    </Styled.Wrapper>
  );
}

BookSession.propTypes = {
  campaignName: PropTypes.string,
  campaignGameSessions: PropTypes.arrayOf(PropTypes.shape({})),
  isEmailDisabled: PropTypes.bool,
};

BookSession.defaultProps = {
  campaignName: 'No campaign name',
  campaignGameSessions: [],
  isEmailDisabled: false,
};

export default BookSession;
