/* Libs */
import styled from 'styled-components';

/* Constants */
import { BREAKPOINTS } from '_constants';

/* Components */
import { Cell } from 'components/blocks/TableCells/SimpleTextCell/styles';


export const LicensingTable = styled.div`
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    ${Cell} {
      font-size: 15px;
    }
  }
`;
