/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VimeoPlayer from '@livingsecurity/react-vimeo';

/* Utils */

import { isEmpty } from 'utils/validation';

/* Components */

import { BigTitle, Button } from 'components';

/* Constants */

import { GAME_STEP_TITLES } from '_constants';

/* Styles */

import * as Styled from './styles';

function Reset({
  hasBackStep,
  vimeoId,
  title,
  text,
  next,
  back,
  isDarkMode
}) {
  const [videoNotFound, setVideoNotFound] = useState(isEmpty(vimeoId));
  if (isEmpty(text) && isEmpty(vimeoId) && isEmpty(title)) next();
  if (videoNotFound && isEmpty(text) && isEmpty(title)) next();
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <BigTitle>
        {title || GAME_STEP_TITLES.reset}
      </BigTitle>
      <Styled.Content>
        {
          videoNotFound
            ? <div dangerouslySetInnerHTML={{ __html: text }} />
            : (
              <VimeoPlayer
                videoId={vimeoId}
                autoplay
                onError={e => setVideoNotFound(true)}
              />
            )
        }
      </Styled.Content>
      <Styled.Actions>
        {
          hasBackStep
          && (
            <Button
              onClick={back}
            >
              {' '}
Back
              {' '}
            </Button>
          )
        }
        <Button
          onClick={next}
        >
          {' '}
Exit
          {' '}
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* Reset type of props */

Reset.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  back(props, propName, componentName) {
    if (props.hasBackStep && typeof props[propName] !== 'function') {
      return new Error(
        `Prop ${propName} should be function if component ${componentName} has previous step.`,
      );
    }
  },
  hasBackStep: PropTypes.bool.isRequired,
};

Reset.defaultTypes = {
  title: '',
};

export default Reset;
