/* Libs */
import styled, { css, keyframes } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  width: ${({ large }) => (large ? '280px' : '200px')};
`;

const skewww = keyframes`
  from {
    transform: skewY(-4deg) scale(1);
  }
  50% {
    transform: skewY(4deg) scale(1.2);
  }
  to {
    transform: skewY(-4deg) scale(1);
  }
`;
export const Time = styled.div`
  font-size: 40px;
  color: ${COLORS_CONFIG.WHITE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  ${({ isAlmostExpired }) => isAlmostExpired && css`
    color: ${COLORS_CONFIG.ORANGE}!important;
    animation: ${skewww} 0.5s linear infinite;
  `}
`;
