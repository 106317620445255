/* Libs */
import styled from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import {
  Button,
} from 'components/ui/Button/styles';
import { Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import { Wrapper as IconTooltip } from 'components/blocks/ui/IconTooltip/styles';
import BREAKPOINTS from '_constants/BREAKPOINTS';

export const Wrapper = styled.div`
  padding: 15px 40px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & ${SelectWrapper} {
    & .ReactSelect {
      &__control {
        border-radius: 0px;
        border-color: ${COLORS_CONFIG.SKY_BLUE};
      }
      &__menu {
        border-radius: 0px;
        border:1px solid ${COLORS_CONFIG.SKY_BLUE};
        box-shadow: initial;
      }
    }
  }
`;

export const InputLabel = styled.div`
  color: ${COLORS_CONFIG.DARK_GREY};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 18px;
  margin-bottom: 15px;
  width: max-content;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
     margin-bottom: 10px;
  }
  
  ${IconTooltip} {
    transform: translateY(-2px);
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:not(:first-child){
    margin-top: 24px;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > *:first-child {
    flex: 1;
  }
  & > *:last-child {
    margin-left: 24px;
    flex: 1;
  }
`;

export const Actions = styled.div`
  height: 150px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & ${Button} {
    width: 193px;
  }
  & ${Button}:last-child {
    margin-top: 9px;
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    background-color: ${COLORS_CONFIG.TRANSPARENT};
  }
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  background-color: ${COLORS_CONFIG.TRANSPARENT};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};  
`;
