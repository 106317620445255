/* Libs */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import {
  GameDefEntity,
} from '_entities';

/* Utils */
import { isEmpty, isEqual } from 'lodash';
import { notification } from 'utils/services';
import {
  validateGameDefForm,
} from 'utils/validation';
import {
  getError,
  getViewCompanyLink,
  redirect,
  exportCSV,
  deepCopyObject,
} from 'utils/custom';

/* Components */
import {
  GameDefinitionForm,
} from 'components';

/* Config */

import { URLS_CONFIG } from 'config';

/* Constants */

import {
  NOTIFICATIONS,
  GAME_DEF_FORM,
  GAME_DEF_FORM_DATA,
} from '_constants';

const DEFAULT_FORM_DATA = Object.freeze({
  data: { ...GAME_DEF_FORM_DATA.DEFAULT_DATA },
  errors: { ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS },
});

const PAGE_TITLE = 'Edit Game Definition';

const EditGameDef = ({
  updateCompanyGameDef,
  downloadGameDef,
  getGameDefById,
  deleteGameDef,
  match: { params: { id } },
}) => {
  const [formData, setFormData] = useState({ ...deepCopyObject(DEFAULT_FORM_DATA) });
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  const formChanged = useMemo(() => {
    if (fetchedData && fetchedData.is_final_code_only && formData.data.is_final_code_only) {
      return !isEqual({
        ...fetchedData,
        questions: null,
      }, {
        ...formData.data,
        questions: null,
      });
    }
    return !isEqual(fetchedData, formData.data);
  }, [fetchedData, formData.data]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const requestedData = {
        ...formData.data,
        questions: formData.data.is_final_code_only ? [] : formData.data.questions,
        game_def_template: isEmpty(formData.data.game_def_template) ? null : formData.data.game_def_template.id,
      };
      await updateCompanyGameDef(requestedData);
      redirect(getViewCompanyLink(formData.data.company), NOTIFICATIONS.SUCCESS_UPDATE_GAME_DEF);
    } catch (error) {
      const newError = getError(error);
      if (error.response.data.name) {
        setFormData(prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            name: newError,
          },
        }));
      }
      notification.error(newError);
      setLoading(false);
    }
  }, [formData.data, updateCompanyGameDef]);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await deleteGameDef(formData.data.id);
      redirect(getViewCompanyLink(formData.data.company), NOTIFICATIONS.SUCCESS_DELETE_GAME_DEF);
    } catch (error) {
      notification.error(getError(error));
      setLoading(false);
    }
  }, [deleteGameDef, formData.data.company, formData.data.id]);

  const loadGameDef = useCallback(gameDefId => async function () {
    try {
      const gameDef = await downloadGameDef(gameDefId);
      exportCSV(gameDef, 'game-definition');
    } catch (error) {
      notification.error(getError(error));
    }
  }, [downloadGameDef]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getGameDefById(id);

      const newData = {
        ...response,
        questions: response.is_final_code_only
          ? deepCopyObject(GAME_DEF_FORM_DATA.DEFAULT_DATA.questions)
          : response.questions,
      };
      const newErrors = validateGameDefForm(newData);

      setFetchedData(deepCopyObject(response));
      setFormData({
        data: newData,
        errors: {
          ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS,
          ...newErrors,
        },
      });
      setLoading(false);
    })();
  }, []);

  return (
    <GameDefinitionForm
      title={PAGE_TITLE}
      formData={formData}
      setFormData={setFormData}
      loading={loading}
      setLoading={setLoading}
      handleSave={handleSave}
      handleDelete={handleDelete}
      loadGameDef={loadGameDef}
      companyId={formData.data.company}
      mode={GAME_DEF_FORM.MODE.EDIT}
      formChanged={formChanged}
      gameDefId={id}
      goBack={{
        enabled: true,
        text: 'Back to company',
        href: getViewCompanyLink(formData.data.company),
      }}
    />
  );
};

/* Page Url */

EditGameDef.path = URLS_CONFIG.admin.editGameDef;

/* Page Title */

EditGameDef.title = PAGE_TITLE;

EditGameDef.propTypes = {
  deleteGameDef: PropTypes.func.isRequired,
  downloadGameDef: PropTypes.func.isRequired,
  updateCompanyGameDef: PropTypes.func.isRequired,
  getGameDefById: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(null, {
  deleteGameDef: GameDefEntity.actions.deleteGameDef,
  downloadGameDef: GameDefEntity.actions.downloadGameDef,
  updateCompanyGameDef: GameDefEntity.actions.updateCompanyGameDef,
  importGameDef: GameDefEntity.actions.importGameDef,
  getGameDefById: GameDefEntity.actions.getGameDefById,

})(EditGameDef);
