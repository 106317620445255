/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import { BigTitle } from 'components/blocks/ui/BigTitle/styles';
import { Button } from 'components/ui/Button/styles';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > ${Button}{
    border: 1.5px solid ${COLORS_CONFIG.WHITE};
    color: ${COLORS_CONFIG.WHITE};
    background-color: ${COLORS_CONFIG.SKY_BLUE};
    width: 210px;
  }
  /* & > ${Button}:last-child{
    margin-top: 18px;
    border: none;
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  } */
  & ${BigTitle} {
    padding-bottom: 40px;
    color: ${COLORS_CONFIG.WHITE};
    text-align: center;
  }
`;
