/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Modal, SessionInfoSection } from 'components';


function SessionInfoModal({
  onClose,
  open,
  openEditSession,
  session,
}) {
  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ maxWidth: '467px' }}
    >
      <SessionInfoSection
        onClose={onClose}
        openEditSession={openEditSession}
        session={session}
      />
    </Modal>
  );
}

// Type of props
SessionInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openEditSession: PropTypes.func.isRequired,
};

export default SessionInfoModal;
