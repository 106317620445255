/* Libs */
import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  Input,
  Textarea,
  BigTitle,
  GameCardPreviewModal,
  PreviewCard,
} from 'components';

/* Utils */

import { validateText } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';

/* Styles */

import * as Styled from './styles';

const DEFAULT_ERRORS = {
  introduction_text: null,
  introduction_vimeo_id: null,
};

const Introduction = memo(({
  data,
  formName,
  handleChange,
}) => {
  const [errors, setErrors] = useState({ ...DEFAULT_ERRORS });
  const [previewOpen, setPreviewOpen] = useState(false);

  const onChange = useCallback(({ target: { name, value } }) => setErrors((prevErrors) => {
    const newErrors = { ...prevErrors };
    switch (name) {
      case 'introduction_text':
        newErrors[name] = validateText({
          name: 'Introduction text', value, required: false, max: 2000,
        });
        break;
      case 'introduction_vimeo_id':
        newErrors[name] = validateText({
          name: 'Introduction vimeo id', value, required: false, max: 500,
        });
        break;
    }
    handleChange(
      { target: { name, value } },
      { formName, isValid: formIsValid({ ...newErrors }) },
    );
    return newErrors;
  }),
  []);
  return (
    <Styled.Wrapper>
      <BigTitle>
        Introduction
      </BigTitle>
      <Styled.PreviewWrapper>
        <Styled.Preview
          onClick={() => setPreviewOpen(true)}
        >
          preview
        </Styled.Preview>
        <Input
          placeholder="Title"
          name="introduction_title"
          value={data.introduction_title}
          onChange={onChange}
          error={errors.introduction_title}
        />
      </Styled.PreviewWrapper>
      <Textarea
        placeholder="Add Message"
        name="introduction_text"
        value={data.introduction_text}
        onChange={onChange}
        error={errors.introduction_text}
      />
      <Input
        placeholder="Vimeo ID"
        name="introduction_vimeo_id"
        value={data.introduction_vimeo_id}
        onChange={onChange}
        error={errors.introduction_vimeo_id}
      />
      <GameCardPreviewModal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
      >
        <PreviewCard
          step="introduction"
          title={data.introduction_title}
          text={data.introduction_text}
          vimeoId={data.introduction_vimeo_id}
        />
      </GameCardPreviewModal>
    </Styled.Wrapper>
  );
});

/* Introduction type of props */

Introduction.propTypes = {
  data: PropTypes.shape({
    introduction_title: PropTypes.string.isRequired,
    introduction_text: PropTypes.string.isRequired,
    introduction_vimeo_id: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
};

export default Introduction;
