import { COMMON_ERRORS, ANSWER_ERRORS } from '_constants/errors';
import { isEmpty, isMoreThan, isNull } from 'utils/validation';

export default ({
  value: { answer },
  currentAnswerNumber,
  answers,
  name,
  max = null,
  initial = false,
  required = true,
  regex = null,
}) => {
  let error = (isEmpty(answer) && COMMON_ERRORS.isRequired(name))
    || (!isNull(max)
      && isMoreThan(answer, max, true)
      && COMMON_ERRORS.lessThan(name, max))
    || (!isNull(regex)
      && !regex.test(answer)
      && COMMON_ERRORS.onlyDigitsAndLetters)
    || (!!answers.find(
      (subAnswer, idx) => answer === subAnswer.answer && idx !== currentAnswerNumber,
    )
      && ANSWER_ERRORS.questionMustBeUniq);
  error = !required && isEmpty(answer) ? null : error;

  error = error && initial ? null : error;
  return error;
};
