/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function PariticipantsLaunch({
  children,
}) {
  return (
    <Styled.Wrapper>
      { children }
    </Styled.Wrapper>
  );
}

/* PariticipantsLaunch type of props */

PariticipantsLaunch.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PariticipantsLaunch;
