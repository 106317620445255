/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

/* Styles */
import { COLORS_CONFIG, AVAILABLE_COLORS } from 'config';
import * as Styled from './styles';

function Switcher({
  checked,
  onChange,
  checkedText,
  uncheckedText,
  checkedIcon,
  uncheckedIcon,
  onColor,
  offColor,
  onHandleColor,
  offHandleColor,
  disabled,
  ...rest
}) {
  return (
    <Styled.Wrapper>
      <Styled.Label>
        <Switch
          {...rest}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          uncheckedIcon={uncheckedIcon}
          checkedIcon={checkedIcon}
          onHandleColor={onHandleColor}
          offHandleColor={offHandleColor}
          className="react-switch"
          onColor={onColor}
          offColor={offColor}
        />
        {
          (checkedText || uncheckedText) && (
            <Styled.Text>
              {checked ? checkedText : uncheckedText}
            </Styled.Text>
          )
        }
      </Styled.Label>
    </Styled.Wrapper>
  );
}

/* Switcher type of props */

Switcher.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  checkedText: PropTypes.string,
  onColor: PropTypes.oneOf([...AVAILABLE_COLORS]),
  offColor: PropTypes.oneOf([...AVAILABLE_COLORS]),
  onHandleColor: PropTypes.oneOf([...AVAILABLE_COLORS]),
  offHandleColor: PropTypes.oneOf([...AVAILABLE_COLORS]),
  uncheckedText: PropTypes.string,
  checkedIcon: PropTypes.node,
  uncheckedIcon: PropTypes.node,
  disabled: PropTypes.bool,
};

/* Switcher default props */

Switcher.defaultProps = {
  checked: false,
  onChange: () => {},
  checkedText: null,
  uncheckedText: null,
  checkedIcon: false,
  uncheckedIcon: false,
  onColor: COLORS_CONFIG.SKY_BLUE,
  offColor: COLORS_CONFIG.SILVER,
  onHandleColor: COLORS_CONFIG.WHITE,
  offHandleColor: COLORS_CONFIG.WHITE,
  disabled: false,
};

export default memo(Switcher);
