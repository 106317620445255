import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ContainerLoader,
  LeaderboardTable,
  Pagination,
  TopPlacesSection,
} from 'components';
import { LEADERBOARD_TABLE, URLS_CONFIG } from 'config';
import {
  copyToClipboard,
  getError, getLeaderboardShareLink,
  redirectWithError,
} from 'utils/custom';
import { notification } from 'utils/services';
import { LeaderboardEntity } from '_entities';
import { NOTIFICATIONS } from '_constants';
import * as Styled from './styles';


function Leaderboard({
  isDarkMode,
  list,
  page,
  total,
  loaded,
  setPage,
  loading,
  topPlaces,
  campaignName,
  getTopPlaces,
  getLeaderboard,
  getCampaignNameById,
  token,
  campaignId,
}) {
  const [shareLink, setShareLink] = useState('');
  async function getAllLeaderboard({
    currentPage = page,
    userToken = token,
    campaign = campaignId,
  } = {}) {
    if (page < 0) return;
    try {
      const { results, count } = await getLeaderboard({ campaign, token: userToken, page: currentPage });
      if (!results.length && count) {
        setPage(1);
        getAllLeaderboard({ currentPage: 1 });
      }
    } catch (error) {
      notification.error(getError(error));
    }
  }

  useEffect(() => {
    loaded && getAllLeaderboard({ currentPage: page });
  }, [page]);

  useEffect(() => {
    setPage(1);
    setShareLink(getLeaderboardShareLink({ token, campaignId, isDarkMode }));
  }, []);

  useEffect(() => {
    if (!campaignId || !token) return;
    Promise.all([
      getTopPlaces({
        token,
        campaign: campaignId,
      }),
      getAllLeaderboard(),
      getCampaignNameById({
        id: campaignId,
      }),
    ]).catch(error => redirectWithError(URLS_CONFIG.auth.signin, error));
  }, [token, campaignId]);

  const handlePageChange = ({ selected }) => setPage(selected + 1);

  const handleCopy = () => {
    copyToClipboard(shareLink);
    notification.success(NOTIFICATIONS.SUCCESS_COPIED);
  };

  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <Styled.Header>
        <Styled.Title>
          <Styled.CompanyName>{campaignName}</Styled.CompanyName>
          {' - Leaderboard'}
        </Styled.Title>
        <Styled.Action>
          <Styled.Link>
            { shareLink }
          </Styled.Link>
          <Button onClick={handleCopy}>
            Copy Link
          </Button>
        </Styled.Action>
      </Styled.Header>
      <Styled.Content>
        {
          !loaded
            ? <ContainerLoader isDarkMode={isDarkMode} />
            : (
              <>
                <TopPlacesSection
                  first={topPlaces.first}
                  second={topPlaces.second}
                  third={topPlaces.third}
                  isDarkMode={isDarkMode}
                />
                <LeaderboardTable
                  isDarkMode={isDarkMode}
                  data={list}
                  loading={loading}
                  pagination={(
                    <Pagination
                      pageCount={total / LEADERBOARD_TABLE.PAGINATION.PER_PAGE}
                      pageChangeHandler={handlePageChange}
                      activePage={page}
                      isDarkMode={isDarkMode}
                    />
                  )}
                />
              </>
            )
        }
      </Styled.Content>
    </Styled.Wrapper>
  );
}


export default connect(({ leaderboard }) => ({
  list: leaderboard.list,
  page: leaderboard.page,
  total: leaderboard.total,
  loaded: leaderboard.loaded,
  loading: leaderboard.loading,
  topPlaces: leaderboard.topPlaces,
  campaignName: leaderboard.campaignName,
}), {
  setPage: LeaderboardEntity.actions.setPage,
  getTopPlaces: LeaderboardEntity.actions.getTopPlaces,
  getLeaderboard: LeaderboardEntity.actions.getLeaderboard,
  getCampaignNameById: LeaderboardEntity.actions.getCampaignNameById,
})(Leaderboard);
