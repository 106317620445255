/* Libs */
import styled from 'styled-components';

/* Constants */
import { BREAKPOINTS } from '_constants';


export const Wrapper = styled.div`
  padding: 0 16px;
  & > * {
    margin-top: 32px;
  }
  & > *:last-child {
    margin-top: 30px;
  }
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    & > * {
      margin-top: 30px;
    }
  }
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 0 32px;
  }
`;
