/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function CompanyAdminCompanyStruct({
  title,
  topRow,
  bottomRow,
}) {
  return (
    <Styled.CompanyStruct>
      {title && (
        <Styled.Title>{title}</Styled.Title>
      )}
      <Styled.TopRow>
        {topRow}
      </Styled.TopRow>
      <Styled.BottomRow>
        {bottomRow}
      </Styled.BottomRow>
    </Styled.CompanyStruct>
  );
}

/* CompanyAdminCompanyStruct type of props */

CompanyAdminCompanyStruct.propTypes = {
  title: PropTypes.string,
  topRow: PropTypes.node.isRequired,
  bottomRow: PropTypes.node.isRequired,
};

CompanyAdminCompanyStruct.defaultProps = {
  title: null,
};

export default CompanyAdminCompanyStruct;
