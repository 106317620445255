import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ToggleBar, ShareLink, ExportFile } from 'components';

import * as Styled from './styles';

const TAB_TYPES = {
  LINK: 'link',
  EXPORT: 'export',
};

export default function ShareLeaderboardSection({
  link,
  campaign,
  token,
}) {
  const [tabType, setTabType] = useState(TAB_TYPES.LINK);
  const [fileType, setFileType] = useState(null);
  const changeTab = ({ target: { value } }) => setTabType(value);
  const changeFileType = ({ target: { value } }) => setFileType(value);

  return (
    <Styled.Share>
      <ToggleBar
        name="tabType"
        onChange={changeTab}
        active={tabType}
        options={[
          TAB_TYPES.LINK,
          TAB_TYPES.EXPORT,
        ]}
      />
      {
        tabType === TAB_TYPES.LINK
          ? <ShareLink link={link} />
          : (
            <ExportFile
              onChange={changeFileType}
              value={fileType}
              token={token}
              campaign={campaign}
            />
          )
      }
    </Styled.Share>
  );
}

ShareLeaderboardSection.propTypes = {
  link: PropTypes.string,
  campaign: PropTypes.number,
  token: PropTypes.string,
};

ShareLeaderboardSection.deafultProps = {
  link: null,
  campaign: null,
  token: null,
};
