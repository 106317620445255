import styled, { css } from 'styled-components';

export const Circle = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  ${({ autoSize }) => autoSize && css`
    width: 60%;
    height: 60%;
  `}
  border-radius: 50%;
  border: 1.5px solid ${({ color }) => color};
`;

export default Circle;
