/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import {
  Modal,
  EditPOCForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function EditPOCModal({
  onClose,
  open,
  data,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Edit Point Of Contract"
      open={open}
    >
      <Styled.EditPOC>
        <EditPOCForm onClose={onClose} data={data} />
      </Styled.EditPOC>
    </Modal>
  );
}

// Type of props
EditPOCModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};
