import { validateGameDefField } from 'utils/validation';

export default (questions, finalCode, filledOnly) => questions.reduce((acc, question, index, self) => {
  const otherQuestions = self.filter(q => q.name !== question.name);
  Object.entries(question).forEach(([name, questionValue]) => {
    acc[`questions[${index}].${name}`] = filledOnly && !questionValue
      ? null
      : validateGameDefField({
        name,
        value: questionValue,
        section: 'questions',
        additionalData: {
          otherQuestions,
          finalCode,
        },
      });
  });
  return acc;
}, {});
