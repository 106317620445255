/* Libs */
import styled from 'styled-components';

import { Wrapper as ListItemWrapper } from 'components/blocks/ListItems/ManageGame/Participant/styles';

export const Wrapper = styled.div`
  & ${ListItemWrapper} {
    padding-top: 25px;
  }
`;
