import { COMMON_ERRORS } from '_constants/errors';
import { isEmpty, isValidDate } from 'utils/validation';

export default ({ value, name, required = true, initial = false }) => {
  let error =
    (isEmpty(value) && COMMON_ERRORS.isRequired('date')) ||
    (new Date(value).getFullYear() <= '1999' && COMMON_ERRORS.dateLessThan) ||
    (!isValidDate(value) && COMMON_ERRORS.wrongDate);
  error = !required && isEmpty(value) ? null : error;
  error = error && initial ? null : error;
  return error;
};
