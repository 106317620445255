/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { TextEllipsis } from 'assets/styles/mixins';

import { Wrapper as ToggleWrapper } from 'components/ui/ToggleBar/styles';
import { Wrapper as InputWrapper } from 'components/ui/SearchInput/styles';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  & ${InputWrapper} {
    border-radius: 3px;
    margin-left: 20px;
    width: 300px;
  }
`;

export const Title = styled.div`
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 40px;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  ${TextEllipsis};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
 
`;

export const Action = styled.div``;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const TopActions = styled(Actions)`
  flex-grow: 1;
  & > *:not(:first-child) {
    position: relative;
    padding: 0 16px;
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 24px;
      background-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
    }
  }
  & > *:first-child {
    padding-right: 10px;
  }
`;

export const BotActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  & > * {
    margin-right: 44px;
    &:last-child {
    margin-right: 0;
    }
  }
  & > ${ToggleWrapper} {
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    border: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & ${Title} {
    flex-grow: 1;
  }
`;

export const Top = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  top: 30px;
  width: calc( 100% - 80px );
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 72px 40px 50px 40px;
  min-height: 100vh;
`;

export const Content = styled.div`
  padding-top: 35px;
`;
