/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

// import {} from 'utils';

/* Components */

// import {} from 'components';

/* Constants */

// import {} from 'constants';

/* Styles */

import * as Styled from './styles';

function CardsHeader({
  title,
  actions,
}) {
  return (
    <Styled.Header>
      <Styled.Title>
        {title}
      </Styled.Title>
      <Styled.Actions>
        {
          actions.map(action => (action.action ? (
            <Styled.Action key={action.label} onClick={action.action}>
              {action.label}
            </Styled.Action>
          )
            : action))
        }
      </Styled.Actions>
    </Styled.Header>
  );
}

/* CardsHeader type of props */

CardsHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
    PropTypes.node,
  ])),
};

/* CardsHeader default props */

CardsHeader.defaultProps = {
  actions: [],
};

export default CardsHeader;
