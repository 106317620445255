/* Libs */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Components */
import {
  Select,
  Button,
  Loader,
  TimezoneSelect,
  // GoLabel,
} from 'components';

/* Constants */
import { COMMON_ERRORS } from '_constants/errors';
// import {
//   TRIANGLE_TYPES,
//   LABEL_COLOR_TYPES,
//   TIME_OPTIONS,
// } from '_constants';

/* Utils */
import {
  transformDataToOptions,
  getError,
  generateKitOptions,
  getTimezoneOffset,
  getTimezoneName,
} from 'utils/custom';
import { notification } from 'utils/services';

/* Styles */
import * as Styled from './styles';

const DEFAULT_DATA = {
  campaign: null,
  campaigns: [],
  kit: null,
  kits: [],
  gameDef: null,
  gameDefs: [],
  location: null,
  locations: [],
  timezone: null,
  introductionTime: null,
  debriefingTime: null,
};

function LaunchSessionForm({
  onSave,
  saveText,
  data: initialData,
  loadCampaigns,
  loadGameDefs,
  loadLocations,
  loadNewData,
  getInitialUserData,
  role,
  roles,
}) {
  const [data, changeData] = useState({
    ...DEFAULT_DATA,
    ...initialData,
  });
  const [loading, setLoading] = useState(false);
  // const [optionsAreVisible, setOptionsVisibility] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const {
    campaign,
    campaigns,
    kit,
    kits,
    gameDef,
    gameDefs,
    location,
    locations,
    timezone,
    introductionTime,
    debriefingTime,
  } = data;

  // const toggleOptionsVisibility = () => setOptionsVisibility(!optionsAreVisible);
  const handleChange = ({ target: { name, value } }) => {
    changeData({ ...data, [name]: value });
    setValidationErrors(prevState => ({ ...prevState, [name]: null })); // clear validation, when value set
  };

  const handleCampaignChange = async ({ target: { name, value } }) => {
    try {
      setLoading(true);
      const { kits, gameDefs, campaigns } = await loadNewData(value);

      changeData({
        ...data,
        [name]: value,
        kit: null,
        gameDef: null,
        location: null,
        campaigns,
        kits,
        gameDefs,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error(getError(error));
    }
  };
  const saveData = async () => {
    try {
      setLoading(true);
      if (
        getInitialUserData
        && ![roles.admin, roles.company_admin].includes(role)
      ) {
        await getInitialUserData();
      }
      await onSave({
        campaign,
        kit,
        game_def: gameDef,
        location,
        timezone,
        timezone_offset: getTimezoneOffset(timezone),
        debriefing_time: debriefingTime,
        introduction_time: introductionTime,
        is_fast_session: true,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification.error(getError(error));
    }
  };

  const checkAllFields = () => !(
    campaign
      && kit
      && gameDef
      && location
      && timezone
      && introductionTime
      && debriefingTime
  );
  const kitOptions = useMemo(
    () => generateKitOptions({
      kits,
      campaign: campaigns.find(({ id }) => id === campaign),
    }),
    [kits, campaigns, campaign],
  );

  const validateOnBlurWithMessage = msg => (e) => {
    const {
      target: { name, value },
    } = e;
    if (value) return;
    setValidationErrors(prevState => ({ ...prevState, [name]: msg }));
  };

  return (
    <Styled.Wrapper>
      <Styled.Data>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Select
              name="campaign"
              placeholder="Campaign"
              title="Campaign"
              value={campaign}
              onChange={handleCampaignChange}
              options={transformDataToOptions({
                data: campaigns,
                value: 'id',
                label: 'name',
              })}
              defaultOptions={transformDataToOptions({
                data: campaigns,
                value: 'id',
                label: 'name',
              })}
              loadOptions={loadCampaigns}
              async
              searchable
              withoutReset
            />
            <Select
              name="kit"
              placeholder="Nickname (Kit ID)"
              title="Nickname (Kit ID)"
              value={kit}
              onChange={handleChange}
              onBlur={validateOnBlurWithMessage(
                COMMON_ERRORS.isRequired('nickname'),
              )}
              error={validationErrors.kit}
              options={kitOptions}
              defaultOptions={kitOptions}
              isOptionDisabled={option => get(option, 'disabled', false)}
              searchable
            />
            <Select
              name="gameDef"
              placeholder="Game definition"
              title="Game definition"
              value={gameDef}
              onChange={handleChange}
              options={transformDataToOptions({
                data: gameDefs,
                value: 'id',
                label: 'name',
              })}
              defaultOptions={transformDataToOptions({
                data: gameDefs,
                value: 'id',
                label: 'name',
              })}
              loadOptions={(gameDef, callback) => loadGameDefs({
                gameDef,
                callback,
                campaigns,
                campaign,
              })
              }
              async
              searchable
              onBlur={validateOnBlurWithMessage(
                COMMON_ERRORS.isRequired('game definition'),
              )}
              error={validationErrors.gameDef}
            />
            <Select
              name="location"
              placeholder="Location"
              title="Location"
              value={location}
              onChange={handleChange}
              options={transformDataToOptions({
                data: locations,
                value: 'id',
                label: 'location',
              })}
              defaultOptions={transformDataToOptions({
                data: locations,
                value: 'id',
                label: 'location',
              })}
              loadOptions={loadLocations}
              async
              creatable
              searchable
              onBlur={validateOnBlurWithMessage(
                COMMON_ERRORS.isRequired('location'),
              )}
              error={validationErrors.location}
            />
            <TimezoneSelect
              onChange={handleChange}
              value={getTimezoneName(timezone)}
              selectProps={{
                title: 'Timezone',
                name: 'timezone',
                searchable: true,
                onBlur: validateOnBlurWithMessage(
                  COMMON_ERRORS.isRequired('timezone'),
                ),
                error: validationErrors.timezone,
              }}
            />
            {/* <GoLabel
                  text="More options"
                  triangleType={
                    optionsAreVisible
                      ? TRIANGLE_TYPES.FORWARD_DOWN
                      : TRIANGLE_TYPES.FORWARD
                  }
                  color={LABEL_COLOR_TYPES.BLUE}
                  click={toggleOptionsVisibility}
                />
                {
                  optionsAreVisible && (
                    <Styled.Options>
                      <Select
                        name="introductionTime"
                        key="introductionTime"
                        options={TIME_OPTIONS}
                        value={introductionTime}
                        onChange={handleChange}
                        placeholder="Select introduction time"
                        withoutReset
                      />
                      <Select
                        name="debriefingTime"
                        key="debriefingTime"
                        options={TIME_OPTIONS}
                        value={debriefingTime}
                        onChange={handleChange}
                        placeholder="Select debriefing time"
                        withoutReset
                      />
                    </Styled.Options>
                  )
                } */}
          </>
        )}
      </Styled.Data>
      <Styled.Actions>
        <Button onClick={saveData} disabled={loading || checkAllFields()}>
          {saveText}
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* LaunchSessionForm type of props */

LaunchSessionForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  saveText: PropTypes.string,
  loadCampaigns: PropTypes.func.isRequired,
  loadGameDefs: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  loadNewData: PropTypes.func.isRequired,
  getInitialUserData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  roles: PropTypes.shape({
    admin: PropTypes.string,
    company_admin: PropTypes.string,
    company_host: PropTypes.string,
    host: PropTypes.string,
  }).isRequired,
};

/* LaunchSessionForm default props */

LaunchSessionForm.defaultProps = {
  saveText: 'Save',
};

export default LaunchSessionForm;
