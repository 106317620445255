import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';
import { Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const SideBar = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
`;

export const PageContent = styled.div`
  min-height: 100vh;
  background-color: ${COLORS_CONFIG.SILVER};
`;

export const StructWrapper = styled.div`
  min-height: 100vh;
  padding-left: 260px;
  height: 100vh;
  overflow: auto;
  
  
  & ${SelectWrapper} {
    & .ReactSelect {
      &__control {
        border-radius: 0px;
        border-color: ${COLORS_CONFIG.SKY_BLUE};
      }
      &__menu {
        border-radius: 0px;
        border:1px solid ${COLORS_CONFIG.SKY_BLUE};
        box-shadow: initial;
      }
    }
  }
`;
