export const GET_STORYLINES = '@PROJECT_NAME/GET_STORYLINES';
export const GET_STORYLINES_SUCCESS = '@PROJECT_NAME/GET_STORYLINES_SUCCESS';
export const GET_STORYLINES_FAIL = '@PROJECT_NAME/GET_STORYLINES_FAIL';

export const ADD_STORYLINE = '@PROJECT_NAME/ADD_STORYLINE';
export const ADD_STORYLINE_SUCCESS = '@PROJECT_NAME/ADD_STORYLINE_SUCCESS';
export const ADD_STORYLINE_FAIL = '@PROJECT_NAME/ADD_STORYLINE_FAIL';

export const UPDATE_STORYLINE = '@PROJECT_NAME/UPDATE_STORYLINE';
export const UPDATE_STORYLINE_SUCCESS = '@PROJECT_NAME/UPDATE_STORYLINE_SUCCESS';
export const UPDATE_STORYLINE_FAIL = '@PROJECT_NAME/UPDATE_STORYLINE_FAIL';

export const GET_STORYLINES_BY_COMPANY_ID = '@PROJECT_NAME/GET_STORYLINES_BY_COMPANY_ID';
export const GET_STORYLINES_BY_COMPANY_ID_SUCCESS = '@PROJECT_NAME/GET_STORYLINES_BY_COMPANY_ID_SUCCESS';
export const GET_STORYLINES_BY_COMPANY_ID_FAIL = '@PROJECT_NAME/GET_STORYLINES_BY_COMPANY_ID_FAIL';
