/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { VictoryPie } from 'victory';

/* Constants */

import {
  COLORS_CONFIG,
} from 'config';

/* Styles */

import { Loader } from 'components/index';
import * as Styled from './styles';

const chartInnerRadius = 190;

function AnswersSection({
  first,
  second,
  loading,
}) {
  // if (
  //   (!first && !second)
  //   && !(typeof first === 'number' || typeof second === 'number')
  // ) return null;
  return (
    <Styled.Wrapper>
      <Styled.Card>
        {loading ? (
          <Loader />
        )
          : (
            <>
              <Styled.Title>
                % Questions answered correct first time
              </Styled.Title>
              <Styled.Chart>
                <VictoryPie
                  colorScale={[COLORS_CONFIG.SKY_BLUE, '#DEDEDE']}
                  innerRadius={chartInnerRadius}
                  data={[
                    { x: '1', y: first, label: ' ' },
                    { x: '2', y: 100 - first, label: ' ' },
                  ]}
                />
                <Styled.ChartLabel>
                  {first}
                  %
                </Styled.ChartLabel>
              </Styled.Chart>
            </>
          )
        }
      </Styled.Card>
      <Styled.Card>
        {loading ? (
          <Loader />
        )
          : (
            <>
              <Styled.Title>
                % Questions answered correct after 1st wrong guess
              </Styled.Title>
              <Styled.Chart>
                <VictoryPie
                  colorScale={[COLORS_CONFIG.ORANGE, '#DEDEDE']}
                  innerRadius={chartInnerRadius}
                  data={[
                    { x: '1', y: second, label: ' ' },
                    { x: '2', y: 100 - second, label: ' ' },
                  ]}
                />
                <Styled.ChartLabel>
                  {second}
                  %
                </Styled.ChartLabel>
              </Styled.Chart>
            </>
          )
        }
      </Styled.Card>
    </Styled.Wrapper>
  );
}

/* AnswersSection type of props */

AnswersSection.propTypes = {
  first: PropTypes.number.isRequired,
  second: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

/* AnswersSection default props */

AnswersSection.defaultProps = {
  loading: false,
};

export default AnswersSection;
