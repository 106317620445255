/* Libs */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 520px;
  padding: 32px 48px 5.2% 48px;
`;

export const Content = styled.div`
  padding-bottom: 5.2%;
`;
