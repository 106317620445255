/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  Img,
} from 'components';

/* Styles */

import * as Styled from './styles';

function MaskInput({
  icon,
  name,
  value,
  mask,
  error,
  onClick,
  onChange,
  placeholder,
  onIconClick,
  isDisabled
}) {
  const handleIconClick = (event) => {
    if (event) event.stopPropagation();
    onIconClick();
  };

  return (
    <Styled.Wrapper
      hasError={error}
      hasIcon={!!icon}
      onClick={onClick}
      isIconClickable={!!onIconClick}
      disabled={isDisabled}
    >
      <Styled.Input
        value={value || ''}
        name={name}
        disabled={isDisabled}
        onChange={onChange}
        mask={mask}
        placeholder={placeholder}
      />
      {icon && <Img size={[18, 18]} src={icon} onClick={handleIconClick} />}
      <Styled.Error>
        {error && error}
      </Styled.Error>
    </Styled.Wrapper>
  );
}

/* MaskInput type of props */

MaskInput.propTypes = {
  mask: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.string,
  onIconClick: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  name: PropTypes.string,
};

/* MaskInput default props */

MaskInput.defaultProps = {
  value: '',
  error: null,
  placeholder: '',
  onIconClick: null,
  onChange: null,
  onClick: null,
  icon: null,
  name: '',
};

export default MaskInput;
