/* Libs */
import PropTypes from 'prop-types';
import React from 'react';

/* Components */
import {
  SimpleTextCell,
  Table,
} from 'components';

/* Constants */

import { GAME_CONFIG, DATE_SETTINGS, SESSION_TIME_TYPES } from 'config';

/* Styles */
import moment from 'moment';
import { getSessionTimeData } from 'utils/custom';
import * as Styled from './styles';


// LeaderboardTable columns cell structure
const getColumnsData = () => [
  {
    accessor: 'rank',
    Header: 'Rank',
    Cell: props => <SimpleTextCell value={props.original.rank} size="small" />,
  },
  {
    accessor: 'team',
    Header: 'Team',
    Cell: props => <SimpleTextCell value={props.original.team_name} size="small" />,
  },
  {
    accessor: 'location',
    Header: 'Location',
    Cell: props => <SimpleTextCell value={props.original.location} size="small" />,
    // Cell: props => <SimpleTextCell value={props.original.location && props.original.location.location} size="small" />, //FIX: uncomment that if be will send location as object
  },
  {
    accessor: 'time',
    Header: 'Start date',
    Cell: ({ original: { utc_start_time, timezone } }) => {
      const { startTime } = getSessionTimeData({
        type: SESSION_TIME_TYPES.LOCAL_TIME,
        propsTimezone: timezone,
        propsStartTime: utc_start_time,
      });

      return (
        <SimpleTextCell
          value={startTime.format(DATE_SETTINGS.FORMAT.MONTH_SHORT_DAY_COMMA_YEAR)}
          size="small"
        />
      );
    },
  },
  {
    accessor: 'time',
    Header: 'Time completed',
    Cell: ({ original: { time_completed, is_finished } }) => (
      <SimpleTextCell
        value={
          is_finished ? moment(time_completed, DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED).format(DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED) : GAME_CONFIG.NOT_FINISHED
        }
        size="small"
      />
    ),
  },
];

export default function LeaderboardTable({
  data,
  tableSize,
  pagination,
  loading,
  isDarkMode,
}) {
  return (
    <Styled.LeaderboardTable isDarkMode={isDarkMode}>
      <Table
        defaultPageSize={data.length ? data.length : null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={getColumnsData()}
        loading={loading}
        data={data}
        sortable={false}
        pagination={pagination}
        isDarkMode={isDarkMode}
      />
    </Styled.LeaderboardTable>
  );
}

// Type of props
LeaderboardTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    rank: PropTypes.string.number,
    team_name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  })).isRequired,
};
