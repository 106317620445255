import { store } from 'lib';
import { AuthEntity, AdminCompanyEntity } from '_entities';

export function impersonate(id) {
  store.dispatch(AuthEntity.actions.changeUserInfo({ company: id }));
  localStorage.setItem('IMPERSONATED_COMPANY_ID', id);
}

export function canImpersonate() {
  const impersonateCompanyId = localStorage.getItem('IMPERSONATED_COMPANY_ID') || false;
  if (!impersonateCompanyId) return false;
  impersonate(parseInt(impersonateCompanyId, 10));
  return true;
}

export function cancelImpersonate() {
  store.dispatch(AuthEntity.actions.changeUserInfo({ company: null }));
  store.dispatch(AdminCompanyEntity.actions.resetCompany());
  localStorage.removeItem('IMPERSONATED_COMPANY_ID');
}

export default {
  cancelImpersonate,
  canImpersonate,
  impersonate,
};
