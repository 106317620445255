/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

/* Actions */

import { ParticipantEntity } from '_entities';

/* Constants */
import { DATE_SETTINGS } from 'config';
import { NOTIFICATIONS, VALIDATION_VALUES } from '_constants';


/* Utils */

import { validateEmail } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { notification } from 'utils/services';
import { getError, getTimezoneTitle } from 'utils/custom';

/* Components */

import {
  Input,
  Button,
  BigTitle,
} from 'components';

/* Styles */

import * as Styled from './styles';

const DEFAULT_DATA = {
  email: '',
};
const DEFAULT_ERRORS = {
  email: null,
};

function AcceptInvite({
  token,
  loading,
  nextStep,
  gameSession: {
    id,
    utc_end_time,
    utc_start_time,
    timezone,
  },
  participantSignup,
  participant_email,
}) {
  const [formData, setData] = useState({
    data: {
      ...DEFAULT_DATA,
      email: participant_email,
    },
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      default:
        break;
    }
    setData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    });
  };


  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
        });
        break;
      default:
        break;
    }
    setData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    }));
  };

  async function signupAttempt() {
    try {
      await participantSignup({
        game_session: id,
        token,
        participant_email: formData.data.email,
      });
      nextStep();
    } catch (error) {
      const signUpError = getError(error);
      if (signUpError.trim() === NOTIFICATIONS.ERROR_PARTICIPANT_WITH_EMAIL_IS_SIGNED_UP) {
        setData(prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            email: signUpError,
          },
          canSubmit: false,
        }));

        return;
      }
      notification.error(getError(error));
    }
  }


  const date = moment(utc_start_time).format(DATE_SETTINGS.FORMAT.WEEKDAY_MONTH_DAY_YEAR);
  const startTime = moment(utc_start_time).format(DATE_SETTINGS.FORMAT.HOURS_MINUTES_MERIDIEM_LOW);
  const endTime = moment(utc_end_time).format(DATE_SETTINGS.FORMAT.HOURS_MINUTES_MERIDIEM_LOW);

  const localTimezone = moment.tz.guess();

  return (
    <Styled.Wrapper>
      <Styled.Information>
        <BigTitle>
          Session registration
        </BigTitle>
        <Styled.Line>
          <div>Date:</div>
          <div>{date}</div>
        </Styled.Line>
        <Styled.Line>
          <div>Time:</div>
          <div>
            {`${startTime} - ${endTime} ${getTimezoneTitle(localTimezone)}`}
          </div>
        </Styled.Line>
      </Styled.Information>
      <Input
        type="email"
        name="email"
        value={formData.data.email}
        error={formData.errors.email}
        onChange={handleChange}
        onBlur={validateOnBlur}
        placeholder="Email address"
        dark
      />
      <Button
        onClick={signupAttempt}
        disabled={!formData.canSubmit || loading}
      >
        Accept
      </Button>
    </Styled.Wrapper>
  );
}

/* AcceptInvite type of props */

AcceptInvite.propTypes = {
  nextStep: PropTypes.func.isRequired,
  gameSession: PropTypes.shape({
    id: PropTypes.number.isRequired,
    utc_end_time: PropTypes.string.isRequired,
    utc_start_time: PropTypes.string.isRequired,
  }).isRequired,
  participant_email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(({ participant }) => ({
  token: participant.token,
  loading: participant.loading,
  gameSession: participant.gameSession,
  participant_email: participant.participant_email,
}), {
  participantSignup: ParticipantEntity.actions.signup,
})(AcceptInvite);
