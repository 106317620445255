/* Libs */
import styled from 'styled-components';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';


export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
`;

export const MenuTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & > :last-child{
    margin-left: 16px;
  }
`;

export const Button = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 14px;
  padding: 8px 0;
  cursor: pointer;
`;
