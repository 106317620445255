/* Libs */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
/* Styles */

import * as Styled from './styles';


function Timer({
  onExpired,
  expireDate,
  stopped,
  timeLeft,
  timeLimit,
  countUp,
  normalFormat,
  expiredFormat,
  onTick,
  name,
}) {
  const [format, setNewFormat] = useState(normalFormat);
  const isMounted = useRef(false);
  const [isAlmostExpired, setAlarm] = useState(false);
  const [current, setNew] = useState(timeLeft);
  const [timerId, setTimerId] = useState(null);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    const endTime = moment(expireDate);
    clearInterval(timerId);
    const newTimerId = setInterval(() => {
      if (!isMounted.current) return;
      const now = moment();
      const newTime = moment.duration(endTime.diff(now));
      if (newTime.asMinutes() < 10) {
        setNewFormat(expiredFormat);
      }
      if (newTime.asMinutes() < 1) {
        setAlarm(true);
      }
      if (stopped) {
        return clearInterval(newTimerId); // we do it here becouse we wanna apply effects to view
      }
      if (endTime.isBefore(now)) {
        clearInterval(newTimerId);
        setNew(moment.utc(0));
        return onExpired();
      }
      setNew(moment.utc(newTime.asMilliseconds()));
    }, 30);

    setTimerId(newTimerId);
    return () => clearInterval(timerId);
  }, [expireDate, format, stopped]);
  useEffect(() => {
    onTick && !stopped && typeof onTick === 'function' && onTick({ target: { name, value: current } });
  }, [current]);
  const getCountUpTime = () => moment.utc(moment.duration(timeLimit, 'HH:mm:ss').asMilliseconds() - current.valueOf()).format(format);
  return (
    <Styled.Wrapper large={format === expiredFormat}>
      <Styled.Time isAlmostExpired={isAlmostExpired}>
        {countUp ? getCountUpTime() : current.format(format)}
      </Styled.Time>
    </Styled.Wrapper>
  );
}

/* Timer type of props */

Timer.propTypes = {
  expireDate: PropTypes.number.isRequired,
  onExpired: PropTypes.func.isRequired,
  timeLeft: PropTypes.instanceOf(moment).isRequired,
  timeLimit: PropTypes.string.isRequired,
  countUp: PropTypes.bool,
};

/* Timer default props */

Timer.defaultProps = {
  countUp: false,
};

export default Timer;
