import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { TextEllipsis } from 'assets/styles/mixins';
import { Wrapper as ShareLink } from 'components/ui/ShareLink/styles';
import {
  Wrapper as TextareaWrapper,
  Textarea,
  Title,
} from 'components/ui/Textarea/styles';
import { Button } from 'components/ui/Button/styles';

export const Invite = styled.div`
  padding: 0 48px 20px;
  ${ShareLink} {
    margin-top: 16px;
  }
  ${TextareaWrapper} {
    margin-top: 12px;
    min-height: 100px;
  }
  ${Textarea} {
    padding: 14px 16px;
    min-height: 100px;
  }
`;

export const Campaign = styled.div`
  margin-top: 12px;
`;

export const CampaignTitle = styled(Title)`
  display: flex;
  align-items: center;
`;

export const CampaignName = styled.div`
  margin-left: 5px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  width: 100%;
  ${TextEllipsis}
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  ${Button} {
    &:last-child {
      margin-top: 9px;
      background: ${COLORS_CONFIG.TRANSPARENT};
      color: ${COLORS_CONFIG.SMOOTH_BROWN};
      font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
    }
  }
`;
