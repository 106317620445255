/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import { Wrapper as InputWrapper } from 'components/ui/SearchInput/styles';

export const Wrapper = styled.div`
  padding: 71px 32px 67px 48px;
`;
export const Actions = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  & ${InputWrapper} {
    border-radius: 3px;
    border-color: ${COLORS_CONFIG.SILVER_LIGHT};
  }
`;

export const BotActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  & > * {
    margin-right: 44px;
    &:last-child {
    margin-right: 0;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 40px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  display: flex;
  align-items: center;
  & ${InputWrapper} {
    border-radius: 3px;
    margin-left: 20px;
    width: 300px;
  }
`;
export const Content = styled.div`
  margin-top: 35px;
`;
