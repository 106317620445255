import styled from 'styled-components';

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent ${({ color }) => color} ;
`;

export default Triangle;
