/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash.get';

/* Components */
import {
  Select,
  Button,
  Accordion,
  DatePicker,
  TimezoneSelect,
  Input,
  ForwardTriangle,
  DownTriangle,
  Switcher,
} from 'components';
import { useValidationOnBlurHook } from 'hooks';

/* Constants */
import { COLORS_CONFIG } from 'config';
import { TIME_OPTIONS } from '_constants';
import { COMMON_ERRORS } from '_constants/errors';

/* Styles */

import * as Styled from './styles';


function SetupSessionsForm(props) {
  const {
    campaign,
    kit,
    handleFormChange,
    location,
    locations,
    loadLocations,
    gameDef,
    gameDefs,
    dateFrom,
    dateTo,
    weekdays,
    weekdaysShort,
    timeOptions,
    sessionOptions,
    handleMenuOpen,
    timeList,
    processRange,
    isFormValid,
    kitOptions,
    timezone,
    dateErrors,
    // breakDuration,
    // breakOptions,
    toggleWeekday,
    sessionsPerDay,
    startTime,
    introductionTime,
    // introductionTimeOptions,
    debriefingTime,
    hasMoreOptions,
    setMoreOptions,
    isSecretSession,
    includedWeekdays,
    isTimerCountingUp,
    toggleTimerType,
    isKitOptionDisabled,
    changeParticipantsPerSession,
    participantsPerSession,
    meetingLink,
    isOnlineOnly,
  } = props;

  const { validationErrors, validateOnBlurWithMessage, withValidationReset } = useValidationOnBlurHook();

  return (
    <>
      <Styled.Campaign>
        <Styled.Label>Campaign</Styled.Label>
        <Styled.CampaignName>
          {get(campaign, 'name', 'No campaign name')}
        </Styled.CampaignName>
      </Styled.Campaign>
      <Styled.Data>
        <Styled.Row>
          <Styled.Column>
            <Styled.Row>
              <Select
                name="kit"
                placeholder="Nickname (Kit ID)"
                title="Nickname (Kit ID)"
                searchable
                value={kit}
                options={kitOptions}
                defaultOptions={kitOptions}
                onChange={withValidationReset(handleFormChange)}
                isOptionDisabled={isKitOptionDisabled}
                onBlur={validateOnBlurWithMessage(
                  COMMON_ERRORS.isRequired('nickname'),
                )}
                error={validationErrors.kit}
              />
            </Styled.Row>
            <Styled.Row>
              <Select
                name="location"
                placeholder="Location"
                title="Location"
                value={location}
                onChange={withValidationReset(handleFormChange)}
                options={locations.map(({ location, id }) => ({
                  value: id,
                  label: location,
                }))}
                defaultOptions={locations.map(({ location, id }) => ({
                  value: id,
                  label: location,
                }))}
                loadOptions={loadLocations}
                async
                creatable
                searchable
                onBlur={validateOnBlurWithMessage(
                  COMMON_ERRORS.isRequired('location'),
                )}
                error={validationErrors.location}
              />
            </Styled.Row>
            <Styled.Row>
              <Select
                name="gameDef"
                placeholder="Game definition"
                title="Game definition"
                value={gameDef}
                options={gameDefs.reduce(
                  (arr, { storyline, id, name }) => (storyline.id === campaign.storyline.id
                    ? arr.concat({ value: id, label: name })
                    : arr),
                  [],
                )}
                onChange={withValidationReset(handleFormChange)}
                onBlur={validateOnBlurWithMessage(
                  COMMON_ERRORS.isRequired('game definition'),
                )}
                error={validationErrors.gameDef}
              />
            </Styled.Row>
            {isOnlineOnly && !isSecretSession && (
              <Styled.Row>
                <Input
                  name="meetingLink"
                  placeholder="Meeting link"
                  title="Meeting link"
                  onChange={handleFormChange}
                  value={meetingLink}
                />
              </Styled.Row>
            )}
            <Styled.Row>
              <TimezoneSelect
                value={timezone}
                onChange={withValidationReset(handleFormChange)}
                selectProps={{
                  title: 'Timezone',
                  name: 'timezone',
                  searchable: true,
                  onBlur: validateOnBlurWithMessage(
                    COMMON_ERRORS.isRequired('timezone'),
                  ),
                  error: validationErrors.timezone,
                }}
              />
            </Styled.Row>
          </Styled.Column>
          <Styled.Column>
            <Styled.RowWithTwo>
              <DatePicker
                name="dateFrom"
                placeholder="Start Date"
                value={dateFrom}
                error={dateErrors.dateFrom}
                onChange={handleFormChange}
                withIcon
              />
              <DatePicker
                name="dateTo"
                placeholder="End Date"
                value={dateTo}
                error={dateErrors.dateTo}
                onChange={handleFormChange}
                minDate={moment(dateFrom).toDate()}
                maxDate={moment(dateFrom).add(6, 'months').toDate()}
                withIcon
              />
            </Styled.RowWithTwo>
            {/* <Styled.Row>
              <Select
                name="startTime"
                placeholder="Start Time"
                value={startTime}
                options={timeOptions}
                onChange={handleFormChange}
                searchable
                onMenuOpen={handleMenuOpen}
                forwardedRef={timeList}
              />
            </Styled.Row> */}
            <Styled.RowWithTwo>
              {/* <Select
                name="breakDuration"
                placeholder="Break"
                value={breakDuration}
                options={TIME_OPTIONS}
                onChange={handleFormChange}
              /> */}
              <Select
                name="startTime"
                placeholder="Start Time"
                title="Start Time"
                value={startTime}
                options={timeOptions}
                onChange={handleFormChange}
                searchable
                onMenuOpen={handleMenuOpen}
                forwardedRef={timeList}
                onBlur={validateOnBlurWithMessage(
                  COMMON_ERRORS.isRequired('start time'),
                )}
                error={validationErrors.startTime}
              />
              <Select
                name="sessionsPerDay"
                placeholder="Sessions per day"
                title="Sessions per day"
                value={sessionsPerDay}
                options={sessionOptions}
                onChange={handleFormChange}
                isDisabled={!(gameDef && kit && startTime)}
                searchable
              />
            </Styled.RowWithTwo>
            <Styled.Row>
              {weekdaysShort.map(item => (
                <Styled.WeekDay
                  onClick={() => includedWeekdays.includes(item) && toggleWeekday(item)
                  }
                  key={item}
                  item={item}
                  filled={startTime && sessionsPerDay}
                  active={weekdays.includes(item)}
                  disabled={!includedWeekdays.includes(item)}
                >
                  {item}
                </Styled.WeekDay>
              ))}
            </Styled.Row>
          </Styled.Column>
        </Styled.Row>
        <Accordion
          isActive={hasMoreOptions}
          setActive={() => setMoreOptions(!hasMoreOptions)}
          header={(
            <Styled.ExtraOptions active={hasMoreOptions}>
              <Styled.ExtraOptionLabel>More options</Styled.ExtraOptionLabel>
              {hasMoreOptions ? (
                <DownTriangle color={COLORS_CONFIG.SKY_BLUE} />
              ) : (
                <ForwardTriangle color={COLORS_CONFIG.SKY_BLUE} />
              )}
            </Styled.ExtraOptions>
          )}
          content={(
            <Styled.Row>
              <Styled.Column>
                <Styled.Row>
                  <Select
                    name="introductionTime"
                    placeholder="Introduction"
                    title="Introduction time"
                    value={introductionTime}
                    // options={introductionTimeOptions}
                    options={TIME_OPTIONS}
                    onChange={handleFormChange}
                    withoutReset
                  />
                </Styled.Row>
                <Styled.Row>
                  <Select
                    name="debriefingTime"
                    placeholder="Debriefing"
                    title="Debriefing time"
                    value={debriefingTime}
                    // options={introductionTimeOptions}
                    options={TIME_OPTIONS}
                    onChange={handleFormChange}
                    withoutReset
                  />
                </Styled.Row>
              </Styled.Column>
              <Styled.Column>
                <Styled.Row>
                  <Input
                    name="participantsPerSession"
                    placeholder="Max participants"
                    title="Max participants"
                    value={participantsPerSession}
                    onChange={withValidationReset(changeParticipantsPerSession)}
                    maxLength="2"
                    onBlur={validateOnBlurWithMessage(
                      COMMON_ERRORS.numberLessThan('participants', 1),
                    )}
                    error={validationErrors.participantsPerSession}
                  />
                </Styled.Row>
                <Switcher
                  checked={isTimerCountingUp}
                  onChange={toggleTimerType}
                  checkedText="Count up"
                  uncheckedText="Count down"
                />
              </Styled.Column>
            </Styled.Row>
          )}
        />
      </Styled.Data>
      <Styled.Actions>
        <Button onClick={processRange} disabled={!isFormValid}>
          Next
        </Button>
      </Styled.Actions>
    </>
  );
}

/* SetupSessionsForm type of props */
SetupSessionsForm.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string,
    storyline: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  kit: PropTypes.number,
  handleFormChange: PropTypes.func.isRequired,
  location: PropTypes.number,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  loadLocations: PropTypes.func.isRequired,
  gameDef: PropTypes.number,
  gameDefs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      storyline: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ).isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  weekdays: PropTypes.arrayOf(PropTypes.string),
  weekdaysShort: PropTypes.arrayOf(PropTypes.string),
  timeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  sessionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  handleMenuOpen: PropTypes.func.isRequired,
  timeList: PropTypes.any,
  processRange: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool,
  kitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      disabled: PropTypes.bool,
    }),
  ),
  timezone: PropTypes.string,
  dateErrors: PropTypes.shape({
    dateFrom: PropTypes.bool,
    dateTo: PropTypes.bool,
  }),
  // breakDuration: PropTypes.string,
  breakOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  toggleWeekday: PropTypes.func.isRequired,
  sessionsPerDay: PropTypes.number,
  startTime: PropTypes.string,
  introductionTime: PropTypes.string,
  debriefingTime: PropTypes.string,
  hasMoreOptions: PropTypes.bool.isRequired,
  setMoreOptions: PropTypes.func.isRequired,
  isSecretSession: PropTypes.bool.isRequired,
  includedWeekdays: PropTypes.arrayOf(PropTypes.string),
  isKitOptionDisabled: PropTypes.func.isRequired,
  meetingLink: PropTypes.string,
  isOnlineOnly: PropTypes.bool,
};

/* SetupSessionsForm default props */
SetupSessionsForm.defaultProps = {
  isFormValid: false,
  timeOptions: [],
  kitOptions: [],
  sessionOptions: [],
  timeList: null,
  kit: 0,
  timezone: null,
  weekdaysShort: [],
  weekdays: [],
  location: null,
  locations: [],
  gameDef: null,
  dateErrors: null,
  // breakDuration: null,
  breakOptions: [],
  sessionsPerDay: null,
  startTime: null,
  introductionTime: null,
  debriefingTime: null,
  includedWeekdays: [],
  meetingLink: '',
  isOnlineOnly: false,
};

export default memo(SetupSessionsForm);
