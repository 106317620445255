/* Libs */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  ManageGameQuestionListItem,
  ManageGameQuestionsList,
  Img,
} from 'components';

/* Styles */

import { NO_QUESTIONS } from 'assets/icons';
import * as Styled from './styles';

function Questions({
  questions,
}) {
  const questionsList = useMemo(() => questions.map(question => (
    <ManageGameQuestionListItem
      key={question.number}
      question={question.question}
      answers={question.answers}
    />
  )), [questions]);
  return (
    <Styled.Wrapper>
      <Styled.Title>
        Questions
      </Styled.Title>
      {
        questions.length < 1
          ? <Img src={NO_QUESTIONS} center size={[487, 453]} />
          : (
            <ManageGameQuestionsList>
              {questionsList}
            </ManageGameQuestionsList>
          )
      }

    </Styled.Wrapper>
  );
}

/* Questions type of props */

Questions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
    number: PropTypes.number.isRequired,
  })),
};

export default Questions;
