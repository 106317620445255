import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config/index';
import { Button } from 'components/ui/Button/styles';
import { TextEllipsis } from 'assets/styles/mixins';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 25px;
`;

export const Title = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  white-space: nowrap;
`;

export const CompanyName = styled.span`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  max-width: 300px;
  margin-right: 5px;
  ${TextEllipsis}
`;

export const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  height: 100%;
  & ${Button} {
    width: 84px;
    min-width: 84px;
    font-size: 12px;
    line-height: 12px;
    border: none;
    border-radius: initial;
  }
`;

export const Link = styled.div`
  height: 48px;
  padding: 16px 18px 14px;
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  width: 243px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Content = styled.div`
`;

export const Wrapper = styled.div`
  width: 100%;

  ${({ isDarkMode }) => isDarkMode && css`
    ${Title} {
      color: ${COLORS_CONFIG.WHITE};
    }
    ${Link} {
      color: ${COLORS_CONFIG.WHITE};
    }
  `}
`;
