import React from 'react';
import PropTypes from 'prop-types';

import { LOGO_CONFIG } from 'config';

import { Button, Logo } from 'components';

import * as Styled from './styles';

export default function ParticipantsInvitedSuccess({
  onClose,
}) {
  return (
    <Styled.Wrapper>
      <Logo type={LOGO_CONFIG.VERTICAL_WHITE_WHITE} />
      <Styled.Title>Your campaign invitations have been sent.</Styled.Title>
      {/* <Styled.Description>
        Uncomment and replace with needed text
      </Styled.Description> */}
      <Button onClick={onClose}>Close</Button>
    </Styled.Wrapper>
  );
}

ParticipantsInvitedSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
};
