/* Libs */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/* Constants */
import { URLS_CONFIG } from 'config';

// Host subroutes
const HOST_SUB_ROUTES = [
  {
    path: URLS_CONFIG.host.campaigns,
    component: require('../CompanyAdmin/Campaigns').default,
  },
  {
    path: URLS_CONFIG.host.calendar,
    component: require('../CompanyAdmin/Calendar').default,
  },
  {
    path: URLS_CONFIG.host.manageGame,
    component: require('../CompanyAdmin/ManageGame').default,
  },
];

export default class HostPage extends Component {
  // Static props
  static path = URLS_CONFIG.host.default;

  render() {
    return (
      <Switch>
        {HOST_SUB_ROUTES.map(route => (
          <Route path={route.path} exact component={route.component} key={route.path} />
        ))}
        <Redirect to={URLS_CONFIG.host.calendar} />
      </Switch>
    );
  }
}
