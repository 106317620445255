import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

/* Actions */

import {
  GameDefEntity,
  LS_AdminStorylinesEntity,
} from '_entities';

/* Components */

import {
  BigTitle,
  Checkbox,
  Input,
  Select,
} from 'components';

/* Utils */

import {
  getError,
  getStaticData,
  getViewCompanyLink,
  redirectWithError,
  transformDataToOptions,
} from 'utils/custom';

/* Constants */

import { GAME_DEF_FORM } from '_constants';

/* Configs */

import { DATE_SETTINGS, GAME_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

const TIME_WITH_MIN_REGEX = /^\d+ min$/;
const TIME_WITH_MIN_REGEX_EXTENDED = /^\d+( min)?$/;
const SECTION_NAME = 'basicInformation';

const BasicInformationForm = ({
  data,
  errors,
  onChange,
  mode,
  getStorylinesByCompanyId,
  getAllGameDef,
  companyId,
}) => {
  const [timeOptions, setTimeOptions] = useState(getStaticData('timeLimits') || []);
  const [basicOptions, setBasicOptions] = useState({
    loaded: false,
    storyLines: [],
    templates: [],
  });

  const isTemplateMode = useMemo(() => [
    GAME_DEF_FORM.MODE.TEMPLATE,
    GAME_DEF_FORM.MODE.TEMPLATE_EDIT,
  ].includes(mode), [mode]);

  const allTimeOptions = useMemo(() => {
    if (!data.time_limit) return timeOptions;
    const timeOptionAlreadyExist = timeOptions.some(option => option.value === data.time_limit);
    if (timeOptionAlreadyExist) return timeOptions;
    return timeOptions.concat({
      label: `${moment.duration(data.time_limit).asMinutes()} min`,
      value: data.time_limit,
    }).sort((a, b) => {
      const aDuration = moment.duration(a.value).asMinutes();
      const bDuration = moment.duration(b.value).asMinutes();
      return aDuration - bDuration;
    });
  }, [data.time_limit, timeOptions]);

  function validateTimeOption(inputValue, selectValue, selectOptions) {
    if (!TIME_WITH_MIN_REGEX_EXTENDED.test(inputValue) || parseInt(inputValue, 10) > GAME_CONFIG.MAXIMUM_TIME) {
      return false;
    }
    const matchLabel = TIME_WITH_MIN_REGEX.test(inputValue) ? inputValue : `${inputValue} min`;
    return !selectOptions.find(selectOption => selectOption.label === matchLabel);
  }

  function createNewTimeOption(inputLabel) {
    const [minutes] = inputLabel.split(' ');
    const newTimeValue = moment().startOf('day').add(minutes, 'm').format(DATE_SETTINGS.FORMAT.TIMER_FORMAT);
    setTimeOptions((existedTimeOptions) => {
      if (existedTimeOptions.find(timeOption => timeOption.value === newTimeValue)) {
        return existedTimeOptions;
      }
      return existedTimeOptions.concat({
        value: newTimeValue,
        label: `${minutes} min`,
      }).sort((a, b) => (a.value - b.value));
    });
    onChange({ target: { name: 'time_limit', value: newTimeValue, section: SECTION_NAME } });
  }

  const fetchStorylines = (storylineName, callback) => {
    if (!isTemplateMode && !companyId) return;
    getStorylinesByCompanyId({ id: companyId, name: storylineName }).then(({ results }) => {
      callback(transformDataToOptions({ data: results, label: 'name', value: 'id' }));
    });
  };
  const loadStorylines = debounce(fetchStorylines, 300);

  const fetchTemplates = (templateName, callback) => {
    getAllGameDef({ name: templateName, company: 'null' }).then(({ results }) => {
      callback(transformDataToOptions({ data: results, label: 'name', value: 'id' }));
    });
  };
  const loadTemplates = debounce(fetchTemplates, 300);

  const handleOnChange = ({ target: { name, value } }) => {
    onChange({
      target: {
        name,
        value,
        section: SECTION_NAME,
      },
    });
  };

  useEffect(() => {
    if (mode && isTemplateMode) {
      return setBasicOptions(prev => ({
        ...prev,
        loaded: true,
      }));
    }
    (async () => {
      try {
        if (!companyId) {
          return;
        }
        const [storylineResponse, gameDefResponse] = await Promise.all([
          getStorylinesByCompanyId({ id: companyId }),
          getAllGameDef({
            company: 'null',
          }),
        ]);
        const { results: storylines } = storylineResponse;
        const { results: gameDefs } = gameDefResponse;

        const templates = gameDefs.map(gameDef => ({ label: gameDef.name, value: gameDef.id }));

        setBasicOptions({
          loaded: true,
          storyLines: storylines.map(storyline => ({ label: storyline.name, value: storyline.id })),
          templates: data.initial_template_name
            && !templates.some(template => template.label === data.initial_template_name)
            ? [
              { label: data.initial_template_name, value: data.game_def_template },
              ...templates,
            ]
            : templates,
        });
      } catch (error) {
        redirectWithError(getViewCompanyLink(data.company), getError(error));
      }
    })();
  }, [companyId]);

  useEffect(() => {
    if (!companyId && data.initial_template_name) {
      setBasicOptions(prev => ({
        ...prev,
        templates: [{ label: data.initial_template_name, value: data.game_def_template }],
      }));
    }
  }, [data.initial_template_name, companyId]);

  return (
    <Styled.Wrapper>
      <BigTitle>
        Basic information
      </BigTitle>
      <Input
        placeholder="Definition name"
        name="name"
        title="Definition name"
        value={data.name}
        onChange={handleOnChange}
        onBlur={handleOnChange}
        error={errors.name}
      />
      <Styled.InputGroup>
        {
          [
            GAME_DEF_FORM.MODE.ADD,
            GAME_DEF_FORM.MODE.TEMPLATE,
            GAME_DEF_FORM.MODE.TEMPLATE_EDIT,
          ].includes(mode)
            ? (
              <Select
                placeholder="Template"
                title="Template"
                name="game_def_template"
                value={data.game_def_template || null}
                options={basicOptions.templates}
                onChange={handleOnChange}
                error={isTemplateMode ? null : errors.game_def_template}
                loadOptions={loadTemplates}
                defaultOptions={basicOptions.templates}
                async
                searchable
                isDisabled={isTemplateMode}
                optional={!isTemplateMode}
              />
            )
            : (
              <Styled.Label>
                <div>Template:</div>
                <div>
                  {
                    (data.game_def_template && data.game_def_template.name)
                    || 'Not provided'
                  }
                </div>
              </Styled.Label>
            )
        }
        <Select
          placeholder="Time limit"
          name="time_limit"
          title="Time limit"
          value={data.time_limit || null}
          onChange={handleOnChange}
          withoutReset
          options={allTimeOptions}
          error={errors.time_limit}
          creatable
          searchable
          formatCreateLabel={inputValue => (TIME_WITH_MIN_REGEX.test(inputValue) ? moment(inputValue)
            .minutes() : `${inputValue} min`)}
          isValidNewOption={validateTimeOption}
          onCreateOption={createNewTimeOption}
          type="number"
        />
      </Styled.InputGroup>
      <Styled.InputGroup notSame>
        <Checkbox
          label="Final code only"
          name="is_final_code_only"
          value={data.is_final_code_only}
          onChange={handleOnChange}
        />
        <Input
          placeholder="Final code"
          name="final_code"
          title="Final code"
          value={data.final_code}
          onChange={handleOnChange}
          error={errors.final_code}
        />
      </Styled.InputGroup>
      <Select
        placeholder="Storyline"
        name="storyline"
        title="Storyline"
        options={basicOptions.storyLines}
        defaultOptions={basicOptions.storyLines}
        value={data.storyline || null}
        onChange={handleOnChange}
        error={errors.storyline}
        loadOptions={loadStorylines}
        async
        searchable
        isDisabled={isTemplateMode}
      />
      {
        [GAME_DEF_FORM.MODE.ADD, GAME_DEF_FORM.MODE.EDIT].includes(mode) && (
          <Styled.Hint>
            *If no storyline selected, the game definition won&apos;t be displayed while creating a session
          </Styled.Hint>
        )
      }
    </Styled.Wrapper>
  );
};

BasicInformationForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.number,
    storyline: PropTypes.number,
    final_code: PropTypes.string,
    time_limit: PropTypes.string,
    game_def_template: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_final_code_only: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  getStorylinesByCompanyId: PropTypes.func.isRequired,
  getAllGameDef: PropTypes.func.isRequired,
  companyId: PropTypes.number,
};

BasicInformationForm.defaultProps = {
  companyId: null,
};

export default connect(null, {
  getStorylinesByCompanyId: LS_AdminStorylinesEntity.actions.getStorylinesByCompanyId,
  getAllGameDef: GameDefEntity.actions.getAllGameDef,
})(BasicInformationForm);
