/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

/* Components */

import { Img } from 'components';

/* Styles */

import { SEARCH } from 'assets/icons';
import * as Styled from './styles';

function SearchInput({
  placeholder,
  title,
  type,
  name,
  value,
  ...rest
}) {
  const handlePreventSpace = (e) => {
    if (e.keyCode === 32 && type === 'email') {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <Styled.Wrapper>
      <Img size={[12, 12]} src={SEARCH} />
      <Styled.Input
        placeholder={placeholder}
        type={type}
        name={name}
        value={value || ''}
        onKeyDown={handlePreventSpace}
        {...rest}
      />
    </Styled.Wrapper>
  );
}

/* SearchInput type of props */

SearchInput.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.shape({
    valid: PropTypes.bool,
    error: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'email']),
  rules: PropTypes.array,
};

/* SearchInput default props */

SearchInput.defaultProps = {
  autoComplete: 'new-password',
  type: 'text',
  optional: false,
  value: '',
  title: '',
  placeholder: '',
  status: null,
  rules: [],
};

export default SearchInput;
