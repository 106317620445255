/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';


export const Logo = styled.div`
  display: flex;
  align-items: center;
  width: 205px;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    width: 165px;
  }
`;

export const Navigation = styled.div`
  height: 100%;
`;

export const UserInfo = styled.div`
  height: 100%;
`;

export const Wrapper = styled.div`
  padding: 0 20px;
  height: 100%;
  background-color: ${COLORS_CONFIG.WHITE};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  ${Logo} {
    /* flex: 2; */
  }
  ${UserInfo} {
    flex: 1;
  }
  ${Navigation} {
    flex: 4;
  }
`;

export const SupportLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 20px;
  /* min-width: 113px; */
  height: 100%;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  cursor: pointer;
  &:after {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: ${COLORS_CONFIG.SKY_BLUE};
    content: '';
  }
  &:hover {
    &:after {
      display: block;
    }
  }
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 12px;
    font-size: 14px;
  }
`;
