/* Libs */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isUndefined, isEmpty } from 'lodash';

/* Actions */

import { AdminCompanyEntity, AuthEntity } from '_entities';

/* Utils */

import { getStaticData } from 'utils/custom';

/* Components */

import {
  Logo,
  CompanyAdminNavbarList,
  CompanyAdminNavbarItem,
  CompanyAdminHeaderInfoSection,
  Img,
} from 'components';

/* Constants */

import { URLS_CONFIG, LOGO_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function CompanyAdminHeader({
  role,
  logo,
  getCompany,
  companyId,
  getInitialUserData,
  companyLogo,
}) {
  const roles = getStaticData('roles');
  const ROUTES = (() => {
    switch (role) {
      case roles.host:
      case roles.company_host:
        return [
          {
            path: URLS_CONFIG.host.calendar,
            label: 'Calendar',
          },
          {
            path: URLS_CONFIG.host.campaigns,
            label: 'Campaigns',
          },
        ];
      case roles.company_admin:
      default:
        return [
          {
            path: URLS_CONFIG.companyAdmin.company,
            label: 'Company',
          },
          {
            path: URLS_CONFIG.companyAdmin.calendar,
            label: 'Calendar',
          },
          {
            path: URLS_CONFIG.companyAdmin.campaigns,
            label: 'Campaigns',
          },
          {
            path: URLS_CONFIG.companyAdmin.reports.default,
            label: 'Reports',
          },
          {
            path: URLS_CONFIG.companyAdmin.users,
            label: 'Users',
          },
        ];
    }
  })();

  useEffect(() => {
    companyId
      ? getCompany(companyId)
      : isUndefined(companyLogo) && getInitialUserData();
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Logo>
        { companyLogo || logo ? <Img src={companyLogo || logo} size={[205, 80]} /> : <Logo type={LOGO_CONFIG.HORIZONTAL_BLUE_BLACK} />}
      </Styled.Logo>
      <Styled.Navigation>
        <CompanyAdminNavbarList>
          {
            ROUTES.map(route => (
              <CompanyAdminNavbarItem
                key={route.label}
                url={route.path}
                label={route.label}
              />
            ))
          }
          <Styled.SupportLink href={URLS_CONFIG.support} target="_blank">
            Support
          </Styled.SupportLink>
        </CompanyAdminNavbarList>
      </Styled.Navigation>
      <Styled.UserInfo>
        <CompanyAdminHeaderInfoSection />
      </Styled.UserInfo>
    </Styled.Wrapper>
  );
}

/* CompanyAdminHeader type of props */

CompanyAdminHeader.propTypes = {};

/* CompanyAdminHeader default props */

CompanyAdminHeader.defaultProps = {};

const mapStateToProps = state => ({
  role: state.auth.role,
  companyId: state.auth.company,
  logo: state.adminCompany.company.logo,
  companyLogo: state.auth.companyLogo,
});

export default connect(mapStateToProps, {
  getCompany: AdminCompanyEntity.actions.getCompany,
  getInitialUserData: AuthEntity.actions.getInitialUserData,
})(CompanyAdminHeader);
