import get from 'lodash.get';

export default data => ({
  id: data.id,
  name: data.name,
  start_date: data.start_date || get(data, 'contract.start_date'),
  licenses: data.licenses,
  is_active: data.is_active,
  email: get(data, 'admin.email', ''),
  first_name: get(data, 'admin.first_name', ''),
  last_name: get(data, 'admin.last_name', ''),
  role: get(data, 'admin.role', ''),
  deactivation_date: data.deactivation_date,
});
