/* Libs */
import React from 'react';
import PropTypes from 'prop-types';


/* Components */
import {
  Button,
  LaunchHeader,
  CircleWithSign, Input,
} from 'components';

/* Constants */
import { PARTICIPANTS_COUNT_RANGE } from '_constants';
import { COLORS_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

function Anonymous({
  location,
  utc_start_time,
  isFastSession,
  participantCount,
  handleParticipantsCount,
  handleParticipantsCountOnBlurt,
  increase,
  decrease,
  next,
  back,
  isDarkMode,
}) {
  return (
    <Styled.Wrapper>
      <LaunchHeader
        title="Launching Game!"
        location={location}
        utcStartTime={utc_start_time}
        subtitle={!isFastSession}
        isAnonymous
      />
      <Styled.Subtitle>
        Participants
      </Styled.Subtitle>
      <Styled.MainSection>
        <Styled.Action>
          <CircleWithSign
            sign="-"
            color={COLORS_CONFIG.WHITE}
            onClick={decrease}
            disabled={participantCount <= PARTICIPANTS_COUNT_RANGE.MIN}
            isDarkMode={isDarkMode}
          />
        </Styled.Action>
        <Styled.InputParticipantsCountWrapper>
          <Input
            name="newParticipantCount"
            value={participantCount}
            onChange={handleParticipantsCount}
            onBlur={handleParticipantsCountOnBlurt}
          />
        </Styled.InputParticipantsCountWrapper>
        <Styled.Action>
          <CircleWithSign
            sign="+"
            color={COLORS_CONFIG.WHITE}
            onClick={increase}
            disabled={participantCount >= PARTICIPANTS_COUNT_RANGE.MAX}
            isDarkMode={isDarkMode}
          />
        </Styled.Action>
      </Styled.MainSection>
      <Styled.Actions>
        <Button onClick={next}>
          Next
        </Button>
        <Button onClick={back}>
          Cancel
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* Anonymous type of props */
Anonymous.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.shape({
      location: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.number,
  ]),
  utc_start_time: PropTypes.string,
  isFastSession: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  increase: PropTypes.func.isRequired,
  decrease: PropTypes.func.isRequired,
  participantCount: PropTypes.number.isRequired,
  handleParticipantsCount: PropTypes.func.isRequired,
  handleParticipantsCountOnBlurt: PropTypes.func.isRequired,
};

/* Anonymous default props */
Anonymous.defaultProps = {
  utc_start_time: null,
  isFastSession: false,
  isDarkMode: false,
};

export default Anonymous;
