/* Libs */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, merge } from 'lodash';

/* Constants */

import { TIME_OPTIONS, PARTICIPANTS_COUNT_RANGE, PERMISSION_NAMES } from '_constants';

/* Utils */

import { isValidNumbersField } from 'utils/validation';
import { hasPermission } from 'utils/custom';
import { validateNumber } from 'utils/validation/fields';

/* Components */

import {
  Modal, SettingsSection, Select, Input, ConfirmToggle, Switcher,
} from 'components';

/* Styles */

import * as Styled from './styles';

const DEFAULT_ERRORS = {
  participantsPerSession: false,
};


function CampaignSettingsModal({
  onClose,
  open,
  campaign: {
    id,
    introduction_time: introductionTime,
    debriefing_time: debriefingTime,
    participants_per_session: participantsPerSession,
    is_timer_counting_up: isTimerCountingUp,
    allow_multiple_signup: allowMultipleSignup,
  },
  closeCampaign,
  deleteCampaign,
  changeCampaignSettings,
}) {
  if (!open) return null;

  const [data, updateData] = useState({
    introductionTime,
    debriefingTime,
    participantsPerSession,
    allowMultipleSignup,
  });
  const [errors, setErrors] = useState(DEFAULT_ERRORS);
  const [isTimerCountingUpOn, setTimerType] = useState(isTimerCountingUp);
  const changeData = ({ target: { name, value } }) => updateData({
    ...data,
    [name]: value,
  });
  const changeParticipantsPerSession = ({ target: { name, value } }) => {
    if (!isValidNumbersField(value) || +value > PARTICIPANTS_COUNT_RANGE.MAX) return;
    changeData({ target: { name, value } });
    setErrors(prev => ({
      ...prev,
      participantsPerSession: validateNumber({ value, required: false }),
    }));
  };

  const changeParticipantsPerSessionOnBlur = ({ target: { name, value } }) => {
    if (!isValidNumbersField(value) || +value > PARTICIPANTS_COUNT_RANGE.MAX) return;
    const participantValue = isEmpty(value) ? PARTICIPANTS_COUNT_RANGE.MIN : value;
    changeData({ target: { name, value: participantValue } });
    setErrors(prev => ({
      ...prev,
      participantsPerSession: validateNumber({ value: participantValue, required: false }),
    }));
  };
  const toggleTimerType = () => setTimerType(!isTimerCountingUpOn);

  const submitHandler = () => {
    const requestData = merge(
      { id },
      introductionTime === data.introductionTime ? {} : { introduction_time: data.introductionTime },
      debriefingTime === data.debriefingTime ? {} : { debriefing_time: data.debriefingTime },
      participantsPerSession == data.participantsPerSession ? {} : { participants_per_session: +data.participantsPerSession },
      allowMultipleSignup === data.allowMultipleSignup ? {} : { allow_multiple_signup: data.allowMultipleSignup },
      isTimerCountingUpOn === isTimerCountingUp ? {} : { is_timer_counting_up: isTimerCountingUpOn },
    );

    changeCampaignSettings(requestData);
  };

  const sectionParts = [
    {
      subTitle: 'Introduction time',
      components: [
        <Select
          name="introductionTime"
          key="introductionTime"
          options={TIME_OPTIONS}
          value={data.introductionTime}
          onChange={changeData}
          placeholder="Select introduction time"
          withoutReset
          error={false}
        />,
      ],
    },
    {
      subTitle: 'Debriefing time',
      components: [
        <Select
          name="debriefingTime"
          key="debriefingTime"
          options={TIME_OPTIONS}
          value={data.debriefingTime}
          onChange={changeData}
          placeholder="Select debriefing time"
          withoutReset
          error={false}
        />,
      ],
    },
    {
      subTitle: 'Participants per session',
      infoText: `The number of participants should be from ${PARTICIPANTS_COUNT_RANGE.MIN} to ${PARTICIPANTS_COUNT_RANGE.MAX}`,
      components: [
        <Input
          name="participantsPerSession"
          key="participantsPerSession"
          value={data.participantsPerSession}
          onChange={changeParticipantsPerSession}
          onBlur={changeParticipantsPerSessionOnBlur}
          placeholder="Enter participants per session"
          error={errors.participantsPerSession}
          hideErrorText
        />,
      ],
    },
    {
      subTitle: 'Game timer type',
      components: [
        <Switcher
          checked={isTimerCountingUpOn}
          onChange={toggleTimerType}
          checkedText="Count up"
          uncheckedText="Count down"
          key="timerType"
        />,
      ],
    },
    {
      subTitle: 'Allow multiple Sign Up',
      infoText: 'If “yes” - no restriction for participant emails, if “no” then 1 unique email can take a part just in 1 session',
      components: [
        <Switcher
          name="allowMultipleSignup"
          checked={data.allowMultipleSignup}
          onChange={value => changeData({ target: { name: 'allowMultipleSignup', value } })}
          checkedText="Yes"
          uncheckedText="No"
          key="timerType"
        />,
      ],
    },
  ];
  const isSubmitButtonEnable = () => introductionTime !== data.introductionTime
    || debriefingTime !== data.debriefingTime
    || participantsPerSession != data.participantsPerSession
    || allowMultipleSignup !== data.allowMultipleSignup
    || isTimerCountingUp !== isTimerCountingUpOn;

  return (
    <Modal
      title="Campaign settings"
      titleAdditionalComponent={
        hasPermission(PERMISSION_NAMES.DELETE_CAMPAIGN)
          ? (
            <Styled.CampaignActions>
              <ConfirmToggle onSubmit={closeCampaign} toggleText="Close" />
              <ConfirmToggle onSubmit={deleteCampaign} toggleText="Delete" />
            </Styled.CampaignActions>
          )
          : <ConfirmToggle onSubmit={closeCampaign} toggleText="Close campaign" />
      }
      open={open}
      // onlyContentCloseClick
      onClose={onClose}
      style={{ maxWidth: '660px' }}
      withoutScroll
    >
      <SettingsSection
        onClose={onClose}
        onSubmit={submitHandler}
        sectionParts={sectionParts}
        isSubmitButtonEnable={isSubmitButtonEnable()}
      />
    </Modal>
  );
}

// Type of props
CampaignSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.number,
    introduction_time: PropTypes.string,
    debriefing_time: PropTypes.string,
    participants_per_session: PropTypes.number,
    is_timer_counting_up: PropTypes.bool,
  }).isRequired,
  closeCampaign: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
};

export default CampaignSettingsModal;
