/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const Wrapper = styled.div`
  width: 100%;
  height: 87px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.DARK_GRAY};
  font-size: 15px;
  line-height: 18px;
`;

export const Value = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${({ color }) => color};
  font-size: 17px;
  line-height: 20px;
`;
