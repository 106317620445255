import { COLORS_CONFIG } from 'config';

export default (percent) => {
  if (percent <= 29) {
    return COLORS_CONFIG.ORANGE;
  } if (percent <= 69) {
    return COLORS_CONFIG.SOFT_ORANGE;
  }
  return COLORS_CONFIG.GREEN_CUSTOM_1;
};
