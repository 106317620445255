/* Libs */
import React from 'react';

/* Utils */


/* Components */

import {
  Logo,
} from 'components';

/* Constants */

import { LOGO_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function LeaderboardHeader({
  isDarkMode,
}) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <Styled.Logo>
        <Logo type={isDarkMode ? LOGO_CONFIG.HORIZONTAL_BLUE_WHITE : LOGO_CONFIG.HORIZONTAL_BLUE_BLACK} />
      </Styled.Logo>
    </Styled.Wrapper>
  );
}

LeaderboardHeader.propTypes = {};

export default LeaderboardHeader;
