import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { Button } from 'components/ui/Button/styles';
import { Wrapper as StatisticItem } from 'components/blocks/ListItems/GameStatistic/styles';
import BREAKPOINTS from '_constants/BREAKPOINTS';


export const Actions = styled.div`
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  align-items: center;
`;

export const Title = styled.div`
  font-size: 32px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;

export const Hint = styled.div`
  font-size: 18px;
  text-align: center;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  padding-top: 21px;
  margin: 0 0 62px;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
     margin: 0 0 42px;
  }
  
`;

export const Statistic = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  padding: 58px 0;
  width: 553px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
     padding: 35px 0;
  }

  & ${Button} {

    &:first-child {
      color: ${COLORS_CONFIG.SMOOTH_BROWN};
      background-color: ${COLORS_CONFIG.WHITE};
      border: 1px solid ${COLORS_CONFIG.SMOOTH_BROWN};
      transition: opacity .2s;

      &:hover {
        opacity: .6;
      }
    }
  }

  ${StatisticItem} {
    border-bottom: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
  }

  ${({ isDarkMode }) => isDarkMode && css`
    background-color: ${COLORS_CONFIG.DARK_BLUE};

    & ${Button}{
      &:first-child {
        color: ${COLORS_CONFIG.NAVY_BLUE};
        background-color: ${COLORS_CONFIG.WHITE};
        border: none;
      }
      &:last-child {
        color: ${COLORS_CONFIG.WHITE};
        background-color: ${COLORS_CONFIG.SKY_BLUE};
      }
    }

    & ${Title}, ${Hint}{
      color: ${COLORS_CONFIG.WHITE};
    }

    ${StatisticItem} {
      border-bottom: 1px solid ${COLORS_CONFIG.OXFORD_BLUE};
    }
  `}
`;
