/* Libs */
import styled from 'styled-components';

/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/* Components */
import { Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import { Wrapper as InputWrapper, Input } from 'components/ui/Input/styles';
import { Wrapper as CalendarHeaderWrapper } from 'components/blocks/ui/CalendarHeader/styles';
import { GoBack } from 'components/ui/GoBackButton/styles';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';
import { CustomScroll, TextEllipsis } from 'assets/styles/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: 1100px;
  height: 630px;
  border-radius: 10px;
  background: ${COLORS_CONFIG.SILVER};
  overflow: hidden;
`;

export const Sidebar = styled.div`
  padding: 40px 25px;
  width: 340px;
  background: ${COLORS_CONFIG.WHITE};
  ${InputWrapper} {
    margin-bottom: 15px;
  }
  ${Input} {
    padding-right: 20px;
    border-radius: 4px;
    border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
    &:hover {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
    &:disabled {
      background: ${COLORS_CONFIG.TRANSPARENT};
      color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      &:hover {
        border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      }
    }
  }
  ${SelectWrapper} {
    margin-bottom: 35px;
  }
  ${CalendarHeaderWrapper} {
    margin-bottom: 30px;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 40px 30px;
  height: 100%;
  overflow: auto;
  ${CustomScroll}
  
  ${GoBack} {
    display: inline-block;
    margin-bottom: 10px;
  }
  ${CalendarHeaderWrapper} {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  ${BigTitle} {
    margin-bottom: 25px;
    width: 100%
    font-size: 32px;

    ${TextEllipsis}
  }
`;

export const Note = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  margin-bottom: 20px;
`;
