/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

export const Place = styled.div`
  width: 8%;
  text-align: left;
`;

export const Team = styled.div`
  width: 43%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${BREAKPOINTS.LAPTOP}) {
    width: 37%;
  }
`;

export const Value = styled.div`
  width: 11%;
  text-align: center;
`;

export const Time = styled.div`
  text-align: right;
  width: 34%;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & ${Place},
  & ${Team},
  & ${Value},
  & ${Time} {
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    color: ${COLORS_CONFIG.DARK_GRAY};
    font-size: 14px;
    line-height: 18px;
    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      font-size: 12px;
    }
  }
`;
