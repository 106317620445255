/* Libs */
import styled from 'styled-components';

/* Configs */
import { COLORS_CONFIG } from 'config';

/* Components */
import { Wrapper as LoaderWrapper, Loader } from 'components/blocks/ui/Loader/styles';
import { Wrapper as RulesTooltipWrapper } from 'components/blocks/ui/RulesTooltip/styles';
import { default as Triangle } from 'components/blocks/Markers/TopTriangle/styles';


export const GlobalSettingsModal = styled.div`
  ${LoaderWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    background: ${COLORS_CONFIG.WHITE$72};
  }
  ${Loader} {
    max-height: 100px;
  }
  ${RulesTooltipWrapper} {
    left: initial;
    right: 0;
  }
  ${Triangle} {
    left: initial;
    right: 15px;
  }
`;
