/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { validateEmail, validateText } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { VALIDATION_VALUES } from '_constants';

/* Components */

import {
  Input,
  Button,
  SmallTitle,
} from 'components';

/* Styles */

import * as Styled from './styles';


function PointOfContact({
  data: INITIAL_DATA,
  errors: INITIAL_ERRORS,
  updateData,
  prevStep,
  save,
  loading,
}) {
  const [formData, setData] = useState({
    data: INITIAL_DATA,
    errors: INITIAL_ERRORS,
    canSubmit: false,
  });
  const [saveAttempting, trySaving] = useState(false);

  useEffect(() => {
    if (saveAttempting) {
      trySaving(false);
      save();
    }
  }, [INITIAL_DATA]);

  useEffect(() => {
    setData(prev => ({
      ...prev,
      canSubmit: formIsValid({ ...errors }, ['email', 'first_name', 'last_name']),
    }));
  }, []);


  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };

    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({ value, name, max: VALIDATION_VALUES.EMAIL_MAX_LENGTH });
        break;
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({ value, name, max: VALIDATION_VALUES.NAME_MAX_LENGTH });
        break;
      default:
        break;
    }

    setData({
      data: { ...data, [name]: value },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email', 'first_name', 'last_name']),
    });
  };

  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };

    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({ value, name, max: VALIDATION_VALUES.EMAIL_MAX_LENGTH });
        break;
      case 'first_name':
      case 'last_name':
        newErrors[name] = validateText({ value, name, max: VALIDATION_VALUES.NAME_MAX_LENGTH });
        break;
      default:
        break;
    }

    setData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email', 'first_name', 'last_name']),
    }));
  };

  const updateAllData = () => updateData(formData.data, formData.errors);

  const handleBack = () => {
    updateAllData();
    prevStep();
  };

  const saveAttempt = () => {
    updateAllData();
    trySaving(true);
  };

  const { data, errors, canSubmit } = formData;

  return (
    <Styled.Wrapper>
      <SmallTitle>
        Point of Contact
      </SmallTitle>
      <Input
        placeholder="First name"
        title="First name"
        name="first_name"
        error={errors.first_name}
        value={data.first_name}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Input
        placeholder="Last name"
        title="Last name"
        name="last_name"
        error={errors.last_name}
        value={data.last_name}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Input
        placeholder="Email"
        title="Email"
        name="email"
        type="email"
        error={errors.email}
        value={data.email}
        onChange={handleChange}
        onBlur={validateOnBlur}
      />
      <Styled.Buttons>
        <Button inverted onClick={handleBack}>Back</Button>
        <Button
          disabled={loading || !canSubmit}
          isLoading={loading}
          onClick={saveAttempt}
        >
          Add company
        </Button>
      </Styled.Buttons>
    </Styled.Wrapper>
  );
}

/* PointOfContact type of props */

PointOfContact.propTypes = {
  data: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  prevStep: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PointOfContact;
