/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/* Constants */
import { AVATAR_CONFIG } from 'config';

/* Components */

import {
  SmallTitle,
  Button,
  Avatar,
  Loader,
} from 'components';

/* Styles */

import * as Styled from './styles';

function AvailableStorylines({
  handleChange,
  onDoubleClick,
  nextStep,
  selectedStoryline,
  storylines,
  loading,
  pagination,
}) {
  return (
    <Styled.Wrapper withScroll={storylines.length > 3}>
      <SmallTitle>
        Select an available storyline:
      </SmallTitle>
      {
        loading
          ? <Loader />
          : (
            <Styled.StorylinesList>
              {
                storylines.length
                  ? storylines.map(storyline => (
                    <Styled.Storyline
                      key={storyline.id}
                      onClick={() => handleChange({ target: { name: 'selectedStoryline', value: storyline } })}
                      onDoubleClick={() => onDoubleClick(storyline)}
                      selected={storyline.id === selectedStoryline.id}
                    >
                      <Avatar avatar={storyline.image} size={AVATAR_CONFIG.SIZE.M} />
                      <Styled.StorylineName>{storyline.name}</Styled.StorylineName>
                    </Styled.Storyline>
                  ))
                  : <Styled.NoStorylines>No storylines</Styled.NoStorylines>
              }
            </Styled.StorylinesList>
          )
      }
      {pagination}
      <Button
        disabled={isEmpty(selectedStoryline)}
        onClick={nextStep}
      >
        Continue
      </Button>
    </Styled.Wrapper>
  );
}

/* AvailableStorylines type of props */

AvailableStorylines.propTypes = {
  storylines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
    }),
  ),
  selectedStoryline: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    image: PropTypes.string,
  }),
  nextStep: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.node.isRequired,
};

AvailableStorylines.defaultProps = {
  storylines: [],
  selectedStoryline: {},
};

export default AvailableStorylines;
