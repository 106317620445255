import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import * as Styled from './styles';

const TextButton = ({ children, onClick }) => (
  <Styled.Button onClick={onClick}>
    {children}
  </Styled.Button>
);

TextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TextButton;
