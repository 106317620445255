/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactCalendar from 'react-calendar';

/* Constants */

// import { DATE_SETTINGS } from 'config';

/* Components */

import { Cell } from './Subcomponents';

/* Styles */

import * as Styled from './styles';

function CalendarExpanded({
  name,
  value,
  events,
  onDayChange,
  isDayVisible,
  isCurrentDayVisible,
}) {
  const hasDateEvent = date => events.some(event => moment(event).isSame(date, 'day'));

  return (
    <Styled.Wrapper isDayVisible={isDayVisible} isCurrentDayVisible={isCurrentDayVisible}>
      <ReactCalendar
        locale="en"
        calendarType="US"
        onClickDay={e => onDayChange({ target: { name, value: new Date(e) } })}
        value={value}
        showNavigation={false}
        tileContent={({ date }) => (
          <Cell
            day={moment(date).format('D')}
            hasEvent={hasDateEvent(date)}
          />
        )}
        name={name}
      />
    </Styled.Wrapper>
  );
}

/* CalendarExpanded type of props */

CalendarExpanded.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  name: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired, // TODO: change it to shape,
  onDayChange: PropTypes.func.isRequired,
  isDayVisible: PropTypes.bool,
  isCurrentDayVisible: PropTypes.bool,
};

CalendarExpanded.defaultProps = {
  isDayVisible: true,
  isCurrentDayVisible: true,
};

export default memo(CalendarExpanded);
