/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

/* Actions */
import { ParticipantEntity } from '_entities';

/* Components */
import {
  BigTitle,
  ShareLink,
} from 'components';

import { getTimezoneTitle } from 'utils/custom';

/* Constants */
import { DATE_SETTINGS } from 'config';

/* Styles */
import * as Styled from './styles';


function ConfirmMessage({
  utcStartTime,
  meetingLink,
}) {
  const startDate = moment(utcStartTime).format(DATE_SETTINGS.FORMAT.DAY_MONTH_DATE_AT_TIME);
  const timezone = moment.tz.guess();

  return (
    <Styled.Wrapper>
      <BigTitle>
        You’re confirmed!
      </BigTitle>
      <Styled.Date>
        { `${startDate} ${getTimezoneTitle(timezone)}` }
      </Styled.Date>
      <Styled.Line />
      {
        meetingLink && (
          <>
            <Styled.Text>
              This experience is 100% online. Use the link below
            </Styled.Text>
            <ShareLink link={meetingLink} />
          </>
        )
      }
    </Styled.Wrapper>
  );
}

ConfirmMessage.propTypes = {
  utcStartTime: PropTypes.string.isRequired,
  meetingLink: PropTypes.string,
};

ConfirmMessage.defaultProps = {
  meetingLink: null,
};

export default connect(({ participant }) => ({
  utcStartTime: participant.gameSession.utc_start_time,
  meetingLink: participant.gameSession.meeting_link,
}), {
  finishSignup: ParticipantEntity.actions.finishSignup,
})(ConfirmMessage);
