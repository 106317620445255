/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG } from 'config';
import { GAME_PLAY_BG } from 'assets/icons/game_play';
import { Wrapper as LogoWrapper } from 'components/blocks/ui/Logo/styles';
import {
  Button,
} from 'components/ui/Button/styles';


export const TimerWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 50px;
`;

export const Content = styled.div`
  padding-top: 45px;
  & ${Button} {
    width: 160px;
    min-width: 160px;
  }
  & > * {
    height: 100%;
  }
`;

export const Container = styled.div`
  max-width: 90%;
  width: 800px;
  height: 600px;
  padding: 48px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  background-color: ${COLORS_CONFIG.WHITE};
  display: flex;
  flex-direction: column;
  & ${Content} {
    flex: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
  & ${LogoWrapper} {
    width: 230px;
  }
  & .vimeo{
    &-loading{
    display: none;
    }
    &-embed {
      & iframe {
        width: 705px!important;
        height: 297px!important;
      }
    }
  }
  
  ${({ isDarkMode }) => isDarkMode && css`
     background-color: ${COLORS_CONFIG.DARK_BLUE};
     background-image: url(${GAME_PLAY_BG});
     background-size: contain;
     background-repeat: no-repeat;
     background-position: center center;
     
     &:before{
       position: absolute;
       content: '';
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       background: ${COLORS_CONFIG.NAVY_BLUE$70};
      }
      
      & ${Container} {
         background: ${COLORS_CONFIG.DARK_BLUE};         
      }   
  `}
`;
