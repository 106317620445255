import { COMMON_ERRORS, QUESTION_ERRORS } from '_constants/errors';
import { isEmpty, isMoreThan, isNull } from 'utils/validation';

export default ({
  value,
  anotherQuestions,
  name = 'Unlock code',
  max = null,
  final_code = null,
  customErrors = {},
  initial = false,
  required = true,
  regex = null,
}) => {
  let error = (required && isEmpty(value) && COMMON_ERRORS.isRequired(name))
    || (!isNull(max) && isMoreThan(value, max, true) && COMMON_ERRORS.lessThan(name, max))
    || (!isNull(regex) && !regex.test(value) && COMMON_ERRORS.onlyDigitsAndLetters)
    || (final_code === value && QUESTION_ERRORS.unlockDiffrentFinalCode)
    || (!!anotherQuestions.find(question => value === question.unlock_code) && QUESTION_ERRORS.unlockCodeMustBeUniq);
  error = error && initial ? null : error;
  return error;
};
