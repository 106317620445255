/* Libs */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* Actions */
import { AuthEntity } from '_entities';

/* Utils */
import {
  hasDigit,
  isMoreThan,
  formIsValid,
  hasCapitalLetter,
} from 'utils/validation';
import { getError, redirect } from 'utils/custom';
import { notification } from 'utils/services';
import {
  isEmpty,
} from 'lodash';
import { validateText, validateNewPassword } from 'utils/validation/fields';

/* Components */
import {
  Input,
  Button,
} from 'components';

/* Constants */
import { URLS_CONFIG } from 'config';
import { NOTIFICATIONS, TOOLTIP_POSITION } from '_constants';
import { PASSWORD_RULES } from '_constants/rules';

/* Styles */
import * as Styled from './styles';


const DEFAULT_DATA = {
  password1: null,
  password2: null,
};
const DEFAULT_ERRORS = {
  password1: null,
  password2: null,
};

function NewPassword({
  confirmNewPassword,
  token,
}) {
  const [formData, setFormData] = useState({
    data: DEFAULT_DATA,
    errors: DEFAULT_ERRORS,
    loading: false,
    canSubmit: false,
  });
  const [isOpenRules, setOpensRules] = useState(false);
  const [rules, setRules] = useState(null);


  function checkRules(value) {
    if (isEmpty(value)) return;
    return [
      { name: PASSWORD_RULES._8Char, passed: isMoreThan(value, 7, true) },
      { name: PASSWORD_RULES._1Capital, passed: hasCapitalLetter(value) },
      { name: PASSWORD_RULES.digits, passed: hasDigit(value) },
    ];
  }

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'password1':
        newErrors[name] = validateNewPassword({ value, name: 'password' });
        setRules(checkRules(value));
        if (!isEmpty(data.password2)) {
          newErrors.password2 = data.password2 !== value && 'Passwords don\'t match';
        }
        break;
      case 'password2':
        newErrors[name] = validateText({ value, name: 'confirm_password' }) || (data.password1 !== value && 'Passwords don\'t match');
        break;
      default:
        break;
    }
    setFormData({
      data: { ...data, [name]: value },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['password1', 'password2']),
    });
  };

  const submit = async () => {
    setFormData({ ...formData, loading: true });
    try {
      await confirmNewPassword({ ...formData.data, token });
      redirect(URLS_CONFIG.auth.signin, NOTIFICATIONS.SUCCESS_PASSWORD_RESET);
    } catch (error) {
      const errorText = getError(error);
      const passwordListError = [
        NOTIFICATIONS.ERROR_PASSWORD_TOO_SHORT,
        NOTIFICATIONS.ERROR_PASSWORD_ENTIRELY_NUMERIC,
        NOTIFICATIONS.ERROR_PASSWORD_WITHOUT_CAPITAL_LETTER,
      ];
      const isInPasswordListError = passwordListError.some(item => getError(error).includes(item));
      setFormData(prev => ({
        ...prev,
        ...(isInPasswordListError && {
          errors: {
            ...prev.errors,
            password1: NOTIFICATIONS.ERROR_PASSWORD_DOES_NOT_MEET_REQUIREMENTS,
          },
        }),
        loading: false,
        canSubmit: false,
      }));

      const checkPasswordList = () => (isInPasswordListError
        ? setOpensRules(true)
        : notification.error(errorText));

      checkPasswordList();
    }
  };
  const {
    data, errors, loading, canSubmit,
  } = formData;

  return (
    <Styled.Wrapper>
      <Input
        placeholder="Password"
        name="password1"
        error={errors.password1}
        value={data.password1}
        onChange={handleChange}
        onBlur={handleChange}
        type="password"
        rules={rules}
        rulesPosition={TOOLTIP_POSITION.RIGHT}
        setOpensRules={setOpensRules}
        isOpenRules={isOpenRules}
      />
      <Input
        placeholder="Confirm password"
        name="password2"
        error={errors.password2}
        value={data.password2}
        onChange={handleChange}
        onBlur={handleChange}
        type="password"
      />
      <Button
        isLoading={loading}
        disabled={loading || !canSubmit}
        onClick={submit}
      >
        Submit
      </Button>
    </Styled.Wrapper>
  );
}

/* NewPassword type of props */

NewPassword.propTypes = {
  confirmNewPassword: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(
  null,
  { confirmNewPassword: AuthEntity.actions.confirmNewPassword },
)(NewPassword);
