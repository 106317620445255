/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/* Styles */

import * as Styled from './styles';

function SessionTimeCell({
  startTime, endTime, timezoneTitle, label,
}) {
  const timezoneParts = timezoneTitle.split('/');
  const timezoneFirstString = `${timezoneParts.slice(0, 2).join('/')}/`;
  const timezoneSecondString = timezoneParts.slice(2).join('/');

  return (
    <Styled.Wrapper>
      {
        !isEmpty(label) && (
          <Styled.Label>
            {label}
          </Styled.Label>
        )
      }
      <Styled.SessionTime>
        {`${startTime} - ${endTime}`}
      </Styled.SessionTime>
      <Styled.SessionTime>
        {timezoneParts.length > 2 ? (
          <div>
            {timezoneFirstString}
            <br />
            {timezoneSecondString}
          </div>
        ) : timezoneTitle}
      </Styled.SessionTime>
    </Styled.Wrapper>
  );
}

/* SessionTimeCell type of props */

SessionTimeCell.propTypes = {
  upperValue: PropTypes.string.isRequired,
  downValues: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

/* SessionTimeCell default props */

SessionTimeCell.defaultProps = {
  downValues: [],
  label: null,
};

export default SessionTimeCell;
