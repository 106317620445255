/* Libs */
import styled from 'styled-components';

/* Components */
import {
  Wrapper as ConfirmToggleWrapper,
  Text,
} from 'components/ui/ConfirmToggle/styles';

/* Constants */
import { COLORS_CONFIG } from 'config';


export const CampaignActions = styled.div`
  display: flex;
  align-items: center;
  ${ConfirmToggleWrapper} {
    &:first-child {
      position: relative;
      padding-right: 20px;
      border-right: 1px solid ${COLORS_CONFIG.SILVER_CUSTOM_1};
    }
    &:last-child {
      padding-left: 20px;
      ${Text} {
        color: ${COLORS_CONFIG.ORANGE};
      }
    }
  }
`;
