/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

import recycle from 'assets/icons/recycle.svg';

/* Styles */

import * as Styled from './styles';

function Refresh({
  refresh,
  loading,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Refresh
        isLoading={loading}
        src={recycle}
        onClick={loading ? null : refresh}
      />
    </Styled.Wrapper>
  );
}

/* Refresh type of props */

Refresh.propTypes = {
  refresh: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Refresh.defaultProps = {
  loading: false,
};
export default Refresh;
