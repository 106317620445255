import { GAME_DEF_CONFIG } from 'config';
import {
  validateText,
  validateAnswer,
  validateUnlockCode,
  validateQuestionName,
  validateQuestionField,
} from 'utils/validation/fields';

import { formIsValid } from 'utils/validation';

export default ({ questionForValidate, otherQuestions, final_code = null }) => {
  const {
    correct_answer_message,
    hint_text,
    incorrect_answer_message,
    intro_vimeo_id,
    name,
    question,
    unlock_code,
    answers,
    security_context,
    isNew: initial,
  } = questionForValidate;
  const initialErrors = {
    ...GAME_DEF_CONFIG.DEFAULT_ERRORS.QUESTION,
    correct_answer_message: validateText({
      initial, name: 'correct_answer_message', value: correct_answer_message, max: 2000,
    }),
    incorrect_answer_message: validateText({
      initial, name: 'incorrect_answer_message', value: incorrect_answer_message, max: 2000,
    }),
    name: validateQuestionName({
      initial, name: 'name', value: name, anotherQuestions: otherQuestions,
    }),
    intro_vimeo_id: validateText({
      initial, name: 'intro_vimeo_id', value: intro_vimeo_id, required: false, max: 500,
    }),
    security_context: validateText({
      initial, name: 'security_context', value: security_context, required: false, max: 500,
    }),
    question: validateQuestionField({
      initial, name: 'question', value: question, anotherQuestions: otherQuestions, max: 500,
    }),
    unlock_code: validateUnlockCode({
      initial, value: unlock_code, max: 20, anotherQuestions: otherQuestions, final_code,
    }),
    hint_text: validateText({
      initial, name: 'hint_text', required: false, value: hint_text, max: 500,
    }),
    answers: answers.map((answer, idx) => validateAnswer({
      currentAnswerNumber: idx, initial, answers, name: `Answer ${idx + 1}`, value: answer, max: 500,
    })),
  };
  return { isValid: formIsValid(initialErrors, GAME_DEF_CONFIG.REQUIRED_FIELDS.QUESTION), errors: initialErrors };
};
