import {
  isEmpty, flatten, values, isArray,
} from 'lodash';
import { notification } from 'utils/services';

const showErrorsFromBackend = (error) => {
  if (!error || isEmpty(error)) {
    return notification.error('Error! Try again!');
  }

  if (typeof error === 'string') {
    return notification.error(error);
  }

  const arrayOfErrors = values(error.response.data);
  const isArrayOfArrays = arrayOfErrors.every(item => isArray(item));
  const messages = isArrayOfArrays ? flatten(arrayOfErrors) : arrayOfErrors;

  messages.forEach((message) => {
    notification.error(message);
  });
};

export default showErrorsFromBackend;
