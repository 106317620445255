/* Libs */
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px 40px 48px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 455px; /* we need that becouse date pickers need some space */
`;
export const InputGroup = styled.div`
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > *:first-child {
    flex: 1;
  }
  & > *:last-child {
    margin-left: 24px;
    flex: 1;
  }
`;
