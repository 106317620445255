/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';


import { Table } from 'components/blocks/Table/styles';

export const Wrapper = styled.div`
  padding: 22px 32px;
  background-color: ${COLORS_CONFIG.WHITE};
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
  border-radius: 8px;
  & ${Table} {
    box-shadow: none;
    & .rt-table {
      border-radius: 0px;
    }
  }
`;
