/* Libs */
import styled, { css } from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import ReactMaskInput from 'react-input-mask';
import { Wrapper as ImgWrapper } from 'components/blocks/ui/Img/styles';

export const Error = styled.div`
  color: ${COLORS_CONFIG.ORANGE};
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc( 100% + 2px );
  left: 0;
  width: 100%;
  display: none;
`;

export const Input = styled(ReactMaskInput)`
  background-color: ${COLORS_CONFIG.WHITE};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  ${({ showPlaceholder }) => showPlaceholder && css`
    color: ${COLORS_CONFIG.SMOOTH_BROWN$70};
  `}
  width: 100%;
  height: 48px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 16px;
  padding: 15px 24px;
  ${({ hasError }) => css`
    border: 1px solid ${hasError ? 'red' : COLORS_CONFIG.SKY_BLUE};
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  ${({ onClick }) => onClick && css`
    ${Input} {
      cursor: pointer;
    }
  `}
  ${({ hasIcon }) => hasIcon && css`
    ${Input} {
      padding-right: 40px;
    }
    ${ImgWrapper} {
      ${({ isIconClickable }) => isIconClickable && css`
        cursor: pointer;
      `}
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  `}

  ${({ hasError }) => hasError && css`
    ${Input} {
      border-color: ${COLORS_CONFIG.ORANGE};
      color: ${COLORS_CONFIG.ORANGE};
    }
    ${Error} {
      display: block;
    }
  `}

  ${({ hasError }) => hasError === false && css`
    ${Input} {
      border-color: ${COLORS_CONFIG.LIGHT_GREEN};
    }
  `}
  ${({ disabled }) => disabled && css`
    pointer-events: none;

    ${Input} {
      border-color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
      background: ${COLORS_CONFIG.TRANSPARENT};      
      color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
    }
 `}
`;
