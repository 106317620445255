/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

/* Actions */

import { GameEntity } from '_entities';

/* Components */

import { BeforeGameStruct, GameCards, ContainerLoader } from 'components';

/* Constants */

import { GAME_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

const STEP_CHAIN = [
  GAME_CONFIG.STEP.INTRODUCTION,
  GAME_CONFIG.STEP.INSTRUCTIONS,
  GAME_CONFIG.STEP.STORYLINE,
];

function BeforeGame({
  step,
  loading,
  changeStep,
  isDarkMode,
  ...props
}) {
  const [steps, setSteps] = useState([]);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setSteps(STEP_CHAIN.reduce((acc, item) => {
      if (
        isEmpty(props[`${item}_title`])
        && isEmpty(props[`${item}_text`])
        && isEmpty(props[`${item}_vimeo_id`])
      ) return acc;
      return acc.concat(item);
    }, []).concat(GAME_CONFIG.STEP.GAME_PLAY));
  }, []);

  const removeCurrentStep = () => {
    setSteps(prev => prev.filter((item, i) => i !== index));
  };

  useEffect(() => {
    if (!steps[index]) return;
    changeStep(steps[index]);
  }, [steps[index]]);

  const handleNext = () => setIndex(prev => prev + 1);

  const getGoBack = () => {
    if (index - 1 < 0) return false;
    return () => setIndex(prev => prev - 1);
  };

  const isLastStep = index >= steps.length - 2;

  function manageDisplay(hideStep) {
    switch (step) {
      case GAME_CONFIG.STEP.INTRODUCTION:
        return (
          <GameCards.Introduction
            next={handleNext}
            title={props.introduction_title}
            text={props.introduction_text}
            vimeoId={props.introduction_vimeo_id}
            removeCurrentStep={removeCurrentStep}
            isLastStep={isLastStep}
            isDarkMode={isDarkMode}
          />
        );
      case GAME_CONFIG.STEP.INSTRUCTIONS:
        return (
          <GameCards.Instructions
            next={handleNext}
            back={getGoBack()}
            title={props.instruction_title}
            text={props.instruction_text}
            vimeoId={props.instruction_vimeo_id}
            removeCurrentStep={removeCurrentStep}
            isLastStep={isLastStep}
            isDarkMode={isDarkMode}
          />
        );
      case GAME_CONFIG.STEP.STORYLINE:
        return (
          <GameCards.Storyline
            next={handleNext}
            back={getGoBack()}
            title={props.storyline_title}
            text={props.storyline_text}
            vimeoId={props.storyline_vimeo_id}
            removeCurrentStep={removeCurrentStep}
            isDarkMode={isDarkMode}
          />
        );

      default:
        break;
    }
  }
  return (
    <BeforeGameStruct isDarkMode={isDarkMode}>
      { loading
        ? <ContainerLoader />
        : (
          <Styled.ContentWrapper>
            { manageDisplay() }
          </Styled.ContentWrapper>
        )
      }
    </BeforeGameStruct>
  );
}

/* BeforeGame type of props */

BeforeGame.propTypes = {
  step: PropTypes.oneOf([
    GAME_CONFIG.STEP.INTRODUCTION,
    GAME_CONFIG.STEP.INSTRUCTIONS,
    GAME_CONFIG.STEP.STORYLINE,
  ]),
  changeStep: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  introduction_text: PropTypes.string.isRequired,
  instruction_text: PropTypes.string.isRequired,
  storyline_text: PropTypes.string.isRequired,
};

/* BeforeGame default props */

BeforeGame.defaultProps = {};

export default connect(({ game }) => ({
  introduction_title: game.gameDef.introduction_title,
  introduction_text: game.gameDef.introduction_text,
  instruction_title: game.gameDef.instruction_title,
  instruction_text: game.gameDef.instruction_text,
  storyline_title: game.gameDef.storyline_title,
  storyline_text: game.gameDef.storyline_text,
  introduction_vimeo_id: game.gameDef.introduction_vimeo_id,
  instruction_vimeo_id: game.gameDef.instruction_vimeo_id,
  storyline_vimeo_id: game.gameDef.storyline_vimeo_id,
  isDarkMode: game.gameDef.is_dark_mode,
  loading: game.loading,
}), {
  changeStep: GameEntity.actions.changeStep,
})(BeforeGame);
