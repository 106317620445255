import moment from 'moment';

import { restoreGame } from 'utils/transformers';

import { QUESTION_STATUS, ANSWER_STATUS } from '_constants';
import {
  GAME_CONFIG,
  // DATE_SETTINGS
} from 'config';

import * as TYPES from './Game.types';

const initialState = {
  gameSessionId: null,
  gameDefId: null,

  gameSession: {},

  loaded: false,
  loading: false,

  token: '',
  campaignToken: '',
  is_finished: null,

  logo: null,

  invitedParticipants: [],
  newParticipants: [],
  actualParticipants: [],
  timeLeft: moment.utc(0),

  expireDate: 0, // timestamp when timer should expire

  duration: 0,
  questions: [],
  answers: [],

  gameDef: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.CHANGE_STEP_SUCCESS:
      return {
        ...state,
        step: action.payload.step,
        loading: false,
      };
    case TYPES.SET_COMPLETED_TIME_SUCCESS:
      return {
        ...state,
        is_finished: GAME_CONFIG.STEP.GAME_SUCCESS === state.step,
      };
    case TYPES.TIMER_TICK:
      return {
        ...state,
        timeLeft: action.payload.newTime,
      };

    case TYPES.SET_EXPIRE_DATE:
      return {
        ...state,
        expireDate: action.payload.expireDate,
      };

    case TYPES.SET_QUESTION_IS_OPEN: {
      const { question: questionForOpen } = action.payload;
      return {
        ...state,
        questions: state.questions.map(question => (question.question === questionForOpen.question
          ? {
            ...question,
            status: question.status === QUESTION_STATUS.ANSWERED
              ? question.status
              : QUESTION_STATUS.OPENED,
          }
          : question)),
      };
    }
    case TYPES.SET_QUESTION_ANSWERED: {
      const { question: answeredQuestion } = action.payload;
      return {
        ...state,
        questions: state.questions.map(question => (question.question === answeredQuestion.question
          ? {
            ...question,
            status: QUESTION_STATUS.ANSWERED,
          } : question)),
      };
    }
    case TYPES.HANDLE_ANSWER_CLICK_SUCCESS: { // TODO: looks bad, need refactor
      const {
        question: clickedQuestion,
        answer: clickedAnswer,
      } = action.payload;
      let newAnswerStatus = ANSWER_STATUS.CORRECT;
      const questions = state.questions.map(question => (question.question === clickedQuestion.question
        ? {
          ...question,
          answers: question.answers.map((answer) => {
            if (answer.answer === clickedAnswer.answer) {
              newAnswerStatus = answer.is_correct ? ANSWER_STATUS.CORRECT : ANSWER_STATUS.WRONG;
              return {
                ...answer,
                status: newAnswerStatus,
                clicked: true,
              };
            }
            return answer;
          }),
        } : question));
      return {
        ...state,
        questions,
        /* This logic reduce game time, do not delete it ! */
        // expireDate: newAnswerStatus === ANSWER_STATUS.WRONG
        //   ? state.expireDate - moment(
        //     state.gameDef.penalty_time,
        //     DATE_SETTINGS.FORMAT.HOURS_MINUTES_SECONDS,
        //   )
        //     .subtract(moment.duration(moment().startOf('day').valueOf()))
        //     .valueOf() // here we reduce expireDate by penalty time
        //   : state.expireDate,
      };
    }
    case TYPES.GET_GAME_INFO:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_GAME_INFO_SUCCESS: {
      const {
        game_session,
        game_session_details,
        game_session_result,
        company_logo,
        campaign_token,
        storyline_image: storylineImage,
      } = action.result;
      const {
        is_finished, restoredQuestions, lastStep, timeLeft,
      } = restoreGame({
        currentAnswers: game_session_details,
        staticData: action.staticData,
        gameDef: game_session.game_def,
        gameSessionResult: game_session_result,
      });
      return {
        ...state,
        step: lastStep,
        token: action.payload.token,
        campaignToken: campaign_token,
        gameDefId: game_session.game_def.id,
        gameSessionId: game_session.id,
        gameDef: game_session.game_def,
        gameSession: game_session,
        timeLeft,
        invitedParticipants: game_session.invited_participants || [],
        questions: restoredQuestions,
        is_finished,
        logo: company_logo,
        loading: false,
        loaded: true,
        storylineImage,
        isAnonymous: game_session.is_anonymous,
      };
    }
    case TYPES.GET_GAME_INFO_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
};

export default reducer;
