/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  AVAILABLE_COLORS,
  COLORS_CONFIG,
} from 'config';

/* Styles */

import Circle from './styles';

function CircleDefault({ color, autoSize }) {
  return <Circle autoSize={autoSize} color={color} />;
}

/* CircleDefault type of props */

CircleDefault.propTypes = {
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
  autoSize: PropTypes.bool,
};

/* CircleDefault default props */

CircleDefault.defaultProps = {
  color: COLORS_CONFIG.SKY_BLUE,
  autoSize: false,
};

export default CircleDefault;
