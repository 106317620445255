/* Libs */
import styled, { css } from 'styled-components';

import { Image } from 'components/blocks/ui/Img/styles';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  width: 100%;
  ${({ isEditing }) => isEditing && css`
    height: 300px;
  `}
`;

export const CroppedSection = styled.div`
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > * {
    flex: 1;
  }
`;
export const Preview = styled.div`
  width: 100%;
  height: 50%;
  overflow: hidden;
`;
export const LeftSide = styled.div`
  height: 100%;
`;
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding-left: 24px;
`;


export const Action = styled.div`
  height: 33px;
  border-radius: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 300ms;
  &:hover {
    opacity: 0.5;
  }
  ${({ type }) => type === 'cancel' && css`
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    background-color: ${COLORS_CONFIG.TRANSPARENT};
  `}
  ${({ type }) => type === 'apply' && css`
    color: ${COLORS_CONFIG.WHITE};
    background-color: ${COLORS_CONFIG.SKY_BLUE};
  `}
  ${({ type }) => type === 'change' && css`
    color: ${COLORS_CONFIG.SKY_BLUE};
    border:2px solid ${COLORS_CONFIG.SKY_BLUE};
  `}
`;

export const Actions = styled.div`
  width: 100%;
  flex: 1;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    flex-basis: 50%;
  }
`;

export const DeleteImg = styled.div`
  position: absolute;
  top:0;
  right:0;
  transform: translate(40%, -42%);
  & ${Image} {
    display: none;
  }
  z-index: 5;
`;

export const PreviewWrapper = styled.div`
  position: relative;
  display: inline-block;
  & ${Image} {
    opacity: 0.8;
    transition: opacity 300ms;
  }
  &:hover {
    ${Image}{
      opacity: 1;
      ${({ hasImage }) => hasImage && css`
        display: block;
      `}
    }
  }
`;
