/* Libs */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VimeoPlayer from '@livingsecurity/react-vimeo';

/* Utils */

import { isEmpty } from 'lodash';

/* Components */

import { BigTitle, Button } from 'components';

/* Constants */

import { GAME_STEP_TITLES } from '_constants';
/* Styles */

import * as Styled from './styles';

function Instructions({
  vimeoId,
  title,
  text,
  next,
  back,
  removeCurrentStep,
  isLastStep,
  isDarkMode,
}) {
  const [errorInVideo, setErrorInVideo] = useState(false);

  useEffect(() => {
    if (errorInVideo && isEmpty(text)) {
      removeCurrentStep();
    }
  }, [errorInVideo]);

  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <BigTitle>
        {title || GAME_STEP_TITLES.instructions}
      </BigTitle>
      <Styled.Content>
        {
          (!isEmpty(vimeoId) && !errorInVideo)
            ? (
              <VimeoPlayer
                videoId={vimeoId}
                autoplay
                onError={() => setErrorInVideo(true)}
              />
            )
            : <div dangerouslySetInnerHTML={{ __html: text }} />
        }
      </Styled.Content>
      <Styled.Actions>
        { back && (
          <Button
            onClick={back}
          >
            Back
          </Button>
        )}

        <Button
          onClick={next}
        >
          {isLastStep ? 'Start' : 'Next'}
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* Instructions type of props */

Instructions.propTypes = {
  removeCurrentStep: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  vimeoId: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Instructions;
