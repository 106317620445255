/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG } from 'config';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';
import {
  Button,
} from 'components/ui/Button/styles';

export const Content = styled.div`
  flex: 1;
  padding: 20px 0 20px;
`;

export const Actions = styled.div`
`;


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  ${({ isDarkMode }) => isDarkMode && css`
     & ${BigTitle}, ${Content}{
        color: ${COLORS_CONFIG.WHITE};
     }
     
     & ${Actions} {
        & ${Button} {
          background-color: ${COLORS_CONFIG.WHITE};
          color: ${COLORS_CONFIG.NAVY_BLUE};
        }
      }
  `}
`;
