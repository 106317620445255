/* Libs */
import styled from 'styled-components';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: 49%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 4px;
  padding: 20px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;

export const Title = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  line-height: 19px;
  max-width: 60%;
`;

export const Chart = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 136px;
`;

export const ChartLabel = styled.p`
  z-index: 1;
  position: absolute;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 26px;
  line-height: 36px;
`;
