/* Libs */
import styled from 'styled-components';

import { LABEL_COLOR_TYPES, TRIANGLE_TYPES } from '_constants';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Triangle as BackTriangle } from 'components/blocks/Markers/BackTriangle/styles';
import { Triangle as ForwardTriangle } from 'components/blocks/Markers/ForwardTriangle/styles';
import { Triangle as TopTriangle } from 'components/blocks/Markers/TopTriangle/styles';
import { Triangle as DownTriangle } from 'components/blocks/Markers/DownTriangle/styles';

export const Label = styled.div`
  ${({ triangleType }) => (triangleType === TRIANGLE_TYPES.BACK
    ? 'padding-left:  6px;'
    : 'padding-right:  6px;')
}
  cursor: pointer;
  font-size: 12px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & ${Label} {
    color: ${({ color }) => (color === LABEL_COLOR_TYPES.BROWN
    ? COLORS_CONFIG.SMOOTH_BROWN
    : COLORS_CONFIG.SKY_BLUE)
};
  }
  & ${BackTriangle} {
    border-right-color: ${({ color }) => (color === LABEL_COLOR_TYPES.BROWN
    ? COLORS_CONFIG.SMOOTH_BROWN
    : COLORS_CONFIG.SKY_BLUE)
};
  }
  & ${ForwardTriangle} {
    border-left-color: ${({ color }) => (color === LABEL_COLOR_TYPES.BROWN
    ? COLORS_CONFIG.SMOOTH_BROWN
    : COLORS_CONFIG.SKY_BLUE)
};
  }
  & ${TopTriangle} {
    border-bottom-color: ${({ color }) => (color === LABEL_COLOR_TYPES.BROWN
    ? COLORS_CONFIG.SMOOTH_BROWN
    : COLORS_CONFIG.SKY_BLUE)
};
  }
  & ${DownTriangle} {
    border-top-color: ${({ color }) => (color === LABEL_COLOR_TYPES.BROWN
    ? COLORS_CONFIG.SMOOTH_BROWN
    : COLORS_CONFIG.SKY_BLUE)
};
  }
`;
