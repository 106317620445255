/* global window */
import { createBrowserHistory } from 'history';
import { compose, createStore, applyMiddleware } from 'redux';
import { clientMiddleware } from 'middlewares';
import rootReducer from './ConfigureReducers';
import httpClient from './httpClient';

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [clientMiddleware(httpClient)];

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const store = createStore(rootReducer, initialState, composedEnhancers);
export const getStore = () => store;
export default store;
