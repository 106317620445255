/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';

/* Styled-Components */

import AdminSidebarList from 'components/blocks/Lists/AdminSidebarList/styles';
import { Logo } from 'components/blocks/ui/Logo/styles';

export const Wrapper = styled.div`
  height: 100%;
  padding-top: 32px;
  background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
  display: flex;
  flex-direction: column;
  min-width: 260px;
  & ${AdminSidebarList} {
    margin-top: 40px;
  }
  & ${Logo} {
    width: 162px;
  }
`;

export const Logout = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
  justify-content:center;
  & > div {
    color: ${COLORS_CONFIG.WHITE};
    cursor: pointer;
    font-size: 24px;
  }
`;
