/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LS_AdminCompanyEntity } from '_entities';

/* Utils */

import { validateNumber } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { getError } from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import {
  Input,
  Button,
} from 'components';

/* Constants */

import { NOTIFICATIONS } from '_constants';

/* Styles */

import * as Styled from './styles';

const DEFAULT_ERRORS = {
  licenses: null,
};

const INITIAL_DATA = {
  licenses: '',
};

function EditLicenseCountForm({
  updateCompanyLicensesCount,
  companyId,
  onClose,
}) {
  const [formData, setData] = useState({
    data: INITIAL_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    if (!Number.isInteger(+value)) return;
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'licenses':
        newErrors[name] = validateNumber({ value, name, max: 2147483647 });
        break;
      default:
        break;
    }
    setData({
      data: { ...data, [name]: value.trim().replace(/\./g, '') },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['licenses']),
    });
  };

  const handleBlur = ({ target: { name, value } }) => {
    if (!Number.isInteger(+value)) return;
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'licenses':
        newErrors[name] = validateNumber({ value, name, max: 2147483647 });
        break;
      default:
        break;
    }
    setData(prev => ({
      data: prev.data,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['licenses']),
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await updateCompanyLicensesCount({ ...formData.data, companyId });
      notification.success(NOTIFICATIONS.SUCCESS_UPDATE_LICENSES_COUNT);
      onClose();
    } catch (error) {
      notification.error(getError(error));
      setLoading(false);
    }
  };

  const { data, errors, canSubmit } = formData;
  return (
    <Styled.Wrapper>
      <Input
        placeholder="Set new licenses count"
        name="licenses"
        title="Licenses count"
        error={errors.licenses}
        value={data.licenses}
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
      />
      <Button
        disabled={!canSubmit || loading}
        onClick={handleSave}
      >
        Update
      </Button>
    </Styled.Wrapper>
  );
}

/* EditLicenseCountForm type of props */

EditLicenseCountForm.propTypes = {
  data: PropTypes.shape({
    licenses: PropTypes.number.isRequired,
  }).isRequired,
  companyId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  updateCompanyLicensesCount: PropTypes.func.isRequired,
};

export default connect(({ LS_adminCompanyView }) => ({
  companyId: LS_adminCompanyView.company.id,
  loading: LS_adminCompanyView.loading,
}), {
  updateCompanyLicensesCount: LS_AdminCompanyEntity.actions.updateCompanyLicensesCount,
})(EditLicenseCountForm);
