/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  TopTriangle,
  RulesList,
  Img,
} from 'components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

function RulesTooltip({
  rules,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Content>
        <TopTriangle color={COLORS_CONFIG.WHITE} />
        <Styled.Card>
          <RulesList rules={rules} />
        </Styled.Card>
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* RulesTooltip type of props */

RulesTooltip.propTypes = {
  rules: PropTypes.array.isRequired,
};

export default RulesTooltip;
