/* Libs */
import {
  useState, useRef, useMemo, useCallback,
} from 'react';
import {
  debounce, merge, isEqual, differenceWith, get,
} from 'lodash';

/* Utils */
import { validateEmail, validateDate } from 'utils/validation/fields';
import { notification } from 'utils/services';
import { TIME_OPTIONS } from 'config';
import {
  generateTimeOptions,
  generateKitOptions,
  generateGameDefOptions,
  getTimezoneOffset,
  transformDataToOptions,
  getTimezoneName,
} from 'utils/custom';
import { formIsValid, isValidNumbersField } from 'utils/validation';

/* Constants */
import { COMMON_ERRORS } from '_constants/errors';
import {
  TIME, NOTIFICATIONS, PARTICIPANTS_COUNT_RANGE,
} from '_constants';
import moment from 'moment';

/**
 * @param {function} onSave
 * @param {string} saveText
 * @param {function} onDelete
 * @typedef {Object} data
 * @property {number} id
 * @property {Object} campaign
 * @property {number} kit
 * @property {string} dateFrom
 * @property {Array} kits
 * @property {number} gameDef
 * @property {Array} gameDefs
 * @property {string} time
 * @property {string} location
 * @property {Array} locattions
 * @property {number} maxParticipants
 * @property {Array} participantEmails
 * @property {string} activeSidebar
 * @property {Object} SIDEBAR_NAMES
 * @param {function} getLocations
 */
function useEditSessionForm({
  onSave,
  saveText,
  onDelete,
  data: initialData,
  getLocations,
  loadGameDefs,
  externalData,
  setExternalData,
  isLoading,
  confirmSidebar,
  setConfirmSidebarState,
}) {
  const [innerData, setInnerData] = useState(initialData);
  const [dateError, setDateError] = useState(null);
  const [participantEmail, setEmail] = useState('');
  const [optionsAreVisible, setOptionsVisibility] = useState(false);
  const [isTimerCountingUp, setTimerType] = useState(initialData.isTimerCountingUp);
  const list = useRef(null);

  const setData = setExternalData || setInnerData;
  const currentData = { ...innerData, ...externalData };
  const {
    id,
    campaign,
    kit,
    dateFrom,
    kits,
    gameDef,
    gameDefs,
    startTime,
    timezone,
    location,
    locations,
    participantEmails,
    activeSidebar,
    SIDEBAR_NAMES,
    introductionTime,
    debriefingTime,
    participantsPerSession,
    autoSignUp,
    signedUpParticipants,
    meetingLink,
  } = currentData;
  const { isSecretSession } = initialData;

  const handleChange = useCallback(({ target: { name, value } }) => {
    setData(prevData => ({
      ...prevData,
      [name]: value,
      ...(name === 'gameDef' && { meetingLink: '' }),
    }));
  }, []);

  const handleDateChange = useMemo(() => ({ target: { name, value } }) => {
    setDateError(validateDate({ value, name }));
    handleChange({ target: { name, value } });
  }, []);
  const toggleTimerType = useMemo(() => () => setTimerType(!isTimerCountingUp), [isTimerCountingUp]);
  const toggleOptionsVisibility = useMemo(() => () => setOptionsVisibility(!optionsAreVisible), [optionsAreVisible]);
  const openConfirmSidebar = () => setConfirmSidebarState(true);
  const closeConfirmSidebar = () => setConfirmSidebarState(false);
  const handleChangeParticipantEmail = useMemo(() => ({ target: { value } }) => setEmail(value), []);
  const addEmail = (e) => {
    e.preventDefault();

    if (participantsPerSession <= participantEmails.length) {
      return;
    }

    const error = validateEmail({ value: participantEmail, name: 'email' });

    if (error) {
      notification.error(error);
      return;
    }

    if (participantEmails.map(({ email }) => email).includes(participantEmail)) {
      notification.error(COMMON_ERRORS.emailDuplication);
      return;
    }

    setEmail('');
    handleChange({
      target: {
        name: 'participantEmails',
        value: currentData.participantEmails.concat({
          email: participantEmail,
          id: participantEmail,
        }),
      },
    });
  };
  const deleteEmail = (id) => {
    handleChange({ target: { name: 'participantEmails', value: participantEmails.filter(({ id: emailId }) => emailId !== id) } });
  };
  const saveData = () => {
    if (participantEmails.length > participantsPerSession) {
      notification.error(NOTIFICATIONS.ERROR_PARTICIPANTS_EMAILS_MORE_THAN_MAXIMUM);
      return;
    }

    if (participantEmail.trim().length) {
      notification.info(NOTIFICATIONS.INFO_NEW_PATICIPANT_EMAIL_FIELD_EMPTY);
      return;
    }

    const deletedParticipants = differenceWith(initialData.participantEmails, participantEmails, isEqual);
    const newParticipants = differenceWith(participantEmails, initialData.participantEmails, isEqual);
    const newStartTime = `${dateFrom} ${startTime}`;
    const fullKit = kits.find(({ id }) => id === kit);
    const fullGameDef = gameDefs.find(({ id }) => id === gameDef);


    const timezoneOffset = getTimezoneOffset(getTimezoneName(timezone));

    const data = activeSidebar === SIDEBAR_NAMES.ADD
      ? merge(
        {
          start_time: newStartTime,
          location,
          campaign: campaign.id,
          game_def: fullGameDef && fullGameDef.id,
          kit: fullKit && fullKit.id,
          timezone,
          timezone_offset: timezoneOffset,
          introduction_time: introductionTime,
          debriefing_time: debriefingTime,
          is_timer_counting_up: isTimerCountingUp,
          auto_signup: autoSignUp,
          meeting_link: meetingLink,
          participants_per_session: participantsPerSession,
        },
        participantEmails.length ? { participants_emails: participantEmails.map(({ email }) => email) } : {},
      )
      : merge(
        { id },
        ((startTime === initialData.startTime) && (dateFrom === initialData.dateFrom)) ? {} : { start_time: newStartTime },
        location === initialData.location ? {} : { location },
        gameDef === initialData.gameDef ? {} : { game_def: fullGameDef && fullGameDef.id },
        kit === initialData.kit ? {} : { kit: fullKit && fullKit.id },
        newParticipants.length ? { participants_emails: newParticipants.map(({ email }) => email) } : {},
        deletedParticipants.length ? { participants_ids_to_delete: deletedParticipants.map(({ id }) => id) } : {},
        timezone === initialData.timezone ? {} : {
          timezone,
          timezone_offset: timezoneOffset,
        },
        isTimerCountingUp === initialData.isTimerCountingUp ? {} : { is_timer_counting_up: isTimerCountingUp },
        introductionTime === initialData.introductionTime ? {} : { introduction_time: introductionTime },
        debriefingTime === initialData.debriefingTime ? {} : { debriefing_time: debriefingTime },
        participantsPerSession === initialData.participantsPerSession ? {} : { participants_per_session: participantsPerSession },
        autoSignUp === initialData.autoSignUp ? {} : { auto_signup: autoSignUp },
        meetingLink === initialData.meetingLink ? {} : { meeting_link: meetingLink },
      );

    onSave(data);
  };
  const handleMenuOpen = useMemo(() => () => {
    setTimeout(() => {
      const defaultTime = TIME_OPTIONS.DEFAULT_HOUR * TIME.MINUTES_IN_HOUR / TIME_OPTIONS.STEP_IN_MINUTES;
      if (list.current.select.menuListRef.children[defaultTime]) {
        list.current.select.menuListRef.children[defaultTime].scrollIntoView();
      }
    }, 50);
  }, [TIME_OPTIONS.DEFAULT_HOUR, TIME_OPTIONS.STEP_IN_MINUTES]);
  const changeParticipantsPerSession = useMemo(() => ({ target: { name, value } }) => {
    if (!isValidNumbersField(value) || +value > PARTICIPANTS_COUNT_RANGE.MAX) return;
    handleChange({ target: { name, value } });
  }, []);

  const isOnlineOnly = useMemo(() => {
    const chosenGameDef = gameDefs.find(definition => definition.id === gameDef);
    if (!chosenGameDef) return false;
    return chosenGameDef.is_online_only;
  },
  [gameDef, gameDefs]);

  const isAllFieldsFilled = Boolean(
    kit
    && gameDef
    && dateFrom
    && (!dateError || formIsValid({ dateError }, ['dateError']))
    && startTime
    && timezone
    && location
    && introductionTime
    && debriefingTime
    && participantsPerSession
    && (!isOnlineOnly || meetingLink || isSecretSession),
  );

  const checkAllFields = () => (activeSidebar === SIDEBAR_NAMES.ADD
    ? !isAllFieldsFilled
    : !isAllFieldsFilled || (
      kit === initialData.kit
      && gameDef === initialData.gameDef
      && dateFrom === initialData.dateFrom
      && startTime === initialData.startTime
      && timezone === initialData.timezone
      && location === initialData.location
      && isTimerCountingUp === initialData.isTimerCountingUp
      && introductionTime === initialData.introductionTime
      && debriefingTime === initialData.debriefingTime
      && +participantsPerSession === initialData.participantsPerSession
      && (isSecretSession || (isEqual(participantEmails, initialData.participantEmails)))
      && autoSignUp === initialData.autoSignUp
      && meetingLink === initialData.meetingLink
    )
  );

  const timeOptions = useMemo(generateTimeOptions, []);
  const kitOptions = useMemo(() => generateKitOptions({ kits, campaign, identifier: 'id' }), []);
  const isKitOptionDisabled = useMemo(() => option => get(option, 'disabled', false), []);
  const gameDefOptions = useMemo(() => generateGameDefOptions({ gameDefs }), []);
  const loadGameDefOptions = useMemo(() => (gameDef, callback) => loadGameDefs({ gameDef, callback }), [gameDef]);
  const locationOptions = useMemo(() => transformDataToOptions({ data: locations, value: 'id', label: 'location' }), [locations]);
  const fetchLocations = (location, callback) => {
    getLocations({ location, withoutStoreChanging: true }).then(({ results: locations }) => {
      callback(transformDataToOptions({ data: locations, value: 'id', label: 'location' }));
    });
  };
  const loadLocations = useMemo(() => debounce(fetchLocations, 300), []);

  const handleDelete = useCallback(debounce(async () => {
    await onDelete();
    setConfirmSidebarState(false);
  }, 1000), []);

  return ({
    confirmSidebar,
    onDelete: handleDelete,
    closeConfirmSidebar,
    handleChange,
    handleDateChange,
    campaign,
    kit,
    gameDef,
    dateFrom,
    startTime,
    timezone,
    handleMenuOpen,
    list,
    location,
    locations,
    loadLocations,
    participantEmail,
    participantEmails,
    handleChangeParticipantEmail,
    addEmail,
    deleteEmail,
    saveData,
    checkAllFields,
    saveText,
    openConfirmSidebar,
    timeOptions,
    kitOptions,
    isKitOptionDisabled,
    gameDefOptions,
    loadGameDefOptions,
    locationOptions,
    dateError,
    optionsAreVisible,
    toggleOptionsVisibility,
    introductionTime,
    debriefingTime,
    participantsPerSession,
    isSecretSession,
    changeParticipantsPerSession,
    isTimerCountingUp,
    toggleTimerType,
    autoSignUp,
    signedUpParticipants,
    isOnlineOnly,
    meetingLink,
    isLoading,
    ...externalData,
  });
}

export default useEditSessionForm;
