/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

/* Components */

import { CircleWithSign } from 'components';

/* Styles */

import * as Styled from './styles';

function AddCircle({
  onClick,
  title,
  titleSize,
  to,
}) {
  return (
    <Styled.Wrapper
      onClick={onClick}
      to={to}
      as={to ? Link : Styled.Wrapper}
    >
      <CircleWithSign sign="+" />
      <Styled.Text className={cn(titleSize)}>
        {title}
      </Styled.Text>
    </Styled.Wrapper>
  );
}

/* AddCircle type of props */

AddCircle.propTypes = {
  // eslint-disable-next-line consistent-return
  onClick: (props, propName) => {
    if (!props.to && (props[propName] === undefined || typeof (props[propName]) !== 'function')) {
      return new Error('Please provide a "onClick" or "to" prop to "AddCircle" component');
    }
  },
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.oneOf(['small']),
  to: PropTypes.string,
};

AddCircle.defaultProps = {
  titleSize: null,
  to: null,
  onClick: null,
};

export default AddCircle;
