/* Libs */
import styled from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';
import { CustomScroll } from 'assets/styles/mixins';


export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Data = styled.div`
  flex-grow: 1;
  padding: 24px 32px 20px 32px;
  height: calc( 100% - 200px );
  overflow-y: auto;
  
  ${CustomScroll}
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 8px;
`;

export const CampaignName = styled.div`
  font-size: 18px;
  line-height: 18px;
`;

export const Campaign = styled.div`
  padding-bottom: 16px;
  & ${Label},
  & ${CampaignName} {
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  }
`;

export const Participants = styled.div`
  padding: 10px 0;
`;

export const ParticipantsBlock = styled.div`
  margin-bottom: 28px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const TitleText = styled.div`
  margin-right: 5px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 15px;
`;

export const TitleNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: ${COLORS_CONFIG.SKY_BLUE};
  color: ${COLORS_CONFIG.WHITE};
  font-size: 11px;
  border-radius: 50%;
`;

export const InfoText = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
`;

export const Emails = styled.div``;

export const Email = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;
