/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG } from 'config';
import { CustomScroll } from 'assets/styles/mixins';


export const Content = styled.div`
  width: 100vw;
  background-color: ${COLORS_CONFIG.SILVER};
  height: calc(100vh - 80px);
  overflow-y: auto;
  
  ${CustomScroll}    
`;

export const Struct = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${({ isDarkMode }) => isDarkMode && css`
             
       & ${Content} {
         background: ${COLORS_CONFIG.DARK_BLUE};
         
         ${() => CustomScroll({
    scrollBG: COLORS_CONFIG.DARK_BLUE,
    thumbScrollBG: COLORS_CONFIG.OXFORD_BLUE,
  })} 
       }
   `}
`;
