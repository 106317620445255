/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/* Constants */
import { FONT_WEIGHTS } from '_constants';

/* Styles */

import * as Styled from './styles';

function SimpleTextCell({ value, fontFamily }) {
  return <Styled.Cell fontFamily={fontFamily}>{value}</Styled.Cell>;
}

/* SimpleTextCell type of props */

SimpleTextCell.propTypes = {
  value: PropTypes.node,
  fontFamily: PropTypes.oneOf([
    FONT_WEIGHTS.REGULAR,
    FONT_WEIGHTS.BOLD,
  ]),
};

/* SimpleTextCell default props */

SimpleTextCell.defaultProps = {
  value: ' ---- ',
  fontFamily: FONT_WEIGHTS.REGULAR,
};

export default SimpleTextCell;
