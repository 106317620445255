/* Libs */
import styled, { css } from 'styled-components';

import Triangle from 'components/blocks/Markers/BackTriangle/styles';
import { Wrapper as LoaderWrapper } from 'components/blocks/ui/Loader/styles';

import { COLORS_CONFIG } from 'config';
import { CustomScroll } from 'assets/styles/mixins';

export const Tooltip = styled.div`
  z-index: 11;
  position: absolute;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 3px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.31);
  ${({ visible }) => !visible && css`
    display: none;
  `}
  
  ${Triangle} {
    position: absolute;
    top: -5px;
    left: 8px;
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent ${COLORS_CONFIG.WHITE} transparent;
    ${({ place }) => place === 'top' && css`
        top: unset;
        bottom: -5px;
        border-width: 6px 4px 0 4px;
        border-color: ${COLORS_CONFIG.WHITE} transparent transparent transparent;
      `
}
}
`;

export const Content = styled.div`
  position: relative;
  overflow: auto;
  word-break: break-word;
  min-height: unset;
  width: auto;
  font-size: 14px;
  padding: 6px 10px;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}
  
  ${LoaderWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
