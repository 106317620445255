import styled from 'styled-components';

import { Wrapper as ShareLink } from 'components/ui/ShareLink/styles';
import { Wrapper as ExportFile } from 'components/blocks/ui/ExportFile/styles';

export const Share = styled.div`
  padding: 40px 48px;

  ${ShareLink},
  ${ExportFile} {
    margin-top: 32px;
  }
`;
