/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/* Styles */

import { URLS_CONFIG } from 'config/index';
import * as Styled from './styles';

function CompanyAdminNavbarItem({
  url,
  label,
  location: { pathname },
}) {
  return (
    <Styled.Link
      active={pathname.includes(url) ? 1 : 0}
      // true as string coz of router lib warning
      same={(
        pathname === url
        || (url === URLS_CONFIG.companyAdmin.reports.default && pathname === URLS_CONFIG.companyAdmin.reports.main) ?
          'true' : undefined
      )}
      to={url}
    >
      <Styled.Wrapper>
        <Styled.Label>
          {label}
        </Styled.Label>
      </Styled.Wrapper>
    </Styled.Link>
  );
}

/* CompanyAdminNavbarItem type of props */

CompanyAdminNavbarItem.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};

CompanyAdminNavbarItem.defaultProps = {
  pathname: null,
};

export default withRouter(CompanyAdminNavbarItem);
