/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { LS_AdminCompanyEntity } from '_entities';

/* Utils */

import { validateDate } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { notification } from 'utils/services';
import { getError } from 'utils/custom';

/* Components */

import {
  Button,
  DatePicker,
} from 'components';

/* Constants */

import { NOTIFICATIONS } from '_constants';

/* Styles */

import moment from 'moment';
import * as Styled from './styles';

const DEFAULT_ERRORS = {
  start_date: false,
  end_date: false,
};

function EditContractForm({
  contract: INITIAL_DATA,
  updateCompanyContract,
  companyId,
  onClose,
  loading,
}) {
  const [formData, setData] = useState({
    data: INITIAL_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'start_date':
      case 'end_date':
        newErrors[name] = validateDate({ value, name });
        break;
      default:
        break;
    }
    setData({
      data: { ...data, [name]: value },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['start_date', 'end_date']),
    });
  };

  const handleSave = async () => {
    try {
      await updateCompanyContract({ ...formData.data, companyId });
      notification.success(NOTIFICATIONS.SUCCESS_UPDATE_CONTRACT);
      onClose();
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const { data, errors, canSubmit } = formData;
  return (
    <Styled.Wrapper>
      <Styled.InputGroup>
        <DatePicker
          placeholder="Start date"
          title="Start date"
          name="start_date"
          error={errors.start_date}
          value={data.start_date}
          onChange={handleChange}
        />
        <DatePicker
          placeholder="End date"
          title="End date"
          name="end_date"
          error={errors.end_date}
          value={data.end_date}
          onChange={handleChange}
          minDate={moment(data.start_date).toDate()}
          position="right"
        />
      </Styled.InputGroup>
      <Button
        disabled={!canSubmit || loading}
        onClick={handleSave}
      >
        Save
      </Button>
    </Styled.Wrapper>
  );
}

/* EditContractForm type of props */

EditContractForm.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    end_date: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  updateCompanyContract: PropTypes.func.isRequired,
};

export default connect(({ LS_adminCompanyView }) => ({
  companyId: LS_adminCompanyView.company.id,
  loading: LS_adminCompanyView.loading,
}), {
  updateCompanyContract: LS_AdminCompanyEntity.actions.updateCompanyContract,
})(EditContractForm);
