/* Libs */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

/* Components */

import { ReportListItem, EmptyBlockText } from 'components';

/* Styles */

import * as Styled from './styles';

function ReportsList({
  data,
  next,
  total,
  exportRequest,
  getCampaignActivity,
}) {
  const height = useMemo(() => {
    if (!data.length) return 90;
    return data.length < 5 ? data.length * 90 : 300;
  }, [data.length]);

  return (
    <Styled.Wrapper>
      <InfiniteScroll
        dataLength={data.length}
        next={next}
        hasMore={data.length < total}
        height={height}
        scrollThreshold={0.9}
        loader={<h3 style={{ textAlign: 'center' }}>Loading..</h3>}
      >
        {!!data && data.length ? (
          data.map(item => (
            <ReportListItem
              key={item.id}
              exportRequest={exportRequest}
              getCampaignActivity={getCampaignActivity}
              {...item}
            />
          ))
        ) : (
          <EmptyBlockText text="No campaigns" />
        )}
      </InfiniteScroll>
    </Styled.Wrapper>
  );
}

/* ReportsList type of props */

ReportsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      sessionsCompleted: PropTypes.number.isRequired,
      participantsActual: PropTypes.number.isRequired,
      correctAnswers: PropTypes.number.isRequired,
    }),
  ),
};

/* ReportsList default props */

ReportsList.defaultProps = {
  data: [],
};

export default memo(ReportsList);
