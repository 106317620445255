/* Libs */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';


/* Utils */
import {
  redirect,
  getLaunchGameLink,
  hasPermission,
  getSessionTimeCellData,
  getSessionTimeData,
  getTimeInUTCFormat,
} from 'utils/custom';

/* Components */
import {
  SimpleTextCell,
  ActionsCell,
  GoLabel,
  Table,
  SessionTimeCell,
  HeaderSessionTimeCell,
  ParticipantsCell,
  TimeCompletedCell,
  DeletedCell,
  KitCell,
} from 'components';

/* Constants */
import {
  CAMPAIGN_TYPES,
  SESSION_TYPES,
  SESSION_TIME_TYPES,
  DATE_SETTINGS,
} from 'config';
import { TRIANGLE_TYPES, PERMISSION_NAMES, KIT_FIELD_TYPES } from '_constants';

/* Styles */
import * as Styled from './styles';

const getRoleName = (role) => {
  switch (role) {
    case 'admin':
      return 'LS Admin';
    case 'company_admin':
      return 'Company Admin';
    default:
      return role;
  }
};


// CampaignsSchedule columns cell structure
const getColumnsData = ({
  sessionType,
  campaignType,
  actions,
  tableTimeType,
  toggleTableTimeType,
  role,
  kitFieldType,
  setKitFieldType,
  companyTimezone,
}) => {
  const columns = {
    date: {
      accessor: 'date',
      minWidth: 110,
      Header: 'Date',
      Cell: (props) => {
        const localDate = getTimeInUTCFormat(props.original.utc_start_time, companyTimezone)
          .format(DATE_SETTINGS.FORMAT.MONTH_SHORT_DAY_COMMA_YEAR);

        return <SimpleTextCell fontFamily="BOLD" value={localDate} size="small" />;
      },

    },
    location: {
      accessor: 'location',
      minWidth: 120,
      flexGrow: 3,
      Header: 'Location',
      Cell: props => <SimpleTextCell value={props.original.location && props.original.location.location} size="small" />,
    },
    locationLeaderboard: {
      accessor: 'location',
      minWidth: 100,
      flexGrow: 3,
      Header: 'Location',
      Cell: props => <SimpleTextCell value={props.original.location} size="small" />,
    },
    sessionTime: {
      accessor: 'session_time',
      minWidth: 185,
      Header: () => (
        <HeaderSessionTimeCell
          value={tableTimeType}
          onToggle={toggleTableTimeType}
          checked={tableTimeType === SESSION_TIME_TYPES.SESSION_TIME}
        />
      ),
      Cell: (props) => {
        const {
          startTime,
          endTime,
          timezoneTitle,
          label,
        } = getSessionTimeCellData(tableTimeType, companyTimezone, props);

        return (
          <SessionTimeCell
            startTime={startTime}
            endTime={endTime}
            timezoneTitle={timezoneTitle}
            label={label}
          />
        );
      },
    },
    kit: {
      accessor: 'kit',
      minWidth: 150,
      flexGrow: 2,
      Header: () => (
        <KitCell
          options={Object.values(KIT_FIELD_TYPES)}
          value={kitFieldType}
          onChange={value => setKitFieldType(value)}
        />
      ),
      Cell: ({ original: { kit: { kit_id: kitId, nickname } } }) => (
        <SimpleTextCell
          value={(kitFieldType === KIT_FIELD_TYPES.NICKNAME && nickname) ? nickname : kitId}
          size="small"
        />
      ),
    },
    rank: {
      accessor: 'rank',
      minWidth: 30,
      Header: 'Rank',
      Cell: props => <SimpleTextCell value={props.original.rank} size="small" />,
    },
    team: {
      accessor: 'team',
      minWidth: 120,
      flexGrow: 2,
      Header: 'Team',
      Cell: props => <SimpleTextCell value={props.original.team_name} size="small" />,
    },
    start_time: {
      accessor: 'time',
      minWidth: 120,
      Header: 'Start date',
      Cell: ({ original: { utc_start_time, timezone } }) => {
        const { startTime } = getSessionTimeData({
          type: SESSION_TIME_TYPES.LOCAL_TIME,
          propsTimezone: timezone,
          propsStartTime: utc_start_time,
        });

        return (
          <SimpleTextCell
            value={startTime.format(DATE_SETTINGS.FORMAT.MONTH_SHORT_DAY_COMMA_YEAR)}
            size="small"
          />
        );
      },
    },
    time: {
      accessor: 'time',
      minWidth: 120,
      Header: 'Time completed',
      Cell: ({ original: { time_completed, is_finished } }) => (
        <TimeCompletedCell
          timeCompleted={moment(
            time_completed,
            DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED,
          ).format(DATE_SETTINGS.FORMAT.TIMER_FORMAT_EXPIRED)}
          isFinished={is_finished}
        />
      ),
    },
    actualOfPerSessionParticipants: {
      accessor: 'participants_count',
      minWidth: 100,
      headerStyle: {
        overflow: 'visible',
      },
      Header: () => (
        <ParticipantsCell
          leftParticipants="Actual participants"
          rightParticipants="Participants per session"
        />
      ),
      Cell: props => (
        <SimpleTextCell
          value={`${props.original.actual_participants.length} of ${props.original.participants_per_session}`}
          size="small"
        />
      ),
    },
    intivedOfPerSessionParticipants: {
      accessor: 'participants_count',
      minWidth: 130,
      headerStyle: {
        overflow: 'visible',
      },
      Header: () => (
        <ParticipantsCell
          leftParticipants="Signed up participants"
          rightParticipants="Participants per session"
        />
      ),
      Cell: props => (
        <SimpleTextCell
          value={`${props.original.signedup_participants.length} of ${props.original.participants_per_session}`}
          size="small"
        />
      ),
    },
    actualOfSignedupParticipants: {
      accessor: 'participants_count',
      minWidth: 130,
      headerStyle: {
        overflow: 'visible',
      },
      Header: () => (
        <ParticipantsCell
          leftParticipants="Actual participants"
          rightParticipants="Signedup participants"
        />
      ),
      Cell: props => (
        <SimpleTextCell
          value={`${props.original.actual_participants_count} of ${props.original.signedup_participants_count}`}
          size="small"
        />
      ),
    },
    viewExportActions: {
      minWidth: 150,
      Header: '',
      Cell: ({ original: { id } }) => (
        <ActionsCell>
          <div onClick={() => actions.openManageGame(id)}>View</div>
          <GoLabel
            text="Export"
            triangleType={TRIANGLE_TYPES.FORWARD}
            click={() => actions.exportSession(id)}
          />
        </ActionsCell>
      ),
    },
    editLaunchActions: {
      minWidth: 150,
      Header: '',
      Cell: ({ original: { id } }) => (
        <ActionsCell>
          {
            hasPermission(PERMISSION_NAMES.EDIT_SESSION) && (
              <div onClick={() => actions.openEditSession(id)}>Edit</div>
            )
          }
          <GoLabel
            text="Launch"
            triangleType={TRIANGLE_TYPES.FORWARD}
            click={() => redirect(getLaunchGameLink(id))}
          />
        </ActionsCell>
      ),
    },
    gameManager: {
      minWidth: 150,
      Header: '',
      Cell: ({ original: { id } }) => (
        <ActionsCell>
          <div onClick={() => actions.openManageGame(id)}>Manage</div>
        </ActionsCell>
      ),
    },
    delete: {
      Header: '',
      Cell: ({ original: { game_session, game_session_status } }) => (
        <ActionsCell>
          <div onClick={() => actions.deleteSessionAction(game_session, game_session_status)}>Delete</div>
        </ActionsCell>
      ),
    },
    deleted: {
      minWidth: 150,
      Header: 'Deleted',
      Cell: ({ original: { deleted_by_role, deleted } }) => (
        <DeletedCell
          at={moment(deleted).utc().format('MMM D, YYYY')}
          by={getRoleName(deleted_by_role)}
        />
      ),
    },
  };

  switch (sessionType) {
    case SESSION_TYPES.ACTIVE:
      return [
        columns.date,
        columns.location,
        columns.sessionTime,
        columns.kit,
        columns.actualOfPerSessionParticipants,
        columns.gameManager,
      ];
    case SESSION_TYPES.COMPLETED:
      return [
        columns.date,
        columns.location,
        columns.sessionTime,
        columns.kit,
        columns.actualOfPerSessionParticipants,
        columns.viewExportActions,
      ];
    case SESSION_TYPES.LEADERBOARD:
      return [
        columns.rank,
        columns.team,
        columns.locationLeaderboard,
        columns.actualOfSignedupParticipants,
        columns.start_time,
        columns.time,
      ].concat(!['company_host', 'host'].includes(role)
        ? [columns.delete]
        : []);
    case SESSION_TYPES.DELETED:
      return [
        columns.date,
        columns.location,
        columns.sessionTime,
        columns.kit,
        columns.intivedOfPerSessionParticipants,
        columns.deleted,
      ];
    case SESSION_TYPES.SCHEDULED:
    default:
      return [
        columns.date,
        columns.location,
        columns.sessionTime,
        columns.kit,
        columns.intivedOfPerSessionParticipants,
      ].concat(
        campaignType === CAMPAIGN_TYPES.ACTIVE
          ? columns.editLaunchActions
          : [],
      );
  }
};

function CampaignsSchedule({
  data,
  actions,
  tableSize,
  sessionType,
  campaignType,
  pagination,
  loading,
  role,
  companyTimezone,
}) {
  const [tableTimeType, setTableTimeType] = useState(SESSION_TIME_TYPES.LOCAL_TIME);
  const [kitFieldType, setKitFieldType] = useState(KIT_FIELD_TYPES.ID);

  const toggleTableTimeType = () => setTableTimeType(
    tableTimeType === SESSION_TIME_TYPES.SESSION_TIME
      ? SESSION_TIME_TYPES.LOCAL_TIME
      : SESSION_TIME_TYPES.SESSION_TIME,
  );
  const columnsData = useMemo(() => getColumnsData({
    sessionType,
    campaignType,
    actions,
    tableTimeType,
    toggleTableTimeType,
    role,
    kitFieldType,
    setKitFieldType,
    companyTimezone,
  }), [tableTimeType, sessionType, campaignType, actions, kitFieldType, setKitFieldType]);


  return (
    <Styled.CampaignsSchedule>
      <Table
        defaultPageSize={data.length || null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={columnsData}
        data={data}
        pagination={pagination}
        loading={loading}
      />
    </Styled.CampaignsSchedule>
  );
}

// Type of props
CampaignsSchedule.propTypes = {
  // Warning!!! data has got another fields on Leaderboard table
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    kit: PropTypes.shape({
      kit_id: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      id: PropTypes.number.isRequired,
      location: PropTypes.string.isRequired,
    }),
    participants_per_session: PropTypes.number.isRequired,
  })).isRequired,
  actions: PropTypes.shape({
    openEditSession: PropTypes.func.isRequired,
    openViewSessionParticipants: PropTypes.func.isRequired,
    exportSession: PropTypes.func.isRequired,
    openManageGame: PropTypes.func.isRequired,
    deleteSessionAction: PropTypes.func.isRequired,
  }).isRequired,
  pagination: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
};


const mapStateToProps = state => ({
  companyTimezone: get(state, 'adminCompany.company.timezone'),
});

export default connect(mapStateToProps, null)(CampaignsSchedule);
