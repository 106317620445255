/* Libs */
import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

/* Actions */

import { ManageGameEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';

/* Components */

import { CircleWithSign, ContainerLoader } from 'components';

/* Constants */

import { COLORS_CONFIG, GAME_CONFIG } from 'config';
import { NOTIFICATIONS, PARTICIPANTS_COUNT_RANGE } from '_constants';

/* Styles */

import * as Styled from './styles';

function AnonymousView({
  participants,
  loading,
  setAnonymousParticipants,
  mode,
}) {
  const [participantsCount, setParticipantsCount] = useState(participants.length);
  const [savedParticipants, setSavedParticipants] = useState(participants.length);
  const [innerLoading, setInnerLoading] = useState(false);

  const editable = useMemo(() => mode === GAME_CONFIG.MANAGER.MODE.EDIT, [mode]);

  const updateParticipantsCount = useCallback(debounce(async (newCount, oldCount) => {
    try {
      if (newCount === oldCount) return;
      setInnerLoading(true);
      await setAnonymousParticipants({ newCount, oldCount });
      notification.success(NOTIFICATIONS.SUCCESS_CHANGED_PARTICIPANTS_COUNT);
      setSavedParticipants(newCount);
      setInnerLoading(false);
    } catch (error) {
      notification.error(error);
      setInnerLoading(false);
      setParticipantsCount(oldCount);
    }
  }, 1000), []);

  useEffect(() => {
    updateParticipantsCount(participantsCount, savedParticipants);
  }, [participantsCount]);

  const increase = useCallback(() => setParticipantsCount(prevCount => prevCount + 1), []);
  const decrease = useCallback(() => setParticipantsCount(prevCount => (prevCount - 1 <= 0 ? 0 : prevCount - 1)), []);
  const onChange = useCallback(({ target: { value } }) => {
    let count = parseInt(value, 10);
    if (!count || (count < PARTICIPANTS_COUNT_RANGE.MIN)) count = PARTICIPANTS_COUNT_RANGE.MIN;
    if (count > PARTICIPANTS_COUNT_RANGE.MAX) count = PARTICIPANTS_COUNT_RANGE.MAX;
    setParticipantsCount(count);
  }, []);
  return (
    <Styled.Wrapper isEditable={editable}>
      { (loading || innerLoading) && <ContainerLoader friendlyMode /> }
      {
        editable
        && (
          <Styled.Action>
            <CircleWithSign
              sign="-"
              color={COLORS_CONFIG.SMOOTH_BROWN}
              onClick={decrease}
              disabled={participantsCount <= PARTICIPANTS_COUNT_RANGE.MIN}
            />
          </Styled.Action>
        )
      }
      <Styled.ParticipantsCount
        value={participantsCount}
        onChange={onChange}
        type="number"
        disabled={!editable}
      />
      {
        editable
        && (
          <Styled.Action>
            <CircleWithSign
              sign="+"
              color={COLORS_CONFIG.SMOOTH_BROWN}
              onClick={increase}
              disabled={participantsCount === PARTICIPANTS_COUNT_RANGE.MAX}
            />
          </Styled.Action>
        )
      }
    </Styled.Wrapper>
  );
}

/* AnonymousView type of props */

AnonymousView.propTypes = {
  participants: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setAnonymousParticipants: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(GAME_CONFIG.MANAGER.MODE)).isRequired,
};

export default connect(({ manageGame }) => ({
  loading: manageGame.loading,
  participants: manageGame.participants,
}), {
  setAnonymousParticipants: ManageGameEntity.actions.setAnonymousParticipants,
})(AnonymousView);
