/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Table } from 'components/blocks/Table/styles';
import { Cell as ActionCell } from 'components/blocks/TableCells/ActionsCell/styles';

// TODO: styles of action cell after logic is completed

export const CampaignsSchedule = styled.div`
  margin-top: 35px;

  & ${Table} {
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    box-shadow: initial;
    & .rt-thead.-header {
      box-shadow: initial;
    }
    & ${ActionCell} {
      & > *:not(:first-child)::before {
          background-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      }
      & div {
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
`;

export default CampaignsSchedule;
