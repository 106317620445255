/* Libs */
import styled from 'styled-components';

import { Header } from 'components/blocks/ui/CardsHeader/styles';

export const Wrapper = styled.div`
  & ${Header} {
    padding-bottom: 18px;
  }
`;

export default Wrapper;
