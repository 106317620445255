/* Libs */
import styled from 'styled-components';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

/* Styles */

import {
  Wrapper as SelectWrapper,
} from 'components/ui/Select/styles';
import {
  Wrapper as CardWrapper,
} from 'components/blocks/Card/styles';
import {
  Title as CardHeaderTitle,
} from 'components/blocks/ui/CardsHeader/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const Wrapper = styled.div`
  width: 100%;
  height: 436px;

  & ${SelectWrapper} {
    position: absolute;
    top: 20px;
    right: 45px;
    height: 40px;
    width: 200px;
    height: 30px;

    & .ReactSelect__value-container {
      padding: 0;
      padding-left: 11px;
    }
  }

  & ${CardWrapper} {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 4px;

    & ${CardHeaderTitle} {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const SubTitle = styled.p`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0;
`;

export const Main = styled.div`
  height: calc( 100% - 60px );
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  
  ${CustomScroll}
`;
