/* Libs */
import React, {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
/* Utils */
import { validateEmail } from 'utils/validation/fields';
import { notification } from 'utils/services';
import { LOGO_CONFIG, NOTIFICATIONS_CONFIG } from 'config/index';
import { COMMON_ERRORS } from '_constants/errors';
import { isEmpty } from 'lodash';
import { copyToClipboard } from 'utils/custom';
import { NOTIFICATIONS, VALIDATION_VALUES } from '_constants';

/* Components */
import {
  Input,
  Button,
  Logo,
  BigTitle,
} from 'components';

/* Styles */
import { formIsValid } from 'utils/validation';
import * as Styled from './styles';


const DEFAULT_DATA = {
  email: '',
};
const DEFAULT_ERRORS = {
  email: null,
};


function BookTeamSessionForm({
  participantsDaTa,
  participantEmail,
  bookSession,
  loading,
}) {
  const {
    signedUpParticipantsAmount,
    participantsPerSessionAmount,
    sessionID,
    publicInviteLink,
  } = participantsDaTa;
  const [availableSlots, setAvailableSlots] = useState(participantsPerSessionAmount - signedUpParticipantsAmount - 1);
  const [formData, setData] = useState({
    data: DEFAULT_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  });
  const [bookedParticipants, setBookedParticipants] = useState([]);

  function copyInviteLink() {
    copyToClipboard(publicInviteLink);
    notification.success(NOTIFICATIONS.SUCCESS_COPY_INVITE_LINK);
  }


  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
          required: false,
        });
        break;
      default:
        break;
    }
    setData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    });
  };


  const validateOnBlur = ({ target: { name, value } }) => {
    const { errors } = formData;
    const newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors[name] = validateEmail({
          value,
          name,
          max: VALIDATION_VALUES.EMAIL_MAX_LENGTH,
          required: false,
        });
        break;
      default:
        break;
    }
    setData(prev => ({
      ...prev,
      errors: newErrors,
      canSubmit: formIsValid({ ...newErrors }, ['email']),
    }));
  };

  const hasAvailableSlots = () => availableSlots > 0;


  const deleteEmail = (id) => {
    setBookedParticipants(bookedParticipants.filter(({ id: emailId }) => emailId !== id));
    setAvailableSlots(prev => prev + 1);
  };


  const {
    data: {
      email,
    },
    errors,
    canSubmit,
  } = formData;

  const addEmail = (e) => {
    e.preventDefault();

    if (!hasAvailableSlots()) return;


    const bookedEmails = bookedParticipants.map(({ email }) => email);

    if ([...bookedEmails, participantEmail].includes(email)) {
      setData(prev => ({
        ...prev,
        errors: {
          ...prev.errors,
          email: COMMON_ERRORS.emailDuplication,
        },
        canSubmit: false,
      }));
      return;
    }

    setData(prev => ({
      ...prev,
      data: {
        ...prev.data,
        email: '',
      },
      errors: {
        ...prev.errors,
        email: null,
      },
      canSubmit: false,
    }));

    setBookedParticipants(bookedParticipants.concat({
      email,
      id: email,
    }));
    setAvailableSlots(prev => prev - 1);
  };

  const handleSave = () => {
    const bookedTeam = bookedParticipants.map(({ email }) => email);
    if (!isEmpty(email)) {
      return notification.warning(NOTIFICATIONS_CONFIG.confirmBookTeam(
        () => {
          bookSession({ sessionID, bookedTeam });
        },
        notification.removeAllNotifications,
      ));
    }
    bookSession({ sessionID, bookedTeam });
  };


  return (
    <Styled.Wrapper>
      <Logo type={LOGO_CONFIG.VERTICAL_BLUE_BLACK} />
      <BigTitle>
        Book For Team
      </BigTitle>
      <Styled.Data>
        <Styled.TitleWrapper>
          <Styled.Title>
            Add Participant(s):
          </Styled.Title>
          {` ${availableSlots} of ${participantsPerSessionAmount} slots are available`}
        </Styled.TitleWrapper>
        <Styled.LinkWrapper>
          You may invite participants via email or use a
          <Styled.Link onClick={copyInviteLink}>
            shareable link
          </Styled.Link>
        </Styled.LinkWrapper>
        <Styled.HostBox>
          <Styled.HostEmail>
            {participantEmail}
          </Styled.HostEmail>
          <Styled.HostLabel>
            (You)
          </Styled.HostLabel>
        </Styled.HostBox>
        <Styled.ParticipantsForm onSubmit={addEmail}>
          <Input
            type="email"
            name="email"
            placeholder="Participant email"
            onChange={handleChange}
            onBlur={validateOnBlur}
            value={email}
            error={errors.email}
            isDisabled={!hasAvailableSlots()}
          />
          <Styled.AddEmail disabled={!hasAvailableSlots() || !canSubmit}>
            + Add participant
          </Styled.AddEmail>
        </Styled.ParticipantsForm>
        <Styled.ParticipantsList>
          {
            bookedParticipants.map(({ email, id }) => (
              <Styled.Email key={id}>
                <Styled.EmailText>{email}</Styled.EmailText>
                <Styled.DeleteEmail onClick={() => deleteEmail(id)} />
              </Styled.Email>
            ))
          }
        </Styled.ParticipantsList>
      </Styled.Data>
      <Styled.Actions>
        <Button
          onClick={handleSave}
          disabled={isEmpty(bookedParticipants) || loading}
          isLoading={loading}
        >
          Book a session
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* BookTeamSessionForm type of props */
const {
  func, string, bool,
} = PropTypes;

BookTeamSessionForm.propTypes = {
  participantEmail: string.isRequired,
  loading: bool.isRequired,
  participantsDaTa: PropTypes.objectOf(Object).isRequired,
  bookSession: func.isRequired,
};

/* BookTeamSessionForm default props */
BookTeamSessionForm.defaultProps = {};

export default BookTeamSessionForm;
