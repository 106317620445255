/* Libs */
import styled, { css } from 'styled-components';

import {
  Button,
} from 'components/ui/Button/styles';
import { COLORS_CONFIG } from 'config';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';

export const Content = styled.div`
  flex: 1;
  padding: 20px 0 20px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & ${Button}:first-child {
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    width: initial;
    min-width: initial;
    padding: 0 35px 0 0 ;
  }
`;


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  ${({ isDarkMode }) => isDarkMode && css`
     & ${BigTitle}, ${Content}{
        color: ${COLORS_CONFIG.WHITE};
     }
     
     & ${Actions} {
        & ${Button} {
          background-color: ${COLORS_CONFIG.WHITE};
          color: ${COLORS_CONFIG.NAVY_BLUE};
          
          &:first-child:not(:last-child) {
            background-color: ${COLORS_CONFIG.TRANSPARENT};
            color: ${COLORS_CONFIG.WHITE};
            width: initial;
            min-width: initial;
            padding: 0 35px 0 0 ;
          }
        }
      }
   `}
`;
