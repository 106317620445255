/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { Button, LeaderboardHeader } from 'components';

/* Styles */

import * as Styled from './styles';

function LeaderboardStruct({
  children,
  companyName,
  shareLink,
  isDarkMode,
  handleCopy,
}) {
  return (
    <Styled.Struct isDarkMode={isDarkMode}>
      <Styled.Header>
        <LeaderboardHeader isDarkMode={isDarkMode} shareLink={shareLink} />
      </Styled.Header>
      <Styled.Page>
        <Styled.PageWrapper>
          <Styled.Title>
            <Styled.CompanyName>{companyName}</Styled.CompanyName>
            {' - Leaderboard'}
          </Styled.Title>
          <Styled.Action>
            <Styled.Link>
              { shareLink }
            </Styled.Link>
            <Button onClick={handleCopy}>
              Copy Link
            </Button>
          </Styled.Action>
        </Styled.PageWrapper>
        <Styled.Content>
          {children}
        </Styled.Content>
      </Styled.Page>
    </Styled.Struct>
  );
}

/* LeaderboardStruct type of props */

LeaderboardStruct.propTypes = {
  shareLink: PropTypes.string,
  companyName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/* LeaderboardStruct default props */

LeaderboardStruct.defaultProps = {
  shareLink: '',
  companyName: '',
};

export default LeaderboardStruct;
