/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

import * as logoTypes from 'assets/icons/logo';
import * as Styled from './styles';

// TODO: make from logo link to home page
function Logo({
  type,
  wrapperStyle,
}) {
  return (
    <Styled.Wrapper style={wrapperStyle}>
      <Styled.Logo src={logoTypes[type]} alt="logo" />
    </Styled.Wrapper>
  );
}

Logo.propTypes = {
};

Logo.defaultProps = {
};

export default Logo;
