/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  AVAILABLE_COLORS,
  COLORS_CONFIG,
} from 'config';

/* Styles */

import * as Styled from './styles';

function CircleWithSign({
  sign,
  color,
  onClick,
  disabled,
}) {
  const handleClick = () => {
    if (disabled || !onClick) return;
    onClick();
  };

  return (
    <Styled.Circle onClick={handleClick} disabled={disabled} color={color}>
      <Styled.Sign>
        {sign}
      </Styled.Sign>
    </Styled.Circle>
  );
}

/* CircleWithSign type of props */

CircleWithSign.propTypes = {
  sign: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

/* CircleWithSign default props */

CircleWithSign.defaultProps = {
  color: COLORS_CONFIG.SKY_BLUE,
  onClick: null,
  disabled: false,
};

export default CircleWithSign;
