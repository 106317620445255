/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

import { Table } from 'components/blocks/Table/styles';
import { Cell as ActionCell } from 'components/blocks/TableCells/ActionsCell/styles';
import { Label as GoLabel } from 'components/ui/GoLabel/styles';

// TODO: styles of action cell after logic is completed

export const TemplatesTable = styled.div`
  & ${Table} {
    & ${ActionCell} {
      & div:not(${GoLabel}),
      & a {
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 14px;
        cursor: pointer;
      }
    }
    @media (max-width: ${BREAKPOINTS.TABLET}) {
      .react-table__wrapper {
        .rt-thead {
          .rt-tr {
            padding: 25px 30px 0px !important;
          }
        }
        .rt-tbody {
          .rt-tr {
            padding: 25px 30px 20px!important;
          }
        }
      }
    }
  }
`;

export default TemplatesTable;
