/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
  background-color: ${COLORS_CONFIG.WHITE};
  padding: 20px 21px 25px 19px;
  border-radius: 4px;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
`;
