/* Libs */
import styled from 'styled-components';

/* Constants */
import { FONTS_CONFIG, COLORS_CONFIG, ADD_SESSION_TYPES } from 'config';

/* Styles */
import {
  Select,
  Wrapper as SelectWrapper,
} from 'components/ui/Select/styles';
import {
  Wrapper as DatePickerWrapper,
  DateLabel,
  Calendar,
} from 'components/ui/DatePicker/styles';
import { TextEllipsis } from 'assets/styles/mixins';


/* Component styles */
export const Wrapper = styled.div`
  flex-grow: 1;
  /* height: 100%; */
  width: ${({ formType }) => (formType === ADD_SESSION_TYPES.SINGLE ? '400px' : '860px')};
  transition: width 300ms;
  position: relative;
  display: flex;
  flex-direction: column;
  & ${SelectWrapper} {
    & ${Select} {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
  }
  & ${DatePickerWrapper} {
    & ${DateLabel} {
      height: 51px;
      border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      border-radius: 4px;
      &:hover {
        border-color: ${COLORS_CONFIG.SKY_BLUE};
      }
    }
  }
  & ${Calendar} {
    right: 0;
  }
`;

// export const Data = styled.div`
//   flex-grow: 1;
//   padding: 40px 56px 0px 40px;
//   height: calc( 100% - 200px );
//   overflow-y: auto;
// `;

// export const Label = styled.div`
//   font-size: 14px;
//   line-height: 14px;
//   padding-bottom: 8px;
// `;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 40px 10px;
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  line-height: 26px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  ${TextEllipsis};
`;

// export const CampaignName = styled.div`
//   font-size: 18px;
//   line-height: 18px;
// `;

export const Toggle = styled.div`
  flex-shrink: 0;
  display: flex;
  /* max-width: 290px; */
  width: 100%;
  justify-content: space-between;
  padding: 10px 40px 10px;
`;

// export const Campaign = styled.div`
//   padding: 10px 32px 10px 32px;
//   & ${Label},
//   & ${CampaignName} {
//     color: ${COLORS_CONFIG.SMOOTH_BROWN};
//     font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
//   }
// `;
