/* Libs */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

/* Utils */

import { getViewTemplateLink } from 'utils/custom';

/* Components */
import {
  SimpleTextCell,
  ActionsCell,
  Table,
} from 'components';

/* Constants */

import { COMPANIES_TABLE } from 'config';

/* Styles */
import * as Styled from './styles';

// Companies columns cell structure
const getColumnsData = ({ companyType, actions }) => [
  {
    accessor: 'name',
    minWidth: 95,
    flexGrow: 3,
    Header: 'Template name',
    Cell: props => <SimpleTextCell value={props.value} size="small" />,
  },
  {
    accessor: 'time_limit',
    minWidth: 95,
    flexGrow: 2,
    Header: 'Time limit',
    Cell: ({ value }) => <SimpleTextCell value={`${moment.duration(value, 'HH:mm:ss').asMinutes()}m`} size="small" />,
  },
  {
    accessor: 'questions_count',
    minWidth: 95,
    Header: 'Questions',
    Cell: ({ value, original: { is_final_code_only } }) => <SimpleTextCell value={is_final_code_only ? 'Final code' : (value).toString()} size="small" />,
  },
  {
    accessor: 'actions',
    minWidth: 90,
    Header: '',
    Cell: ({
      original: {
        id,
      },
    }) => (
      <ActionsCell>
        <Link to={getViewTemplateLink(id)}>Manage</Link>
      </ActionsCell>
    ),
  },
];


export default function TemplatesTable({
  data, actions, pagination, loading,
}) {
  return (
    <Styled.TemplatesTable>
      <Table
        defaultPageSize={data.length > 10 ? 10 : data.length}
        rowMinHeight="small"
        loading={loading}
        columns={getColumnsData({ actions })}
        pagination={pagination}
        sortable={COMPANIES_TABLE.sortable}
        data={data}
      />
    </Styled.TemplatesTable>
  );
}

// Type of props
TemplatesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    time_limit: PropTypes.string.isRequired,
    questions_count: PropTypes.number.isRequired,
    is_final_code_only: PropTypes.bool.isRequired,
  })).isRequired,
};
