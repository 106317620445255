/* Libs */
import React, {
  useState, useMemo, useEffect, useCallback, memo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Utils */
import { handleDateRangeChange } from 'utils/custom';

/* Components */

import {
  DatePicker,
  SearchInput,
  Checkbox,
  ContainerLoader,
  EmptyBlockText,
  Button,
} from 'components';

/* Constants */

import { DATE_SETTINGS, LOCATIONS_LIST } from 'config';

/* Styles */

import InfiniteScroll from 'react-infinite-scroll-component';
import { isEqual } from 'lodash';
import * as Styled from './styles';


function FilterSection({
  locations, onChange, startDate, loading, next, totalLocations, handleSearchLocations,
}) {
  const DEFAULT_DATA = {
    dateFrom: moment(startDate || []).format(
      DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
    ),
    dateTo: moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
    checkedLocations: [],
  };
  const [selectedData, setSelectedData] = useState(DEFAULT_DATA);
  const [activateData, setActivatedData] = useState(DEFAULT_DATA);
  const [search, setSearch] = useState('');
  const {
    dateFrom, dateTo, checkedLocations,
  } = selectedData;

  const handleDateChange = useCallback(
    ({ target }) => {
      const dateRange = handleDateRangeChange({
        target,
        from: {
          name: 'dateFrom',
          value: dateFrom,
        },
        to: {
          name: 'dateTo',
          value: dateTo,
        },
        changeToSameDate: true,
      });
      setSelectedData(prev => ({ ...prev, ...dateRange }));
    },
    [dateFrom, dateTo],
  );

  const handleSearch = ({ target: { value } }) => {
    setSearch(() => (value));
    handleSearchLocations(value);
  };

  const handleFilterChange = useCallback(({ target: { name, value } }) => {
    switch (name) {
      case 'dateFrom':
      case 'dateTo':
        handleDateChange({ target: { name, value } });
        break;
      default:
        setSelectedData(prev => ({ ...prev, [name]: value }));
    }
  }, []);

  const handleCheckboxChange = ({ target: { name, value } }) => {
    if (value) {
      const newLocation = locations.find(({ location }) => location === name);
      return setSelectedData(prev => ({
        ...prev,
        checkedLocations: prev.checkedLocations.concat(newLocation),
      }));
    }
    setSelectedData(prev => ({
      ...prev,
      checkedLocations: prev.checkedLocations.filter(({ location }) => location !== name),
    }));
  };

  const mapLocations = useMemo(
    () => locations.reduce((acc, { location, id }) => {
      if (
        !search.length
          || location.toLowerCase().includes(search.toLowerCase())
      ) {
        return acc.concat(
          <Checkbox
            key={id}
            name={location}
            label={location}
            value={
              !!selectedData.checkedLocations.find(({ location: loc }) => loc === location)
            }
            onChange={handleCheckboxChange}
          />,
        );
      }
      return acc;
    }, []),
    [locations, search, selectedData.checkedLocations],
  );

  const handleSave = () => {
    setActivatedData(selectedData);

    onChange({
      dateFrom,
      dateTo,
      locations: checkedLocations.map(({ id }) => id).sort(),
    });
  };

  useEffect(() => {
    setSelectedData(prev => ({
      ...prev,
      dateFrom: moment(startDate || []).format(
        DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY,
      ),
    }));
  }, [startDate]);

  useEffect(() => {
    handleSave();
  }, []);

  const minDate = useMemo(() => moment(dateFrom).toDate(), [dateFrom]);
  const maxDate = useMemo(
    () => moment(dateFrom)
      .add(6, 'months')
      .toDate(),
    [dateFrom],
  );

  return (
    <Styled.Wrapper>
      {loading && <ContainerLoader friendlyMode />}
      <Styled.ContentWrapper>
        <Styled.Title>Filter</Styled.Title>
        <Styled.Dates>
          <DatePicker
            name="dateFrom"
            value={dateFrom}
            onChange={handleFilterChange}
            editable={false}
          />
          <Styled.Delimiter />
          <DatePicker
            name="dateTo"
            value={dateTo}
            onChange={handleFilterChange}
            minDate={minDate}
            maxDate={maxDate}
            editable={false}
          />
        </Styled.Dates>
        <Styled.Locations>
          <Styled.InputLabel>Sessions distributed by location:</Styled.InputLabel>
          <SearchInput
            name="search"
            value={search}
            onChange={handleSearch}
            placeholder="Search location"
          />
          <Styled.CheckboxList empty={!mapLocations.length}>
            <InfiniteScroll
              dataLength={mapLocations.length}
              next={next}
              hasMore={mapLocations.length < totalLocations}
              height={mapLocations.length < LOCATIONS_LIST.PAGINATION.PER_PAGE ? '100%' : 355}
              scrollThreshold={0.9}
            >
              {mapLocations.length ? mapLocations : <EmptyBlockText />}
            </InfiniteScroll>
          </Styled.CheckboxList>
        </Styled.Locations>
      </Styled.ContentWrapper>
      <Button onClick={handleSave} disabled={isEqual(selectedData, activateData)}>
        Submit
      </Button>
    </Styled.Wrapper>
  );
}

/* FilterSection type of props */

FilterSection.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  loading: PropTypes.bool,
  totalLocations: PropTypes.number,
};

/* FilterSection default props */

FilterSection.defaultProps = {
  locations: [],
  startDate: null,
  loading: false,
  totalLocations: 0,
};

export default memo(FilterSection);
