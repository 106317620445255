/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import {
  Modal,
  AddStorylineForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

export default function AddStoryline({
  onClose,
  open,
  getAllStorylines,
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Add a storyline"
      open={open}
    >
      <Styled.AddStorylineModal>
        <AddStorylineForm onClose={onClose} getAllStorylines={getAllStorylines} />
      </Styled.AddStorylineModal>
    </Modal>
  );
}

// Type of props
AddStoryline.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
