import { history } from 'lib';
import { isEmpty } from 'utils/validation';
import { notification } from 'utils/services';
import { getError } from 'utils/custom';
/**
 * if message provided it will call new notification
 */
export default (url = '/', message = '') => {
  if (url.includes('http')) {
    return window.location.replace(url);
  }
  if (!isEmpty(message)) {
    notification.error(getError(message));
  }
  history.push(url);
};
