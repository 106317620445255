import queryString from 'query-string';
import * as TYPES from './Participant.types';

export const checkToken = (token = '') => ({
  types: [TYPES.CHECK_TOKEN, TYPES.CHECK_TOKEN_SUCCESS, TYPES.CHECK_TOKEN_FAIL],
  promise: client => client.post('/participants/get-sign-up/', { token }),
  payload: { token },
});

export const signup = (data = {}) => ({
  types: [TYPES.SIGNUP, TYPES.SIGNUP_SUCCESS, TYPES.SIGNUP_FAIL],
  promise: client => client.post('/participants/sign-up/', data),
});

export const downloadCalendar = ({ token, game_session }) => {
  const params = queryString.stringify({
    token,
    game_session,
  });
  return {
    types: [TYPES.DOWNLOAD_CALENDAR, TYPES.DOWNLOAD_CALENDAR_SUCCESS, TYPES.DOWNLOAD_CALENDAR_FAIL],
    promise: client => client.get(`/participants/download-calendar/?${params}`),
  };
};

export const finishSignup = () => ({
  type: TYPES.FINISH_SIGNUP,
});
