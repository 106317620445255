/* Libs */
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > *:last-child {
    padding-left: 16px;
  }
  & > *:nth-child(2) {
    flex: 1;
  }
`;
