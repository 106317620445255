/* Libs */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { GameDefEntity } from '_entities';

/* Utils */
import {
  getError, hasPermission, exportCSV,
} from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import {
  Card,
  Refresh,
  Pagination,
  ContainerLoader,
  GameDefinitionsTable,
} from 'components';

/* Constants */

import { PERMISSION_NAMES } from '_constants';
import { TABLES_CONFIG, URLS_CONFIG, GAME_DEF_TABLE } from 'config';

/* Styles */

import * as Styled from './styles';


function GameDefinitionsCard({
  gameDefs,
  total,
  loading,
  loaded,
  companyId,
  getCompanyGameDef,
  downloadGameDef,
  isImpersonatedAdmin,
}) {
  const [page, setPage] = useState(1);
  const handlePageChange = ({ selected }) => setPage(selected + 1);

  async function getGameDefs(currentPage) {
    try {
      await getCompanyGameDef(companyId, { page: currentPage });
    } catch (error) {
      notification.error(getError(error));
    }
  }

  async function loadGameDef(id) {
    try {
      const gameDef = await downloadGameDef(id);
      exportCSV(gameDef, 'game-definition');
    } catch (error) {
      notification.error(getError(error));
    }
  }

  useEffect(() => {
    getGameDefs(page);
  }, [page]);

  function getDisplay() {
    if (!loaded && loading) {
      return <ContainerLoader />;
    }
    if (!loaded && !loading) {
      return <Refresh refresh={() => getGameDefs(page)} />;
    }
    return (
      <GameDefinitionsTable
        data={gameDefs}
        tableSize={TABLES_CONFIG.SIZE.S}
        actions={{ loadGameDef }}
        pagination={(
          <Pagination
            pageCount={total / GAME_DEF_TABLE.PAGINATION.PER_PAGE}
            pageChangeHandler={handlePageChange}
            activePage={page}
          />
        )}
        isImpersonatedAdmin={isImpersonatedAdmin}
      />
    );
  }
  return (
    <Styled.Wrapper>
      <Card
        title="Game definitions"
        actions={hasPermission(PERMISSION_NAMES.ADD_GAME_DEFINITION) && !isImpersonatedAdmin
          ? [
            <Link to={`${URLS_CONFIG.admin.addGameDef}?company_id=${companyId}`} key="add">
              Add
            </Link>,
          ]
          : []
        }
      >
        {getDisplay()}
      </Card>
    </Styled.Wrapper>
  );
}

/* KitsCard type of props */

GameDefinitionsCard.propTypes = {
  gameDefs: PropTypes.arrayOf(PropTypes.shape({})).isRequired, // TODO: define prop more correctly
  total: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isImpersonatedAdmin: PropTypes.bool,
  getCompanyGameDef: PropTypes.func.isRequired,
  downloadGameDef: PropTypes.func.isRequired,
};

GameDefinitionsCard.defaultProps = {
  isImpersonatedAdmin: false,
};

export default connect(({ gameDef }) => ({
  gameDefs: gameDef.gameDefs,
  total: gameDef.total,
  loaded: gameDef.loaded,
  loading: gameDef.loading,
}), {
  getCompanyGameDef: GameDefEntity.actions.getCompanyGameDef,
  downloadGameDef: GameDefEntity.actions.downloadGameDef,
})(GameDefinitionsCard);
