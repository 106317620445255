/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { isNull } from 'utils/validation';

/* Components */

import {
  Img,
  EditStorylineModal,
} from 'components';

/* Styles */

import * as Styled from './styles';

function StorylineCell({
  storyline,
  openEditUserSidebar
}) {
  return (
    <Styled.Wrapper>
      {
        isNull(storyline)
          ? null
          : (
            <>
              <Img
                src={storyline.image}
                size={[75, 75]}
              />
              <Styled.Title>{storyline.name}</Styled.Title>
              <Styled.Action onClick={() => openEditUserSidebar(storyline.id)}>Edit</Styled.Action>
            </>
          )
      }
    </Styled.Wrapper>
  );
}

/* StorylineCell type of props */

StorylineCell.propTypes = {
  storyline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
  }),
};

StorylineCell.defaultProps = {
  storyline: null,
};

export default StorylineCell;
