/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { Logo } from 'components';

/* Constants */

import { LOGO_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function ResetPasswordStruct({
  children,
  title,
}) {
  return (
    <Styled.Wrapper>
      <Logo type={LOGO_CONFIG.VERTICAL_BLUE_WHITE} />
      {!!title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Content>
        { children }
      </Styled.Content>
    </Styled.Wrapper>
  );
}

/* ResetPasswordStruct type of props */

ResetPasswordStruct.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

/* ResetPasswordStruct default props */

ResetPasswordStruct.defaultProps = {
  title: '',
};

export default ResetPasswordStruct;
