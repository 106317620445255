export const COMPANY_ADMIN_PAGE = 'COMPANY_ADMIN_PAGE';
export const COMPANY_ADMIN_PAGE_CONTENT = 'COMPANY_ADMIN_PAGE_CONTENT';
export const REPORTS_PARTICIPANTS_TABLE = 'REPORTS_PARTICIPANTS_TABLE';
export const ADMIN_SETTINGS_PAGE = 'ADMIN_SETTINGS_PAGE';

export default {
  COMPANY_ADMIN_PAGE,
  ADMIN_SETTINGS_PAGE,
  COMPANY_ADMIN_PAGE_CONTENT,
  REPORTS_PARTICIPANTS_TABLE,
};
