/* Libs */
import styled from 'styled-components';

export const EditUserSidebar = styled.div`
  /* height: 100%; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 445px;
`;
