/* Libs */
import styled from 'styled-components';

/* Constants */

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

/* Styles */

import {
  Content as TooltipContent,
  Label as TooltipContentLabel,
} from 'components/blocks/ui/IconTooltip/styles';
import { CustomScroll } from 'assets/styles/mixins';


export const Wrapper = styled.div`
  width: 100%;
  height: 436px;
  display: flex;
  flex-direction: column;
  background: ${COLORS_CONFIG.WHITE}; 
  border-radius: 4px;
  padding: 20px;
  padding-right: 5px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;

export const Header = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;

  & ${TooltipContent} {
    width: 200px;
    left: 200%;
  }

  & ${TooltipContentLabel} {
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    color: ${COLORS_CONFIG.SMOOTH_BROWN};
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Title = styled.h2`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 20px;
  line-height: 24px;
`;

export const Main = styled.div`
  height: calc( 100% - 30px );
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  
  ${CustomScroll}
`;
