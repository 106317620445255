/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/* Styles */

import {
  Wrapper as ImgWrapper,
} from 'components/blocks/ui/Img/styles';


export const Wrapper = styled.div`
  background: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  position:relative;
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};

  & > ${ImgWrapper} {
    position: absolute;
    left: 10px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  padding-left: 30px;
  background: none;
  border: none;
  outline: none;
`;
