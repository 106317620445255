import { store } from 'lib';
import { StaticDataEntity } from '_entities';

export const getData = async (attempt = 1) => {
  try {
    return store.dispatch(StaticDataEntity.actions.bootstrap());
  } catch (error) {
    getData(attempt + 1);
  }
};

export default { getData };
