/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG } from 'config';

import { Select, Wrapper as SelectWrapper } from 'components/ui/Select/styles';
import {
  Wrapper as GoLabelWrapper,
  Label as GoLabelLabel,
} from 'components/ui/GoLabel/styles';
import { CustomScroll } from 'assets/styles/mixins';

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  ${SelectWrapper} {
    margin-top: 24px;
    ${Select} {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
  }
  ${GoLabelWrapper} {
    margin: 19px 0 5px;
  }
  ${GoLabelLabel} {
    font-size: 14px;
  }
`;

export const Data = styled.div`
  flex: 1 1 auto;
  padding: 24px 32px 20px 32px;
  /* height: calc( 100% - 200px ); */
  height: 0;
  overflow-y: auto;

  ${() => CustomScroll({ scrollBG: 'transparent' })}
`;

export const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 32px;
  width: 100%;
  min-height: 120px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10_20};
`;

export const Options = styled.div`
  border-top: 1px solid ${COLORS_CONFIG.SILVER_LIGHT};
`;
