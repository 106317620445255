import BasicInformationForm from './BasicInformationForm';
import QuestionsForm from './QuestionsForm';
import StepForm from './StepForm';
import OnlineOnlyForm from './OnlineOnlyForm';

export default {
  BasicInformationForm,
  QuestionsForm,
  StepForm,
  OnlineOnlyForm,
};
