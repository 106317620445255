/* Libs */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Constants */
import { TRIANGLE_TYPES, LABEL_COLOR_TYPES, TIME_OPTIONS } from '_constants';

/* Components */
import {
  Select,
  Button,
  Input,
  ConfirmBlock,
  DatePicker,
  TimezoneSelect,
  GoLabel,
  Switcher,
  Accordion,
} from 'components';


import { getTimezoneName } from 'utils/custom';

/* Styles */
import { COMMON_ERRORS } from '_constants/errors';
import * as Styled from './styles';

function EditSessionForm(props) {
  const {
    confirmSidebar,
    onDelete,
    closeConfirmSidebar,
    handleChange,
    handleDateChange,
    campaign,
    kit,
    gameDef,
    dateFrom,
    startTime,
    handleMenuOpen,
    list,
    location,
    loadLocations,
    participantEmail,
    participantEmails,
    handleChangeParticipantEmail,
    addEmail,
    deleteEmail,
    saveData,
    checkAllFields,
    saveText,
    openConfirmSidebar,
    timeOptions,
    kitOptions,
    isKitOptionDisabled,
    gameDefOptions,
    loadGameDefOptions,
    locationOptions,
    timezone,
    dateError,
    optionsAreVisible,
    toggleOptionsVisibility,
    introductionTime,
    debriefingTime,
    participantsPerSession,
    isSecretSession,
    changeParticipantsPerSession,
    isTimerCountingUp,
    toggleTimerType,
    isLoading,
    autoSignUp,
    signedUpParticipants,
    meetingLink,
    isOnlineOnly,
    isEdit,
    validateOnBlurWithMessage,
    validationErrors,
    withValidationReset,
  } = props;

  if (confirmSidebar) {
    return (
      <ConfirmBlock
        title="Are you sure you want to delete this session?"
        onSubmit={onDelete}
        onCancel={closeConfirmSidebar}
        submitText="Delete"
        cancelText="Back to edit"
      />
    );
  }

  const timezoneSelectProps = useMemo(
    () => (() => ({
      title: 'Timezone',
      name: 'timezone',
      searchable: true,
      onBlur: validateOnBlurWithMessage(COMMON_ERRORS.isRequired('timezone')),
      error: validationErrors.timezone,
    }))(),
    [validationErrors.timezone],
  );

  const campaignTitle = useMemo(
    () => (() => (
      <Styled.Campaign>
        <Styled.Label>Campaign</Styled.Label>
        <Styled.CampaignName>
          {get(campaign, 'name', 'No campaign name')}
        </Styled.CampaignName>
      </Styled.Campaign>
    ))(),
    [],
  );

  const invitedParticipants = useMemo(
    () => participantEmails.filter(
      participant => !signedUpParticipants.some(
        signedParticipant => signedParticipant.email === participant.email,
      ),
    ),
    [signedUpParticipants, participantEmails],
  );

  const confirmedParticipants = useMemo(
    () => participantEmails.filter(participant => signedUpParticipants.some(
      signedParticipant => signedParticipant.email === participant.email,
    )),
    [signedUpParticipants, participantEmails],
  );

  const moreOptions = useMemo(
    () => (() => {
      if (!optionsAreVisible) return null;

      return (
        <Styled.Options>
          <Select
            name="introductionTime"
            key="introductionTime"
            title="Introduction time"
            options={TIME_OPTIONS}
            value={introductionTime}
            onChange={handleChange}
            placeholder="Select introduction time"
            withoutReset
          />
          <Select
            name="debriefingTime"
            key="debriefingTime"
            title="Debriefing time"
            options={TIME_OPTIONS}
            value={debriefingTime}
            onChange={handleChange}
            placeholder="Select debriefing time"
            withoutReset
          />
          <Input
            name="participantsPerSession"
            key="participantsPerSession"
            title="Max participants"
            value={participantsPerSession}
            onChange={withValidationReset(changeParticipantsPerSession)}
            placeholder="Enter participants per session"
            onBlur={validateOnBlurWithMessage(
              COMMON_ERRORS.numberLessThan('participants', 1),
            )}
            error={validationErrors.participantsPerSession}
          />
          <Switcher
            checked={isTimerCountingUp}
            onChange={toggleTimerType}
            checkedText="Count up"
            uncheckedText="Count down"
          />
        </Styled.Options>
      );
    })(),
    [
      optionsAreVisible,
      introductionTime,
      debriefingTime,
      participantsPerSession,
      isSecretSession,
      isTimerCountingUp,
      validationErrors.participantsPerSession,
    ],
  );

  return (
    <Styled.Wrapper>
      <Styled.Data>
        {campaignTitle}
        <Select
          name="kit"
          placeholder="Nickname (Kit ID)"
          title="Nickname (Kit ID)"
          value={kit}
          onChange={withValidationReset(handleChange)}
          options={kitOptions}
          defaultOptions={kitOptions}
          searchable
          isOptionDisabled={isKitOptionDisabled}
          onBlur={validateOnBlurWithMessage(
            COMMON_ERRORS.isRequired('nickname'),
          )}
          error={validationErrors.kit}
        />
        <Select
          name="gameDef"
          placeholder="Game definition"
          title="Game definition"
          value={gameDef}
          onChange={withValidationReset(handleChange)}
          options={gameDefOptions}
          defaultOptions={gameDefOptions}
          loadOptions={loadGameDefOptions}
          async
          searchable
          onBlur={validateOnBlurWithMessage(
            COMMON_ERRORS.isRequired('game definition'),
          )}
          error={validationErrors.gameDef}
        />
        <DatePicker
          name="dateFrom"
          placeholder="Pick a date"
          value={dateFrom}
          error={dateError}
          onChange={handleDateChange}
          withIcon
        />
        <Select
          name="startTime"
          placeholder="Time"
          title="Time"
          value={startTime}
          options={timeOptions}
          onChange={withValidationReset(handleChange)}
          searchable
          onMenuOpen={handleMenuOpen}
          forwardedRef={list}
          onBlur={validateOnBlurWithMessage(
            COMMON_ERRORS.isRequired('start time'),
          )}
          error={validationErrors.startTime}
        />
        <TimezoneSelect
          value={getTimezoneName(timezone)}
          onChange={handleChange}
          selectProps={timezoneSelectProps}
        />
        <Select
          name="location"
          placeholder="Location"
          title="Location"
          value={location}
          onChange={withValidationReset(handleChange)}
          options={locationOptions}
          defaultOptions={locationOptions}
          loadOptions={loadLocations}
          async
          creatable
          searchable
          onBlur={validateOnBlurWithMessage(
            COMMON_ERRORS.isRequired('location'),
          )}
          error={validationErrors.location}
        />
        {isOnlineOnly && !isSecretSession && (
          <Input
            name="meetingLink"
            placeholder="Meeting link"
            title="Meeting link"
            onChange={handleChange}
            value={meetingLink}
          />
        )}
        <GoLabel
          text="More options"
          triangleType={
            optionsAreVisible
              ? TRIANGLE_TYPES.FORWARD_DOWN
              : TRIANGLE_TYPES.FORWARD
          }
          color={LABEL_COLOR_TYPES.BLUE}
          click={toggleOptionsVisibility}
        />
        {moreOptions}
        {!isSecretSession && (
          <Styled.Participants>
            <Styled.AddParticipantsWrapper>
              <Styled.ParticipantsCount>
                {`Add participants (${participantEmails.length}/${
                  participantsPerSession || 0
                })`}
              </Styled.ParticipantsCount>
              <Switcher
                name="autoSignUp"
                checked={autoSignUp}
                onChange={value => handleChange({ target: { name: 'autoSignUp', value } })
                }
                checkedText="Auto sign up: ON"
                uncheckedText="Auto sign up: OFF"
                disabled={
                  signedUpParticipants
                  && !!signedUpParticipants.length
                  && autoSignUp
                }
              />
            </Styled.AddParticipantsWrapper>
            {isEdit
              && !!(confirmedParticipants && confirmedParticipants.length) && (
              <Accordion
                disabled={!confirmedParticipants.length}
                preopen={confirmedParticipants.length}
                header={(
                  <Styled.AccordionHeader
                    empty={!confirmedParticipants.length}
                  >
                    Confirmed
                  </Styled.AccordionHeader>
                )}
                content={(
                  <Styled.ParticipantsList>
                    {confirmedParticipants.map(({ email, id }) => (
                      <Styled.Email key={id}>
                        <Styled.EmailText>{email}</Styled.EmailText>
                        <Styled.DeleteEmail onClick={() => deleteEmail(id)} />
                      </Styled.Email>
                    ))}
                  </Styled.ParticipantsList>
                )}
              />
            )}
            <Accordion
              disabled={!invitedParticipants.length}
              preopen={invitedParticipants.length}
              header={(
                <Styled.AccordionHeader empty={!invitedParticipants.length}>
                  Invited
                </Styled.AccordionHeader>
              )}
              content={(
                <Styled.ParticipantsList>
                  {invitedParticipants.map(({ email, id }) => (
                    <Styled.Email key={id}>
                      <Styled.EmailText>{email}</Styled.EmailText>
                      <Styled.DeleteEmail onClick={() => deleteEmail(id)} />
                    </Styled.Email>
                  ))}
                </Styled.ParticipantsList>
              )}
            />
            <Styled.ParticipantsForm onSubmit={addEmail}>
              <Input
                type="email"
                name="participantEmail"
                placeholder="Participant email"
                onChange={handleChangeParticipantEmail}
                value={participantEmail}
                isDisabled={
                  participantsPerSession
                  <= confirmedParticipants.length + invitedParticipants.length
                }
                error={validationErrors.participantEmail}
              />
              <Styled.AddEmail
                disabled={
                  participantsPerSession
                  <= confirmedParticipants.length + invitedParticipants.length
                  || !participantEmail.length
                }
              >
                + Invite participant
              </Styled.AddEmail>
            </Styled.ParticipantsForm>
          </Styled.Participants>
        )}
      </Styled.Data>
      <Styled.Actions>
        <Button
          onClick={saveData}
          isLoading={isLoading}
          disabled={checkAllFields() || isLoading}
        >
          {saveText}
        </Button>
        {isEdit && (
          <Styled.DeleteSession>
            <Button onClick={openConfirmSidebar}>Delete session</Button>
          </Styled.DeleteSession>
        )}
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* EditSessionForm type of props */
const {
  bool, func, shape, string, number, oneOfType, arrayOf,
} = PropTypes;

EditSessionForm.propTypes = {
  confirmSidebar: bool,
  onDelete: func,
  closeConfirmSidebar: func,
  handleChange: func,
  handleDateChange: func,
  campaign: shape({
    name: string,
  }),
  kit: number,
  gameDef: number,
  dateFrom: string,
  startTime: string,
  handleMenuOpen: func,
  list: PropTypes.any,
  location: oneOfType([number, string]),
  locations: arrayOf(
    shape({
      value: string,
      label: string,
    }),
  ),
  loadLocations: func,
  participantEmail: string,
  participantEmails: arrayOf(string),
  maxParticipantsPerSession: number,
  handleChangeParticipantEmail: func,
  addEmail: func,
  deleteEmail: func,
  saveData: func,
  checkAllFields: func,
  saveText: string,
  openConfirmSidebar: func,
  timeOptions: arrayOf(
    shape({
      name: string,
      value: string,
    }),
  ),
  kitOptions: arrayOf(
    shape({
      name: string,
      value: number,
      disabled: bool,
    }),
  ),
  gameDefOptions: arrayOf(
    shape({
      name: string,
      value: oneOfType([string, number]),
    }),
  ),
  timezone: string,
  optionsAreVisible: bool.isRequired,
  toggleOptionsVisibility: func.isRequired,
  introductionTime: string,
  debriefingTime: string,
  participantsPerSession: oneOfType([string, number]).isRequired,
  isSecretSession: bool.isRequired,
  changeParticipantsPerSession: func.isRequired,
  meetingLink: string,
  isEdit: bool,
};

/* EditSessionForm default props */
EditSessionForm.defaultProps = {
  campaign: {
    name: 'No campaign name',
  },
  saveText: 'Save',
  timeOptions: [],
  kitOptions: [],
  locations: [],
  list: null,
  timezone: null,
  meetingLink: '',
  isEdit: false,
};

export default memo(EditSessionForm);
