/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */

import {
  CircleBack,
  CircleForward,
} from 'components';

/* Constants */

import {
  DATE_SETTINGS,
  TABLE_TYPES,
} from 'config';

/* Styles */

import * as Styled from './styles';

function CalendarHeader({
  date,
  dateInterval,
  onBackClick,
  onForwardClick,
  format,
  calendarType,
}) {
  return (
    <Styled.Wrapper>
      <Styled.FormattedDate calendarType={calendarType}>
        {
          dateInterval
            ? `${moment(date).startOf('week').format(format)} - ${moment(date).endOf('week').format(format)}`
            : moment(date).format(format)
        }
      </Styled.FormattedDate>
      <Styled.Actions>
        <CircleBack onClick={onBackClick} />
        <CircleForward onClick={onForwardClick} />
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* CalendarHeader type of props */

CalendarHeader.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  dateInterval: PropTypes.bool,
  onBackClick: PropTypes.func.isRequired,
  onForwardClick: PropTypes.func.isRequired,
  format: PropTypes.oneOf(Object.values(DATE_SETTINGS.FORMAT)),
  calendarType: PropTypes.string,
};

/* CalendarHeader default props */

CalendarHeader.defaultProps = {
  dateInterval: false,
  format: DATE_SETTINGS.FORMAT.MONTH_YEAR,
  calendarType: TABLE_TYPES.DAY,
};

export default memo(CalendarHeader);
