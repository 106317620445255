/* Libs */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Utils */
import { isEmpty, isNull } from 'utils/validation';
import { notification } from 'utils/services';

/* Constants */

import { URLS_CONFIG } from 'config';
import { NOTIFICATIONS } from '_constants';

function AdminRoute({
  authorized,
  isAdmin,
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const history = [props.history.location.pathname, ...get(props, 'location.state.history', [])];
        const redirect = authorized
          ? isAdmin
            ? URLS_CONFIG.admin.default
            : URLS_CONFIG.auth.signin
          : URLS_CONFIG.auth.signin;
        if (!isAdmin) {
          notification.error(NOTIFICATIONS.DONT_HAVE_PERMISSION);
        }
        return authorized && isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              search: get(props, 'location.state.from.search', ''),
              state: {
                from: props.location,
                history,
              },
            }}
          />
        );
      }}
    />
  );
}

/* Component PropTypes */

AdminRoute.propTypes = {
  authorized: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth, staticData }) => ({
  authorized: !isEmpty(auth.token) && !isNull(auth.id),
  isAdmin: auth.role === staticData.roles.admin,
});

export default connect(mapStateToProps)(AdminRoute);
