/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryTooltip,
  Flyout,
} from 'victory';

/* Constants */

import {
  COLORS_CONFIG,
} from 'config';

const CustomFlyout = (props) => {
  const { x, y } = props;
  return (
    <g>
      <line
        x1={x}
        y1={y}
        x2={x}
        y2={270}
        style={{
          stroke: COLORS_CONFIG.SKY_BLUE,
          strokeDasharray: 3,
          strokeDashoffset: 6,
        }}
      />
      <Flyout {...props} />
    </g>
  );
};

function ChartTooltip({
  withLine,
  ...rest
}) {
  return (
    <VictoryTooltip
      {...rest}
      cornerRadius={() => 0}
      pointerLength={() => 0}
      dy={-10}
      style={{
        fill: COLORS_CONFIG.WHITE,
      }}
      flyoutStyle={{
        fill: COLORS_CONFIG.SKY_BLUE,
        strokeWidth: 0,
      }}
      flyoutComponent={((withLine) ? <CustomFlyout /> : <Flyout />)}
    />
  );
}

/* ChartTooltip type of props */

ChartTooltip.propTypes = {
  withLine: PropTypes.bool,
};

/* ChartTooltip default props */

ChartTooltip.defaultProps = {
  withLine: false,
};

export default ChartTooltip;
