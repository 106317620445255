import { SESSION_TIME_TYPES } from 'config';
import { getTimezoneName, getTimeInUTCFormat } from 'utils/custom';
import getTimezonesDiff from '../getTimezonesDiff';


const getSessionTimeData = ({
  type,
  companyTimezone,
  propsTimezone,
  propsStartTime,
  propsEndTime,
}) => {
  const isSessionTimeType = type === SESSION_TIME_TYPES.SESSION_TIME;

  const sessionTimezone = getTimezoneName(propsTimezone);
  const localTimezone = getTimezoneName(companyTimezone);

  // get timezone
  const timezone = isSessionTimeType ? sessionTimezone : localTimezone;


  // get offset
  const offset = getTimezonesDiff(localTimezone, sessionTimezone, propsStartTime);


  if (isSessionTimeType) {
    return ({
      timezone: sessionTimezone,
      startTime: getTimeInUTCFormat(propsStartTime, sessionTimezone),
      endTime: getTimeInUTCFormat(propsEndTime, sessionTimezone),
      offset,
    });
  }


  if (localTimezone === sessionTimezone) {
    return ({
      timezone: sessionTimezone,
      startTime: getTimeInUTCFormat(propsStartTime, sessionTimezone),
      endTime: getTimeInUTCFormat(propsEndTime, sessionTimezone),
      offset,
    });
  }

  // get startTime
  const startTime = propsStartTime && getTimeInUTCFormat(propsStartTime, localTimezone);

  // get endTime
  const endTime = propsEndTime && getTimeInUTCFormat(propsEndTime, localTimezone);


  return ({
    timezone,
    startTime,
    endTime,
    offset,
  });
};


export default getSessionTimeData;
