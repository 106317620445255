/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';


export const Cell = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & > *:not(:first-child) {
    position: relative;
    padding: 0 16px;
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 24px;
      background-color: ${COLORS_CONFIG.SILVER};
    }
  }
  & > *:first-child {
    padding-right: 16px;
  }
`;

export default Cell;
