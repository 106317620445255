/* Libs */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';

/* Utils */

import { notification } from 'utils/services';
import { getError, getStaticData } from 'utils/custom';

/* Components */

import {
  CompanyAdminStruct,
  CompanyAdminCompanyStruct,
  LicensingCard,
  LicenseUsageCard,
  PointOfContactCard,
  GameDefinitionsCard,
  KitsCard,
} from 'components';

/* Actions */

import { AdminCompanyEntity } from '_entities';

/* Constants */

import { URLS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';


const PAGE_TITLE = 'Company';

function Company({
  companyId,
  company,
  getCompanyById,
  userRole,
}) {
  useEffect(() => {
    async function getCompanyInfo() {
      try {
        await getCompanyById(companyId);
      } catch (error) {
        notification.error(getError(error));
      }
    }
    getCompanyInfo();
  }, []);

  const isImpersonatedAdmin = getStaticData('roles').admin === userRole;

  return (
    <CompanyAdminStruct>
      <Styled.Wrapper>
        <CompanyAdminCompanyStruct
          title={company.name}
          topRow={(
            <>
              <LicensingCard
                data={company.contract || {}}
                isImpersonatedAdmin={isImpersonatedAdmin}
              />
              <LicenseUsageCard
                used={company.used_user_licenses || 0}
                total={company.licenses || 0}
                isImpersonatedAdmin={isImpersonatedAdmin}
              />
              <PointOfContactCard
                first_name={get(company, 'admin.first_name', '')}
                last_name={get(company, 'admin.last_name', '')}
                email={get(company, 'admin.email', '')}
                id={get(company, 'admin.id', 0)}
                isImpersonatedAdmin={isImpersonatedAdmin}
              />
            </>
          )}
          bottomRow={(
            <>
              <GameDefinitionsCard
                companyId={companyId}
                isImpersonatedAdmin={isImpersonatedAdmin}
              />
              <KitsCard
                companyId={companyId}
                isImpersonatedAdmin={isImpersonatedAdmin}
              />
            </>
          )}
        />
      </Styled.Wrapper>
    </CompanyAdminStruct>
  );
}

/* Page Url */

Company.path = URLS_CONFIG.companyAdmin.company;

/* Page Title */

Company.title = PAGE_TITLE;

/* Company type of props */
const {
  number,
  func,
  // arrayOf,
  shape,
  string,
  // bool,
} = PropTypes;

Company.propTypes = {
  company: shape({}), // TODO: write `normal` propType
  companyId: number.isRequired,
  userRole: string.isRequired,
  getCompanyById: func.isRequired,
};

/* Company default props */

Company.defaultProps = {
  company: {},
};

const mapStateToProps = state => ({
  companyId: state.auth.company,
  userRole: state.auth.role,
  company: state.adminCompany.company,
});

export default connect(mapStateToProps, {
  getCompanyById: AdminCompanyEntity.actions.getCompany,
})(Company);
