/* Libs */
import styled from 'styled-components';

import { Circle as CircleFilled } from 'components/blocks/Markers/CircleFilled/styles';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & ${CircleFilled}:not(:first-child) {
    margin-left: 12px;
  }
`;
