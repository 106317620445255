/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

/* Components */
import { LeaderboardListItem, EmptyBlockText } from 'components';

/* Styles */
import * as Styled from './styles';

function LeaderboardList({ data, next, total }) {
  if (!data.length) {
    return <EmptyBlockText />;
  }

  return (
    <Styled.Wrapper>
      <InfiniteScroll
        dataLength={data.length}
        next={next}
        hasMore={data.length < total}
        scrollThreshold={0.9}
        height={data.length < 5 ? '100%' : 355}
        loader={<h3 style={{ textAlign: 'center' }}>Loading..</h3>}
      >
        {data.map((item, i) => (
          <LeaderboardListItem key={i} {...item} place={i + 1} />
        ))}
      </InfiniteScroll>
    </Styled.Wrapper>
  );
}

/* LeaderboardList type of props */

LeaderboardList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      team_name: PropTypes.string,
      time_completed: PropTypes.string,
    }),
  ),
  next: PropTypes.func.isRequired,
  total: PropTypes.number,
};

/* LeaderboardList default props */

LeaderboardList.defaultProps = {
  data: [],
  total: 0,
};

export default LeaderboardList;
