import { TIME_LIMITS } from '_constants';
import { getTimezoneOffset } from 'utils/custom';
import moment from 'moment-timezone';
import * as TYPES from './StaticData.types';

const userTimezone = moment.tz.guess();

const initialState = { // TODO: get data from BE
  timeLimits: TIME_LIMITS,
  loaded: false,
  roles: {},
  gameSessionStatuses: {},
  weekdays: {},
  timezone: userTimezone,
  timezoneOffset: getTimezoneOffset(userTimezone),
  isDarkMode: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.BOOTSTRAP_SUCCESS:
      return {
        ...state,
        roles: { ...action.result.user_roles },
        gameSessionStatuses: { ...action.result.game_session_statuses },
        loaded: true,
        weekdays: { ...action.result.weekdays },
        gamePlayStatuses: action.result.game_play_statuses,
      };
    case TYPES.SET_DARK_MODE:
      return {
        ...state,
        isDarkMode: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
