import pick from 'lodash.pick';
import * as TYPES from './Company.types';

const initialState = {
  loading: false,
  company: {},
  laoded: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.RESET_COMPANY_STATE:
      return {
        ...initialState,
      };
    case TYPES.CHANGE_COMPANY_ACTIVITY:
    case TYPES.DELETE_COMPANY:
    case TYPES.UPDATE_POC:
    case TYPES.UPDATE_COMPANY_CONTRACT:
    case TYPES.UPDATE_COMPANY_LICESES_COUNT:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_POC_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          admin: { ...action.result },
        },
        loading: false,
      };
    case TYPES.UPDATE_COMPANY_LICESES_COUNT_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          licenses: parseInt(action.result.number_of_seats, 10),
        },
        loading: false,
      };
    case TYPES.UPDATE_COMPANY_CONTRACT_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          contract: {
            ...pick(action.result, ['id', 'start_date', 'end_date']),
          },
        },
        loading: false,
      };
    case TYPES.GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        company: { ...action.result },
        loaded: true,
        loading: false,
      };
    case TYPES.UPDATE_POC_FAIL:
    case TYPES.GET_COMPANY_BY_ID_FAIL:
    case TYPES.UPDATE_COMPANY_CONTRACT_FAIL:
    case TYPES.DELETE_COMPANY_FAIL:
    case TYPES.CHANGE_COMPANY_ACTIVITY_FAIL:
    case TYPES.UPDATE_COMPANY_LICESES_COUNT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.CHANGE_COMPANY_ACTIVITY_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          is_active: action.result.is_active,
        },
      };
    default:
      return state;
  }
};

export default reducer;
