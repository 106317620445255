/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const UserName = styled.div`
  max-width: 250px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
