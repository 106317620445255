import { getPermissions } from 'utils/custom';

let userRole = null;

export const setUserRole = (data) => {
  userRole = data;
};

export default (permissionName) => {
  const permission = getPermissions()[permissionName];

  return permission
    ? permission.includes(userRole)
    : false;
  // TODO: uncomment when User role will be array
  // return userRoles.some(role => getPermissions()[permissionName].includes(role));
};
