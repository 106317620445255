/* Libs */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/* Constants */

import { STRUCT_IDS } from '_constants';

/* Components */

import {
  TableActionSidebar,
  EditAdminForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

export default function EditAdminSidebar({
  open,
  nonDeletableUsersId,
  title,
  onClose,
  id,
  user,
  loadAllUsers,
  clearSearchOnRequest,
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleClose = () => {
    setShowConfirmationModal(false);
    onClose();
  };

  if (!open) return null;
  return (
    <TableActionSidebar
      open={open}
      title={title}
      onClose={handleClose}
      id={id}
      fullScreenHeight
      showTitle={!showConfirmationModal}
    >
      <Styled.EditAdminSidebar>
        <EditAdminForm
          loadAllUsers={loadAllUsers}
          clearSearchOnRequest={clearSearchOnRequest}
          isHiddenDeleteButtons={nonDeletableUsersId.includes(user.id)}
          user={user}
          onClose={handleClose}
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      </Styled.EditAdminSidebar>
    </TableActionSidebar>
  );
}

// Type of props
EditAdminSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  nonDeletableUsersId: PropTypes.arrayOf(PropTypes.number),
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  user: PropTypes.object,
};

// Default value for props
EditAdminSidebar.defaultProps = {
  id: STRUCT_IDS.ADMIN_SETTINGS_PAGE,
  nonDeletableUsersId: [],
};
