/* Libs */
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';

/* Components */
import {
  GameDefinitionForm,
} from 'components';

/* Config */

import { URLS_CONFIG } from 'config';

/* Actions */

// eslint-disable-next-line camelcase
import { GameDefEntity, LS_AdminCompanyEntity } from '_entities';

/* Utils */

import { notification } from 'utils/services';
import {
  isNull,
  validateGameDefForm,
} from 'utils/validation';
import {
  getError,
  getViewCompanyLink,
  redirect,
  redirectWithError,
  deepCopyObject,
} from 'utils/custom';
import { removeIds } from 'utils/transformers';


/* Constants */

import { GAME_DEF_FORM, GAME_DEF_FORM_DATA, NOTIFICATIONS } from '_constants';

const DEFAULT_FORM_DATA = {
  data: { ...GAME_DEF_FORM_DATA.DEFAULT_DATA },
  errors: { ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS },
};

const PAGE_TITLE = 'Add Game Definition';

const AddGameDef = ({
  getCompanyById,
  createCompanyGameDef,
  getGameDefById,
}) => {
  const [formData, setFormData] = useState({ ...deepCopyObject(DEFAULT_FORM_DATA) });
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(null);

  const formChanged = useMemo(() => {
    if (DEFAULT_FORM_DATA.data.is_final_code_only && formData.data.is_final_code_only) {
      return !isEqual({
        ...deepCopyObject(DEFAULT_FORM_DATA.data),
        questions: null,
      }, {
        ...formData.data,
        questions: null,
      });
    }
    return !isEqual(DEFAULT_FORM_DATA.data, formData.data);
  }, [formData.data]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const requestedData = {
        ...formData.data,
        company: companyId,
      };
      if (requestedData.is_final_code_only) {
        delete requestedData.questions;
      }
      if (isNull(requestedData.game_def_template)) {
        delete requestedData.game_def_template;
      }
      await createCompanyGameDef(requestedData);
      redirect(getViewCompanyLink(companyId), NOTIFICATIONS.SUCCESS_ADD_GAME_DEF);
    } catch (error) {
      const newError = getError(error);
      if (error.response.data.name) {
        setFormData(prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            name: newError,
          },
        }));
      }
      notification.error(newError);
      setLoading(false);
    }
  }, [formData.data, companyId, setFormData, setLoading]);

  useEffect(() => {
    (async () => {
      try {
        const queryCompanyId = new URLSearchParams(window.location.search).get('company_id');
        const templateId = new URLSearchParams(window.location.search).get('template_id');
        const { id } = await getCompanyById(queryCompanyId);
        if (parseInt(templateId, 10) > 0) {
          const gameDef = await getGameDefById(templateId);
          const newData = {
            ...gameDef,
            name: null,
            game_def_template: +templateId,
            initial_template_name: gameDef.name, // we need that for template select on initial load
            questions: removeIds(gameDef.questions),
          };
          const newErrors = validateGameDefForm(newData);
          setFormData({
            data: newData,
            errors: {
              ...GAME_DEF_FORM_DATA.DEFAULT_FORMS_ERRORS,
              ...newErrors,
            },
          });
        } else {
          setFormData({
            ...formData,
            data: {
              ...formData.data,
            },
          });
        }
        setCompanyId(id);
      } catch (error) {
        if (get(error, 'response.status', null) === 404) {
          redirectWithError(URLS_CONFIG.admin.companies, NOTIFICATIONS.COMPANY_DOESNT_EXISTS);
        }
      }
    })();
  }, []);

  return (
    <GameDefinitionForm
      title={PAGE_TITLE}
      formData={formData}
      setFormData={setFormData}
      loading={loading}
      setLoading={setLoading}
      handleSave={handleSave}
      companyId={companyId}
      mode={GAME_DEF_FORM.MODE.ADD}
      formChanged={formChanged}
      goBack={{
        enabled: true,
        text: 'Back to company',
        href: getViewCompanyLink(companyId),
      }}
    />
  );
};

/* Page Url */

AddGameDef.path = URLS_CONFIG.admin.addGameDef;

/* Page Title */

AddGameDef.title = PAGE_TITLE;

AddGameDef.propTypes = {
  getCompanyById: PropTypes.func.isRequired,
  createCompanyGameDef: PropTypes.func.isRequired,
  getGameDefById: PropTypes.func.isRequired,
};

export default connect(null, {
  getCompanyById: LS_AdminCompanyEntity.actions.getCompanyById,
  createCompanyGameDef: GameDefEntity.actions.createCompanyGameDef,
  getGameDefById: GameDefEntity.actions.getGameDefById,
})(AddGameDef);
