/* Libs */
import styled, { css } from 'styled-components';

import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';

import { Input } from 'components/ui/MaskInput/styles';
import { Circle as ArrowCircle } from 'components/blocks/Markers/CircleWithSign/styles';
import { Triangle as ForwardTriangle } from 'components/blocks/Markers/ForwardTriangle/styles';
import { Triangle as BackTriangle } from 'components/blocks/Markers/BackTriangle/styles';
import { Content, Wrapper as CellWrapper } from './Subcomponents/Cell/styles';


export const DateLabel = styled.div`
  background-color: ${COLORS_CONFIG.WHITE};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  ${({ showPlaceholder }) => showPlaceholder && css`
    color: ${COLORS_CONFIG.SMOOTH_BROWN$70};
  `}
  width: 100%;
  cursor: pointer;
  height: 48px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 16px;
  padding: 15px 24px;
  border: 4px solid ${COLORS_CONFIG.SKY_BLUE};
  ${({ withIcon }) => withIcon && css`
    display: flex;
    justify-content: space-between;
    padding-right: 7px;
  `}
`;

export const Calendar = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    z-index: 5;
    background-color: ${COLORS_CONFIG.WHITE};
    top: calc(100% + 4px);
    box-sizing: border-box;
    min-width: 300px;
    ${({ position }) => position === 'right' && css`
      right: 0;
    `}
    ${({ position }) => position === 'center' && css`
      left: 50%;
      transform: translateX(-45%);
    `}

    width: 100%;
`;


export const Title = styled.div`
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 1;
  padding: 2px 5px;
  background: ${COLORS_CONFIG.WHITE};
  font-size: 11px;
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    
    ${Title} {
      color: ${COLORS_CONFIG.SILVER_CUSTOM_7};
    }

  `}

  & .react-calendar {
    min-width: 300px;
    padding: 0px 10px 10px 10px;
    width: 80%;
    margin: auto;
    &__navigation {
      & button:disabled {
        background-color: ${COLORS_CONFIG.TRANSPARENT};
      }
      &__arrow {
        display: flex!important;
        align-items: center!important;
        justify-items: center!important;
        &:hover,
        &:focus {
          background-color: initial!important;
        }
        &:disabled {
          ${ArrowCircle} {
            border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
            cursor: default;
            ${ForwardTriangle} {
              border-color: transparent transparent transparent ${COLORS_CONFIG.SILVER_CUSTOM_1};
            }
            ${BackTriangle} {
              border-color: transparent ${COLORS_CONFIG.SILVER_CUSTOM_1} transparent transparent;
            }
          }
        }
      }
      &__label {
        order: -1;
        text-align: left;
        cursor: initial;
        padding-left: 5px;
        width: 225px;
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        font-size: 18px;
        line-height: 18px;
        background-color: initial!important;
        &:hover{
          background-color: initial!important;
        }
      }
    }
    &__tile {
      position: relative;
      cursor: initial;
      & ${Content}{

        &:hover {
          background-color: ${COLORS_CONFIG.SILVER};
        }
      }
      &:enabled:focus,
      &:enabled:hover {
        cursor: initial;
        background: none;
      }
      & > abbr {
        display: none;
      }
      width: 32px;
      height: 42px;

      &--now{
        &:not(:disabled){
        background: none;
          & ${Content} {
            background-color: ${COLORS_CONFIG.SILVER};
          }
        }
      }

      &--active {
        background: none;
        & ${Content} {
          background-color: ${COLORS_CONFIG.SKY_BLUE}!important;
          color: ${COLORS_CONFIG.WHITE};
          font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        }
      }
      &:disabled {
        background-color: ${COLORS_CONFIG.TRANSPARENT};
        & ${Content} {
          &:hover {
            background-color: ${COLORS_CONFIG.TRANSPARENT};
          }
          cursor: not-allowed;
          color: ${COLORS_CONFIG.SILVER};
        }
      }
      ${({ currentView }) => currentView === 'year' && css`
        ${CellWrapper}{
          position: absolute;
          width: 100%;
          height: 100%;
          & * {
            width: 100%;
            height: 100%;
          }
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        & ${Content} {
          border-radius: initial;
        }
      `}
    }
    &__month-view {
      &__weekdays__weekday {
        padding: 0;
        font-size: 14px;
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        & > abbr {
          text-decoration: none;
        }
      }
      &__days__day--neighboringMonth {
        & ${Content} {
          color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
          font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        }
      }
    }
  }
`;
