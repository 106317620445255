export default {
  // success notifications
  SUCCESS_ADD_MULTIPLE_GAME_SESSION: 'Game sessions added successfully.',
  SUCCESS_UPDATE_LICENSES_COUNT: 'Licenses count updated successfully.',
  COMPANY_ADMIN_SUCCESS_RESEND_INVITE: 'Invite resended successfully.',
  SUCCESS_UPDATE_GAME_DEF: 'Game Definition updated successfully.',
  SUCCESS_UPDATE_GAME_DEF_TEMPLATE: 'Game Definition template updated successfully.',
  SUCCESS_DELETE_GAME_DEF: 'Game Definition deleted successfully.',
  SUCCESS_EDIT_GAME_SESSION: 'Game session edited successfully.',
  SUCCESS_ACTIVATE_CAMPAIGN: 'Campaign activated successfully.',
  SUCCESS_UPDATE_POC: 'Point of contact updated successfully.',
  SUCCESS_ADD_GAME_SESSION: 'Game session added successfully.',
  SUCCESS_ADD_GAME_DEF: 'Game Definition added successfully.',
  SUCCESS_ADD_GAME_DEF_TEMPLATE: 'Game Definition template added successfully.',
  SUCCESS_UPDATE_STORYLINE: 'Storyline updated successfully.',
  SUCCESS_STORYLINE_ADDED: 'Storyline created successfully.',
  SUCCESS_UPDATE_CONTRACT: 'Contract updated successfully.',
  SUCCESS_UPDATE_CAMPAIGN: 'Campaign updated successfully.',
  SUCCESS_DELETE_CAMPAIGN: 'Campaign deleted successfully.',
  SUCCESS_CREATE_CAMPAIGN: 'Campaign created successfully.',
  SUCCESS_PASSWORD_RESET: 'Password was set up/changed successfully',
  SUCCESS_CLOSE_CAMPAIGN: 'Campaign closed successfully.',
  SUCCESS_UPDATE_COMPANY: 'Company updated successfully.',
  SUCCESS_DELETE_SESSION: 'Session deleted successfully.',
  SUCCESS_ACTIVATE_USER: 'User activated successfully.',
  SUCCESS_ADD_COMPANY: 'Company created successfully.',
  SUCCESS_RESEND_EMAIL: 'Email resent successfully.',
  SUCCESS_UPDATE_USER: 'User updated successfully.',
  SUCCESS_DELETE_USER: 'User deleted successfully.',
  SUCCESS_GAME_CODE_IS_CORRECT: 'Code is correct.',
  SUCCESS_GAME_CLOSED: 'Game closed successfully.',
  SUCCESS_COPY_INVITE_LINK: 'Invite link copied.',
  SUCCESS_COPY_MEETING_LINK: 'Meeting link copied.',
  SUCCESS_KIT_ADDED: 'Kit created successfully.',
  SUCCESS_KIT_EDITED: 'Kit edited successfully.',
  SUCCESS_ADD_USER: 'User added successfully.',
  SUCCESS_DEACTIVATED_USER: 'User deactivated successfully.',
  SUCCESS_COMPANY_ACTIVATION: 'Company was successfully activated',
  SUCCESS_COPIED: 'Copied',
  SUCCESS_SEND_MESSAGE_TO_PARTICIPANTS: 'The message was successfully sent to participant\'s emails',
  SUCCESS_SEND_MESSAGE_TO_HOST: 'The message was successfully sent to host\'s email',
  SUCCESS_ADDED_PARTICIPANT_EMAIL: 'New participant\'s email was added successfully',
  SUCCESS_CHANGED_PARTICIPANTS_COUNT: 'Count of participants was successfully changed',
  SUCCESS_CHANGED_CURRENT_TIME: 'Current game time was changed successfully',
  SUCCESS_CHANGED_COMPLETED_TIME: 'Completed game time was changed successfully',
  SUCCESS_DELETED_PARTICIPANT_EMAIL: 'Participant\'s email was successfully deleted from a session',
  SUCCESS_BOOKING: 'You were successfully booked to session',

  // info notifications
  INFO_NEW_PATICIPANT_EMAIL_FIELD_EMPTY: 'New participant email field should be empty',
  INFO_GAME_CODE_ALREADY_USED: 'Duplicate Code, Keep Searching',
  INFO_INCORRECT_GAME_CODE: 'Incorrect code',
  NO_ACTIVE_CAMPAIGNS: 'You should create or activate at least one campaign first',

  // error notifications
  ERROR_PARTICIPANTS_EMAILS_MORE_THAN_MAXIMUM: 'Participant emails can\'t be more than participants per session',
  ERROR_ADD_SESSION_TO_CLOSED_CAMPAIGN: 'You can\'t add session to closed campaign',
  ERROR_VIEW_SESSION_PARTICIPANTS: 'Can\'t view this session participants.',
  DONT_HAVE_PERMISSION: 'Sorry, but you have not permission to do that.',
  ERROR_GAME_DEF_DOESNT_EXISTS: 'Game Definition does not exists',
  ERROR_CAMPAIGN_EXISTS: 'Campaign with this name already exists.',
  ERROR_GAME_SESSION_DOESNT_EXISTS: 'Game session does not exists',
  ERROR_NO_CAMPAIGN: 'Sorry, but campaign was not provided.',
  ERROR_GAME_DEF_NO_STORYLINE: 'Create storyline first.',
  ERROR_NO_TOKEN: 'Sorry, but token was not provided.',
  PLEASE_FILL_ALL_FIELDS: 'Please fill all fields.',
  ERROR_KIT_NO_STORYLINE: 'Create storyline first.',
  COMPANY_DOESNT_EXISTS: 'Company does not exists.',
  ERROR_RESET_TOKEN: 'Token is not valid',
  ERROR_DEFAULT: 'Something going wrong.',
  WRONG_FILE_TYPE: 'Wrong file type.',
  ERROR_UQNIUE_NAME: 'Sorry, but this company is already exist',
  ERROR_DUPLICATE_TEAM_NAME: 'Duplicate team name.',
  ONLY_FINAL_CODE: 'You can\'t create questions with "Final code only" option.',
  DATE_FROM_CANT_BE_AFTER_DATE_TO: '"dateFrom" can\'t be after "dateTo"',
  ERROR_NOT_FOUND: 'Not found.',
  ERROR_PASSWORD_TOO_SHORT: 'This password is too short. It must contain at least 8 characters.',
  ERROR_PASSWORD_TOO_COMMON: 'This password is too common.',
  ERROR_PASSWORD_ENTIRELY_NUMERIC: 'This password is entirely numeric.',
  ERROR_PASSWORD_WITHOUT_CAPITAL_LETTER: 'This password hasn\'t capital letter.',
  ERROR_PASSWORD_DOES_NOT_MEET_REQUIREMENTS: 'Password does not meets the requirements',
  ERROR_COMPANY_CODE_ALREADY_IN_USE: 'Company code already in use.',
  KIT_ALREADY_SCHEDULED: 'The chosen kit is not available for your selected dates/times. Please choose other dates/times.',
  MULTIPLE_BOOKING_NOT_ALLOWED: 'You have already booked a session and booking another is not possible.',
  ERROR_PARTICIPANT_WITH_EMAIL_IS_SIGNED_UP: 'Participant with this email already signed up in the session',
  ERROR_NO_SESSION: 'Sorry, but provided wrong session ID',

  // CUSTOM NOTIFICATIONS
  ON_GAME_LEAVE: 'The game will not be saved. Are you sure you want to leave?',
  ON_GD_LEAVE: 'You have unsaved information and have chosen to navigate away from this page.  Are you sure?',

  // NOTIFICATIONS WITH PARAMS
  TOO_BIG_FILE: number => `Input file should be less than ${number}MB`,
};
