/* Libs */
import styled, { css } from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  ${({ haveQuestions }) => haveQuestions && css`
    & > * {
      margin: 12px;
      max-width: calc(25% - 24px);
      flex: 1 1 21%;
    }
  `}
`;
export const NotFound = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
