/* Libs */
import styled, { css, keyframes } from 'styled-components';

/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

/* Styles */
import { TextEllipsis } from 'assets/styles/mixins';

// Component styles
export const Title = styled.p`
  flex-shrink: 0;
  padding: 0 32px 10px;
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  line-height: 26px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  width: 380px;
  ${TextEllipsis};
`;

export const CancelIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// TODO: think about functionality of backdrop
export const Backdrop = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  /* transition: opacity 250ms ease; */
  background-color: ${COLORS_CONFIG.WHITE$72};
  opacity: 0;
  /* opacity: ${({ state }) => (state === 'exited' ? 0 : 1)
};
  display: ${({ state }) => (state === 'entering' || state === 'entered' ? 'flex' : 'none')
}; */
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 32px;
  z-index: 1000;
  min-width: 400px;
  height: 100%;
  background-color: ${COLORS_CONFIG.WHITE};
  background-repeat: no-repeat;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10_10};
  overflow: hidden;
  opacity: 1;
`;

const SidebarFadeIn = keyframes`
  from {
    right: -100%;
  }

  to {
    right: 0px;
  }
`;
const SidebarFadeOut = keyframes`
  from {
    right: 0px;
  }

  to {
    right: -100%;
  }
`;

export const OverallSidebarWrapper = styled.div`
  position: absolute;
  z-index: 3;
  height: calc( 100% - 1px );
  ${({ fullScreenHeight }) => fullScreenHeight && css`
    position: fixed;
    height: 100vh;
  `}
  right: -400px;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ state }) => {
    // if (state === 'entering' || state === 'entered') {
    if (state === 'entered') {
      return css`
        animation: ${SidebarFadeIn} 0.25s ease both;

        ${Backdrop} {
          animation: show 0.5s ease both;
        }
      `;
    }

    if (state === 'exiting' || state === 'exited') {
      return css`
        animation: ${SidebarFadeOut} 0.25s ease both;
        animation-delay: 0.1s;

        ${Backdrop} {
          animation: show 0.5s ease both;
        }
      `;
    }
  }}
`;
