/* Libs */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Actions */

import { ManageGameEntity } from '_entities';

/* Utils */

import { redirectWithError } from 'utils/custom';

/* Components */

import {
  ManageGameParticipantsSection,
  CompanyAdminStruct,
  ManageGameStruct,
  ContainerLoader,
  ManageGameQuestionsSection,
  ManageGameTimerSection,
  StoppedGameActionsSection,
} from 'components';

/* Constants */

import { URLS_CONFIG, GAME_CONFIG } from 'config';
import { NOTIFICATIONS } from '_constants';


const PAGE_TITLE = 'Manage Game';

function ManageGame({
  status,
  statusCode,
  loaded,
  loading,
  teamName,
  gameTime,
  questions,
  getGameSession,
  resetManageGame,
  editModeStatuses,
  stoppedModeStatuses,
  gameSessionGlobalStatus,
  match: { params: { id } },
}) {
  useEffect(() => {
    async function getCurrentGameSession() {
      try {
        await getGameSession(id);
      } catch (error) {
        redirectWithError(URLS_CONFIG.companyAdmin.campaigns, NOTIFICATIONS.ERROR_GAME_SESSION_DOESNT_EXISTS);
      }
    }
    getCurrentGameSession();
    return () => resetManageGame();
  }, [id]);

  const mode = editModeStatuses.includes(gameSessionGlobalStatus) // TODO: need improvements
    ? GAME_CONFIG.MANAGER.MODE.EDIT
    : stoppedModeStatuses.includes(gameSessionGlobalStatus)
      ? GAME_CONFIG.MANAGER.MODE.STOPPED
      : GAME_CONFIG.MANAGER.MODE.VIEW;

  const sessionType = useMemo(() => (
    Object.values(GAME_CONFIG.STEPS_NAME).indexOf(status)
      <= Object.values(GAME_CONFIG.STEPS_NAME).indexOf(GAME_CONFIG.STEPS_NAME.game_play)
      ? GAME_CONFIG.SESSION_TYPES.active
      : GAME_CONFIG.SESSION_TYPES.completed
  ), [status]);

  return (
    <CompanyAdminStruct>
      {
        loaded
          ? (
            <ManageGameStruct
              status={status}
              teamName={teamName}
              actions={(
                <StoppedGameActionsSection
                  mode={mode}
                  loading={loading}
                  sessionType={sessionType}
                />
              )}
              timer={<ManageGameTimerSection mode={mode} gameTime={gameTime} statusCode={statusCode} />}
            >
              <ManageGameParticipantsSection
                mode={mode}
              />
              <ManageGameQuestionsSection
                questions={questions}
              />
            </ManageGameStruct>
          )
          : <ContainerLoader />
      }

    </CompanyAdminStruct>
  );
}

/* Page Url */

ManageGame.path = URLS_CONFIG.companyAdmin.manageGame;

/* Page Title */

ManageGame.title = PAGE_TITLE;

/* ManageGame type of props */

ManageGame.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  questions: PropTypes.array.isRequired,
  resetManageGame: PropTypes.func.isRequired,
  getGameSession: PropTypes.func.isRequired,
};

/* ManageGame default props */

ManageGame.defaultProps = {};

export default connect(({ manageGame, staticData }) => ({
  loaded: manageGame.loaded,
  loading: manageGame.loading,
  questions: manageGame.questions,
  status: manageGame.status,
  statusCode: manageGame.statusCode,
  teamName: manageGame.teamName,
  gameTime: manageGame.gameTime,
  gameSessionGlobalStatus: manageGame.gameSessionGlobalStatus,
  editModeStatuses: [
    staticData.gameSessionStatuses.completed,
  ],
  stoppedModeStatuses: [
    staticData.gameSessionStatuses.stopped,
  ],
}), {
  getGameSession: ManageGameEntity.actions.getGameSession,
  resetManageGame: ManageGameEntity.actions.resetManageGame,
})(ManageGame);
