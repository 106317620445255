/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';
import { TextEllipsis } from 'assets/styles/mixins';
import { Wrapper as LogoWrapper } from 'components/blocks/ui/Logo/styles';
import { Wrapper as InputWrapper } from 'components/ui/Input/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 48px 40px;
  width: 500px;
  background: ${COLORS_CONFIG.WHITE};
  border-radius: 8px;

  ${LogoWrapper} {
    margin-bottom: 50px;
    width: 118px;
  }

  ${InputWrapper} {
    margin-bottom: 35px;
  }
`;

export const Text = styled.div`
  margin-bottom: 30px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

export const CompanyName = styled.div`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  ${TextEllipsis}
`;
