/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

/* Constants */
import { TABLES_CONFIG } from 'config';

/* Components */
import { ContainerLoader } from 'components';

/* Styles */
import * as Styled from './styles';
import 'assets/styles/react-table.scss';

// ----------------
export default function Table({
  withoutBackground,
  defaultPageSize,
  getTheadThProps,
  rowMinHeight,
  getTdProps,
  getProps,
  pagination,
  tableSize,
  emptyText,
  sortable,
  columns,
  loading,
  data,
  isDarkMode
}) {
  const hasPagination = data.length > 0 && Boolean(pagination);

  return (
    <Styled.Table
      withoutBackground={withoutBackground}
      rowMinHeight={rowMinHeight}
      tableSize={tableSize}
      hasPagination={hasPagination}
    >
      <ReactTable
        key={data}
        defaultPageSize={defaultPageSize || data.length}
        showPagination={false}
        noDataText={emptyText}
        filterable={false}
        className="react-table__wrapper"
        resizable={false}
        columns={columns}
        style={{ height: '100%' }}
        data={data}
        loading={loading}
        LoadingComponent={() => loading && <ContainerLoader isDarkMode={isDarkMode} friendlyMode />}
        getTheadThProps={getTheadThProps}
        getTdProps={getTdProps}
        getProps={getProps}
        sortable={sortable}
      />
      {hasPagination && pagination}
    </Styled.Table>
  );
}

// Type of props
Table.propTypes = {
  withoutBackground: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  rowMinHeight: PropTypes.oneOf(['small', 'middle']),
  tableSize: PropTypes.oneOf([
    TABLES_CONFIG.SIZE.S,
    TABLES_CONFIG.SIZE.M,
  ]),
  emptyText: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getTdProps: PropTypes.func,
  getProps: PropTypes.func,
  sortable: PropTypes.bool,
  loading: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  pagination: PropTypes.node,
};

// Default value for props
Table.defaultProps = {
  getTheadThProps: (state, rowInfo, { flexGrow = 1 }, instance) => ({
    className: `${TABLES_CONFIG.CLASSES.TABLE_CELL_FLEX_GROW_BASE}${flexGrow}`,
  }),
  getTdProps: (state, rowInfo, { flexGrow = 1 }, instance) => ({
    className: `${TABLES_CONFIG.CLASSES.TABLE_CELL_FLEX_GROW_BASE}${flexGrow}`,
  }),
  getProps: () => ({}),
  withoutBackground: false,
  rowMinHeight: 'middle',
  defaultPageSize: 10,
  tableSize: TABLES_CONFIG.SIZE.M,
  emptyText: 'Nothing found.',
  sortable: false,
  pagination: false,
  loading: false,
  isDarkMode: false,
};
