/* Libs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Components */
import {
  AdminAuthStruct,
  AdminSigninForm,
  Logo,
} from 'components';

/* Constants */
import { URLS_CONFIG, LOGO_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

class SigninPage extends Component {
  static title = 'Sign in';

  static path = URLS_CONFIG.auth.signin;

  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {
    return (
      <AdminAuthStruct>
        <Styled.Wrapper>
          <Logo type={LOGO_CONFIG.VERTICAL_BLUE_WHITE} />
          <AdminSigninForm />
        </Styled.Wrapper>
      </AdminAuthStruct>
    );
  }
}

export default SigninPage;
