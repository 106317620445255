/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  AVAILABLE_COLORS,
  COLORS_CONFIG,
} from 'config';

/* Styles */
import Triangle from './styles';

function BackTriangle({ color }) {
  return <Triangle color={color} />;
}

BackTriangle.propTypes = {
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
};

BackTriangle.defaultProps = {
  color: COLORS_CONFIG.SMOOTH_BROWN,
};


export default BackTriangle;
