/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const InputWrapper = styled.div`
  display: block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  display: none;
`;

export const Shape = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS_CONFIG.WHITE};
  border: 1px solid ${COLORS_CONFIG.SKY_BLUE};
  width: 20px;
  height: 20px;
  & > * {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
  }
  border-radius: 50%;
  & > * {
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
`;

export const HtmlLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 14px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  padding-left: 10px;
  user-select: none;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOlD};
`;

export const Wrapper = styled.div`
  width: 100%;
  & ${Label},
  & ${InputWrapper} {
    cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};
  }
`;
