import { isEmpty } from 'utils/validation';

/**
 * Predicts if we are in infinite loop redirect
 * if redirect to next route
 *
 * @param {Array} stack stack of current routes history
 * @param {String} next next route to redirect
 */
export default (stack = [], next = '') => {
  if (!Array.isArray(stack)) return false;
  if (stack.length < 4) return false;

  const neededSegment = stack.slice(0, 4);

  if (neededSegment.some(segmentPart => isEmpty(segmentPart))) {
    return false;
  }

  return [
    neededSegment[1],
    neededSegment[3],
  ].every(segmentPart => segmentPart === next)
    && neededSegment[0] === neededSegment[2];
};
