/* Libs */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Components */

import {
  Modal,
  CreateCampaignForm,
  // CampaignCreatedSuccess
} from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function CreateCampaignModal({
  onClose,
  open,
  ...restProps
}) {
  if (!open) return null;

  const [link, setLink] = useState(null);

  return (
    <Modal
      onClose={onClose}
      title="Create a campaign"
      open={open}
    >
      <Styled.CreateCampaignModal>
        <CreateCampaignForm
          {...restProps}
          onClose={onClose}
          setLink={setLink}
        />
      </Styled.CreateCampaignModal>
    </Modal>
  );
}

// Type of props
CreateCampaignModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
CreateCampaignModal.defaultProps = {};
