const DAY = 'day';
const WEEK = 'week';
const MONTH = 'month';
const YEAR = 'year';

export default {
  DAY,
  WEEK,
  MONTH,
  YEAR,
};
