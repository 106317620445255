/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function ToggleBar({
  name, onChange, active, options, sameWidth,
}) {
  if (!Array.isArray(options)) return null;

  const clickHandler = (option) => {
    if (active === option) return;

    onChange({ target: { name, value: option } });
  };

  return (
    <Styled.Wrapper>
      <Styled.Inline sameWidth={sameWidth} count={options.length}>
        {options.map(option => (
          <Styled.Item
            key={option}
            active={active === option}
            onClick={() => clickHandler(option)}
          >
            {option}
          </Styled.Item>
        ))}
      </Styled.Inline>
    </Styled.Wrapper>
  );
}

/* ToggleBar type of props */

ToggleBar.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  sameWidth: PropTypes.bool,
};

ToggleBar.defaultProps = {
  sameWidth: false,
};

export default ToggleBar;
