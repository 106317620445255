/* Libs */
import React, { useState } from 'react';

/* Components */

import {
  Avatar,
  CompanyAdminHeaderInfoMenu,
  GlobalSettingsModal,
} from 'components';

/* Constants */

import {
  AVATAR_CONFIG,
} from 'config';

/* Styles */

import * as Styled from './styles';

function CompanyAdminHeaderInfo() {
  const [modalIsOpen, toggleModal] = useState(false);
  const closeModal = () => toggleModal(false);
  const openModal = () => toggleModal(true);

  return (
    <Styled.Wrapper>
      <Avatar size={AVATAR_CONFIG.SIZE.S} />
      <CompanyAdminHeaderInfoMenu openModal={openModal} />
      <GlobalSettingsModal
        open={modalIsOpen}
        onClose={closeModal}
      />
    </Styled.Wrapper>
  );
}

export default CompanyAdminHeaderInfo;
