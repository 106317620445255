/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { CircleFilled, CircleWithSign } from 'components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function AdminSidebarItem({
  url,
  label,
  isActive,
}) {
  return (
    <Styled.Link props={{ isActive }} to={url}>
      <Styled.Wrapper>
        <CircleWithSign sign={isActive && (
          <CircleFilled
            autoSize
            color={COLORS_CONFIG.SKY_BLUE}
            active
          />
        )}
        />
        <Styled.Label>
          {label}
        </Styled.Label>
      </Styled.Wrapper>
    </Styled.Link>
  );
}

/* AdminSidebarItem type of props */

AdminSidebarItem.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default AdminSidebarItem;
