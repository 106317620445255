/* Libs */
import React, { PureComponent } from 'react';
import Transition from 'react-transition-group/Transition';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/* Styles */
import * as Styled from './styles';

// ----------------
export default class TableActionSidebar extends PureComponent {
  // Type of props
  static propTypes = {
    onlyContentCloseClick: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    style: PropTypes.object,
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    fullScreenHeight: PropTypes.bool,
  };

  // Default value for props
  static defaultProps = {
    showTitle: true,
    fullScreenHeight: false,
  };

  // Data
  element = null;

  // -------- Methods --------

  // Life cycle
  componentDidMount() {
    document.addEventListener('keydown', this.onEscape);
    this.element = document.getElementById(this.props.id);
    this.forceUpdate();
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.element.classList.add('TableActionSidebar-open');
    } else {
      this.element.classList.remove('TableActionSidebar-open');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape);
    this.element.classList.remove('TableActionSidebar-open');
  }

  onEscape = ({ keyCode }) => {
    if (this.props.onClose && this.props.open && keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const {
      onlyContentCloseClick,
      children,
      onClose,
      title,
      style,
      open,
      showTitle,
      fullScreenHeight,
    } = this.props;

    if (!this.element) return null;

    return ReactDOM.createPortal(
      <Transition in={open} timeout={300}>
        {state => state !== 'exited' && (
          <Styled.OverallSidebarWrapper state={state} fullScreenHeight={fullScreenHeight}>
            <Styled.Backdrop />
            <Styled.Sidebar style={style}>
              {showTitle && title && <Styled.Title>{title}</Styled.Title>}
              {!onlyContentCloseClick && (
                <Styled.CancelIconWrapper onClick={onClose}>
                    X
                </Styled.CancelIconWrapper>
              )}
              {children}
            </Styled.Sidebar>
          </Styled.OverallSidebarWrapper>
        )
        }
      </Transition>,
      this.element,
    );
  }
}
