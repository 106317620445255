/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function Cell({
  day,
  hasEvent,
}) {
  return (
    <Styled.Wrapper>
      <Styled.Cell hasEvent={hasEvent}>
        <Styled.Content>
          {day}
        </Styled.Content>
      </Styled.Cell>
    </Styled.Wrapper>
  );
}

/* Cell type of props */

Cell.propTypes = {
  day: PropTypes.string.isRequired,
  hasEvent: PropTypes.bool.isRequired,
};

export default Cell;
