import { PARTICIPANT_SIGNUP_TYPE } from '_constants';
import * as TYPES from './Participant.types';

const initialState = {
  token: '',
  loaded: false,
  loading: false,
  inviteType: null,
  gameSession: null,
  linkForSignup: '',
  participant_email: '',
  campaignGameSessions: null,
};

function getInviteType({
  link_for_sign_up,
  result_type,
  campaign_game_sessions,
  game_session,
  participant_email,
}) {
  if (link_for_sign_up && result_type === 'Canceled') {
    return PARTICIPANT_SIGNUP_TYPE.CANCELED;
  }
  /* CAMPAIGN TYPES */
  if (campaign_game_sessions && game_session && participant_email) {
    if (
      game_session.signedup_participants.find(
        signuped_paticipant => signuped_paticipant.email === participant_email,
      )
    ) {
      return PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_EDIT;
    }
  }
  if (campaign_game_sessions && participant_email) {
    return PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_DIRECT;
  }
  if (campaign_game_sessions) {
    return PARTICIPANT_SIGNUP_TYPE.CAMPAIGN_PUBLIC;
  }

  /* SESSION TYPES */
  if (game_session && participant_email) {
    if (
      game_session.signedup_participants.find(
        signuped_paticipant => signuped_paticipant === participant_email,
      )
    ) {
      return PARTICIPANT_SIGNUP_TYPE.SESSION_EDIT;
    }
  }
  if (game_session && participant_email) {
    return PARTICIPANT_SIGNUP_TYPE.SESSION_DIRECT;
  }
  if (game_session) {
    return PARTICIPANT_SIGNUP_TYPE.SESSION_PUBLIC;
  }

  if (campaign_game_sessions) {
    return PARTICIPANT_SIGNUP_TYPE.CAMPAIGN;
  }
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.CHECK_TOKEN:
      return {
        ...initialState,
        loading: true,
      };
    case TYPES.SIGNUP:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        inviteType: getInviteType(action.result),
        gameSession: action.result.game_session,
        campaignGameSessions: action.result.campaign_game_sessions,
        participant_email: action.result.participant_email || '',
        linkForSignup: action.result.link_for_sign_up,
        token: action.payload.token,
        campaignName: action.result.campaign_name,
        allowMultipleSignup: action.result.allow_multiple_signup,
        loading: false,
        loaded: true,
      };
    case TYPES.FINISH_SIGNUP:
      return {
        ...initialState,
      };
    case TYPES.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.CHECK_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case TYPES.SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
