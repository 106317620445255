/* Libs */
import React from 'react';
import PropTypes from 'prop-types';


/* Styles */

import * as Styled from './styles';

function SmallTitle({
  children,
}) {
  return (
    <Styled.SmallTitle>
      {children}
    </Styled.SmallTitle>
  );
}

/* SmallTitle type of props */

SmallTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default SmallTitle;
