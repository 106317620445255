/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */

import {
  CalendarExpanded,
  CalendarHeader,
} from 'components';

/* Constants */

import {
  DATE_SETTINGS,
} from 'config';

/* Styles */

import * as Styled from './styles';

function CalendarBig({
  toggleBar,
  calendarData: {
    value,
    name,
    onChange,
  },
  calendarData: data,
  calendarType,
}) {
  return (
    <Styled.Wrapper>
      <CalendarHeader
        date={value}
        onBackClick={() => onChange({ target: { name, value: new Date(moment(value).subtract(1, 'M')) } })}
        onForwardClick={() => onChange({ target: { name, value: new Date(moment(value).add(1, 'M')) } })}
        format={DATE_SETTINGS.FORMAT.MONTH_YEAR}
        calendarType={calendarType}
      />
      {toggleBar}
      <CalendarExpanded
        {...data}
      />
    </Styled.Wrapper>
  );
}

/* CalendarBig type of props */

CalendarBig.propTypes = {
  toggleBar: PropTypes.node.isRequired,
  calendarData: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(Date).isRequired,
    events: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

/* CalendarBig default props */

CalendarBig.defaultProps = {};

export default CalendarBig;
