/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';


/* Components */

import {
  UserName,
  TopTriangle,
  DownTriangle,
  CompanyAdminHeaderInfoMenuList,
  CompanyAdminHeaderInfoMenuListItem,
} from 'components';

/* utils */

import { hasPermission, redirect } from 'utils/custom';
import { tokenService, impersonateService } from 'utils/services';

/* Constants */

import { URLS_CONFIG } from 'config';
import { PERMISSION_NAMES } from '_constants';

/* Styles */

import * as Styled from './styles';

function CompanyAdminHeaderInfoMenu({
  openModal,
}) {
  const [isOpened, setOpenedState] = useState(false);
  const openMenu = () => setOpenedState(true);
  const closeMenu = () => setOpenedState(false);

  function goToCompanies() {
    impersonateService.cancelImpersonate();
    redirect(URLS_CONFIG.admin.companies);
  }

  return (
    <Styled.Wrapper
      menuIsOpened={isOpened}
      onClick={openMenu}
    >
      <Styled.MenuTitle>
        <UserName />
        {isOpened ? <TopTriangle /> : <DownTriangle />}
      </Styled.MenuTitle>
      {
        isOpened && (
          <CompanyAdminHeaderInfoMenuList onClose={closeMenu}>
            {
              hasPermission(PERMISSION_NAMES.CHANGE_GLOBAL_SETTINGS) && (
                <CompanyAdminHeaderInfoMenuListItem
                  key="Global settings"
                  label="Global settings"
                  onClick={openModal}
                />
              )
            }
            {
              hasPermission(PERMISSION_NAMES.BACK_TO_COMPANIES_IN_HEADER) && (
                <CompanyAdminHeaderInfoMenuListItem
                  key="Back to companies"
                  onClick={goToCompanies}
                  label="Back to companies"
                />
              )
            }
            {
              hasPermission(PERMISSION_NAMES.LOGOUT_IN_HEADER) && (
                <CompanyAdminHeaderInfoMenuListItem
                  key="Logout"
                  onClick={tokenService.removeToken}
                  label="Logout"
                />
              )
            }
          </CompanyAdminHeaderInfoMenuList>
        )
      }
    </Styled.Wrapper>
  );
}

CompanyAdminHeaderInfoMenu.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default CompanyAdminHeaderInfoMenu;
