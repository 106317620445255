/* Libs */
import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  merge,
  debounce,
  isInteger,
  values,
  isEmpty,
  isNaN,
  get,
} from 'lodash';

/* Utils */

import { notification, fastSessionService } from 'utils/services';
import {
  showErrorsFromBackend,
  getError,
  hasPermission,
  transformDataToOptions,
  redirect,
  getLaunchGameLink,
  replaceQueryParams,
  getStaticData,
  getTimezoneName,
  getUTCTime,
  getLocalDateFromUTCString,
  getTimeInUTCFormat,
  getTimezoneOffset,

} from 'utils/custom';

/* Components */

import {
  CalendarMainSection,
  CompanyAdminStruct,
  EditSessionSidebar,
  AddSessionSidebar,
  LaunchSessionSidebar,
  CalendarSidebar,
  ToggleBar,
  AddCircle,
  Select,
  Pagination,
  SessionInfoModal,
  CompanyLicenseExpiredBlock,
  Loader,
} from 'components';

/* Actions */

import { AdminCompanyEntity, AuthEntity } from '_entities';

/* Constants */

import {
  URLS_CONFIG,
  TABLE_TYPES,
  DATE_SETTINGS,
  GAME_SESSIONS_TABLE,
  NOTIFICATIONS_CONFIG,
} from 'config';
import {
  NOTIFICATIONS,
  SESSION_LAUNCH_TYPES,
  PERMISSION_NAMES,
  CALENDAR_STYLES,
} from '_constants';


const SESSION_DURATION = 60;
const SESSION_STEP = 10;

const PAGE_TITLE = 'Calendar';

const DEFAULT_DATA = {
  campaign: null,
  date: new Date(),
  timeRange: TABLE_TYPES.WEEK,
  filterBy: null,
  calendarStyle: CALENDAR_STYLES.BY_TIME,
};

const MODAL_NAMES = {
  SESSION_INFO: 'Session info',
};

const SIDEBAR_NAMES = {
  ADD: 'Add',
  EDIT: 'Edit',
  LAUNCH: 'Launch',
};

function Calendar({
  location: { search },
  getCampaigns,
  getCampaign,
  getGameSessions,
  addDayWithGameSession,
  getDaysWithGameSession,
  getKits,
  getKit,
  removeKit,
  getGameDefs,
  getGameDef,
  removeGameDef,
  getLocations,
  addNewLocation,
  deleteGameSession,
  editGameSession,
  addGameSession,
  campaigns,
  gameSessions,
  loading,
  company,
  kits,
  gameDefs,
  locations,
  gameSessionStatuses,
  daysWithGameSession,
  userCompany,
  getCompany,
  role,
  roles,
  getInitialUserData,
  companyTimezone,
}) {
  const isGameSessionsMount = useRef(false);
  const [data, changeData] = useState(DEFAULT_DATA);
  const [gameSessionsPage, setGameSessionsPage] = useState(1);
  const [totalGameSessions, setTotalGameSessions] = useState(0);
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [openedSessionForEdit, setOpenedSessionForEdit] = useState({});
  const [isLoading, setLoading] = useState(false);

  const isLSAdmin = role === getStaticData('roles').admin;
  const isContractIgnored = [roles.host, roles.company_host].includes(role);

  const loadGameSessions = async ({
    page = gameSessionsPage,
    changedData: {
      campaign,
      date,
      timeRange,
      filterBy: location,
    } = data,
    changedData: initialData,
    storedCampaigns = campaigns,
    calendarStyle = data.calendarStyle,
  } = {}) => {
    try {
      if (!campaign) return;

      const fullCampaign = storedCampaigns.find(({ id }) => id === campaign);

      if (!fullCampaign) return;

      const fromDate = timeRange === TABLE_TYPES.DAY ? moment(date) : moment(date).startOf('week');
      const toDate = timeRange === TABLE_TYPES.DAY ? moment(date).endOf('day') : moment(date).endOf('week');

      const requestData = {
        campaign: fullCampaign.founder_id,
        kit: location,
        page,
        fromDate: calendarStyle === CALENDAR_STYLES.ALL
          ? getUTCTime({ date: fromDate, timezone: companyTimezone })
            .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS)
          : getUTCTime({ date: fromDate, timezone: companyTimezone })
            .subtract(SESSION_DURATION - SESSION_STEP, 'minutes')
            .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS),
        toDate: getUTCTime({ date: toDate, timezone: companyTimezone })
          .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS),
      };

      const result = await getGameSessions(merge(
        requestData,
        timeRange === TABLE_TYPES.DAY
          ? {
            offset: (page - 1) * GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE,
            limit: GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE,
          }
          : {},
      ));

      const count = (
        (timeRange === TABLE_TYPES.WEEK || calendarStyle === CALENDAR_STYLES.BY_TIME)
        && (result.count > GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE)
      )
        ? await getGameSessions({
          ...requestData,
          limit: result.count,
          offset: 0,
        }).then(({ count: weekResultCount }) => weekResultCount)
        : result.count;

      if (count && !result.results.length) {
        return handleGameSessionsPageChange({
          selected: 0,
          changedData: initialData,
          storedCampaigns,
        });
      }

      replaceQueryParams({
        campaign: String(campaign),
        interval: timeRange,
        date: moment(date)
          .format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR_HYPHEN),
        filter: String(location),
        page,
      });
      setTotalGameSessions(count);
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const manageSidebar = name => () => setActiveSidebar(name);
  const manageModal = name => () => setActiveModal(name);
  const closeModal = () => {
    setActiveModal(null);
    setOpenedSessionForEdit({});
  };


  const handleCalendarStyleChange = ({ target: { name, value } }) => {
    loadGameSessions({
      page: value === CALENDAR_STYLES.ALL
        ? gameSessionsPage
        : 1,
      calendarStyle: value,
    });

    changeData({
      ...data,
      [name]: value,
    });
  };


  const handleGameSessionsPageChange = ({ selected, ...restData }) => {
    setGameSessionsPage(selected + 1);
    loadGameSessions({
      page: selected + 1,
      ...restData,
    });
  };


  const handleCalendarChange = ({ target: { value: date }, changedData = data, ...restData }) => {
    changeData({
      ...changedData,
      date,
    });
    handleGameSessionsPageChange({
      ...restData,
      changedData: {
        ...changedData,
        date,
      },
      selected: 0,
    });
  };

  const handleFilterChange = ({ target: { value: filterBy }, changedData = data, ...restData }) => {
    changeData({
      ...changedData,
      filterBy,
    });
    handleGameSessionsPageChange({
      ...restData,
      changedData: {
        ...changedData,
        filterBy,
      },
      selected: 0,
    });
  };


  const handleIntervalRangeChange = ({ target: { value: timeRange }, changedData = data, ...restData }) => {
    changeData({
      ...changedData,
      timeRange,
    });
    handleGameSessionsPageChange({
      ...restData,
      changedData: {
        ...changedData,
        timeRange,
      },
      selected: 0,
    });
  };


  const handleCampaignChange = async ({ target: { value: campaign }, changedData = data, ...restData }) => {
    changeData({
      ...data,
      campaign,
      filterby: DEFAULT_DATA.filterBy,
    });

    const fullCampaign = campaigns.find(({ id }) => id === campaign);

    try {
      const storyline = fullCampaign
        ? fullCampaign.storyline.founder_id
        : await getCampaign({ id: campaign })
          .then(({ storyline: { founder_id } }) => founder_id);
      getKits({
        company,
        storyline,
      });
      getGameDefs({
        company,
        storyline,
      });
      handleFilterChange({
        ...restData,
        changedData: {
          ...changedData,
          campaign,
        },
        selected: 0,
        target: { value: DEFAULT_DATA.filterBy },
      });
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const handleDayChange = (e) => {
    if (data.timeRange === TABLE_TYPES.WEEK) {
      changeData({
        ...data,
        timeRange: TABLE_TYPES.DAY,
        [e.target.name]: e.target.value,
      });
      loadGameSessions({ page: 1, changedData: { ...data, timeRange: TABLE_TYPES.DAY, date: e.target.value } });
    } else {
      handleCalendarChange(e);
    }
  };

  const tableData = useMemo(() => ((
    data.timeRange === TABLE_TYPES.DAY
    && data.calendarStyle === CALENDAR_STYLES.ALL
  )
    ? gameSessions
    : gameSessions
      .reduce((arr, session) => {
        const start = getTimeInUTCFormat(session.utc_start_time, companyTimezone);
        const end = getTimeInUTCFormat(session.utc_end_time, companyTimezone);

        /**
       * Split session on 2 parts because react-week-calendar render only
       * last part of session which starts on one day and ends on another.
       * Moment-s methods endOf and startOf don't work and module need
       * ONLY moment objects in format as come from backend.
       */


        const differentDatesSession = end.hours() === 0 && end.minutes() === 0
          ? {
            ...session,
            start,
            end: moment(`${start.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)}T23:59:59Z`).utc(),
          }
          : [
            {
              ...session,
              start,
              end: moment(`${start.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)}T23:59:59Z`).utc(),
            },
            {
              ...session,
              start: moment(`${end.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)}T00:00:00Z`).utc(),
              end,
            },
          ];

        return arr.concat(
          start.date() === end.date()
            ? {
              ...session,
              start,
              end,
            }
            : differentDatesSession,
        );
      }, [])),
  [data.calendarStyle, data.timeRange, gameSessions, companyTimezone]);


  const openAddSessionSidebar = async () => {
    try {
      !('is_secret_mode' in userCompany) && await getCompany(company);
      manageSidebar(SIDEBAR_NAMES.ADD)();
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const openLaunchSessionSidebar = () => {
    manageSidebar(SIDEBAR_NAMES.LAUNCH)();
  };

  const closeHandler = () => {
    openedSessionForEdit.invalidGameDef && removeGameDef(openedSessionForEdit.game_def.id);
    openedSessionForEdit.invalidKit && removeKit(openedSessionForEdit.kit.id);

    manageSidebar(null)();
    setOpenedSessionForEdit({});
  };

  const openEditSessionSidebar = async (id) => {
    const session = gameSessions.find(gameSession => gameSession.id === id);
    const selectedCampaign = campaigns.find(({ id }) => id === data.campaign);

    if (!session || !selectedCampaign) {
      return notification.error(getError());
    }

    try {
      const isGameDefAbsent = !gameDefs.find(({ id }) => id === session.game_def.id);
      const isKitAbsent = !kits.find(({ founder_id }) => founder_id === session.kit.founder_id);

      // TODO: use promise all
      setOpenedSessionForEdit({
        ...session,
        invalidGameDef: isGameDefAbsent
          && await getGameDef({ id: session.game_def.id })
            .then(({ deleted, storyline }) => Boolean(deleted) || (storyline !== selectedCampaign.storyline.id)),
        invalidKit: isKitAbsent
          && await getKit({ id: session.kit.id })
            .then(({ deleted, storyline }) => Boolean(deleted) || (storyline.id !== selectedCampaign.storyline.id)),
      });
      manageSidebar(SIDEBAR_NAMES.EDIT)();
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const openSessionInfoModal = (id) => {
    const session = gameSessions.find(gameSession => gameSession.id === id);

    if (!session) {
      return notification.error(getError());
    }

    setOpenedSessionForEdit(session);
    manageModal(MODAL_NAMES.SESSION_INFO)();
  };

  const loadDaysWithGameSession = async (campaign = data.campaign, date = data.date) => {
    const fullCampaign = campaigns.find(({ id }) => id === campaign);

    if (!fullCampaign) return;

    const startDay = moment(date)
      .startOf('month')
      .startOf('week');
    const endDay = moment(date)
      .endOf('month')
      .endOf('week');
    // const limit = endDay.diff(startDay, 'days') + 1;

    try {
      await getDaysWithGameSession({
        campaign: fullCampaign.founder_id,
        fromDate: startDay.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        toDate: endDay.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        // limit,
      });
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const onEditSessionDelete = async (id, status) => {
    try {
      await deleteGameSession({
        sessionId: id || openedSessionForEdit.id,
        campaignId: data.campaign,
        status: status || gameSessionStatuses.scheduled,
      });

      notification.success(NOTIFICATIONS.SUCCESS_DELETE_SESSION);

      if (
        (gameSessions.length === 1 || data.timeRange === TABLE_TYPES.WEEK)
        && gameSessionsPage === Math.ceil(totalGameSessions / GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE)
      ) {
        if (gameSessionsPage === 1) {
          loadDaysWithGameSession();
          loadGameSessions();
        } else {
          setGameSessionsPage(gameSessionsPage - 1);
          loadGameSessions({ page: gameSessionsPage - 1 });
        }
      } else {
        loadDaysWithGameSession();
        loadGameSessions();
      }

      closeHandler();
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const onSessionDelete = async (id, status) => {
    notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
      {
        onSuccess: () => onEditSessionDelete(id, status),
        onCancel: notification.removeAllNotifications,
        message: 'Are you sure you want to delete this session?',
      },
    ));
  };

  const saveHandler = async (requestData) => {
    try {
      setLoading(true);

      const sendingData = merge(
        requestData,
        requestData.location
          ? {
            location: typeof requestData.location === 'number'
              ? requestData.location
              : await addNewLocation(requestData.location)
                .then(({ id }) => id),
          }
          : {},
      );

      if (activeSidebar === SIDEBAR_NAMES.EDIT) {
        await editGameSession(sendingData);
        notification.success(NOTIFICATIONS.SUCCESS_EDIT_GAME_SESSION);
        if (requestData.start_time) {
          if (!moment(openedSessionForEdit.start_time)
            .utc()
            .isSame(requestData.start_time, 'day')) {
            (
              (gameSessions.length === 1)
              && (gameSessionsPage === Math.ceil(totalGameSessions / GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE))
              && (gameSessionsPage !== 1)
            )
              ? setGameSessionsPage(gameSessionsPage - 1)
              : loadGameSessions();
          } else {
            loadGameSessions();
          }
        }
      } else {
        await addGameSession({
          ...sendingData,
          is_anonymous: userCompany.is_secret_mode,
        });
        notification.success(NOTIFICATIONS.SUCCESS_ADD_GAME_SESSION);

        const UTCDate = getUTCTime({ date: requestData.start_time, timezone: requestData.timezone });
        const offset = getTimezoneOffset(getTimezoneName(companyTimezone), UTCDate);
        const localStartTime = UTCDate.add(offset, 'minutes');

        handleFilterChange({
          target: {
            name: 'filterBy',
            value: null,
          },
          changedData: {
            ...data,
            date: (requestData.start_time || requestData.timezone) && !moment(localStartTime).isSame(data.date, 'day')
              ? new Date(localStartTime)
              : data.date,
          },
        });
      }

      loadDaysWithGameSession(data.campaign);

      setLoading(false);
      closeHandler();
    } catch (error) {
      setLoading(false);
      showErrorsFromBackend(error);
    }
  };

  const loadNewDataForLaunchSessionSidebar = async (campaign) => {
    const fullCampaign = campaigns.find(({ id }) => id === campaign);
    const newCampaign = fullCampaign
      || await getCampaign({
        id: campaign,
        withoutStoreChanging: true,
      });

    return Promise.all([
      getKits({
        company,
        storyline: newCampaign.storyline.founder_id,
        withoutStoreChanging: true,
      }),
      getGameDefs({
        company,
        storyline: newCampaign.storyline.founder_id,
        withoutStoreChanging: true,
      }),
    ])
      .then(
        ([
          { results: kits },
          { results: gameDefs },
        ]) => ({
          kits,
          gameDefs,
          campaigns: fullCampaign
            ? campaigns
            : campaigns.concat(newCampaign),
        }),
      );
  };

  const launchSession = async (requestData) => {
    const selectedGameDef = gameDefs.find(({ id }) => id === requestData.game_def);

    fastSessionService.saveFastSessionData(merge(
      {
        ...requestData,
        is_anonymous: userCompany.is_secret_mode,
      },
      selectedGameDef ? { is_dark_mode: selectedGameDef.is_dark_mode } : {},
    ));
    // const { id } = await addGameSession({
    //   ...requestData,
    //   location: typeof requestData.location === 'number'
    //     ? requestData.location
    //     : await addNewLocation(requestData.location).then(({ id }) => id),
    //   start_time: moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY_HOURS_MINUTES),
    // });
    // redirect(getLaunchGameLink(id));
    redirect(getLaunchGameLink(SESSION_LAUNCH_TYPES.FAST));
  };

  const fetchCampaigns = (campaign, callback) => {
    getCampaigns({
      campaign,
      isActive: true,
      withoutStoreChanging: true,
      perPage: 10,
      company,
    })
      .then(({ results: campaigns }) => {
        callback(transformDataToOptions({
          data: campaigns,
          value: 'id',
          label: 'name',
        }));
      });
  };
  const loadCampaigns = debounce(fetchCampaigns, 300);

  const fetchGameDefs = ({
    gameDef, callback, campaigns: storedCampaigns = campaigns, campaign = data.campaign,
  }) => {
    const storyline = (storedCampaigns.find(({ id }) => campaign === id) || { storyline: {} }).storyline.founder_id;

    getGameDefs({
      company,
      storyline,
      gameDef,
      withoutStoreChanging: true,
    })
      .then(({ results: gameDefs }) => {
        callback(transformDataToOptions({
          data: gameDefs,
          value: 'id',
          label: 'name',
        }));
      });
  };
  const loadGameDefs = debounce(fetchGameDefs, 300);
  const fetchLocations = (location, callback) => {
    getLocations({
      location,
      withoutStoreChanging: true,
    })
      .then(({ results: locations }) => {
        callback(transformDataToOptions({
          data: locations,
          value: 'id',
          label: 'location',
        }));
      });
  };
  const loadLocations = debounce(fetchLocations, 300);


  const isCompanyContractLoaded = isContractIgnored
    || (!isEmpty(userCompany) && !isEmpty(userCompany.contract));
  const isValidCompany = isContractIgnored
    || (isCompanyContractLoaded && moment(userCompany.contract.end_date).isSameOrAfter());

  useEffect(() => {
    async function fetchData() {
      try {
        const params = new URLSearchParams(search);
        const queryCampaign = params.get('campaign');
        const queryInterval = params.get('interval');
        const queryDate = params.get('date');
        const queryFilter = params.get('filter');
        const searchParamsPage = +params.get('page');
        const page = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;

        const campaignNumber = queryCampaign && Number(queryCampaign);
        const filterNumber = queryFilter && Number(queryFilter);

        const { results: campaigns } = await getCampaigns({
          company,
          isActive: true,
          perPage: 10,
        });

        getLocations();

        if (!campaigns.length) {
          notification.info(NOTIFICATIONS.NO_ACTIVE_CAMPAIGNS);
          redirect(
            isContractIgnored
              ? URLS_CONFIG.host.campaigns
              : URLS_CONFIG.companyAdmin.campaigns,
          );
          return;
        }

        const searchedCampaign = isInteger(campaignNumber)
          && (
            campaigns.find(({ id }) => id === campaignNumber)
            || await getCampaign({ id: campaignNumber })
              .then(res => res)
              .catch(err => err)
          );

        const isValidCampaign = searchedCampaign && searchedCampaign.id;
        const isValidInterval = isValidCampaign && queryInterval && values(TABLE_TYPES)
          .includes(queryInterval);
        const isValidDate = isValidCampaign && queryDate && (queryDate === moment(queryDate)
          .format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR_HYPHEN));

        const campaign = isValidCampaign ? searchedCampaign : campaigns[0];
        const interval = isValidInterval ? queryInterval : DEFAULT_DATA.timeRange;
        const date = isValidDate ? new Date(queryDate) : DEFAULT_DATA.date;

        const { results: kits } = await getKits({
          company,
          storyline: campaign.storyline.founder_id,
        });
        const searchedKit = isInteger(filterNumber)
          && (
            kits.find(({ id }) => id === filterNumber)
            || await getKit({ id: filterNumber })
              .then(res => res)
              .catch(err => err)
          );
        const isValidFilter = searchedKit && searchedKit.id && (searchedKit.storyline.id === campaign.storyline.id);
        const filter = isValidFilter ? filterNumber : DEFAULT_DATA.filterBy;

        if (searchedKit && searchedKit.id && !isValidFilter) removeKit(searchedKit.id);

        if (!isValidCampaign || !isValidInterval || !isValidDate || !isValidFilter || !page) {
          replaceQueryParams({
            campaign: campaign.id,
            interval,
            date: moment(date)
              .format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR_HYPHEN),
            filter: String(filter),
            page,
          });
        }

        getGameDefs({
          company,
          storyline: campaign.storyline.founder_id,
        });

        const changedData = {
          ...data,
          campaign: campaign.id,
          timeRange: interval,
          date,
          filterBy: filter,
        };
        changeData(changedData);
        setGameSessionsPage(page);
        loadGameSessions({
          page,
          changedData,
          storedCampaigns: campaigns,
        });
      } catch (error) {
        notification.error(getError(error));
      }
    }

    isValidCompany && fetchData();
  }, [isValidCompany]);

  const month = useMemo(() => moment(data.date)
    .month(), [data.date]);

  useEffect(() => {
    if (isGameSessionsMount.current && data.campaign) {
      loadDaysWithGameSession();
    } else {
      isGameSessionsMount.current = true;
    }
  }, [
    month,
    data.campaign,
  ]);

  const selectedCampaign = campaigns.find(({ id }) => id === data.campaign) || {};

  const participantEmails = useMemo(() => {
    // need to concat signed up participants coz of signed up, but not invited users (which registered via shared link)
    if (!openedSessionForEdit.invited_participants || !openedSessionForEdit.signedup_participants) return [];
    return openedSessionForEdit.invited_participants.concat(openedSessionForEdit.signedup_participants).reduce((acc, item) => (
      acc.some(({ id }) => id === item.id) ? acc : acc.concat(item)
    ), []);
  },
  [openedSessionForEdit.invited_participants, openedSessionForEdit.signedup_participants]);

  const userTimezone = getTimezoneName(openedSessionForEdit.timezone || companyTimezone);
  const sessionSidebarProps = {
    data: {
      id: openedSessionForEdit.id || null,
      kit: (openedSessionForEdit.kit && openedSessionForEdit.kit.founder_id) || null,
      // gameDef: openedSessionForEdit.game_def || null,
      gameDef: (openedSessionForEdit.game_def && openedSessionForEdit.game_def.id) || null,
      // date: openedSessionForEdit.start_time ? moment(openedSessionForEdit.start_time).utc().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY) : null,
      dateFrom: openedSessionForEdit.start_time
        ? moment(openedSessionForEdit.start_time)
          .utc()
          .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY)
        : moment(
          data.timeRange === TABLE_TYPES.WEEK
            ? new Date()
            : data.date,
        )
          .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
      // time: openedSessionForEdit.start_time ? moment(openedSessionForEdit.start_time).utc().format(DATE_SETTINGS.FORMAT.HOURS_MINUTES) : null,
      startTime: openedSessionForEdit.start_time
        ? moment(openedSessionForEdit.start_time)
          .utc()
          .format(DATE_SETTINGS.FORMAT.HOURS_MINUTES)
        : null,
      location: (openedSessionForEdit.location && openedSessionForEdit.location.id) || null,
      timezone: userTimezone,
      timezone_offset: openedSessionForEdit.timezone_offset || null,
      maxParticipants: openedSessionForEdit.max_participants || null,
      invitedParticipants: openedSessionForEdit.invited_participants_count || 0,
      participantEmails,
      introductionTime: openedSessionForEdit.introduction_time || selectedCampaign.introduction_time,
      debriefingTime: openedSessionForEdit.debriefing_time || selectedCampaign.debriefing_time,
      participantsPerSession: openedSessionForEdit.participants_per_session || selectedCampaign.participants_per_session,
      isTimerCountingUp: isEmpty(openedSessionForEdit) ? selectedCampaign.is_timer_counting_up : openedSessionForEdit.is_timer_counting_up,
      isSecretSession: ('is_anonymous' in openedSessionForEdit) ? openedSessionForEdit.is_anonymous : userCompany.is_secret_mode,
      signedUpParticipants: openedSessionForEdit.signedup_participants || [],
      autoSignUp: openedSessionForEdit.auto_signup || false,
      campaign: data.campaign ? selectedCampaign : {},
      meetingLink: openedSessionForEdit.meeting_link || '',
      kits,
      gameDefs,
      locations,
      activeSidebar,
      SIDEBAR_NAMES,
    },
    getLocations,
    loadGameSessions,
    loadDaysWithGameSession,
    loadGameDefs,
    handleCalendarChange,
  };
  const launchSessionData = {
    campaign: data.campaign,
    campaigns,
    kits,
    gameDefs,
    locations,
    timezone: userTimezone,
    introductionTime: selectedCampaign.introduction_time,
    debriefingTime: selectedCampaign.debriefing_time,
  };


  const calenderMarkers = useMemo(() => daysWithGameSession.map(item => getLocalDateFromUTCString(item, companyTimezone)
    .format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY_HOURS_MINUTES)), [companyTimezone, daysWithGameSession]);


  const calendarSidebar = isValidCompany
    ? (
      <CalendarSidebar
        campaign={data.campaign}
        calendarType={data.timeRange}
        campaignPicker={(
          <Select
            name="campaign"
            placeholder="Select campaign"
            value={data.campaign}
            onChange={handleCampaignChange}
            options={transformDataToOptions({
              data: campaigns,
              value: 'id',
              label: 'name',
            })}
            defaultOptions={transformDataToOptions({
              data: campaigns,
              value: 'id',
              label: 'name',
            })}
            loadOptions={loadCampaigns}
            async
            searchable
            withoutReset
          />
        )}
        toggleBar={(
          <ToggleBar
            name="timeRange"
            onChange={handleIntervalRangeChange}
            active={data.timeRange}
            options={[
              TABLE_TYPES.DAY,
              TABLE_TYPES.WEEK,
            ]}
          />
        )}
        calendarData={{
          value: data.date,
          name: 'date',
          onChange: handleCalendarChange,
          events: calenderMarkers,
          isDayVisible: data.timeRange !== TABLE_TYPES.WEEK,
          onDayChange: handleDayChange,
        }}
      />
    )
    : null;

  const calendarContent = isValidCompany
    ? (
      <>
        <CalendarMainSection
          onChange={handleCalendarChange}
          loading={loading}
          date={data.date}
          name="date"
          calendarType={data.timeRange}
          gameSessionStatuses={gameSessionStatuses}
          tableData={tableData}
          pagination={(
            <Pagination
              activePage={gameSessionsPage}
              pageChangeHandler={handleGameSessionsPageChange}
              pageCount={totalGameSessions / GAME_SESSIONS_TABLE.PAGINATION.PER_PAGE}
              withInitialPage={false}
            />
          )}
          openEditSession={openEditSessionSidebar}
          openSessionInfoModal={openSessionInfoModal}
          onSessionDelete={onSessionDelete}
          campaign={data.campaign}
          actions={(
            <>
              {
                hasPermission(PERMISSION_NAMES.ADD_NEW_SESSION) && (
                  <AddCircle
                    key="Add session"
                    onClick={openAddSessionSidebar}
                    title="Add session"
                  />
                )
              }
              <AddCircle
                key="Launch session"
                onClick={openLaunchSessionSidebar}
                title="Launch session"
              />
              <Select
                name="filterBy"
                placeholder="Filter by kit"
                value={data.filterBy}
                onChange={handleFilterChange}
                options={transformDataToOptions({
                  data: kits,
                  value: 'founder_id',
                  label: 'kit_id',
                })}
                defaultOptions={transformDataToOptions({
                  data: kits,
                  value: 'founder_id',
                  label: 'kit_id',
                })}
                searchable
                size="small"
              />
            </>
          )}
          styleToggleBar={(
            <ToggleBar
              name="calendarStyle"
              sameWidth
              onChange={handleCalendarStyleChange}
              active={data.calendarStyle}
              options={[
                CALENDAR_STYLES.ALL,
                CALENDAR_STYLES.BY_TIME,
              ]}
            />
          )}
          calendarStyle={data.calendarStyle}
        />
        <EditSessionSidebar
          open={activeSidebar === SIDEBAR_NAMES.EDIT}
          title="Edit session"
          onClose={closeHandler}
          onSave={saveHandler}
          saveText="Save changes"
          onDelete={onEditSessionDelete}
          isLoading={isLoading}
          {...sessionSidebarProps}
        />
        <AddSessionSidebar
          open={activeSidebar === SIDEBAR_NAMES.ADD}
          onClose={closeHandler}
          onSave={saveHandler}
          isLoading={isLoading}
          {...sessionSidebarProps}
        />
        <LaunchSessionSidebar
          open={SIDEBAR_NAMES.LAUNCH === activeSidebar}
          title="Launch session"
          saveText="Launch"
          onClose={closeHandler}
          onSave={launchSession}
          data={launchSessionData}
          loadCampaigns={loadCampaigns}
          loadGameDefs={loadGameDefs}
          loadLocations={loadLocations}
          loadNewData={loadNewDataForLaunchSessionSidebar}
          getInitialUserData={getInitialUserData}
          role={role}
          roles={roles}
        />
        <SessionInfoModal
          open={activeModal === MODAL_NAMES.SESSION_INFO}
          onClose={closeModal}
          openEditSession={openEditSessionSidebar}
          session={openedSessionForEdit}
        />
      </>
    )
    : <CompanyLicenseExpiredBlock isLSAdmin={isLSAdmin} />;

  return (
    <CompanyAdminStruct sidebar={calendarSidebar}>
      {
        isCompanyContractLoaded
          ? calendarContent
          : <Loader />
      }
    </CompanyAdminStruct>
  );
}

/* Page Url */

Calendar.path = URLS_CONFIG.companyAdmin.calendar;

/* Page Title */

Calendar.title = PAGE_TITLE;

/* Calendar type of props */

Calendar.propTypes = {
  getCampaigns: PropTypes.func.isRequired,
  getCampaign: PropTypes.func.isRequired,
  getGameSessions: PropTypes.func.isRequired,
  addDayWithGameSession: PropTypes.func.isRequired,
  getDaysWithGameSession: PropTypes.func.isRequired,
  getKits: PropTypes.func.isRequired,
  getKit: PropTypes.func.isRequired,
  removeKit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  company: PropTypes.number,
  deleteGameSession: PropTypes.func.isRequired,
  editGameSession: PropTypes.func.isRequired,
  addGameSession: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  getGameDefs: PropTypes.func.isRequired,
  getGameDef: PropTypes.func.isRequired,
  removeGameDef: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  addNewLocation: PropTypes.func.isRequired,
  getInitialUserData: PropTypes.func.isRequired,
};

/* Calendar default props */

Calendar.defaultProps = {
  company: null,
};

const mapStateToProps = state => ({
  campaigns: state.adminCompany.campaigns,
  gameSessions: state.adminCompany.gameSessions,
  loading: state.adminCompany.loading,
  company: state.auth.company,
  kits: state.adminCompany.kits,
  gameDefs: state.adminCompany.gameDefs,
  locations: state.adminCompany.locations,
  gameSessionStatuses: state.staticData.gameSessionStatuses,
  daysWithGameSession: state.adminCompany.daysWithGameSession,
  role: state.auth.role,
  roles: state.staticData.roles,
  userCompany: state.adminCompany.company,
  companyTimezone: get(state, 'adminCompany.company.timezone'),
});

export default connect(mapStateToProps, {
  getCampaigns: AdminCompanyEntity.actions.getCampaigns,
  getCampaign: AdminCompanyEntity.actions.getCampaign,
  getGameSessions: AdminCompanyEntity.actions.getGameSessions,
  addDayWithGameSession: AdminCompanyEntity.actions.addDayWithGameSession,
  getDaysWithGameSession: AdminCompanyEntity.actions.getDaysWithGameSession,
  getKits: AdminCompanyEntity.actions.getKits,
  getKit: AdminCompanyEntity.actions.getKit,
  removeKit: AdminCompanyEntity.actions.removeKit,
  getGameDefs: AdminCompanyEntity.actions.getGameDefs,
  getGameDef: AdminCompanyEntity.actions.getGameDef,
  removeGameDef: AdminCompanyEntity.actions.removeGameDef,
  getLocations: AdminCompanyEntity.actions.getLocations,
  addNewLocation: AdminCompanyEntity.actions.addNewLocation,
  deleteGameSession: AdminCompanyEntity.actions.deleteGameSession,
  editGameSession: AdminCompanyEntity.actions.editGameSession,
  addGameSession: AdminCompanyEntity.actions.addGameSession,
  getCompany: AdminCompanyEntity.actions.getCompany,
  getInitialUserData: AuthEntity.actions.getInitialUserData,
})(Calendar);
