/* Libs */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  merge,
  values,
  debounce,
  isInteger,
  isEmpty,
  isNaN,
} from 'lodash';
import moment from 'moment';

/* Components */

import {
  CompanyAdminStruct,
  UsersMainSection,
  GoLabel,
  AddCircle,
  ToggleBar,
  Select,
  EditUserSidebar,
  AddUserSidebar,
  Pagination,
  Loader,
  CompanyLicenseExpiredBlock,
  SearchInput,
} from 'components';

/* Actions */

import { AdminCompanyEntity } from '_entities';

/* Utils */
import {
  getFullName,
  exportCSV,
  getError,
  showErrorsFromBackend,
  replaceQueryParams,
  getStaticData,
  checkLastPageEmptiness,
} from 'utils/custom';
import { notification } from 'utils/services';

/* Constants */

import {
  URLS_CONFIG,
  USER_TYPES,
  USERS_TABLE,
} from 'config';
import {
  TRIANGLE_TYPES,
  LABEL_COLOR_TYPES,
  NOTIFICATIONS,
} from '_constants';

/* Styles */

import * as Styled from './styles';


const PAGE_TITLE = 'Users';

const DEFAULT_DATA = {
  userType: USER_TYPES.ACTIVE,
  filterBy: null,
  searchName: null,
};

const SIDEBAR_NAMES = {
  ADD: 'Add',
  EDIT: 'Edit',
};


function Users({
  location: { search },
  campaigns,
  getCampaigns,
  getCampaign,
  downloadUsers,
  company,
  getUsers,
  loading,
  users,
  resendEmail,
  roles,
  getAllActiveCampaigns,
  allActiveCampaigns,
  addUser,
  updateUser,
  deleteUser,
  currentUserEmail,
  role,
  userCompany,
  loggedID,
}) {
  const [data, changeData] = useState(DEFAULT_DATA);
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [openedUserForEdit, setOpenedUserForEdit] = useState(null);
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  const isLSAdmin = role === getStaticData('roles').admin;

  const loadUsers = async ({
    currentPage = page,
    filterBy = data.filterBy,
    userType = data.userType,
    name = data.searchName,
  } = {}) => {
    try {
      const { count, results } = await getUsers(
        merge(
          {
            company,
            isActive: userType === USER_TYPES.ACTIVE,
            page: currentPage,
            name,
          },
          filterBy ? { campaign: filterBy } : {},
        ),
      );

      if (!results.length && count) {
        setPage(1);
        changeData(prev => ({
          ...prev,
          type: userType,
        }));
        replaceQueryParams({
          type: userType,
          filter: data.filterBy,
          page: 1,
        });
        loadUsers({
          currentPage: 1,
          userType,
        });
      } else {
        replaceQueryParams({
          type: userType,
          filter: data.filterBy,
          page: currentPage,
        });
        setTotalUsers(count);
      }
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const fetchUsers = useCallback(debounce(loadUsers, 300), []);


  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);

    replaceQueryParams({
      type: data.userType,
      filter: String(data.filterBy),
      page: selected + 1,
    });
    loadUsers({ currentPage: selected + 1 });
  };

  const handleChange = ({ target: { name, value } }) => changeData({
    ...data,
    [name]: value,
  });

  const handleUserTypeChange = ({ target: { value: userType } }) => {
    const { filterBy } = DEFAULT_DATA;

    changeData({
      ...data,
      userType,
      filterBy,
    });
    setPage(1);

    loadUsers({
      filterBy,
      userType,
      name: data.searchName,
      currentPage: 1,
    });
    replaceQueryParams({
      type: userType,
      filter: String(filterBy),
      page: 1,
    });
  };

  const handleSearch = ({ target: { name, value } }) => {
    const { userType, filterBy } = data;

    handleChange({
      target: {
        name,
        value,
      },
    });
    replaceQueryParams({
      type: userType,
      filter: filterBy,
      page: 1,
    });
    setPage(1);

    fetchUsers({
      filterBy,
      userType,
      currentPage: 1,
      name: value,
    });
  };


  const handleFilterChange = ({ target: { name, value } }) => {
    const { userType } = data;

    handleChange({
      target: {
        name,
        value,
      },
    });
    replaceQueryParams({
      type: userType,
      filter: value,
      page: 1,
    });
    setPage(1);

    loadUsers({
      filterBy: value,
      userType,
      currentPage: 1,
    });
  };
  const manageSidebar = name => () => setActiveSidebar(name);
  const getCampaignsForFilter = (newCampaigns = campaigns) => newCampaigns.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const openEditUserSidebar = (id) => {
    setOpenedUserForEdit(users.find(user => user.id === id));
    manageSidebar(SIDEBAR_NAMES.EDIT)();
  };
  const openAddUserSidebar = () => manageSidebar(SIDEBAR_NAMES.ADD)();
  const closeHandler = () => {
    manageSidebar(null)();
  };

  const isLastItemOnLastPage = checkLastPageEmptiness({
    total: totalUsers,
    perPage: USERS_TABLE.PAGINATION.PER_PAGE,
    page,
  });

  const deleteHandler = async () => {
    try {
      await deleteUser(openedUserForEdit.id);
      notification.success(NOTIFICATIONS.SUCCESS_DELETE_USER);
      if (isLastItemOnLastPage) {
        setPage(prev => prev - 1);
        await loadUsers({ currentPage: page - 1, name: '' });
      } else {
        await loadUsers({ name: '' });
      }
      changeData(prev => ({
        ...prev,
        searchName: '',
      }));
      closeHandler();
    } catch (error) {
      showErrorsFromBackend(error);
    }
  };
  const shareUsers = async () => {
    try {
      const downloadedUsers = await downloadUsers({ company });
      exportCSV(downloadedUsers, 'users');
    } catch (error) {
      notification.error(getError(error));
    }
  };

  const resendEmailToUser = async (user) => {
    try {
      await resendEmail({ user });
      notification.success(NOTIFICATIONS.SUCCESS_RESEND_EMAIL);
    } catch (error) {
      notification.error(getError(error));
    }
  };


  const handleSave = async (requestData) => {
    try {
      changeData(prev => ({
        ...prev,
        searchName: '',
      }));

      if (activeSidebar === SIDEBAR_NAMES.EDIT) {
        await updateUser(requestData);
        if (isLastItemOnLastPage) {
          setPage(prev => prev - 1);
          await loadUsers({ currentPage: page - 1, name: '' });
        } else {
          await loadUsers({ name: '' });
        }
        notification.success((requestData.hasOwnProperty('is_active') && !requestData.is_active)
          ? NOTIFICATIONS.SUCCESS_DEACTIVATED_USER
          : NOTIFICATIONS.SUCCESS_UPDATE_USER);
      } else {
        await addUser(requestData);
        setPage(1);
        loadUsers({ userType: data.userType, name: '', currentPage: 1 });
        notification.success(NOTIFICATIONS.SUCCESS_ADD_USER);
      }
    } catch (error) {
      showErrorsFromBackend(error);
      return;
    }

    closeHandler();
  };
  const activateUser = async (id) => {
    try {
      await updateUser({
        id,
        is_active: true,
      });
      changeData(prev => ({
        ...prev,
        searchName: '',
      }));
      notification.success(NOTIFICATIONS.SUCCESS_ACTIVATE_USER);
      if (isLastItemOnLastPage) {
        setPage(prev => prev - 1);
        await loadUsers({ currentPage: page - 1, name: '' });
      } else {
        await loadUsers({ name: '' });
      }
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const fetchCampaigns = async (campaign, callback) => {
    try {
      await getCampaigns({
        campaign,
        withoutStoreChanging: true,
        perPage: 10,
        company,
      })
        .then(({ results: campaigns }) => {
          callback(getCampaignsForFilter(campaigns));
        });
    } catch (error) {
      notification.error(getError(error));
    }
  };
  const loadCampaigns = useCallback(debounce(fetchCampaigns, 300), []);


  const isCompanyContractLoaded = !isEmpty(userCompany) && !isEmpty(userCompany.contract);
  const isValidCompany = isCompanyContractLoaded && moment(userCompany.contract.end_date)
    .isSameOrAfter();

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(search);
      const queryUserType = params.get('type');
      const queryFilter = params.get('filter');
      const searchParamsPage = +params.get('page');
      const page = (isNaN(searchParamsPage) || (searchParamsPage === 0)) ? 1 : searchParamsPage;

      const filterNumber = queryFilter && Number(queryFilter);

      const { results: campaigns } = await getCampaigns({
        isActive: true,
        perPage: 10,
        company,
      });
      const campaign = isInteger(filterNumber)
        && (
          campaigns.find(({ id }) => id === filterNumber)
          || await getCampaign({ id: filterNumber })
            .then(res => res)
            .catch(err => err)
        );

      const isValidQueryUserType = queryUserType && values(USER_TYPES)
        .includes(queryUserType);
      const isValidFilter = campaign && campaign.id;

      const userType = isValidQueryUserType ? queryUserType : DEFAULT_DATA.userType;
      const filter = isValidFilter ? filterNumber : DEFAULT_DATA.filterBy;

      if (!isValidQueryUserType || !isValidFilter || !page) {
        replaceQueryParams({
          type: userType,
          filter: String(filter),
          page,
        });
      }

      if ((userType !== DEFAULT_DATA.userType) || isValidFilter) {
        changeData({
          ...data,
          userType,
          filterBy: filter,
        });
      }
      setPage(page);
      loadUsers({
        currentPage: page,
        filterBy: filter,
        userType,
      });
    };

    isValidCompany && fetchData();
  }, [isValidCompany]);

  const usersContent = isValidCompany
    ? (
      <>
        <Styled.Wrapper>
          <UsersMainSection
            actions={[
              <AddCircle key="Add user" onClick={openAddUserSidebar} title="Add user" />,
              <GoLabel
                key="Share users"
                text="Share users"
                triangleType={TRIANGLE_TYPES.FORWARD}
                color={LABEL_COLOR_TYPES.BLUE}
                click={shareUsers}
              />,
            ]}
            search={(
              <SearchInput
                name="searchName"
                placeholder="Search by name or email"
                value={data.searchName}
                onChange={handleSearch}
              />
            )}
            toggleBar={(
              <ToggleBar
                name="userType"
                onChange={handleUserTypeChange}
                active={data.userType}
                options={[
                  USER_TYPES.ACTIVE,
                  USER_TYPES.INACTIVE,
                ]}
              />
            )}
            filter={(
              <Select
                name="filterBy"
                placeholder="Filter by campaign"
                value={data.filterBy}
                onChange={handleFilterChange}
                options={getCampaignsForFilter()}
                defaultOptions={getCampaignsForFilter()}
                loadOptions={loadCampaigns}
                async
                searchable
              />
            )}
            tableData={users}
            loading={loading}
            userType={data.userType}
            resendEmailToUser={resendEmailToUser}
            openEditUser={openEditUserSidebar}
            activateUser={activateUser}
            pagination={(
              <Pagination
                activePage={page}
                pageChangeHandler={handlePageChange}
                pageCount={totalUsers / USERS_TABLE.PAGINATION.PER_PAGE}
                withInitialPage={false}
              />
            )}
          />
        </Styled.Wrapper>
        <AddUserSidebar
          title="Add new user"
          open={activeSidebar === SIDEBAR_NAMES.ADD}
          onClose={closeHandler}
          roles={roles}
          company={company}
          onSave={handleSave}
          getAllActiveCampaigns={getAllActiveCampaigns}
          allActiveCampaigns={allActiveCampaigns}
        />
        <EditUserSidebar
          open={activeSidebar === SIDEBAR_NAMES.EDIT}
          onClose={closeHandler}
          title={openedUserForEdit && `Edit ${getFullName(openedUserForEdit.first_name, openedUserForEdit.last_name)}`}
          data={
            openedUserForEdit
              ? {
                id: openedUserForEdit.id,
                first_name: openedUserForEdit.first_name,
                last_name: openedUserForEdit.last_name,
                email: openedUserForEdit.email,
                campaigns: openedUserForEdit.campaigns.map(({ id }) => id),
                deactivatedUser: Boolean(openedUserForEdit.deactivation_date),
                userRole: openedUserForEdit.role,
              }
              : {}
          }
          roles={roles}
          onDelete={deleteHandler}
          onSave={handleSave}
          getAllActiveCampaigns={getAllActiveCampaigns}
          allActiveCampaigns={allActiveCampaigns}
          company={company}
          isCurrentUser={openedUserForEdit && (currentUserEmail === openedUserForEdit.email)}
          loggedID={loggedID}
        />
      </>
    )
    : <CompanyLicenseExpiredBlock isLSAdmin={isLSAdmin} />;

  return (
    <CompanyAdminStruct>
      {
        isCompanyContractLoaded
          ? usersContent
          : <Loader />
      }
    </CompanyAdminStruct>
  );
}

/* Page Url */

Users.path = URLS_CONFIG.companyAdmin.users;

/* Page Title */

Users.title = PAGE_TITLE;

/* Users type of props */
const {
  arrayOf, shape, number, string, func, bool,
} = PropTypes;

Users.propTypes = {
  campaigns: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    }),
  ),
  getCampaigns: func.isRequired,
  getCampaign: func.isRequired,
  downloadUsers: func.isRequired,
  company: number.isRequired,
  getUsers: func.isRequired,
  loading: bool.isRequired,
  users: arrayOf(
    shape({
      id: number.isRequired,
      first_name: string,
      last_name: string,
      email: string.isRequired,
      role: string.isRequired,
      campaigns: arrayOf(Object),
      is_active: bool.isRequired,
      deactivation_date: string,
    }),
  ),
  resendEmail: func.isRequired,
  getAllActiveCampaigns: func.isRequired,
  addUser: func.isRequired,
  updateUser: func.isRequired,
  deleteUser: func.isRequired,
  currentUserEmail: string.isRequired,
  role: string.isRequired,
  roles: PropTypes.objectOf(Object).isRequired,
  allActiveCampaigns: PropTypes.objectOf(Object),
  userCompany: PropTypes.objectOf(Object).isRequired,
  loggedID: PropTypes.number.isRequired,
};

/* Users default props */
Users.defaultProps = {
  campaigns: [],
  users: [],
  allActiveCampaigns: [],
};

const mapStateToProps = state => ({
  campaigns: state.adminCompany.campaigns,
  company: state.auth.company,
  loading: state.adminCompany.loading,
  users: state.adminCompany.users,
  roles: state.staticData.roles,
  allActiveCampaigns: state.adminCompany.allActiveCampaigns,
  currentUserEmail: state.auth.email,
  role: state.auth.role,
  userCompany: state.adminCompany.company,
  loggedID: state.auth.id,
});

export default connect(mapStateToProps, {
  getCampaigns: AdminCompanyEntity.actions.getCampaigns,
  getCampaign: AdminCompanyEntity.actions.getCampaign,
  downloadUsers: AdminCompanyEntity.actions.downloadUsers,
  getUsers: AdminCompanyEntity.actions.getUsers,
  resendEmail: AdminCompanyEntity.actions.resendEmail,
  getAllActiveCampaigns: AdminCompanyEntity.actions.getAllActiveCampaigns,
  addUser: AdminCompanyEntity.actions.addUser,
  updateUser: AdminCompanyEntity.actions.updateUser,
  deleteUser: AdminCompanyEntity.actions.deleteUser,
})(Users);
