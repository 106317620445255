/* Libs */
import styled from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Wrapper = styled.div`
`;

export const Question = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${COLORS_CONFIG.SILVER_CUSTOM_3};
  padding: 18px 16px 14px 17px;
`;
export const QuestionText = styled.div`
  word-break: break-all;
  padding-right: 16px;
  font-size: 15px;
  line-height: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.DARK_GREY};
`;
export const Action = styled.div`
`;
