/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

/* Constants */
import { STRUCT_IDS } from '_constants';

/* Components */
import {
  TableActionSidebar,
  TextWithTooltip,
} from 'components';

/* Styles */
import * as Styled from './styles';

const TITLES = {
  invited_participants: 'Invited',
  signedup_participants: 'Signed up',
  actual_participants: 'Actually played',
};

function ViewParticipantsSidebar({
  open,
  title,
  onClose,
  id,
  data,
}) {
  if (!open) return null;
  const keys = Object.keys(data);

  return (
    <TableActionSidebar
      open={open}
      title={title}
      onClose={onClose}
      id={id}
    >
      <Styled.ContentWrapper>
        <Styled.Wrapper>
          {
            keys.map((prop) => {
              const items = get(data, prop, []);
              return (
                <Styled.GroupWrapper>
                  <Styled.GroupTitle key={prop}>
                    {TITLES[prop]}
                    <Styled.Label>{items.length}</Styled.Label>
                  </Styled.GroupTitle>
                  {
                    items.map((item => (
                      <Styled.Participant key={item.id}>
                        <TextWithTooltip withPortalTooltip maxLength={45} text={item.email} />
                      </Styled.Participant>
                    )))
                  }
                </Styled.GroupWrapper>
              );
            })
          }
        </Styled.Wrapper>
      </Styled.ContentWrapper>
    </TableActionSidebar>
  );
}

// Type of props

ViewParticipantsSidebar.propTypes = {
  id: PropTypes.oneOf([
    STRUCT_IDS.COMPANY_ADMIN_PAGE,
    STRUCT_IDS.ADMIN_SETTINGS_PAGE,
  ]),
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      invited: PropTypes.arrayOf(PropTypes.string),
      signedup: PropTypes.arrayOf(PropTypes.string),
      actual: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

// Default value for props

ViewParticipantsSidebar.defaultProps = {
  id: STRUCT_IDS.COMPANY_ADMIN_PAGE,
  data: [],
  title: 'Participants view',
};

export default ViewParticipantsSidebar;
