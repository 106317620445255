/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';


export const Cell = styled.div`
  max-width: 100%;
  font-family: ${props => FONTS_CONFIG[`MAIN_TEXT_FONT_${props.fontFamily}`]};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Cell;
