/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import {
  Modal,
  EditStorylineForm,
} from 'components';

/* Styles */

import * as Styled from './styles';

export default function EditStoryline({
  onClose,
  data,
  open,
  loadAllStorylines
}) {
  if (!open) return null;
  return (
    <Modal
      onClose={onClose}
      title="Edit storyline"
      open={open}
    >
      <Styled.EditStorylineModal>
        <EditStorylineForm loadAllStorylines={loadAllStorylines} data={data} onClose={onClose} />
      </Styled.EditStorylineModal>
    </Modal>
  );
}

// Type of props
EditStoryline.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
