/* Libs */
import styled from 'styled-components';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Brush = styled.div`
  width: 100%;
  padding: 0;
  height: 95px;
  overflow-y: hidden;
  overflow-x: visible;
  padding: 0 5px;
`;
