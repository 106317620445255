/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

// import {} from 'utils';

/* Components */

import { ReportCard, Loader } from 'components';

/* Constants */

// import {} from 'constants';

/* Styles */

import * as Styled from './styles';

function StatiscticsSection({ data = {}, loading }) {
  // if (loading || !data) {
  //   return <Loader />;
  // }

  const {
    actual_participants: actualParticipants,
    invited_participants: invitedParticipants,
    max_capacity: maxCapacity,
    registered_participants: registeredParticipants,
    sessions_completed: sessionsCompleted,
    sessions_scheduled: sessionsScheduled,
    avg_actual_participants: avgActualParticipants,
  } = data;

  const getPercentage = (first, second) => {
    const percentage = Math.round((first / second) * 100);

    if (percentage === Infinity) return 100;

    return Number.isNaN(percentage) ? 0 : percentage;
  };

  return (
    <Styled.Wrapper>
      <ReportCard value={sessionsScheduled} label="Sessions scheduled" loading={loading} />
      <ReportCard value={sessionsCompleted} label="Sessions completed" loading={loading} />
      <ReportCard
        value={Math.round(avgActualParticipants)}
        label="Average number of participants in each completed sessions"
        loading={loading}
      />
      <ReportCard
        value={`${registeredParticipants}/${invitedParticipants}`}
        label="Sign up/Invited"
        badge={getPercentage(registeredParticipants, invitedParticipants)}
        loading={loading}
      />
      <ReportCard
        value={`${actualParticipants}/${maxCapacity}`}
        label="Actually played/Max capacity"
        badge={getPercentage(actualParticipants, maxCapacity)}
        loading={loading}
      />
      <ReportCard
        value={`${actualParticipants}/${registeredParticipants}`}
        label="Actually played/Sign up"
        badge={getPercentage(actualParticipants, registeredParticipants)}
        loading={loading}
      />
    </Styled.Wrapper>
  );
}

/* StatiscticsSection type of props */

StatiscticsSection.propTypes = {
  data: PropTypes.shape({
    actual_participants: PropTypes.number,
    invited_participants: PropTypes.number,
    max_capacity: PropTypes.number,
    registered_participants: PropTypes.number,
    sessions_completed: PropTypes.number,
    sessions_scheduled: PropTypes.number,
    avg_actual_participants: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

/* StatiscticsSection default props */

StatiscticsSection.defaultProps = {
  data: null,
  loading: false,
};

export default StatiscticsSection;
