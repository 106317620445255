/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { CustomScroll } from 'assets/styles/mixins';

export const Wrapper = styled.div`
  width: 100%;
  padding: 25px 39px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  flex: 1;
`;
export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-top: -5px;
  & > div {
    padding-right: 25px;
    &:last-child {
      padding-right: 0;
    }
  }
`;
export const ActionsList = styled.div`
`;

export const GoBack = styled.div`
  color: ${COLORS_CONFIG.SKY_BLUE};
  cursor: pointer;
  width: 50px;
  position: relative;
  padding-left: 10px;
  font-size: 15px;
  line-height: 18px;
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid ${COLORS_CONFIG.SKY_BLUE};
    border-right: 1px solid ${COLORS_CONFIG.SKY_BLUE};
    transform: translateY(-5px) rotate(135deg) ;
  }
`;

export const Information = styled.div`
  flex: 1;
  max-width: 40%;
  margin-top: 25px;
`;
export const Timer = styled.div``;
export const Status = styled.div`
  padding-top: 7px;
  font-size: 16px;
  line-height: 19px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;
export const StatusName = styled.span`
  color: ${COLORS_CONFIG.SKY_BLUE};
`;

export const TeamName = styled.div`
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 17px 0;
`;
export const LeftSide = styled.div`
  position: absolute;
  width: 30%;
  min-width: 280px;
  border-radius: 4px;
  overflow: auto;
  height: 100%;
  top: 0;
  left:0;
  
  ${CustomScroll}
  
`;
export const RightSide = styled.div`
  padding-left: calc(30% + 15px);
  flex: 1;
  @media screen and (max-width: 1010px){
    padding-left: 295px;
  }
`;
