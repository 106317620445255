/* Libs */
import styled from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Title = styled.div`
  flex-grow: 1;
  font-size: 18px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Action = styled.div`
  font-size: 14px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  text-align: end;
  flex-grow: 1;
  cursor: pointer;
`;
