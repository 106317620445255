import React from 'react';
import EditFormComponent from './component';
import useEditSessionForm from './container';

const EditSessionForm = ({ validationProps, ...props }) => {
  const formProps = useEditSessionForm(props);

  return (
    <EditFormComponent isEdit {...formProps} {...validationProps} />
  );
};

export default EditSessionForm;
