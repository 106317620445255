export const SET_TOKEN = '@PROJECT_NAME/SET_TOKEN';

export const START_WITHOUT_TOKEN = '@PROJECT_NAME/START_WITHOUT_TOKEN';

export const LOGOUT = '@PROJECT_NAME/LOGOUT';

export const SIGNUP = '@PROJECT_NAME/SIGNUP';
export const SIGNUP_SUCCESS = '@PROJECT_NAME/SIGNUP_SUCCESS';
export const SIGNUP_FAIL = '@PROJECT_NAME/SIGNUP_FAIL';

export const CONFIRM_ACCOUNT = '@PROJECT_NAME/CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_SUCCESS = '@PROJECT_NAME/CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_FAIL = '@PROJECT_NAME/CONFIRM_ACCOUNT_FAIL';

export const RESET_PASSWORD = '@PROJECT_NAME/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '@PROJECT_NAME/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = '@PROJECT_NAME/RESET_PASSWORD_FAIL';

export const CONFIRM_NEW_PASSWORD = '@PROJECT_NAME/CONFIRM_NEW_PASSWORD';
export const CONFIRM_NEW_PASSWORD_SUCCESS = '@PROJECT_NAME/CONFIRM_NEW_PASSWORD_SUCCESS';
export const CONFIRM_NEW_PASSWORD_FAIL = '@PROJECT_NAME/CONFIRM_NEW_PASSWORD_FAIL';

export const CHECK_RESET_TOKEN = '@PROJECT_NAME/CHECK_RESET_TOKEN';
export const CHECK_RESET_TOKEN_SUCCESS = '@PROJECT_NAME/CHECK_RESET_TOKEN_SUCCESS';
export const CHECK_RESET_TOKEN_FAIL = '@PROJECT_NAME/CHECK_RESET_TOKEN_FAIL';

export const SIGNIN = '@PROJECT_NAME/SIGNIN';
export const SIGNIN_SUCCESS = '@PROJECT_NAME/SIGNIN_SUCCESS';
export const SIGNIN_FAIL = '@PROJECT_NAME/SIGNIN_FAIL';

export const UPDATE_TOKEN = '@PROJECT_NAME/UPDATE_TOKEN';
export const UPDATE_TOKEN_SUCCESS = '@PROJECT_NAME/UPDATE_TOKEN_SUCCESS';
export const UPDATE_TOKEN_FAIL = '@PROJECT_NAME/UPDATE_TOKEN_FAIL';

export const GET_INITIAL_USER_DATA = '@PROJECT_NAME/GET_INITIAL_USER_DATA';
export const GET_INITIAL_USER_DATA_SUCCESS = '@PROJECT_NAME/GET_INITIAL_USER_DATA_SUCCESS';
export const GET_INITIAL_USER_DATA_FAIL = '@PROJECT_NAME/GET_INITIAL_USER_DATA_FAIL';

export const CHANGE_USER_INFO = '@PROJECT_NAME/CHANGE_USER_INFO';
