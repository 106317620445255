/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

export const Cell = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Option = styled.div`
  display: flex;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  color: ${({ active }) => (active ? COLORS_CONFIG.SMOOTH_BROWN : COLORS_CONFIG.SKY_BLUE)};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  margin-left: 5px;
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 15px;
    background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
    margin-left: 5px;
    margin-top: -1px;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
    &:after {
      display: none;
    }
  }
`;

export const Img = styled.img`
  object-fit: contain;
`;

export default Cell;
