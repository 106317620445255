/* Libs */

import PropTypes from 'prop-types';
import React from 'react';

/* Components */

import { Modal, AddCompanyForm } from 'components';

/* Styles */

import * as Styled from './styles';

// ----------------
export default function AddCompanyModal({
  onClose,
  open,
}) {
  if (!open) return null;
  return (
    <Modal
      // onlyContentCloseClick
      onClose={onClose}
      title="Add a company"
      open={open}
    >
      <Styled.AddCompanyModal>
        <AddCompanyForm onClose={onClose} />
      </Styled.AddCompanyModal>
    </Modal>
  );
}

// Type of props
AddCompanyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

// Default value for props
AddCompanyModal.defaultProps = {};
