/* Libs */
import styled from 'styled-components';

import { FONTS_CONFIG, COLORS_CONFIG } from 'config';
import { BREAKPOINTS } from '_constants';

import { Pagination } from 'components/ui/Pagination/styles';


export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${Pagination} {
    margin-top: 40px;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 24px 0;
  padding-bottom: 20px;
  width: 100%;
  &:before,
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 32px);
    height: 1px;
    background: ${COLORS_CONFIG.SILVER};
    content: '';
    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      width: calc(100% + 20px);
    }
  }
  &:after {
    top: 100%;
  }
`;

export const NoCompaigns = styled.div`
  padding-top: 30px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 16px;
  text-align: center;
`;

export const List = styled.ul`
  flex-grow: 1;
  position: relative;
  padding-top: 16px;
`;

export const ItemText = styled.span`
  display: block;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Item = styled.li`
  position: relative;
  margin-bottom: 9px;
  font-size: 18px;
  line-height: 48px;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? 'none' : 'initial')};
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: -32px;
    width: calc(100% + 32px * 2);
    height: 100%;
    background: ${({ active }) => (active ? COLORS_CONFIG.WHITE_CUSTOM_1 : 'none')};
    content: '';
    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      left: -20px;
      width: calc(100% + 20px * 2);
    }
  }
  &:hover {
    &:before {
      background: ${COLORS_CONFIG.GREY_CUSTOM_4};
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  & ${ItemText} {
    font-family: ${({ active }) => (active ? FONTS_CONFIG.MAIN_TEXT_FONT_BOLD : FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR)};
  }
`;
