/* Libs */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/* Components */
import { ButtonLoader } from 'components';

/* Constants */
import { AVAILABLE_COLORS, COLORS_CONFIG } from 'config';

/* Styles */
import * as Styled from './styles';

function Button({
  loadingColor,
  isLoading,
  children,
  color,
  link,
  ...rest
}) {
  const renderButton = () => (
    <Styled.Button {...rest}>
      {isLoading ? <ButtonLoader color={loadingColor} /> : children}
    </Styled.Button>
  );

  const renderLink = () => (
    <Link to={link}>
      <Styled.ButtonLink {...rest}>{children}</Styled.ButtonLink>
    </Link>
  );

  return link ? renderLink() : renderButton();
}

// Type of props
Button.propTypes = {
  loadingColor: PropTypes.PropTypes.oneOf([...AVAILABLE_COLORS]),
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  link: PropTypes.string,
};

// Default value for props
Button.defaultProps = {
  loadingColor: COLORS_CONFIG.BLUE_NEON,
  isLoading: false,
  color: 'blue',
  type: 'button',
};

export default memo(Button);
