/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Modal, ParticipantSessionInfoSection } from 'components';


function ParticipantSessionInfoModal({
  onClose,
  open,
  session,
}) {
  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ maxWidth: '467px' }}
    >
      <ParticipantSessionInfoSection
        session={session}
        onClose={onClose}
      />
    </Modal>
  );
}

// Type of props
ParticipantSessionInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    team_name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    signedup_participants_count: PropTypes.number.isRequired,
    actual_participants_count: PropTypes.number.isRequired,
    time_completed: PropTypes.string.isRequired,
    actual_start_time: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
  }).isRequired,
};

export default ParticipantSessionInfoModal;
