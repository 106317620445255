/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Utils */

import {
  exportCSV, getCampaignLink, truncateText,
} from 'utils/custom';

/* Constants */

import { DATE_SETTINGS } from 'config';

/* Components */

import {
  Button,
  LinkTo,
  IconTooltip,
  Tooltip,
  TextWithTooltip,
} from 'components';

/* Assets */

import { INFO_CIRCLE_BLUE } from 'assets/icons';

/* Styles */

import * as Styled from './styles';

const maxTitleLength = 20;

function ReportListItem({
  id,
  isActive,
  title,
  createdAt,
  sessionsCompleted,
  participantsActual,
  exportRequest,
}) {
  const exportReport = async (e) => {
    e.preventDefault();

    const dates = {
      dateFrom: moment(createdAt).format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
      dateTo: moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
    };

    const csv = await exportRequest({
      id,
      ...dates,
    });
    exportCSV(csv, `${title}_report_from_${dates.dateFrom}_to_${dates.dateTo}`);
  };

  return (
    <LinkTo to={getCampaignLink(id)}>
      <Styled.Wrapper disabled={!isActive}>
        <Styled.Label>
          <Styled.CampaignName>
            <TextWithTooltip maxLength={maxTitleLength} text={title}>
              {!isActive && (
                <IconTooltip
                  src={INFO_CIRCLE_BLUE}
                  items={['This campaign is inactive.']}
                />
              )}
            </TextWithTooltip>
          </Styled.CampaignName>
          <Styled.SubTitle>{`Created on: ${createdAt}`}</Styled.SubTitle>
        </Styled.Label>
        <Styled.LabelWrapper>
          <Styled.Label>
            <Styled.Title>{sessionsCompleted}</Styled.Title>
            <Styled.SubTitle>Sessions completed</Styled.SubTitle>
          </Styled.Label>
        </Styled.LabelWrapper>
        <Styled.LabelWrapper>
          <Styled.Label>
            <Styled.Title>{participantsActual}</Styled.Title>
            <Styled.SubTitle>Actually played</Styled.SubTitle>
          </Styled.Label>
        </Styled.LabelWrapper>
        <Button onClick={exportReport}>Export</Button>
      </Styled.Wrapper>
    </LinkTo>
  );
}

/* ReportListItem type of props */

ReportListItem.propTypes = {
  id: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  sessionsCompleted: PropTypes.number.isRequired,
  participantsActual: PropTypes.number.isRequired,
  exportRequest: PropTypes.func.isRequired,
};

/* ReportListItem default props */

ReportListItem.defaultProps = {
  isActive: true,
};

export default ReportListItem;
