/* Libs */
import {
  useState, useRef, useMemo, useEffect,
} from 'react';
import get from 'lodash.get';
import moment from 'moment';
import { debounce } from 'lodash';

/* Utils */

import { validateDate } from 'utils/validation/fields';
import {
  convertTimeStringToSeconds,
  generateTimeOptions,
  generateKitOptions,
  getMonthsBetweenDates,
  getIncludedWeekdays,
} from 'utils/custom';

/* Constants */

import { DATE_SETTINGS, TIME_OPTIONS } from 'config';
import { TIME } from '_constants';

function useSetupSessionsForm({
  data: initialData,
  getLocations,
  steps,
  onClose,
  loadGameSessions,
  loadDaysWithGameSession,
  addNewLocation,
  externalData,
  setExternalData,
  isTimerCountingUp,
  toggleTimerType,
  changeParticipantsPerSession,
  handleCalendarChange,
  resetCampaignsFilter,
}) {
  const {
    gameDefs,
  } = initialData;

  const weekdaysShort = moment.weekdaysShort();
  const [innerData, setInnerData] = useState({
    dateFrom: initialData.dateFrom || moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
    dateTo: initialData.dateFrom || moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
    weekdays: [],
    location: null,
    gameDef: null,
    kit: null,
    timezone: initialData.timezone,
    timezone_offset: 0,
    // breakDuration: null,
    startTime: null,
    sessionsPerDay: null,
    introductionTime: initialData.introductionTime,
    debriefingTime: initialData.debriefingTime,
  });
  const { isSecretSession } = initialData;
  const setFormData = setExternalData || setInnerData;

  const [formDateRange, setFormDateRange] = useState({});
  const [dateErrors, setDateError] = useState({ dateFrom: null, dateTo: null });
  const [hasMoreOptions, setMoreOptions] = useState(false);

  const timeList = useRef(null);
  const currentData = { ...innerData, ...externalData };
  const {
    dateFrom,
    dateTo,
    weekdays,
    location,
    gameDef,
    kit,
    timezone,
    // breakDuration,
    startTime,
    sessionsPerDay,
    introductionTime,
    debriefingTime,
    meetingLink,
  } = currentData;

  const getDates = (startDate, endDate) => {
    let currentDate = moment(startDate);
    const dates = [];
    while (currentDate <= moment(endDate)) {
      dates.push(currentDate.format('ddd'));
      currentDate = currentDate.add(1, 'day');
    }
    return dates;
  };

  const setActiveDays = () => {
    setFormData(prev => ({
      ...prev,
      weekdays: getDates(dateFrom, dateTo),
    }));
  };

  const toggleWeekday = (day) => {
    setFormData(prev => ({
      ...prev,
      weekdays: weekdays.includes(day)
        ? weekdays.filter(item => item !== day)
        : [...weekdays, day],
    }));
  };

  const handleDateChange = ({ target: { name, value } }) => {
    setDateError(prev => ({ ...prev, [name]: validateDate({ value, name }) }));
    if (name === 'dateFrom') {
      const dateFromLocal = moment(value);
      const dateToLocal = moment(dateTo);
      if (
        dateFromLocal.isAfter(dateToLocal)
        || dateFromLocal.clone().add(6, 'months').isBefore(dateToLocal)
      ) {
        return setFormData(prev => ({
          ...prev,
          dateFrom: value,
          dateTo: dateFromLocal.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        }));
      }
    } else if (name === 'dateTo') {
      const dateToLocal = moment(value);
      const dateFromLocal = moment(dateFrom);

      if (Math.abs(dateFromLocal.diff(dateToLocal, 'months')) > 6) {
        return setFormData(prev => ({
          ...prev,
          dateTo: value,
          dateFrom: dateToLocal.format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY),
        }));
      }
    }
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFormChange = useMemo(() => ({ target: { name, value } }) => {
    switch (name) {
      case 'dateFrom':
      case 'dateTo':
        handleDateChange({ target: { name, value } });
        break;
      default:
        setFormData(prev => ({
          ...prev,
          [name]: value,
          ...(name === 'gameDef' && { meetingLink: '' }),
        }));
    }
  }, []);

  const isOnlineOnly = useMemo(() => {
    const chosenGameDef = gameDefs.find(definition => definition.id === gameDef);
    if (!chosenGameDef) return false;
    return chosenGameDef.is_online_only;
  },
  [gameDef, gameDefs]);

  const isFormValid = useMemo(() => !!(
    dateFrom
    && dateTo
    && location
    && gameDef
    && kit
    && timezone
    && startTime
    && sessionsPerDay
    && weekdays.length
    && moment(dateTo).isValid() && moment(dateFrom).isValid()
    && (!isOnlineOnly || meetingLink || isSecretSession)
  ), [
    dateFrom,
    dateTo,
    location,
    gameDef,
    kit,
    weekdays,
    timezone,
    startTime,
    sessionsPerDay,
    isOnlineOnly,
    meetingLink,
  ]);

  const fetchLocations = (location, callback) => {
    getLocations({ location, withoutStoreChanging: true }).then(({ results: locations }) => {
      callback(locations.map(({ location, id }) => ({ value: id, label: location })));
    });
  };
  const loadLocations = debounce(fetchLocations, 300);
  const handleMenuOpen = () => {
    setTimeout(() => {
      const defaultTime = TIME_OPTIONS.DEFAULT_HOUR * TIME.MINUTES_IN_HOUR / TIME_OPTIONS.STEP_IN_MINUTES;
      if (timeList.current.select.menuListRef.children[defaultTime]) {
        timeList.current.select.menuListRef.children[defaultTime].scrollIntoView();
      }
    }, 50);
  };

  const sessionDuration = useMemo(() => {
    const { campaign: { debriefing_time, introduction_time } } = initialData;
    const currentGameDef = gameDefs.find(({ id }) => id === gameDef);
    return currentGameDef
      ? convertTimeStringToSeconds(currentGameDef.time_limit)
      + convertTimeStringToSeconds(debriefingTime || debriefing_time)
      + convertTimeStringToSeconds(introductionTime || introduction_time)
      : 0;
  }, [gameDefs, gameDef, debriefingTime, introductionTime]);

  const sessionOptions = useMemo(() => {
    const sessions = [];

    if (startTime && sessionDuration) {
      const count = (
        (
          (TIME.HOURS_IN_DAY * TIME.MINUTES_IN_HOUR * TIME.SECONDS_IN_MIN)
          - convertTimeStringToSeconds(startTime)
        )
        // (sessionDuration + convertTimeStringToSeconds(breakDuration))
        / sessionDuration
      );

      if (count > 1) {
        for (let i = 1; i < count; i++) {
          sessions.push({ value: i, label: `${i}` });
        }
      }
    }
    return sessions;
  }, [
    startTime,
    gameDefs,
    gameDef,
    sessionDuration,
    // breakDuration
  ]);
  const timeOptions = useMemo(generateTimeOptions, []);
  const kitOptions = useMemo(() => (
    generateKitOptions({ kits: initialData.kits, campaign: initialData.campaign })
  ), [initialData.kits]);
  const isKitOptionDisabled = useMemo(() => option => get(option, 'disabled', false), []);
  // const breakOptions = useMemo(() => generateTimeLimits({ interval: 5, limit: 60 }), []);
  // const introductionTimeOptions = useMemo(() => generateTimeLimits({ interval: 1, limit: 5 }), []);
  const includedWeekdays = useMemo(() => (
    getIncludedWeekdays({
      dateFrom,
      dateTo,
      weekdays: weekdaysShort,
    })
  ), [dateFrom, dateTo, weekdaysShort]);

  const processRange = async () => {
    setFormDateRange(getMonthsBetweenDates(dateFrom, dateTo) || {});

    if (typeof location === 'string') {
      const { id } = await addNewLocation(location);
      setFormData(prev => ({ ...prev, location: id }));
    }
    steps.nextStep();
  };

  useEffect(() => {
    const newSessionsPerDayMaxCount = (
      (
        (TIME.HOURS_IN_DAY * TIME.MINUTES_IN_HOUR * TIME.SECONDS_IN_MIN)
        - convertTimeStringToSeconds(startTime)
      )
      // (sessionDuration + convertTimeStringToSeconds(breakDuration))
      / sessionDuration
    );

    if (newSessionsPerDayMaxCount < sessionsPerDay) {
      handleFormChange({ target: { name: 'sessionsPerDay', value: null } });
    }
  }, [
    // breakDuration,
    sessionDuration,
    startTime,
    sessionsPerDay,
  ]);

  useEffect(() => {
    setActiveDays();
  }, [
    // breakDuration,
    dateFrom,
    dateTo,
  ]);

  return ({
    ...initialData,
    ...innerData,
    ...steps,
    ...externalData,
    onClose,
    formDateRange,
    weekdaysShort,
    timeList,
    isFormValid,
    fetchLocations,
    loadLocations,
    handleFormChange,
    handleMenuOpen,
    timeOptions,
    sessionOptions,
    processRange,
    sessionDuration,
    loadGameSessions,
    handleCalendarChange,
    loadDaysWithGameSession,
    kitOptions,
    isKitOptionDisabled,
    dateErrors,
    toggleWeekday,
    // introductionTimeOptions,
    hasMoreOptions,
    setMoreOptions,
    includedWeekdays,
    isTimerCountingUp,
    toggleTimerType,
    changeParticipantsPerSession,
    setActiveDays,
    isOnlineOnly,
    resetCampaignsFilter,
  });
}

export default useSetupSessionsForm;
