export default [
  {
    value: '00:05:00',
    label: '5 minutes',
  },
  {
    value: '00:10:00',
    label: '10 minutes',
  },
  {
    value: '00:15:00',
    label: '15 minutes',
  },
  {
    value: '00:20:00',
    label: '20 minutes',
  },
  {
    value: '00:25:00',
    label: '25 minutes',
  },
  {
    value: '00:30:00',
    label: '30 minutes',
  },
  {
    value: '00:35:00',
    label: '35 minutes',
  },
  {
    value: '00:40:00',
    label: '40 minutes',
  },
  {
    value: '00:45:00',
    label: '45 minutes',
  },
  {
    value: '00:50:00',
    label: '50 minutes',
  },
  {
    value: '00:55:00',
    label: '55 minutes',
  },
  {
    value: '01:00:00',
    label: '60 minutes',
  },
];
