/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';
import { MODAL_BG_COLOR } from '_constants';

/* Styles */
import { TextEllipsis, CustomScroll } from 'assets/styles/mixins';

export const Title = styled.p`
  flex-grow: 1;
  font-size: 24px;
  font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
  line-height: 26px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  ${TextEllipsis};
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 40px 48px 0 48px;
  ${({ hasAdd, aca }) => hasAdd && css`
    ${Title} {
      ${aca === 'right' ? 'padding-right' : 'padding-left'}: 16px ;
    }
  `}
  ${({ aca }) => aca === 'left' && css`
    flex-direction: row-reverse;
  `};
`;

export const CancelIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ crossColor }) => crossColor && css`
    color: ${crossColor};
  `};
  &:before,
  &:after {
    content: '';
    position: relative;
    width: 20px;
    height: 2px;
    background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
  }
  &:before {
    transform: rotate(45deg);
    right: -10px;
  }
  &:after {
    transform: rotate(-45deg);
    left: -5px;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.backdropColor || COLORS_CONFIG.SHADOWS.BACKDROP_BROWN};
  opacity: 0;
`;

export const Modal = styled.div`
  position: relative;
  border-radius: 8px;
  z-index: 1000;
  transform: translateY(-50%);
  width: calc(100% - 48px);
  max-width: 496px;
  min-height: 350px;
  max-height: calc(100vh - 24px);
  margin: 0 auto;
  background-color: ${COLORS_CONFIG.WHITE};
  background-repeat: no-repeat;
  box-shadow: 0 2px 60px 0 ${COLORS_CONFIG.SHADOWS.BLACK$10};
  overflow-y: ${({ withoutScroll }) => (withoutScroll ? 'hidden' : 'auto')};
  opacity: 0;
  
  ${() => CustomScroll({ scrollBG: 'transparent' })}
`;

export const OverallModalWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ state }) => {
    if (state === 'entered') {
      return css`
        ${Modal} {
          animation: modalFadeDown 0.25s ease both;
        }

        ${Backdrop} {
          animation: show 0.5s ease both;
        }
      `;
    }

    if (state === 'exiting' || state === 'exited') {
      return css`
        ${Modal} {
          animation: modalFadeUp 0.25s ease both;
          animation-delay: 0.1s;
        }

        ${Backdrop} {
          animation: show 0.5s ease both;
        }
      `;
    }
  }}
  
  
  ${({ backgroundColor }) => {
    switch (backgroundColor) {
      case MODAL_BG_COLOR.BLUE:
        return css`
           ${Modal} {
             background-color: ${COLORS_CONFIG.SKY_BLUE};
           }
            
           ${CancelIconWrapper} {
            &:before,
            &:after {
              content: '';
              position: relative;
              width: 20px;
              height: 2px;
              background-color: ${COLORS_CONFIG.WHITE};
            }
           }
         `;
      case MODAL_BG_COLOR.DARK:
        return css`
           ${Modal} {
             background-color: ${COLORS_CONFIG.DARK_BLUE};
           }
           
           ${CancelIconWrapper} {
             &:before,
             &:after {
               content: '';
               position: relative;
               width: 20px;
               height: 2px;
               background-color: ${COLORS_CONFIG.WHITE};
             }
            }
            
           ${Title} {
             color: ${COLORS_CONFIG.WHITE};
           }
         `;
      default:
        break;
    }
  }}
  
`;
