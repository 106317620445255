/* Libs */
import styled from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Text = styled.div`
  padding-left: 10px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  font-size: 14px;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;
