import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';

import { Tooltip, Content as TooltipContent } from 'components/blocks/ui/Tooltip/styles';
// eslint-disable-next-line import/no-named-default
import { default as Triangle } from 'components/blocks/Markers/BackTriangle/styles';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  ${Tooltip} {
    top: 26px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.31);
    z-index: 2;
    ${Triangle} {
      top: -5px;
      left: 8px;
      border-width: 0 4px 6px 4px;
      border-color: transparent transparent ${COLORS_CONFIG.WHITE} transparent;
    }
    ${TooltipContent} {
      word-break: break-word;
      min-height: unset;
      width: auto;
      font-size: 14px;
      padding: 6px 10px;
    }
  }
`;
