/* Libs */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

/* Hooks */

import {
  useOutsideClickHook,
} from 'hooks';

/* Components */

import {
  BackTriangle,
  Loader,
} from 'components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function Tooltip({
  visible,
  onClose,
  loading,
  children,
}) {
  const tooltipRef = useRef(null);
  useOutsideClickHook(onClose, tooltipRef);

  if (!visible) return null;

  return (
    <Styled.Tooltip ref={tooltipRef}>
      {/* {onClose && <Styled.Close onClick={onClose}>X</Styled.Close>} */}
      <BackTriangle color={COLORS_CONFIG.WHITE} />
      <Styled.Content>
        {loading ? <Loader /> : children}
      </Styled.Content>
    </Styled.Tooltip>
  );
}

/* Tooltip type of props */

Tooltip.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
};

Tooltip.defaultProps = {
  visible: false,
  onClose: null,
  loading: false,
};

export default Tooltip;
