import { restoreGame } from 'utils/transformers';
import { GAME_CONFIG } from 'config';
import * as TYPES from './ManageGame.types';

const initialState = {
  gameSession: {},
  details: {},
  result: {},
  participants: [],
  questions: [],
  teamName: '',
  status: '',
  gameTime: null,
  loaded: false,
  loading: false,
  gameSessionGlobalStatus: 1,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.RESET_MANAGE_GAME:
      return {
        ...initialState,
      };
    case TYPES.GET_GAME_SESSION:
      return state;
    case TYPES.SET_NEW_GAME_TIME:
    case TYPES.ADD_PARTICIPANT:
    case TYPES.DELETE_PARTICIPANT:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CLOSE_GAME_SUCCESS:
      return {
        ...state,
        gameSessionGlobalStatus: action.payload.newStatus,
      };
    case TYPES.SET_NEW_GAME_TIME_SUCCESS:
      return {
        ...state,
        gameTime: action.payload.newTime,
        loading: false,
      };
    case TYPES.ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        participants: action.result,
        loading: false,
      };
    case TYPES.SET_ANONYMOUS_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.result,
        loading: false,
      };
    case TYPES.DELETE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        participants: state.participants.filter(
          participant => participant.id !== action.payload.deletedParticipantId,
        ),
        loading: false,
      };
    case TYPES.GET_GAME_SESSION_SUCCESS: {
      const {
        game_session: gameSession,
        game_session_details: gameSessionDetails,
        game_session_result: gameSessionResult,
        status,
      } = action.result;
      const {
        // is_finished,
        restoredQuestions,
        lastStep,
        timeLeft,
      } = restoreGame({
        currentAnswers: gameSessionDetails,
        staticData: action.staticData,
        gameDef: gameSession.game_def,
        gameSessionResult,
      });
      return {
        ...state,
        gameSession,
        details: gameSessionDetails,
        result: gameSessionResult,
        gameTime: timeLeft,
        teamName: gameSessionResult.team_name,
        status: GAME_CONFIG.STEPS_NAME[lastStep],
        statusCode: status,
        step: lastStep,
        questions: restoredQuestions,
        participants: gameSession.actual_participants,
        gameSessionGlobalStatus: gameSession.status,
        loaded: true,
      };
    }
    case TYPES.SET_NEW_GAME_TIME_FAIL:
    case TYPES.DELETE_PARTICIPANT_FAIL:
    case TYPES.ADD_PARTICIPANT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_GAME_SESSION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
};

export default reducer;
