/* Libs */
import styled from 'styled-components';

/* Constants */

import { COLORS_CONFIG } from 'config';


export const List = styled.div`
  position: absolute;
  top: 100%;
  left: -40px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  width: calc(100% + 16px + 32px );
  background-color: ${COLORS_CONFIG.WHITE};
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;
