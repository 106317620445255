/* Libs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* utils */

import { hasPermission } from 'utils/custom';

/* Components */

import {
  Card,
  EditLicenseCountModal,
} from 'components';

/* Constants */

import { PERMISSION_NAMES } from '_constants';

/* Styles */

import * as Styled from './styles';

function LicenseUsageCard({
  used,
  total,
  isImpersonatedAdmin,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Styled.Wrapper>
      <Card
        title="User license usage"
        actions={hasPermission(PERMISSION_NAMES.EDIT_USER_LICENSE_USAGE) && !isImpersonatedAdmin
          ? [{
            label: 'Edit',
            action: () => setIsOpen(true),
          }]
          : []
        }
      >
        <Styled.Count>
          {`${used} of ${total}`}
        </Styled.Count>
      </Card>
      <EditLicenseCountModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        data={{ licenses: total }}
      />
    </Styled.Wrapper>
  );
}

/* LicenseUsageCard type of props */

LicenseUsageCard.propTypes = {
  used: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  isImpersonatedAdmin: PropTypes.bool,
};

LicenseUsageCard.defaultProps = {
  isImpersonatedAdmin: false,
};

export default LicenseUsageCard;
