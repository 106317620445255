/* Libs */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/* Utils */

import { impersonateService } from 'utils/services';
import { redirect } from 'utils/custom';

/* Components */

import { ContainerLoader } from 'components';

/* Constants */

import { URLS_CONFIG } from 'config';

function Impersonate({
  match: { params: { id } },
}) {
  useEffect(() => {
    impersonateService.impersonate(id);
    redirect(URLS_CONFIG.companyAdmin.calendar);
  }, []);
  return <ContainerLoader />;
}

Impersonate.path = URLS_CONFIG.companyAdmin.impersonate;

Impersonate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Impersonate;
