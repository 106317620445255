/* Libs */
import React from 'react';

import loader from 'assets/icons/loader.svg';

import * as Styled from './styles';

function Loader() {
  return (
    <Styled.Wrapper>
      <Styled.Loader src={loader} alt="loader" />
    </Styled.Wrapper>
  );
}

export default Loader;
