/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Table } from 'components/blocks/Table/styles';
import { Cell as ActionCell } from 'components/blocks/TableCells/ActionsCell/styles';
import { Pagination } from 'components/ui/Pagination/styles';

// TODO: styles of action cell after logic is completed

export const DaySchedule = styled.div`
  & ${Table} {
    background-color: ${COLORS_CONFIG.TRANSPARENT};
    box-shadow: initial;
    & ${ActionCell} {
      & > *:not(:first-child)::before {
          background-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      }
      & div {
        font-size: 14px;
      }
      & > div {
        color: ${COLORS_CONFIG.SMOOTH_BROWN};
        font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
        cursor: pointer;
        text-align: right;
        width: 50%;
        &:last-child {
          text-align: left;
        }
      }
    }
    ${Pagination} {
      margin-top: 40px;
    }
  }
`;

export default DaySchedule;
