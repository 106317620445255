/* Libs */
import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';

/* Constants */
import { URLS_CONFIG } from 'config';

import GameRoute from 'containers/Game/GameRoute';
import HostRoute from 'containers/Host/HostRoute';

// Admin subroutes
const GAME_SUB_ROUTES = [
  require('./Game').default,
];

const LAUNCH_ROUTES = [
  require('./Launch').default,
];

export default class GamePage extends Component {
  // Static props
  static path = URLS_CONFIG.game.default;

  render() {
    return (
      <div>
        <Switch>
          {LAUNCH_ROUTES.map(route => (
            <HostRoute path={route.path} component={route} key={route.path} />
          ))}
          {GAME_SUB_ROUTES.map(route => (
            <GameRoute path={route.path} component={route} key={route.path} />
          ))}
          <Redirect to={URLS_CONFIG.auth.signin} />
        </Switch>
      </div>
    );
  }
}
