/* Libs */
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce, isEmpty } from 'lodash';

/* Actions */

import {
  KitsEntity,
  LS_AdminStorylinesEntity,
} from '_entities';

/* Utils */

import { getError } from 'utils/custom';
import { validateDate, validateText, validateStorylineSelect } from 'utils/validation/fields';
import { formIsValid } from 'utils/validation';
import { notification } from 'utils/services';
import { storylinesToOptions } from 'utils/transformers';


/* Components */

import {
  Button,
  Input,
  Select,
  DatePicker,
  ContainerLoader, IconTooltip,
} from 'components';

/* Constants */

import { NOTIFICATIONS } from '_constants';
import moment from 'moment';
import { INFO_CIRCLE_BLUE } from 'assets/icons';

/* Styles */
import * as Styled from './styles';

const DEFAULT_ERRORS = {
  license_start: null,
  license_end: null,
  kit_id: null,
  nickname: null,
  storyline: null,
};

const INITIAL_DATA = {
  license_start: '',
  license_end: null,
  nickname: '',
  storyline: null,
  kit_id: '',
  name: 'we dont have this on markup', // TODO: remove
};

function AddKitForm({
  availableStorylines,
  addCompanyKit,
  getStorylines,
  companyId,
  onClose,
  loading,
  updateAllKits,
}) {
  const [dates, setDates] = useState({
    licenseStart: INITIAL_DATA.license_start,
    licenseEnd: INITIAL_DATA.license_end,
  });
  const [nickname, setNickname] = useState(INITIAL_DATA.nickname);
  const [storyline, setStoryline] = useState(INITIAL_DATA.storyline);
  const [kitId, setKitId] = useState(INITIAL_DATA.kit_id);
  const [datesErrors, setDatesErrors] = useState({
    licenseStart: DEFAULT_ERRORS.license_start,
    licenseEnd: DEFAULT_ERRORS.license_end,
  });
  const [nicknameError, setNicknameError] = useState(DEFAULT_ERRORS.nickname);
  const [storylineError, setStorylineError] = useState(DEFAULT_ERRORS.storyline);
  const [kitIdError, setKitIdError] = useState(DEFAULT_ERRORS.kit_id);

  const [loaded, setLoaded] = useState(true);
  const [storylines, setStorylines] = useState(storylinesToOptions(availableStorylines));
  const [defaultStorylines, setDefaultStorylines] = useState(storylinesToOptions(availableStorylines));

  const handleDateChange = useCallback(
    ({ target: { name, value } }) => {
      const newErrors = { ...datesErrors };

      switch (name) {
        case 'licenseStart':
          newErrors[name] = validateDate({
            value,
            name,
          });
          break;
        case 'licenseEnd':
          newErrors[name] = validateDate({
            value,
            name,
            required: false,
          });
          break;
        default:
          break;
      }

      setDates({
        ...dates,
        [name]: value,
      });
      setDatesErrors(newErrors);
    },
    [
      dates,
      datesErrors,
    ],
  );

  const handleNicknameChange = useCallback(
    ({ target: { name, value } }) => {
      setNickname(value);
      setNicknameError(
        validateText({
          value,
          name,
          max: 256,
        }),
      );
    },
    [],
  );

  const handleStorylineChange = useCallback(
    ({ target: { name, value } }) => {
      setStoryline(value);
      setStorylineError(
        validateStorylineSelect({
          value,
          name,
        }),
      );
    },
    [],
  );

  const handleKitIdChange = useCallback(
    ({ target: { name, value } }) => {
      setKitId(value);
      setKitIdError(
        validateText({
          value,
          name,
        }),
      );
    },
    [],
  );

  const handleSave = async () => {
    try {
      await addCompanyKit({
        company: companyId,
        license_start: dates.licenseStart,
        license_end: isEmpty(dates.licenseEnd) ? null : dates.licenseEnd,
        nickname,
        storyline,
        kit_id: kitId,
      });

      await updateAllKits();

      notification.success(NOTIFICATIONS.SUCCESS_KIT_ADDED);
      onClose();
    } catch (error) {
      if (error.response.data) {
        Object.keys(error.response.data).forEach((key) => {
          switch (key) {
            case 'kit_id':
              setKitIdError(error.response.data[key][0]);
              break;
            case 'nickname':
              setNicknameError(error.response.data[key][0]);
              break;
            default:
              notification.error(getError(error));
              break;
          }
        });
      } else {
        notification.error(getError(error));
      }
    }
  };

  const fetchStorylines = useCallback(debounce((storylineName, callback) => {
    // endpoint has default limit, but we need to load all storylines
    getStorylines({
      name: storylineName,
      limit: 9999999,
    })
      .then(({ results }) => {
        const newStorylines = storylinesToOptions(results);
        callback(newStorylines);
        setStorylines(newStorylines);
      });
  }, 300), [getStorylines]);

  const getAllStorylines = async () => {
    try {
      setLoaded(false);
      // endpoint has default limit, but we need to load all storylines
      const { results } = await getStorylines({ limit: 9999999 });
      if (results.length < 1) {
        notification.error(NOTIFICATIONS.ERROR_KIT_NO_STORYLINE);
        onClose();
        return;
      }
      if (isEmpty(defaultStorylines) || onClose) {
        setDefaultStorylines(results.map(storyline => ({
          label: storyline.name,
          value: storyline.id,
        })));
      }
      setStorylines(results.map(storyline => ({
        label: storyline.name,
        value: storyline.id,
      })));
      setLoaded(true);
    } catch (error) {
      notification.error(getError(error));
    }
  };

  useEffect(() => {
    getAllStorylines();
  }, []);

  const minDate = useMemo(() => moment(dates.licenseStart).toDate(), [dates.licenseStart]);
  const canSubmit = formIsValid(
    {
      license_start: validateDate({ value: dates.licenseStart, name: 'license_start' }),
      nickname: validateText({ value: nickname, name: 'nickname', max: 256 }),
      storyline: validateStorylineSelect({ value: storyline, name: 'storyline' }),
      kit_id: validateText({ value: kitId, name: 'kit_id' }),
    },
    [
      'license_start',
      'nickname',
      'storyline',
      'kit_id',
    ],
  ) && (!dates.licenseEnd || !datesErrors.licenseEnd);

  const nicknameTitle = useMemo(() => (
    <Styled.InputLabel>
      Nickname
    </Styled.InputLabel>
  ), []);
  const storylineTitle = useMemo(() => (
    <Styled.InputLabel>
      Storyline
      <IconTooltip
        src={INFO_CIRCLE_BLUE}
        items={['Storyline can be changed only during kit creation']}
      />
    </Styled.InputLabel>
  ), []);
  const kitIdTitle = useMemo(() => (
    <Styled.InputLabel>
      Kit ID
      <IconTooltip
        src={INFO_CIRCLE_BLUE}
        items={['Kit ID can be changed only during kit creation']}
      />
    </Styled.InputLabel>
  ), []);

  return (
    <Styled.Wrapper>
      <Styled.Input>
        <Styled.InputGroup>
          <DatePicker
            placeholder="Start date"
            title="Start date"
            name="licenseStart"
            error={datesErrors.licenseStart}
            value={dates.licenseStart}
            onChange={handleDateChange}
          />
          <DatePicker
            placeholder="End date"
            title="End date"
            name="licenseEnd"
            error={datesErrors.licenseEnd}
            value={dates.licenseEnd}
            minDate={minDate}
            onChange={handleDateChange}
            position="right"
          />
        </Styled.InputGroup>
      </Styled.Input>
      <Styled.Input>
        { nicknameTitle }
        <Input
          placeholder="Nickname"
          name="nickname"
          value={nickname}
          onChange={handleNicknameChange}
          onBlur={handleNicknameChange}
          error={nicknameError}
        />
      </Styled.Input>
      <Styled.Input>
        {!loaded && <ContainerLoader friendlyMode />}
        { storylineTitle }
        <Select
          onChange={handleStorylineChange}
          onBlur={handleStorylineChange}
          value={storyline}
          error={storylineError}
          name="storyline"
          placeholder="Storyline"
          options={storylines}
          defaultOptions={defaultStorylines}
          loadOptions={fetchStorylines}
          async
          searchable
        />
      </Styled.Input>
      <Styled.Input>
        { kitIdTitle }
        <Input
          placeholder="Kit id"
          name="kitId"
          value={kitId}
          onChange={handleKitIdChange}
          onBlur={handleKitIdChange}
          error={kitIdError}
        />
      </Styled.Input>
      <Styled.Actions>
        <Button
          disabled={!canSubmit || loading}
          onClick={handleSave}
        >
          Save
        </Button>
        <Styled.CancelButton
          onClick={onClose}
        >
          Cancel
        </Styled.CancelButton>
      </Styled.Actions>
    </Styled.Wrapper>
  );
}

/* AddKitForm type of props */

AddKitForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  addCompanyKit: PropTypes.func.isRequired,
  getStorylines: PropTypes.func.isRequired,
  updateAllKits: PropTypes.func.isRequired,
};

export default connect(({ LS_adminCompanyView, kits, LS_adminStorylines }) => ({
  companyId: LS_adminCompanyView.company.id,
  loading: kits.loading,
  availableStorylines: LS_adminStorylines.storylines,
}), {
  addCompanyKit: KitsEntity.actions.addCompanyKit,
  getStorylines: LS_AdminStorylinesEntity.actions.getAllStorylines,
})(AddKitForm);
