/* Libs */
import React from 'react';
import { connect } from 'react-redux';

/* Components */

import {
  Logo,
  Img,
} from 'components';

/* Constants */

import { LOGO_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function GameHeader({
  input,
  timer,
  logo,
  isDarkMode,
}) {
  return (
    <Styled.Wrapper isDarkMode={isDarkMode}>
      <Styled.Logo>
        { logo ? <Img disabled src={logo} size={[205, 80]} /> : <Logo type={isDarkMode ? LOGO_CONFIG.HORIZONTAL_BLUE_WHITE : LOGO_CONFIG.HORIZONTAL_BLUE_BLACK} />}
      </Styled.Logo>
      <Styled.Input>
        {input}
      </Styled.Input>
      <Styled.Timer>
        {timer}
      </Styled.Timer>
    </Styled.Wrapper>
  );
}

/* GameHeader type of props */

GameHeader.propTypes = {};

/* GameHeader default props */

GameHeader.defaultProps = {};

export default connect(({ game }) => ({
  logo: game.logo,
}))(GameHeader);
