/* Libs */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  merge,
  isEmpty,
  isUndefined,
  omit,
} from 'lodash';

/* Actions */

import { GameEntity, AdminCompanyEntity, StaticDataEntity } from '_entities';

/* Utils */

import {
  redirectWithError,
  getGameLink,
  replaceUrl,
  goBack, getError,
} from 'utils/custom';
import { notification, fastSessionService } from 'utils/services';

/* Components */

import {
  LaunchGameSessionForms,
  LaunchGameStruct,
  ContainerLoader, Watermark,
} from 'components';

/* Constants */

import { URLS_CONFIG, DATE_SETTINGS, NOTIFICATIONS_CONFIG } from 'config';
import {
  NOTIFICATIONS,
  LAUNCH_TYPES,
  SESSION_LAUNCH_TYPES,
  PARTICIPANTS_COUNT_RANGE,
} from '_constants';
import { formIsValid, isValidNumbersField } from 'utils/validation';
import { validateText } from 'utils/validation/fields';


const PAGE_TITLE = 'Launch new game';

const PARTICIPANT_COUNT_RULE = /^[+,-.]$/;

function Launch({
  match: { params: { id } },
  setActualParticipants,
  getGameSession,
  launchGame,
  launchFastGame,
  addNewLocation,
  isSecretMode,
  getGameDef,
  setStaticDataDarkMode,
  logo,
}) {
  const [gameSession, setGameSession] = useState({});
  const [dataTeamName, setDataTeamName] = useState({
    team_name: null,
    error: null,
    canSubmit: false,
  });
  const [isDarkMode, setDarkMode] = useState(false);
  const [isForcedAnonymous, setForcedAnonymous] = useState(false);
  const [launchType, setType] = useState(LAUNCH_TYPES.NORMAL);
  const [signedupParticipants, setSignedupParticipants] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);
  const [anonymousParticipants, setAnonymousParticipants] = useState(PARTICIPANTS_COUNT_RANGE.MIN);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const isFastSession = useMemo(() => id === SESSION_LAUNCH_TYPES.FAST, [id]);

  const handleDataTeamNameChange = ({ target: { name, value } }) => {
    const newError = validateText({
      value,
      name,
      max: 64,
    });
    setDataTeamName({
      [name]: value,
      error: newError,
      canSubmit: formIsValid({ [name]: newError }, [name]),
    });
  };

  const validateOnBlurDataTeamNameChange = ({ target: { name, value } }) => {
    const newError = validateText({
      value,
      name,
      max: 64,
    });
    setDataTeamName(prev => ({
      ...prev,
      error: newError,
      canSubmit: formIsValid({ [name]: newError }, [name]),
    }));
  };

  useEffect(() => {
    async function loadGameInfo() {
      try {
        const session = isFastSession
          ? fastSessionService.getFastSessionData()
          : await getGameSession(id);

        const currentDarkMode = isFastSession
          ? isUndefined(session.is_dark_mode)
            ? await getGameDef({ id: session.game_def })
              .then(({ is_dark_mode: isGameDefDarkMode }) => isGameDefDarkMode)
            : session.is_dark_mode
          : session.game_def.is_dark_mode;

        setStaticDataDarkMode(currentDarkMode);

        setDarkMode(currentDarkMode);
        setGameSession(session);
        setType(session.is_anonymous || (isSecretMode && isFastSession) ? LAUNCH_TYPES.ANONIMOUS : LAUNCH_TYPES.NORMAL);
        setSignedupParticipants((session.signedup_participants || [])
          .map(participant => ({ ...participant, isActive: true })));
        setLoaded(true);
      } catch (error) {
        redirectWithError(URLS_CONFIG.auth.signin, NOTIFICATIONS.ERROR_DEFAULT);
      }
    }

    loadGameInfo();

    // function beforeUnloadHandler() {
    //   fastSessionService.removeFastSessionData();
    // };

    // window.addEventListener('beforeunload', beforeUnloadHandler);

    // return () => {
    //   window.removeEventListener('beforeunload', beforeUnloadHandler);
    // };
  }, []);

  const handleToggleActive = id => () => {
    setSignedupParticipants(signedupParticipants.map(participant => ({
      ...participant,
      isActive: participant.id === id ? !participant.isActive : participant.isActive,
    })));
  };

  const handleAdd = (newParticipant) => {
    setNewParticipants([newParticipant, ...newParticipants]);
  };

  const handleDelete = newParticipantEmail => () => {
    setNewParticipants(newParticipants.filter(participant => participant.email !== newParticipantEmail));
  };

  const manageAnonymousParticipantsCount = delta => () => {
    let newCount = anonymousParticipants + delta;
    if (newCount < PARTICIPANTS_COUNT_RANGE.MIN) {
      newCount = PARTICIPANTS_COUNT_RANGE.MIN;
    }
    if (newCount > PARTICIPANTS_COUNT_RANGE.MAX) {
      newCount = PARTICIPANTS_COUNT_RANGE.MAX;
    }
    setAnonymousParticipants(newCount);
  };

  const changeParticipantsCount = ({ target: { value } }) => {
    if (!isValidNumbersField(value) || +value > PARTICIPANTS_COUNT_RANGE.MAX) return;
    const participantCountValidation = val => val.replace(PARTICIPANT_COUNT_RULE, '');
    const participantsCount = participantCountValidation(value);
    setAnonymousParticipants(+participantsCount);
  };

  const validateParticipantsCountOnBlur = ({ target: { value } }) => {
    if (!isEmpty(value)) return;
    setAnonymousParticipants(PARTICIPANTS_COUNT_RANGE.MIN);
  };

  async function launchNewGame() {
    try {
      setLoading(true);

      const requestObject = {
        game_session: gameSession.id,
      };

      if (gameSession.is_anonymous || isForcedAnonymous || (isSecretMode && isFastSession)) {
        requestObject.anonymous_participants_count = anonymousParticipants;
      }

      if (!gameSession.is_anonymous && !isForcedAnonymous) {
        requestObject.signed_up_participants = signedupParticipants
          .reduce((signuped, participant) => (
            participant.isActive
              ? [...signuped, participant.id]
              : signuped
          ), []);
        requestObject.new_participants_emails = newParticipants
          .map(participant => participant.email);
      }

      (id !== SESSION_LAUNCH_TYPES.FAST) && await setActualParticipants(requestObject);

      const { token } = id === SESSION_LAUNCH_TYPES.FAST
        ? await launchFastGame(merge(
          {
            game_session: {
              ...omit(gameSession, 'is_dark_mode'),
              is_anonymous: gameSession.is_anonymous || isForcedAnonymous,
              location: typeof gameSession.location === 'number'
                ? gameSession.location
                : await addNewLocation(gameSession.location).then(({ id }) => id),
              start_time: moment().format(DATE_SETTINGS.FORMAT.YEAR_MONTH_DAY_HOURS_MINUTES),
            },
            game_session_result: {
              team_name: dataTeamName.team_name,
            },
          },
          newParticipants.length ? { new_participants_emails: requestObject.new_participants_emails } : {},
          requestObject.anonymous_participants_count ? { anonymous_participants_count: requestObject.anonymous_participants_count } : {},
        ))
        : await launchGame({
          game_session: gameSession.id,
          team_name: dataTeamName.team_name,
        });

      fastSessionService.removeFastSessionData();
      replaceUrl(getGameLink(token));
    } catch (error) {
      setLoading(false);
      if (getError(error).trim() === NOTIFICATIONS.ERROR_DUPLICATE_TEAM_NAME) {
        setDataTeamName(prev => ({
          ...prev,
          error: NOTIFICATIONS.ERROR_DUPLICATE_TEAM_NAME,
          canSubmit: false,
        }));
      } else {
        notification.error(getError(error));
      }
    }
  }

  function handleNextNormalStep() {
    const activeSignupedParticipants = signedupParticipants
      .filter(signupedParticipant => signupedParticipant.isActive);
    if (activeSignupedParticipants.length < 1 && newParticipants.length < 1) {
      return notification.warning(NOTIFICATIONS_CONFIG.confirmAnonymousMode(
        () => {
          setType(LAUNCH_TYPES.ANONIMOUS);
          setForcedAnonymous(true);
        },
        notification.removeAllNotifications,
      ));
    }
    setType(LAUNCH_TYPES.NAME);
  }

  function backToNormalMode() {
    setType(LAUNCH_TYPES.NORMAL);
    setForcedAnonymous(false);
    setAnonymousParticipants(PARTICIPANTS_COUNT_RANGE.MIN);
  }

  function backToCalendarPage() {
    setStaticDataDarkMode(false);
    fastSessionService.removeFastSessionData();
    goBack({ url: URLS_CONFIG.companyAdmin.calendar, length: 3 });
  }

  function pickRightTypeOfLaunch() {
    switch (launchType) {
      case LAUNCH_TYPES.NORMAL:
        return (
          <LaunchGameSessionForms.Normal
            utc_start_time={gameSession.utc_start_time}
            location={gameSession.location}
            timezone={gameSession.timezone}
            isFastSession={gameSession.is_fast_session}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            signedupParticipants={signedupParticipants}
            newParticipants={newParticipants}
            handleToggleActive={handleToggleActive}
            next={handleNextNormalStep}
            back={backToCalendarPage}
            isDarkMode={isDarkMode}
          />
        );
      case LAUNCH_TYPES.ANONIMOUS:
        return (
          <LaunchGameSessionForms.Anonymous
            utc_start_time={gameSession.utc_start_time}
            location={gameSession.location}
            timezone={gameSession.timezone}
            isFastSession={gameSession.is_fast_session}
            participantCount={anonymousParticipants}
            handleParticipantsCount={changeParticipantsCount}
            handleParticipantsCountOnBlurt={validateParticipantsCountOnBlur}
            increase={manageAnonymousParticipantsCount(1)}
            decrease={manageAnonymousParticipantsCount(-1)}
            next={() => setType(LAUNCH_TYPES.NAME)}
            back={() => (isForcedAnonymous
              ? backToNormalMode()
              : goBack({ url: URLS_CONFIG.companyAdmin.calendar, length: 3 }))
            }
            isDarkMode={isDarkMode}
          />
        );
      case LAUNCH_TYPES.NAME:
        return (
          <LaunchGameSessionForms.Name
            utc_start_time={gameSession.utc_start_time}
            location={gameSession.location}
            isFastSession={gameSession.is_fast_session}
            launch={launchNewGame}
            timezone={gameSession.timezone}
            loading={loading}
            onChange={handleDataTeamNameChange}
            onBlur={validateOnBlurDataTeamNameChange}
            data={dataTeamName}
            back={() => setType(gameSession.is_anonymous || isForcedAnonymous || (isSecretMode && isFastSession)
              ? LAUNCH_TYPES.ANONIMOUS
              : LAUNCH_TYPES.NORMAL)}
            isDarkMode={isDarkMode}
          />
        );
      default:
        break;
    }
  }

  if (!loaded) return <ContainerLoader />;

  return (
    <LaunchGameStruct isDarkMode={isDarkMode}>
      {pickRightTypeOfLaunch()}
      <Watermark logo={logo} isDarkMode={isDarkMode} />
    </LaunchGameStruct>
  );
}

/* Launch type of props */

Launch.propTypes = {
  launchGame: PropTypes.func.isRequired,
  launchFastGame: PropTypes.func.isRequired,
  addNewLocation: PropTypes.func.isRequired,
  getGameSession: PropTypes.func.isRequired,
  setActualParticipants: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isSecretMode: PropTypes.bool,
  getGameDef: PropTypes.func.isRequired,
};

/* Launch default props */

Launch.defaultProps = {
  isSecretMode: false,
};

/* Page Url */

Launch.path = URLS_CONFIG.game.launch;

/* Page Title */

Launch.title = PAGE_TITLE;

export default connect((state => ({
  isSecretMode: state.auth.isSecretMode,
  logo: state.adminCompany.company.logo || state.auth.companyLogo,
})), {
  getGameSession: GameEntity.actions.getGameSession,
  launchGame: GameEntity.actions.launchGame,
  launchFastGame: GameEntity.actions.launchFastGame,
  addNewLocation: AdminCompanyEntity.actions.addNewLocation,
  setActualParticipants: GameEntity.actions.setActualParticipants,
  getGameDef: AdminCompanyEntity.actions.getGameDef,
  setStaticDataDarkMode: StaticDataEntity.actions.setDarkMode,
})(Launch);
