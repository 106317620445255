/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Components */

import { GameHeader } from 'components';

/* Styles */

import * as Styled from './styles';

function GameStruct({
  headerInput,
  headerTimer,
  isDarkMode,
  children,
}) {
  return (
    <Styled.Struct isDarkMode={isDarkMode}>
      <GameHeader
        isDarkMode={isDarkMode}
        input={headerInput}
        timer={headerTimer}
      />
      <Styled.Content>
        { children }
      </Styled.Content>
    </Styled.Struct>
  );
}

/* GameStruct type of props */

GameStruct.propTypes = {
  headerInput: PropTypes.object,
  headerTimer: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  children: PropTypes.node.isRequired,
};

/* GameStruct default props */

GameStruct.defaultProps = {};

export default GameStruct;
