/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

/* Components */
import { Button } from 'components';

/* Utils */
import {
  getStaticData,
  hasPermission,
  redirect,
  getLaunchGameLink,
  getSessionStatus,
  getSessionTimeData,
  getTimezoneTitle,
  getModalDateTitle,
} from 'utils/custom';

/* Constants */
import { SESSION_TIME_TYPES } from 'config';
import { PERMISSION_NAMES } from '_constants';

/* Styles */
import * as Styled from './styles';

function SessionInfoSection({
  onClose,
  openEditSession,
  session: {
    id,
    utc_start_time,
    utc_end_time,
    timezone,
    location,
    kit: {
      kit_id: kitId,
      nickname: kitNickname,
    },
    game_def: {
      name: gameDefName,
    },
    introduction_time,
    debriefing_time,
    invited_participants,
    signedup_participants,
    participants_per_session,
    status,
  },
  companyTimezone,
}) {
  const openEditSessionSidebar = () => {
    onClose();
    openEditSession(id);
  };

  const data = [
    {
      id: 1,
      title: 'Nickname (Kit ID)',
      text: kitNickname ? `${kitNickname} (id: ${kitId})` : `id: ${kitId}`,
    },
    {
      id: 2,
      title: 'Game definition',
      text: gameDefName,
    },
    {
      id: 3,
      title: 'Introduction time',
      text: introduction_time,
    },
    {
      id: 4,
      title: 'Debriefing time',
      text: debriefing_time,
    },
    {
      id: 5,
      title: 'Participants invited',
      text: invited_participants.length,
    },
    {
      id: 6,
      title: 'Participants signed up',
      text: signedup_participants.length,
    },
    {
      id: 7,
      title: 'Maximum participants',
      text: participants_per_session,
    },
    {
      id: 8,
      title: 'Status',
      text: getSessionStatus(status).charAt(0).toUpperCase() + getSessionStatus(status).slice(1),
    },
  ];

  const {
    timezone: localTimezone,
    startTime,
    endTime,
  } = getSessionTimeData({
    type: SESSION_TIME_TYPES.LOCAL_TIME,
    companyTimezone,
    propsTimezone: timezone,
    propsStartTime: utc_start_time,
    propsEndTime: utc_end_time,
  });

  const timezoneTitle = getTimezoneTitle(localTimezone);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>
          {getModalDateTitle(startTime, endTime)}
          {timezoneTitle.length > 15 && <br />}
          {timezoneTitle}
        </Styled.Title>
        <Styled.Location>
          {`Location: ${(location && location.location) || 'No location'}`}
        </Styled.Location>
      </Styled.Header>
      <Styled.Content>
        {
          data.map(({ id: itemId, title, text }) => (
            <Styled.Item key={itemId}>
              <Styled.ItemTitle>{title}</Styled.ItemTitle>
              <Styled.ItemText>{text}</Styled.ItemText>
            </Styled.Item>
          ))
        }
      </Styled.Content>
      {
        status === getStaticData('gameSessionStatuses').scheduled
          ? (
            <Styled.Actions centralize={!hasPermission(PERMISSION_NAMES.EDIT_SESSION)}>
              <Button onClick={() => redirect(getLaunchGameLink(id))}>Launch</Button>
              {
                hasPermission(PERMISSION_NAMES.EDIT_SESSION) && (
                  <Button onClick={openEditSessionSidebar}>Edit</Button>
                )
              }
            </Styled.Actions>
          )
          : (
            <Button onClick={onClose}>Close</Button>
          )
      }
    </Styled.Wrapper>
  );
}

/* SessionInfoSection type of props */
const {
  func, shape, string, number, arrayOf,
} = PropTypes;

SessionInfoSection.propTypes = {
  onClose: func.isRequired,
  openEditSession: func.isRequired,
  session: shape({
    id: number.isRequired,
    utc_start_time: string.isRequired,
    utc_end_time: string.isRequired,
    timezone: string.isRequired,
    location: shape({
      location: string.isRequired,
      id: number.isRequired,
    }).isRequired,
    kit: shape({
      kit_id: string.isRequired,
      id: number.isRequired,
    }).isRequired,
    gameDef: shape({
      name: string.isRequired,
    }).isRequired,
    introduction_time: string.isRequired,
    debriefing_time: string.isRequired,
    invited_participants: arrayOf(string).isRequired,
    signedup_participants: arrayOf(string).isRequired,
    participants_per_session: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  companyTimezone: get(state, 'adminCompany.company.timezone'),
});

export default connect(mapStateToProps, null)(SessionInfoSection);
