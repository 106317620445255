/* Libs */
import styled from 'styled-components';

import { Wrapper as RuleWrapper } from 'components/blocks/ListItems/Rule/styles';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & ${RuleWrapper}:not(:first-child) {
    padding-top: 15px;
  }
`;
