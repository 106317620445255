/* Libs */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

/* Actions */

import {
  LS_AdminStorylinesEntity,
  GameDefEntity,
} from '_entities';

/* Utils */

import {
  getStaticData,
  getViewCompanyLink,
  redirectWithError,
  getError,
} from 'utils/custom';

/* Components */

import {
  Input,
  Select,
  Checkbox,
  BigTitle,
  ContainerLoader,
} from 'components';

/* Constants */

import { NOTIFICATIONS, GAME_DEF_FORM } from '_constants';
import { DATE_SETTINGS, GAME_CONFIG } from 'config';

/* Styles */

import moment from 'moment';
import { validateText } from 'utils/validation/fields';

import { formIsValid, isNull } from 'utils/validation';
import * as Styled from './styles';

const DEFAULT_ERRORS = {
  name: null,
  game_def_template: null,
  time_limit: null,
  final_code: null,
  storyline: null,
};

const TIME_WITH_MIN_REGEX = /^\d+ min$/;

function BasicInformation({
  data,
  mode,
  formName,
  handleChange,
  getAllGameDef,
  getStorylinesByCompanyId,
  importedData,
  globalErrors,
}) {
  const [basicInformationData, setData] = useState({
    loaded: false,
    storyLines: [],
    templates: [],
  });
  const [timeOptions, setTimeOptions] = useState(getStaticData('timeLimits') || []);
  const [errors, setErrors] = useState(DEFAULT_ERRORS);

  useEffect(() => {
    if (globalErrors === null) {
      setErrors(DEFAULT_ERRORS);
    }
  }, [globalErrors]);

  useEffect(() => {
    if (basicInformationData.loaded
      && !basicInformationData.storyLines.find(storyline => storyline.value === data.storyline)
      && !isNull(data.storyline)) {
      onChange({ target: { name: 'storyline', value: null } });
    }
  }, [data.storyline, basicInformationData.loaded]);

  useEffect(() => {
    if (mode && mode === GAME_DEF_FORM.MODE.TEMPLATE) {
      return setData(prev => ({
        ...prev,
        loaded: true,
      }));
    }
    async function loadInformation() {
      try {
        const { results } = await getStorylinesByCompanyId({ id: data.company });
        // if (results.length < 1)
        //   return redirectWithError(getViewCompanyLink(data.company), NOTIFICATIONS.ERROR_GAME_DEF_NO_STORYLINE);
        const { results: gameDefs } = await getAllGameDef({ company: 'null' });
        const templates = gameDefs.map(gameDef => ({ label: gameDef.name, value: gameDef.id }));
        if (data.initial_template_name) {
          templates.unshift({ label: data.initial_template_name, value: data.game_def_template });
        }
        setData({
          loaded: true,
          storyLines: results.map(storyline => ({ label: storyline.name, value: storyline.id })),
          templates,
        });
      } catch (error) {
        redirectWithError(getViewCompanyLink(data.company), getError(error));
      }
    }
    loadInformation();
  }, []);

  const fetchStorylines = (storylineName, callback) => {
    getStorylinesByCompanyId({ id: data.company, name: storylineName }).then(({ results }) => {
      callback(results.map(storyline => ({ label: storyline.name, value: storyline.id })));
    });
  };
  const loadStorilines = debounce(fetchStorylines, 300);

  const fetchTemplates = (templateName, callback) => {
    getAllGameDef({ name: templateName, company: 'null' }).then(({ results }) => {
      callback(results.map(template => ({ label: template.name, value: template.id })));
    });
  };
  const loadTemplates = debounce(fetchTemplates, 300);

  // Validate input in select
  function validateTimeOption(inputValue, selectValue, selectOptions) {
    if (!/^\d+( min)?$/.test(inputValue) || parseInt(inputValue, 10) > GAME_CONFIG.MAXIMUM_TIME) {
      return false;
    }
    const matchLabel = TIME_WITH_MIN_REGEX.test(inputValue) ? inputValue : `${inputValue} min`;
    return !selectOptions.find(selectOption => selectOption.label === matchLabel);
  }

  // Handle create new option in select
  function createNewTimeOption(inputLabel) {
    const [minutes] = inputLabel.split(' ');
    const newTimeValue = moment().startOf('day').add(minutes, 'm').format(DATE_SETTINGS.FORMAT.TIMER_FORMAT);
    setTimeOptions((existedTimeOptions) => {
      if (existedTimeOptions.find(timeOption => timeOption.value === newTimeValue)) {
        return existedTimeOptions;
      }
      return [...existedTimeOptions, {
        value: newTimeValue,
        label: `${minutes} min`,
      }].sort((a, b) => (a.value === b.value ? 0 : a.value > b.value ? 1 : -1));
    });
    onChange({ target: { name: 'time_limit', value: newTimeValue } });
  }

  function calculateInitialErrors() {
    const initErrors = {
      name: validateText({ name: 'name', value: data.name, max: 500 }),
      game_def_template: false,
      time_limit: false,
      final_code: false,
      storyline: null,
    };
    setErrors(initErrors);
    handleChange(
      { target: { name: null, value: null } },
      { formName, isValid: !Object.values(initErrors).some(error => error) },
    );
  }

  useEffect(() => {
    if (isNull(data.game_def_template)) return;
    calculateInitialErrors();
  }, [data.game_def_template]);

  const onChange = useCallback(({ target: { name, value } }) => setErrors((prevErrors) => {
    const newErrors = { ...prevErrors };
    switch (name) {
      case 'name':
        newErrors[name] = validateText({ name, value, max: 500 });
        break;
      case 'game_def_template':
        newErrors[name] = false;
        break;
      case 'time_limit':
        newErrors[name] = false;
        break;
      case 'final_code':
        newErrors[name] = validateText({ name, value, max: 20 });
        break;
      case 'storyline':
        newErrors[name] = isNull(value) ? null : false;
        break;
      default:
        break;
    }
    handleChange(
      { target: { name, value } },
      {
        formName,
        isValid: formIsValid({
          ...newErrors,
        }, [
          'name',
          'time_limit',
          'final_code',
        ]),
      },
    );
    return newErrors;
  }), []);

  useEffect(() => {
    if (importedData) {
      calculateInitialErrors();
    }
  }, [importedData]);

  const allTimeOptions = useMemo(() => {
    if (!data.time_limit) return timeOptions;
    const timeOptionAlreadyExist = timeOptions.some(option => option.value === data.time_limit);
    if (timeOptionAlreadyExist) return timeOptions;
    return [
      ...timeOptions,
      {
        label: `${moment.duration(data.time_limit).asMinutes()} min`,
        value: data.time_limit,
      },
    ].sort((a, b) => {
      const aDuration = moment.duration(a.value).asMinutes();
      const bDuration = moment.duration(b.value).asMinutes();
      return aDuration - bDuration;
    });
  }, [data.time_limit, timeOptions]);

  return (
    <Styled.Wrapper>
      <BigTitle>
        Basic information
      </BigTitle>
      {
        !basicInformationData.loaded
          ? <ContainerLoader friendlyMode />
          : (
            <>
              <Input
                placeholder="Definition name"
                name="name"
                title="Definition name"
                value={data.name}
                onChange={onChange}
                error={errors.name}
              />
              <Styled.InputGroup>
                {
                  mode === GAME_DEF_FORM.MODE.ADD || mode === GAME_DEF_FORM.MODE.TEMPLATE
                    ? (
                      <Select
                        placeholder="Template"
                        title="Template"
                        name="game_def_template"
                        value={data.game_def_template || null}
                        options={basicInformationData.templates}
                        onChange={onChange}
                        error={errors.game_def_template}
                        loadOptions={loadTemplates}
                        defaultOptions={basicInformationData.templates}
                        async
                        searchable
                        isDisabled={mode === GAME_DEF_FORM.MODE.TEMPLATE}
                      />
                    )
                    : (
                      <Styled.Label>
                        <div>Template:</div>
                        <div>{data.game_def_template || 'Not provided'}</div>
                      </Styled.Label>
                    )
                }
                <Select
                  placeholder="Time limit"
                  name="time_limit"
                  title="Time limit"
                  value={data.time_limit || null}
                  onChange={onChange}
                  withoutReset
                  options={allTimeOptions}
                  error={errors.time_limit}
                  creatable
                  searchable
                  formatCreateLabel={inputValue => (TIME_WITH_MIN_REGEX.test(inputValue) ? moment(inputValue).minutes() : `${inputValue} min`)}
                  isValidNewOption={validateTimeOption}
                  onCreateOption={createNewTimeOption}
                />
              </Styled.InputGroup>
              <Styled.InputGroup notSame>
                <Checkbox
                  label="Final code only"
                  name="is_final_code_only"
                  value={data.is_final_code_only}
                  onChange={onChange}
                />
                <Input
                  placeholder="Final code"
                  name="final_code"
                  title="Final code"
                  value={data.final_code}
                  onChange={onChange}
                  error={errors.final_code}
                />
              </Styled.InputGroup>
              <Select
                placeholder="Storyline"
                name="storyline"
                title="Storyline"
                options={basicInformationData.storyLines}
                defaultOptions={basicInformationData.storyLines}
                value={data.storyline || null}
                onChange={onChange}
                error={errors.storyline}
                loadOptions={loadStorilines}
                async
                searchable
                isDisabled={mode === GAME_DEF_FORM.MODE.TEMPLATE}
              />
            </>
          )
      }

    </Styled.Wrapper>
  );
}

/* BasicInformation type of props */

BasicInformation.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.number,
    storyline: PropTypes.number,
    final_code: PropTypes.string,
    time_limit: PropTypes.string,
    game_def_template(props, propName, componentName) {
      if (props.mode === GAME_DEF_FORM.MODE.ADD && typeof props.game_def_template !== 'number') {
        return new Error(
          `Prop ${propName}passed to ${componentName}must be a number.`,
        );
      }
      if (props.mode === GAME_DEF_FORM.MODE.EDIT && typeof props.game_def_template !== 'string') {
        return new Error(
          `Prop ${propName}passed to ${componentName}must be a string.`,
        );
      }
    },
    is_final_code_only: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  getStorylinesByCompanyId: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([
    GAME_DEF_FORM.MODE.EDIT,
    GAME_DEF_FORM.MODE.ADD,
    GAME_DEF_FORM.MODE.TEMPLATE,
  ]).isRequired,
  getAllGameDef: PropTypes.func.isRequired,
};

export default connect(null, {
  getStorylinesByCompanyId: LS_AdminStorylinesEntity.actions.getStorylinesByCompanyId,
  getAllGameDef: GameDefEntity.actions.getAllGameDef,
})(BasicInformation);
