/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function ExportsList({
  children,
}) {
  return (
    <Styled.List>
      {children}
    </Styled.List>
  );
}

/* ExportsList type of props */

ExportsList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExportsList;
