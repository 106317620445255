import { COMMON_ERRORS } from '_constants/errors';
import {
  isEmpty, isNull, isMoreThan, isLessThan,
} from 'utils/validation';

export default ({
  value,
  name,
  required = true,
  min = null,
  max = null,
  initial = false,
  regex = null,
}) => {
  let error = (isEmpty(value) && COMMON_ERRORS.isRequired(name))
    || (!isNull(min)
      && isLessThan(value, min, true)
      && COMMON_ERRORS.lessThan(name, min))
    || (!isNull(max)
      && isMoreThan(value, max, true)
      && COMMON_ERRORS.moreThan(name, max))
    || (!isNull(regex)
      && !regex.test(value)
      && COMMON_ERRORS.onlyDigitsAndLetters);
  error = !required && isEmpty(value) ? null : error;
  error = error && initial ? null : error;
  return error;
};
