/* Libs */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

/* Components */
import {
  SimpleTextCell,
  Table,
} from 'components';

/* Constants */

import { DATE_SETTINGS } from 'config';

/* Styles */
import * as Styled from './styles';

// LicensingTable columns cell structure
const getColumnsData = () => [
  {
    accessor: 'start_date',
    minWidth: 95,
    maxWidth: 150,
    Header: 'Start date',
    Cell: props => <SimpleTextCell value={moment(props.value).format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR)} size="small" />,
  },
  {
    accessor: 'end_date',
    minWidth: 95,
    maxWidth: 150,
    Header: 'End date',
    Cell: props => <SimpleTextCell value={moment(props.value).format(DATE_SETTINGS.FORMAT.MONTH_DAY_YEAR)} size="small" />,
  },
];


export default function LicensingTable({ data, actions, tableSize }) {
  return (
    <Styled.LicensingTable>
      <Table
        defaultPageSize={data.length ? data.length : null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={getColumnsData()}
        data={data}
        actions={actions}
      />
    </Styled.LicensingTable>
  );
}

// Type of props
LicensingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  })).isRequired,
};
