/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */
import {
  SimpleTextCell,
  ActionsCell,
  ColumnCell,
  GoLabel,
  Table,
} from 'components';

/* Utils */
import getFullName from 'utils/custom/getFullName';
import { titleCase } from 'utils/custom';

/* Constants */
import { USER_TYPES, TABLES_CONFIG } from 'config';
import { LABEL_COLOR_TYPES } from '_constants';

/* Styles */
import * as Styled from './styles';

// CampaignsSchedule columns cell structure
const getColumnsData = ({ userType, actions }) => [
  {
    accessor: 'name',
    Header: 'Name',
    Cell: props => (
      <SimpleTextCell
        value={getFullName(props.original.first_name, props.original.last_name)}
        size="small"
      />
    ),
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: props => <SimpleTextCell value={props.original.email} size="small" />,
  },
  {
    accessor: 'role',
    Header: 'Role',
    Cell: props => <SimpleTextCell value={titleCase(props.original.role)} size="small" />,
  },
  {
    accessor: 'campaigns',
    Header: 'Campaigns',
    Cell: props => (props.original.campaigns.length
      ? (
        <ColumnCell
          value={
            props.original.campaigns.length > 10
              ? props.original.campaigns.map(({ name }) => name).slice(0, 10).concat('...')
              : props.original.campaigns.map(({ name }) => name)
          }
          size="small"
        />
      )
      : <SimpleTextCell value="-------" size="small" />),
  },
].concat(
  userType === USER_TYPES.ACTIVE
    ? [
      {
        accessor: 'action',
        minWidth: 200,
        Header: '',
        Cell: ({ original: { id } }) => (
          <ActionsCell>
            <Styled.EditUser onClick={() => actions.openEditUser(id)}>Edit</Styled.EditUser>
            <GoLabel
              text="Resend email"
              click={() => actions.resendEmailToUser(id)}
            />
          </ActionsCell>
        ),
      },
    ]
    : [
      {
        accessor: 'deactivationDate',
        Header: 'Deactivation Date',
        Cell: props => <SimpleTextCell value={moment(props.original.deactivation_date).format('L')} size="small" />,
      },
      {
        accessor: 'action',
        minWidth: 110,
        Header: '',
        Cell: ({ original: { id } }) => (
          <ActionsCell>
            <GoLabel
              text="Activate"
              click={() => actions.activateUser(id)}
              color={LABEL_COLOR_TYPES.BLUE}
            />
          </ActionsCell>
        ),
      },
    ],
);

export default function Users({
  data,
  tableSize,
  userType,
  actions,
  pagination,
}) {
  return (
    <Styled.Users>
      <Table
        defaultPageSize={data.length || null}
        rowMinHeight="small"
        tableSize={tableSize}
        columns={getColumnsData({ userType, actions })}
        data={data}
        pagination={pagination}
      />
    </Styled.Users>
  );
}

// Type of props
const {
  number, string, arrayOf, bool, oneOf, shape, func,
} = PropTypes;

Users.propTypes = {
  data: arrayOf(
    shape({
      id: number.isRequired,
      first_name: string,
      last_name: string,
      email: string.isRequired,
      role: string.isRequired,
      campaigns: arrayOf(Object),
      is_active: bool.isRequired,
      deactivation_date: string,
    }),
  ),
  tableSize: oneOf(Object.values(TABLES_CONFIG.SIZE)),
  userType: oneOf(Object.values(USER_TYPES)).isRequired,
  actions: shape(),
};

Users.defaultProps = {
  data: [],
  tableSize: TABLES_CONFIG.SIZE.S,
};
