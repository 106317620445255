export const TOP = 'TOP';
export const BOTTOM = 'BOTTOM';
export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';

export default {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
};
