/* Libs */
import styled from 'styled-components';

import { COLORS_CONFIG } from 'config';


export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;
