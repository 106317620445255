/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Utils */

import {
  getPercentColorLevel,
} from 'utils/custom';

/* Components */

import {
  ForwardTriangle,
  Card,
  LinkTo,
  Loader,
} from 'components';

/* Constants */

import { COLORS_CONFIG } from 'config';

/* Styles */

import * as Styled from './styles';

function ReportCard({
  value,
  label,
  badge,
  link,
  loading,
}) {
  return (
    <Styled.Wrapper>
      <Card title={loading ? null : value}>
        {loading ? (
          <Loader />
        )
          : (
            <>
              <Styled.Label>{label}</Styled.Label>
              {
                link && (
                  <LinkTo
                    to={link}
                    text="View participants"
                  >
                    <ForwardTriangle color={COLORS_CONFIG.SKY_BLUE} />
                  </LinkTo>
                )
              }
              {
                (badge || badge === 0) && (
                  <Styled.Badge
                    color={getPercentColorLevel(badge)}
                  >
                    {`${badge}%`}
                  </Styled.Badge>
                )
              }
            </>
          )
        }
      </Card>
    </Styled.Wrapper>
  );
}

/* ReportCard type of props */

ReportCard.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  badge: PropTypes.number,
  link: PropTypes.string,
};

/* ReportCard default props */

ReportCard.defaultProps = {
  badge: null,
  link: null,
};

export default ReportCard;
