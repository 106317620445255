import React from 'react';
import PropTypes from 'prop-types';

// import { useOutsideClickHook } from 'hooks';
/* Components */
import { FormActions } from 'components';

/* Styles */
import * as Styled from './styles';


export default function Confirm({
  open,
  confirmText,
  onSubmit,
  onCancel,
  submitText,
  cancelText,
}) {
  if (!open) return null;
  // const confirmRef = useRef(null);
  // useOutsideClickHook(onCancel, confirmRef);

  return (
    <Styled.Wrapper>
      <Styled.ConfirmText>{confirmText}</Styled.ConfirmText>
      <FormActions
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitText={submitText}
        cancelText={cancelText}
      />
    </Styled.Wrapper>
  );
}

Confirm.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
};

Confirm.defaultProps = {
  confirmText: 'Are you sure?',
  submitText: 'Yes',
  cancelText: 'Cancel',
};
