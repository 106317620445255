/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG } from 'config/index';

import { Wrapper as ImgWrapper } from 'components/blocks/ui/Img/styles';


export const Exit = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  ${ImgWrapper}{
    margin-right: 5px;
    transform: rotate(180deg);
  }

  ${({ isDarkMode }) => isDarkMode && css`
      color: ${COLORS_CONFIG.WHITE};
  `}
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  ${ImgWrapper}{
    margin: 0 5px 0 0;
  }

  ${({ isDarkMode }) => isDarkMode && css`
      color: ${COLORS_CONFIG.WHITE};
  `}
`;
