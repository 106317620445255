import BasicInformationForm from './BasicInformation';
import InstructionsForm from './Instructions';
import IntroductionForm from './Introduction';
import DebriefingForm from './Debriefing';
import StorylineForm from './Storyline';
import QuestionForm from './Question';
import ResetForm from './Reset';

export default {
  BasicInformationForm,
  InstructionsForm,
  IntroductionForm,
  DebriefingForm,
  StorylineForm,
  QuestionForm,
  ResetForm,
};
