export default {
  MODE: {
    EDIT: 'edit',
    ADD: 'add',
    VIEW: 'view',
    TEMPLATE: 'template',
    TEMPLATE_EDIT: 'template_edit',
  },
  DEFAULT_ANSWER: {
    answer: '',
    is_correct: false,
  },
  DEFAULT_QUESTION: {
    name: '',
    unlock_code: '',
    intro_vimeo_id: '',
    question: '',
    correct_answer_message: '',
    incorrect_answer_message: '',
    hint_text: '',
    security_context: '',
  },
  STEPS: {
    introduction: 'introduction',
    instruction: 'instruction',
    storyline: 'storyline',
    debriefing: 'debriefing',
    reset: 'reset',
  },
};
