/* Libs */
import React from 'react';
import PropTypes from 'prop-types';


/* Utils */
import {
  isEmpty,
} from 'lodash';

/* Components */

import {
  StorylinesTable,
} from 'components';

/* Styles */

import * as Styled from './styles';

function StorylinesSection({
  storylines,
  pagination,
  loading,
  openEditUserSidebar
}) {
  return (
    <Styled.Wrapper>
      {
        isEmpty(storylines)
          ? <Styled.PleaseCreate> Nothing found.</Styled.PleaseCreate>
          : (
            <StorylinesTable
              storylines={storylines}
              pagination={pagination}
              loading={loading}
              openEditUserSidebar={openEditUserSidebar}
            />
          )
      }
    </Styled.Wrapper>
  );
}

/* StorylinesSection type of props */

StorylinesSection.propTypes = {
  storylines: PropTypes.array.isRequired,
  pagination: PropTypes.node,
};

export default StorylinesSection;
