/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Constants */

import {
  AVAILABLE_COLORS,
  COLORS_CONFIG,
} from 'config';


/* Styles */

import * as Styled from './styles';

function CircleFilled({
  active,
  color,
  colorInactive,
  autoSize,
}) {
  return (
    <Styled.Circle
      active={active}
      autoSize={autoSize}
      color={color}
      colorInactive={colorInactive}
    />
  );
}

/* CircleFilled type of props */

CircleFilled.propTypes = {
  active: PropTypes.bool.isRequired,
  color: PropTypes.oneOf([...AVAILABLE_COLORS]),
  colorInactive: PropTypes.oneOf([...AVAILABLE_COLORS]),
  autoSize: PropTypes.bool,
};

/* CircleFilled default props */

CircleFilled.defaultProps = {
  color: COLORS_CONFIG.SKY_BLUE,
  colorInactive: COLORS_CONFIG.SILVER,
  autoSize: false,
};

export default CircleFilled;
