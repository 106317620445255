export default {
  FORMAT: {
    DAY: 'DD',
    HOUR: 'HH',
    DAY_MONTH: 'DD.MM',
    TIMER_FORMAT: 'HH:mm:ss',
    TIMER_FORMAT_EXPIRED: 'HH:mm:ss.SSS',
    MONTH_DAY_SHORT: 'MM.DD',
    MONTH_DAY: 'MMMM DD',
    HOURS_MINUTES: 'HH:mm',
    MONTH_YEAR: 'MMMM YYYY',
    YEAR_MONTH_DAY: 'YYYY-MM-DD',
    MONTH_DAY_YEAR: 'MM/DD/YYYY',
    DAY_MONTH_DATE: 'dddd, MMMM D',
    HOURS_MINUTES_MERIDIEM: 'hh:mm A',
    HOURS_MINUTES_MERIDIEM_LOW: 'hh:mm a',
    HOURS_MINUTES_SECONDS: 'HH:mm:ss',
    MONTH_DAY_YEAR_HYPHEN: 'MM-DD-YYYY',
    MONTH_DAY_YEAR_DOT: 'MM.DD.YYYY',
    YEAR_MONTH_DAY_HOURS_MINUTES: 'YYYY-MM-DD HH:mm',
    DAY_MONTH_DATE_AT_TIME: 'dddd, MMM DD [at] hh:mm a',
    YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS: 'YYYY-MM-DD HH:mm:ss',
    TIMEZONE_OFFSET: '[(GTM]Z[)]',
    MONTH_SHORT_DAY_SHORT: 'MMM D',
    MONTH_SHORT_DAY_YEAR: 'MMM D YYYY',
    MONTH_SHORT_DAY_COMMA_YEAR: 'MMM D, YYYY',
    MINUTES_SECONDS: 'mm:ss',
    MINUTES_SECONDS_FULL: 'm[m] s[s]',
    DAY_LONG_DAY_SHORT: 'dddd, D',
    SHORT_DAY: 'dd DD',
    WEEKDAY_MONTH_DAY_YEAR: 'dddd, MMM DD, YYYY',
  },
};
