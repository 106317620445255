/* Libs */
import styled, { css } from 'styled-components';


export const ContentWrapper = styled.div`
  & > * {
    height: 100%;
  }
  ${({ hideCurrentCard }) => hideCurrentCard && css`
    & > *:last-child {
      display: none;
    }
  `}
`;
