/* Libs */
import styled, { css } from 'styled-components';
import { FONTS_CONFIG, COLORS_CONFIG } from 'config';

import {
  Select,
  Wrapper as SelectWrapper,
} from 'components/ui/Select/styles';
import {
  Wrapper as DatePickerWrapper,
  DateLabel,
} from 'components/ui/DatePicker/styles';


export const Wrapper = styled.div`
  /* height: 100%; */
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  & ${SelectWrapper} {
    & ${Select} {
      border-color: ${COLORS_CONFIG.SKY_BLUE};
    }
  }
  & ${DatePickerWrapper} {
    & ${DateLabel} {
      height: 51px;
      border-color: ${COLORS_CONFIG.SILVER_CUSTOM_1};
      border-radius: 4px;
      &:hover {
        border-color: ${COLORS_CONFIG.SKY_BLUE};
      }
    }
  }
`;
