import replaceUnderscoreWithSpace from 'utils/custom/replaceUnderscoreWithSpace';

const moreThan = (name, count) => `The ${replaceUnderscoreWithSpace(name)} must be no more than ${count} symbols`;
const lessThan = (name, count) => `The ${replaceUnderscoreWithSpace(name)} must be no less than ${count} symbols`;
const numberLessThan = (name, count) => `The ${replaceUnderscoreWithSpace(name)} must be no less than ${count}`;
const numberMoreThan = (name, count) => `The ${replaceUnderscoreWithSpace(name)} must be no more than ${count}`;
const isRequired = name => `The ${replaceUnderscoreWithSpace(name)} is required`;
const wrongDate = 'Use MM/DD/YYYY';
const dateLessThan = 'Year should not be less than 2000';
const onlyDigitsAndLetters = 'Wrong format, use only digits and letters';
const email = 'The email is not valid';
const emailDuplication = 'You can\'t duplicate emails';
const passwordDontMatch = '*Passwords don’t match';
const participantIsAlreadyExists = 'Participant is already in game session';
const password = 'Password must not be less than 8 characters, must contain one uppercase letter, one lowercase letter and a number';
const imgFormats = 'Allowed formats: PNG, JPG, JPEG.';
const imgSize = 'The chosen picture is more than 5mb';
export default {
  participantIsAlreadyExists,
  onlyDigitsAndLetters,
  passwordDontMatch,
  emailDuplication,
  isRequired,
  wrongDate,
  dateLessThan,
  moreThan,
  lessThan,
  numberMoreThan,
  numberLessThan,
  password,
  email,
  imgFormats,
  imgSize,
};
