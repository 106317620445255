import { invert } from 'lodash';
import moment from 'moment';

import { isEmpty } from 'utils/validation';

import { QUESTION_STATUS, ANSWER_STATUS } from '_constants';
/**
 *
 *  recovery game from BE results
 *
 */
export default function restoreGame({
  gameSessionResult,
  currentAnswers,
  staticData,
  gameDef,
}) {
  let incorrectAnswers = 0;
  const currentStep = gameSessionResult.status;
  const restoredQuestions = (
    Array.isArray(gameDef.questions)
      ? gameDef.questions
      : []
  ).map((question) => {
    let currentStatus = QUESTION_STATUS.NOT_OPENED;
    let { answers } = question;
    const answersRelatedToQuestion = currentAnswers.filter(
      answer => answer.question === question.question,
    );

    // CALCULATE new state of answers depends on answers from BE
    if (answersRelatedToQuestion.length !== 0) {
      answers = answers.map((answer) => {
        const alreadyGivenAnswer = answersRelatedToQuestion.find(
          relatedAnswer => relatedAnswer.answer === answer.answer,
        );
        if (isEmpty(alreadyGivenAnswer)) {
          return {
            ...answer,
            status: ANSWER_STATUS.NOT_ANSWERED,
            clicked: false,
          };
        }
        if (!alreadyGivenAnswer.is_correct) incorrectAnswers++;
        return {
          ...answer,
          clicked: true,
          status: alreadyGivenAnswer.is_correct ? ANSWER_STATUS.CORRECT : ANSWER_STATUS.WRONG,
        };
      });
      currentStatus = isEmpty(answers.find(answer => answer.status === ANSWER_STATUS.CORRECT))
        ? QUESTION_STATUS.OPENED
        : QUESTION_STATUS.ANSWERED;
    }

    // SET DEFAULT STATUS TO ANSWERS
    if (answersRelatedToQuestion.length === 0) {
      answers = answers.map(answer => ({
        ...answer,
        status: ANSWER_STATUS.NOT_ANSWERED,
        clicked: false,
      }));
    }

    return {
      ...question,
      answers,
      status: currentStatus,
    };
  });

  const timeLeft = moment.utc(gameSessionResult.current_time || gameDef.time_limit, 'HH:mm:ss.SSS');

  const availableSteps = invert(staticData.gamePlayStatuses);
  return {
    restoredQuestions,
    lastStep: availableSteps[currentStep],
    timeLeft,
    is_finished: gameSessionResult.is_finished,
  };
}
