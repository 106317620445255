/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

/* Styles */
import * as Styled from './styles';

function ColumnCell({ value }) {
  return (
    <Styled.Wrapper>
      {value.map(item => <Styled.Text key={shortid.generate()}>{item}</Styled.Text>)}
    </Styled.Wrapper>
  );
}

/* ColumnCell type of props */

ColumnCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ColumnCell;
