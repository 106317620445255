import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { redirect, getError } from 'utils/custom';
import { notification } from 'utils/services';

import { SmallConfirmationModal } from 'components';

const DeleteCompanyModal = ({ open, onConfirm, onClose }) => {
  const [isLoading, setLoading] = useState(false);

  const handleOnConfirm = useCallback(throttle(async () => {
    try {
      setLoading(true);
      await onConfirm();
      setLoading(false);
      redirect('/companies', 'Company was successfully deleted');
    } catch (error) {
      setLoading(false);
      notification.error(getError(error));
      onClose();
    }
  }, 1000, { trailing: false }), []);

  return (
    <SmallConfirmationModal
      open={open}
      withoutCloseCross
      style={{ minHeight: 'unset' }}
      onConfirm={handleOnConfirm}
      onClose={onClose}
      description="Are you sure you want to delete this company?"
      confirmText="Delete"
      loading={isLoading}
    />
  );
};

DeleteCompanyModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeleteCompanyModal.defaultProps = {
  open: false,
};

export default DeleteCompanyModal;
