/* Libs */
import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

/* Actions */
import { AdminCompanyEntity } from '_entities';

/* Constants */

import {
  TRIANGLE_TYPES,
  LABEL_COLOR_TYPES,
  NOTIFICATIONS,
} from '_constants';
import {
  CAMPAIGN_TYPES,
  NOTIFICATIONS_CONFIG,
  TABLES_CONFIG,
} from 'config';

/* Utils */

import { redirect, getManageGameLink } from 'utils/custom';
import { notification } from 'utils/services';

/* Components */

import {
  // Loader,
  GoLabel,
  CampaignsScheduleTable,
  Img,
  Tooltip, Button,
} from 'components';

/* Styles */
import { INFO_CIRCLE_BLUE, NO_CAMPAIGNS } from 'assets/icons';
import Loader from 'components/blocks/ui/Loader';
import * as Styled from './styles';


function CampaignsMainSection({
  campaign: {
    id: campaignId,
    name: campaignName,
    sessions_scheduled: sessionsScheduled,
    sessions_completed: sessionsCompleted,
    participants_actual: participantsActual,
    participants_registered: participantsRegistered,
    completed_sessions_participants_per_session_sum: sumOfParticipants,
  },
  actions,
  shareLeaderboard,
  toggleBar,
  filter,
  tableData,
  loading,
  sessionType,
  date,
  openEditSession,
  openViewSessionParticipants,
  exportSession,
  campaignType,
  pagination,
  tooltipData,
  role,
  deleteGameSession,
  loadGameSessions,
  campaigns,
  createCampaignHandler,
  isActiveCampaigns,
}) {
  const [campaignTooltipIsVisible, setCampaignTooltipVisibility] = useState(false);
  const [participantsTooltipIsVisible, setParticipantsTooltipVisibility] = useState(false);
  const openCampaignTooltip = () => setCampaignTooltipVisibility(true);
  const closeCampaignTooltip = () => setCampaignTooltipVisibility(false);
  const openParticipantsTooltip = () => setParticipantsTooltipVisibility(true);
  const closeParticipantsTooltip = () => setParticipantsTooltipVisibility(false);

  const openManageGame = useCallback(id => redirect(getManageGameLink(id, role)), [role]);

  const handleDeleteSession = async (sessionId, sessionStatus) => {
    try {
      await deleteGameSession({ campaignId, sessionId, status: sessionStatus });
      loadGameSessions();
      notification.success(NOTIFICATIONS.SUCCESS_DELETE_SESSION);
    } catch (error) {
      notification.error(error);
    }
  };
  const deleteSessionAction = useCallback((sessionId, sessionStatus) => notification.warning(NOTIFICATIONS_CONFIG.confirmTemplate(
    {
      onSuccess: () => handleDeleteSession(sessionId, sessionStatus),
      onCancel: notification.removeAllNotifications,
      message: `Are you sure you want to delete this session?
      Note: If you delete a session that result will be deleted from "Leaderboard"`,
    },
  )), [loadGameSessions]);

  const statisticsData = [
    {
      number: sessionsScheduled || 0,
      text: 'Sessions scheduled',
      id: 0,
    },
    {
      number: sessionsCompleted || 0,
      text: 'Sessions completed',
      id: 1,
    },
    {
      number: `${participantsActual || 0}/${participantsRegistered || 0}/${sumOfParticipants || 0}`,
      text: 'Participants',
      id: 2,
    },
  ];

  const availableHostRoles = ['host', 'company_host'];

  return (
    <>
      {
        /*
         Loading avoid to show empty layout
         before major data is loaded on page refresh
         */
        loading
          ? (
            <Styled.WrapperLoader>
              <Loader />
            </Styled.WrapperLoader>
          )
          : (
            <Styled.Wrapper>
              {
                isEmpty(campaigns)
                  ? (
                    <Styled.NoCampaignsWrapper>
                      <Img
                        size={[155, 100]}
                        src={NO_CAMPAIGNS}
                        disabled
                      />
                      <Styled.NoCampaignsTitle>
                        No
                        {isActiveCampaigns ? ' active ' : ' closed '}
                        campaigns yet!
                      </Styled.NoCampaignsTitle>
                      {isActiveCampaigns && !availableHostRoles.includes(role) && (
                        <>
                          <Styled.NoCampaignsText>
                            You should create or activate at least one campaign first
                          </Styled.NoCampaignsText>
                          <Button
                            onClick={createCampaignHandler}
                          >
                            Create campaign
                          </Button>
                        </>
                      )}
                    </Styled.NoCampaignsWrapper>
                  )
                  : (
                    <>
                      <Styled.Header>
                        <Styled.Title>
                          <Styled.TitleText>
                            {campaignName}
                          </Styled.TitleText>
                          <Styled.TitleInfo>
                            {
                              campaignId && (
                                <>
                                  <Img
                                    size={[20, 20]}
                                    src={INFO_CIRCLE_BLUE}
                                    onClick={openCampaignTooltip}
                                  />
                                  <Tooltip
                                    visible={campaignTooltipIsVisible}
                                    onClose={closeCampaignTooltip}
                                  >
                                    <Styled.TooltipBlock>
                                      <Styled.TooltipTitle>Game definition</Styled.TooltipTitle>
                                      {
                                        tooltipData.gameDefs.map(gameDef => (
                                          <Styled.TooltipText key={gameDef}>{gameDef}</Styled.TooltipText>
                                        ))
                                      }
                                    </Styled.TooltipBlock>
                                    <Styled.TooltipBlock>
                                      <Styled.TooltipTitle>Storyline</Styled.TooltipTitle>
                                      <Styled.TooltipText>{tooltipData.storyline}</Styled.TooltipText>
                                    </Styled.TooltipBlock>
                                    <Styled.TooltipBlock>
                                      <Styled.TooltipTitle>Nickname (Kit ID)</Styled.TooltipTitle>
                                      {
                                        tooltipData.kits.map(kit => (
                                          <Styled.TooltipText
                                            key={kit.kit_id}
                                          >
                                            {kit.nickname ? `${kit.nickname} (id: ${kit.kit_id})` : `id: ${kit.kit_id}`}
                                          </Styled.TooltipText>
                                        ))
                                      }
                                    </Styled.TooltipBlock>
                                  </Tooltip>
                                </>
                              )
                            }
                          </Styled.TitleInfo>
                        </Styled.Title>
                        {
                          campaignId && (
                            <Styled.Actions>
                              {actions}
                            </Styled.Actions>
                          )
                        }
                      </Styled.Header>
                      {
                        campaignId && (
                          <>
                            <Styled.Statistics>
                              {
                                statisticsData.map(({ number, text, id }) => (
                                  <Styled.StatisticsItem key={id}>
                                    <Styled.StatisticsNumbers>
                                      {number}
                                      {
                                        id === 2 && (
                                          <Styled.ParticipantsTooltip>
                                            <Img
                                              size={[17, 17]}
                                              src={INFO_CIRCLE_BLUE}
                                              onClick={openParticipantsTooltip}
                                            />
                                            <Tooltip
                                              visible={participantsTooltipIsVisible}
                                              onClose={closeParticipantsTooltip}
                                            >
                                              <Styled.ParticipantsDescription>Actual participants</Styled.ParticipantsDescription>
                                              <Styled.ParticipantsDescription>Signed up participants</Styled.ParticipantsDescription>
                                              <Styled.ParticipantsDescription>Max capacity of the participants</Styled.ParticipantsDescription>
                                            </Tooltip>
                                          </Styled.ParticipantsTooltip>
                                        )
                                      }
                                    </Styled.StatisticsNumbers>
                                    <Styled.StatisticsText>{text}</Styled.StatisticsText>
                                  </Styled.StatisticsItem>
                                ))
                              }
                            </Styled.Statistics>
                            <Styled.Share>
                              <GoLabel
                                text="Share leaderboard"
                                triangleType={TRIANGLE_TYPES.FORWARD}
                                color={LABEL_COLOR_TYPES.BLUE}
                                click={shareLeaderboard}
                              />
                            </Styled.Share>
                            <Styled.Filters>
                              {toggleBar}
                              {filter}
                            </Styled.Filters>
                            <CampaignsScheduleTable
                              data={tableData}
                              tableSize={TABLES_CONFIG.SIZE.M}
                              actions={{
                                openEditSession,
                                openViewSessionParticipants,
                                exportSession,
                                openManageGame,
                                deleteSessionAction,
                              }}
                              date={date}
                              sessionType={sessionType}
                              campaignType={campaignType}
                              pagination={pagination}
                              loading={loading}
                              role={role}
                            />
                          </>
                        )
                      }
                    </>
                  )

              }
            </Styled.Wrapper>
          )

      }
    </>
  );
}

/* CampaignsMainSection type of props */

CampaignsMainSection.propTypes = {
  campaign: PropTypes.shape({
    campaignName: PropTypes.string,
    sessionsScheduled: PropTypes.number,
    sessionsCompleted: PropTypes.number,
    participantsActual: PropTypes.number,
    participantsRegistered: PropTypes.number,
    sumOfParticipants: PropTypes.number,
  }),
  actions: PropTypes.node.isRequired,
  toggleBar: PropTypes.node.isRequired,
  filter: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  openEditSession: PropTypes.func.isRequired,
  openViewSessionParticipants: PropTypes.func.isRequired,
  exportSession: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  pagination: PropTypes.node.isRequired,
  deleteGameSession: PropTypes.func.isRequired,
  loadGameSessions: PropTypes.func.isRequired,
};

/* CampaignsMainSection default props */

CampaignsMainSection.defaultProps = {
  campaign: {
    name: 'No campaign name',
  },
  date: null,
};

export default connect(({ auth }) => ({
  role: auth.role,
}), {
  deleteGameSession: AdminCompanyEntity.actions.deleteGameSession,
})(memo(CampaignsMainSection));
