/* Libs */
import styled, { css } from 'styled-components';
import { COLORS_CONFIG, FONTS_CONFIG } from 'config';

import { Wrapper as DeleteAnswerWrapper } from 'components/ui/DeleteAnswer/styles';
import { Wrapper as InputWrapper } from 'components/ui/Input/styles';
import { BigTitle } from 'components/blocks/ui/BigTitle/styles';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${COLORS_CONFIG.WHITE};
  border-radius: 8px;
  box-shadow: ${COLORS_CONFIG.SHADOWS.BLACK$10};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  & ${BigTitle} {
    width: initial;
  }
  padding: 13px 32px 32px 32px;
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > *:first-child {
    width: 48px;
  }
  & ${InputWrapper} {
    flex: 1;
  }
  & ${DeleteAnswerWrapper} {
    padding-left: 10px;
  }
`;

export const Label = styled.div`
  color: ${COLORS_CONFIG.SKY_BLUE};
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_REGULAR};
  padding-left: 8px;
`;

export const AddQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const AnswersSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
`;

export const AddAnswer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;
  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`;

export const Title = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  font-size: 18px;
`;

export const Tip = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 14px;
`;

export const QuestionTab = styled.div`
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOOK};
  font-size: 16px;
  width: 24px;
  text-align: center;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};
  position: relative;
  cursor: pointer;
  padding-bottom: 8px;
  ${({ isActive }) => isActive && css`
    font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
    border-bottom: 2px solid ${COLORS_CONFIG.ORANGE};
  `}

  ${({ hasError }) => hasError === false && css`
    color: ${COLORS_CONFIG.LIGHT_GREEN};
  `}
  ${({ hasError }) => hasError === true && css`
    color: ${COLORS_CONFIG.ORANGE};
  `}
`;

export const QuestionsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 36px;
  border-bottom: 1px solid ${COLORS_CONFIG.SILVER};
  & > ${QuestionTab} {
    margin-left: 26px;
  }
`;

export const QuestionWrapper = styled.div`
  padding: 8px 32px 56px 32px;
  & > * {
    margin-top: 24px;
  }
`;

export const OnlyFinalCodeMessage = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: ${COLORS_CONFIG.BLACK$50};
  color: ${COLORS_CONFIG.WHITE};
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
