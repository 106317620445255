/* Libs */
import styled, { css } from 'styled-components';

/* Constants */
import {
  COLORS_CONFIG,
  FONTS_CONFIG,
} from 'config';


export const BreakSpan = styled.span``;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;

  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;

    li {
      flex-shrink: 0;
      display: block;
      width: 25px;
      height: 25px;
      margin: 0 3px 0 0;
      border: none;
      text-align: center;
      transition: color 0.2s ease;
      list-style: none;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      };

      a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: none;
        font-family: ${FONTS_CONFIG.MAIN_TITLE_FONT_BOLD};
        color: ${COLORS_CONFIG.BLACK};
        text-align: center;
        text-decoration: none;
        font-size: 12px;
        outline: none;
        transition: opacity 0.2s ease;
      }

      &:hover {
        opacity: 0.5;
      }
      &.previous {
        margin-right: 10px;
      }
      &.next {
        margin-left: 10px;
      }
      &.active {
        pointer-events: none;
        a {
          color: ${COLORS_CONFIG.WHITE};
          font-size: 16px;
          background: ${COLORS_CONFIG.SILVER_CUSTOM_1};
          border-radius: 50%;
          line-height: 20px;
        }
      }

      &.disabled {
        pointer-events: none;

        a {
          opacity: 0.3;
        }
      }

      &.break-me {
        width: 15px;
      }
    }
  }
  
  
  ${({ isDarkMode }) => isDarkMode && css`
         
    .pagination {
        li {

          a {
            color: ${COLORS_CONFIG.WHITE};
          }
          
          &.active {
            a {
              color: ${COLORS_CONFIG.WHITE};
              background: ${COLORS_CONFIG.LIGHT_BLUE_GRADIENT_1};
            }
          }
        }
      }
 `}
`;
