/* Libs */
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px 40px 48px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:not(:first-child){
    margin-top: 30px;
  }
  & > *:last-child {
    margin-top: 30px;
  }
`;
