import { PERMISSION_NAMES } from '_constants';

let roles = {};

export const setRoles = (data) => {
  roles = data;
};

export default () => ({
  [PERMISSION_NAMES.ADD_KIT]: [roles.admin],
  [PERMISSION_NAMES.RESEND_INVITE]: [roles.admin],
  [PERMISSION_NAMES.EDIT_LICENSING]: [roles.admin],
  [PERMISSION_NAMES.EDIT_KIT_DATES]: [roles.admin],
  [PERMISSION_NAMES.DELETE_CAMPAIGN]: [roles.admin],
  [PERMISSION_NAMES.ADD_GAME_DEFINITION]: [roles.admin],
  [PERMISSION_NAMES.VIEW_GAME_DEFINITION]: [roles.admin],
  [PERMISSION_NAMES.EDIT_POINT_OF_CONTACT]: [roles.admin],
  [PERMISSION_NAMES.EDIT_USER_LICENSE_USAGE]: [roles.admin],
  [PERMISSION_NAMES.BACK_TO_COMPANIES_IN_HEADER]: [roles.admin],
  [PERMISSION_NAMES.VIEW_KIT]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.EDIT_SESSION]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.LOAD_GAME_DEFS]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.ADD_NEW_SESSION]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.CREATE_CAMPAIGN]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.OPEN_SUPPORT_PAGE]: [roles.host, roles.company_admin],
  [PERMISSION_NAMES.LAUNCH_NEW_SESSION]: [roles.host, roles.company_host],
  [PERMISSION_NAMES.ACTIVATE_CAMPAIGNS]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.SET_NOTIFICATION_DAYS]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.CHANGE_GLOBAL_SETTINGS]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.CHANGE_CAMPAIGN_SETTINGS]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.CAN_VIEW_COMPANY_ADMIN_PAGES]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.INVITE_PARTICIPANTS_TO_CAMPAIGN]: [roles.admin, roles.company_admin],
  [PERMISSION_NAMES.LOGOUT_IN_HEADER]: [roles.admin, roles.company_admin, roles.host, roles.company_host],
});
