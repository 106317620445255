/* Libs */
import styled from 'styled-components';

import {
  FONTS_CONFIG,
  COLORS_CONFIG,
} from 'config';

import {
  Wrapper as AccordionWrapper,
} from 'components/blocks/ui/Accordion/styles';
import { Wrapper as ImgWrapper } from 'components/blocks/ui/Img/styles';

export const Wrapper = styled.div`
  min-height: 90%;
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};

  & ${AccordionWrapper} {
    margin-bottom: 11px;
  }
`;

export const Rows = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-left: 38px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${COLORS_CONFIG.SMOOTH_BROWN};

  & ${AccordionWrapper} {
    margin-bottom: 11px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 545px;
  height: 52px;
  cursor: pointer;
  background: #FBFBFB;
  border-radius: 6px;
  padding-right: 17px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 480px;
  min-height: 75px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 102%;
  height: 52px;
  min-height: 40px;
  margin-bottom: 11px;
`;

export const Label = styled.div`
  width: 31%;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  padding-left: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #373636;
`;

export const Range = styled.div`
  width: 31%;
`;

export const Sessions = styled.div`
  width: 31%;
  display: flex;
  ${ImgWrapper} {
    margin-left: 10px;
  }
`;

export const WeekLabel = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 20px;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
`;

export const WeekLabelLine = styled.div`
  height: 100%;
  width: 2px;
  background-color: #EFEFEF;
  border-radius: 10px;
`;

export const WeekLabelTitle = styled.div`
  display: flex;
  height: 27px;
  justify-content: center;
  aling-items: center;
  padding: 0 10px;
  transform: rotate(-90deg);
  border-radius: 15px;
  line-height: 27px;
  font-family: ${FONTS_CONFIG.MAIN_TEXT_FONT_BOLD};
  background-color: ${COLORS_CONFIG.SKY_BLUE};
  font-size: 14px;
  color: ${COLORS_CONFIG.WHITE};
  position: absolute;
  min-width: 180px;
`;
